import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, FormGroup, FormFeedback, Card, Input } from 'reactstrap';
import { getAlertType, getAlertFieldCompare } from '../../Base/ReferenceDataFunctions';
import { handleNotification } from "../../Base/Notification";
import CustomSelect from "../../Base/CustomSelect";
import { ErrorAlert } from "../../Base/ErrorAlert";
import { getAPI } from "../../Base/API";
import BlockUi from 'react-block-ui';
import moment from 'moment-timezone';
import { VoucherConfiguration } from './VoucherConfiguration';
import { BinaryStatusCombo } from '../../Base/CommonUIComponents';
import { hasVoucherAccess } from '../CommunicationJourney/CommonFunctions';

class AlertStep1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            senders: null,
            totalItems: 0,
            offset: 0,
            limit: 10,
            alertType: [],
            formsList: [],
            egoiLists: [],
            pnFormsList: [],
            fixedTime: false,
            loyaltyTypes: [],
            cardTypesBlock: true,
            egoiBlock: true,
            pnBlock: true,
            listBlock: true,
            loyaltyCardLevelsBlock: true,
            loyaltyCardLevels: []
        };
    }

    componentDidMount() {
        const alertType = getAlertType().reduce((acc, current) => { acc.push({ value: current.value, label: current.label }); return acc; }, [])

        this.setState({ alertType, fixedTime: this.props.alert?.ProcessTime != null }, () => this.getForms(this.props.alert));
        this.getLoyaltyCardTypes();
        this.getLoyaltyCardLevels();
    }    

    componentWillReceiveProps(nextProps) {
        if (nextProps.alert && nextProps.alert.alertType && this.props.alert && this.props.alert.alertType && this.props.alert.alertType !== nextProps.alert.alertType) {
            this.getForms(nextProps.alert)
        }
    }

    getForms = (alert) => {
        if (this.state.formsList.length === 0 && alert && alert.alertType === 'Survey') {
            if (alert.source === 'Egoi') {
                this.getEgoiLists();
            }
            else {
                this.getReviwProForms();
            }
        } else if (this.state.formsList.length === 0 && alert && alert.alertType === 'FormSubmit') {
            this.getPNForms();
        }
    }

    getReviwProForms = () => {
        this.setState({ listBlock: true }, () => {

            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, listBlock: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ listBlock: false });
                        return;
                    }
                    if (data.response && data.response.length > 0) {
                        const forms = data.response.map(form => ({ value: form.id, label: form.name }));

                        this.setState({ formsList: forms, listBlock: false });
                    }
                }
                else this.setState({ error: errorMessage, listBlock: false });
            }, '/api/gms/ReviewPro/v1/Surveys')
        });
    }

    getEgoiLists = () => {
        this.setState({ egoiBlock: true, formsList: [] }, () => {

            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, egoiBlock: false });
                    return;
                }
                if (data) {
                    if (data.Errors && data.Errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, egoiBlock: false });
                        return;
                    }
                    if (data.items && data.items.length > 0) {
                        let egoiLists = [];

                        data.items && data.items.forEach(list => {
                            egoiLists.push({ value: list.list_id, label: list.public_name })
                        })

                        this.setState({ egoiLists, egoiBlock: false }, () => { if (this.props.alert.listId) this.getEgoiForms(this.props.alert.listId) });
                    }
                }
                else this.setState({ error: errorMessage, egoiBlock: false });
            }, `/api/gms/Marketing/v1/egoi/lists?limit=100&offset=0`)
        });
    }

    getEgoiForms = (listId) => {
        this.setState({ listBlock: true }, () => {

            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, listBlock: false });
                    return;
                }
                if (data) {
                    if (data.Errors && data.Errors.length > 0) {
                        handleNotification(data);
                    }
                    else if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    var formsList = [];

                    if (data.response && data.response.length > 0) {
                        formsList = data.response.map(form => ({ value: form.id, label: form.title }));
                    }

                    this.setState({ formsList, listBlock: false });
                }
                else this.setState({ error: errorMessage, listBlock: false });
            }, `/api/gms/Marketing/v1/Forms?listId=${listId}`)
        });
    }

    getPNForms = () => {
        this.setState({ pnBlock: true }, () => {

            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, pnBlock: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    if (data.response && data.response.length > 0) {
                        const forms = data.response.map(form => ({ value: form.id, label: form.name }));

                        this.setState({ pnFormsList: forms, pnBlock: false });
                    }
                    else this.setState({ error: errorMessage, pnBlock: false });
                }
                else this.setState({ error: errorMessage, pnBlock: false });
            }, `/api/gms/Survey/v1/survey?all=false`)
        });
    }

    getTypeForms = () => {
        this.setState({ block: true }, () => { 

            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    var formsList = [];

                    if (data.response && data.response.length > 0) {
                        formsList = data.response.map(form => ({ value: form.id, label: form.title }));
                    }

                    this.setState({ formsList, block: false });
                }
                else this.setState({ block: false });
            }, `/api/gms/TypeForm/Forms?page=1&pageSize=1000`)
        });
    }

    handleButton = (field, data) => {
        const e = { target: { name: field, value: data } };

        this.props.handleChange(e);
    }

    getLoyaltyCardTypes = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, cardTypesBlock: false });
                return;
            }
            if (data) {
                this.setState({ error: errorMessage, cardTypesBlock: false, loyaltyTypes: data.response?.map((el) => ({ label: el.description, value: el.code })) });
            }
            else {
                this.setState({ error: errorMessage, cardTypesBlock: false });
            }
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardTypeConfig`);
    }

    getLoyaltyCardLevels = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, loyaltyCardLevelsBlock: false });

                return;
            }
            if (data) {
                data.response.sort((a, b) => (a.minRoomNightsToLevel + a.minPointsToLevel > b.minRoomNightsToLevel + b.minPointsToLevel)
                    ? 1 : ((b.minRoomNightsToLevel + b.minPointsToLevel > a.minRoomNightsToLevel + a.minPointsToLevel) ? -1 : 0));

                const loyaltyCardLevels = data.response.map(resp => {
                    resp.value = resp.code;
                    resp.label = `${resp.description} (${resp.code})`;
                    return resp;
                });

                this.setState({ error: errorMessage, loyaltyCardLevelsBlock: false, loyaltyCardLevels });
            }
            else {
                this.setState({ error: errorMessage, loyaltyCardLevelsBlock: false });
            }
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardLevelConfig`);
    }

    handleAlertSource = (provider) => {
        const e = { target: { name: 'source', value: provider } };

        if (provider === 'Egoi' && this.state.egoiLists.length === 0) {
            this.getEgoiLists();
        }
        else if (provider === 'PNForm') {
            this.getPNForms();
        }
        else if (provider === 'TypeForm') {
            this.getTypeForms()
        }
        else {
            this.getReviwProForms();
        }

        this.props.handleChange(e);
    }

    handleEgoiList = (combo) => {
        if (combo) {
            this.getEgoiForms(combo.value);
        }

        this.props.handleSelect('listId', combo);
    }

    handleTimePicker = (combo, name) => {
        const { alert } = this.props;
        const time = alert.processTime ? moment(alert.processTime) : moment();

        if (combo && combo.value && name === 'min'){
            time.set('minute', combo.value);
        }
        else{
            time.set('hour', combo.value);
        }

        const e = { target: { name: 'processTime', value: time.format("YYYY-MM-DD HH:mm") }};

        this.props.handleChange(e);
    }

    handleTime = (time) => {
        this.setState({ fixedTime: time?.value === "FixedTime" });
    }

    handleCardType = (combo) => {
        const e = { target: { name: 'loyaltyCardTypeConfigCode', value: combo?.value }};

        this.props.handleChange(e);        
    }

    handleCardLevel = (combo) => {
        const e = { target: { name: 'loyaltyCardLevelConfigCode', value: combo?.value }};

        this.props.handleChange(e);
    }

    handleVoucherEndDate = (endDate) => {
        const e = { target: { name: 'voucherEndDate', value: endDate } };
        
        this.props.handleChange(e);
    }

    render() {
        const { alert, handleChange, handleVoucherChange, hasSendGrid, intl, collectMetricsForGoogleAnalytics, handleSwitch, invalidCampaignName, validateCampaignName, handleCardType, handleAlertType } = this.props;
        const { block, error, egoiLists, loyaltyTypes, cardTypesBlock, pnFormsList, pnBlock, egoiBlock, listBlock, loyaltyCardLevelsBlock, loyaltyCardLevels } = this.state;

        const selectedAlertType = alert && getAlertType().find(at => at.value === alert.alertType);

        const timeOptions = [
            {
                label: intl.formatMessage({ id: 'MarketingAlert.FixedTime' }),
                value: 'FixedTime'
            },
            {
                label: intl.formatMessage({ id: 'MarketingAlert.OnIssuing' }),
                value: 'OnIssuing'
            }
        ];

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                <Row>
                    <Col>
                        <h5><FormattedMessage id="generic.name" /></h5>
                    </Col>
                </Row>
                <FormGroup row>
                    <Col className="mt-2">
                        <Input
                            type="text"
                            name="name"
                            onChange={(e) => handleChange(e)}
                            value={alert && alert.name ? alert.name : ''}
                            maxLength='50'
                        />
                    </Col>
                </FormGroup>

                <Row>
                    <Col className="col-6">
                        <h5><FormattedMessage id="generic.status" /></h5>
                    </Col>
                    {
                        hasSendGrid ?
                            <>
                                <Col className="col-3">
                                    <h5>
                                        <FormattedMessage id="CampaignDetails.CollectMetricsForGoogleAnalytics" />
                                    </h5>
                                </Col>
                                <Col style={{ display: collectMetricsForGoogleAnalytics ? 'initial' : 'none' }} className="px-1">
                                    <h5>
                                        <FormattedMessage id="CampaignDetails.CampaignName" />
                                    </h5>
                                </Col>
                            </>
                            :
                            ''
                    }
                </Row>
                <FormGroup row>
                    <Col className="col-3 pr-1">
                        <Card className="border-0 shadow pointer py-3" body
                            style={alert && alert.active === true ? { backgroundColor: '#d3eaff' } : {}}
                            onClick={() => this.handleButton('active', true)}>
                            <FormattedMessage id="generic.active" />
                        </Card>
                    </Col>
                    <Col className="col-3 px-1">
                        <Card className="border-0 shadow pointer py-3" body
                            style={alert && alert.active === false ? { backgroundColor: '#d3eaff' } : {}}
                            onClick={() => this.handleButton('active', false)}>
                            <FormattedMessage id="generic.inactive" />
                        </Card>
                    </Col>
                    {
                        hasSendGrid ?
                            <>
                                <Col className="col-3">
                                    <BinaryStatusCombo
                                        name='collectMetricsForGoogleAnalytics'
                                        value={collectMetricsForGoogleAnalytics}
                                        onChangeFunc={handleSwitch}
                                        placeholder={<FormattedMessage id="generic.status" />}
                                    />
                                </Col>
                                {
                                    collectMetricsForGoogleAnalytics ?
                                        <Col className="mr-3">
                                            <FormGroup row>
                                                <FormattedMessage id="CampaignDetails.CampaignName">
                                                    {
                                                        placeholder =>
                                                            <Input
                                                                type="text" name="campaignName" value={alert && alert.campaignName ? alert.campaignName : ''}
                                                                invalid={invalidCampaignName}
                                                                onChange={(e) => handleChange(e)}
                                                                onBlur={(e) => validateCampaignName(e)}
                                                                maxLength="50"
                                                                placeholder={placeholder}
                                                            />
                                                    }
                                                </FormattedMessage>
                                                <FormFeedback>
                                                    <FormattedMessage id="CampaignDetails.InvalidCampaignName" />
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        :
                                        ''
                                }
                            </>
                            :
                            ''
                    }
                </FormGroup>

                <Row className="mt-2">
                    <Col>
                        <h5><FormattedMessage id="MarketingAlert.AlertType" /></h5>
                    </Col>
                </Row>
                <FormGroup row>
                    <Col>
                        <Row className="px-3">
                            {this.state.alertType.map((at, key) =>
                                at.value === "LoyaltyCardIssue" ?
                                    global.modules && global.modules.some(m => m === 'LoyaltyProgram') ?
                                        <Col className="px-1 mb-2" key={key}>
                                            <Card className="border-0 shadow pointer py-3" body onClick={() => handleAlertType(at.value)}
                                                style={alert && alert.alertType && alert.alertType === at.value ? { backgroundColor: '#d3eaff' } : {}}
                                            >
                                                {at.label}
                                            </Card>
                                        </Col>
                                    :''
                                :
                                <Col className="px-1 mb-2" key={key}>
                                    <Card className="border-0 shadow pointer py-3" body
                                        style={alert && alert.alertType && alert.alertType === at.value ? { backgroundColor: '#d3eaff' } : {}}
                                        onClick={() => handleAlertType(at.value)}
                                    >
                                        {at.label}
                                    </Card>

                                </Col>
                            )}
                        </Row>
                    </Col>
                </FormGroup>

                {alert && alert.alertType ?
                    alert.alertType === 'Survey' ?
                        <div>
                            <Row className="mt-4">
                                <Col>
                                    <h5><FormattedMessage id="MarketingAlert.Provider" /></h5>
                                </Col>
                            </Row>
                            <FormGroup row className="px-3" >
                                {selectedAlertType && selectedAlertType.fieldType.map((provider, key) =>
                                    <Col className="px-1 mb-2 col-3" key={key}>
                                        <Card className="border-0 shadow pointer py-3" body
                                            style={alert && alert.source && alert.source === provider.value ? { backgroundColor: '#d3eaff' } : {}}
                                            onClick={() => this.handleAlertSource(provider.value)}
                                        >
                                            {provider.label}
                                        </Card>
                                    </Col>
                                )}
                            </FormGroup>


                            <Row className="mt-4 px-3">
                                {alert.source &&
                                <>
                                    {alert.source === 'Egoi' &&
                                        <Col className="px-1 mb-2 col-3">
                                                <h5><FormattedMessage id="MarketingAlert.EgoiList" /></h5>
                                        </Col>
                                    }

                                    <Col className="px-1 mb-2 col-3">
                                        <h5><FormattedMessage id="MarketingAlert.Form" /></h5>
                                    </Col>
                                </>
                                }
                            </Row>
                            <FormGroup row className="px-3">
                                {alert.source && alert.source === 'Egoi' &&
                                    <Col className="px-1 mb-2 col-3">
                                        <CustomSelect
                                            options={egoiLists}
                                            isSearchable
                                            isLoading={egoiBlock}
                                            placeholder={''}
                                            onChange={this.handleEgoiList.bind(this)}
                                            value={egoiLists.find(el => el.value === alert.listId)}
                                        />
                                    </Col>
                                }

                                {alert.source &&
                                    <Col className="px-1 mb-2 col-3">
                                        <CustomSelect
                                            options={this.state.formsList}
                                            isSearchable
                                            isLoading={listBlock}
                                            placeholder={''}
                                            onChange={this.props.handleSelect.bind(this, "formId")}
                                            value={this.state.formsList.find(el => el.value == alert.formId)}
                                        />
                                    </Col>
                                }
                            </FormGroup>
                        </div>
                    : alert.alertType === 'Review' ?
                        <div>
                            <Row className="mt-4">
                                <Col>
                                    <h5><FormattedMessage id="MarketingAlert.FieldType" /></h5>
                                </Col>
                            </Row>
                            <FormGroup row>
                                <Col>
                                    <Row className="px-3">
                                        {selectedAlertType && selectedAlertType.fieldType.map((at, key) =>
                                            <Col className="px-1 mb-2 col-2" key={key}>
                                                <Card className="border-0 shadow pointer py-3" key={key} body
                                                    style={alert && alert.fieldType && alert.fieldType === at.value ? { backgroundColor: '#d3eaff' } : {}}
                                                    onClick={() => this.handleButton('fieldType', at.value)}
                                                >
                                                    {at.label}
                                                </Card>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            </FormGroup>

                            <Row>
                                <Col>
                                    <h5><FormattedMessage id="MarketingAlert.FieldCompare" /></h5>
                                </Col>
                            </Row>
                            <FormGroup row>
                                <Col>
                                    <Row className="px-3">
                                        {getAlertFieldCompare().map((fc, key) =>
                                            <Col className="px-1 mb-2 col-2" key={key}>
                                                <Card className="border-0 shadow pointer py-3" key={key} body
                                                    style={alert && alert.fieldCompare && alert.fieldCompare === fc.value ? { backgroundColor: '#d3eaff' } : {}}
                                                    onClick={() => this.handleButton('fieldCompare', fc.value)}
                                                >
                                                    {fc.label}
                                                </Card>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            </FormGroup>

                            <Row className="mb-2">
                                <Col>
                                    <h5><FormattedMessage id="MarketingAlert.FieldValue" /></h5>
                                </Col>
                            </Row>
                            <FormGroup row>
                                <Col className="col-4">
                                    <Input
                                        type="number"
                                        name="fieldValue"
                                        onChange={(e) => handleChange(e)}
                                        value={alert.fieldValue ? alert.fieldValue : ''}
                                    />
                                </Col>
                            </FormGroup>
                        </div>
                    : alert.alertType === 'LoyaltyCardIssue' || alert.alertType === 'LoyaltyCardLevelUp' ?
                        <div>
                            {/* <Row className="mt-4">
                                <Col className='col-2'>
                                    <h5><FormattedMessage id="MarketingAlert.Time" /></h5>
                                </Col>
                                {fixedTime ?
                                    <Col className='col-2'>
                                        <h5><FormattedMessage id="MarketingAlert.ProcessTime" /></h5>
                                    </Col>
                                :''}
                            </Row>
                            <FormGroup row>
                                <Col>
                                    <Row className="px-3">
                                        <Col className="px-1 mb-2 col-2">
                                            <CustomSelect
                                                isSearchable
                                                placeholder={""}
                                                options={timeOptions}
                                                onChange={this.handleTime}
                                                value={fixedTime ? timeOptions.find((op) => op.value === "FixedTime") : timeOptions.find((op) => op.value === "OnIssuing")}
                                            />
                                        </Col>
                                        {fixedTime ?
                                            <Col className="px-1 mb-2 col-2">
                                                <CommonTimePicker
                                                    handleTimePicker={this.handleTimePicker}
                                                    time={alert.processTime}
                                                />
                                            </Col>
                                        :''}
                                    </Row>
                                </Col>
                            </FormGroup> */}
                                    <Row>
                                        {
                                            global.modules && global.modules.some(m => m === 'OneCardByType') ?
                                                <Col className="col-3">
                                                    <Row className="mt-4">
                                                        <Col>
                                                            <h5><FormattedMessage id="MarketingAlert.CardType" /></h5>
                                                        </Col>
                                                    </Row>
                                                    <FormGroup row>
                                                        <Col>
                                                            <Row className="px-3">
                                                                <Col className="px-1 mb-2">
                                                                    <CustomSelect
                                                                        options={loyaltyTypes}
                                                                        value={loyaltyTypes.find(tp => tp.value === alert.loyaltyCardTypeConfigCode) || ''}
                                                                        isClearable={true}
                                                                        placeholder={''}
                                                                        isLoading={cardTypesBlock}
                                                                        onChange={handleCardType}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                :
                                                ''
                                        }
                                        {
                                            // alertType === 'LoyaltyCardLevelUp'
                                            alert.alertType === getAlertType()[3].value ?
                                                <Col className="col-3">
                                                    <Row className="mt-4">
                                                        <Col>
                                                            <h5><FormattedMessage id="MarketingAlert.LoyaltyCardLevel" /></h5>
                                                        </Col>
                                                    </Row>
                                                    <FormGroup row>
                                                        <Col>
                                                            <Row className="px-3">
                                                                <Col className="px-1 mb-2">
                                                                    <CustomSelect
                                                                        options={loyaltyCardLevels.filter(lvl => lvl.loyaltyCardTypeConfigCode === alert.loyaltyCardTypeConfigCode)}
                                                                        value={loyaltyCardLevels.find(tp => tp.value === alert.loyaltyCardLevelConfigCode) || ''}
                                                                        isClearable={true}
                                                                        placeholder={''}
                                                                        isLoading={loyaltyCardLevelsBlock}
                                                                        onChange={(e) => this.handleCardLevel(e)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                :
                                                ''
                                        }
                                    </Row>
                            
                            {
                                hasVoucherAccess() ?
                                    <VoucherConfiguration
                                        alert={alert}
                                        handleChange={handleChange}
                                        handleVoucherChange={handleVoucherChange}
                                        handleVoucherEndDate={this.handleVoucherEndDate}
                                    />
                                    :
                                    <div />
                            }
                        </div>
                    :
                    alert.alertType === 'FormSubmit' ?
                        <div>
                            <Row className="mt-4">
                                <Col>
                                    <h5><FormattedMessage id="MarketingAlert.Provider" /></h5>
                                </Col>
                            </Row>
                            <FormGroup row className="px-3" >
                                {selectedAlertType && selectedAlertType.fieldType.map((provider, key) =>
                                    <Col className="px-1 mb-2 col-3" key={key}>
                                        <Card className="border-0 shadow pointer py-3" body
                                            style={alert && alert.source && alert.source === provider.value ? { backgroundColor: '#d3eaff' } : {}}
                                            onClick={() => this.handleAlertSource(provider.value)}
                                        >
                                            {provider.label}
                                        </Card>
                                    </Col>
                                )}
                            </FormGroup>
                            {alert.source && alert.source === 'PNForm' &&
                                <Row className="mt-4">
                                    <Col className='col-2'>
                                        <h5><FormattedMessage id="MarketingAlert.Form" /></h5>
                                    </Col>
                                </Row>
                            }
                            {alert.source && alert.source === 'PNForm' &&
                                <Row>
                                    <Col className="px-4 col-4">
                                        <CustomSelect
                                            options={pnFormsList}
                                            isSearchable
                                            isLoading={pnBlock}
                                            placeholder={''}
                                            onChange={this.props.handleSelect.bind(this, "formId")}
                                            value={pnFormsList.find(el => el.value == alert.formId)}
                                            enableRequired
                                            required
                                        />
                                    </Col>
                                </Row>
                            }
                            {
                                global.modules && global.modules.some(m => m === 'HeyCard') && hasVoucherAccess() ?
                                    <VoucherConfiguration
                                        alert={alert}
                                        handleChange={handleChange}
                                        handleVoucherChange={handleVoucherChange}
                                        handleVoucherEndDate={this.handleVoucherEndDate}
                                    />
                                    :
                                    <div />
                            }
                        </div>
                    :''
                : ''}
            </BlockUi>
        );
    }
}
export default injectIntl(AlertStep1)