import React, { Component } from 'react';
import CustomBlockUI from '../../Base/CustomBlockUI';
import { createPortal } from 'react-dom';

class SalesProcessGridSpaceCell extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tooltip: false,
        };
    }

    componentDidMount(){
        document.getElementById('MainContentCoolDataGrid').addEventListener('scroll', this.handleParentScroll);
    }

    componentWillUnmount() {
        try {
            document.getElementById('MainContentCoolDataGrid').removeEventListener('scroll');
        } catch (error) { }
    }

    handleParentScroll = () => {
        if(this.state.tooltip){
            this.setState({ tooltip: false });
        }
    }

    toggleTooltip = () => {
        const { dealList, d, r, hotelId } = this.props;
        this.setState({ tooltip: !this.state.tooltip }, () => {
            if(this.state.tooltip && dealList?.length){
            }
        });
    }

    getLowestOrderStatusColor = (events) => {
        const { colorsByStatus } = this.props;
        const statusList = events.map(({pmsStatus}) => pmsStatus);
        const sortedStatus = statusList
            .map(status => colorsByStatus.find(c => c.status === status))
            .filter(Boolean)
            .sort((a, b) => a.order - b.order);
        return sortedStatus.length ? sortedStatus[0].color : '#FFFFFF';
    }

    render() {
        const { slotOcc, blockSpaces, hotelId, customClasses, dealList, d, r, description } = this.props;
        const { tooltip } = this.state;
        
        const filterIsDeal = ({isDeal}) => isDeal;
        
        return (
            <CustomBlockUI blocking={!slotOcc || blockSpaces[hotelId]}>
                <div style={{ border: '1px solid', borderColor: 'transparent' }} className={'py-1 w-100 h-100 d-flex align-items-center justify-content-center ' + customClasses} onClick={() => this.props.toggleFocusedDeals(null, false)}>
                    {slotOcc ?
                        <>
                            <div className={"w-100 h-100 d-flex align-items-center"} style={{ gap: '5%' }} onClick={this.toggleTooltip} id={`space-daily-cell-${d}-${r.data.id}-${hotelId}`}>
                                <div className="h-100"
                                    style={{
                                        width: '25%',
                                        borderRadius: '3px',
                                        border: slotOcc[0]?.value > 0 ? `1px solid ${this.getLowestOrderStatusColor(slotOcc[0]?.events)}` : '',
                                        background:
                                            slotOcc[0]?.events?.filter(filterIsDeal)?.length ?
                                                this.getLowestOrderStatusColor(slotOcc[0]?.events)
                                            : slotOcc[0]?.value > 0 ?
                                                `repeating-linear-gradient(45deg, ${this.getLowestOrderStatusColor(slotOcc[0]?.events)}, ${this.getLowestOrderStatusColor(slotOcc[0]?.events)} 4px, #ffffff 5px, #ffffff 9px)`
                                                // 'repeating-linear-gradient(45deg, #f38375, #f38375 4px, #ffffff 5px, #ffffff 9px)'
                                            :''
                                    }}
                                />
                                <div className="h-100"
                                    style={{
                                        width: '25%',
                                        borderRadius: '3px',
                                        border: slotOcc[1]?.value > 0 ? `1px solid ${this.getLowestOrderStatusColor(slotOcc[1]?.events)}`:'',
                                        background:
                                            slotOcc[1]?.events?.filter(filterIsDeal)?.length ?
                                                this.getLowestOrderStatusColor(slotOcc[1]?.events)
                                            : slotOcc[1]?.value > 0 ?
                                                `repeating-linear-gradient(45deg, ${this.getLowestOrderStatusColor(slotOcc[1]?.events)}, ${this.getLowestOrderStatusColor(slotOcc[1]?.events)} 4px, #ffffff 5px, #ffffff 9px)`
                                            :''
                                    }}
                                />
                                <div className="h-100"
                                    style={{
                                        width: '25%',
                                        borderRadius: '3px',
                                        border: slotOcc[2]?.value > 0 ? `1px solid ${this.getLowestOrderStatusColor(slotOcc[2]?.events)}`:'',
                                        background:
                                            slotOcc[2]?.events?.filter(filterIsDeal)?.length ?
                                                this.getLowestOrderStatusColor(slotOcc[2]?.events)
                                            : slotOcc[2]?.value > 0 ?
                                                `repeating-linear-gradient(45deg, ${this.getLowestOrderStatusColor(slotOcc[2]?.events)}, ${this.getLowestOrderStatusColor(slotOcc[2]?.events)} 4px, #ffffff 5px, #ffffff 9px)`
                                            :''
                                    }}
                                />
                                <div className="h-100"
                                    style={{
                                        width: '25%',
                                        borderRadius: '3px',
                                        border: slotOcc[3]?.value > 0 ? `1px solid ${this.getLowestOrderStatusColor(slotOcc[3]?.events)}`:'',
                                        background:
                                            slotOcc[3]?.events?.filter(filterIsDeal)?.length ?
                                                this.getLowestOrderStatusColor(slotOcc[3]?.events)
                                            : slotOcc[3]?.value > 0 ?
                                                `repeating-linear-gradient(45deg, ${this.getLowestOrderStatusColor(slotOcc[3]?.events)}, ${this.getLowestOrderStatusColor(slotOcc[3]?.events)} 4px, #ffffff 5px, #ffffff 9px)`
                                            :''
                                    }}
                                />
                            </div>
                            {tooltip && dealList?.filter(filterIsDeal)?.length ?
                                (() => {
                                    const element = document.getElementById(`space-daily-cell-${d}-${r.data.id}-${hotelId}`);
                                    const rect = element.getBoundingClientRect();
                                    
                                    return createPortal(
                                        <div style={{
                                            position: 'absolute',
                                            zIndex: '999',
                                            top: (rect.top - 80),
                                            left: (rect.left - 85),
                                            background: 'blue',
                                            width: "250px",
                                            background: "#767679",
                                            color: "white",
                                            padding: "0.4rem",
                                            fontSize: "0.9em",
                                            borderRadius: "4px"
                                        }}>
                                            <div className='text-center'>
                                                <div className=''>
                                                    <b>{d}</b>
                                                </div>
                                                <div className='mt-1'>
                                                    {dealList.filter(filterIsDeal).map((deal, key) =>
                                                        <div key={key} className='d-flex align-items-center justify-content-between text-truncate'>
                                                            <div className='text-truncate' title={deal.name}>
                                                                <span className='mr-1'>
                                                                    <b>
                                                                        ({deal.pmsStatus})
                                                                    </b>
                                                                </span>
                                                                <span>{deal.name}</span>
                                                            </div>
                                                            <div className='ml-2'>
                                                                <span>{deal.start}h</span>
                                                                <span className='mx-1'>-</span>
                                                                <span>{deal.end}h</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    , document.body);
                                })()
                            :''}
                        </>
                    :
                        <div style={{ visibility: 'hidden' }}>
                            -
                        </div>
                    }
                </div>
            </CustomBlockUI>
        );
    }
}

export default SalesProcessGridSpaceCell;
