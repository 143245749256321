import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, CardBody, Col, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown, UncontrolledTooltip } from 'reactstrap';
import { StyledCard } from "../Base/CommonUIComponents";
import { getAPI, postAPI } from "../Base/API";
import { handleNotification } from "../Base/Notification";
import CustomSelect from "../Base/CustomSelect";
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import BlockUi from 'react-block-ui';
import { CampaignStats } from './Common/MarketingFunctions';
import { getMarketingStatisticsStatus, getMarketingType, getSenderChannelOptions } from '../Base/ReferenceDataFunctions';
import { Doughnut, Line } from 'react-chartjs-2';
import Authorization from '../Base/Authorization';
import { TagAssociation } from './TagAssociation';
import StatsPerTemplate from './Common/StatsPerTemplate';

class CommunicationStats extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            blockStats: false,
            blockTemplateDetails: false,
            activeTab: '0',
            startDate: moment().subtract(15, 'days'),
            endDate: moment(),
            group: 'Day',
            chartData: { labels: [], datasets: [] },
            pieData: { labels: [], datasets: [] },
            campaign: null,
            isOpen: false,
            allTemplates: null,
            percentageChart: { labels: [], datasets: [] },
            percentagePie: { labels: [], datasets: [] },
            showInPercentage: false,
            isRecurring: false,
            associationTagModal: false,
            urls: [],
            hasEgoi: false
        };
    }

    componentDidMount() {
        window.setTimeout(
            () => {
                this.setState({
                    hasEgoi: global.modules && global.modules.some(m => m === 'Egoi')
                });
            },
            global.modules ? 0 : 2500
        );

        if (this.props.match.params.id) {
            this.getStats();
            this.getTemplateStats();
        }
    }

    getStats = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                this.buildChartData(data.response);
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/marketingstatistics?fromDate=${moment(this.state.startDate).format("YYYY-MM-DD")}&toDate=${moment(this.state.endDate).format("YYYY-MM-DD")}&group=${this.state.group}&campaignId=${this.props.match.params.id}`);
    }

    getTemplates = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.buildChartData(data.response);
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/marketingstatistics?fromDate=${moment(this.state.startDate).format("YYYY-MM-DD")}&toDate=${moment(this.state.endDate).format("YYYY-MM-DD")}&group=${this.state.group}&campaignId=${this.props.match.params.id}`);
    }

    calculateKpi = (e) => {
        e.preventDefault();
        const { campaign, isRecurring } = this.state;

        this.setState({ blockStats: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockStats: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ blockStats: false });
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="CustomCampaign.CampaignStatsCalculated" />, <FormattedMessage id="generic.success" />);

                    this.setState({ blockStats: false }, this.getTemplateStats );
                    return;
                }
            }
            this.setState({ blockStats: false });
        }, `/api/gms/Marketing/v1/MarketingSendKPIs?id=${campaign && campaign.id}&isRecurring=${isRecurring}`);
    }

    buildChartData = (data) => {
        const marketingRun = data && data.length > 0 ? data[0].marketingRun : null;
        const commStatus = getMarketingStatisticsStatus(this.props.intl);
        let total = new Map()

        let chartData = { labels: [], datasets: [] };
        let pieData = { labels: [], datasets: [{ label: 'Total', data: [], backgroundColor: commStatus.map(el => (el.color)) }] };

        //Dates
        var formatDate = 'YYYY-MM-DD', type = 'days';

        if (this.state.group === 'Month') {
            formatDate = 'YYYY-MMM'; type = 'months';
        }

        var dates = Array(moment(this.state.endDate).diff(moment(this.state.startDate), type)).fill(undefined)
            .reduce((acc, cur) => {
                acc.push(moment(this.state.startDate).add(acc.length, type).format(formatDate));

                return acc;
            }, [moment(this.state.startDate).format(formatDate)]);

        chartData.labels = dates;



        //datasets
        commStatus.forEach(action => {
            chartData.datasets.push({ value: action.value, label: action.label, data: Array(chartData.labels.length).fill(0), backgroundColor: action.color, borderColor: action.color, fill: false });

            total.set(action.value, 0);
        })

        //Setup
        marketingRun && Object.keys(marketingRun).forEach(el => {
            const element = marketingRun[el];
            const date = moment(el).format(formatDate);
            const index = chartData.labels.findIndex(d => date === d);

            if (index !== -1) {
                Object.keys(marketingRun[el]).forEach(status => {
                    const datasetIndex = chartData.datasets.findIndex(el => el.value === status);

                    if (datasetIndex !== -1) {
                        chartData.datasets[datasetIndex].data[index] = element[status];

                        total.set(status, total.get(status) + element[status]);
                    }
                })
            }
        })


        pieData.labels = [...total.keys()];
        pieData.labels.forEach(item => {
            pieData.datasets[0].data.push(total.get(item));
        });

        pieData.labels = pieData.labels.map((item, idx) => {
            let percentage = 0;


            if (idx === 0 && pieData.datasets[0].data[0]) {
                percentage = 100;
            }
            else {
                const dataIdx = this.getStatusIndex('sent');
                const divideBy = pieData.datasets[0].data[dataIdx];
                const curValue = pieData.datasets[0].data[idx];

                if (divideBy) {
                    percentage = parseFloat((curValue * 100) / divideBy).toFixed(2).replace(/[.,]00$/, "");
                } else percentage = '-';
            }

            return this.props.intl.formatMessage({ id: `ReferenceDataFunctions.${item}` }) + `${pieData.datasets[0].data[idx] ? ' ' + pieData.datasets[0].data[idx] + ' -' : ''} (${percentage}%)`;
        });



        //Percentages
        const percentageChart = this.getChartPercentages(chartData);
        const percentagePie = this.getPieChartPercentages(pieData);


        this.setState({ chartData, percentageChart, pieData, percentagePie })
    }

    getStatusIndex = (value) => {
        return getMarketingStatisticsStatus(this.props.intl).findIndex(mss => mss.value === value);
    }

    getChartPercentages = (chartData) => {
        let percentageChart = {
            labels: chartData.labels, datasets: [
                { value: 'Errors', label: this.props.intl.formatMessage({ id: "MarketingFunctions.Errors" }), data: Array(chartData.labels.length).fill(0), backgroundColor: "#F16D84", borderColor: "#F16D84", fill: false },
                { value: 'Viewed', label: this.props.intl.formatMessage({ id: "MarketingFunctions.Viewed" }), data: Array(chartData.labels.length).fill(0), backgroundColor: "#CF53FA", borderColor: "#CF53FA", fill: false },
                { value: 'Clicked', label: this.props.intl.formatMessage({ id: "MarketingFunctions.Clicked" }), data: Array(chartData.labels.length).fill(0), backgroundColor: "#0665ff", borderColor: "#0665ff", fill: false },
                { value: 'Removed', label: this.props.intl.formatMessage({ id: "MarketingFunctions.Removed" }), data: Array(chartData.labels.length).fill(0), backgroundColor: "#7054E5", borderColor: "#7054E5", fill: false }
            ]
        };

        chartData.labels.forEach((date, idx) => {
            const sent = chartData.datasets[this.getStatusIndex('sent')].data[idx];
            const bounced = chartData.datasets[this.getStatusIndex('bounced')].data[idx];
            const hardBounced = chartData.datasets[this.getStatusIndex('hardBounced')].data[idx];
            const softBounced = chartData.datasets[this.getStatusIndex('softBounced')].data[idx];
            const abused = chartData.datasets[this.getStatusIndex('rejected')].data[idx];
            const failed = chartData.datasets[this.getStatusIndex('failed')].data[idx];

            const totalErrors = (bounced + hardBounced + softBounced) + abused + failed;

            if (sent && totalErrors) {
                percentageChart.datasets[0].data[idx] = (totalErrors * 100 / sent).toFixed();  //Errors
                percentageChart.datasets[1].data[idx] = (chartData.datasets[this.getStatusIndex("viewed")].data[idx] * 100 / sent).toFixed(); //Viewed
                percentageChart.datasets[2].data[idx] = (chartData.datasets[this.getStatusIndex("clicked")].data[idx] * 100 / sent).toFixed(); //Clicked
                percentageChart.datasets[3].data[idx] = (chartData.datasets[this.getStatusIndex("removed")].data[idx] * 100 / sent).toFixed(); //Removed
            }
        })

        return percentageChart;
    }

    getPieChartPercentages = (pieData) => {
        let percentagePie = {
            labels: [this.props.intl.formatMessage({ id: "MarketingFunctions.Errors" }), this.props.intl.formatMessage({ id: "MarketingFunctions.Viewed" }), this.props.intl.formatMessage({ id: "MarketingFunctions.Clicked" }), this.props.intl.formatMessage({ id: "MarketingFunctions.Removed" })],
            datasets: [ { backgroundColor: ["#F16D84", "#CF53FA", "#0665ff", "#7054E5"], data: [0, 0, 0, 0] } ]
        };

        const sent = pieData.datasets[0].data[this.getStatusIndex('sent')];
        const bounced = pieData.datasets[0].data[this.getStatusIndex('bounced')];
        const hardBounced = pieData.datasets[0].data[this.getStatusIndex('hardBounced')];
        const softBounced = pieData.datasets[0].data[this.getStatusIndex('softBounced')];
        const abused = pieData.datasets[0].data[this.getStatusIndex('rejected')];
        const failed = pieData.datasets[0].data[this.getStatusIndex('failed')];

        const totalErrors = (bounced + hardBounced + softBounced) + abused + failed;

        if (sent && totalErrors) {
            percentagePie.datasets[0].data[0] = (totalErrors * 100 / sent).toFixed();  //Errors
            percentagePie.datasets[0].data[1] = (pieData.datasets[0].data[this.getStatusIndex("viewed")] * 100 / sent).toFixed(); //Viewed
            percentagePie.datasets[0].data[2] = (pieData.datasets[0].data[this.getStatusIndex("clicked")] * 100 / sent).toFixed(); //Clicked
            percentagePie.datasets[0].data[3] = (pieData.datasets[0].data[this.getStatusIndex("removed")] * 100 / sent).toFixed(); //Removed
        }

        return percentagePie;
    }

    getTemplateStats = () => {
        this.setState({ blockStats: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockStats: false, isRecurring: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    this.setState({ campaign: data.response[0], blockStats: false, error: errorMessage, isRecurring: getMarketingType().some(type => type.value === data.response[0].marketingType) });
                }
                else {
                    this.setState({ campaign: null, blockStats: false, error: errorMessage, isRecurring: false });
                }
            }
            else {
                this.setState({ error: errorMessage, blockStats: false, isRecurring: false });
            }
        }, `/api/gms/Marketing/v1/marketingsend/${this.props.match.params.id}`);
    }

    downloadChartImg = (e) => {
        e.preventDefault()

        var lineChart = document.getElementById("lineChart");
        var pieChart = document.getElementById("pieChart");

        const width = lineChart.clientWidth + pieChart.clientWidth + 200
        const height = lineChart.clientHeight + 100;


        var canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        var ctx = canvas.getContext("2d");
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, width, height);

        ctx.drawImage(lineChart, 50, 50, lineChart.clientWidth, lineChart.clientHeight)
        ctx.drawImage(pieChart, lineChart.clientWidth + 90, 50)


        var image = canvas.toDataURL();
        var anchor = document.createElement("a");

        anchor.href = image;
        anchor.download = `${this.state.campaign?.name}.png`;
        anchor.click();
    }

    toggle(val) { this.setState({ activeTab: val }) }

    doSearch = () => {
        this.getStats();
    }

    handleSelect = (combo, name) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    handleCollapse = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    }

    toggleChartView = () => {
        this.setState(prevState => ({
            showInPercentage: !prevState.showInPercentage
        }));
    }

    recalculateStatistics = () => {
        this.setState({ blockStats: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockStats: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    this.setState({ blockStats: false }, () => handleNotification(data, <FormattedMessage id="StatsEgoi.Recalculating"/>, <FormattedMessage id="generic.success"/>));
                    return;
                }
                this.setState({ error: errorMessage, blockStats: false });
            }, `/api/gmsscheduletask/CampaignsFeedback/RunCampaignFeedback/${this.props.match.params.id}`);
        });
    }

    handleAssociationTagModal = (_, urls) => {
        this.setState(prevState => ({ associationTagModal: !prevState.associationTagModal, urls }));
    }

    getUrl = (url) => {
        const urlOption = {
            value: url.url,
            label: url.url
        };
        return urlOption;
    }

    render() {
        const { campaign, showInPercentage, percentageChart, percentagePie, isRecurring, associationTagModal, urls, hasEgoi } = this.state;

        const groupBy = [
            { value: 'Day', label: <FormattedMessage id="StatsEgoi.Day" /> },
            { value: 'Month', label: <FormattedMessage id="StatsEgoi.Month" /> },
        ]

        const optionsLine = {
            type: 'line',
            maintainAspectRatio: false,
            legend: {
                display: true,
                position: "top"
            },
            scales: {
                x: { display: true },
                y: {
                    beginAtZero: true, min: 0, max: (showInPercentage ? 100 : undefined),
                    grid: {
                        display: false
                    },
                }
            },
            plugins: {
                datalabels: { display: false }
            }
        }

        const optionsPie = {
            cutout: '60%',
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: true,
                    position: "left"
                },
                datalabels: {
                    color: 'white',
                    formatter: (ctx, data) => {
                        return ctx ? ctx : '';
                    }
                },
            }
        }

        return (
            <StyledCard error={this.state.error} >
                <BlockUi tag="div" blocking={this.state.blockStats}>
                    {this.state.campaign ?
                        <>
                            <TagAssociation campaignId={this.state.campaign.id} modal={associationTagModal} toggleModal={this.handleAssociationTagModal} urls={urls} />                            
                            <Card className="border-0 shadow px-3">
                                <Row className="mt-3 align-items-center">
                                    <Col sm={2}>
                                    </Col>
                                    <Col className="text-center">
                                        <h5>{campaign.name} </h5>
                                    </Col>
                                    <Col className="text-right col-2">
                                        {
                                            isRecurring ?
                                                moment(moment(campaign.startDate).format("YYYY-MM-DD HH:mm")).isBefore(moment().format("YYYY-MM-DD HH:mm")) ?
                                                    <Authorization
                                                        perform="campaigns:calculateStats"
                                                        yes={() => (
                                                            <>
                                                                <Button className="btn btn-host btn-sm" id="calculateKpi" onClick={(e) => this.calculateKpi(e, campaign.id)}>
                                                                    <i className="fas fa-calculator"></i>
                                                                </Button>

                                                                <UncontrolledTooltip target="calculateKpi" placement="bottom">
                                                                    <FormattedMessage id="CustomCampaign.CalculateStats" />
                                                                </UncontrolledTooltip>
                                                            </>
                                                        )}
                                                        no={() => <span />}
                                                    />
                                                    :
                                                    ''
                                                :
                                                moment(moment(campaign.scheduleDay).format("YYYY-MM-DD HH:mm")).isBefore(moment().format("YYYY-MM-DD HH:mm")) ?
                                                    <>
                                                        <Button className="btn btn-host btn-sm" id="expandTemplateDetails" onClick={this.handleCollapse} >
                                                            <i className="fas fa-expand-arrows-alt" />
                                                        </Button>
                                                        <UncontrolledTooltip target="expandTemplateDetails" placement="bottom">
                                                            <FormattedMessage id="Campaigns.ExpandDetailsStats" />
                                                        </UncontrolledTooltip>
                                                        <UncontrolledButtonDropdown>
                                                            <DropdownToggle className="btn btn-host btn-sm ml-2" style={{ background: 'none', border: 'none', color: 'unset', padding: 'unset', width: '10px' }}>
                                                                <i className="fas fa-ellipsis-v" />
                                                            </DropdownToggle>
                                                            <DropdownMenu right>
                                                                <DropdownItem onClick={(e) => this.calculateKpi(e, campaign.id)}>
                                                                    <i className="fas fa-calculator mr-2" />
                                                                    <FormattedMessage id="CustomCampaign.CalculateStats" />
                                                                </DropdownItem>
                                                                <DropdownItem onClick={_ => this.handleAssociationTagModal(_, campaign.marketingSendKpis !== null ? campaign.marketingSendKpis.filter(msk => msk.marketingSendUrlKpis !== null).flatMap(msk => msk.marketingSendUrlKpis).map(this.getUrl) : [])}>
                                                                    <i className="fas fa-tag mr-2" />
                                                                    <FormattedMessage id="CustomCampaign.AssociateTags" />
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>                                                        
                                                    </>
                                                    :
                                                    ''
                                        }
                                    </Col>
                                </Row>

                                {campaign.marketingSendKpis && !isRecurring ?
                                    <>
                                        <CampaignStats
                                            marketingSendKpis={campaign.marketingSendKpis}
                                            styles={{ display: 'flex', justifyContent: 'space-around', padding: '20px 30%' }}
                                            isSmsEgoi={hasEgoi && campaign.senderChannel === getSenderChannelOptions()[1].value}
                                        />
                                    </>
                                : <div className="text-center my-5"> <FormattedMessage id="Campaigns.NoStatsAvailable" /> </div>}


                                <Collapse isOpen={this.state.isOpen} >
                                    <StatsPerTemplate
                                        campaignType={this.state.campaign && this.state.campaign.senderChannel && this.state.campaign.senderChannel.toLowerCase()}
                                        marketingSendKpis={campaign.marketingSendKpis}
                                    />
                                </Collapse>
                            </Card>
                        </>
                        : ''}   
                </BlockUi>

                <BlockUi tag="div" blocking={this.state.block}>
                    <Row className="align-items-centes mt-4 mb-3">
                        <Col className="col-2">
                            <CustomSelect
                                options={groupBy}
                                isClearable
                                isSearchable
                                placeholder={<FormattedMessage id="StatsEgoi.GroupBy" />}
                                onChange={(e) => this.handleSelect(e, "group")}
                                value={groupBy.find(c => c.value === this.state.group)}
                            />
                        </Col>
                        <Col sm={3}>
                            <DateRangePicker
                                startDate={this.state.startDate}
                                startDateId="startDate"
                                isOutsideRange={day => false}
                                endDate={this.state.endDate}
                                endDateId="endDate"
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                            />
                        </Col>

                        <Col className="text-right mt-2">
                            <Authorization
                                perform="admin:view"
                                yes={() => (
                                    <>
                                        <Button className=" btn btn-host btn-sm mr-2" onClick={this.recalculateStatistics} id="recalcStats">
                                            <i className={"fas fa-sync"}/>
                                        </Button>
                                        <UncontrolledTooltip placement="bottom" target="recalcStats">
                                            <FormattedMessage id="CommunicationStats.recalcStats"/>
                                            </UncontrolledTooltip>
                                        </>
                                    )}
                                no={() => <span />}
                            />

                            <a className=" btn btn-host btn-sm text-white mr-2" id="downloadbtn" onClick={this.downloadChartImg} >
                                <i className="fas fa-file-download "></i>
                            </a>

                            <Button className=" btn btn-host btn-sm mr-2" onClick={this.toggleChartView} id="chartsView">
                                <i className={showInPercentage ? "fas fa-hashtag" : "fas fa-percent"} />
                            </Button>
                            <UncontrolledTooltip placement="bottom" target="chartsView">
                                {showInPercentage ? <FormattedMessage id="CommunicationStats.SeeAbsoluteValues" /> : <FormattedMessage id="CommunicationStats.SeePercentage" />}
                            </UncontrolledTooltip>

                            <Button className=" btn btn-host btn-sm mr-3" onClick={this.doSearch}>
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="col-7">
                            <Card className="shadow border-0 h-100">
                                <CardBody >
                                    <span className="text-muted d-flex">
                                        <h5 className="pr-1"><FormattedMessage id="CommunicationStats.CommunicationStats" /> </h5> {showInPercentage ? ' (%)' : ''}
                                    </span>
                                    <div>
                                        <Line id="lineChart" data={showInPercentage ? percentageChart : this.state.chartData} height={310} options={optionsLine} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="col-5">
                            <Card className="shadow border-0 h-100">
                                <CardBody>
                                    <span className="text-muted d-flex">
                                        <h5 className="pr-1">Total </h5> {showInPercentage ? ' (%)' : ''}
                                    </span>
                                    <div className="pt-4" >
                                        <Doughnut id="pieChart" data={showInPercentage ? percentagePie : this.state.pieData} height={250} options={optionsPie} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </BlockUi>

            </StyledCard>
        );
    }
}
export default injectIntl(CommunicationStats)
