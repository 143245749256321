import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Card, Col, Row, Nav, NavItem, NavLink } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import profileNow from '../../../img/profileNow.png';
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import SearchForm from '../../Forms/SearchForm/SearchForm';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../../Base/ErrorAlert';
import PNFormAnalytics from './PNFormAnalytics';
import PNForms from './PNForms';

class ProfileNowMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            activeTab: 'pNForms',
            tabs: [
                { name: 'pNForms', title: 'ProfileNowMenu.Forms', icon: null },
                { name: 'searchForm', title: 'SearchForm.SearchForm', icon: 'fas fa-file-signature' },
                { name: 'stats', title: 'ProfileNowMenu.Stats', icon: 'far fa-chart-bar' },
            ]
        };
    }

    componentDidMount() {
        this.getPNForms();
    }

    getPNForms = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                if (data.response?.length > 0) {
                    const formsWithAnalytics = [];

                    data.response.forEach(el => {
                        if (el.hasAnalytics) {
                            formsWithAnalytics.push({ value: el.id, label: el.name });
                        }
                    });

                    this.setState({ formsWithAnalytics });
                }

                this.getReviewMappings(data.response);
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Survey/v1/survey`);
    }

    getReviewMappings = (pnForms) => {
        if (pnForms) {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, pnForms, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    if (data.response && data.response.length > 0) {
                        pnForms.forEach(el => {
                            el.isMapped = data.response.some(rm => rm.formId == el.id);
                        })
                    }

                    this.setState({ pnForms, block: false });
                }
                else this.setState({ block: false, pnForms });
            }, `/api/gms/Review/v1/reviewMapping/PNForm`);
        }
        else {
            this.setState({ pnForms: pnForms || [], block: false });
        }
    }


    updatePnForms = (form, toDelete) => {
        const { pnForms } = this.state;
        const index = pnForms.findIndex(el => el.id === form.id);

        if (index !== -1) {
            if (toDelete) {
                pnForms.splice(index, 1);
            }
            else {
                pnForms[index] = form;
            }

            this.setState({ PNForms })
        }
    }

    render() {
        const { block, error, pnForms, tabs, activeTab } = this.state;
        
        return (
            <div className="border-0 h-100 py-2">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <Row>
                        <Col className='col-12 d-flex align-items-start pr-0'>
                            <div style={{ width: '15%' }}>
                                <Nav tabs vertical className='border-0'>
                                    <Card className="bg-white p-2 border-0 shadow" style={{ borderRadius: '5px', height: '80vh' }}>
                                        <div className='d-flex align-items-center mb-4 p-2'>
                                            <h5>
                                                <img className='mr-2' alt="" src={profileNow} height="18px"/>
                                                <FormattedMessage id="ProfileNowMenu.Title"/>
                                            </h5>
                                        </div>
                                        {tabs && tabs.map((tab, key) => {
                                            const isActive = activeTab === tab.name;

                                            return (
                                                <NavItem className="pb-2" key={key}>
                                                    <NavLink onClick={_ => this.setState({ activeTab: tab.name })} className="pointer border-0" style={{ width: 'fit-content' }}>
                                                        {isActive ?
                                                            <div style={{
                                                                'left': '0',
                                                                'position': 'absolute',
                                                                'borderRadius': '0px 30px 30px 0px',
                                                                'width': '7px',
                                                                'height': '14px',
                                                                'background': '#0667FF 0% 0% no-repeat padding-box',
                                                                'opacity': '1',
                                                                'marginTop': '2px'
                                                            }} />
                                                        : ''}
                                                        <div id={`tab-${key}`} className={(isActive ? 'text-primary ' : '') + 'd-flex align-items-center justify-content-start'}>
                                                            {tab.icon ? <i className={`${tab.icon} mr-2`} /> : <img className='mr-2' alt="" src={profileNow} height="18px" style={{ filter: isActive ? '' : 'grayscale(100%) brightness(0)' }} />}
                                                            <FormattedMessage id={tab.title} />
                                                        </div>
                                                    </NavLink>
                                                </NavItem>
                                            )
                                        }
                                        )}
                                    </Card>
                                </Nav>
                            </div>
                            <div style={{ width: '85%' }}>
                                {activeTab === 'pNForms' && !block ?
                                    <Col>
                                        <PNForms
                                            pnForms={pnForms}
                                            history={this.props.history}
                                            updatePnForms={this.updatePnForms}
                                        />
                                    </Col>
                                : activeTab === 'searchForm' ?
                                    <Col>
                                        <SearchForm 
                                            fromPnForms={true}
                                            history={this.props.history}
                                        />
                                    </Col>
                                        : activeTab === 'stats' ?
                                            <Col>
                                                <PNFormAnalytics
                                                    formsWithAnalytics={this.state.formsWithAnalytics}
                                                />
                                            </Col>
                                : ''}
                            </div>
                        </Col>
                    </Row>
                </BlockUi>
            </div>
        );
    }
}
export default injectIntl(ProfileNowMenu)
