import React, { Component, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Row, Col, Card } from 'reactstrap';
import { postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { BlankCard, StyledModal } from '../../Base/CommonUIComponents';


export const CancelProposalsModal = ({ block, error, toggleModal, modal, dealProposals, getDealProposals, updateDealProposal }) => {
    const [hotelProposals, setHotelProposals] = useState([]);
    const [blockUi, setBlockUi] = useState(false);

    useEffect(() => {
        if (dealProposals === null) {
            getDealProposals();
        }
        else if (dealProposals?.length > 0) {
            organizeProposals(dealProposals);
        }
        else {
            toggleModal();
        }
    }, [dealProposals])

    const cancelProposal = (proposalId, hotelId) => {
        setBlockUi(true);

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                setBlockUi(false);
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    setBlockUi(false);
                    return;
                }
                if (data.data?.length > 0) {
                    handleNotification(data, <FormattedMessage id="SalesDeal.ProposalCanceled" />, <FormattedMessage id="generic.success" />);

                    updateDealProposal(proposalId, data.data[0]);
                }
                setBlockUi(false);

                return;                  
            }
            else
                setBlockUi(false);
        }, `/api/gms/SalesProcess/v1/Proposal/${proposalId}/cancel?hotelId=${hotelId}`)
    }

    const organizeProposals = (dealProposals) => {
        const hotelProposals = [];

        dealProposals.forEach(proposal => {
            const hotelIndex = hotelProposals.findIndex(el => el.hotelId === proposal.hotelId);

            const hotel = hotelIndex !== -1 ?
                hotelProposals[hotelIndex]
                :
                {
                    hotelId: proposal.hotelId,
                    hotelName: global.hotelList?.find(({ value }) => value === proposal.hotelId)?.label,
                    groupReservations: [],
                    proposals: []
                }


            if (proposal.groupResExternalId) { //Group Reservations
                const groupIndex = hotel.groupReservations.findIndex(el => proposal.groupResExternalId === el.groupResExternalId);

                if (groupIndex !== -1) {
                    if (proposal.type === "Block" && !hotel.groupReservations[groupIndex].block) {
                        hotel.groupReservations[groupIndex].block = proposal;
                    }
                    else {
                        hotel.groupReservations[groupIndex].events.push(proposal);
                    }
                }
                else {
                    const newGroup = {
                        externalId: proposal.externalId,
                        groupResExternalId: proposal.groupResExternalId,
                        groupResName: proposal.groupResName,
                        hotelId: proposal.hotelId,
                        block: null,
                        events: [],
                    };

                    if (proposal.type === "Block") {
                        newGroup.block = proposal;
                    }
                    else {
                        newGroup.events.push(proposal);
                    }

                    hotel.groupReservations.push(newGroup);
                }
            }
            else if (proposal.type === "Block" || proposal.type === "Event") { //Proposals
                hotel.proposals.push(proposal)
            }

            //Update hotel proposals
            if (hotelIndex !== -1)
                hotelProposals[hotelIndex] = hotel;
            else
                hotelProposals.push(hotel);
        })

        setHotelProposals(hotelProposals);
    }
    

    return (
        <StyledModal toggleModal={toggleModal} modal={modal} size="lg">
            <BlankCard block={block || blockUi} error={error}>
                <Row className="pb-3">
                    <Col>
                        <h5><FormattedMessage id="SalesDeal.AssociatedProposals"/> </h5>
                    </Col>
                </Row>

                <div >
                    {hotelProposals?.map((hotel, key) =>
                        <Card key={key} className="mb-2 border-0 shadow p-0" body>
                            {hotelProposals.length > 1 ?
                                <div className="d-flex align-items-center p-3 border-bottom" style={{ backgroundColor: '#f3f3f3' }}>
                                    <i className="icon-icon-hotel mr-2" />
                                    {hotel.hotelName || hotel.hotelId}
                                </div>
                                : <div className="" />
                            }

                            <div className={(hotelProposals.length > 1 && 'pl-4 ') + " px-3"}>
                                {hotel.groupReservations?.map((group, grkey) => {
                                    return (
                                        <div key={grkey} className="my-2 border-bottom pl-2 mt-3" body>
                                            <div className="mb-2">
                                                <b className='d-flex align-items-center'>
                                                    <i className={`far fa-object-group mr-2`} />
                                                    {group.groupResName}
                                                </b>
                                            </div>

                                            <div className="pl-3">
                                                {group.block &&
                                                    <Row className=" mb-2 py-2 align-items-center">
                                                        <Col className='d-flex align-items-center col-6'>
                                                            <i className={`fas fa-cube mr-2`} />
                                                            <FormattedMessage id="SalesDeal.Rooms" />
                                                        </Col>
                                                        {group.block.isCancelled ?
                                                            <Col className="col-6 text-danger" style={{ fontSize: '0.85em' }}>
                                                                {group.block.statusDesc}
                                                            </Col>
                                                            :
                                                            <>
                                                                <Col className="col-3">
                                                                    <div className={`text-muted text-truncate`} style={{ fontSize: '0.85em' }}>
                                                                        {group.block.statusDesc}
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-3 pl-0 text-right">
                                                                    <Button className="bg-danger border-danger btn-sm btn-host " onClick={_ => cancelProposal(group.block.id, group.hotelId)}>
                                                                        <FormattedMessage id={`SalesDeal.CancelBlock`} />
                                                                    </Button>
                                                                </Col>
                                                            </>
                                                        }
                                                    </Row>
                                                }


                                                {group.events?.map((event, keyEvent) => {
                                                    const eventData = event.proposalJSON?.data?.[0]?.DetailsEvents?.[0] && event.proposalJSON.data[0].DetailsEvents[0];

                                                    return <Row key={keyEvent} className="mb-2 py-2 align-items-center">
                                                        <Col className='d-flex align-items-center col-6'>
                                                            <i className={`fas fa-theater-masks mr-2`} />
                                                            {eventData.EventName}
                                                        </Col>
                                                        {event.isCancelled ?
                                                            <Col className="col-6 text-danger" style={{ fontSize: '0.85em' }}>
                                                                {event.statusDesc}
                                                            </Col>
                                                            :
                                                            <>
                                                                <Col className="col-3">
                                                                    <div className={`text-muted text-truncate`} style={{ fontSize: '0.85em' }}>
                                                                        {event.statusDesc}
                                                                    </div>
                                                                </Col>
                                                                <Col className="col-3 pl-0 text-right">
                                                                    <Button className="bg-danger border-danger btn-sm btn-host " onClick={_ => cancelProposal(event.id, group.hotelId)}>
                                                                        <FormattedMessage id={`SalesDeal.CancelEvent`} />
                                                                    </Button>
                                                                </Col>
                                                            </>
                                                        }
                                                    </Row>
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}


                                {hotel.proposals?.map((proposal, key) => {
                                    return (
                                        <div key={key} className="mb-2 border-bottom pl-2 py-3" body>
                                            <Row key={key} className="align-items-center">
                                                <Col sm={6}>
                                                    <b className='d-flex align-items-center' style={{ maxWidth: '280px' }}>
                                                        <i className={`fas fa-${proposal.type === 'Block' ? 'cube' : 'theater-masks'} mr-2`}></i>
                                                        <span className='text-truncate'>
                                                            {(proposal.type === 'Block' ? proposal.proposalJSON?.BlockData?.headerData?.[0]?.Code : proposal.proposalJSON?.data?.[0]?.Name) || proposal.externalId}
                                                        </span>
                                                    </b>
                                                </Col>
                                                {proposal.isCancelled ?
                                                    <Col className="col-6 text-danger pl-4" style={{ fontSize: '0.85em' }}>
                                                        {proposal.statusDesc}
                                                    </Col>
                                                    :
                                                    <>
                                                        <Col className="col-3">
                                                            <div className={`${proposal.isCancelled ? 'text-danger' : 'text-muted'} text-truncate pl-2`} style={{ fontSize: '0.85em' }}>
                                                                {proposal.statusDesc}
                                                            </div>
                                                        </Col>
                                                        <Col className="col-3 pl-0 text-right">
                                                            <Button className="bg-danger border-danger btn-sm btn-host " onClick={_ => cancelProposal(proposal.id, proposal.hotelId)}>
                                                                <FormattedMessage id={`SalesDeal.Cancel${proposal.type}`} />
                                                            </Button>
                                                        </Col>
                                                    </>
                                                }
                                            </Row>
                                        </div>
                                    )
                                })}
                            </div>
                        </Card>
                    )}
                </div>
            </BlankCard>
        </StyledModal>
    )
}