
import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getAPI } from '../Base/API';
import { StyledCard } from "../Base/CommonUIComponents";
import { handleNotification } from '../Base/Notification';
import { Button, Col, Row } from 'reactstrap';
import CustomSelect from "../Base/CustomSelect";

export class GoContactQueue extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: true,
            queueList: null,
            topic: ['campaign', 'queue', 'ticket', 'webchat'],
            options: [
                { value: true, label: <FormattedMessage id="GoContactQueue.ActiveOwnwers" /> },
                { value: false, label: <FormattedMessage id="GoContactQueue.ActiveAll" /> }
            ],
            showdeleted : true
        };
    }

    componentDidMount() {
        this.getQueues()
    }

    getQueues = () => {


        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.data) {
                    this.setState({ queueList: data.data[0], block: false });
                }

            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/GoContact/v1/Owners?active=${this.state.showdeleted}`);
    }
    handleSelect = (name, combo) => {
        this.setState({
                [name]: combo ? combo.value : null
        })
    }

    render() {
        const { block, error, queueList, topic, options, showdeleted } = this.state;
        
        return (
            <StyledCard block={block} error={error} icon="far fa-bookmark" title="Queue">

                <Row className="mt-3 mb-4 align-items-center">
                    <Col className="col-3 pr-0">
                        <CustomSelect
                            options={options}
                            value={options.find(m => m.value === showdeleted)}
                            placeholder={''}
                            onChange={this.handleSelect.bind(this, 'showdeleted')}
                            required={true}
                        />
                    </Col>
                    <Col>
                    </Col>
                    <Col className="text-right" >
                        <Button className="mr-3 btn btn-sm btn-host" onClick={this.getQueues} >
                            <i className="fa fa-search" />
                        </Button>
                    </Col>
                </Row>
                {topic.map((e, id) =>
                    
                    <div>
                        <h4 className="my-2">{e}</h4>
                        {queueList && queueList[e].map((el, x) =>
                            <Row>
                                <Col className="col-4">{el.id}</Col>
                            <Col>{el.name}</Col>
                    </Row>
                        )}
                    </div>
                )}
            </StyledCard>
        );
    }
}
export default injectIntl(GoContactQueue)