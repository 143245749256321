import React, { Component } from 'react'
import { Card, Nav, NavItem, NavLink, TabContent, TabPane, PopoverBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import CustomToolTip from '../Base/CustomToolTip';


class ShortSideBar extends Component {
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();

        this.state = {
            isExpanded: false,
            openedMenuOptions: null
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    toggleSideBar = () => {
        this.setState(prevState => ({
            isExpanded: !prevState.isExpanded,
            openedMenuOptions: null
        }));
    }

    onMouseDown = (event, menu) => {
        if (menu.isSelectable) {
            if (event.button === 1) {
                event.preventDefault();
                this.setState({ openedMenuOptions: null, isExpanded: false }, _ => this.props.toggleTab(menu.name, true));
               
            } else if (event.button === 0) {
                event.preventDefault();
                this.setState({ openedMenuOptions: null, isExpanded: false }, _ => this.props.toggleTab(menu.name, false));
            }
        }
        else {
            const isAlreadyOpen = this.state.openedMenuOptions === menu.name;

            this.setState({
                openedMenuOptions: isAlreadyOpen ? null : menu.name
            })
        }
    }

    handleClickOutside = (event) =>{
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
                isExpanded: false,
                openedMenuOptions: null
            });
        }
    }


    render() {
        const { isExpanded, openedMenuOptions } = this.state;
        const { tabs, activeTab } = this.props;

        return (
            <div style={{ width: !global.isMobile ? '4%' : '12%' }} className='pr-2 sideBar' ref={this.wrapperRef}>
                <div className="d-flex">
                    <Nav tabs vertical className='border-0' style={{ position: 'sticky', top: '0' }}>
                        <Card className="bg-white p-2 border-0 shadow" style={{ width: 'fit-content' }}>
                            <NavItem className="pb-2 mb-4">
                                <NavLink onClick={this.toggleSideBar} className="pointer border-0" >
                                    <div id="menuIcon" className='d-flex align-items-center justify-content-center py-2'>
                                        <i className={`fas fa-chevron-${isExpanded ? 'left' : 'right'}`} />
                                    </div>
                                    {!isExpanded ?
                                        <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="right" target="menuIcon">
                                            <PopoverBody>
                                                Open Menu
                                            </PopoverBody>
                                        </CustomToolTip>
                                    : ''}
                                </NavLink>
                            </NavItem>

                            {tabs.map((menu, key) => {
                                const isSelected = activeTab === menu.name || menu.subMenus?.some(el => el.name === activeTab);

                                return (
                                    <NavItem key={key} className="pb-2">
                                        <NavLink className={'bg-white border-0 position-sticky ' + (!isExpanded && ' overbglight ')} style={{ cursor: 'pointer' }} onMouseDown={(e) => this.onMouseDown(e, menu)} >
                                            {isSelected ?
                                                <div style={{
                                                    'left': '-5px',
                                                    'position': 'absolute',
                                                    'borderRadius': '0px 30px 30px 0px',
                                                    'width': '7px',
                                                    'height': '14px',
                                                    'background': '#0667FF 0% 0% no-repeat padding-box',
                                                    'opacity': '1',
                                                    'marginTop': '6px'
                                                }} />
                                                : ''}
                                            <div id={`menu-${key}`} className={(isSelected ? ' text-primary ' : '') + ' d-flex align-items-center justify-content-center py-2'} >
                                                <i className={`${menu.icon}`} />
                                            </div>

                                            {!isExpanded && openedMenuOptions !== menu.name ?
                                                <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="right" target={`menu-${key}`}>
                                                    <PopoverBody>
                                                        {menu.label}
                                                    </PopoverBody>
                                                </CustomToolTip>
                                                : ''}


                                            {menu.subMenus?.length > 0 ?
                                                isExpanded ?
                                                    <div className="my-3" style={{ height: `${40 * menu.subMenus.length}px` }} />

                                                    : openedMenuOptions === menu.name ?
                                                        <div className="subMenusCard shadow border cursor-default" style={{ fontSize: '0.9em' }} onMouseDown={(e) => e.stopPropagation()}>
                                                            <div className="my-2 pb-3 border-bottom px-2">
                                                                <b><i className={`${menu.icon} mr-2`} />{menu.label}</b>
                                                            </div>

                                                            <div className="verticalScroll pointer" style={{ maxHeight: '230px' }}>
                                                                <RenderSubMenus subMenus={menu.subMenus} onMouseDown={this.onMouseDown} activeTab={activeTab} />
                                                            </div>
                                                        </div>
                                                        : ''
                                                : ''}
                                        </NavLink>
                                    </NavItem>
                                )
                            } )}
                        </Card>
                    </Nav>


                    {isExpanded ?
                        <div className="bg-white sideBarExtension py-3 pl-1 pr-4 sliding-div">
                            <div className=" pb-1" style={{ display: 'flex', flexDirection: 'column', gap: '13px'/*, height: 'fit-content'*/ }}>
                                <div className="mb-4 pt-1"> <h5> Sales Process </h5></div>

                                {tabs.map((menu, key) =>
                                    <div key={key}
                                        className={(activeTab === menu.name && ' text-primary ') + " py-2 pointer"}
                                        //style={{ fontSize: '0.9em' }}
                                        onMouseDown={(e) => this.onMouseDown(e, menu)}
                                    >
                                        <div>{menu.label} </div>

                                        {menu.subMenus?.length > 0 ?
                                            <div className="border-top my-3">
                                                <RenderSubMenus subMenus={menu.subMenus} onMouseDown={this.onMouseDown} activeTab={activeTab} />
                                            </div>
                                        : ''}
                                    </div>
                                )}
                            </div>

                        </div>
                    : ''}
                </div>
            </div>
        )
    }
}

export default ShortSideBar;


const RenderSubMenus = ({ subMenus, onMouseDown, activeTab }) => {
    return (
        <div>
            {subMenus.map((subMenu, index) =>
                <div key={index}>
                    <div className={'d-flex align-items-center p-2 my-1 overbglight' + (activeTab === subMenu.name ? ' text-primary ' : '')} onMouseDown={(e) => onMouseDown(e, subMenu)}>
                        <i className={`${subMenu.icon} mr-2`} /> {subMenu.label}
                    </div>
                </div>
            )}
        </div>
    )
}