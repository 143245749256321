import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Col, Row, Button } from 'reactstrap';
import { DefaultPage, StyledCard } from "../../../Base/CommonUIComponents";
import { RenderStatsPage, SelectAggregatedBy, getAggregatedByOptions } from '../SendGridFunctions';
import { handleNotification } from '../../../Base/Notification';
import { getOpensAndClicksStatus } from '../SendGridFunctions';
import { DateRangePicker } from 'react-dates';
import { getAPI } from '../../../Base/API';
import moment from 'moment';
import CustomSelect from '../../../Base/CustomSelect';


export class Geographical extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            pieChart: { labels: [], datasets: [] },
            startDate: moment().subtract(6, 'days'),
            endDate: moment(),
            aggregatedBy: 'day',
            offset: 0,
            cards: [],
            aggregateOptions: getAggregatedByOptions(this.props.intl),
            metricsOptions: getOpensAndClicksStatus(this.props.intl),
            selectedMetric: 'opens',
            statsByGeo: [],
            metricByCountry: [["Country", "Total"]]
        };
    }

    componentDidMount() {
        this.getGlobalEmailStatsByGeo();
    }

    getGlobalEmailStatsByGeo = (e) => {
        if (e) e.preventDefault();

        this.setState({ block: true });
        const { startDate, endDate, limit, offset, aggregatedBy } = this.state;

        let params = `?startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}`;

        if (limit) params += `&limit=${limit}`;
        if (offset !== null) params += `&offset=${offset}`;
        if (aggregatedBy) params += `&aggregatedBy=${aggregatedBy}`;


        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ statsByGeo: data.response, block: false });
                
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SendGrid/v1/stats/geo` + params);
    }


    handleChangeCombo = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    render() {
        const { block, error, statsByGeo, metricsOptions, selectedMetric } = this.state;
        const { page } = this.props;


        return (
            <StyledCard block={block} error={error} >
                <Row className="mb-4 py-1">
                    <Col>
                        <h5> <i className={page?.icon} /> {page?.name} </h5>
                    </Col>
                </Row>

                <Row className="align-items-center mb-3">
                    <Col sm={3}>
                        <CustomSelect
                            icon="icon-icon-filter"
                            options={metricsOptions}
                            isSearchable
                            value={metricsOptions.filter(el => el.value === selectedMetric) || ''}
                            placeholder=""
                            onChange={(e) => this.handleChangeCombo("selectedMetric", e)}
                        />
                    </Col>
                    <Col sm={2}>
                        <SelectAggregatedBy
                            value={this.state.aggregatedBy}
                            handleChange={this.handleChangeCombo}
                            intl={this.props.intl}
                            required={true}
                        />
                    </Col>
                    <Col>
                        <DateRangePicker
                            startDate={this.state.startDate}
                            startDateId="startDate"
                            isOutsideRange={day => false}
                            endDate={this.state.endDate}
                            endDateId="endDate"
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>
                    <Col className="text-right">
                        <Button className=" btn btn-host btn-sm" onClick={this.getGlobalEmailStatsByGeo}>
                            <i className="icon-icon-search" />
                        </Button>
                    </Col>
                </Row>

                {statsByGeo && statsByGeo.length > 0 ?
                    <RenderStatsPage
                        type="Countries"
                        renderDailyActivityCard={false}
                        renderMapCard={true}
                        results={statsByGeo}
                        metric={selectedMetric}
                    />
                : <DefaultPage text="SendGrid.ThereAreNoStatisticsAvailable" icon={page?.icon} />}
            </StyledCard>
        );
    }
}

export default injectIntl(Geographical)