import React, { Component } from 'react';
import { Form, Card, Col, Row, Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from '../Base/API';
import { Crt, DefaultPage, FormatAmountNumber, SelectCampaign, StyledCard } from '../Base/CommonUIComponents';
import { handleNotification } from '../Base/Notification';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { downloadExcelFromJson } from '../Base/ReferenceDataFunctions';


export class CampaignStatistics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            startDate: moment().subtract(1, 'month'),
            endDate: moment(),
        }
    }

    componentDidMount() {
        this.getCampaignStatistics();
    }

    getCampaignStatistics = (e) => {
        if (e) e.preventDefault();
        this.setState({ block: true });

        const params = `?startDate=${moment(this.state.startDate).format("YYYY-MM-DD")}&endDate=${moment(this.state.endDate).format("YYYY-MM-DD")}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.campaignCalcs(data.response);
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/marketingsend/stats` + params);
    }

    campaignCalcs = (data) => {
        if (data?.length) {
            data.forEach(campaign => {
                const totalSent = campaign.marketingSendKpis?.reduce((acc, cur) => acc + cur.totalSent, 0);
                const totalViewed = campaign.marketingSendKpis?.reduce((acc, cur) => acc + cur.totalViewed, 0);
                const totalClicked = campaign.marketingSendKpis?.reduce((acc, cur) => acc + cur.totalClicked, 0);
                const totalRemoved = campaign.marketingSendKpis?.reduce((acc, cur) => acc + cur.totalRemoved, 0);

                campaign.scheduleDay = campaign.scheduleDay ? moment(campaign.scheduleDay).format("YYYY-MM-DD") : '';
                campaign.type = campaign.marketingType ? this.props.intl.formatMessage({ id: `Campaigns.${campaign.marketingType}` }) : '';

                campaign.sent = totalSent;
                campaign.viewed = totalViewed;
                campaign.viewedRate = totalViewed && totalSent ? `${parseFloat(totalViewed * 100 / totalSent).toFixed()}%` : '';
                campaign.clicked = totalClicked;
                campaign.clickedRate = totalClicked && totalSent ? `${parseFloat(totalClicked * 100 / totalSent).toFixed() }%` : '';
                campaign.removed = totalRemoved;
                campaign.removedRate = totalRemoved && totalSent ? `${parseFloat(totalRemoved * 100 / totalSent).toFixed()}%` : '';
                campaign.delivered = campaign.marketingSendKpis?.reduce((acc, cur) => acc + cur.totalDelivered, 0);
                campaign.bounced = campaign.marketingSendKpis?.reduce((acc, cur) => acc + cur.totalBounced, 0);
                campaign.errors = campaign.marketingSendKpis?.reduce((acc, cur) => acc + cur.totalFailed + cur.totalAbuses, 0);


                delete campaign.marketingSendKpis;
                delete campaign.hotelGroupId;
                delete campaign.hotelId;
                delete campaign.marketingType;
            })
        }

        this.setState({ campaignStats: data, error: [], block: false });
    }

    downloadExcelData = () => {
        const { campaignStats } = this.state;
        const { intl } = this.props;

        const jsonData = {
            "CampaignStatistics": campaignStats.map(obj => {
                const newObj = {};

                Object.keys(obj).forEach(key => {
                    const newKey = key.charAt(0).toUpperCase() + key.slice(1);
                    newObj[newKey] = obj[key];
                });

                return newObj;
            })
        };

        downloadExcelFromJson(jsonData, 'CampaignStatistics', intl, 'Campaigns');
    }


    render() {
        const { error, block, startDate, endDate, focusedInput, campaignStats } = this.state;
        

        return (
            <StyledCard error={error} block={block} title="NavMenu.CampaignStatistics" icon="icon-icon-statistics-campaigns">
                <Form onSubmit={this.getCampaignStatistics} className="my-4">
                    <Row className="align-items-center">
                        <Col>
                            <DateRangePicker
                                startDate={startDate ? moment(startDate) : null}
                                startDateId="startDate"
                                isOutsideRange={day => day > moment()}
                                endDate={endDate ? moment(endDate) : null}
                                endDateId="endDate"
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                focusedInput={focusedInput}
                                onFocusChange={(focusedInput) => this.setState({focusedInput})}
                                small={true}
                                numberOfMonths={2}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                minimumNights={0}
                                required
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className="btn btn-host btn-sm" onClick={this.downloadExcelData}>
                                <i className="fas fa-file-download" />
                            </Button>
                            <Button className="btn btn-host btn-sm ml-2" type="submit">
                                <i className='fas fa-search' />
                            </Button>
                        </Col>
                    </Row>
                </Form>                

                <Card className="pb-2 border-0 shadow pr-2" body>
                    <Row className="pb-3 align-items-center border-bottom mr-1">
                        <Col style={{ width: '5%', maxWidth: '5%' }}>Id</Col>
                        <Col style={{ width: '25%', minWidth: '25%' }}><FormattedMessage id="Campaigns.Name" /></Col>
                        <Col style={{ width: '12%', minWidth: '12%' }}><FormattedMessage id="Campaigns.ScheduleDay" /></Col>
                        <Col style={{ width: '10%', minWidth: '10%' }}><FormattedMessage id="Campaigns.Type" /></Col>
                        <Col><FormattedMessage id="Campaigns.Sent" /></Col>
                        {/*<Col><FormattedMessage id="Campaigns.Delivered" /></Col>*/}
                        <Col><FormattedMessage id="Campaigns.Bounced" /></Col>
                        <Col><FormattedMessage id="Campaigns.Viewed" /></Col>
                        <Col><FormattedMessage id="Campaigns.Clicked" /></Col>
                        <Col><FormattedMessage id="Campaigns.Removed" /></Col>
                        <Col><FormattedMessage id="Campaigns.Errors" /></Col>
                    </Row>

                    {campaignStats?.length > 0 ?
                        <div className='verticalScroll' style={{ maxHeight: '60vh', overflow: 'auto', overflowX: 'hidden' }}>
                            {campaignStats.map((stats, key) => {
                                return (
                                    <Row key={key} className={(key % 2 ? 'bg-light-grey' : '') + ' align-items-center py-2 overbglight'}>
                                        <Col style={{ width: '5%', maxWidth: '5%' }}>{stats.id}</Col>
                                        <Col style={{ width: '25%', minWidth: '25%' }}><i className={stats.senderChannel === 'Email' ? 'far fa-envelope mr-2' : 'icon-icon-sms mr-2'} /> {stats.name}</Col>
                                        <Col style={{ width: '12%', minWidth: '12%' }}>{stats.scheduleDay}</Col>
                                        <Col style={{ width: '10%', minWidth: '10%' }}>{stats.type}</Col>
                                        <Col>{stats.sent}</Col>
                                        {/*<Col>{stats.delivered}</Col>*/}
                                        <Col>{stats.bounced}</Col>
                                        <Col>{stats.viewed} <span className="text-muted">{stats.viewedRate && `(${stats.viewedRate})`}</span></Col>
                                        <Col>{stats.clicked} <span className="text-muted">{stats.clickedRate && `(${stats.clickedRate})`}</span></Col>
                                        <Col>{stats.removed} <span className="text-muted">{stats.removedRate && `(${stats.removedRate})`}</span></Col>
                                        <Col>{stats.errors}</Col>
                                    </Row>
                                )
                            })}
                        </div>
                        :
                        <DefaultPage icon="icon-icon-statistics-campaigns" text="Campaigns.NoResultsForDates" />
                }
                </Card>
            </StyledCard>
        );
    }
}

export default injectIntl(CampaignStatistics);