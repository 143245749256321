import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, Nav, NavItem, NavLink } from 'reactstrap';
import CustomSelect from '../../../Base/CustomSelect';

class PlatformDeepDiveSideMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productTypeOptions: [{
                label: 'Profile Now',
                value: 'GMS'
            },
            {
                label: 'PN Forms',
                value: 'PNForms'
            }],
        }
    }


    render() {
        const { productTypeOptions } = this.state;
        const { activeTab, productType } = this.props;

        return (
            <Nav tabs vertical className="h-100 pt-1 border-0">
                <div className="bg-white py-1 px-3 mb-3 border-0 shadow" body>
                    <h5 className='m-2'>
                        <FormattedMessage id={`NavMenu.PlatformDeepDive`} />
                    </h5>
                    <div className='p-2'>
                        <CustomSelect 
                            options={productTypeOptions}
                            placeholder={<FormattedMessage id="PlatformDeepDive.SelectType" />}
                            value={productTypeOptions.find(c => c.value === productType)}
                            onChange={this.props.setProductType.bind(this)}
                        />
                    </div>    
                </div>
                <Card className="bg-white p-2 border-0 shadow w-100" body>
                    <NavItem className="pb-2">
                        <NavLink className={activeTab === 'Dashboard' ? 'text-primary  border-0 pointer border-left-primary' : 'bg-white border-0 pointer'}
                            onClick={() => this.props.toggleTab('Dashboard')} >
                            <i className="fas fa-tachometer-alt pr-2" />
                            <FormattedMessage id="PlatformDeepDive.Dashboard" />
                        </NavLink>
                    </NavItem>
                    <NavItem className="pb-2">
                        <NavLink className={activeTab === 'Events' ? 'text-primary  border-0 pointer border-left-primary' : 'bg-white border-0 pointer'}
                            onClick={() => this.props.toggleTab('Events')} >
                            <i className="fas fa-clipboard-list pr-2" />
                            <FormattedMessage id="PlatformDeepDive.Events" />
                        </NavLink>
                    </NavItem>
                    {productType === 'GMS' ?
                        <NavItem className="pb-2">
                            <NavLink className={activeTab === 'Helpers' ? 'text-primary  border-0 pointer border-left-primary' : 'bg-white border-0 pointer'}
                                onClick={() => this.props.toggleTab('Helpers')} >
                                <i className="fas fa-question-circle pr-2" />
                                <FormattedMessage id="PlatformDeepDive.Helpers" />
                            </NavLink>
                        </NavItem>
                    :''}
                    <NavItem className="pb-2">
                        <NavLink className={activeTab === 'ExceedTimeAPIs' ? 'text-primary  border-0 pointer border-left-primary' : 'bg-white border-0 pointer'}
                            onClick={() => this.props.toggleTab('ExceedTimeAPIs')} >
                            <i className="fas fa-hourglass-half pr-2" />
                            <FormattedMessage id="PlatformDeepDive.ExceedTimeAPIs" />
                        </NavLink>
                    </NavItem>
                </Card>
            </Nav>
        );
    }

};
export default injectIntl(PlatformDeepDiveSideMenu);