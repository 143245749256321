import React, { Component } from 'react';
import { Card, Row, Col, FormGroup, Badge, Button, Input } from 'reactstrap';
import { RenderEgoiTemplate, RenderSendGridTemplate, RenderTwillioTemplate, SendGridTemplateValidationsHelper, TemplateByCountry, TemplateValidationsHelper } from '../../Base/TemplateByCountry';
import { getEgoiSenderStatus, getSenderChannel, getSenderChannelOptions } from '../../Base/ReferenceDataFunctions';
import { handleNotification } from '../../Base/Notification';
import { FormattedMessage } from 'react-intl';
import { getAPI } from '../../Base/API';
import BlockUi from 'react-block-ui';
import { Pagination } from '../../Base/PaginationComponents';
import { PreviewSendGridTemplate } from '../../Marketing/SendGrid/PreviewSendGridTemplate';
import PreviewTemplate from '../PreviewTemplate';
import CustomSelect from '../../Base/CustomSelect';


//HANDLE COMPONENTS LIKE CHANNELS AND TEMPLATES, BETWEEN SENDGRID AND EGOI


//#region sender channel
export class SenderChannelOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            senders: null,
            limit: 12,
            offset: 0,
            totalItems: 0,
            senderChannelOptions: getSenderChannel(this.props.hasEgoi, this.props.hasTwillio),
            selectedTemplate: null
        };
    }

    componentDidMount() {
        if (this.props.hasEgoi && this.props.selectedChannel) {
            this.getEgoiSenders(this.props.selectedChannel);
        }
        else {
            if (this.props.hasSendGrid && this.props.selectedChannel == 'Email') {
                this.getSendGridSenders();
            }
            if (this.props.hasTwillio && this.props.selectedChannel == 'SMS') {
                this.getTwillioSenders();
            }
        }
    }
    
    componentWillReceiveProps(nextProps) {
        if (this.state.senders === null || this.state.senders.length === 0 || this.props.selectedChannel !== nextProps.selectedChannel) {
            if (nextProps.hasEgoi && nextProps.selectedChannel) {
                this.getEgoiSenders(nextProps.selectedChannel);
            }
            else {
                if (nextProps.hasSendGrid && nextProps.selectedChannel == 'Email') {
                    this.getSendGridSenders();
                }
                if (nextProps.hasTwillio && nextProps.selectedChannel == 'SMS') {
                    this.getTwillioSenders();
                }
            }
        }

        if ((this.props.hasEgoi !== nextProps.hasEgoi) || (this.props.hasTwillio !== nextProps.hasTwillio)) {
            this.setState({
                senderChannelOptions: getSenderChannel(nextProps.hasEgoi, nextProps.hasTwillio)
            });
        }
    }

    getEgoiSenders = (channel) => {
        this.setState({ block: true });
        const type = channel === 'Email' ? 'email' : 'cellphone';

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({
                    senders: data.items ? data.items.map(item => {
                        item.sender_id = `${item.sender_id}`
                        return item;
                    }) : null,
                    totalItems: data.total_items ? data.total_items : 0
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoi/senders/${type}?limit=${this.state.limit}&offset=${this.state.offset}`);
    }

    getSendGridSenders = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                const senders = [];

                if (data.response && data.response.results) {
                    data.response.results.forEach(sender => {
                        if (sender.verified) {
                            senders.push({
                                name: sender.from_name,
                                email: sender.from_email,
                                sender_id: `${sender.id}`
                            })
                        }
                    })
                }
                this.setState({ senders, block: false });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SendGrid/v1/sender`);
    }

    getTwillioSenders = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.services && data.response.services.length > 0) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                let senders = [];
                if (data && data.response && data.response.services && data.response.services.length > 0) {
                    senders = data.response.services
                        .map(resp => {
                            const sender = {
                                sender_id: resp.sid,
                                cellphone: resp.friendly_name,
                            };
                            return sender;
                        });
                }
                this.setState({ error: errorMessage, block: false, senders: senders });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Twilio/v1/service`);
    }
    
    getSenderStatus = (status) => {
        const senderStatus = getEgoiSenderStatus().find(s => s.value === status);

        return senderStatus ? senderStatus : { label: status };
    }

    handleSenderChannel = (channel) => {
        if (channel !== this.props.selectedChannel) {
            this.props.handleSenderChannel(channel);
        }
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            offset: this.state.offset + value
        }, () => this.getEgoiSenders(this.props.selectedChannel));
    }


    render() {
        const { senderChannelOptions, senders, totalItems, limit, offset } = this.state;
        const { hasEgoi, hasSendGrid, hasTwillio, selectedChannel, senderId, handleCountriesToSend, filters, displayCountriesToSend } = this.props;

        const countriesToSendOptions = [
            {
                value: 'PT',
                label: <FormattedMessage id="Campaigns.Portugal" />
            },
            {
                value: 'ES',
                label: <FormattedMessage id="Campaigns.Spain" />
            }
        ];

        return (hasEgoi || hasSendGrid || hasTwillio ?
            <>
                <Row className="mt-2">
                    <Col>
                        <h5><FormattedMessage id="Campaigns.Channel" /></h5>
                    </Col>
                    {
                        displayCountriesToSend && hasEgoi && selectedChannel === getSenderChannelOptions()[1].value ?
                            <Col>
                                <h5><FormattedMessage id="Campaigns.CountriesToSend" /></h5>
                            </Col>
                            :
                            ''
                    }
                </Row>
                <FormGroup row>
                    <Col>
                        <Row>
                            {senderChannelOptions && senderChannelOptions.map((sc, key) =>
                                <Col className="col-3" key={key}>
                                    <Card className="border-0 shadow pointer" body
                                        style={selectedChannel === sc.value ? { backgroundColor: '#d3eaff' } : {}}
                                        onClick={() => this.handleSenderChannel(sc.value)}
                                    >
                                        {sc.label}
                                    </Card>
                                </Col>
                            )}
                            {
                                displayCountriesToSend && hasEgoi && selectedChannel === getSenderChannelOptions()[1].value ?
                                    <Col>
                                        <CustomSelect
                                            options={countriesToSendOptions}
                                            onChange={handleCountriesToSend}
                                            isMulti={true}
                                            value={countriesToSendOptions.filter(cntOpt => filters.countriesToSend.some(cnt => cntOpt.value === cnt))}
                                        />
                                    </Col>
                                    :
                                    ''
                            }
                        </Row>
                    </Col>
                </FormGroup>

                {selectedChannel ?
                    <BlockUi tag="div" blocking={this.state.block}>
                        <Row className="mt-2">
                            <Col>
                                <h5><FormattedMessage id="Campaigns.Sender" /></h5>
                            </Col>
                        </Row>
                        <Row>
                            {senders && senders.map((sender, key) =>
                                <Col className="col-3 mb-2" key={key}>
                                    <Card className="border-0 shadow pointer" body onClick={() => this.props.selectSender(sender)} style={senderId === sender.sender_id ? { backgroundColor: '#d3eaff' } : {}} >
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col className="text-ellipsis" title={sender.name}>
                                                        {sender.name ? <h5> {sender.name}</h5> : ''}
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col className="text-ellipsis" title={sender.email || sender.cellphone}>
                                                        {sender.email || sender.cellphone}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {sender.status &&
                                                <Col className="col-3">
                                                    <Badge className="p-1" color={this.getSenderStatus(sender.status).color} >
                                                        {this.getSenderStatus(sender.status).label}
                                                    </Badge>
                                                </Col>
                                            }
                                        </Row>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                        {totalItems > 0 && totalItems >= limit ?
                            <Row className="mt-3">
                                <Col>
                                    <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(-limit)} disabled={offset === 0}>
                                        <i className="fas fa-angle-left" />
                                    </Button>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(limit)} disabled={totalItems <= offset + limit}>
                                        <i className="fas fa-angle-right" />
                                    </Button>
                                </Col>
                            </Row>
                        : ''}
                    </BlockUi>
                : ''}
            </>
            : '')

    }
};
//#endregion



//#region templates
export class RenderTemplateSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            templates: null,
            page: 1,
            pageSize: 8,
            pagination: [this.getNewPagination(1)],
            selectedTemplate: null,
            previewTemplate: false,
            previewEgoiTemplate: false,
            selectedTemplateId: null,
            selectedTemplateName: null,
            activeTab: 'Default'
        };
    }

    componentDidMount() {
        this.setState({ block: true });
        if (this.props.hasEgoi && this.props.senderChannel) {
            this.getEgoiTemplates(this.props.senderChannel);
        }
        else {
            if (this.props.hasSendGrid && this.props.senderChannel == 'Email') {
                this.getSendGridTemplates();
            }

            if (this.props.hasTwillio && this.props.senderChannel == 'SMS') {
                this.getTwillioTemplates();
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.hasEgoi && nextProps.senderChannel && this.props.senderChannel !== nextProps.senderChannel) {
            this.getEgoiTemplates(nextProps.senderChannel);
        }
    }

    getNewPagination = (page) => {
        const newPagination = {
            prevToken: null,
            nextToken: null,
            page: page
        };
        return newPagination;
    }

    getEgoiTemplates = (selectedChannel) => {
        this.setState({ block: true });

        const type = selectedChannel === 'Email' ? 'email' : 'sms';

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({
                    templates: data.response ? data.response.sort((a, b) => b.id - a.id) : null
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/template/${type}`);
    }

    getSendGridTemplates = (token, templateName) => {
        const { page, pageSize, pagination } = this.state;

        const param = templateName ? `&templateName=${templateName}` : '';

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.result) {
                const templates = [];

                data.response.result.forEach(temp => {
                    const currentVersion = temp.versions.find(vrs => vrs.active === 1);

                    const template = {
                        id: temp.id,
                        templateId: temp.id,
                        templateName: temp.name,
                        subject: currentVersion?.subject,
                        thumbnail_url: currentVersion?.thumbnail_url,
                        htmlBody: currentVersion?.html_content
                    }

                    templates.push(template);
                })


                if (data.response._metadata) {
                    const paginationItem = pagination.find(p => p.page === page);

                    if (paginationItem) {
                        paginationItem.prevToken = data.response._metadata.self ? (new URLSearchParams(new URL(data.response._metadata.self).searchParams)).get("page_token") : null;
                        paginationItem.nextToken = data.response._metadata.next ? (new URLSearchParams(new URL(data.response._metadata.next).searchParams)).get("page_token") : null;
                    }
                }

                this.setState({ error: errorMessage, pagination }, () => this.getTransactionalTemplate(templates));
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/SendGrid/v1/templates?generations=legacy,dynamic&page=${pageSize}${token ? `&token=${token}` : ``}` + param);
    }

    getTransactionalTemplate = (templates) => {
        const { campaignTemplates } = this.props;
        
        var selectedTemplateId = null;

        if (campaignTemplates && campaignTemplates.length > 0) {

            if(this.state.activeTab === 'Default'){
                selectedTemplateId = campaignTemplates.find(el => el.isDefault)?.templateId;
            }else{
                selectedTemplateId = campaignTemplates[this.state.activeTab + 1]?.templateId;
            }
        }

        if (selectedTemplateId) {

            this.setState({ block: true, templates }, () =>
                getAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];

                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data && data.response) {
                        var { selectedTemplate, page } = this.state;
                        
                        selectedTemplate = data.response;
                        
                        if (selectedTemplate && page !== 1 && templates.some(t => t.templateId === selectedTemplateId)) {
                            templates = templates.filter(t => t.templateId !== selectedTemplateId);
                        }

                        if(selectedTemplate && page === 1){
                            
                                const currentVersion = selectedTemplate.versions.find(vrs => vrs.active === 1);

                                const template = {
                                    id: selectedTemplate.id,
                                    templateId: selectedTemplate.id,
                                    templateName: selectedTemplate.name,
                                    subject: currentVersion?.subject,
                                    thumbnail_url: currentVersion?.thumbnail_url,
                                    htmlBody: currentVersion?.html_content
                                }
                            if(!templates.some(t => t.templateId === selectedTemplateId)){
                                templates.splice(0, 0, template);
                            }
                        }
                        
                        this.setState({ templates, selectedTemplate, block: false });
                    }
                    this.setState({ error: errorMessage, block: false });
                }, `/api/gms/SendGrid/v1/templates/${selectedTemplateId}`)
            );
        }else{this.setState({ templates, block: false });
            return;
        }
    }

    getTwillioTemplates = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.contents && data.response.contents.length > 0) {
                const templates = data.response.contents
                    .map(resp => {
                        const template = {
                            id: resp.sid,
                            templateId: resp.sid,
                            templateName: resp.friendly_name,
                            friendly_name: resp.friendly_name,
                            text: resp.types["twilio/text"].body,
                            types: resp.types,
                            language: resp.language
                        }

                        return template;
                    });

                this.setState({ error: errorMessage, block: false, templates: templates });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Twilio/v1/template`);
    }

    handlePagination = (token, newPage) => {
        const { pagination } = this.state;
        const hasPagination = pagination.some(p => p.page === newPage);

        if (!hasPagination) {
            pagination.push(this.getNewPagination(newPage));
        }

        this.setState({ block: true, page: newPage, pagination }, _ => this.getSendGridTemplates(token));
    }

    toggleCountryTab = (tab) => {
        const { pagination, page } = this.state;
        this.setState({block: true, activeTab: tab }, _ => this.handlePagination(pagination[0].prevToken, 1));
    }

    togglePreviewModal = (templateId, templateName) => {
        this.setState((prevState => ({ previewTemplate: !prevState.previewTemplate, selectedTemplateId: templateId, selectedTemplateName: templateName })));
    }

    togglePreviewEgoiModal = (templateId) => {
        this.setState((prevState => ({ previewEgoiTemplate: !prevState.previewTemplate, selectedTemplateId: templateId })));
    }

    render() {
        const { templates, page, pagination, previewTemplate, selectedTemplateId, selectedTemplateName, previewEgoiTemplate } = this.state;
        const { campaignTemplates, tabs, isMultiSelection, hasEgoi, hasSendGrid, hasTwillio, senderChannel } = this.props;

        const hasPagination = (this.props.hasSendGrid && pagination);

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <Row className="mb-3 mt-4">
                    <Col>
                        <h5><FormattedMessage id="Campaigns.Template" /></h5>
                    </Col>
                    <Col className={this.props.isJourneyBuilder ? 'd-flex align-items-center justify-content-end' : ''}>
                        {this.props.isJourneyBuilder ?  
                            <Button className="btn btn-host btn-sm mr-1" onClick={this.props.toggle}>
                                <i className="fas fa-save"></i>
                            </Button>
                        :''}
                        {hasEgoi ?
                            <TemplateValidationsHelper />
                            : hasSendGrid || hasTwillio ?
                                <SendGridTemplateValidationsHelper />
                                : ''}
                    </Col>
                </Row>
                {isMultiSelection ?
                    <>
                        <Row className="align-items-center mb-3">
                            <Col sm={1}>
                                Sample Size:
                            </Col>
                            <Col className="col-2 d-flex align-items-center">
                                <Input className="mr-2"
                                    type="number" name="sampleSize"
                                    onChange={(e) => this.props.handleChange(e)}
                                    value={this.props.sampleSize || ''}
                                /> %
                            </Col>
                        </Row>
                        <div className="pt-2" style={{ border: '1px solid #ddd', borderRadius: '5px' }}>
                            <Row className="p-2 mx-0" >
                                {templates && templates.map((template, key) =>
                                    <div key={key} onClick={() => this.props.handleMultiTemplates(template.id, null, template)} className="mb-3"
                                        style={{
                                            backgroundColor: campaignTemplates && campaignTemplates.length > 0 && campaignTemplates.some(el => el.templateId.toString() === template.id.toString()) ? '#d3eaff' : 'white',
                                            width: '24%', display: 'inline-block', cursor: 'pointer', boxShadow: '0px 3px 15px #6f73881a', borderRadius: '5px',
                                            margin: '0 0.5%', height: '500px', overflow: 'hidden', paddingTop: '1rem'
                                        }}
                                    >
                                        <div className="mx-2" >
                                            {
                                                this.props.hasEgoi ?
                                                    <RenderEgoiTemplate
                                                        template={template}
                                                        tooltip={`toolTip${key}`}
                                                        togglePreviewModal={this.togglePreviewEgoiModal}
                                                    />
                                                    :
                                                    hasTwillio && this.props.selectedChannel == 'SMS' ?
                                                        <RenderTwillioTemplate
                                                            template={template}
                                                            togglePreviewModal={this.togglePreviewModal}
                                                        />
                                                        :
                                                        <RenderSendGridTemplate
                                                            template={template}
                                                            togglePreviewModal={this.togglePreviewModal}
                                                        />
                                                        
                                            }
                                        </div>
                                    </div>
                                )}
                            </Row>

                            {hasPagination ?
                                <Row className="mb-4 mt-2">
                                    <Col className="text-right mr-3">
                                        <Pagination
                                            isPrevDisabled={page === 1}
                                            isNextDisabled={pagination[page - 1].nextToken === null}
                                            currentPage={page}
                                            handlePrevButton={_ => this.handlePagination(pagination[page - 2].prevToken, page - 1)}
                                            handleNextButton={_ => this.handlePagination(pagination[page - 1].nextToken, page + 1)}
                                        />
                                    </Col>
                                </Row>
                            : ''}
                        </div>
                        {
                            previewTemplate ?
                                <PreviewSendGridTemplate
                                    isOpen={previewTemplate}
                                    toggle={this.togglePreviewModal}
                                    templateId={selectedTemplateId}
                                    templateName={selectedTemplateName}
                                />
                                :
                                ''
                        }
                        {
                            previewEgoiTemplate ?
                                <PreviewTemplate
                                    modal={previewEgoiTemplate}
                                    toggleModal={this.togglePreviewModal}
                                    templateId={selectedTemplateId}
                                    channel={senderChannel && senderChannel.toUpperCase() === 'EMAIL' ? 'email' : 'sms'}
                                />
                                :
                                ''
                        }
                    </>

                    :
                    <TemplateByCountry
                        tabs={tabs}
                        campaignTemplates={campaignTemplates || []}
                        templates={templates}
                        handleTemplates={this.props.handleTemplates}
                        addTab={this.props.addTab}
                        removeTab={this.props.removeTab}
                        handleDefaultTemplate={this.props.handleDefaultTemplate}
                        hasEgoi={this.props.hasEgoi}
                        hasTwillio={this.props.hasTwillio}
                        toggle={this.toggleCountryTab}
                        activeTab={this.state.activeTab}
                        isJourneyBuilder={this.props.isJourneyBuilder}
                        //Pagination Props
                        hasPagination={hasPagination}
                        pagination={{
                            isPrevDisabled: page === 1,
                            isNextDisabled: pagination[page - 1].nextToken === null,
                            currentPage: page,
                            handlePrevButton: () => this.handlePagination(pagination[page - 2].prevToken, page - 1),
                            handleNextButton: () => this.handlePagination(pagination[page - 1].nextToken, page + 1)
                        }}
                        channel={senderChannel && senderChannel.toUpperCase() === 'EMAIL' ? 'email' : 'sms'}
                        searchByApi={this.props.hasSendGrid}
                        getTemplates={this.getSendGridTemplates}
                    />
                }
            </BlockUi>
        )
    }
}
//#endregion