import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getAPI } from '../Base/API';
import { SelectHotel, StyledCard } from "../Base/CommonUIComponents";
import { handleNotification } from '../Base/Notification';
import { Button, Col, Row, Input } from 'reactstrap';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { getFormSource } from '../Base/ReferenceDataFunctions';
import CustomSelect from '../Base/CustomSelect';
import { Pagination, TotalResults } from '../Base/PaginationComponents';
import { Link } from 'react-router-dom';

export class GoContactContacts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            phone: null,
            contactList: []
        };
    }

    componentDidMount() {
    }

    getContact = () => {
        const { phone } = this.state;
        const queryParams = new URLSearchParams();
        queryParams.append('phone', phone);

        const queryString = queryParams.toString();
        this.setState({  block: true});

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                if (data.data ) {
                    this.setState({ contactList: data.data, block: false });
                }

                            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/GoContact/v1/Contacts/phone?${queryString}`);
    }

    render() {
        const { block, error, phone, contactList} = this.state;

        return (
            <StyledCard block={block} error={error} icon="far fa-address-book" title="GoContactContacts.Contacts">

                <Row className="mt-3 mb-4 align-items-center">
                    <Col className="col-2 pr-0">
                        <Input
                            type="text"
                            name="Phone"
                            onChange={(e) => this.setState({ phone: e.target.value })}
                            value={phone || ''}
                            placeholder={this.props.intl.formatMessage({ id: "GoContactContacts.Phone" })}
                            maxLength="20"
                        />
                    </Col>
                    <Col>
                        
                    </Col>
                    <Col className="text-right" >
                        <Button className="mr-3 btn btn-sm btn-host" onClick={this.getContact} >
                            <i className="fa fa-search" />
                        </Button>
                    </Col>
                </Row>
                    {contactList.map((x, idx) => 
                        <div>
                            <Row className="my-1">
                                <Col className ="col-3">
                                    <div className='title-sm'>
                                        <FormattedMessage id="GoContactContacts.id" />
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        {x.id}
                                        </div>
                                </Col>
                                <Col className="col-6">
                                    <div className='title-sm'>
                                        <FormattedMessage id="GoContactContacts.Contact" />
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        {x.contact}
                                        </div>
                            </Col>
                                <Col>
                                    <div className='title-sm'>
                                        <FormattedMessage id="GoContactContacts.Name" />
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between'>

                                        {x.name}
                                        </div>
                            </Col>
                        </Row>
                            <Row className="my-1">
                                <Col>
                                    <div className='title-sm'>
                                        <FormattedMessage id="GoContactContacts.Phone" />
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                {x.first_phone}
                                {x.second_phone}
                                {x.third_phone}
                                {x.fourth_phone}
                                {x.fifth_phone}
                                {x.sixth_phone}
                                {x.seventh_phone}
                                        {x.eighth_phone}
                                        </div>
                                </Col>
                            </Row>
                            <Row className="my-1">
                                <Col>
                                  
                                        <div className='title-sm'>
                                            <FormattedMessage id="GoContactContacts.Address" />
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between'>

                                        {x.address ? x.address : '-'}
                                        </div>
                                </Col>
                                <Col>
                                        <div className='title-sm'>
                                            <FormattedMessage id="GoContactContacts.PostalCode" />
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                        {x.postal_code ? x.postal_code : '-'}
                                        </div>
                                </Col>
                                <Col>
                                        <div className='title-sm'>
                                        <FormattedMessage id="GoContactContacts.Country" />
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                        {x.country ? x.country : '-'}
                                        </div>
                                    </Col>
                                <Col>
                                   
                                        <div className='title-sm'>
                                        <FormattedMessage id="GoContactContacts.city" />
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                        {x.city ? x.city : '-'}
                                        </div>
                                    </Col>
                            </Row>
                            <Row className="my-1">
                                <Col>
                                    <div className='title-sm'>
                                        <FormattedMessage id="GoContactContacts.fields" />
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between'>

                                        {x.fields.field1}
                                        {x.fields.field2}
                                        {x.fields.field3}
                                        {x.fields.field4}
                                        {x.fields.field5}
                                        {x.fields.field6}
                                        {x.fields.field7}
                                        {x.fields.field8}
                                        {x.fields.field9}

                                        {x.fields.field1}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="my-1">
                                <Col className="col-3">
                                    <div className='title-sm'>
                                        <FormattedMessage id="GoContactContacts.LeadStatus" />
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        {x.lead_status}
                                    </div>
                                </Col>
                                <Col className="col-3">
                                    <div className='title-sm'>
                                        <FormattedMessage id="GoContactContacts.CreatedAt" />
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        {x.load_date}
                                    </div>
                                </Col>
                                <Col>
                                    <div className='title-sm'>
                                        <FormattedMessage id="GoContactContacts.UpdatedAt" />
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        {x.last_update_date}
                                    </div>
                                </Col>
                                
                                </Row>
                            </div>
                    )}
            </StyledCard>
        );
    }
}
export default injectIntl(GoContactContacts)