import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../../Base/ErrorAlert';
import { Card, Col, Row, Button } from 'reactstrap';
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { Pagination, ResultsPerPage, TotalResults } from '../../Base/PaginationComponents';
import { KebabMenu } from '../../Base/CommonUIComponents';
import SalesActionModal from '../../SalesProcess/SalesActionModal';
import { checkSalesProcessPermission } from '../../SalesProcess/SalesProcessFunctions';

export class ProfileSalesProcessInteractions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            tasks: [],
            pageSize: 10,
            pageIndex: 0,
            totalItems: 0,
            modal: false,
            profileComboOptions: this.props.profileComboOptions
        };
    }

    componentDidMount() {
        const { profileComboOptions } = this.state;
        const users = global.salesProcessUsers;
        const profileComboOptionsUpdated = [...profileComboOptions];

        if(users?.length){
            users.forEach(u => {
                profileComboOptionsUpdated.push({ value: u.userId, label: u.firstName + ' ' + u.lastName, type: 'user', ...u });
            });
        }

        this.setState({ profileComboOptions: profileComboOptionsUpdated }, () => this.getTasks());
    }

    getTasks = () => {
        const { profileId } = this.props;
        const { pageSize, pageIndex } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                this.setState({ error: errorMessage, block: false, tasks: data.data ? data.data : [], totalItems: data.total ? data.total : 0 });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/SalesProcess/v1/deals/profile/${profileId}/interactions?pageSize=${pageSize}&pageIndex=${pageIndex}`);
    }

    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.pageSize !== value) {
            this.setState({ pageSize: value, pageIndex: 0 }, this.getTasks);
        }
    }

    handlePagination = (value) => {
        this.setState({ block: true, pageIndex: this.state.pageIndex + value }, this.getTasks);
    }

    goToDealDetail = (id) => {
        this.props.history.push({ pathname: `/SalesProcess`, search: `?dealId=${id}#SalesDealDetail` });
    }

    goToInitiativeDetail = (id) => {
        this.props.history.push({ pathname: `/SalesProcess`, search: `?initiativeId=${id}#SalesInitiativeDetail` });
    }

    toggleModal = (action) => {
        this.setState({ modal: !this.state.modal, selectedAction: action }, () => {if(!this.state.modal) this.getTasks()});
    }

    render() {
        const { block, error, pageIndex, pageSize, tasks, totalItems, selectedAction, modal, profileComboOptions } = this.state;
        const { currentSalesProcessUser } = this.props;

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                <Card className="ml-0 ml-lg-3 my-0 mr-0 border-0 bg-light h-100">
                    <Row className="mb-3 align-items-center">
                        <Col className='col-10'>
                            <h5>
                                <i className="fas fa-clipboard-list mr-1" />
                                <FormattedMessage id="ProfileDetails.SalesProcessInteractions" />
                            </h5>
                        </Col>
                        <Col className='col-2 text-right'>
                            <Button className="btn-sm btn-host" onClick={() => this.toggleModal(null, true)}>
                                <i className="fas fa-plus" />
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='mt-3 text-muted' style={{ fontSize: '0.9em' }}>
                                {!global.isMobile ?
                                    <div className='d-flex align-items-center justify-content-between shadow p-3 mb-2 bg-white' style={{ borderRadius: '4px' }}>
                                        <div style={{ width: '20%' }}>
                                            <div className='text-truncate'>
                                                <FormattedMessage id={`SalesTasks.Title`} />
                                            </div>
                                        </div>
                                        <div style={{ width: '18%' }}>
                                            <div className='text-truncate'>
                                                <FormattedMessage id={`SalesTasks.DealName`} />
                                            </div>
                                        </div>
                                        <div className='ml-3' style={{ width: '14%' }}>
                                            <div className='text-truncate'>
                                                <FormattedMessage id={`SalesProcess.RelatedHotels`} />
                                            </div>
                                        </div>
                                        <div style={{ width: '12%' }}>
                                            <div className='text-truncate'>
                                                <FormattedMessage id={`generic.Type`} />
                                            </div>
                                        </div>
                                        <div style={{ width: '12%' }}>
                                            <div className='text-truncate'>
                                                <FormattedMessage id={`SalesTasks.Status`} />
                                            </div>
                                        </div>
                                        <div style={{ width: '12%' }}>
                                            <div className='text-truncate'>
                                                <FormattedMessage id={`SalesTasks.StartDate`} />
                                            </div>
                                        </div>
                                        <div style={{ width: '12%' }}>
                                            <div className='text-truncate'>
                                                <FormattedMessage id={`SalesTasks.EndDate`} />
                                            </div>
                                        </div>
                                    </div>
                                :''}
                                <div className='mt-3'>
                                    {
                                        tasks.map((task, key) => {
                                            const dealDetailsPermission = checkSalesProcessPermission('ProfileDetails:dealActions:details', currentSalesProcessUser?.role, task.dealHotels, currentSalesProcessUser?.hotelList, task.salesDealActionUsers, currentSalesProcessUser?.id);

                                            return !global.isMobile ?
                                                <div key={key} className={`d-flex align-items-center justify-content-between shadow p-3 mb-2 bg-white cursor-pointer`}
                                                    onClick={_ => dealDetailsPermission || !task.salesDealId ? this.toggleModal(task) : undefined}
                                                    style={{ borderRadius: '4px' }}>
                                                    <div className='d-flex align-items-center justify-content-between w-100'
                                                        style={{ width: task.salesDealId !== null && task.salesDealId !== undefined ? '98%' : '100%' }}>
                                                        <div style={{ width: '20%' }}>
                                                            <div className='text-truncate'>
                                                                {task.type === 'Note' ? <div dangerouslySetInnerHTML={{ __html: task.summary }} /> : (task.title ?? '-')}
                                                            </div>
                                                        </div>
                                                        <div style={{ width: '18%' }}>
                                                            <div className='text-truncate'>
                                                                {
                                                                    task.dealName ?
                                                                        <span className='text-truncate hover-underline hrefStyle' onClick={_ => task.salesInitiativeId ? this.goToInitiativeDetail(task.salesInitiativeId) : this.goToDealDetail(task.salesDealId)} >
                                                                            {task.dealName ?? '-'}
                                                                        </span>
                                                                        :
                                                                        '-'
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='ml-3' style={{ width: '14%' }}>
                                                            {task.dealHotels ?
                                                                <div style={{ maxHeight: '22px' }} className='overflow-auto veryCoolScrollBar'>
                                                                    {task.dealHotels.map((id, key) => {
                                                                        const hotel = global.hotelList.find(x => x.value === id);
                                                                        return (

                                                                            <div key={key} className={key ? 'mt-1 text-truncate' : 'text-truncate'}>
                                                                                {hotel.label}
                                                                            </div>
                                                                        )
                                                                    }
                                                                    )}
                                                                </div>
                                                                : '-'}
                                                        </div>
                                                        <div style={{ width: '12%' }}>
                                                            <div className='text-truncate'>
                                                                {task.type ? <FormattedMessage id={`SalesProcess.${task.type}`} /> : '-'}
                                                            </div>
                                                        </div>
                                                        <div style={{ width: '12%' }}>
                                                            <div className='text-truncate'>
                                                                {task.status ?
                                                                    <div className='d-flex align-items-center'>
                                                                        {task.status === 'Scheduled' ?
                                                                            <i style={{ color: 'lightgrey' }} className="mr-2 fas fa-minus-circle" />
                                                                            : task.status === 'Cancelled' ?
                                                                                <i className="mr-2 fas fa-times-circle color-light-red" />
                                                                                : task.status === 'OnGoing' ?
                                                                                    <i className="mr-2 fas fa-arrows-alt-h" style={{ color: "#0665ff" }} />
                                                                                    : task.status === 'Finished' ?
                                                                                        <i className="mr-2 fas fa-check-circle text-success" />
                                                                                        : ''}
                                                                        <div style={{ fontSize: '0.9em', marginTop: '1px' }} className={`deal-action-status-${task.status}`}>
                                                                            <FormattedMessage id={`SalesActions.${task.status}`} />
                                                                        </div>
                                                                    </div>
                                                                    : '-'}
                                                            </div>
                                                        </div>
                                                        <div style={{ width: '12%' }}>
                                                            <div className='text-truncate'>
                                                                {task.startDate ?
                                                                    moment(task.startDate).format('YYYY-MM-DD HH:mm')
                                                                    : '-'}
                                                            </div>
                                                        </div>
                                                        <div style={{ width: '12%' }}>
                                                            <div className='text-truncate'>
                                                                {task.endDate ?
                                                                    moment(task.endDate).format('YYYY-MM-DD HH:mm')
                                                                    : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div key={key} className='cursor-pointer shadow p-3 mb-2 bg-white' style={{ position: 'relative', borderRadius: '4px' }} >
                                                    <Row onClick={_ => dealDetailsPermission || !task.salesDealId ? this.toggleModal(task) : undefined}>
                                                        <Col className='col-4'>
                                                            <b>
                                                                <FormattedMessage id={`SalesTasks.Title`} />
                                                            </b>
                                                            <div style={{ maxWidth: '100%' }}>
                                                                <div className='text-truncate'>
                                                                    {task.type === 'Note' ? <div dangerouslySetInnerHTML={{ __html: task.summary }} /> : (task.title ?? '-')}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col className='col-4'>
                                                            <b>
                                                                <FormattedMessage id={`generic.Type`} />
                                                            </b>
                                                            <div style={{ maxWidth: '100%' }}>
                                                                <div className='text-truncate'>
                                                                    {task.type ? <FormattedMessage id={`SalesProcess.${task.type}`} /> : '-'}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col className='col-4'>
                                                            <b>
                                                                <FormattedMessage id={`SalesTasks.Status`} />
                                                            </b>
                                                            {task.status ?
                                                                <div className='d-flex align-items-center'>
                                                                    {task.status === 'Scheduled' ?
                                                                        <i style={{ color: 'lightgrey' }} className="mr-2 fas fa-minus-circle" />
                                                                        : task.status === 'Cancelled' ?
                                                                            <i className="mr-2 fas fa-times-circle color-light-red" />
                                                                            : task.status === 'OnGoing' ?
                                                                                <i className="mr-2 fas fa-arrows-alt-h" style={{ color: "#0665ff" }} />
                                                                                : task.status === 'Finished' ?
                                                                                    <i className="mr-2 fas fa-check-circle text-success" />
                                                                                    : ''}
                                                                    <div style={{ fontSize: '0.9em', marginTop: '1px' }} className={`deal-action-status-${task.status}`}>
                                                                        <FormattedMessage id={`SalesProcess.${task.status}`} />
                                                                    </div>
                                                                </div>
                                                                : '-'}
                                                        </Col>
                                                        <Col className='col-12'>
                                                            <hr />
                                                        </Col>
                                                        <Col className='col-7 mb-2'>
                                                            <b>
                                                                <FormattedMessage id={`SalesTasks.DealName`} />
                                                            </b>
                                                            <div style={{ maxWidth: '100%' }}>
                                                                <div className='text-truncate'>
                                                                    {
                                                                        task.dealName ?
                                                                            <span className='hover-underline hrefStyle' onClick={_ => this.goToDealDetail(task.salesDealId)}>
                                                                                {task.dealName}
                                                                            </span>
                                                                            :
                                                                            '-'
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col className='col-5 mb-2'>
                                                            <b>
                                                                <FormattedMessage id={`SalesProcess.RelatedHotels`} />
                                                            </b>
                                                            <div style={{ width: '75%' }}>
                                                                {task.dealHotels ?
                                                                    <div style={{ maxHeight: '22px' }} className='overflow-auto veryCoolScrollBar'>
                                                                        {task.dealHotels.map((id, key) => {
                                                                            const hotel = global.hotelList.find(x => x.value === id);
                                                                            return (

                                                                                <div key={key} className={`${key ? 'mt-1' : ''} text-truncate pr-2`}>
                                                                                    {hotel.label}
                                                                                </div>
                                                                            )
                                                                        }
                                                                        )}
                                                                    </div>
                                                                    : '-'}
                                                            </div>
                                                        </Col>
                                                        <Col className='col-7 mb-2'>
                                                            <b>
                                                                <FormattedMessage id={`SalesTasks.StartDate`} />
                                                            </b>
                                                            <div>
                                                                {task.startDate ?
                                                                    moment(task.startDate).format('YYYY-MM-DD HH:mm')
                                                                    : '-'}
                                                            </div>
                                                        </Col>
                                                        <Col className='col-5 mb-2'>
                                                            <b>
                                                                <FormattedMessage id={`SalesTasks.EndDate`} />
                                                            </b>
                                                            <div>
                                                                {task.endDate ?
                                                                    moment(task.endDate).format('YYYY-MM-DD HH:mm')
                                                                    : '-'}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                        })}
                                </div>
                                <Row className="my-4">
                                    <Col>
                                        <ResultsPerPage
                                            changeSizePerPage={this.changePageSize}
                                            pageSize={pageSize}
                                        />
                                        {!global.isMobile ?
                                            <span className="ml-2">
                                                <TotalResults
                                                    end={pageIndex * pageSize + tasks.length}
                                                    start={pageIndex * pageSize + 1}
                                                    total={totalItems}
                                                />
                                            </span>
                                        :''}
                                    </Col>
                                    <Col className="text-right">
                                        <Pagination
                                            isPrevDisabled={pageIndex === 0}
                                            isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                            currentPage={pageIndex + 1}
                                            handlePrevButton={() => this.handlePagination(-1)}
                                            handleNextButton={() => this.handlePagination(1)}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Card>
                {modal ?
                    <SalesActionModal
                        toggle={_ => this.toggleModal()}
                        salesUsers={global.salesProcessUsers}
                        salesDealId={selectedAction?.salesDealId}
                        salesInitiativeId={selectedAction?.salesInitiativeId}
                        salesDealHotelIds={selectedAction?.dealHotels}
                        selectedAction={selectedAction || null}
                        updateActions={this.searchTasks}
                        dealName={selectedAction?.dealName}
                        stageName={selectedAction?.stageName}
                        newTaskFromProfile={true}
                        profileComboOptions={profileComboOptions}
                    />
                    : ''}
            </BlockUi>
        );
    }
}