import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, CardBody } from 'reactstrap';
import { ActiveDeletedStatusBadge, StyledModal, FormatAmountNumber } from '../../Base/CommonUIComponents';
import { ErrorAlert } from '../../Base/ErrorAlert';
import { CustomFormatDate } from '../SalesProcessFunctions';

import moment from 'moment';
import BlockUi from 'react-block-ui';

class SalesDealDetailsProposalDetailsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            currentProposalHistory: null,
            currentProposalVersion: null,
            selectedVersion: null,
            versionOptions: [],
        }
    }

    componentDidMount() {
    }

    handleChangeVersion = (combo) => {
        const { currentProposalHistory } = this.state;
        const selectedVersion = combo?.value || null;
        if(!selectedVersion) return;

        let currentProposalVersion = currentProposalHistory.allVersions.find(({ version }) => version === selectedVersion);

        if(!currentProposalVersion) {
            currentProposalVersion = currentProposalHistory.lastVersion;
        }

        this.setState({ selectedVersion, currentProposalVersion });
    }

    render() {
        const { block, error } = this.state;
        const { toggleModal, modal, selectedProposalVersion, proposalCurrency, intl } = this.props;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="lg" style={{ minWidth: 0, maxWidth: '85%' }}>
                <CardBody>
                    <BlockUi tag="div" blocking={block}>
                        <ErrorAlert error={error} />
                        <div className='p-1'>
                            <Row className="mb-2 align-items-center">
                                <Col className='col-12 d-flex align-items-center'>
                                    <h5 className='m-0'><FormattedMessage id="SalesDeal.Details" /></h5>
                                    <div className='ml-2'>
                                        <ActiveDeletedStatusBadge status={!selectedProposalVersion?.deleted} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pt-2'>
                                <Col className='col-12 d-flex align-items-center justify-content-between mb-2 px-0'>
                                    <Col className='col-3'> {/* version */}
                                        <p className='title-sm text-muted'><FormattedMessage id="SalesDeal.Version" /></p>
                                        <p className='mb-0'>{selectedProposalVersion?.version ?? '-'}</p>
                                    </Col>
                                    <Col className='col-3'> {/* Block / Res Name */}
                                        <p className='title-sm text-muted'><FormattedMessage id="SalesDeal.Name" /></p>
                                        <p className='mb-0'>{(selectedProposalVersion ? (selectedProposalVersion.type === 0 ? selectedProposalVersion.proposalJSON?.BlockData?.headerData?.[0]?.Code : selectedProposalVersion.proposalJSON?.data?.[0]?.Name) : '-') || '-'}</p>
                                    </Col>
                                    <Col className='col-3'> {/* resNumber */}
                                        <p className='title-sm text-muted'><FormattedMessage id="SalesDeal.ResNumber" /></p>
                                        <p className='mb-0'>{selectedProposalVersion?.externalId ?? '-'}</p>
                                    </Col>
                                    <Col className='col-2'> {/* groupResName */}
                                        <p className='title-sm text-muted'>
                                            <FormattedMessage id={`SalesDeal.GroupResName`} />
                                        </p>
                                        <div className='text-truncate'>
                                            {selectedProposalVersion?.groupResName || '-'}
                                        </div>
                                    </Col>
                                </Col>
                                <Col className='col-12 d-flex align-items-center justify-content-between mb-2 px-0'>
                                    <Col className='col-3'> {/* type */}
                                        <p className='title-sm text-muted'><FormattedMessage id="SalesDeal.Type" /></p>
                                        <p className='mb-0'>{isNaN(selectedProposalVersion?.type) ? '-' : selectedProposalVersion?.type === 0 ? <FormattedMessage id="SalesProcess.Block" /> : selectedProposalVersion?.type === 1 ? <FormattedMessage id="SalesProcess.Event" /> : <FormattedMessage id="SalesProcess.GroupReservation" />}</p>
                                    </Col>
                                    <Col className='col-3'> {/* checkIn */}
                                        <p className='title-sm text-truncate'>
                                            <FormattedMessage id={`SalesDeal.CheckIn`} />
                                        </p>
                                        <div className='text-truncate'>
                                            {selectedProposalVersion?.startDate ? CustomFormatDate(selectedProposalVersion.startDate, null, 'hh:mm', intl) || '-' : '-'}
                                        </div>
                                    </Col>
                                    <Col className='col-3'> {/* checkOut */}
                                        <p className='title-sm text-muted'>
                                            <FormattedMessage id={`SalesDeal.CheckOut`} />
                                        </p>
                                        <div className='text-truncate'>
                                            {selectedProposalVersion?.endDate ? CustomFormatDate(selectedProposalVersion.endDate, null, 'hh:mm', intl) || '-' : '-'}
                                        </div>
                                    </Col>
                                    <Col className='col-2'> {/* lastPMSRefresh */}
                                        <p className='title-sm text-muted'>
                                            <FormattedMessage id={`SalesDeal.LastPMSRefresh`} />
                                        </p>
                                        <div className='text-truncate'>
                                            {selectedProposalVersion?.lastPMSRefresh ? CustomFormatDate(selectedProposalVersion.lastPMSRefresh, null, 'hh:mm', intl) || '-' : '-'}
                                        </div>
                                    </Col>
                                    
                                </Col>
                                <Col className='col-12 d-flex align-items-center justify-content-between mb-2 px-0'>
                                    <Col className='col-3'> {/* pmsStatus */}
                                        <p className='title-sm text-muted'>
                                            <FormattedMessage id={`SalesDeal.PMSStatus`} />
                                        </p>
                                        <div className='text-truncate'>
                                            {selectedProposalVersion?.pmsStatus || '-'}
                                        </div>
                                    </Col>
                                    <Col className='col-3'> {/* updatedAt */}
                                        <p className='title-sm text-muted'>
                                            <FormattedMessage id={`SalesDeal.UpdatedAt`} />
                                        </p>
                                        <div className='text-truncate'>
                                            {selectedProposalVersion?.updatedAt ? CustomFormatDate(selectedProposalVersion.updatedAt, null, 'hh:mm', intl) || '-' : '-'}
                                        </div>
                                    </Col>
                                    <Col className='col-3'> {/* exchangeRateDate */}
                                        <p className='title-sm text-muted'>
                                            <FormattedMessage id={`SalesDeal.ExchangeRateDate`} />
                                        </p>
                                        <div className='text-truncate '>
                                            {selectedProposalVersion?.exchangeRateDate ? CustomFormatDate(selectedProposalVersion.exchangeRateDate, null, 'hh:mm', intl) || '-' : '-'}
                                        </div>
                                    </Col>
                                    <Col className='col-2'> {/* exchangeRate */}
                                        <p className='title-sm text-muted'>
                                            <FormattedMessage id={`SalesDeal.ExchangeRate`} />
                                        </p>
                                        <div className='text-truncate'>
                                            {selectedProposalVersion?.exchangeRate || '-'}
                                        </div>
                                    </Col>
                                </Col>
                                <Col className='col-12 d-flex align-items-center justify-content-between px-0'>
                                    <Col className='col-3'> {/* segment */}
                                        <p className='title-sm text-muted'>
                                            <FormattedMessage id={`SalesDeal.Segment`} />
                                        </p>
                                        <div className='text-truncate'>
                                            {selectedProposalVersion?.segment || '-'}
                                        </div>
                                    </Col>
                                    <Col className='col-3'> {/* subSegment */}
                                        <p className='title-sm text-muted'>
                                            <FormattedMessage id={`SalesDeal.SubSegment`} />
                                        </p>
                                        <div className='text-truncate'>
                                            {selectedProposalVersion?.subSegment || '-'}
                                        </div>
                                    </Col>
                                    <Col className='col-3'> {/* isOption */}
                                        <p className='title-sm text-muted'>
                                            <FormattedMessage id={`SalesDeal.IsOption`} />
                                        </p>
                                        <div className='text-truncate'>
                                            {selectedProposalVersion?.isOption ? <FormattedMessage id="generic.yes" /> : <FormattedMessage id="generic.no" />}
                                        </div>
                                    </Col>
                                    <Col className='col-2'> {/* distChannel */}
                                        <p className='title-sm text-muted'>
                                            <FormattedMessage id={`SalesDeal.DistChannel`} />
                                        </p>
                                        <div className='text-truncate'>
                                            {selectedProposalVersion?.distChannel || '-'}
                                        </div>
                                    </Col>
                                </Col>

                                <Col className='col-12'> {/* Values */}
                                    <hr />
                                </Col>

                                <Col className='col-12 mb-2'>
                                    <p className='m-0 font-weight-bold'><FormattedMessage id="SalesDeal.Values" /></p>
                                </Col>
                                <Col className='col-12 d-flex align-items-center justify-content-between mb-2 px-0'>
                                    <Col className='col-3'> {/* roomsValue */}
                                        <p className='title-sm text-muted'>
                                            <FormattedMessage id={`SalesDeal.RoomsValue`} />
                                        </p>
                                        <div className='text-truncate'>
                                            <FormatAmountNumber currency={proposalCurrency} value={selectedProposalVersion?.roomsValue ?? 0} />
                                        </div>
                                    </Col>
                                    <Col className='col-3'> {/* eventsValue */}
                                        <p className='title-sm text-muted'>
                                            <FormattedMessage id={`SalesDeal.EventsValue`} />
                                        </p>
                                        <div className='text-truncate'>
                                            <FormatAmountNumber currency={proposalCurrency} value={selectedProposalVersion?.eventsValue ?? 0} />
                                        </div>
                                    </Col>
                                    <Col className='col-3'> {/* spacesValue */}
                                        <p className='title-sm text-muted'>
                                            <FormattedMessage id={`SalesDeal.SpacesValue`} />
                                        </p>
                                        <div className='text-truncate'>
                                            <FormatAmountNumber currency={proposalCurrency} value={selectedProposalVersion?.spacesValue ?? 0} />
                                        </div>
                                    </Col>
                                    <Col className='col-2'> {/* equipmentValue */}
                                        <p className='title-sm text-muted'>
                                            <FormattedMessage id={`SalesDeal.EquipmentValue`} />
                                        </p>
                                        <div className='text-truncate'>
                                            <FormatAmountNumber currency={proposalCurrency} value={selectedProposalVersion?.equipmentValue ?? 0} />
                                        </div>
                                    </Col>
                                </Col>
                                <Col className='col-12 d-flex align-items-center justify-content-between mb-2 px-0'>
                                    <Col className='col-3'> {/* fbValue */}
                                        <p className='title-sm text-muted'>
                                            <FormattedMessage id={`SalesDeal.F&BValue`} />
                                        </p>
                                        <div className='text-truncate'>
                                            <FormatAmountNumber currency={proposalCurrency} value={selectedProposalVersion?.fbValue ?? 0} />
                                        </div>
                                    </Col>
                                    <Col className='col-3'> {/* banquetsValue */}
                                        <p className='title-sm text-muted'>
                                            <FormattedMessage id={`SalesDeal.BanquetsValue`} />
                                        </p>
                                        <div className='text-truncate'>
                                            <FormatAmountNumber currency={proposalCurrency} value={selectedProposalVersion?.banquetsValue ?? 0} />
                                        </div>
                                    </Col>
                                    <Col className='col-3'>{/* totalValue */}
                                        <p className='title-sm text-muted m-0'>
                                            <FormattedMessage id={`SalesDeal.TotalValue`} />
                                        </p>
                                        <div className='text-truncate'>
                                            <FormatAmountNumber currency={proposalCurrency} value={selectedProposalVersion?.totalValue ?? 0} />
                                        </div>
                                    </Col> 
                                    <Col className='col-2' />
                                </Col>
                            </Row>
                        </div>
                    </BlockUi>
                </CardBody>
            </StyledModal>
        )
    }
}

export default injectIntl(SalesDealDetailsProposalDetailsModal);
