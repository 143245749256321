import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Col, Row, Card } from 'reactstrap';
import { getAPI } from "../../Base/API";
import { BlankCard } from "../../Base/CommonUIComponents";
import StageModal from './StageModal';
import NewTask from './NewTask';

export class PipelineStages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            pipeline: null,
            taskModal: false,
            stageModal: false
        };
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.getPipelineDetails();
        }
    }

    getPipelineDetails = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ pipeline: data.response ? data.response[0] : null });
            }

            this.setState({ block: false });
        }, `/api/gms/Pipeline/v1/${this.props.match.params.id}`);
    }

    toggleModal = (modal, id) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            selectedStageId: id
        }));
    }

    render() {
        const { block, error, pipeline, taskModal, stageModal, selectedStageId } = this.state;

        return (
            <BlankCard block={block} error={error} >
                {pipeline ?
                    <div>
                        <Row className="align-items-center mb-3">
                            <Col>
                                <h5> <i className="fas fa-rocket" /> {pipeline.name} </h5>
                            </Col>
                            <Col className="text-right">
                                <Button className="text-center bg-host-gradient px-3 py-2" style={{ borderRadius: '15px' }} onClick={() => this.toggleModal('stageModal')}>
                                    + New Stage
                                </Button>
                            </Col>
                        </Row>

                        <div className="d-flex scrollableDiv" style={{ overflow: 'auto hidden', height: '85vh' }}>
                            {pipeline.pipelineStage && pipeline.pipelineStage.map((stage, key) =>
                                <div key={key} className="h-100 pr-2 mr-3" style={{ minWidth: '305px', width: '305px' }}>
                                    <div className="pipeline px-2">
                                        <div className="arrow top" />
                                        <div className="pipelineName">
                                            <b><h5 className="m-0"> {stage.name} </h5></b>
                                        </div>
                                        <div className="arrow bottom" />
                                    </div>

                                    <div className="bg-weekend mt-3 px-2 py-3 shadow h-100" style={{ borderRadius: '5px' }}>
                                        <div>
                                            <h5><b> Who does this </b></h5>
                                        </div>
                                        <div className="mt-2 mb-3 px-2" style={{ minHeight: '60px' }}>
                                            <Card className="border-0 shadow p-2">
                                                {stage.assignType} <b>{stage.assignValue}</b>
                                            </Card>
                                        </div>

                                        <div className="mt-5">
                                            <h5><b> Stage goal  </b></h5>
                                        </div>
                                        <div className="mt-2 px-2" style={{ minHeight: '60px' }}>
                                            <Card className="border-0 shadow p-2">
                                                {stage.subType && stage.subType.subType} <br/>
                                                {stage.subType && stage.subType.goalType && stage.subType.goalType.type}
                                            </Card>
                                        </div>
                                        <div className="mt-5">
                                            <h5><b> Tasks  </b></h5>

                                            <div className="mt-2 px-2" >
                                                {stage.stageTask && stage.stageTask.map((task, k) =>
                                                    <Card className="border-0 shadow p-2 mb-3" key={k}>
                                                        <div>
                                                            <b><i className="far fa-clock" /> {task.dueValue} {task.dueType} {task.dueEvent}</b>
                                                        </div>
                                                        <div>
                                                            {task.description}
                                                        </div>
                                                    </Card>
                                                )}
                                            </div>

                                            <div className="text-center mt-4">
                                                <Button className="text-center bg-host-gradient px-3 py-2" style={{ borderRadius: '15px' }} onClick={() => this.toggleModal('taskModal', stage.id)}>
                                                    + New Task
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="h-100 pr-2" style={{ minWidth: '305px', width: '305px' }}>
                                <div className="pipeline px-2 pointer" onClick={() => this.toggleModal('stageModal')}>
                                    <div className="arrow top bg-host-gradient" />
                                    <div className="pipelineName color-white bg-host-gradient">
                                        <b><h5 className="m-0"> + New Stage </h5></b>
                                    </div>
                                    <div className="arrow bottom bg-host-gradient" />
                                </div>
                                <div className="bg-weekend mt-3 px-2 py-3 shadow h-100" style={{ borderRadius: '5px' }}/>
                            </div>
                        </div>
                    </div>
                : ''}


                {stageModal ?
                    <StageModal
                        stageModal={stageModal}
                        toggleModal={() => this.toggleModal('stageModal')}
                        pipelineId={this.props.match.params.id}
                    />
                : ''}

                {taskModal ?
                    <NewTask
                        taskModal={taskModal}
                        toggle={() => this.toggleModal('taskModal')}
                        selectedStageId={selectedStageId}
                    />
                : ''}
            </BlankCard>
        );
    }
}
export default injectIntl(PipelineStages)