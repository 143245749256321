import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Card, Col, Row } from 'reactstrap';
import { DateRangePicker } from 'react-dates';
import { ErrorAlert } from "../../../Base/ErrorAlert";
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { CustomTable } from '../../../Base/CustomTable';
import { postAPI } from '../../../Base/API';
import { handleNotification } from '../../../Base/Notification';

class ExceedTimeAPIs extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        this.getEventsOverTime();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.productType !== this.props.productType) {
            this.getEventsOverTime();
        }
    }

    getEventsOverTime = (e) => {
        const { productType } = this.props;
        if (e) e.preventDefault();

        this.setState({ block: true });

        const body = {
            usageType: this.props.usageType,
            productType: this.props.productType,
            startDate: moment(this.props.startDate).format("YYYY-MM-DD"),
            endDate: moment(this.props.endDate).format("YYYY-MM-DD"),
            dimensions: productType === 'GMS' ? ["eventName", "pagePath", "customEvent:description", "customEvent:stack", "dateHourMinute"] : ["eventName", "customEvent:description", "customEvent:stack", "dateHourMinute"],
            metrics: ["eventCount"],
            aggregation: [],
            dimensionFilter: {
                filter: {
                    fieldName: "eventName",
                    stringFilter: {
                        matchType: "EXACT",
                        value: "api_exceeded_time"
                    }
                }
            },
            orderBys: [
                {
                    "dimension": {
                        "dimensionName": "dateHourMinute",
                        "orderType": "NUMERIC"
                    },
                    "desc": true
                }
            ]
        }

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.error?.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }

                if(data.errors?.length > 0){
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                
                if(data.response?.length > 0){
                    const payload = data.response[0].payload;
                    if(payload){
                        const json = JSON.parse(payload);
                        this.createChartData(json);
                    }else{
                        this.setState({ block: false });
                    }
                    return;
                }

                this.setState({ block: false });
            }
            else this.setState({ block: false });
        }, `/api/gms/GoogleAnalytics/v1/GetGoogleAnalyticsReport`, body);
    }

    createChartData = (json) => {
        this.setState({ exceedTimeAPIs: json.rows, block: false });
    }


    render() {
        const { exceedTimeAPIs } = this.state;
        const { productType } = this.props;

        const columns = [
            {
                dataField: 'dimensionValues[4].value',
                text: this.props.intl.formatMessage({ id: "PlatformDeepDive.Date" }),
                sort: true,
                formatter: (cell) => cell ? `${cell.substring(0, 4)}-${cell.substring(4, 6)}-${cell.substring(6, 8)} ${cell.substring(8, 10)}:${cell.substring(10)}` : ''
            },
            {
                dataField: 'dimensionValues[1].value',
                text: this.props.intl.formatMessage({ id: "PlatformDeepDive.PagePath" }),
                sort: true,
                hidden: productType === 'PNForms'
            },
            {
                dataField: 'dimensionValues[2].value',
                text: this.props.intl.formatMessage({ id: "PlatformDeepDive.Time" }),
                sort: true
            },
            {
                dataField: 'dimensionValues[3].value',
                text: this.props.intl.formatMessage({ id: "PlatformDeepDive.Stack" }),
                sort: true
            },
            {
                dataField: 'metricValues[0].value',
                text: this.props.intl.formatMessage({ id: "PlatformDeepDive.Count" }),
                sort: true
            },
        ];

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <Row className="align-items-center mb-4 pt-1">
                    <Col>
                        <DateRangePicker
                            startDate={this.props.startDate}
                            startDateId="your_unique_start_date_id_announcement"
                            isOutsideRange={(day) => this.props.isOutsideDate(day)}
                            endDate={this.props.endDate}
                            endDateId="your_unique_end_date_id_announcement"
                            onDatesChange={({ startDate, endDate }) => this.props.handleDates(startDate, endDate)}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            small={true}
                            numberOfMonths={2}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>
                    <Col className="text-right col-1">
                        <Button className="float-right  btn-sm btn-host btn" onClick={this.getEventsOverTime}>
                            <i className="fas fa-search" />
                        </Button>
                    </Col>
                </Row>

                <Card className="shadow border-0 p-2">
                    <CustomTable
                        data={exceedTimeAPIs || []}
                        columns={columns}
                        showTotal={true}
                        remote={false}
                        search={true}
                        hideSizePerPage={false}
                    />
                </Card>
            </BlockUi>
        );
    }

};
export default injectIntl(ExceedTimeAPIs);