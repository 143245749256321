import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import { ActiveInactiveStatusCombo, BlankCard, SelectHotelGroup, StyledCard, SelectHotelByHotelGroup, SelectHotel } from "../../../Base/CommonUIComponents";
import { handleNotification } from "../../../Base/Notification";
import { getAPI, postAPI } from "../../../Base/API";
import CustomSelect from '../../../Base/CustomSelect';
import Authorization from '../../../Base/Authorization';

class PNFormDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            survey: this.props.survey || {},
            modal: false,
            typeOptions: [
                { value: 'LeadForm', label: this.props.intl.formatMessage({ id: `ProfileNowMenu.LeadForm` }) },
                { value: 'DealSatisfactionForm', label: this.props.intl.formatMessage({ id: `ProfileNowMenu.DealSatisfaction` }) },
            ]
        };
    }

    componentDidMount() {
        if (this.props.survey && this.props.survey.id) {
            this.getFormDetails(this.props.survey.id);
        }
    }

    getFormDetails = (id) => {
        this.setState({ block: true }, () =>

            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    this.setState({ formDetails: data.response ? data.response[0] : null, modal: true, block: false });
                }

            }, `/api/gms/Survey/v1/survey/${id}`)
        );
    }

    saveSurvey = (e) => {
        e.preventDefault();

        const body = { ...this.state.survey };

        if (this.props.isAValidJson(body.rawForm) === false) {
            const data = { errors: [{ message: this.props.intl.formatMessage({ id: "generic.InvalidJson" }) }]}
                handleNotification(data);

            return;
        }

        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="PNFormDetails.SurveySaved" />, <FormattedMessage id="generic.success" />);

                    if (data.response && data.response.length > 0) {
                        this.props.updateTable(data.response[0]);
                    }
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Survey/v1/survey`, JSON.stringify({ request: body }));
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            survey: {
                ...this.state.survey,
                [name]: value
            }
        })
    }

    handleChangeSelect = (name, combo) => {
        const { value } = combo || {};
    
        let updatedSurvey = {
            ...this.state.survey,
            [name]: value ?? null,
        };
    
        if (name === 'anonymous' && value === false) {
            updatedSurvey = { ...updatedSurvey, createProfile: false, dOptIn: false };
        }
    
        if (name === 'createProfile' && value === false) {
            updatedSurvey = { ...updatedSurvey, dOptIn: false };
        }
    
        if (name === 'type' && value === 'LeadForm') {
            updatedSurvey = { ...updatedSurvey, anonymous: true, createProfile: true };
        }
    
        this.setState({ survey: updatedSurvey });
    };

    render() {
        const { block, error, survey, typeOptions } = this.state;
        const { modal, toggleModal, intl } = this.props;
         
        const trueOrFalseOptions = [
            { value: true, label: intl.formatMessage({ id: `generic.yes`}) },
            { value: false, label: intl.formatMessage({ id: `generic.no`}) }
        ];

        return (
            <StyledCard block={block} error={error}>
                <Modal isOpen={modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                    <Button onClick={toggleModal} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody>
                        <BlankCard block={this.state.block} error={this.state.error}>
                            <Form onSubmit={this.saveSurvey}>
                                <Row className="mb-3">
                                    <Col>
                                        <h5>{this.state.survey?.id ? <FormattedMessage id="PNFormDetails.EditSurvey" /> : <FormattedMessage id="PNFormDetails.CreateSurvey" />}</h5>
                                    </Col>
                                    <Col className="text-right col-3">
                                        <Button className="btn-sm btn-host" type="submit">
                                            <i className="fas fa-save" />
                                        </Button>
                                    </Col>
                                </Row>

                                <FormGroup row className="pt-2">
                                    <Label sm={2}> <FormattedMessage id="PNFormDetails.HotelGroup" /> </Label>
                                    <Col sm={4}>
                                        <SelectHotelGroup
                                            name="hotelGroupId"
                                            onChangeFunc={this.handleChangeSelect}
                                            value={survey?.hotelGroupId || ''}
                                            placeholder={''}
                                            required
                                        />
                                    </Col>
                                    <Label sm={2}> <FormattedMessage id="PNFormDetails.Status" /> </Label>
                                    <Col sm={4}>
                                        <ActiveInactiveStatusCombo
                                            name='active'
                                            onChangeFunc={this.handleChangeSelect}
                                            value={survey ? survey.active : ''}
                                            placeholder={''}
                                            required
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={2}> <FormattedMessage id="PNFormDetails.Hotel" /> </Label>
                                    <Col sm={4}>
                                        <Authorization
                                            perform="admin:view"
                                            yes={() => (
                                                <SelectHotel
                                                    name="hotelId"
                                                    onChangeFunc={this.handleChangeSelect}
                                                    value={survey?.hotelId || ''}
                                                    placeholder={''}
                                                    required
                                                />
                                            )}
                                            no={() => 
                                                <SelectHotelByHotelGroup
                                                    name="hotelId"
                                                    onChangeFunc={this.handleChangeSelect}
                                                    value={survey?.hotelId || ''}
                                                    placeholder={''}
                                                    required
                                                />
                                            }
                                        />
                                    </Col>
                                    <Label sm={2}> <FormattedMessage id="PNFormDetails.Image" /></Label>
                                    <Col sm={4} >
                                        <Input
                                            type="text"
                                            name="imageUrl"
                                            value={survey?.imageUrl || ''}
                                            onChange={this.handleChange}
                                            maxLength="500"
                                        />
                                    </Col>
                                    
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={2}> <FormattedMessage id="PNFormDetails.Title" /></Label>
                                    <Col sm={4} >
                                        <Input
                                            type="text"
                                            name="title"
                                            value={survey?.title || ''}
                                            onChange={this.handleChange}
                                            maxLength="100"
                                        />
                                    </Col>
                                    <Label sm={2}> <FormattedMessage id="PNFormDetails.Type" /> </Label>
                                    <Col sm={4}>
                                        <CustomSelect
                                            name='type'
                                            options={typeOptions}
                                            onChange={e => this.handleChangeSelect('type', e)}
                                            value={typeOptions.find(({value}) => value === survey?.type)}
                                            placeholder={''}
                                            isSearchable={false}
                                            isClearable={true}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={2}> <FormattedMessage id="PNFormDetails.anonymous" /> </Label>
                                    <Col sm={4}>
                                        <CustomSelect
                                            name='anonymous'
                                            options={trueOrFalseOptions}
                                            onChange={e => this.handleChangeSelect('anonymous', e)}
                                            value={trueOrFalseOptions.find(({value}) => value === (survey?.anonymous??false))}
                                            placeholder={''}
                                            isSearchable={false}
                                            isDisabled={survey?.type === 'LeadForm'}
                                        />
                                    </Col>

                                    <Label sm={2}> <FormattedMessage id="PNFormDetails.CreateProfile" /> </Label>
                                    <Col sm={4}>
                                        <CustomSelect
                                            name='createProfile'
                                            options={trueOrFalseOptions}
                                            onChange={e => this.handleChangeSelect('createProfile', e)}
                                            value={trueOrFalseOptions.find(({value}) => value === (survey?.createProfile??false))}
                                            placeholder={''}
                                            isSearchable={false}
                                            isDisabled={!survey?.anonymous || survey?.type === 'LeadForm'}
                                        />
                                    </Col>
                                </FormGroup>
                                
                                <FormGroup row>
                                    <Label sm={2}> <FormattedMessage id="PNFormDetails.dOptIn" /> </Label>
                                    <Col sm={4}>
                                        <CustomSelect
                                            name='dOptIn'
                                            options={trueOrFalseOptions}
                                            onChange={e => this.handleChangeSelect('dOptIn', e)}
                                            value={trueOrFalseOptions.find(({value}) => value === (survey?.dOptIn?? false))}
                                            placeholder={''}
                                            isSearchable={false}
                                            isDisabled={!survey?.anonymous || !survey?.createProfile}
                                        />
                                    </Col>

                                    <Label sm={2}> <FormattedMessage id="PNFormDetails.AutoConsent" /> </Label>
                                    <Col sm={4}>
                                        <CustomSelect
                                            name='dOptIn'
                                            options={trueOrFalseOptions}
                                            onChange={e => this.handleChangeSelect('autoConsent', e)}
                                            value={trueOrFalseOptions.find(({ value }) => value === (survey?.autoConsent ?? false))}
                                            placeholder={''}
                                            isSearchable={false}
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup row>
                                    <Label sm={2}> <FormattedMessage id="PNFormDetails.Name" /></Label>
                                    <Col sm={4}>
                                        <Input
                                            type="text"
                                            name="name"
                                            value={survey?.name || ''}
                                            onChange={this.handleChange}
                                            required
                                            maxLength="100"
                                        />
                                    </Col>

                                    <Label sm={2}><FormattedMessage id="PNFormDetails.CollectUserLang" /></Label>
                                    <Col sm={4}>
                                        <CustomSelect
                                            id="collectUserLang" 
                                            name='collectUserLang'
                                            options={trueOrFalseOptions}
                                            required={false}
                                            isSearchable={false}
                                            value={trueOrFalseOptions.find(({ value }) => value === (survey?.collectUserLang ?? false))}
                                            onChange={e => this.handleChangeSelect('collectUserLang', e)}
                                            placeholder={''}
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup row>
                                    <Label sm={2}> <FormattedMessage id="PNFormDetails.SurveyJson" /></Label>
                                    <Col sm={10} >
                                        <Input
                                            style={{ minHeight: '350px' }}
                                            type="textarea"
                                            name="rawForm"
                                            value={survey?.rawForm || ''}
                                            onChange={this.handleChange}
                                            required
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={2}> <FormattedMessage id="PNFormDetails.Style" /></Label>
                                    <Col sm={10} >
                                        <Input
                                            style={{ minHeight: '200px' }}
                                            type="textarea"
                                            name="css"
                                            value={survey?.css || ''}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </FormGroup>
                            </Form>
                        </BlankCard>
                    </ModalBody>
                </Modal>
            </StyledCard>
        );
    }
}
export default injectIntl(PNFormDetails)