import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Badge, UncontrolledTooltip, Card, CustomInput } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getConvertedDateToHotelTimezone } from '../../Base/ReferenceDataFunctions';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import CustomSelect from "../../Base/CustomSelect";
import BlockUi from 'react-block-ui';
import { getAPI } from "../../Base/API";
import moment from 'moment';
import { CoolBadge } from '../../Base/CommonUIComponents';

export function getStatus() {
    const status = [
        { value: 'processedDate', label: <FormattedMessage id="MarketingFunctions.Processed" />, color: 'yellow', icon: "fas fa-spinner" },
        { value: 'sentDate', label: <FormattedMessage id="MarketingFunctions.SentSingular" />, color: 'cyan', icon: "fas fa-paper-plane" },
        { value: 'deliveredDate', label: <FormattedMessage id="MarketingFunctions.DeliveredSingular" />, color: 'green', icon: "fas fa-inbox" },
        { value: 'viewDate', label: <FormattedMessage id="MarketingFunctions.ViewedSingular" />, color: 'violet', icon: "icon-icon-preview" },
        { value: 'clickDate', label: <FormattedMessage id="MarketingFunctions.ClickedSingular" />, color: 'host', icon: "fas fa-hand-pointer" },
        { value: 'bounceDate', label: <FormattedMessage id="MarketingFunctions.Bounced" />, color: 'orange', icon: "fas fa-undo" },
        { value: 'bounceSoftDate', label: <FormattedMessage id="MarketingFunctions.BounceSoft" />, color: 'light-orange', icon: "fas fa-bug" },
        { value: 'bounceHardDate', label: <FormattedMessage id="MarketingFunctions.BounceHard" />, color: 'dark-orange', icon: "fas fa-ban" },
        { value: 'abuseDate', label: <FormattedMessage id="MarketingFunctions.Abuses" />, color: 'light-red', icon: "fas fa-times" },
        { value: 'failedDate', label: <FormattedMessage id="MarketingFunctions.Failed" />, color: 'red', icon: "fas fa-exclamation" },
        { value: 'removedDate', label: <FormattedMessage id="MarketingFunctions.RemovedSingular" />, color: 'purple', icon: "fas fa-minus-circle" },
    ]

    return status;
}

export function getMostRecentDateKey(data) {
    let mostRecentIdx = null;
    Object.keys(data).forEach(key => {
        if (data[key]) {
            let idx = getStatus().findIndex(x => x.value === key);
            if ((!mostRecentIdx || idx > mostRecentIdx) && idx !== -1) {
                mostRecentIdx = idx;
            }
        }
    });
    return mostRecentIdx;
}


export const LastStatusBadge = ({ data }) => {
    if (data.status && data.status.toLowerCase() === 'finished') {
        return <Badge className={' color-white bg-green'} > <FormattedMessage id="MarketingFunctions.Finished" /> </Badge >
    }
    else {
        const mostRecentDateKey = getMostRecentDateKey(data);
        const lastStatus = mostRecentDateKey !== null ? getStatus()[mostRecentDateKey] : null;
        return lastStatus ?
            <Badge className={' color-white bg-' + lastStatus.color} > {lastStatus.label} </Badge >
            : ''
    }
};

export const MarketingSendRunStatus = ({ comm, index }) => {
    return (
        <div>
            {comm.status || comm.statusDate ?
                <Row className="mt-4 pt-2 align-items-center mx-2 w-100">
                    {getStatus().map((status, key) => {
                        const date = comm[status.value];

                        return date ?
                            <Fragment key={key}>
                                <div className={"d-flex flex-column align-items-center mb-4 "} id={`Status-${index}-${key}`} style={{ width: '14.28%', position: 'relative' }}>
                                    <div style={{ borderBottom: '1px solid lightgrey', zIndex: '0', position: 'absolute', width: '100%', top: '20%' }} />

                                    <div className={`d-flex align-items-center justify-content-center bg-` + (status.color)} style={{ color: 'white', width: '30px', height: '30px', borderRadius: '30px', zIndex: '50' }}>
                                        <i className={status.icon} />
                                    </div>
                                    {status.label}
                                    <b style={{ height: '21px' }}>{date ? getConvertedDateToHotelTimezone(date).format('MMM DD, YYYY HH:mm') : ''}</b>
                                </div>
                                {status.value === 'clickDate' && comm.urlClicked ?
                                    <UncontrolledTooltip placement="bottom" target={`Status-${index}-${key}`}>
                                        <div><FormattedMessage id="MarketingFunctions.ClickedLinks" />: </div>
                                        {comm.urlClicked.map((url, idx) => 
                                            <div key={idx}> { url } </div>
                                        )}
                                    </UncontrolledTooltip>
                                : ''}
                            </Fragment>
                        : ''
                    })}

                    {comm.status && comm.status.toLowerCase() === 'finished' ?
                        <div className={"d-flex flex-column align-items-center mb-4 "} style={{ width: '14.28%', position: 'relative' }}>
                            <div style={{ borderBottom: '1px solid lightgrey', zIndex: '0', position: 'absolute', width: '100%', top: '20%' }} />

                            <div className={`d-flex align-items-center justify-content-center bg-green`} style={{ color: 'white', width: '30px', height: '30px', borderRadius: '30px', zIndex: '50' }}>
                                <i className="fas fa-flag-checkered" />
                            </div>
                            <FormattedMessage id="MarketingFunctions.Finished" />
                            <b style={{ height: '21px' }}>{comm.statusDate ? getConvertedDateToHotelTimezone(comm.statusDate).format('MMM DD, YYYY HH:mm') : ''}</b>
                        </div>
                    : ''}
                </Row>
                :
                <div className="font_size_xxs pl-5 mt-2"> <FormattedMessage id="MarketingFunctions.NoDetailsAvailable" /> </div>
            }
        </div>
    )
};



export const PercentageBubble = ({ value, text, color, visibility }) => {
    return <div style={{ height: '50px', visibility: visibility }}>
        <div className="text-center">
            <svg viewBox="0 0 36 36" style={{ width: '50px' }}>
                <path d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" style={{ fill: 'none', stroke: '#eee', strokeWidth: '3.8'}} />
                <path strokeDasharray={`${value}, 100`} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                    style={{ fill: 'none', strokeWidth: '2.8', strokeLinecap: 'round', animation: 'progress 1s ease-out forwards', stroke: color }}
                />
                <text x="18" y="21.5" style={{ fontSize: '11px', textAnchor: 'middle' }} >
                    {value}%
                </text>
            </svg>
        </div>
        <div className="font_size_xxs text-center" style={{ color: color }}> {text} </div>
    </div>
}

//#region campaign
export function getKpis() {
    const kpis = [
        { value: 'totalSent', label: <FormattedMessage id="MarketingFunctions.Sent" />, bgColor: 'cyan', icon: "fas fa-paper-plane" },
        /*{ value: 'totalDelivered', label: <FormattedMessage id="MarketingFunctions.Delivered" />, bgColor: 'green', icon: "fas fa-inbox" },*/
        { value: 'totalViewed', label: <FormattedMessage id="MarketingFunctions.Viewed" />, bgColor: 'violet', icon: "icon-icon-preview" },
        { value: 'totalClicked', label: <FormattedMessage id="MarketingFunctions.Clicked" />, bgColor: 'host', icon: "fas fa-hand-pointer" },
        { value: 'totalBounced', label: <FormattedMessage id="MarketingFunctions.Bounced" />, bgColor: 'orange', icon: "fas fa-undo" },
        { value: 'totalAbuses', label: <FormattedMessage id="MarketingFunctions.Abuses" />, bgColor: 'light-red', icon: "fas fa-times" },
        { value: 'totalFailed', label: <FormattedMessage id="MarketingFunctions.Failed" />, bgColor: 'red', icon: "fas fa-exclamation" },
        { value: 'totalRemoved', label: <FormattedMessage id="MarketingFunctions.Removed" />, bgColor: 'purple', icon: "fas fa-minus-circle" }
    ]

    return kpis;
}

export const CampaignStats = ({ marketingSendKpis, styles, showSentCircle = true, isSmsEgoi = false }) => {
    let totalSent = 0, totalErrors = 0, totalViewed = 0, totalClicked = 0, totalRemoved = 0;

    marketingSendKpis.forEach(el => {
        totalSent += el.totalSent;
        totalErrors += el.totalAbuses + el.totalBounced + el.totalFailed;
        totalViewed += el.totalViewed;
        totalClicked += el.totalClicked;
        totalRemoved += el.totalRemoved;
    });

    const visibility = isSmsEgoi ? 'hidden' : '';

    return <div className="w-100" style={styles ? styles : { display: 'flex', justifyContent: 'space-around' }}>
        {showSentCircle ?
            <div className="d-flex flex-column align-items-center">
                <div className={`d-flex align-items-center justify-content-center color-white bg-cyan`}
                    style={{ width: '45px', height: '45px', borderRadius: '30px' }}>
                    <b>{totalSent}</b>
                </div>
                <span className="color-cyan font_size_xxs pt-2"> <FormattedMessage id="MarketingFunctions.Sent" /> </span>
            </div>
        : ''}
        <PercentageBubble
            value={totalErrors && totalSent ? parseFloat(totalErrors * 100 / totalSent).toFixed() : 0}
            text={<FormattedMessage id="MarketingFunctions.Errors" />} color="#F16D84"
            visibility={visibility}
        />
        <PercentageBubble
            value={totalViewed && totalSent ? parseFloat(totalViewed * 100 / totalSent).toFixed() : 0}
            text={<FormattedMessage id="MarketingFunctions.Viewed" />} color="#CF53FA"
            visibility={visibility}
        />
        <PercentageBubble
            value={totalClicked && totalSent ? parseFloat(totalClicked * 100 / totalSent).toFixed() : 0}
            text={<FormattedMessage id="MarketingFunctions.Clicked" />} color="#0665ff"
            visibility={visibility}
        />
        <PercentageBubble
            value={totalRemoved && totalSent ? parseFloat(totalRemoved * 100 / totalSent).toFixed() : 0}
            text={<FormattedMessage id="MarketingFunctions.Removed" />} color="#7054E5"
            visibility={visibility}
        />
    </div>
}


export const RecorringCampaignsDates = ({ children, campaign, handleChange, handleMultiDates, handleDateChange, style = {}, styleHotel = { display: 'none' }, inputClassName, labelClassName }) => {

    const [focused, setFocused] = useState(false);
    const [focused2, setFocused2] = useState(false);
    const [focused3, setFocused3] = useState(false);
    const [focused4, setFocused4] = useState(false);

    const inclusiveDateOptions = [
        { value: true, label: <FormattedMessage id="CampaignDetails.IncludeDates" /> },
        { value: false, label: <FormattedMessage id="CampaignDetails.ExcludeDates" /> }
    ]

    return (
        <div>
            <Row className="mt-2">
                <div style={style} className={labelClassName}> <h5 className="m-0"><FormattedMessage id="CampaignDetails.InclusiveDates" /></h5></div>
                {
                    campaign.inclusiveDates === false ?
                        <>
                            <div style={style} className={labelClassName}> <h5><FormattedMessage id="CampaignDetails.NoBookingsFromDate" /></h5></div>
                            <div style={style} className={labelClassName}> <h5><FormattedMessage id="CampaignDetails.NoBookingsToDate" /></h5></div>
                            <div style={style} className={labelClassName}> <h5><FormattedMessage id="CampaignDetails.NoStaysFromDate" /></h5></div>
                            <div style={style} className={labelClassName}> <h5><FormattedMessage id="CampaignDetails.NoStaysToDate" /></h5></div>
                            <div style={styleHotel} className={labelClassName}> <h5><FormattedMessage id="CampaignDetails.Hotels" /></h5></div>
                        </>
                        :
                        <>
                            <div style={style} className={labelClassName}> <h5><FormattedMessage id="CampaignDetails.BookingsFromDate" /></h5></div>
                            <div style={style} className={labelClassName}> <h5><FormattedMessage id="CampaignDetails.BookingsToDate" /></h5></div>
                            <div style={style} className={labelClassName}> <h5><FormattedMessage id="CampaignDetails.StaysFromDate" /></h5></div>
                            <div style={style} className={labelClassName}> <h5><FormattedMessage id="CampaignDetails.StaysToDate" /></h5></div>
                            <div style={styleHotel} className={labelClassName}> <h5><FormattedMessage id="CampaignDetails.Hotels" /></h5></div>
                        </>
                }
            </Row>
            <Row>
                <div className={inputClassName} style={style}>
                    <CustomSelect
                        options={inclusiveDateOptions}
                        value={inclusiveDateOptions.find(el => el.value === campaign.inclusiveDates) || ''}
                        isSearchable
                        placeholder={''}
                        onChange={(combo) => handleChange({ target: { name: 'inclusiveDates', value: combo ? combo.value : null } })}
                    />
                </div>
                <div className={inputClassName} style={style}>
                    <SingleDatePicker
                        id="bookingsFromDate"
                        isOutsideRange={day => false}
                        date={campaign.bookingsFromDate ? moment(campaign.bookingsFromDate) : ''}
                        focused={focused}
                        onFocusChange={({ focused }) => setFocused(focused)}
                        small={true}
                        numberOfMonths={1}
                        showDefaultInputIcon={true}
                        onDateChange={date => handleMultiDates(date, 'bookingsFromDate', 'bookingsToDate')}
                        showClearDate={true}
                    />
                </div>
                <div className={inputClassName} style={style}>
                    <SingleDatePicker
                        id="bookingsToDate"
                        isOutsideRange={day => day < moment(campaign.bookingsFromDate)}
                        date={campaign.bookingsToDate ? moment(campaign.bookingsToDate) : ''}
                        focused={focused2}
                        onFocusChange={({ focused }) => setFocused2(focused)}
                        small={true}
                        numberOfMonths={1}
                        showDefaultInputIcon={true}
                        onDateChange={date => handleDateChange('bookingsToDate', date)}
                        disabled={campaign.bookingsFromDate ? false : true}
                        showClearDate={true}
                    />
                </div>
                <div className={inputClassName} style={style}>
                    <SingleDatePicker
                        id="staysFromDate"
                        isOutsideRange={day => false}
                        date={campaign.staysFromDate ? moment(campaign.staysFromDate) : ''}
                        focused={focused3}
                        onFocusChange={({ focused }) => setFocused3(focused)}
                        small={true}
                        numberOfMonths={1}
                        showDefaultInputIcon={true}
                        onDateChange={date => handleMultiDates(date, 'staysFromDate', 'staysToDate')}
                        showClearDate={true}
                    />
                </div>
                <div className={inputClassName} style={style}>
                    <SingleDatePicker
                        id="staysToDate"
                        isOutsideRange={day => day < moment(campaign.staysFromDate)}
                        date={campaign.staysToDate ? moment(campaign.staysToDate) : null}
                        focused={focused4}
                        onFocusChange={({ focused }) => setFocused4(focused)}
                        small={true}
                        numberOfMonths={1}
                        showDefaultInputIcon={true}
                        onDateChange={date => handleDateChange('staysToDate', date)}
                        disabled={campaign.staysFromDate ? false : true}
                        showClearDate={true}
                    />
                </div>
                {children}
            </Row>
        </div>
    )
};

export const UniqueCampaignsDates = ({ children, stayFrom, stayTo, bookingFromDate, bookingToDate, noStayFrom, noStayTo, noBookingFromDate, noBookingToDate, handleDates, intl, style }) => {

    const [focused, setFocused] = useState(null);
    const [focused2, setFocused2] = useState(null);
    const [focused3, setFocused3] = useState(null);
    const [focused4, setFocused4] = useState(null);

    return (
        <Row className="justify-content-between">
            <div style={style}>
                <DateRangePicker
                    startDate={stayFrom ? moment(stayFrom) : null}
                    startDateId="stayFrom"
                    isOutsideRange={day => false}
                    endDate={stayTo ? moment(stayTo) : null}
                    endDateId="stayTo"
                    onDatesChange={({ startDate, endDate }) => handleDates(startDate, endDate, 'stayFrom', 'stayTo')}
                    focusedInput={focused}
                    onFocusChange={(focusedInput) => setFocused(focusedInput)}
                    small={true}
                    numberOfMonths={2}
                    showDefaultInputIcon={true}
                    renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                    minimumNights={0}
                />
            </div>
            <div style={style}>
                <DateRangePicker
                    startDate={noStayFrom ? moment(noStayFrom) : null}
                    startDateId="noStayFrom"
                    isOutsideRange={day => false}
                    endDate={noStayTo ? moment(noStayTo) : null}
                    endDateId="noStayTo"
                    onDatesChange={({ startDate, endDate }) => handleDates(startDate, endDate, 'noStayFrom', 'noStayTo')}
                    focusedInput={focused2}
                    onFocusChange={(focusedInput) => setFocused2(focusedInput)}
                    small={true}
                    numberOfMonths={2}
                    showDefaultInputIcon={true}
                    renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                    minimumNights={0}
                />
            </div>
            <div  style={style}>
                <DateRangePicker
                    startDate={bookingFromDate ? moment(bookingFromDate) : null}
                    startDateId="bookingFromDate"
                    isOutsideRange={day => false}
                    endDate={bookingToDate ? moment(bookingToDate) : null}
                    endDateId="bookingToDate"
                    onDatesChange={({ startDate, endDate }) => handleDates(startDate, endDate, 'bookingFromDate', 'bookingToDate')}
                    focusedInput={focused3}
                    onFocusChange={(focusedInput) => setFocused3(focusedInput)}
                    small={true}
                    numberOfMonths={2}
                    showDefaultInputIcon={true}
                    renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                    minimumNights={0}
                />
            </div>
            <div style={style}>
                <DateRangePicker
                    startDate={noBookingFromDate ? moment(noBookingFromDate) : null}
                    startDateId="noBookingFromDate"
                    isOutsideRange={day => false}
                    endDate={noBookingToDate ? moment(noBookingToDate) : null}
                    endDateId="noBookingToDate"
                    onDatesChange={({ startDate, endDate }) => handleDates(startDate, endDate, 'noBookingFromDate', 'noBookingToDate')}
                    focusedInput={focused4}
                    onFocusChange={(focusedInput) => setFocused4(focusedInput)}
                    small={true}
                    numberOfMonths={2}
                    showDefaultInputIcon={true}
                    renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                    minimumNights={0}
                />
            </div>
            {
                children
            }
        </Row>
    )
};


export const ReviewRange = ({ label, reviewName, reviewValue, biggerThanValue, handleBiggerThan, handleRangeInput, max = '100', pretty }) => {
    return (
        <Row className="my-1 d-flex align-items-center">
        {pretty ?
            <>
                <Col className="mb-1 col-5 d-flex align-items-center justify-content-between" style={{ fontSize: '0.8rem' }}>
                    {label}
                    <CoolBadge
                        text={biggerThanValue ? ' >= ' : ' <= '}
                        onClick={handleBiggerThan}
                        squared={true}
                    />
                </Col>
                <Col className='col-6'>
                    <CustomInput
                        type="range"
                        id={reviewName}
                        min="1" max={max}
                        step="1"
                        value={reviewValue}
                        onChange={(e) => handleRangeInput({ target: { value: e.target.value, name: e.target.id } })} />
                </Col>
                <Col className="text-center sm text-sm col-1 px-1">
                    {reviewValue}
                </Col>
            </>
        :
            <>
                <Col className="col-3">
                    {label}
                </Col>
                <Col className="col-1">
                    <Badge className="p-2 my-1 mr-2 pointer bg-host" onClick={handleBiggerThan}>
                        {biggerThanValue ? ' >= ' : ' <= '}
                    </Badge>
                </Col>
                <Col sm={6}>
                    <CustomInput
                        type="range"
                        id={reviewName}
                        min="1" max={max}
                        step="1"
                        value={reviewValue}
                        onChange={(e) => handleRangeInput({ target: { value: e.target.value, name: e.target.id } })} />
                </Col>
                <Col className="text-center sm text-sm col-1 px-1">
                    {reviewValue}
                </Col>
            </>
        }
        </Row>
    );
}

export const FrequencyText = ({ marketingType, frequency, frequencyAdjust, intl, pastSimple }) => {

    return marketingType !== 'LinkedCampaign' ?
        marketingType !== 'Birthday' && marketingType !== 'CreationDay' ?
        <>
            {frequency && frequencyAdjust !== null && frequencyAdjust !== undefined ?
                frequencyAdjust == 0 ?
                        `${pastSimple ? intl.formatMessage({ id: "ProfileMarketing.SentAt" }) : intl.formatMessage({ id: "Campaigns.SendAt" })} ${intl.formatMessage({ id: `Campaigns.${frequency}` }).toLowerCase()}`
                    :
                        `${pastSimple ? intl.formatMessage({ id: "ProfileMarketing.Sent" }) : intl.formatMessage({ id: "Campaigns.Send" })} ${Math.abs(frequencyAdjust)} ${intl.formatMessage({ id: "Campaigns.Days" })} ${frequencyAdjust > 0 ? intl.formatMessage({ id: `Campaigns.after` })
                        : intl.formatMessage({ id: `Campaigns.before` })} ${intl.formatMessage({ id: `Campaigns.${frequency}` }).toLowerCase()}`
                :
                intl.formatMessage({ id: "Campaigns.SelectEventAndFreq" })
            }
        </>
        :
            <>
                {frequencyAdjust && frequencyAdjust != 0 ?
                    `${pastSimple ? intl.formatMessage({ id: "ProfileMarketing.Sent" }) : intl.formatMessage({ id: "Campaigns.Send" })} ${Math.abs(frequencyAdjust)} ${intl.formatMessage({ id: "Campaigns.Days" })}
                                                            ${frequencyAdjust > 0 ? intl.formatMessage({ id: `Campaigns.after` }) : intl.formatMessage({ id: `Campaigns.before` })} ${intl.formatMessage({ id: `Campaigns.${marketingType}FreqText` })}`
                    :
                    `${pastSimple ? intl.formatMessage({ id: "ProfileMarketing.SentAt" }) : intl.formatMessage({ id: "Campaigns.SendAt" })} ${intl.formatMessage({ id: `Campaigns.${marketingType}` }).toLowerCase()}`
                }
            </>
        :
        <>
            {pastSimple ? intl.formatMessage({ id: "ProfileMarketing.Sent" }) : intl.formatMessage({ id: "Campaigns.Send" })} <FormattedMessage id="Campaigns.SendAfterXDays" values={{ frequencyAdjust: frequencyAdjust }} />
        </>
}
//#endregion


//#region Forms

const PNFormAnswer = ({ answer, idx, form }) => {
    const formQuestion = form?.find((q) => q.name === answer.question);
    const title = typeof formQuestion?.title === 'object' ? formQuestion?.title?.default : formQuestion?.title;
    const question = (!title || title === '') ? (typeof formQuestion?.placeholder === 'object' ? formQuestion?.placeholder?.default : formQuestion?.placeholder) : title;

    return (
        <div className="formQuestion" tabIndex={'0'} id={'question' + answer.question}>
            <div className="mb-1 text-muted">
                {question && question !== '' ? question : answer.question}
            </div>
            <div className="mb-3">
                <div className="pl-2 ml-1">
                    <RenderPNResp
                        answer={answer.answer}
                        idx={idx}
                        type={formQuestion?.type}
                        rateMax={formQuestion?.rateMax}
                        choices={formQuestion?.choices}
                        rows={formQuestion?.rows}
                        columns={formQuestion?.columns}
                    />
                </div>
            </div>
        </div>
    );
}

export const FormAnswers = ({ children, answers, isPNForm, isTypeForm, form }) => {
    return <Card className="shadow border-0 h-100" body>
        {children}

        {answers && answers.map((e, k1) => 
            isPNForm ?
                <PNFormAnswer form={form} key={k1} idx={k1} answer={e}/>
            :
                <ReviewProFormAnswer key={k1} answer={e}/>
        )}
    </Card>
}

const ReviewProFormAnswer = ({ answer }) => {
    return (
        <div>
            <div className="mb-1 text-muted">
                {answer.question}
            </div>
            {answer.outOf ?
                <div className="d-flex mb-2  pl-2 ml-1">
                    {Array.from({ length: answer.outOf }, (_, i) => i + 1).map((n, key) =>
                        <div className={'d-flex justify-content-center align-items-center mb-2 ' + (answer.answer === n.toString() ? 'bg-host text-host-light' : 'bg-host-light')}
                            style={{ borderRadius: '100px', fontSize: '13px', marginRight: '12px', width: '25px', height: '23px' }} key={key}>
                            {n}
                        </div>
                    )}
                </div>
                :
                <div className="mb-3  pl-2 ml-1">
                    {typeof answer.answer === 'object' ?
                        answer.answer ? <b> {answer.answer?.title} <br /> {answer.answer?.description} </b> : '' 
                    :
                        <div>
                            <b>
                                {answer.answerText && Array.isArray(answer.answerText) ?
                                    answer.answerText.map((t, k) => 
                                        <span>
                                            <span className='mr-1'>{k > 0 ? ',' : ''}</span>
                                            <span>{t}</span>
                                        </span>
                                    )
                                : answer.answer}
                            </b>
                            {answer.linked ?
                                <div className="mt-2 ">
                                    {answer.linked.map((answerLinked, key) => <ReviewProFormAnswer idx={key} answer={answerLinked}/>)}
                                </div>
                            :
                                <div/>
                            }
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export const improvFormObj = (data) => {
    const forms = [];

    if (data && data.length > 0) {
        data.forEach(form => {
            if(form.formSource !== 'PNForm') forms.push(form);
            else{
                const questions = {
                    id: form.id,
                    profileId: form.profileId,
                    responseDate: form.responseDate,
                    answers: [],
                    formName: form.formName,
                    formSource: form.formSource,
                    formTags: form.formTags,
                    externalFormId: form.externalFormId
                };

                if(form.rawForm){
                    let rawForm = JSON.parse(form.rawForm);
                    let q = [...rawForm.pages]; 
                    while (q.filter(({elements}) => elements).length > 0) {
                        q = q.flatMap((page, i) => {
                            if(page.elements){
                                q.splice(i, 0);
                                return page.elements;
                            }
                            else{
                                return page;
                            }
                        });
                    };

                    questions.rawForm = q;
                }

                const rawData = form.rawData;

                rawData && Object.keys(rawData).forEach(question => {
                    if (question) {
                        questions.answers.push({ question, answer: rawData[question] });
                    }
                });
                forms.push(questions);
            }
        })
    }
    
    return forms;
}

export const getArray = (size) => {
    const array = [];
    for (let i = 1; i <= size; i++) {
        array.push(i);
    }
    return array;
}

export const RenderPNResp = ({ answer, idx, type, rateMax, choices, rows, columns }) => {
    if(type && answer && type === "checkbox"){
        return answer.map((value, key) => 
            <div key={key}>
                <i className="fas fa-check mr-1"/>
                <b>{value}</b>
            </div>
        );
    }
    else if(type && answer && type === "rating"){
        return (
            <div className='d-flex mb-2'>
                {getArray(rateMax??answer).map((value, key) => 
                    <div className={answer !== value ? 'd-flex justify-content-center align-items-center mb-2 bg-host-light' : 'd-flex justify-content-center align-items-center mb-2 bg-host text-host-light'}
                    key={key}
                    style={{
                        borderRadius: "100px",
                        fontSize: "13px",
                        marginRight: "12px",
                        width: "25px",
                        height: "23px"
                    }}>
                        {value}
                    </div>
                )}
            </div>
        );
    }
    else if(type && answer && type === "ranking"){
        return (
            answer.map((value, key) =>
                <div key={key} style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <div>{key + 1} - </div>
                    <div><b>{value}</b></div>
                </div>
            )
        );
    }
    else if(type && answer && type === "imagepicker"){
        const imageLink = choices && choices.find(({value}) => value === answer)?.imageLink;
        return (
            <div>
                {imageLink ?
                    <div>
                        <div>
                            <img src={imageLink} style={{ width: 'auto', maxHeight: '155px' }}/>
                        </div>
                        <div className='mt-2'>
                            <b>{answer}</b>
                        </div>
                    </div>
                :
                    <div>
                        <b>{answer}</b>
                    </div>
                }
            </div>
        );
    }
    else if(type && answer && type === "signaturepad"){
        return (
            <img src={answer} style={{ border: "1px solid #c9c9c9", borderRadius: "5px" }}/>
        );
    }
    else if (type && answer && type === "matrix") {
        return (
            <div>
                {rows.map((r, key) => 
                    <Row key={key}>
                        <Col className='col-2 d-flex align-items-center'>
                            {typeof r?.text === 'object' ?
                                Object.keys(r.text).map((objKey, objIndex) => <div key={objIndex}> {objKey}: {r.text[objKey]}</div>)
                            : r}
                        </Col>
                        {columns.map((col, key) => 
                            <Col key={key} className='text-center'>
                                <div>
                                    {typeof col?.text === 'object' ?
                                        Object.keys(col.text).map((objKey, objIndex) => <div key={objIndex}> {objKey}: {col.text[objKey]}</div>)
                                    : col}
                                </div>
                                {answer[r] === col ?
                                    <div>
                                        <b>
                                            <i className="fas fa-check"/>
                                        </b>
                                    </div>
                                :''}
                            </Col>
                        )}
                    </Row>
                )}
            </div>
        );
    }
    else if(type && answer && type === "matrixdropdown"){
        return (
            <div>
                {rows.map((r, key) => {
                    const title = typeof r?.text === 'object' ? r?.text?.default : r?.value;
                    return(
                    <Row key={key}>
                        <Col className='col-2 d-flex align-items-center'>
                            {title}
                        </Col>
                        {columns.map((col, key) =>{
                            const title = typeof col?.title === 'object' ? col?.title?.default : col?.title;
                            return(
                            <Col key={key} className='text-center'>
                                <div>
                                    {title??col?.name}
                                </div>
                                {answer && answer[r?.value??r] && answer[r?.value??r][col.name] ?
                                    <div>
                                        <b>{answer[r?.value??r][col.name]}</b>
                                    </div>
                                :''}
                            </Col>)}
                        )}
                    </Row>)}
                )}
            </div>
        );
    }
    else if(type && answer && type === "matrixdynamic"){
        return (
            <Row>
                {columns.map((col, key) =>
                    <Col key={key} className='text-center'>
                        <div>
                            {col.name}
                        </div>
                        {answer.map((a, key) =>
                            <div key={key}>
                                <b>{a[col.name]}</b>
                            </div>
                        )}
                    </Col>
                )}
            </Row>
        );
    }
    else{
        if (typeof answer === 'boolean') {
            return answer ? <b><FormattedMessage id="generic.yes" /></b> : <b><FormattedMessage id="generic.no" /></b>;
        }
        else if (Array.isArray(answer)) {
            let resp = [];

            answer.forEach((el, index) => {
                if (typeof el === 'object') {
                    if (el.content?.toString().startsWith('data:image/')) {
                        resp.push(<div key={`${idx}-${index}`}>
                            <img src={el.content.toString()} alt={el.name} width='100%' />
                        </div>);
                    }
                    else {
                        Object.keys(el).forEach((key, idx3) => {
                            if (typeof el[key] === 'object') {
                                Object.keys(key).forEach((key2, idx4) => {
                                    resp.push(<div key={`${idx}-Array-${index}-${idx3}-${idx4}`}>
                                        <span className="text-muted">{key}</span> <br />
                                        <span className="pl-3">
                                            <span className="text-muted"> {key2}:</span>
                                            {el[key][key2]}
                                        </span>
                                    </div>);
                                })
                            }
                            else resp.push(<div key={`${idx}-Array-${index}-${idx3}`}>
                                <span className="text-muted">{key} </span> <br />
                                <span className="pl-3"> {el[key]}</span>
                            </div>);
                        })
                    }
                }
                else resp.push(<div> {el} </div>);
            })

            return resp.map(el => el);
        }
        else if (typeof answer === 'object') {
            let resp = [];

            Object.keys(answer).forEach((key, index) => {
                if (typeof answer[key] === 'object') {
                    Object.keys(answer[key]).forEach((key2, idx3) => {
                        resp.push(<div key={`${idx}-Object-${index}-${idx3}`}>
                            <span className="text-muted">{key} </span> <br />
                            <span className="pl-3">
                                <span className="text-muted">{key2}: </span>
                                {answer[key][key2]}
                            </span>
                        </div>);
                    })
                }
                else resp.push(<div key={`${idx}-Object-${index}`}>
                    <span className="text-muted">{key} </span> <br />
                    <span className="pl-3"> {answer[key]}</span>
                </div>);
            })

            return resp.map(el => el);
        }
        else if (answer.toString().startsWith('data:image/')) {
            return <img style={{ width: 'auto', maxHeight: '155px' }} src={answer}/>
        }
        else return <b>{answer}</b>;
    }
}


export const EgoiFormAnswers = ({ children, answers }) => {
    return <Card className="shadow border-0 h-100" body>
        {children}

        {answers && answers.map((inquiry, key2) => 
            inquiry ?
                !Array.isArray(inquiry) ?
                    <div key={key2} className="mb-3">
                        <Row className="text-muted mb-1"> <Col>{inquiry.question}</Col></Row>
                        <Row className="pl-2" ><Col><b>{inquiry.answer}</b></Col> </Row>
                    </div>
                :
                    inquiry.map((question, index) =>
                        <div key={index} className="mb-3">
                            <Row className="text-muted mb-1">
                                <Col>{question.question}</Col>
                            </Row>
                            {question.subQuestions && question.subQuestions.map((subQuestion, index2) =>
                                <Row className="mb-1" key={index2}>
                                    <Col className="ml-3"><b>{subQuestion.subQuestion}</b></Col>
                                    <Col>{subQuestion.answer}</Col>
                                </Row>
                            )}

                        </div>
                    )
                : ''
        )}
    </Card>
}



export const ReviewProFormsSelect = ({ style, icon, name, onChangeFunc, required, value, placeholder, isDisabled, url }) => {

    const [data, setData] = useState([]);
    const [block, setLoad] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        setLoad(true);
        setData([]);

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                setError(errorMessage);
                setLoad(false)
                return;
            }
            if (data && data.response && data.response.length > 0) {
                var combo = [];

                data.response.map((item) =>
                    combo.push({ 'value': item.id, 'label': item.name || item.title })
                )

                setData(combo);
            }
            setLoad(false);
        }, url);
        //}, '/api/gms/ReviewPro/v1/Surveys');
    }, [url]);

    return (
        <BlockUi tag="div" style={style}>
            <CustomSelect
                icon={icon}
                options={data}
                required={required}
                isClearable isSearchable
                value={data ? data.filter(el => el.value == value) : null}
                placeholder={placeholder || ''}
                onChange={onChangeFunc}
                isDisabled={isDisabled || data.length === 0}
                isLoading={block}
            />
        </BlockUi>
    )
};

export const FormsWithTitleSelect = ({ style, icon, onChangeFunc, required, value, placeholder, isDisabled, url }) => {

    const [data, setData] = useState([]);
    const [block, setLoad] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                setError(errorMessage);
                setLoad(false)
                return;
            }
            if (data && data.response && data.response.length > 0) {
                const combo = data.response.map((item) => {
                    const opt = { 'value': item.id, 'label': item.title };
                    return opt;
                });
                setData(combo);
            }
            setLoad(false);
        }, url);
    }, []);

    return (
        <BlockUi tag="div" blocking={block} style={style}>
            {data.length > 0 ?
                <CustomSelect
                    icon={icon}
                    options={data}
                    required={required}
                    isClearable isSearchable
                    value={data ? data.filter(el => el.value == value) : null}
                    placeholder={placeholder || ''}
                    onChange={onChangeFunc}
                    isDisabled={isDisabled}
                />
             : ''}
        </BlockUi>
    )
};

export const ReviewProProductSelect = ({ icon, name, onChangeFunc, required, value, placeholder, isDisabled }) => {

    const [data, setData] = useState([]);
    const [block, setLoad] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                setError(errorMessage);
                setLoad(false)
                return;
            }
            if (data && data.response && data.response.length > 0) {
                var combo = [];

                data.response.forEach(el => combo.push({ label: el.name, value: el.id.toString() }) );

                setData(combo);
            }
            setLoad(false);
        }, '/api/gms/ReviewPro/v1/Lodgings');
    }, []);

    return (
        <BlockUi tag="div" blocking={block}>
            {data.length > 0 ?
                <CustomSelect
                    icon={icon}
                    options={data}
                    required={required}
                    isClearable isSearchable
                    value={data ? data.filter(el => el.value == value) : null}
                    placeholder={placeholder || ''}
                    onChange={onChangeFunc}
                    isDisabled={isDisabled}
                />
            : ''}
        </BlockUi>
    )
};

//#endregion