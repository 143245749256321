import React, { Component } from 'react'
import { Row, Col, Button, Badge } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BlankCard, StyledModal, CommonHelper } from '../../Base/CommonUIComponents';
import { putAPI, postAPI, getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import SalesDealStep1 from './SalesDealStep1';
import SalesDealStep2 from './SalesDealStep2';
import SalesDealStep3 from './SalesDealStep3';
import SalesDealStep4 from './SalesDealStep4';
import moment from 'moment';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';

class SalesDealModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            steps: [
                { step: 1, active: true, name: 'Deal' },
                { step: 2, active: false, name: 'Dates' },
                { step: 3, active: false, name: 'Client' },
                { step: 4, active: false, name: 'Staff' }
            ],
            duplicateList: [],
            deal: this.props.deal || {
                negotiationStartDate: moment().format('YYYY-MM-DD'),
                salesPipelineStageId: this.props.salesStages ? this.props.salesStages[0].value : null,
                salesPipelineId: this.props.salesPipelineId ? this.props.salesPipelineId : this.props.salesPipelines ? this.props.salesPipelines[0]?.value : null
            },
            roles: [{ value: "newRole", label: <div className='d-flex align-items-center'><b><i className="fas fa-plus pr-2" /><FormattedMessage id="SalesProcess.CreateNewRole" /></b></div> }],
            selectedNewPipelineStageId: null,
            selectedDealRelationTypeId: null,
        };
    }

    componentDidMount() {
        const { steps, deal } = this.state;
        const { specificEditStep, salesPipelines, changeLeadToDeal, createNewAssociation } = this.props;
        const newDeal = {...deal};

        this.getDealTags();

        const currentUser = global.salesProcessUsers?.find(el => el.isCurrentUser);

        const userHotelIds = global.hotelList.filter(hotel => currentUser?.hotelIds?.some(hotelId => hotelId === hotel.value)) ?? [];

        if (currentUser && (currentUser.role === "SalesRep" || currentUser.role === "Manager")) {
            const currentUserHotelIds = currentUser?.hotelIds ?? [];

            const fixedList = newDeal.hotelIds?.filter(x => !currentUserHotelIds.includes(x)) ?? [];

            if(changeLeadToDeal || createNewAssociation){
                newDeal.hotelIds = currentUserHotelIds?.filter(x => newDeal.hotelIds?.includes(x)) ?? [];
            }
            
            this.setState({ fixedList });
        }
        else if (userHotelIds && userHotelIds.length === 1 && !newDeal.id && (changeLeadToDeal || createNewAssociation)) {
            newDeal.hotelIds = [userHotelIds[0].value];
        }

        if (steps && specificEditStep) {
            const updatedSteps = steps.map(el => ({
                ...el,
                active: el.step === specificEditStep
            }));
    
            this.setState({ steps: updatedSteps });
        }

        if(salesPipelines?.length === 1){
            newDeal.salesPipelineId = salesPipelines[0].value;
        }

        this.setState({ deal: newDeal });
    }

    onEditorStateChange = (editorState, name, name2) => {
        const contentState = editorState.getCurrentContent();
        const markup = draftToHtml(convertToRaw(contentState));
        this.setState({
            deal: {
                ...this.state.deal,
                [name]: editorState,
                [name2]: markup
            }
        });
    }

    saveDeal = () => {
        const { deal, duplicateList, selectedDealRelationTypeId } = this.state;
        const { isLead, createNewAssociation } = this.props;
        
        if(!isLead && !deal.salesDealUser?.some(el => el.role === "Responsible")){
            handleNotification({ warnings: [{ message: <FormattedMessage id="SalesProcess.ResponsibleRequiredText" />, code: <FormattedMessage id="SalesProcess.ResponsibleRequired" /> }] });
            return;
        }
        
        this.setState({ block: true }, () => {

            delete deal.editorStateHtml;

            let dealToSend = { ...deal };
            
            if(isLead){
                dealToSend.salesPipelineId = null;
                dealToSend.salesPipelineStage = null;
                dealToSend.salesPipelineStageId = null;
            }

            if(createNewAssociation){
                dealToSend = {
                    ...dealToSend, 
                    id: null,
                    salesDealUser: deal.salesDealUser.map(x => ({...x, id: null, salesDealId: null})),
                    salesDealProfile: deal.salesDealProfile.map(x => ({...x, id: null, salesDealId: null})),
                    dealsToAssociate: [
                        {
                            dealId: deal.id,
                            relationId: selectedDealRelationTypeId
                        }
                    ]
                }
            }

            if (deal && deal.salesDealProfile && deal.salesDealProfile.length > 0) {
                const allProfilesHaveValidRole = deal.salesDealProfile.every(el => el.roleId !== null && el.roleId !== undefined);
            
                if (!allProfilesHaveValidRole) {
                    const error = { warnings: [{ message: <FormattedMessage id="SalesProcess.ProfileRoleRequired" />, code: 'Profile Role Required' }] };
                    this.setState({ block: false }, () => handleNotification(error));
                    return;
                }
            }
            let qs = '';
            if (duplicateList.length > 0) {
                duplicateList.forEach(x =>
                    qs += `duplicatedDealsIds=${x}&`
                )
            }

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    if (this.props.updateDeal) {
                        this.props.updateDeal(data, true);
                    }
                    else if (this.props.getDeals) this.props.getDeals();

                    this.props.toggleModal();

                    handleNotification(data, <FormattedMessage id="SalesDeal.SavedSuccessfully" />, <FormattedMessage id="generic.success" />);

                    this.setState({ block: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/deals?` + qs, {...dealToSend });
        })
    }

    verifyDuplicated = () => {
        const { deal } = this.state;
        const { isLead } = this.props;
        let qs = `?`;

        if (deal.businessStartDate)
            qs += `businessStartDate=${deal.businessStartDate.format('YYYY-MM-DD')}&`;

        if (deal.businessEndDate)
            qs += `businessEndDate=${deal.businessEndDate.format('YYYY-MM-DD')}`;

        const profileIds = [deal.mainContactId].concat(deal.salesDealProfile?.map(({ profileId }) => profileId) ?? []);

        if (deal.customerId)
            profileIds.concat(deal.customerId)

        profileIds.forEach(id => {
            qs += `&profileIds=${id}`;
        });

        qs += `&isLead=${isLead ? isLead : 'false'}`

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                if (data.data && data.data.length === 0) {
                    this.saveDeal();
                }
                else {
                    this.setState({dupModal: true, duplicates: data.data})
                }
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/deals/duplicated${qs}`);
    }

    toggleDupModal = () => {
        this.setState({ dupModal: false });
    }

    changeStep = (value) => {
        const { steps } = this.state;

        steps.forEach(el => {
            el.active = el.step === value;
        })

        this.setState({ steps });
    }

    getDealTags = () => {
        getAPI(result => {
            const { data, error } = result;
            const { deal } = this.state;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data?.data?.length > 0) {
                const combo = data.data
                    .sort((a, b) => {
                        const aHasTag = deal.dealTags?.some(d => d.id === a.id) ? 1 : 0;
                        const bHasTag = deal.dealTags?.some(d => d.id === b.id) ? 1 : 0;
                        return bHasTag - aHasTag; 
                    })
                    .map(el => ({
                        label: el.name,
                        id: el.id,
                    }));
                this.setState({ dealTagList: combo })
            } else this.setState({ error: errorMessage });
        }, `/api/gms/SalesProcess/v1/customtypes?type=DealTag`);
    }

    handleSelect = (name, combo) => {
        const { salesStages } = this.props;
        let { deal, fixedList } = this.state;
        let targetValue = null;

        if (Array.isArray(combo)) {
            targetValue = combo.map(el => el.value);
        }
        else {
            if (name === "proposalCurrency")
                targetValue = combo ? combo.label : null;
            else
                targetValue = combo ? combo.value : null;
        }

        if (name === "hotelIds") {
            if (fixedList && fixedList.length > 0) {
                if (targetValue && targetValue.length > 0) {
                    targetValue = targetValue.concat(
                        fixedList
                            .filter(y => !targetValue.some(x => x === y))
                    );
                }
                else {
                    targetValue = fixedList
                }
            }

            if (deal.proposalCurrency == null && targetValue && targetValue.length > 0)
                deal.proposalCurrency = global.hotelList.find(x => x.value == targetValue[0])?.currency
        }

        if(name === "salesPipelineId"){
            deal.salesPipelineStageId = salesStages?.find(({salesPipelineId}) => salesPipelineId === targetValue)?.value;
        }

        deal = {...deal, [name]: targetValue};

        this.setState({ deal });
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        if(name === 'cancelReasonType'){
            this.setState({
                deal: {
                    ...this.state.deal,
                    cancelReason: value ? value.label : null,
                    cancelReasonId: value ? value.value : null
                }
            })
            return;
        }

        this.setState({
            deal: {
                ...this.state.deal,
                [name]: value
            }
        })
    }

    handleDate = (name, date) => {
        this.setState({
            deal: {
                ...this.state.deal,
                [name]: date ? moment(date).format("YYYY-MM-DD") : null
            }
        })
    }

    changeProfile = (name, profile) => {
        this.setState({
            deal: {
                ...this.state.deal,
                [`${name}Id`]: profile.profileId,
                [name]: profile
            }
        })
    }

    handleProfilesList = (name, list) => {
        this.setState({
            deal: {
                ...this.state.deal,
                [name]: list
            }
        })
    }

    handleListElement = (listName, index, propName, value, isToRemove) => {
        if (index !== -1) {
            const { deal } = this.state;

            if (isToRemove) {
                deal[listName].splice(index, 1);
            }
            else{
                if(listName === 'salesDealUser'){
                    if(deal?.[listName]?.some(x => x.userId === value)){
                        handleNotification({ warnings: [{ message: <FormattedMessage id="SalesDeal.UserAlreadyAssociated" /> }] });
                        return;
                    }
                }
                deal[listName][index][propName] = value;
            }

            this.setState({ deal });
        }        
    }

    isDisabled = () => {
        const { steps, deal, selectedDealRelationTypeId } = this.state;
        const { isLead, createNewAssociation } = this.props;

        if (steps[0].active && 
                deal.name && 
                deal.salesSourceId && 
                (this.props.isLead || (deal.businessStartDate && deal.businessEndDate)) && 
                (this.props.isLead || deal.salesPipelineStageId) && 
                (this.props.isLead || deal.hotelIds) && 
                (this.props.isLead || deal.hotelIds.length > 0) && 
                (this.props.isLead || deal.status) && 
                (deal.status === 'Cancelled' ? deal.statusReason && deal.cancelReason : true) &&
                ((!createNewAssociation) || (selectedDealRelationTypeId !== null && selectedDealRelationTypeId !== undefined))
        ) {
            return false
        }
        else if (steps[1].active  && deal.negotiationStartDate && deal.summary) {
            return false
        }
        else if (steps[2].active && 
            (this.props.isLead || deal.customerId) && 
            deal.mainContactId && 
            (!deal.salesDealProfile || deal.salesDealProfile?.length === 0 || deal.salesDealProfile?.every(el => el.roleId && !isNaN(el.roleId) ))
        ) {
            return false
        }
        else if (steps[3].active && (isLead || deal.salesDealUser?.length > 0 && deal.salesDealUser?.every(el => el.role && el.userId))) {
            return false
        }

        return true;
    }

    selectDup = (id) => {
        let { duplicateList } = this.state;

        if (duplicateList.includes(id)) {
            const index = duplicateList.indexOf(id);
            duplicateList.splice(index, 1);
        }
        else {
            duplicateList.push(id);
        }
            
        this.setState({duplicateList})
    }

    handleDealRelationChange = (combo) => {
        this.setState({ selectedDealRelationTypeId: combo?.value });
    }

    handleDealTagChange = (tag) => {
        const { deal } = this.state;
        const tags = deal && deal.dealTags ? deal.dealTags : [];
        const idx = tags.findIndex(({ id }) => id === tag.id);

        if (idx > -1) {
            tags.splice(idx, 1);
        }
        else {
            tags.push({ id: tag.id });
        }

        this.setState({
            deal: {
                ...this.state.deal,
                dealTags: tags
            }
        });
    }

    handleLoc = (city, cityOptions) => {
        const { deal } = this.state;
        const { hotelList } = this.props;

        if(!cityOptions) return;

        const data = cityOptions.find(e => e.city === city);

        let updatedHotelList = deal?.hotelIds ? [...deal.hotelIds] : [];

        if (data) {
            if (data.isSelected) {
                const hotelsToRemove = hotelList?.filter(hotel => hotel.city === city)?.map(x => x.value) ?? [];
                updatedHotelList = updatedHotelList.filter(hotel => !hotelsToRemove.includes(hotel));
            }
            else {
                const hotelsToAdd = hotelList?.filter(hotel => hotel.city == city)?.map(x => x.value) ?? [];
                updatedHotelList = updatedHotelList.concat(hotelsToAdd);
            }

        }
        else {
            updatedHotelList = [];
        }
        data.isSelected = !data.isSelected;

        this.setState(() => ({
            deal: {
                ...deal,
                hotelIds: updatedHotelList 
            }
        }));
    }

    handleSelectAll = (selectAll) => {
        const { deal } = this.state;
        const { hotelList } = this.props;

        let updatedHotelList = deal?.hotelIds ? [...deal.hotelIds] : [];

        if (selectAll) {
            updatedHotelList = hotelList?.map(x => x.value) ?? [];
        }else{
            updatedHotelList = [];
        }

        this.setState(() => ({
            deal: {
                ...deal,
                hotelIds: updatedHotelList
            }
        }));
    }

    render() {
        const { block, error, steps, deal, roles, duplicates, dupModal, duplicateList, selectedDealRelationTypeId, dealTagList } = this.state;
        const { modal, toggleModal, salesStages, salesSources, salesUsers, salesPipelines, editableSalesPipeline, isToEditDeal, isLead, currentUser, changeLeadToDeal, createNewAssociation, hotelList } = this.props;

        const currentStep = steps.find(el => el.active);

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="xl">
                {dupModal ? 
                    <StyledModal toggleModal={this.toggleDupModal} modal={dupModal} size="xl">
                        <BlankCard block={block} error={error}>
                            <Row className="mb-4">
                                <Col className="text-muted" style={{ fontSize: '1.1em' }}>
                                    <i className="fas fa-briefcase mr-2" />
                                    <FormattedMessage id={`SalesProcess.DuplicatedDeals`} />
                                </Col>
                                <Col className="text-right p-0">
                                    <Button className="btn btn-host-invert btn-small ml-3" style={{ fontSize: '0.85em' }} onClick={(e) => this.saveDeal(e)} disabled={duplicateList.length === 0}>
                                        <i class="fas fa-sitemap mr-2"></i>
                                        <FormattedMessage id={`SalesDeal.DupAssociate`} />
                                    </Button>
                                    <Button className="btn btn-host btn-small ml-3 mr-3" style={{ fontSize: '0.85em' }} onClick={() => this.setState({ duplicateList: [] }, (e) => this.saveDeal(e))}>
                                        <i className="fas fa-save mr-2" />
                                        <FormattedMessage id={`SalesDeal.NoDupsAssociate`} />
                                    </Button>
                                    <CommonHelper help={<FormattedMessage id="SalesDeal.DuplicateHelp" />} id={'SalesProcessHelp'} />
                                </Col>
                            </Row>
                            {duplicates.map((x,key) =>
                                <Row className='shadow p-3 mb-4 pointer' style={{ backgroundColor: duplicateList.includes(x.dealId) ? '#f8f9fa' : 'white' }}>
                                    <Col>
                                        <Row>
                                            <Col className="col-3" onClick={() => window.open(`/SalesProcess?dealId=${x.dealId}#SalesDealDetail`, '_blank').focus()}>
                                                <div>
                                                    <div className="text-muted" style={{ fontSize: '0.9em' }} >
                                                        <FormattedMessage id={`SalesProcess.Name`} />
                                                    </div>
                                                    <div className="hover-underline hrefStyle text-truncate">
                                                        {x.dealName}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="col-2" onClick={() => this.selectDup(x.dealId)}>
                                                <div>
                                                    <div className="text-muted" style={{ fontSize: '0.9em' }} >
                                                        <FormattedMessage id={`SalesProcess.Customer`} />
                                                    </div>
                                                    <div className="text-truncate">
                                                        {x.customer}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="col-2" onClick={() => this.selectDup(x.dealId)}>
                                                <div>
                                                    <div className="text-muted" style={{ fontSize: '0.9em' }} >
                                                        <FormattedMessage id={`SalesProcess.MainContact`} />
                                                    </div>
                                                    <div className="text-truncate">
                                                        {x.mainContact}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="col-3" onClick={() => this.selectDup(x.dealId)}>
                                                <div>
                                                    <div className="text-muted" style={{ fontSize: '0.9em' }} >
                                                        <FormattedMessage id={`SalesSource.Responsable`} />
                                                    </div>
                                                    {x.salesDealUsers ?
                                                        <div style={{ fontSize: '0.9em', maxHeight: '22px' }} className='overflow-auto veryCoolScrollBar text-truncate'>
                                                            {x.salesDealUsers.map((user, key) => {
                                                                return (
                                                                    <div key={key} className={key ? 'mt-1 text-truncate' : 'text-truncate'}>
                                                                        {user.name}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        : '-'}
                                                </div>
                                            </Col>
                                            <Col className="col-2" onClick={() => this.selectDup(x.dealId)}>
                                                <div>
                                                    <div className="text-muted" style={{ fontSize: '0.9em' }} >
                                                        <FormattedMessage id={`SalesDeal.Status`} />
                                                    </div>
                                                    <div>
                                                        <Badge id={`dealStatus-${key}`} style={{ fontSize: '0.7em' }} color={(x.dealStatus === "WaitingHotel" || x.dealStatus === "WaitingClient") ? "primary" : x.dealStatus === "Finished" ? "success" : "danger"} className="py-1 px-2">
                                                            <FormattedMessage id={`SalesProcess.${x.dealStatus}`} />
                                                        </Badge>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="mt-3 col-3" onClick={() => this.selectDup(x.dealId)}>
                                                <div>
                                                    <div className="text-muted" style={{ fontSize: '0.9em' }} >
                                                        <FormattedMessage id={`SalesProcess.BusinessDates`} />
                                                    </div>
                                                    <div className="text-truncate">
                                                        {moment(x.businessStartDate).format("DD/MM/YYYY")} - {moment(x.businessEndDate).format("DD/MM/YYYY")}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="mt-3 col-2" onClick={() => this.selectDup(x.dealId)}>
                                                <div>
                                                    <div className="text-muted" style={{ fontSize: '0.9em' }} >
                                                        <FormattedMessage id={`SalesProcess.CreatedBy`} />
                                                    </div>
                                                    <div className="text-truncate">
                                                        {x.createdBy}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="mt-3 col-2" onClick={() => this.selectDup(x.dealId)}>
                                                <div>
                                                    <div className="text-muted" style={{ fontSize: '0.9em' }} >
                                                        <FormattedMessage id={`SalesProcess.RelatedHotels`} />
                                                    </div>
                                                    {x.hotelList ?
                                                        <div style={{ fontSize: '0.9em', maxHeight: '22px' }} className='overflow-auto veryCoolScrollBar text-truncate'>
                                                            {x.hotelList.map((id, key) => {
                                                                const hotel = global.hotelList.find(x => x.value === id);
                                                                return (
                                                                    <div key={key} className={key ? 'mt-1 text-truncate' : 'text-truncate'}>
                                                                        {hotel.label}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        : '-'}
                                                </div>
                                            </Col>
                                            <Col className="mt-3 col-3" onClick={() => this.selectDup(x.dealId)}>
                                                <div>
                                                    <div className="text-muted" style={{ fontSize: '0.9em' }} >
                                                        <FormattedMessage id={`ProfileDetails.AssociatedProfiles`} />
                                                    </div>
                                                    {x.salesDealProfiles ?
                                                        <div style={{ fontSize: '0.9em', maxHeight: '22px' }} className='overflow-auto veryCoolScrollBar text-truncate'>
                                                            {x.salesDealProfiles.map((user, key) => {
                                                                return (
                                                                    <div key={key} className={key ? 'mt-1 text-truncate' : 'text-truncate'}>
                                                                        {user.role} - {user.name}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        : '-'}
                                                </div>
                                            </Col>
                                            <Col className="mt-3 col-2" onClick={() => this.selectDup(x.dealId)}>
                                                <div>
                                                    <div className="text-muted" style={{ fontSize: '0.9em' }} >
                                                        <FormattedMessage id={`SalesProcess.CreatedAtDates`} />
                                                    </div>
                                                    <div className="text-truncate">
                                                        {moment(x.createdStartDate).format("DD/MM/YYYY")}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            )}
                        </BlankCard>
                    </StyledModal>
                : ''}
                <BlankCard block={block} error={error}>
                    <Row className="mb-4">
                        <Col className="col-1 text-left d-flex align-items-center">
                            {steps[0].active === false && !isToEditDeal ?
                                <Button className="btn- btn-host btn-small mr-4" style={{ 'flex': '1' }} onClick={_ => this.changeStep(currentStep.step - 1)}>
                                    <i className="fas fa-angle-left" />
                                </Button>
                            : ''}
                        </Col>

                        <Col className="py-1">
                            <ul className="steps">
                                {steps.map((step, key) =>
                                    <li className={'step ' + (step.active ? ' step-active' : '')} style={{ cursor: 'pointer' }} key={key}>
                                        <div className="step-content">
                                            <span className="step-text small">
                                                <FormattedMessage id={`SalesDeal.Step${step.step}`} />
                                            </span>
                                            <span className="step-circle my-1" onClick={() => !this.isDisabled() ? this.changeStep(step.step) : undefined} />
                                            <span className="step-text small">
                                                <FormattedMessage id={`SalesDeal.${step.name}`} />
                                            </span>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </Col>

                        <Col className="col-1 text-right">
                            <CommonHelper help={<FormattedMessage id={!isLead ? "SalesDealModal.Help" : "SalesDealModal.LeadHelp"} />} id={!isLead ? 'SalesProcessCreateDealModal' : 'SalesProcessCreateLeadModal'} />
                            {isToEditDeal || steps[3].active ?
                                <Button className="btn- btn-host btn-small ml-4 mt-1" style={{ 'flex': '1' }} onClick={(e) => isToEditDeal ? this.saveDeal(e) : this.verifyDuplicated(e)} disabled={this.isDisabled()}/*disabled={!deal.salesDealUser || deal.salesDealUser.length === 0}*/>
                                    <i className="fas fa-save" />
                                </Button>
                                :
                                <Button className="btn- btn-host btn-small ml-4 mt-1" style={{ 'flex': '1' }} onClick={_ => this.changeStep(currentStep.step + 1)} disabled={this.isDisabled()}>
                                    <i className="fas fa-angle-right" />
                                </Button>
                            }
                        </Col>
                    </Row>



                    {steps[0].active ?
                        <SalesDealStep1
                            deal={deal}
                            sources={salesSources}
                            stages={salesStages}
                            salesPipelines={salesPipelines}
                            editableSalesPipeline={editableSalesPipeline}
                            handleSelect={this.handleSelect}
                            handleChange={this.handleChange}
                            handleDate={this.handleDate}
                            isLead={isLead}
                            currentUser={currentUser}
                            changeLeadToDeal={changeLeadToDeal}
                            createNewAssociation={createNewAssociation}
                            selectedDealRelationTypeId={selectedDealRelationTypeId}
                            handleDealRelationChange={this.handleDealRelationChange}
                            handleDealTagChange={this.handleDealTagChange}
                            dealTagList={dealTagList}
                            hotelList={hotelList}
                            handleLoc={this.handleLoc}
                            handleSelectAll={this.handleSelectAll}
                        />
                    : ''}

                    {steps[1].active ?
                        <SalesDealStep2
                            deal={deal}
                            handleSelect={this.handleSelect}
                            handleDate={this.handleDate}
                            handleChange={this.handleChange}
                            onEditorStateChange={this.onEditorStateChange}
                            isLead={isLead}
                        />
                    : ''}

                    {steps[2].active ?
                        <SalesDealStep3
                            deal={deal}
                            roles={roles}
                            handleProfilesList={this.handleProfilesList}
                            changeProfile={this.changeProfile}
                            handleListElement={this.handleListElement}
                            isLead={isLead}
                        />
                    : ''}

                    {steps[3].active ?
                        <SalesDealStep4
                            deal={deal}
                            handleProfilesList={this.handleProfilesList}
                            handleChange={this.handleChange}
                            handleListElement={this.handleListElement}
                            usersList={salesUsers}
                            salesUsers={salesUsers}
                            isLead={isLead}
                        />
                    : ''}
                </BlankCard>
            </StyledModal>
        )
    }
}

export default injectIntl(SalesDealModal);