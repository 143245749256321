import React, { Component } from 'react'
import { Button, Card, Col, Modal, ModalBody, Row } from 'reactstrap';
import { PhoneFrame, StyledModal } from '../Base/CommonUIComponents';
import { FormattedMessage } from "react-intl";

export class TemplateModal extends Component {

    render() {
        const { isOpen, toggle, previewTemplate } = this.props;

        return (
            <Modal isOpen={isOpen} size={"xl"} style={{ minWidth: 0 }}>
                <Button onClick={toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    {previewTemplate ?
                        <Card className="p-2 border-0 mb-2 pointer">
                            <Row>
                                <Col className="col-3">
                                    <h4>{previewTemplate.templateName}</h4>
                                    <div className="mb-4">{previewTemplate.subject}</div>

                                    {previewTemplate.generation ?
                                        <>
                                            <div className="title-sm"><FormattedMessage id="SendGrid.Generation" /></div>
                                            <div className="mb-2 ">{previewTemplate.generation}</div>
                                        </>
                                        : ''}
                                </Col>
                                <Col>
                                    {
                                        previewTemplate.htmlBody ?
                                            <div dangerouslySetInnerHTML={{ __html: previewTemplate.htmlBody }} />
                                            :
                                            <PhoneFrame>
                                                <div className='p-4 veryCoolScrollBar' style={{ display: 'flex', flexDirection: 'column', maxHeight: '90%', overflow: 'auto' }}>
                                                    <div className='iMessage' style={{
                                                        borderRadius: '20px', padding: '8px 15px', marginTop: '5px', marginBottom: '5px', marginRight: '20%',
                                                        backgroundColor: '#eee', position: 'relative'
                                                    }}>
                                                        {previewTemplate.smsTextBody ? previewTemplate.smsTextBody : previewTemplate.textBody}
                                                    </div>
                                                </div>
                                            </PhoneFrame>
                                    }
                                </Col>
                            </Row>
                        </Card>
                : <div className="text-center my-3"> <FormattedMessage id="PreviewTemplate.ThereIsNoTemplates" /> </div>}
                </ModalBody>
            </Modal>    
        );
    }
}


export class SendGridTemplateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            lastVersion: null
        }
    }


    componentDidMount() {
        if (this.props.previewTemplate) {
            var lastVersion = this.props.previewTemplate.versions?.find(el => el.active === 1);

            this.setState({ lastVersion });
        }
    }

    render() {
        const { isOpen, toggle, previewTemplate } = this.props;

        return (
            <StyledModal toggleModal={toggle} modal={isOpen} size="xl">
                {previewTemplate ?
                    <Card className="p-2 border-0 mb-2 pointer">
                        <Row>
                            <Col className="col-3">
                                <h4>{previewTemplate.name}</h4>
                                <div className="mb-4">{this.state.lastVersion?.subject}</div>

                                <div className="title-sm"><FormattedMessage id="SendGrid.Generation" /></div>
                                <div className="mb-3 ">{previewTemplate.generation}</div>

                                <div className="title-sm"><FormattedMessage id="generic.UpdatedAt"/></div>
                                <div className="mb-2 ">{previewTemplate.updated_at}</div>
                            </Col>
                            <Col>
                                <div dangerouslySetInnerHTML={{ __html: this.state.lastVersion?.html_content }} />
                            </Col>
                        </Row>
                    </Card>
                : <div className="text-center my-3"> <FormattedMessage id="PreviewTemplate.ThereIsNoTemplates" /> </div>}
            </StyledModal>
        );
    }
}