import React, { Component } from 'react'
import { handleNotification } from '../Base/Notification';
import { getAPI, deleteAPI, putAPI } from '../Base/API';
import { Row, Col, Card, Button } from 'reactstrap';
import { ActiveInactiveStatusBadge, KebabMenu } from '../Base/CommonUIComponents';
import { ErrorAlert } from '../Base/ErrorAlert';
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';
import SalesProcessConfigModal from './SalesProcessConfigModal';
import SalesProcessEditTemplateModal from './SalesProcessEditTemplateModal';
import { CommonHelper } from '../Base/CommonUIComponents';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { checkSalesProcessPermission, getCurrentUser, getMasterTemplateFileType, getMasterTemplateType } from './SalesProcessFunctions';


class SalesProcessSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            masterTemplates: [],
        };
    }

    componentDidMount() {
        this.getMasterTemplates();
    }

    getMasterTemplates = () => {
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    const list = this.groupFilesByHotel(data.data);

                    this.setState({ masterTemplates: list, block: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/communication/mastertemplates`)
        });
    }

    groupFilesByHotel = (data) => {
        if (data?.length > 0) {
            return data.reduce((acc, item) => {
                let group = acc.find(g => g.hotelId === item.hotelId);

                if (!group) {
                    group = {
                        hotelId: item.hotelId,
                        hotelName: global.hotelList.find(hotel => hotel.value === item.hotelId)?.label || item.hotelId,
                        files: []
                    };
                    acc.push(group);
                }
                
                group.files.push(item);

                return acc;
            }, [])
        }
        else return [];
    }

    deleteMasterTemplate = (id) => {
        this.setState({ block: true }, () => {
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    this.setState({ block: false }, this.getMasterTemplates)
                    this.toggleDeleteModal();                        ;
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/communication/mastertemplate/${id}`)
        });
    }

    updateMasterTemplate = (e) => {
        const { selectedTemplate } = this.state;
        if (e) e.preventDefault()
        this.setState({ block: true }, () => {
            putAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    this.setState({ block: false }, this.getMasterTemplates);
                    this.toggleModal()
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/communication/mastertemplate`, selectedTemplate)
        });
    }

    toggleModal = (temp) => {
        this.setState({ modal: !this.state.modal, selectedTemplate: temp });
    }

    toggleDeleteModal = (temp) => {
        this.setState({ deleteModal: !this.state.deleteModal, selectedTemplate: temp });
    }
    
    addConfig = (configId) => {
        this.setState({
            selectedTemplate: {
                ...this.state.selectedTemplate,
                configIds: [...this.state.selectedTemplate.configIds, configId]
            }
        })
    };

    handleListElement = (index, value, isToRemove) => {
        if (index !== -1) {
            const { selectedTemplate } = this.state;

            if (isToRemove) {
                selectedTemplate.configIds.splice(index, 1);
            }
            else
                selectedTemplate.configIds[index] = value;

            this.setState({ selectedTemplate });
        }
    }

    render() {
        const { masterTemplates, configs, modal, selectedTemplate, error, block, previewTemplate, dragType, deleteModal } = this.state;

        return (
            <>
                <div className="border-0 h-100 pb-2">
                    <ErrorAlert error={error} />
                    <BlockUi tag="div" blocking={block}>
                        <Row className='mb-4'>
                            <Col>
                                <h4 className='m-0'>
                                    <i className={`${this.props.icon} mr-2`} />
                                    <FormattedMessage id="SalesProcess.FileRepo" />
                                </h4>
                            </Col>
                            <Col>
                                <CommonHelper help={<FormattedMessage id="SalesProcess.SalesProcessSettingsHelper" />} id={'CommuncationSettingsHelp'} />
                            </Col>
                        </Row>

                        <div className="pl-2">
                            {masterTemplates && masterTemplates.map((hotel, k) => {
                                return (
                                    <React.Fragment key={k}>
                                        <Row className='mt-4 pt-2'>
                                            <Col className='col-6 mb-2' key={k}>
                                                <i className="icon-icon-hotel mr-2" /> {hotel.hotelName}
                                            </Col>
                                        </Row>

                                        <Row className='mb-2'>
                                            {hotel.files?.map((file, index) => {
                                                const canEdit = file.hotelGroupId !== '0000-000-00-0-00-000';
                                                const fileType = getMasterTemplateFileType().find(el => el.value === file.fileType);
                                                const templateType = getMasterTemplateType(this.props.intl).find(el => el.value === file.type)

                                                return (
                                                    <Col sm={3} key={index}>
                                                        <Card body className='p-3 h-100'>
                                                            <Row className="cursor-pointer" onClick={canEdit ? _ => this.toggleModal(file) : undefined}>
                                                                <Col className="d-flex align-items-center col-10">
                                                                    <i className={`${fileType?.icon || 'far fa-file'} ml-2 mr-4`} style={{ fontSize: '25px' }} />

                                                                    <div className="text-truncate">
                                                                        <div className="text-truncate" title={file.name}>
                                                                            <b>{file.name}</b>
                                                                        </div>
                                                                        <div className='text-muted title-sm mb-0'>
                                                                            {templateType?.label}
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col className=" d-flex justify-content-end col-2">
                                                                    <div className="mr-2">
                                                                        <ActiveInactiveStatusBadge status={file.active} />
                                                                    </div>

                                                                    {checkSalesProcessPermission('SalesProcessSettings:actions', getCurrentUser()?.role) ?
                                                                        <div onClick={e => e.stopPropagation()}>
                                                                            <KebabMenu
                                                                                deleteFunction={canEdit ? _ => this.toggleDeleteModal(file) : undefined}
                                                                            />
                                                                        </div>
                                                                    : ''}
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </BlockUi>
                </div>

                {modal ?
                    <SalesProcessEditTemplateModal
                        masterTemplate={selectedTemplate}
                        hasPreview={selectedTemplate.fileType === "HTML"}
                        toggleModal={this.toggleModal}
                        modal={modal}
                        getMasterTemplates={this.getMasterTemplates}
                        getMasterTemplateHtml={this.getMasterTemplateHtml}
                        dragType={dragType}
                        configs={configs}
                        previewTemplate={previewTemplate}
                        handleListElement={this.handleListElement}
                        updateMasterTemplate={this.updateMasterTemplate}
                        addConfig={this.addConfig}
                    />
                    : ''}
                    
                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={() => this.deleteMasterTemplate(selectedTemplate.id)}
                        text={<FormattedMessage id="SalesProcess.ConfirmDeleteText" values={{ template: selectedTemplate.name }} />}
                        block={block}
                    />
                    : ''}
            </>
        );
    };
}

export default injectIntl(SalesProcessSettings);
