import React, { Component } from 'react';
import CustomSelect from '../../Base/CustomSelect';
import { Button, Col, CustomInput, Input, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment-timezone';
import BlockUi from 'react-block-ui';
import ConfirmActionModal from '../../Base/ConfirmActionModal';

class SpaceManagment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            spaceData: this.props.spaceData??{},
            timeOptions: this.getArray(24).flatMap((d, index) => {
                const hour = index.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                })
                return (
                    [{
                        label: `${hour}:00`,
                        value: `${hour}:00`,
                    },
                    {
                        label: `${hour}:15`,
                        value: `${hour}:15`,
                    },
                    {
                        label: `${hour}:30`,
                        value: `${hour}:30`,
                    },
                    {
                        label: `${hour}:45`,
                        value: `${hour}:45`,
                    }]
                );
            }),
            selectedSpaceTimeSlotLength: 0,
            confirmModal: false
        };
    }

    getArray(size) {
        const array = [];
        for (let i = 0; i < size; i++) {
            array.push(i);
        }
        return array;
    }

    componentWillReceiveProps(nextProps){
        const selectedSpaceTimeSlot = document.getElementsByClassName(`cell-time-slot-space-${nextProps.spaceName}`);
        
        if(selectedSpaceTimeSlot.length !== this.state.selectedSpaceTimeSlotLength){
            this.initialCalc();
        }
    }

    componentDidMount(){
        this.initialCalc();
    }

    initialCalc = () => {
        const { colKey, spaceCode } = this.props;
        const { spaceData } = this.state;

        if(spaceData && spaceData.fromDate && spaceData.toDate && !spaceData.isNew){
            const startDate = moment(spaceData.fromDate);
            const endDate = moment(spaceData.toDate);
            const arr = [];

            const selectedSpaceTimeSlot = document.getElementsByClassName(`cell-time-slot-space-${spaceCode}`)

            selectedSpaceTimeSlot.forEach(element => {
                if(element.className.includes(spaceData.spaceReservationId)){
                    arr.push(element);
                }
            });
            
            arr.forEach((element) => {
                if(!element.className.includes("cell-time-slots-selected-focused")){
                    element.classList.add("cell-time-slots-selected-focused");
                    element.classList.remove("cell-time-slots-selected");
                }
            });

            this.setState({
                selectedSpaceTimeSlotLength: selectedSpaceTimeSlot.length,
                spaceData: {
                    ...spaceData,
                    startDate,
                    endDate,
                    startHour: startDate.format('HH:mm'),
                    endHour: endDate.format('HH:mm'),
                }
            });
        }
        else{
            const selectedSpaceTimeSlot = document.querySelectorAll(`.cell-time-slot-space-${spaceCode}.cell-time-slot-reservation-isNew`);
    
            if(!selectedSpaceTimeSlot)
                return;
    
            const timeSlots = [];
    
            selectedSpaceTimeSlot.forEach(element => {
                const str = 'cell-time-slot-time-';
                const idx = element.className.indexOf(str) + str.length;
                const timeClass = element.className.slice(idx, idx + 10);
    
                if(timeClass){
                    let tSlot = timeSlots.find(({date}) => date === timeClass);
        
                    if(!tSlot){
                        timeSlots.push({ date: timeClass, slots: [0,0,0,0] });
                        tSlot = timeSlots.find(({date}) => date === timeClass);
                    }
    
                    const stotStr = 'cell-time-slot-index-';
                    const slotIdx = element.className.indexOf(stotStr) + stotStr.length;
                    const slotClass = element.className.slice(slotIdx, slotIdx + 1);
    
                    if(slotClass){
                        tSlot.slots[parseInt(slotClass)] += 1;
                    }
                }
            });
    
            const selectedDateIdx = timeSlots.findIndex(({date}) => date === colKey);
            
            if(selectedDateIdx < 0)
                return;
    
            const selectedDate = timeSlots[selectedDateIdx];
    
            const sorted = timeSlots
                .sort((a,b) => moment(a.date).format('YYYYMMDD') - moment(b.date).format('YYYYMMDD'));
    
            const allContinuousDates = [...sorted]
                .slice(0, selectedDateIdx)
                .reverse()
                .filter((date, idx) => moment(date.date).format('YYYY-MM-DD') === moment(selectedDate.date).add(((idx - (idx * 2)) - 1), 'day').format('YYYY-MM-DD'))
                .concat([...sorted]
                    .slice(selectedDateIdx)
                    .filter((date, idx) => moment(date.date).format('YYYY-MM-DD') === moment(selectedDate.date).add(idx, 'day').format('YYYY-MM-DD'))
                );
    
            const arr = allContinuousDates.flatMap(date => {
                let query = `.cell-time-slot-space-${spaceCode}.cell-time-slot-time-${date.date}`;
    
                return date.slots
                .map((a, key) => a > 0 ? document.querySelector(query + `.cell-time-slot-index-${key}`) : null)
                .filter(a => a);
            });
            
            arr.forEach((element) => {
                if(!element.className.includes("cell-time-slots-selected-focused")){
                    element.classList.add("cell-time-slots-selected-focused");
                    element.classList.remove("cell-time-slots-selected");
                }
            });

            const lastContinuousDate = timeSlots.reduce((a, b) => {
                if(moment(b.date).format('YYYY-MM-DD') === moment(a.date).add(1, 'day').format('YYYY-MM-DD')){
                    return b;
                }
                else{
                    return a;
                }
            }, selectedDate);
        
            if(!lastContinuousDate)
                return;
            
            const firstContinuousDate = timeSlots.reduce((a, b) => {
                if(moment(b.date).format('YYYY-MM-DD') === moment(a.date).add(-1, 'day').format('YYYY-MM-DD')){
                    return b;
                }
                else{
                    return a;
                }
            }, selectedDate);

            if(!firstContinuousDate)
                return;

            const firstIdx = firstContinuousDate.slots.findIndex(a => a > 0);
            const lastIdx = lastContinuousDate.slots.findLastIndex(a => a > 0);

            const startDate = moment(firstContinuousDate.date).set('hour', (firstIdx === 0 ? 0 : firstIdx === 1 ? 6 : firstIdx === 2 ? 12 : 18));
            const endDate = moment(lastContinuousDate.date).set('hour', (lastIdx === 0 ? 6 : lastIdx === 1 ? 12 : lastIdx === 2 ? 18 : 24));

            this.setState({
                selectedSpaceTimeSlotLength: selectedSpaceTimeSlot.length,
                spaceData: {
                    ...spaceData,
                    startDate,
                    endDate,
                    startHour: startDate.format('HH:mm'),
                    endHour: endDate.format('HH:mm'),
                }
            });
        }
    }

    componentWillUnmount(){
        const focusedList = document.getElementsByClassName('cell-time-slots-selected-focused');

        while(focusedList.length > 0){
            const element = focusedList[0];
            element.classList.add("cell-time-slots-selected");
            element.classList.remove("cell-time-slots-selected-focused");
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ block: true }, () => this.props.saveSpace(this.state.spaceData, (success) => {
            this.setState({ block: false }, () => {
                if(success){
                    this.props.toggleDetailPopOver();     
                }
            });
        }));
    }

    handleComboChange = (combo, name) => {
        const { spacesOptions } = this.props;
        
        if(name === "spaceCode") {
            const space = spacesOptions.find(({value}) => value === combo?.value);

            this.setState({
                spaceData: {
                    ...this.state.spaceData,
                    [name]: combo?.value,
                    maxCapacity:  space?.maxCapacity??100,
                    spaceName: space?.description
                }
            });
        }
        else if(name === "serviceTypeCode" && combo.referenceDataDetails && combo.referenceDataDetails.startTime && combo.referenceDataDetails.endTime){
            this.setState({
                spaceData: {
                    ...this.state.spaceData,
                    [name]: combo?.value,
                    startHour: moment(combo.referenceDataDetails.startTime).format('HH:mm'),
                    endHour: moment(combo.referenceDataDetails.endTime).format('HH:mm')
                }
            });
        }
        else{
            this.setState({
                spaceData: {
                    ...this.state.spaceData,
                    [name]: combo?.value,
                }
            });
        }
    }

    handleDateChange = (date, name) => {
        this.setState({
            spaceData: {
                ...this.state.spaceData,
                [name]: date
            }
        });
    }

    handleChange = (e) => {
        const { value, name } = e.target;
        this.setState({
            spaceData: {
                ...this.state.spaceData,
                [name]: value
            }
        });
    }

    isOutsideRange = (day) => {
        const { eventData } = this.props;

        if(!eventData?.fromDate || !eventData?.toDate)
            return false;

        if(day.isBefore(eventData.fromDate) || day.isAfter(eventData.toDate))
            return true;

        return false;
    }

    handleCopyDaily = () => {
        const { spaceData } = this.state;
        this.setState({ spaceData: { ...spaceData, copyDaily: !spaceData?.copyDaily } });
    }
    
    toggleConfirmModal = () => {
        this.setState({ confirmModal: !this.state.confirmModal });
    }

    removeSpace = () => {
        this.setState({ block: true }, () => {
            this.props.removeSpace(this.state.spaceData, (success) => {
                this.setState({ block: false, confirmModal: !success }, () => {
                    if(success){
                        this.props.toggleDetailPopOver();     
                    }
                });
            });
        });
    }

    render() {
        const { spaceName, eventTypeOptions, customStatusOptions, spacesOptions, eventData } = this.props;
        const { spaceData, timeOptions, block, confirmModal } = this.state;

        return (
            <div className='px-3' style={{ minWidth: '550px', maxWidth: '550px' }}>
                <BlockUi blocking={block} tag={"div"}>
                    <form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col className="col-6">
                                <div>
                                    <div className='title-sm required' style={{ fontSize: '0.9em' }}>
                                        <FormattedMessage id="SalesProcess.Space"/>
                                    </div>
                                    <CustomSelect
                                        required={true}
                                        options={spacesOptions}
                                        value={spacesOptions.find(({value}) => value === spaceData.spaceCode)}
                                        onChange={e => this.handleComboChange(e, 'spaceCode')}
                                        placeholder={""}
                                    />
                                </div>
                            </Col>
                            <Col className="col-6 text-muted pt-2 d-flex align-items-start justify-content-end" style={{ fontSize: '0.9em' }}>
                                {!spaceData.id ?
                                    <div className='d-flex align-items-center'>
                                        <CustomInput
                                            type="checkbox"
                                            id="popOverCopyDaily"
                                            className="text-ellipsis"
                                            checked={spaceData.copyDaily}
                                            onChange={this.handleCopyDaily}
                                            disabled={spaceData.startDate?.format('DD') !== spaceData.endDate?.clone().add(-1, 'minute').format('DD')}
                                        />
                                        <FormattedMessage id="SalesProcess.CopySpaceDaily"/>
                                    </div>
                                : spaceData.spaceReservationParentId ?
                                    <div>
                                        <FormattedMessage id="SalesProcess.SubEvent"/>
                                    </div>
                                :''}
                            </Col>

                            <Col className="col-6 mt-3">
                                <div className='title-sm required' style={{ fontSize: '0.9em' }}>
                                    <FormattedMessage id="SalesProcess.ServiceType"/>
                                </div>
                                <CustomSelect
                                    required={true}
                                    options={eventTypeOptions}
                                    value={eventTypeOptions.find(({value, description}) => (!spaceData.serviceTypeCode ? description === spaceData.spaceTypeDescription : value === spaceData.serviceTypeCode))}
                                    onChange={e => this.handleComboChange(e, 'serviceTypeCode')}
                                    placeholder={""}
                                />
                            </Col>
                            <Col className="col-6 mt-3">
                                <div className='title-sm required' style={{ fontSize: '0.9em' }}>
                                    <FormattedMessage id="SalesProcess.Status"/>
                                </div>
                                <CustomSelect
                                    required={true}
                                    options={customStatusOptions}
                                    value={customStatusOptions.find(({value}) => value === spaceData.statusCode)}
                                    onChange={e => this.handleComboChange(e, 'statusCode')}
                                    placeholder={""}
                                />
                            </Col>

                            <Col className="col-6 mt-3">
                                <div className='title-sm required' style={{ fontSize: '0.9em' }}>
                                    <FormattedMessage id="SalesProcess.StartDate"/>
                                </div>
                                <SingleDatePicker
                                    id="StartDate"
                                    isOutsideRange={this.isOutsideRange}
                                    date={spaceData.startDate}
                                    focused={this.state.focused}
                                    onFocusChange={({ focused }) => this.setState({ focused })}
                                    small={true}
                                    required={true}
                                    numberOfMonths={1}
                                    showDefaultInputIcon={true}
                                    onDateChange={e => this.handleDateChange(e, 'startDate')}
                                    showClearDate={true}
                                    initialVisibleMonth={() => moment(eventData.fromDate)}
                                />
                            </Col>
                            <Col className="col-6 mt-3">
                                <div className='title-sm required' style={{ fontSize: '0.9em', visibility: 'hidden' }}>
                                    <FormattedMessage id="SalesProcess.StartDate"/>
                                </div>
                                <CustomSelect
                                    required={true}
                                    options={timeOptions}
                                    value={timeOptions.find(({value}) => value === spaceData.startHour)}
                                    onChange={e => this.handleComboChange(e, 'startHour')}
                                    placeholder={""}
                                />
                            </Col>

                            <Col className="col-6 mt-3">
                                <div className='title-sm required' style={{ fontSize: '0.9em' }}>
                                    <FormattedMessage id="SalesProcess.EndDate"/>
                                </div>
                                <SingleDatePicker
                                    id="EndDate"
                                    isOutsideRange={this.isOutsideRange}
                                    date={spaceData.endDate}
                                    focused={this.state.focused2}
                                    required={true}
                                    onFocusChange={({ focused }) => this.setState({ focused2: focused })}
                                    small={true}
                                    numberOfMonths={1}
                                    showDefaultInputIcon={true}
                                    onDateChange={e => this.handleDateChange(e, 'endDate')}
                                    showClearDate={true}
                                    initialVisibleMonth={() => moment(eventData.toDate)}
                                />
                            </Col>
                            <Col className="col-6 mt-3">
                                <div className='title-sm required' style={{ fontSize: '0.9em', visibility: 'hidden' }}>
                                    <FormattedMessage id="SalesProcess.EndDate"/>
                                </div>
                                <CustomSelect
                                    required={true}
                                    options={timeOptions}
                                    value={timeOptions.find(({value}) => value === spaceData.endHour)}
                                    onChange={e => this.handleComboChange(e, 'endHour')}
                                    placeholder={""}
                                />
                            </Col>

                            <Col className="col-4 mt-3">
                                <div className='title-sm required' style={{ fontSize: '0.9em' }}>
                                    <FormattedMessage id="SalesProcess.Adults"/>
                                </div>
                                <Input
                                    type='number'
                                    required={true}
                                    name="pax"
                                    value={spaceData.pax}
                                    style={{ backgroundColor: (spaceData.maxCapacity < spaceData.pax || eventData.adults < spaceData.pax) ? 'rgb(241, 172, 178)' : '' }}
                                    min={0}
                                    onChange={this.handleChange}
                                />
                            </Col>
                            <Col className="col-4 mt-3">
                                <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                    <FormattedMessage id="SalesProcess.Children"/>
                                </div>
                                <Input
                                    type='number'
                                    name="children"
                                    min={0}
                                    style={{ backgroundColor: (spaceData.maxCapacity < spaceData.children || eventData.children < spaceData.children) ? 'rgb(241, 172, 178)' : '' }}
                                    value={spaceData.children}
                                    onChange={this.handleChange}
                                />
                            </Col>
                            <Col className="col-4 mt-3">
                                <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                    <FormattedMessage id="SalesProcess.Children2"/>
                                </div>
                                <Input
                                    type='number'
                                    name="children2"
                                    min={0}
                                    style={{ backgroundColor: (spaceData.maxCapacity < spaceData.children2 || eventData.children2 < spaceData.children2) ? 'rgb(241, 172, 178)' : ''}}
                                    value={spaceData.children2}
                                    onChange={this.handleChange}
                                />
                            </Col>

                            <Col className="col-12 mt-3">
                                <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                    <FormattedMessage id="SalesProcess.DailyPrice"/>
                                </div>
                                <Input
                                    type='number'
                                    name="dailyPrice"
                                    value={spaceData.dailyPrice}
                                    onChange={this.handleChange}
                                />
                            </Col>

                            <Col className="col-12 mt-3">
                                <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                    <FormattedMessage id="SalesProcess.Notes"/>
                                </div>
                                <Input
                                    style={{ minHeight: '100px' }}
                                    type='textarea'
                                    name="notes"
                                    onChange={this.handleChange}
                                    value={spaceData.notes}
                                />
                            </Col>
                        </Row>
                        <div className='mt-4 mb-2 d-flex align-items-center justify-content-between'>
                            {!spaceData.id ?
                                <Button className="w-100 py-2 btn btn-host btn-sm btn btn-secondary" type='submit'>
                                    <div style={{ marginTop: '-1px' }}>
                                        <FormattedMessage id="SalesProcess.CreateSpace"/>
                                        <i className='fas fa-plus ml-2'/>
                                    </div>
                                </Button>
                            :
                                <>
                                    <Button className="w-100 py-2 btn btn-host btn-sm btn btn-secondary" type='submit'>
                                        <div style={{ marginTop: '-1px' }}>
                                            <FormattedMessage id="SalesProcess.SaveSpace"/>
                                            <i className='fas fa-save ml-2'/>
                                        </div>
                                    </Button>
                                    <Button onClick={this.toggleConfirmModal} className="ml-3 py-2 btn btn-host btn-sm btn btn-secondary bg-danger border-danger" type='button' style={{ width: '7%' }}>
                                        <div style={{ marginTop: '-1px' }}>
                                            <i className='fas fa-trash'/>
                                        </div>
                                    </Button>
                                </>
                            }
                        </div>
                    </form>
                    {confirmModal ?
                        <ConfirmActionModal
                            modal={confirmModal}
                            toggleModal={_ => this.toggleConfirmModal()}
                            actionFunction={this.removeSpace}
                            text={<FormattedMessage id="SendGrid.ConfirmRemoveSpace"/>}
                            block={block}
                            error={null}
                        />
                    :''}
                </BlockUi>
            </div>
        );
    }
}

export default SpaceManagment;
