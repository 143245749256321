import React, { Component } from 'react';

class SpaceCell extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCells: [],
            originalCells: [],
            hasNewSpaceReservation: false
        };
    }

    componentWillReceiveProps(nextProps){
        const { selectedCells, hasNewSpaceReservation, originalCells } = this.state;

        const isValueDiff = () => {
            const { value } = this.props;
            const periods = [0,1,2,3]

            if(nextProps.value ? !value : value){
                return true;
            }

            periods.forEach(p => {
                const periodValue = nextProps.value[p];
                const prevPeriodValue = value[p];

                if(periodValue.value !== prevPeriodValue.value || periodValue.spaceReservationList.length !== prevPeriodValue.spaceReservationList.length)
                    return true;
            });
        }
        
        if(((this.props.hasNewSpaceReservation() !== hasNewSpaceReservation) && selectedCells.length > 0 && originalCells.length < selectedCells.length) || isValueDiff()
        ){
            this.inicialCalc(nextProps.value);
        }
    }

    componentDidMount(){
        this.inicialCalc(this.props.value);
    }

    inicialCalc = (value) => {
        if(!value)
            return;

        const selectedCells = [];

        for(let i = 0; i < 4; i++) {
            if(value[i]?.spaceReservationList?.length){
                selectedCells.push(i);
            }
        }
        

        this.setState({ selectedCells, originalCells: selectedCells, hasNewSpaceReservation: this.props.hasNewSpaceReservation() });
    }

    handleDragStart = (e, key) => {
        const { selectedCells, originalCells } = this.state;
        const { canDrag, setDraggingRow } = this.props;

        if(!selectedCells.includes(key) && canDrag()){
            this.setState({ selectedCells: [...selectedCells, key], originalCells: originalCells.filter((a) => a !== key), hasNewSpaceReservation: true }, () => setDraggingRow());
        }
    }

    handleDragEnd = (e, key) => {
        const { toggleDetailPopOver } = this.props;
        const { selectedCells } = this.state;

        if(selectedCells?.length){
            this.setState({ hasNewSpaceReservation: true }, () => toggleDetailPopOver(true, key));
        }
    }

    handleDragOver = (e, key) => {
        const { selectedCells, originalCells } = this.state;
        const { canDrag } = this.props;
        
        if(!selectedCells.includes(key) && canDrag()){
            this.setState({ selectedCells: [...selectedCells, key], originalCells: originalCells.filter((a) => a !== key), hasNewSpaceReservation: true });
        }
    }
    
    isNewEvent = (key) => {
        const { selectedCells } = this.state;
        return !(selectedCells.includes(key));
    }

    handleClick = (key) => {
        const { selectedCells, originalCells } = this.state;
        const { canDrag, setDraggingRow, toggleDetailPopOver } = this.props;
        const isNewEvent = this.isNewEvent(key);
        
        if(canDrag() && !selectedCells.includes(key)) {
            this.setState({ selectedCells: [...selectedCells, key], originalCells: originalCells.filter((a) => a !== key), hasNewSpaceReservation: isNewEvent }, () => {
                setDraggingRow();
                toggleDetailPopOver(isNewEvent, key);
            });
        }
        else{
            setDraggingRow();
            toggleDetailPopOver(isNewEvent, key);
        }
    }

    generateClassNames = (key) => {
        const { selectedCells, originalCells } = this.state;
        const { colKey, value, spaceCode } = this.props;

        let classNames = `h-100 cell-time-slots cell-time-slot-time-${colKey} cell-time-slot-index-${key}`;
        
        if(selectedCells.includes(key)){
            classNames += ` cell-time-slot-space-${spaceCode}`;

            if(originalCells.includes(key)){
                classNames += ` cell-time-slots-selected`;
            }
            else{
                classNames += ` cell-time-slots-selected-focused cell-time-slot-reservation-isNew`;
            }
        }

        if(!value[key].value){
            classNames += ' cell-time-slots-empty';
        }

        if(value[key].spaceReservationList?.length){
            classNames += ' cell-time-slots-selected';

            value[key].spaceReservationList.forEach(id => {
                classNames += ` cell-time-slot-reservation-${id}-space-${spaceCode}-date-${colKey}`;
            });
        }

        return classNames;
    }

    render() {
        const { selectedCells, originalCells } = this.state;
        const { value, rowKey, colKey } = this.props;

        const isNew = selectedCells.some((a) => !originalCells.includes(a));
        const id = `cell-${rowKey}-${colKey}`;

        return (
            <>
                <div id={id} className={"py-1 px-2 w-100 h-100 d-flex align-items-center"} style={{ gap: '5%' }}>
                    <div className={this.generateClassNames(0)}>
                        <div
                            className={`h-100 w-100 cell-${value[0].spaceReservationList.toString()}-${rowKey}-isNew-${isNew ? 't' : 'f'}-${colKey}`}
                            onClick={_ => this.handleClick(0)}
                            draggable={true}
                            onDragStart={e => this.handleDragStart(e, 0)}
                            onDragEnd={e => this.handleDragEnd(e, 0)}
                            onDragOver={e => this.handleDragOver(e, 0)}
                        />
                    </div>
                    <div className={this.generateClassNames(1)}>
                        <div
                            className={`h-100 w-100 cell-${value[1].spaceReservationList.toString()}-${rowKey}-isNew-${isNew ? 't' : 'f'}-${colKey}`}
                            onClick={_ => this.handleClick(1)}
                            draggable={true}
                            onDragStart={e => this.handleDragStart(e, 1)}
                            onDragEnd={e => this.handleDragEnd(e, 1)}
                            onDragOver={e => this.handleDragOver(e, 1)}
                        />
                    </div>
                    <div className={this.generateClassNames(2)}>
                        <div
                            className={`h-100 w-100 cell-${value[2].spaceReservationList.toString()}-${rowKey}-isNew-${isNew ? 't' : 'f'}-${colKey}`}
                            onClick={_ => this.handleClick(2)}
                            draggable={true}
                            onDragStart={e => this.handleDragStart(e, 2)}
                            onDragEnd={e => this.handleDragEnd(e, 2)}
                            onDragOver={e => this.handleDragOver(e, 2)}
                        />
                    </div>
                    <div className={this.generateClassNames(3)}>
                        <div
                            className={`h-100 w-100 cell-${value[3].spaceReservationList.toString()}-${rowKey}-isNew-${isNew ? 't' : 'f'}-${colKey}`}
                            onClick={_ => this.handleClick(3)}
                            draggable={true}
                            onDragStart={e => this.handleDragStart(e, 3)}
                            onDragEnd={e => this.handleDragEnd(e, 3)}
                            onDragOver={e => this.handleDragOver(e, 3)}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default SpaceCell;