import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getAPI } from '../Base/API';
import { SelectHotel, StyledCard } from "../Base/CommonUIComponents";
import { handleNotification } from '../Base/Notification';
import { Button, Col, Row, Input, Card, Badge, Collapse } from 'reactstrap';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { getFormSource } from '../Base/ReferenceDataFunctions';
import CustomSelect from '../Base/CustomSelect';
import { Pagination, TotalResults } from '../Base/PaginationComponents';
import { Link } from 'react-router-dom';

export class GoContactTicket extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            ticketid: null,
            ticket: null
        };
    }

    componentDidMount() {
    }

    getTicket = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                if (data?.data.length>0) {
                    this.setState({ ticket: data.data[0], block: false });
                }

            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/GoContact/v1/Ticket/${this.state.ticketid}`);
    }

    render() {
        const { block, error, ticketid, ticket } = this.state;

        return (
            <StyledCard block={block} error={error} icon="far fa-address-book" title="GoContactTicket.Ticket">

                <Row className="mt-3 mb-4 align-items-center">
                    <Col className="col-2 pr-0">
                        <Input
                            type="text"
                            name="Ticket"
                            onChange={(e) => this.setState({ ticketid: e.target.value })}
                            value={ticketid || ''}
                            placeholder={this.props.intl.formatMessage({ id: "GoContactTicket.Ticket" })}
                            maxLength="20"
                        />
                    </Col>
                    <Col>

                    </Col>
                    <Col className="text-right" >
                        <Button className="mr-3 btn btn-sm btn-host" onClick={this.getTicket} >
                            <i className="fa fa-search" />
                        </Button>
                    </Col>
                </Row>
                {ticket ? 
                    <div>
                        <Row className="my-1">
                            <Col className="col-6">
                                <div className='title-sm'>
                                    <FormattedMessage id="GoContactTicket.Subject" />
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <b>{ticket.subject}</b>
                                </div>
                            </Col>
                            <Col className="col-3">
                                <div className='title-sm'>
                                    <FormattedMessage id="GoContactTicket.Source" />
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    {ticket.source_type}
                                </div>
                            </Col>
                            <Col>
                                <div className='title-sm'>
                                    <FormattedMessage id="GoContactTicket.status" />
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    {ticket.status}
                                </div>
                            </Col>
                        </Row>
                        <Row className="my-1">
                            <Col className="col-6">
                                <div className='title-sm'>
                                    <FormattedMessage id="GoContactTicket.Contact" />
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    {ticket.contact_info?.contact}
                                </div>
                            </Col>
                            <Col className="col-3">
                                <div className='title-sm'>
                                    <FormattedMessage id="GoContactTicket.Email" />
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    {ticket.contact_info?.email}
                                </div>
                            </Col>
                            <Col>
                                <div className='title-sm'>
                                    <FormattedMessage id="GoContactTicket.Phone" />
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    {ticket.contact_info?.first_phone}
                                </div>
                            </Col>
                        </Row>
                       
                        {ticket.episodes?.map((el, idx) =>
                            <Card className="my-3">
                            <Collapse className="my-2 mx-2" isOpen="false">
                                    <Row><Col>
                                       <b> {el.user_name}</b>
                                        </Col>
                                        </Row>
                                    <Row>
                                        <Col>
                                            <Badge> {el.status}</Badge>
                                        </Col>
                                        <Col>
                                            <Badge>{el.type}</Badge>
                                        </Col>
                                        <Col>
                                            <Badge>{el.visibility}</Badge>
                                        </Col>
                                        <Col>
                                            {el.episode_create_date}
                                        </Col>
                                        
                                    </Row>
                                    <Row className="my-1">
                                        <Col>
                                            {el.ticket_tags && el.ticket_tags.map((item, i) =>
                                                <Badge color="primary">{item}</Badge>
                                            )}
                                        </Col>
                                    </Row>
                                    {el.from && el.from.length>0 ? 
                                    <div>
                                    <hr />
                                    <Row className="my-1">
                                        <Col>
                                            <h5>From:</h5>
                                            {el.from && el.from.map((item, i) =>
                                                <span color="primary" className="pr-2">{item}</span>
                                            )}
                                        </Col>
                                        <Col>
                                            <h5>To:</h5>
                                            {el.sent_to && el.sent_to.map((item, i) =>
                                                <span color="primary" className="pr-2">{item}</span>
                                            )}
                                            </Col>
                                        </Row>
                                    </div>
                                       :'' }
                                    <hr/>
                                    <Row className="my-1">
                                        <Col>
                                            {el.close_comments ?
                                                <div dangerouslySetInnerHTML={{ __html: el.close_comments }} />
                                                : el.html_part ? 
                                                <div dangerouslySetInnerHTML={{ __html: el.html_part }} />
                                                :
                                                <div>{el.text_part}</div>
                                            }
                                    
                                </Col>
                                    </Row>
                                    {el.attachments?.length > 0 ?
                                        <div className="my-1">
                                            <hr/>
                                            <h5>
                                                <i class="fas fa-paperclip mr-2"></i> Attach
                                            </h5>
                                            {el.attachments?.map((item, x) =>
                                                <Row >
                                                    <Col>{item.filename}</Col>

                                                </Row>
                                            )}
                                        </div>
                                        : ''}
                                    
                                </Collapse>
                                </Card>
                            )}
                    </div>
                    :''}
                
            </StyledCard>
        );
    }
}
export default injectIntl(GoContactTicket)