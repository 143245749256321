import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Card, Col, Row, Nav, NavItem, NavLink, Badge,Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';


import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../Base/ErrorAlert';

class Tickets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            activeTab: 'Contacts',
            tabs: [
                /*{ name: 'Tickets', title: 'GoContactMenu.Tickets', icon: 'far fa-envelope' },*/
            ]
        };
    }

    componentDidMount() {
    }

    

    render() {
        const { block, error, tabs, activeTab } = this.state;

        return (
            <div className="border-0 h-100 py-2">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <Row>
                        <Col className='col-12 d-flex align-items-start pr-0'>
                            <div style={{ width: '15%' }}>
                                <Nav tabs vertical className='border-0'>
                                    <Card className="bg-white p-2 border-0 shadow" style={{ borderRadius: '5px', height: '80vh' }}>
                                        <div className='d-flex align-items-center mb-1 p-2'>
                                            <h6>
                                                <FormattedMessage id="Tickets.Filters" />
                                            </h6>
                                        </div>
                                        <Row className="p-2">
                                            <Col>
                                                Hotel Id
                                            </Col>
                                            <Col>
                                                Status
                                            </Col>
                                        </Row>
                                        <div className='d-flex align-items-center mb-1 p-2'>
                                            <h6>
                                                <FormattedMessage id="Tickets.Tickets" />
                                            </h6>
                                        </div>
                                        <hr className="my-1" />
                                        <Row>
                                            <Col>
                                                Mala Perdida no quarto 23
                                            </Col>
                                            <Col className="col-2 fr-0">
                                                <i class="far fa-id-badge"></i>
                                            </Col>
                                        </Row>
                                        <Row className="small my-1">
                                            <Col className="col-4">
                                                <Badge color="primary">Open</Badge>
                                                <div className="small">Operador</div>
                                            </Col>
                                            <Col className="col-4 ">
                                                54RRtTT33
                                            </Col>
                                            <Col className="col-4 fr-0">
                                                2 seg ago
                                            </Col>

                                        </Row>
                                        
                                        <hr className="my-1" />

                                       
                                        {tabs && tabs.map((tab, key) => {
                                            const isActive = activeTab === tab.name;

                                            return (
                                                <NavItem className="pb-2" key={key}>
                                                    <NavLink onClick={_ => this.setState({ activeTab: tab.name })} className="pointer border-0" style={{ width: 'fit-content' }}>
                                                        {isActive ?
                                                            <div style={{
                                                                'left': '0',
                                                                'position': 'absolute',
                                                                'borderRadius': '0px 30px 30px 0px',
                                                                'width': '7px',
                                                                'height': '14px',
                                                                'background': '#0667FF 0% 0% no-repeat padding-box',
                                                                'opacity': '1',
                                                                'marginTop': '2px'
                                                            }} />
                                                            : ''}
                                                        <div id={`tab-${key}`} className={(isActive ? 'text-primary ' : '') + 'd-flex align-items-center justify-content-start'}>
                                                            <i className={`${tab.icon} mr-2`} /> 
                                                            <FormattedMessage id={tab.title} />
                                                        </div>
                                                    </NavLink>
                                                </NavItem>
                                            )
                                        }
                                        )}
                                    </Card>
                                </Nav>
                            </div>
                            <div style={{ width: '85%' }}>
                                {activeTab === 'Contacts' && !block ?
                                    <Col>
                                        <Card className="bg-white p-2 border-0 shadow" style={{ borderRadius: '5px', height: '80vh' }}>
                                            <div className='d-flex align-items-center mb-4 p-2'>
                                                <Col>
                                                <h5>
                                                    <FormattedMessage id="Tickets.TicketDetail" />
                                                    </h5>
                                                </Col>
                                                <Col className="text-right">

                                                    <Button className="mr-3 btn btn-sm btn-host"  >
                                                        <i className="fa fa-envelope-open-text" />
                                                    </Button>
                                                    <Button className="mr-3 btn btn-sm btn-host"  >
                                                        <i className="fa fa-reply" />
                                                    </Button>
                                                    <Button className="mr-3 btn btn-sm btn-host"  >
                                                        <i className="fa fa-plus" />
                                                    </Button>
                                                </Col>
                                            </div>
                                            <div>
                                                Detalhe do ticket 
                                            </div>
                                            <Row>
                                                <Col>
                                                </Col>
                                                <Col>
                                                </Col>

                                            </Row>


                                        </Card>
                                    </Col>
                                    : ''}
                                
                            </div>
                        </Col>
                    </Row>
                </BlockUi>
            </div>
        );
    }
}
export default injectIntl(Tickets)
