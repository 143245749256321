import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Nav, NavItem, NavLink, PopoverBody, Row } from 'reactstrap';
import CustomToolTip from '../../Base/CustomToolTip';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import SalesDealProposalResumeBar from '../SalesDealProposalResumeBar';
import { CoolTooltip, FormatAmountNumber, KebabMenu } from '../../Base/CommonUIComponents';
import { ErrorAlert } from '../../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { getSalesDealActionStatusOptions, getSalesDealActionTypes } from '../../Base/ReferenceDataFunctions';
import FocusActionCard from '../FocusActionCard';
import InfiniteScroll from 'react-infinite-scroller';
import SalesActionModal from '../SalesActionModal';
import ConfirmActionModal from '../../Base/ConfirmActionModal';
import { CustomFormatDate } from '../SalesProcessFunctions';
import FormReplyModal from '../FormReplyModal';

class SalesDealDetailActions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            blockScroll: false,
            error: false,
            actions: [],
            total: 0,
            type: null,
            status: null,
            pageSize: 10,
            pageIndex: 0,
            orderByOld: false,
            actionStatusOptions: getSalesDealActionStatusOptions(this.props.intl),
            actionTypesOptions: getSalesDealActionTypes(this.props.intl, true),
            actionModal: false,
            selectedAction: null,
            confirmActionModal: false,
            changeToStatus: null
        };
    }

    componentDidMount(){
        this.getDealActions();
    }

    getDealActions = (isScroll) => {
        const { deal } = this.props;
        const { type, status, pageSize, pageIndex, orderByOld, actions } = this.state;
        let qs = '?';
        
        if(type)
            qs += `type=${type}&`;
        if(status)
            qs += `status=${status}&`;
        if(orderByOld)
            qs += `orderByOld=${orderByOld}&`;
        if(pageSize)
            qs += `pageSize=${pageSize}&`;
        if(pageIndex){
            qs += `pageIndex=${pageIndex}&`;
            qs += `skip=${actions.length}&`;
        }

        const timerStart = moment();
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                
                this.setState({
                    block: false,
                    blockScroll: false,
                    actions: isScroll ? actions.concat(data.data??[]) : data.data,
                    total: data.total??0
                }, () => this.updatePageSize(moment().diff(timerStart, 'seconds')));

                return;
            }
            else this.setState({ error: errorMessage, block: false, blockScroll: false });
        }, `/api/gms/SalesProcess/v1/deal/${deal.id}/actions${qs}`);
    }

    updatePageSize = (time) => { // update page size based on slow api response time
        const { pageSize } = this.state;

        if(time > 2 && pageSize > 1)
            this.setState({ pageSize: (pageSize - 2) < 2 ? 1 : (pageSize - 2) });
        
        if(time < 2 && pageSize < 20)
            this.setState({ pageSize: pageSize + 2 });
    }
    
    toggleType = (value) => {
        this.setState({ type: value, block: true, pageIndex: 0 }, () => this.getDealActions());
    }

    toggleStatus = (value) => {
        this.setState({ status: value === this.state.status ? null : value, block: true, pageIndex: 0 }, () => this.getDealActions());
    }
    
    toggleOrderByOld = () => {
        this.setState({ orderByOld: !this.state.orderByOld, block: true, pageIndex: 0 }, () => this.getDealActions());
    }

    loadNextActions = () => {
        const { blockScroll, pageIndex } = this.state;

        if(!blockScroll)
            this.setState({ pageIndex: pageIndex + 1, blockScroll: true }, () => this.getDealActions(true));
    }

    toggleActionModal = (selectedAction, event) => {
        const { intl } = this.props;

        if (this.state.actionModal) {
            if (!window.confirm(intl.formatMessage({ id: 'stripo.confirmUnsaved' }))) {
                event.preventDefault();
            }
            else {
                this.setState({ actionModal: !this.state.actionModal, selectedAction });
            }
        }
        else {
            this.setState({ actionModal: !this.state.actionModal, selectedAction });
        }
    }

    toggleConfirmActionModal = (action, status) => {
        this.setState({
            confirmActionModal: !this.state.confirmActionModal,
            selectedAction: action,
            changeToStatus: status
        })
    }

    toggleFormModal = () => {
        this.setState({
            leadFormModal: !this.state.leadFormModal
        })
    }

    resetFiltersAndSearch = () => {
        this.setState({
            block: true,
            actions: [],
            total: 0,
            type: null,
            status: null,
            pageSize: 10,
            pageIndex: 0,
            orderByOld: false,
            confirmActionModal: false,
            actionModal: false
        }, () => this.getDealActions());
    }

    render() {
        const { changeActionPin, downloadAction,selectedPipeline, source, toggleEdit, deal, intl, salesUsers, customers, focusActions, updateActionStatus, isLead, hotelCurrency } = this.props;
        const { error, block, actions, type, status, actionStatusOptions, actionTypesOptions, orderByOld, total, actionModal, selectedAction, confirmActionModal, changeToStatus, leadFormModal, formDetails } = this.state;

        return (
            <div>
                <ErrorAlert error={error}/>
                <Row className='h-100'>
                    <Col className='pt-2 col-9'>
                        <div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                    <h5 className='m-0'>
                                        <FormattedMessage id="SalesProcess.Actions"/>
                                    </h5>
                                </div>
                                <div>
                                    <Button onClick={_ => this.toggleActionModal()} className="btn btn-host btn-sm">
                                        <FormattedMessage id="SalesProcess.NewAction"/>
                                        <i className="fas fa-play ml-2"/>
                                    </Button>
                                </div>
                            </div>
                            {focusActions && focusActions.length > 0 ?
                                <div className='mt-3'>
                                    <div className="text-muted pb-3" style={{ fontSize: '1.1em' }}>
                                        <i className="fas fa-briefcase mr-2"/>
                                        <FormattedMessage id="SalesDeal.FocusedActions"/>
                                    </div>
                                    <div>
                                        {focusActions.map((action, key, arr) =>
                                            <div key={key}>
                                                <FocusActionCard
                                                    bigAction={true}
                                                    action={action}
                                                    intl={intl}
                                                    focusActions={arr}
                                                    editFunction={_ => this.toggleActionModal(action)}
                                                    deleteFunction={_ => this.toggleConfirmActionModal(action, 'Cancelled')}
                                                    finishedFunction={_ => this.toggleConfirmActionModal(action, 'Finished')}
                                                    onGoingFunction={_ => this.toggleConfirmActionModal(action, 'OnGoing')}
                                                    pinAction={(e) => changeActionPin(e, action)}
                                                    downloadFunction={(e) => downloadAction(e, action)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            :''}
                            {focusActions && focusActions.length > 0 ?
                                <div className="text-muted mb-3 mt-3" style={{ fontSize: '1.1em' }}>
                                    <i className="far fa-check-circle mr-2"/>
                                    <FormattedMessage id="SalesDeal.History"/>
                                </div>
                            :''}
                            <div className='mt-3 d-flex align-items-start justify-content-between' style={{ borderBottom: '1px solid lightgrey' }}>
                                <div>
                                    <Nav tabs className='border-0'>
                                        <NavItem>
                                            <NavLink className={!type ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleType(null)} style={{ cursor: "pointer" }}>
                                                <FormattedMessage id="SalesDeal.All"/>
                                            </NavLink>
                                        </NavItem>
                                        {actionTypesOptions.map((op, key) =>
                                            <NavItem key={key}>
                                                <NavLink className={type === op.value ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleType(op.value)} style={{ cursor: "pointer" }}>
                                                    <i className={`mr-2 ${op.icon}`}/>
                                                    {op.label}
                                                </NavLink>
                                            </NavItem>
                                        )}
                                    </Nav>
                                </div>
                                <div className='d-flex align-items-start justify-content-end'>
                                    <div className='d-flex align-items-center justify-content-end' style={{ fontSize: '0.75em' }}>
                                        {actionStatusOptions.map((op, key) =>
                                            <div onClick={() => this.toggleStatus(op.value)} key={key} className={`deal-action-badge-${op.value} ${status === op.value ? 'selected-deal-action-badge' : ''} ml-2 p-2`}>
                                                {op.label}
                                            </div>
                                        )}
                                    </div>
                                    <div className='ml-4'>
                                        <Button id="sortBtn" className="ml-2 btn btn-host btn-sm" onClick={this.toggleOrderByOld}>
                                            {orderByOld ?
                                                <i className="fas fa-sort-amount-up"/>
                                            :
                                                <i className="fas fa-sort-amount-down"/>
                                            }
                                        </Button>
                                        <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="bottom" target={`sortBtn`}>
                                            <PopoverBody>
                                                <FormattedMessage id={`${!orderByOld ? 'SalesProcess.SortByNewButton' : 'SalesProcess.SortByOldButton'}`} />
                                            </PopoverBody>
                                        </CustomToolTip>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-4'>
                                <BlockUi tag="div" blocking={block}>
                                    {actions && actions.length > 0 ?
                                        <InfiniteScroll
                                            pageStart={0}
                                            loadMore={this.loadNextActions}
                                            element='div'
                                            hasMore={actions.length < total}
                                            loader={<div style={{ width: '100%' }} key="loader"><BlockUi tag="div" blocking={true}/></div>}
                                        >
                                            {actions.map((action, key, arr) =>
                                                <div key={key}>
                                                    <ActionCard
                                                        action={action}
                                                        k={key}
                                                        intl={intl}
                                                        actionsList={arr}
                                                        editFunction={() => this.toggleActionModal(action)}
                                                        deleteFunction={() => this.toggleConfirmActionModal(action, 'Cancelled')}
                                                        pinAction={(e) => changeActionPin(e, action)}
                                                        salesUsers={salesUsers}
                                                        finishedFunction={_ => this.toggleConfirmActionModal(action, 'Finished')}
                                                        onGoingFunction={_ => this.toggleConfirmActionModal(action, 'OnGoing')}
                                                        downloadFunction={(e) => downloadAction(e, action)}
                                                        toggleFormModal={this.toggleFormModal}
                                                        deal={deal}
                                                    />
                                                </div>
                                            )}
                                        </InfiniteScroll>
                                    :
                                        <div className='text-muted d-flex align-items-center justify-content-center w-100' style={{ height: '150px' }}>
                                            {!block ?
                                                <FormattedMessage id="SalesProcess.NoActions"/>
                                            :''}
                                        </div>
                                    }
                                </BlockUi>
                            </div>
                        </div>
                    </Col>
                    <Col className='pt-2 col-3 px-0 d-flex justify-content-between h-100 flex-column' style={{ textAlign: 'center', top: '0', position: 'sticky' }}>
                        <div>
                            <div className='d-flex aling-items-center justify-content-between px-3'>
                                <div className=''>
                                    <h5 className='m-0'>
                                        <FormattedMessage id="SalesDeal.DealResume"/>
                                    </h5>
                                </div>
                                <div>
                                    <Button id="editFieldBtnTooltip" onClick={toggleEdit} className="btn btn-host btn-sm">
                                        <i className="fas fa-edit"/>
                                    </Button>
                                    <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="bottom" target={`editFieldBtnTooltip`}>
                                        <PopoverBody>
                                            <FormattedMessage id={`${isLead ? 'SalesProcess.EditLeadButton' : 'SalesProcess.EditDealButton'}`} />
                                        </PopoverBody>
                                    </CustomToolTip>
                                </div>
                            </div>
                            <div className='px-3 pt-3 text-left'>
                                <div className='shadow w-100 mb-3 bg-white p-3'>
                                    <div className='text-muted'>
                                        <i className="mr-2 fas fa-suitcase-rolling"/>
                                        <FormattedMessage id="SalesProcess.DealDetails"/>
                                    </div>
                                    <div className='px-2'>
                                        <Row className='w-100'>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.Source"/>
                                                </div>
                                                {source?.label ?
                                                    <div>
                                                        {source.label}
                                                    </div>
                                                :'-'}
                                            </Col>
                                            {!isLead ?
                                                <Col className='col-6 mt-2'>
                                                    <div className='title-sm'>
                                                        {selectedPipeline?.label}
                                                    </div>
                                                    <div className='mt-1 h-100 d-flex w-100 px-1'>
                                                        {selectedPipeline?.stages ?
                                                            selectedPipeline?.stages.map((stage, key) =>{
                                                                const selectedStage = stage.value === deal.salesPipelineStage?.id;
                                                                const stageName = deal?.salesPipelineStage?.name;
                                                                
                                                                return (
                                                                    <React.Fragment key={key}>
                                                                        <div id={`stage-${key}`} className={`h-100 ${selectedStage ? 'cursor-pointer' : ''}`} style={{ width: '100%' }}>
                                                                            <div className='text-center pipeline smallPipeline'>
                                                                                <div className={`arrow top smallPipeline noShadow ${selectedStage ? 'selectedStage' : ''}`}></div>
                                                                                <div className={`arrow bottom noShadow ${selectedStage ? 'selectedStage' : ''}`}></div>
                                                                            </div>
                                                                        </div>
                                                                        {selectedStage && stageName ? 
                                                                            <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="bottom" target={`stage-${key}`}>
                                                                                <PopoverBody>
                                                                                    {stageName}
                                                                                </PopoverBody>
                                                                            </CustomToolTip>
                                                                        :''}
                                                                    </React.Fragment>
                                                                )}
                                                            )
                                                        :''}
                                                    </div>
                                                </Col>
                                            :
                                                <Col className='col-6 mt-2'>
                                                    <div className='title-sm'>
                                                        <FormattedMessage id="SalesProcess.CreatedAt"/>
                                                    </div>
                                                    {deal.createdAt ?
                                                        <div style={{ maxHeight: '25px' }}>
                                                            {deal.createdAt}
                                                        </div>
                                                    :
                                                        '-'
                                                    }
                                                </Col>
                                            }
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.NegotiationStartDate"/>
                                                </div>
                                                {deal.negotiationStartDate ?
                                                    <div>
                                                        {CustomFormatDate(deal.negotiationStartDate, null, null, intl)}
                                                    </div>
                                                :'-'}
                                            </Col>
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.NegotiationEndDate"/>
                                                </div>
                                                {deal.negotiationEndDate ?
                                                    <div>
                                                        {CustomFormatDate(deal.negotiationEndDate, null, null, intl)}
                                                    </div>
                                                :'-'}
                                            </Col>
                                            {!isLead ?
                                                <>
                                                    <Col className='col-6 mt-2'>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="SalesProcess.CreatedAt"/>
                                                        </div>
                                                        {deal.createdAt ?
                                                            <div style={{ maxHeight: '25px' }}>
                                                                {CustomFormatDate(deal.createdAt, null, null, intl)}
                                                            </div>
                                                        :
                                                            '-'
                                                        }
                                                    </Col>
                                                    <Col className='col-6 mt-2'>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="SalesProcess.ExpectedEndDate"/>
                                                        </div>
                                                        {deal.expectedEndDate ?
                                                            <div>
                                                                {CustomFormatDate(deal.expectedEndDate, null, null, intl)}
                                                            </div>
                                                        :'-'}
                                                    </Col>
                                                    <Col className='col-6 mt-2'>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="SalesProcess.BusinessStartDate"/>
                                                        </div>
                                                        {deal.businessStartDate ?
                                                            <div>
                                                                {CustomFormatDate(deal.businessStartDate, null, null, intl)}
                                                            </div>
                                                        :'-'}
                                                    </Col>
                                                    <Col className='col-6 mt-2'>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="SalesProcess.BusinessEndDate"/>
                                                        </div>
                                                        {deal.businessEndDate ?
                                                            <div>
                                                                {CustomFormatDate(deal.businessEndDate, null, null, intl)}
                                                            </div>
                                                        :'-'}
                                                    </Col>
                                                    <Col className='col-6 mt-2'>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="SalesProcess.Budget"/>
                                                        </div>
                                                        {deal.budget ?
                                                            <div>
                                                                <FormatAmountNumber value={deal.budget} currency={hotelCurrency} />
                                                            </div>
                                                        :'-'}
                                                    </Col>
                                                    <Col className='col-6 mt-2'>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="SalesProcess.ExpectedValue"/>
                                                        </div>
                                                        {deal.expectedValue ?
                                                            <div>
                                                                <FormatAmountNumber value={deal.expectedValue} currency={hotelCurrency} />
                                                            </div>
                                                        :'-'}
                                                    </Col>
                                                </>
                                            :''}
                                            <Col className='col-12 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.RelatedHotels"/>
                                                </div>
                                                <div>
                                                    {deal.hotelIds ?
                                                        <div style={{ maxHeight: '25px' }} className='overflow-auto veryCoolScrollBar'>
                                                            {deal.hotelIds?.map((hotelId, key) => {
                                                                const hotel = global.hotelList?.find(({value}) => value === hotelId);
                                                                return (
                                                                    <React.Fragment key={key}>
                                                                        {key ?
                                                                            <span>,</span>
                                                                        :''}
                                                                        <span className={key ? 'ml-1' : ''}>
                                                                            {hotel?.label || hotelId}
                                                                        </span>
                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                        </div>
                                                    :''}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='shadow w-100 mb-3 bg-white p-3'>
                                    <div className='text-muted'>
                                        <i className="fas fa-users mr-2"/>
                                        <FormattedMessage id="SalesProcess.Clients"/>
                                    </div>
                                    <Row className='px-2 w-100'>
                                        {!isLead && deal.customer?.name ?
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                <FormattedMessage id="SalesProcess.Customer"/>
                                                </div>
                                                <div className='text-truncate'>
                                                    <span className='text-muted hover-underline'>
                                                        <Link to={{ pathname: "/ProfileDetails/" + deal.customer.id }}>
                                                            {deal.customer.name}
                                                        </Link>
                                                    </span>
                                                </div>
                                            </Col>
                                        :''}
                                        {deal.mainContact?.name ?
                                            <Col className='col-6 mt-2'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.MainContact"/>
                                                </div>
                                                <div className='text-truncate'>
                                                    <span className='text-muted hover-underline'>
                                                        <Link to={{ pathname: "/ProfileDetails/" + deal.mainContact.id }}>
                                                            {deal.mainContact.name}
                                                        </Link>
                                                    </span>
                                                </div>
                                            </Col>
                                        :''}
                                        {deal.salesDealProfile?.map((u, k) => {
                                            const user = customers.find(({id}) => id === u.id);
                                            return (
                                                <Col key={k} className={`col-6 mt-2`}>
                                                    <div className='title-sm'>{u.role}</div>
                                                    {user?.name ?
                                                        <div className='text-truncate'>
                                                            <Link to={{ pathname: "/ProfileDetails/" + u.profileId }}>
                                                                {user.name}
                                                            </Link>
                                                        </div>
                                                    :'-'}
                                                </Col>
                                            )}
                                        )}
                                    </Row>
                                </div>
                                <div className='shadow w-100 mb-3 bg-white p-3'>
                                    <div className='text-muted'>
                                        <i className="fas fa-user-cog mr-2"></i>
                                        <FormattedMessage id="SalesProcess.Staff"/>
                                    </div>
                                    <Row className='px-2 w-100'>
                                        {deal.salesDealUser?.map((u, k) => {
                                            const user = salesUsers.find(({id}) => id === u.userId);
                                            return (
                                                <Col key={k} className={`col-6 mt-2`}>
                                                    <div className='title-sm'>{u.role}</div>
                                                    {user?.firstName && user.lastName ?
                                                        <div>{`${user.firstName} ${user.lastName}`}</div>
                                                    :'-'}
                                                </Col>
                                            )}
                                        )}
                                    </Row>
                                </div>
                            </div>
                        </div>
                        {!isLead && deal.salesDealProposals && deal.salesDealProposals.length ?
                            <SalesDealProposalResumeBar
                                salesDealProposals={deal.salesDealProposals}
                            />
                        :''}
                    </Col>
                </Row>

                <>
                    {actionModal ?
                        <SalesActionModal
                            customers={customers}
                            toggle={_ => this.toggleActionModal()}
                            salesUsers={salesUsers}
                            salesDealId={deal.id}
                            salesDealHotelIds={deal?.hotelIds}
                            salesDealUsers={salesUsers.filter(user => deal.salesDealUser.some(dealUser => dealUser.userId === user.value))}
                            salesPipelineStageId={deal.salesPipelineStage?.id}
                            selectedAction={selectedAction || null}
                            updateActions={this.resetFiltersAndSearch}
                        />
                    :''}

                    {leadFormModal ?
                        <FormReplyModal
                            toggleModal={this.toggleFormModal}
                            modal={true}
                            formId={deal?.formId}
                        />
                        : ''}

                    {confirmActionModal ?
                        <ConfirmActionModal
                            modal={confirmActionModal}
                            toggleModal={() => this.toggleConfirmActionModal()}
                            actionFunction={(e) => updateActionStatus(e, selectedAction.id, changeToStatus,  this.resetFiltersAndSearch)}
                            text={<FormattedMessage id={`SalesDeal.Confirm${changeToStatus}Text`} values={{ action: selectedAction.title }}/>}
                            block={block}
                        />
                    :''}
                </>
            </div>
        );
    }
}

const ActionCard = ({ action, intl, actionsList, editFunction, deleteFunction, downloadFunction, k, salesUsers, pinAction, finishedFunction, onGoingFunction, toggleFormModal, deal }) => {
    const isActionable = action.type !== 'Status' && action.type !== 'Create' && action.type !== 'Stage' && action.type !== 'SavedValue' && action.type !== "LeadForm";
    const lastItem = (k + 1) === actionsList.length;
    const renderActionsUsers = () => {
        const firstUser = salesUsers?.find(({ id }) => id === action.salesDealActionUsers[0]?.userId);
        return (
            <>
                <div
                    className={`emailDescription smallDescription text-muted ${action.salesDealActionUsers.length > 1 ? 'cursor-pointer' : ''}`}
                    style={{ fontSize: '0.9em', width: 'fit-content' }}
                >
                    {firstUser?.firstName && firstUser?.lastName && (
                        <div className='d-flex align-items-center mr-2'>
                        <div>
                            <i id={`interveningHelp-${k}`} className="far fa-user" />
                        </div>
                        <div id={`interveningUsers-${k}`} className='ml-2'>
                            {`${firstUser.firstName} ${firstUser.lastName} ${action.salesDealActionUsers.length > 1 ? '...' : ''}`}
                        </div>
                        </div>
                    )}
                </div>
                {action.salesDealActionUsers.length > 1 && (
                    <CoolTooltip placement="right" target={`interveningUsers-${k}`}>
                        {action.salesDealActionUsers.map((u, key) => {
                            const user = salesUsers?.find(({ id }) => id === u.userId);
                                return user ? (
                                    <Col key={key} className={`col-12 ${key !== action.salesDealActionUsers.length - 1 ? 'mb-2' : ''} p-0`}>
                                        <div className='mt-1'>
                                            <div className='emailDescription smallDescription'>
                                                <div className='d-flex align-items-center'>
                                                    <div>
                                                        <i className="far fa-user" />
                                                    </div>
                                                    <div className='ml-2'>
                                                        {`${user.firstName} ${user.lastName}`}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ) : null;
                        })}
                    </CoolTooltip>
                )}
                <CoolTooltip placement="top" target={`interveningHelp-${k}`}>
                    <FormattedMessage id="SalesProcess.Interveners" />
                </CoolTooltip>
            </>
        );
    };

    return (
        <>
            {!k ?
                <Row className='w-100'>
                    <Col className='col-1'/>
                    <Col className='col-11'>
                        <div className='text-center w-100 text-muted mb-3'>
                            {moment(action.createdAt).format('dddd, DD-MM')}
                        </div>
                    </Col>
                </Row>
            :''}
            <Row className='w-100'>
                <Col className={`col-1 d-flex align-items-center justify-content-center flex-column pr-0`}>
                    <div>
                        <div
                            title={action.type ? intl.formatMessage({ id: `SalesProcess.${action.type}` }) : ''}
                            style={{
                                fontSize: isActionable ? '1.1em' : '0.85em',
                                background: isActionable ? '#0665ff' : '#bcbcbc',
                                color: 'white',
                                width: isActionable ? '40px' : '30px',
                                height: isActionable ? '40px' : '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '100%'
                            }}
                        >
                            {action.type === 'Email' ?
                                <i style={{ marginTop: '1px' }} className="far fa-envelope"></i>
                            : action.type === 'Call' ?
                                <i className="fas fa-phone"></i>
                            : action.type === 'Note' ?
                                <i style={{ marginTop: '1px' }} className="far fa-sticky-note"></i>
                            : action.type === 'File' ?
                                <i style={{ marginTop: '1px' }} className="fas fa-file"></i>
                            : action.type === 'Meeting' ?
                                <i style={{ marginTop: '1px' }} className="far fa-building"></i>
                            : action.type === 'Status' ?
                                <i style={{ marginTop: '1px' }} className="fas fa-power-off"></i>
                            : action.type === 'Create' ?
                                <i style={{ marginTop: '1px' }} className="fas fa-briefcase"/>
                            : action.type === 'Stage' ?
                                <i style={{ marginTop: '1px' }} className="fas fa-level-up-alt"/>
                            : action.type === 'LeadForm' ?
                                <i style={{ marginLeft: '3px' }} className="fas fa-file-signature"/>
                            : action.type === 'Task' ?
                                <i className="far fa-clipboard"/>
                            : action.type === 'SavedValue' ?
                                <i className="fas fa-camera" />
                            :''}
                        </div>
                    </div>
                    <div style={{
                        width: '1px',
                        height: '100%',
                        borderLeft: k < (actionsList.length - 1) ? '1px dashed lightgrey' : '',
                        borderRight: k < (actionsList.length - 1) ? '1px dashed lightgrey' : ''
                    }}/>
                </Col>
                {isActionable ?
                    <Col className='col-11 pr-0'>
                        <div className='pb-2 px-3 card shadow border-0'>
                            <Row className='py-3'>
                                <Col className='col-8 d-flex align-items-center'>
                                    <div className='text-truncate'>
                                        {action.title ?
                                            action.type === 'Task' ? 
                                                <div className='d-flex flex-column align-items-start'>
                                                    <div>
                                                        <b style={{ fontSize: '1.1em' }}>{action.title} </b>
                                                    </div>
                                                    <div className='mt-1'>
                                                        {renderActionsUsers()}
                                                    </div>
                                                </div>
                                            :
                                                <b style={{ fontSize: '1.1em' }}>
                                                    {action.title}
                                                </b>
                                        :
                                            action.type === 'Note' ?
                                                <b style={{ fontSize: '1.1em' }}><FormattedMessage id={`SalesProcess.${action.type}`}/></b>
                                            :''
                                        }
                                    </div>
                                </Col>
                                <Col className='col-4 d-flex align-items-center justify-content-between'>
                                    <div className='d-flex align-items-center'>
                                        {action.status === 'Scheduled' ?
                                            <i style={{ color: 'lightgrey' }} className="mr-2 fas fa-minus-circle"/>
                                        : action.status === 'Cancelled' ?
                                            <i className="mr-2 fas fa-times-circle color-light-red"/>
                                        : action.status === 'OnGoing' ?
                                            <i className="mr-2 fas fa-arrows-alt-h" style={{ color: "#0665ff" }}/>
                                        : action.status === 'Finished' ?
                                            <i className="mr-2 fas fa-check-circle text-success"/>
                                        :''}
                                        <div style={{ fontSize: '0.9em', marginTop: '1px' }} className={`deal-action-status-${action.status}`}>
                                            <FormattedMessage id={`SalesActions.${action.status}`}/>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        <div className='text-muted mr-3' style={{ fontSize: '0.9em' }}>
                                            {action.type === "Task" ?
                                                <>
                                                    <div id={`actionDueDateTitle-${k}`}>
                                                        {CustomFormatDate(action.dueDate, null, 'HH:mm', intl)}
                                                    </div>
                                                    <CoolTooltip placement="top" target={`actionDueDateTitle-${k}`}>
                                                        <FormattedMessage id="SalesProcess.DeadLine" />
                                                    </CoolTooltip>
                                                </>
                                            :
                                                <>
                                                    <div id={`actionStartDateTitle-${k}`}>
                                                        {CustomFormatDate(action.startDate, null, 'HH:mm', intl)}
                                                    </div>
                                                    <CoolTooltip placement="top" target={`actionStartDateTitle-${k}`}>
                                                        <FormattedMessage id="SalesProcess.StartDate" />
                                                    </CoolTooltip>
                                                </>
                                            }
                                        </div>
                                        <div>
                                            
                                            {action.type === 'File' || action.type === 'Note' ?
                                                <KebabMenu
                                                    editFunction={editFunction}
                                                    deleteText={"SalesDeal.Cancel"}
                                                    deleteFunction={deleteFunction}
                                                    downloadFunction={action.type === 'File' ? downloadFunction : null}
                                                    extraFields={[
                                                        { text: action.pinned ? "SalesProcess.Unpin" : "SalesProcess.Pin", function: pinAction, icon: "fas fa-thumbtack mr-2" }
                                                    ]}
                                                />
                                                : action.type === 'Task' ?
                                                    <KebabMenu
                                                        editFunction={editFunction}
                                                        deleteFunction={deleteFunction}
                                                        deleteText={"SalesDeal.Cancel"}
                                                        extraFields={[
                                                            action.status == "Scheduled" ? {
                                                                text: "SalesProcess.OnGoing",
                                                                textClass: "onGoingColorIcon",
                                                                function: _ => onGoingFunction(action),
                                                                icon: "fas fa-arrows-alt-h mr-2 onGoingColorIcon",
                                                            } : null,
                                                            action.status == "OnGoing" ? {
                                                                text: "SalesActions.Finished",
                                                                textClass: "text-success",
                                                                function: _ => finishedFunction(action),
                                                                icon: "mr-2 fas fa-check-circle text-success"
                                                            } : null,
                                                        ].filter(Boolean)}
                                                    />
                                                    : <KebabMenu
                                                        editFunction={editFunction}
                                                        deleteFunction={deleteFunction}
                                                        deleteText={"SalesDeal.Cancel"}
                                                    />
                                            }
                                        </div>
                                        </div>
                                </Col>
                            </Row>
                            {action.summary ?
                                <>
                                    <hr className='m-0' />
                                    <div className='py-2'>
                                        <div dangerouslySetInnerHTML={{__html: action.summary}}/>
                                    </div>
                                </>
                            :''}
                            <div className={`d-flex align-items-center justify-content-${action.type === 'Task' ? 'between' : 'end'} mt-1`}>
                                <div className={`mt-1 ${action.type === 'Task' ? 'd-flex align-items-center' : ''}`}>
                                    {action.type === 'Task' && salesUsers?.find(({ userId }) => userId === action.createdBy) && (
                                        <div className='emailDescription smallDescription text-muted' style={{ fontSize: '0.9em', width: 'fit-content' }}>
                                            <div className='title-sm'>
                                                <FormattedMessage id="SalesProcess.CreatedBy" />
                                            </div>
                                            <div>
                                                {salesUsers.find(({ userId }) => userId === action.createdBy)?.firstName} {salesUsers.find(({ userId }) => userId === action.createdBy)?.lastName}
                                            </div>
                                        </div>
                                    )}
                                    {action.salesDealActionUsers?.length && isActionable && action.type !== 'Task' ?
                                        <div>
                                            {renderActionsUsers()}
                                        </div>
                                    : ''}
                                </div>

                                {action.type === 'Task' && (
                                    <div>
                                        {action.startDate && !action.endDate && (
                                            <div className='d-flex flex-column align-items-start text-muted' style={{ fontSize: '0.9em' }}>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.StartDate" />
                                                </div>
                                                <div>
                                                    {CustomFormatDate(action.startDate, null, 'HH:mm', intl)}
                                                </div>
                                            </div>
                                        )}
                                        {action.endDate && (
                                            <div className='d-flex flex-column align-items-start text-muted' style={{ fontSize: '0.9em' }}>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="SalesProcess.EndDate" />
                                                </div>
                                                <div className='ml-2'>
                                                    {CustomFormatDate(action.endDate, null, 'HH:mm', intl)}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        {!lastItem && actionsList[k + 1]?.createdAt && !moment(actionsList[k + 1].createdAt).isSame(action.createdAt, 'date') ?
                            <div className='text-center w-100 text-muted mt-4 mb-3'>
                                {moment(actionsList[k + 1].createdAt).format('dddd, DD-MM')}
                            </div>
                        :
                            <div className='mb-4'></div>
                        }
                    </Col>
                :
                    <Col className={`text-center pb-2 col-11 ${deal?.formId ? 'pointer' : ''}`} onClick={deal?.formId ? toggleFormModal : undefined}>
                        <div className='text-truncate'>
                            {action.title}
                        </div>
                        <div className='text-muted' style={{ fontSize: '0.9em' }}>
                            <div dangerouslySetInnerHTML={{__html: action.summary}}/>
                        </div>

                        {!lastItem && actionsList[k + 1]?.createdAt && !moment(actionsList[k + 1].createdAt).isSame(action.createdAt, 'date') ?
                            <div className='text-center w-100 text-muted mt-4 mb-3'>
                                {moment(actionsList[k + 1].createdAt).format('dddd, DD-MM')}
                            </div>
                            :
                            <div className='mb-4'></div>
                        }
                    </Col>
                }
            </Row>
        </>
    )
}

export default injectIntl(SalesDealDetailActions);