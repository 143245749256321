import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getAPI } from '../../Base/API';
import { SelectHotel, StyledCard } from "../../Base/CommonUIComponents";
import { handleNotification } from '../../Base/Notification';
import { Button, Col, Row, Card } from 'reactstrap';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { getFormSource } from '../../Base/ReferenceDataFunctions';
import CustomSelect from '../../Base/CustomSelect';
import PreviewForm from './PreviewForm';
import { Pagination, TotalResults } from '../../Base/PaginationComponents';
import { Link } from 'react-router-dom';

export class SearchForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: true,
            modal: false,
            fromDate: moment().subtract(1, "years"),
            toDate: moment(),
            pageIndex: 0,
            pageSize: 12,
            totalItems: 0,
            hotelId: null,
            source: this.props.fromPnForms ? 'PNForm' : null,
            forms: [],
            formsOptions: [],
        };
    }

    componentDidMount() {
        window.setTimeout(
            () => {
                this.setState({ hotelId: global.hotelId }, this.getFormsList );
            },
            global.hotelId ? 0 : 2500
        );
    }

    getFormsList = (e) => {
        if (e) e.preventDefault();

        this.setState({ block: true });
        const { pageSize, pageIndex, source, fromDate, toDate } = this.state;

        var params = "";
        if (source) params += `&source=${source}`;

        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ error: errorMessage, block: false }));
                }

                this.setState({ error: errorMessage, block: false, forms: data.response, totalItems: data.totalItems }, () => this.getFormsTypesAvailable());
            }
        }, `/api/gms/FormExternal/v1/form/list?pageSize=${pageSize}&pageIndex=${pageIndex}&fromDate=${moment(fromDate).format('YYYY-MM-DD')}&toDate=${moment(toDate).format('YYYY-MM-DD')}` + params);
    }

    goToFormAnswers = (source, formId, listId) => {
        if (source === "Egoi") {
            this.props.history.push({ pathname: `/FormSubmissions?list=${listId}&form=${formId}` });
            this.props.history.go();
        }
        else if (source === "ReviewPro") {
            this.props.history.push({ pathname: `/reviewProInquiry/${formId}` });
        }
        else if (source === "PNForm") {
            this.props.history.push({ pathname: `/PNFormAnswers/${formId}` });
        }
        else if (source === "TypeForm") {
            this.props.history.push({ pathname: `/TypeformAnswers/${formId}` });
        }
    }

    doSearch = () => {
        this.setState({ pageIndex: 0 }, this.getFormsList)
    }

    handleSelect = (combo, name) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    toggleModal = (selectedForm) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedForm
        }));
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getFormsList());
    }

    getFormsTypesAvailable = () => {
        const options = [];

        if(global.modules && global.modules.includes("ProfileNowForms")){
            options.push(getFormSource().find(m => m.value === "PNForm"));
        }

        if(global.modules && global.modules.includes("TypeForm")){
            options.push(getFormSource().find(m => m.value === "TypeForm"));
        }

        if(global.modules && global.modules.includes("ReviewPro")){
            options.push(getFormSource().find(m => m.value === "ReviewPro"));
        }

        if(global.modules && global.modules.includes("Egoi")){
            options.push(getFormSource().find(m => m.value === "Egoi"));
        }

        this.setState({ formsOptions: options });
    }

    render() {
        const { block, error, forms, pageIndex, pageSize, totalItems, source, formsOptions } = this.state;
        const { fromPnForms } = this.props;

        return (
            <StyledCard block={block} error={error} icon="fas fa-file-signature" title="SearchForm.SearchForm">
                {this.state.modal ?
                    <PreviewForm
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        selectedForm={this.state.selectedForm}
                    />
                : ''}

                <Row className="mt-3 mb-4 align-items-center">
                    {!fromPnForms &&
                        <Col className="col-2 pr-0">
                            <CustomSelect
                                options={formsOptions}
                                value={formsOptions?.find(m => m.value === source) || ''}
                                isSearchable isClearable
                                placeholder={<FormattedMessage id="SearchForm.Source" />}
                                onChange={(e) => this.handleSelect(e, 'source')}
                            />
                        </Col>
                    }
                    <Col>
                        <DateRangePicker
                            startDate={this.state.fromDate ? moment(this.state.fromDate) : null}
                            endDate={this.state.toDate ? moment(this.state.toDate) : null}
                            onDatesChange={({ startDate, endDate }) => this.setState({ fromDate: startDate, toDate: endDate })}
                            isOutsideRange={day => day.isAfter(moment(), 'days')}
                            endDateId="your_unique_end_date_id_announcement"
                            startDateId="your_unique_start_date_id_announcement"
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                        />
                    </Col>
                    <Col className="text-right" >
                        <Button className="mr-3 btn btn-sm btn-host" onClick={this.getFormsList}>
                            <i className="fa fa-search" />
                        </Button>
                    </Col>
                </Row>

                <Row>
                    {forms?.map((form, key) => {
                        const formSource = formsOptions?.find(fs => fs.value === form.formSource);

                        return <Col key={key} className="col-4 mb-3">
                            <Card body className="border-0 shadow h-100 d-flex justify-content-between">
                                <Row className="align-items-center pr-0">
                                    <Col>
                                        <h5 className="text-host pointer" onClick={() => this.goToFormAnswers(form.formSource, form.externalFormId, form.externalListId) }>
                                            {form.formName || `${formSource?.label} Form`}
                                        </h5>
                                    </Col>
                                    <Col className="col-1 text-right">
                                        <Button className="float-right btn btn-sm btn-host" onClick={() => this.toggleModal(form)}>
                                            <i className="icon-icon-preview" />
                                        </Button>
                                    </Col>
                                </Row>

                                <div>
                                    <div className="mb-2">
                                        <img src={formSource?.logo} height="15px" className="mr-1" /> {formSource?.label}
                                    </div>
                                    <Row>
                                        <Col style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", cursor: 'pointer' }} >
                                            <Link to={{ pathname: "/ProfileDetails/" + form.profileId }} style={{ color: 'black' }} >
                                                <b><FormattedMessage id="generic.Profile" />: </b>{form.firstName} {form.lastName}
                                            </Link>
                                        </Col>
                                        <Col className="text-right col-3">
                                            {form.responseDate ? moment(form.responseDate).format("YYYY-MM-DD") : ''}
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    })}
                </Row>

                {totalItems > 0 ?
                    <Row className="mb-4 mt-2">
                        <Col className="ml-2">
                            <TotalResults
                                end={pageIndex * pageSize + forms.length}
                                start={pageIndex * pageSize + 1}
                                total={totalItems}
                            />
                        </Col>

                        <Col className="text-right mr-3">
                            <Pagination
                                isPrevDisabled={pageIndex === 0}
                                isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                currentPage={pageIndex + 1}
                                handlePrevButton={() => this.handlePagination(-1)}
                                handleNextButton={() => this.handlePagination(1)}
                            />
                        </Col>
                    </Row>
                : ''}
            </StyledCard>
        );
    }
}
export default injectIntl(SearchForm)