import moment from 'moment-timezone';
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Breadcrumb, BreadcrumbItem, Button, Card, CardBody, Col, Nav, NavItem, NavLink, Row, UncontrolledTooltip } from 'reactstrap'
import { getAPI, postAPI } from '../../../Base/API';
import { StyledCard } from '../../../Base/CommonUIComponents';
import { handleNotification } from "../../../Base/Notification";
import ConfirmActionModal from '../../../Base/ConfirmActionModal';
import FormSettings from './FormSettings';
import FormStyles from './FormStyles';
import FormContent from './FormContent';
import SurveyPreview from './SurveyPreview';
import { getConsentsCheckboxStyle } from '../../../Admin/Config/PNForms/FormBuilderFunctions';

class FormBuilder extends Component {

    constructor(props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.setWrapperFormRef = this.setWrapperFormRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = {
            block: true,
            error: null,
            activeTab: '0',
            activeCountryTab: "default",
            hideQuestionCard: true,
            hideQuestionNumber: false,
            hideQuestionTitle: false,
            labelPosition: 'top',
            formLanguages: [{language: "default", removableLang: false, tab: "default"}],
            questionErrorLocation: 'top',
            layoutDisplay: '-webkit-left',
            buttonSubmitPosition: 'end',
            comboLanguagesOptions: [
                { label: <span> <i className='flag-icon flag-icon-gb mr-2'></i> English</span>, value: 'en-gb',  disabled: false },
                { label: <span> <i className='flag-icon flag-icon-pt mr-2'></i> Português</span>, value: 'pt', disabled: false },
                { label: <span> <i className='flag-icon flag-icon-es mr-2'></i> Español</span>, value: 'es', disabled: false },
                { label: <span> <i className='flag-icon flag-icon-fr mr-2'></i> Français</span>, value: 'fr', disabled: false },
                { label: <span> <i className='flag-icon flag-icon-de mr-2'></i> Deutsch</span>, value: 'de', disabled: false },
                { label: <span> <i className='flag-icon flag-icon-pl mr-2'></i> Polski</span>, value: 'pl', disabled: false },
                { label: <span> <i className='flag-icon flag-icon-it mr-2'></i> Italiano</span>, value: 'it', disabled: false },
            ],
            unsavedChanges: false,
            allowHardWarning: false,
            deleteModal: false,
            deleteFormModal: false,
            tags: [],
            tagGroups: [],
            navigateToUrl: null,
            survey: {
                name: null,
                questions: null,
                active: false,
                rawForm: null,
                imageUrl: null,
                title: null,
                css: null,
                anonymous: true,
                createProfile: true,
                dOptIn: false,
                tagId: null,
                createdAt: moment().format('DD-MM-YYYY'),
                updatedAt: moment().format('DD-MM-YYYY'),
                textColor: "#000000",
                backgroundColor: "transparent",
                mainColor: "#f2f2f2",
                buttonColor: "#ffffff",
                formNotCompleted: true,
                marketingUrlNotValid: false,
                autoConsent: false,
                autoConsentType: null
            },
            formQuestions: [
                {
                    type: "text",
                    name: "FirstName",
                    title: {
                        "default": "First Name",
                        "pt": "Primeiro Nome",
                        "en-gb": "First Name",
                        "es": "Nombre",
                        "fr": "Prénom",
                        "de": "Vorname"
                    },
                    isRequired: true,
                    autocomplete: "given-name",
                    placeholder: {
                        "default": "First Name",
                        "pt": "Primeiro Nome",
                        "en-gb": "First Name",
                        "es": "Nombre",
                        "fr": "Prénom",
                        "de": "Vorname"
                    },
                    selected: true,
                    requiredErrorText: "Response required",
                    titleLocation: "top",
                    startWithNewLine: true
                },
                {
                    type: "text",
                    name: "LastName",
                    title: {
                        "default": "Last Name",
                        "pt": "Último Nome",
                        "en-gb": "Last Name",
                        "es": "Apellido",
                        "fr": "Nom",
                        "de": "Nachname"
                    },
                    isRequired: true,
                    autocomplete: "family-name",
                    placeholder: {
                        "default": "Last Name",
                        "pt": "Último Nome",
                        "en-gb": "Last Name",
                        "es": "Apellido",
                        "fr": "Nom",
                        "de": "Nachname"
                    },
                    selected: true,
                    requiredErrorText: "Response required",
                    titleLocation: "top",
                    startWithNewLine: true
                },
                {
                    type: "text",
                    name: "Email",
                    title: {
                        "default": "Email",
                        "pt": "E-mail",
                        "en-gb": "E-mail",
                        "es": "Correo electrónico",
                        "fr": "E-mail",
                        "de": "E-mail"
                    },
                    isRequired: true,
                    inputType: "email",
                    autocomplete: "email",
                    placeholder: {
                        "default": "Email",
                        "pt": "E-mail",
                        "en-gb": "E-mail",
                        "es": "Correo electrónico",
                        "fr": "E-mail",
                        "de": "E-mail"
                    },
                    selected: true,
                    requiredErrorText: "Response required",
                    titleLocation: "top",
                    startWithNewLine: true
                }
            ],
            autoConsentTypes: [
                {
                    value: null,
                    label: <FormattedMessage id="PNFormDetails.No" />
                },
                {
                    value: 'Mailings',
                    label: <FormattedMessage id="PNFormDetails.Mailings" />
                },
                {
                    value: 'General',
                    label: <FormattedMessage id="PNFormDetails.General" />
                },
                {
                    value: 'All',
                    label: <FormattedMessage id="PNFormDetails.All" />
                }
            ],
            gaAccountsOptions: [],
            blockGAAccounts: false
        };
    }

    componentWillMount() {
        this._isMounted = true;
        document.addEventListener("mousedown", this.handleClickOutside);
        window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this));
        document.addEventListener("keydown", this.onKeyPressed.bind(this));
    }

    componentDidMount() {
        const { formQuestions, survey, layoutDisplay } = this.state;
        this.getGoogleAnalyticsAccounts();

        if(this.props.match.params.id){
            this.getFormDetails(this.props.match.params.id);
        }
        else{
            const rawForm = {
                pages: [{
                    name: "page1",
                    elements: formQuestions
                }]
            };

            survey.rawForm = JSON.stringify(rawForm);
            var parsed = JSON.parse(survey.rawForm);

            parsed.completeText = "Submit"

            survey.backgroundOpacity = 1;

            survey.css = `:root {
                --Font: ${survey.textColor}; /* var(--Font) */
                --Background: ${survey.backgroundColor}; /* var(--Background) */
                --MainColor: ${survey.mainColor}; /* var(--MainColor) */
                --Buttons: ${survey.buttonColor}; /* var(--Buttons) */
            }\n#formComponent .sd-row__question--small{
                box-shadow: unset;
                background: unset;
                background-color: unset;
            }\n#formComponent .sd-element--with-frame{
                padding: unset;
                box-shadow: unset;
                background-color: transparent;
            }\n#formComponent .sd-question__header{
                padding: unset;
            }\n#formComponent .sd-element__content{
                padding-top: 5px;
                text-align: ${layoutDisplay};
            }\n.descriptionURL{
                visibility:hidden
            }\n#formComponent .sd-error{
                width: 100%;
                margin: unset;
            }\n#formComponent .sd-text__content{
                margin-bottom: unset;
            }\n#formComponent .sd-input, .sd-text{
                box-shadow: unset;
                background-color: transparent;
                padding-top: 5px;
            }\n#formComponent .sd-input, .sd-text{
                text-align: ${layoutDisplay};
            }\n#formComponent .sd-text__content, .sd-question__content{
                border-bottom: #dddddd 1px solid;
                border-top: 0;
                border-right: 0;
                border-left: 0;
                border-radius: 0;
            }\n#formComponent .sd-body.sd-body--static .sd-body__navigation.sd-action-bar{
                justify-content: end;
            }\n.sd-question__header.sd-element__header > div {
                    font-style: normal;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.45);
                    font-size: 16px;
                    line-height: calc(1.5*(16px));
                    white-space: normal;
            }\n#formComponent .sv-components-column--expandable {
                position: relative;
                width: 100%;
                background: var(--Background);
                overflow: hidden;
            }\n.surveyHeader > .col:first-of-type {
                display: flex;
                align-items: center;
                flex-direction: unset;
                justify-content: unset;
            }\n.surveyHeader .col img {
                margin-right: 1.5rem !important;
            }\n.surveyHeader .col .hotelName {
                margin-top: 0px;
            }\n#formComponent .sv-components-column--expandable::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: none;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                opacity: 1;
                z-index: 1;
            }\n#formComponent .sv-components-column > * {
                position: relative;
                z-index: 2;
                opacity: 1;
                justify-content: end;
            }\n#formComponent .sd-title {
                text-align: ${layoutDisplay};
            }\n#formComponent .sd-checkbox {
                text-align: ${layoutDisplay} !important;
            }\n .sd-selectbase {
                text-align: ${layoutDisplay} !important;
            }\n .sv-title-actions {
                display: unset;
            }
            ${getConsentsCheckboxStyle()}`;

            if (!survey.logoPosition) {
                const cssBlockRegex = /\.surveyHeader\s*>\s*\.col:first-of-type\s*{([^}]*)}/; //.surveyHeader > .col:first-of-type
                const match = survey.css.match(cssBlockRegex);

                if (match) {
                    const blockContent = match[1];
                    survey.logoPosition = blockContent.includes('justify-content: unset;') ? '-webkit-left' :
                        blockContent.includes('justify-content: center;') ? '-webkit-center' :
                            blockContent.includes('justify-content: space-between;') ? '-webkit-right' :
                                null;
                }
            }

            survey.rawForm = JSON.stringify({
                ...parsed
            });

            survey.labelPosition = this.state.labelPosition;
            survey.hideQuestionNumber = this.state.hideQuestionNumber;

            this.setState({ survey, block: false, unsavedChanges: true, allowHardWarning: true }, this.getTagGroups);
        }
    }

    getFormDetails = (id) => {
        const { formLanguages, comboLanguagesOptions, layoutDisplay, autoConsentTypes } = this.state;
        var { hideQuestionCard, labelPosition, hideQuestionNumber, hideQuestionTitle } = this.state;
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                let questions = null;
                const survey = data?.response && data?.response[0];
                let navigateToUrl = null;

                if (data.response && data.response.length > 0) {
                    const p = this.getQuestions(data.response[0].rawForm);
                    questions = p.questions;

                    if(questions){
                        questions.forEach(q => {
                            q.selected = true;
                        });
                    }
                    if(typeof p.title === 'string'){
                        survey.surveyTitle = {default: p.title};
                    }else{
                        survey.surveyTitle = p.title;
                    }

                    if(typeof p.description === 'string'){
                        survey.description = {default: p.description};
                    }else{
                        survey.description = p.description;
                    }

                    if(typeof p.completeText === 'string'){
                        survey.completeText = {default: p.completeText};
                    }else{
                        survey.completeText = p.completeText;
                    }

                    if(typeof p.completedHtml === 'string'){
                        survey.completedHtml = {default: p.completedHtml};
                    }else{
                        survey.completedHtml = p.completedHtml;
                    }
                    if(!survey.backgroundOpacity && survey.backgroundOpacity !== 0){
                        survey.backgroundOpacity = 1;
                    }

                    if (survey.css != null) {
                        if(survey.css.includes(":root")){
                            if(survey.css.includes("--Font: ")){
                                const textLine = survey.css.substring(survey.css.indexOf("--Font: ") + 8);
                                survey.textColor = textLine.substring(0, textLine.indexOf(';'));
                            }

                            if(survey.css.includes("--Background: ")){
                                const backgroundLine = survey.css.substring(survey.css.indexOf("--Background: ") + 14);
                                survey.backgroundColor = backgroundLine.substring(0, backgroundLine.indexOf(';'));
                            }

                            if(survey.css.includes("--MainColor: ")){
                                const mainLine = survey.css.substring(survey.css.indexOf("--MainColor: ") + 13);
                                survey.mainColor = mainLine.substring(0, mainLine.indexOf(';'));
                            }

                            if(survey.css.includes("--Buttons: ")){
                                const buttonLine = survey.css.substring(survey.css.indexOf("--Buttons: ") + 11);
                                survey.buttonColor = buttonLine.substring(0, buttonLine.indexOf(';'));
                            }
                        }

                        if(!survey.css.includes(`#formComponent .sd-text__content, .sd-question__content`)){
                            survey.css += `\n#formComponent .sd-input, .sd-text{
                                box-shadow: unset;
                                background-color: transparent;
                            }\n\n#formComponent .sd-input, .sd-text{
                text-align: ${layoutDisplay};
            }#formComponent .sd-text__content, .sd-question__content{
                                border-bottom: #dddddd 1px solid;
                                border-top: 0;
                                border-right: 0;
                                border-left: 0;
                                border-radius: 0;
                            }\n#formComponent .sd-body.sd-body--static .sd-body__navigation.sd-action-bar{
                                justify-content: end;
                            }`;
                        }

                        if (!survey.css.includes(`.sd-question__header.sd-element__header > div `)) {
                            survey.css += `\n.sd-question__header.sd-element__header > div {
                    font-style: normal;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.45);
                    font-size: 16px;
                    line-height: calc(1.5*(16px));
                    white-space: normal;
                }`
                        }

                        if (!survey.css.includes(`.descriptionURL`)) {
                            survey.css += `\n.descriptionURL{
                visibility:hidden
            }`;
                        }

                        if (!survey.css.includes('#formComponent .sd-question__header')) {
                            survey.css += `\n#formComponent .sd-question__header{
                            padding: unset;
                        }`;
                        }

                        if (!survey.css.includes('#formComponent .sd-element__content')) {
                            survey.css += `\n#formComponent .sd-element__content{
                padding-top: 5px;
                text-align: ${layoutDisplay};
            }}`;
                        }

                        if (survey.css.includes(`#formComponent .sd-input, .sd-text{`)) {
                            survey.css.replace(`#formComponent .sd-input, .sd-text{
                            box-shadow: unset;
                            background-color: transparent;
                        }`, `#formComponent .sd-input, .sd-text{
                            box-shadow: unset;
                            background-color: transparent;
                            padding-top: 5px;
                        }`)
                        } else {
                            survey.css += `\n#formComponent .sd-input, .sd-text{
                            box-shadow: unset;
                            background-color: transparent;
                            padding-top: 5px;
                        }`
                            survey.css += `\n#formComponent .sd-input, .sd-text{
                text-align: ${layoutDisplay};
            }`;
                        }

                        if(survey.css.includes(`#formComponent .sd-row__question--small{
                    box-shadow: unset;
                    background: unset;
                    background-color: unset;
                }`)){
                            hideQuestionCard = true;
                        }else{
                            hideQuestionCard = false;
                        }


                        if (!survey.css.includes('#formComponent .sd-checkbox')) {
                            survey.css += `\n#formComponent .sd-checkbox {
                text-align: ${layoutDisplay} !important;
            }`;
                        }

                        if (!survey.css.includes('#formComponent .sd-checkbox:has(input[type="checkbox"]')) {
                            survey.css += getConsentsCheckboxStyle();
                        }

                        const hideQuestionTitles = `.sd-question__header{
                padding: unset;
                display: none;
            }`;
                        if (survey.css.includes(hideQuestionTitles)) {
                            hideQuestionTitle = true;
                        }

                        const surveyHeaderBlockRegex = /\.surveyHeader\s*>\s*\.col:first-of-type\s*{([^}]*)}/; //.surveyHeader > .col:first-of-type
                        const match = survey.css.match(surveyHeaderBlockRegex);

                        if (match) {
                            const blockContent = match[1];
                            if (!survey.logoPosition) {
                                survey.logoPosition = blockContent.includes('justify-content: unset;') ? '-webkit-left' :
                                    blockContent.includes('justify-content: center;') ? '-webkit-center' :
                                        blockContent.includes('justify-content: space-between;') ? '-webkit-right' :
                                            null;
                            }
                        } else {
                            survey.css += `\n.surveyHeader > .col:first-of-type {
                                display: flex;
                                align-items: center;
                                flex-direction: unset;
                                justify-content: unset;
                            }`;
                        }

                        if(!survey.css.includes(`.surveyHeader .col img {`)){
                            survey.css += `\n.surveyHeader .col img {
                                margin-right: 1.5rem !important;
                            }`;
                        }

                        if(!survey.css.includes(`.surveyHeader .col .hotelName {`)){
                            survey.css += `\n.surveyHeader .col .hotelName {
                                margin-top: 0px;
                            }`;
                        }

                        const expandableBlockRegex = /#formComponent\s+\.sv-components-column--expandable\s*{([^}]*)}/; //#formComponent .sv-components-column--expandable
                        const beforeBlockRegex = /#formComponent\s+\.sv-components-column--expandable::before\s*{([^}]*)}/; // #formComponent .sv-components-column--expandable::before

                        const expandableBlockMatch = survey.css.match(expandableBlockRegex);
                        const beforeBlockMatch = survey.css.match(beforeBlockRegex);

                        if (!expandableBlockMatch || !beforeBlockMatch) {
                            survey.css += `\n#formComponent .sv-components-column--expandable {
                                position: relative;
                                width: 100%;
                                background: var(--Background);
                                overflow: hidden;
                            }\n#formComponent .sv-components-column--expandable::before {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background-image: none;
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: center;
                                opacity: 1;
                                z-index: 1;
                            }`;
                        } else {
                            const beforeBlockContent = beforeBlockMatch[1];

                            const backgroundImageMatch = beforeBlockContent.match(/background-image:\s*url\(([^)]+)\)/); //background-image: url(...)
                            survey.backgroundImage = backgroundImageMatch ? backgroundImageMatch[1] : null;

                            const opacityMatch = beforeBlockContent.match(/opacity:\s*([0-9.]+);/); //opacity: ...;
                            survey.backgroundOpacity = opacityMatch ? parseFloat(opacityMatch[1]) : 1;
                        }
                        
                        if(!survey.css.includes(`#formComponent .sv-components-column > * {`)){
                            survey.css += `\n#formComponent .sv-components-column > * {
                                position: relative;
                                z-index: 2;
                                opacity: 1;
                            }`;
                        }

                        if (!survey.css.includes(`#formComponent .sd-title`)) {
                            survey.css += `\n#formComponent .sd-title {
                text-align: ${layoutDisplay};
            }`;
                        }

                        if (survey.css.includes(`#formComponent .sd-body__navigation .sd-btn`)) {
                            const regex = /#formComponent .sd-body__navigation .sd-btn {\s+color:\s(.+) /g;
                            const matches = survey.css.match(regex);
                            if (matches && matches.length > 0) {
                                const groups = matches[0].split(' ');
                                if (groups && groups.length > 2) {
                                    survey.submitTextColor = groups[groups.length - 2];
                                }
                            }
                        }
                    }

                    const surveyParsed = JSON.parse(survey.rawForm);
                    if (surveyParsed !== '') {
                        surveyParsed.completeText = survey.completeText ?? {};
                    }

                    var languages = [];

                    if (surveyParsed.title) {
                        if(typeof surveyParsed.title === 'string'){
                            surveyParsed.title = {default: surveyParsed.title};
                        }
                        languages = languages.concat(Object.keys(surveyParsed.title));
                    }
                    if (surveyParsed.description) {
                        if(typeof surveyParsed.description === 'string'){
                            surveyParsed.description = {default: surveyParsed.description};
                        }
                        languages = languages.concat(Object.keys(surveyParsed.description));
                    }
                    if (surveyParsed.completeText) {
                        if(typeof surveyParsed.completeText === 'string'){
                            surveyParsed.completeText = {default: surveyParsed.completeText};
                        }
                        languages = languages.concat(Object.keys(surveyParsed.completeText));
                    }
                    if (surveyParsed.completedHtml) {
                        if(typeof surveyParsed.completedHtml === 'string'){
                            surveyParsed.completedHtml = {default: surveyParsed.completedHtml};
                        }
                        languages = languages.concat(Object.keys(surveyParsed.completedHtml));
                    }
                    if (surveyParsed.pages && surveyParsed.pages.length > 0) {
                        surveyParsed.pages.forEach(p => {
                            if (p.elements) {
                                p.elements.forEach(el => {
                                    if(el.title) {
                                        if(typeof el.title === 'string'){
                                            el.title = {default: el.title};
                                        }
                                        languages = languages.concat(Object.keys(el.title));
                                    }
                                    if(el.placeholder) {
                                        if(typeof el.placeholder === 'string'){
                                            el.placeholder = {default: el.placeholder};
                                        }
                                        languages = languages.concat(Object.keys(el.placeholder));
                                    }
                                    if(el.requiredErrorText){
                                        if(typeof el.requiredErrorText === 'string'){
                                            el.requiredErrorText = {default: el.requiredErrorText};
                                        }
                                        languages = languages.concat(Object.keys(el.requiredErrorText));
                                    }

                                    if (el.titleLocation) {
                                        if (survey.css.includes(`.sd-title.sd-element__title.sd-question__title{
                display:none
            }`)) {
                                            labelPosition = 'hidden';
                                        } else {
                                            labelPosition = el.titleLocation;
                                        }
                                    }else{
                                        labelPosition = 'top';
                                    }

                                    if(el.hideNumber){
                                        hideQuestionNumber = el.hideNumber;
                                    }
                                });
                            }
                        });
                    }
                    if (surveyParsed.navigateToUrl) {
                        navigateToUrl = surveyParsed.navigateToUrl;
                    }
                    languages = [...new Set(languages)];
                    languages.forEach((lang) => { 
                        lang !== 'default' && formLanguages.push({language: lang, removableLang: true, tab: lang}) 
                    });
                    comboLanguagesOptions.map(l => l.disabled = formLanguages.find(curr => curr.language === l.value) ? true : false);

                    survey.labelPosition = labelPosition;
                    survey.hideQuestionNumber = hideQuestionNumber;

                    survey.rawForm = JSON.stringify(surveyParsed);

                    if (!survey.autoConsentType) {
                        if (survey.autoConsent) {
                            survey.autoConsentType = autoConsentTypes[1].value;
                        }
                        else {
                            survey.autoConsentType = null;
                        }
                    }
                }

                this.setState({ survey, formQuestions: questions, block: false, formLanguages, comboLanguagesOptions, hideQuestionCard, hideQuestionNumber, labelPosition, hideQuestionTitle, navigateToUrl }, this.getTagGroups);
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Survey/v1/survey/${id}`);
    }

    getQuestions = (data) => {
        let questions = [], title, description, completeText, completedHtml;
        try {
            const rawForm = JSON.parse(data);

            if(typeof rawForm.title === 'string'){
                title = {default: rawForm.title};
            }else{
                title = rawForm.title;
            }

            if(typeof rawForm.description === 'string'){
                description = {default: rawForm.description};
            }else{
                description = rawForm.description;
            }

            if(typeof rawForm.completeText === 'string'){
                completeText = {default: rawForm.completeText};
            }else{
                completeText = rawForm.completeText;
            }

            if(typeof rawForm.completedHtml === 'string'){
                completedHtml = {default: rawForm.completedHtml};
            }else{
                completedHtml = rawForm.completedHtml;
            }

            const allElements = rawForm.pages?.flatMap(el => el.elements) ?? [];

            allElements && allElements.forEach(el => {
                if (el.type !== 'image' && ((el.type === 'html' && el.name === 'HtmlText') || el.type !== 'html') && el.type !== 'expression') {
                    if (el.elements) {
                        questions = questions.concat(el.elements.filter(f => f.type !== 'image' && ((el.type === 'html' && el.name === 'HtmlText') || el.type !== 'html') && f.type !== 'expression'));
                    }
                    else {
                        questions.push({ ...el, tagMappings: [] });
                    }
                }
            })

        } catch (e) {
            console.error(e)
        }
        const result = {
            questions,
            title,
            description,
            completeText,
            completedHtml
        }
        return result;
    }

    getTagGroups = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const tagGroups = data.response && data.response.map(tg => {
                    const tagGroup = { value: tg.id, label: tg.code };
                    return tagGroup;
                });

                this.setState({ tagGroups }, this.getTags);
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/tag/group`);
    }

    getTags = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const tags = data.response.map((obj) => ({ 
                    tagGroupId: obj.id,
                    label: obj.code,
                    options: obj.tag.map((t) => {
                        return { label: t.code, value: t.id }})
                }));
                this.setState({ tags, block: false }, () => this.checkRequiredFormFields());
            }
        }, `/api/gms/Profile/v1/tag/groupDetails?tagType=all`);
    }

    saveForm = (e) => {
        if(e){ e.preventDefault() };

        if(!this.wrapperFormRef || this.state.formNotCompleted || this.state.marketingUrlNotValid || this.state.formLanguages.find(({language}) => !language)){
            return;
        }

        if (!this.wrapperFormRef.checkValidity()) {
            this.wrapperFormRef.reportValidity();
            return;
        }
        
        const { survey } = this.state;

        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else {
                        handleNotification(data, <FormattedMessage id="PNFormDetails.SurveySaved" />, <FormattedMessage id="generic.success" />);

                        if (data.response && data.response.length > 0) {
                            window.history.replaceState(null, '', '/FormBuilder/' + data.response[0].id);
                            this.setState({ unsavedChanges: false, survey: { ...survey, id: data.response[0].id }, allowHardWarning: false, block: false });
                            return;
                        }
                    }
                }

                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/Survey/v1/survey`, JSON.stringify({ request: survey }));
        });
    }

    deleteForm = () => {
        const { survey } = this.state;
        const id = survey.id;
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="ConfigPNForms.SuccessDelete" />, <FormattedMessage id="generic.success" />);
            }
            this.setState({ error: errorMessage, block: false }, () => {
                this.props.history.push({ pathname: `/ProfileNowConfig` });
            });
        }, `/api/gms/Survey/v1/survey/${id}/delete`);
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    handleInputChange = (evt) => {
        this.setState({
            survey: {
                ...this.state.survey,
                [evt.target.name]: evt.target.value
            },
            unsavedChanges: true,
            allowHardWarning: true
        }, () => this.checkRequiredFormFields());
    }

    handleInputChangeOnSurveyAndRawForm = (evt) => {
        if (evt && evt.target) {
            const { survey } = this.state;
            const { name, value } = evt.target;

            const parsed = JSON.parse(survey.rawForm);
            survey.rawForm = JSON.stringify({
                ...parsed,
                [name]: value
            });

            this.setState({ survey, [name]: value, unsavedChanges: true, allowHardWarning: true });
        }
    }

    handleStylesChange = (evt) => {
        const { survey } = this.state;

        survey[evt.target.name] = evt.target.value;

        if(!survey.css)
            survey.css = "";

        if(survey.css.includes(":root")){
            survey.css = survey.css.substring(survey.css.indexOf("}") + 2);
        }

        survey.css = `:root {
            --Font: ${survey.textColor}; /* var(--Font) */
            --Background: ${survey.backgroundColor}; /* var(--Background) */
            --MainColor: ${survey.mainColor}; /* var(--MainColor) */
            --Buttons: ${survey.buttonColor}; /* var(--Buttons) */
        }\n` + survey.css;
        
        this.setState({
            survey,
            unsavedChanges: true,
            allowHardWarning: true
        });
    }

    handleSubmitTextColor = (evt) => {
        const { survey } = this.state;

        if (evt && evt.target) {

            const { name, value } = evt.target;
            const submitTextColor = survey[name]; 
            survey[name] = value;

            if (!survey.css) {
                survey.css = "";
            }

            if (!survey.css.includes('#formComponent .sd-body__navigation .sd-btn')) {
                survey.css += `\n#formComponent .sd-body__navigation .sd-btn {
                color: ${value} !important;
            }`;
            }
            else {
                survey.css = survey.css.replace(`.sd-body__navigation .sd-btn {
                color: ${submitTextColor} !important;
            }`, `.sd-body__navigation .sd-btn {
                color: ${value} !important;
            }`);
            }

            this.setState({
                survey,
                unsavedChanges: true,
                allowHardWarning: true
            });
        }
    }

    handleChangeCombo = (name, el) => {
        const value = el ? el.value : null;
    
        this.setState({
            survey: {
                ...this.state.survey,
                [name]: value,
                ...(name === 'hasAnalytics' && !value && { gaConnectionId: null })
            },
            unsavedChanges: true,
            allowHardWarning: true
        }, () => this.checkRequiredFormFields());
    };

    handleChangeAutoConsentType = (name, el) => {
        const value = el ? el.value : null;
        this.setState({
            survey: {
                ...this.state.survey, 
                [name]: value,
                autoConsent: value !== null
            },
            unsavedChanges: true,
            allowHardWarning: true
        }, () => this.checkRequiredFormFields());
    }

    handleQuestionChange = (update, k) => {
        const { formQuestions } = this.state;
        const question = formQuestions.findIndex(({name}) => name === update.name);

        if(question !== -1){
            formQuestions.splice(question, 1);
            formQuestions.splice(question, 0, update);
        }
        else{
            formQuestions.splice(k, 0, update);
        }

        this.setState({ formQuestions: formQuestions.filter(({selected}) => selected) }, () => this.updateRawEl());
    }

    updateRawEl = (tab) => {
        const { formQuestions, survey, activeCountryTab } = this.state;
        const parsed = JSON.parse(survey.rawForm);

        const page1 = parsed && parsed.pages ? parsed.pages[0] : {};

            formQuestions.map((obj) => {

                if(survey.labelPosition){
                    if(survey.hideQuestionNumber){
                        obj.hideNumber = survey.hideQuestionNumber;
                    }
                    obj.titleLocation = survey.labelPosition
                }

                if (tab) {
                    const isConsentField = obj.name === 'Mailings' || obj.name === 'General';
                    const hasTypeOptions = isConsentField;
                    
                    if (hasTypeOptions) {
                        delete obj.typeOptions;
                    }

                    if(typeof obj.placeholder === 'string'){
                        obj.placeholder = {default: obj.placeholder}
                    }

                    if( typeof obj.title === 'string'){
                        obj.title = {default: obj.title}
                    }

                    if(typeof obj.requiredErrorText === 'string'){
                        obj.requiredErrorText = {default: obj.requiredErrorText}
                    }

                    if (isConsentField && typeof obj.marketingConsentUrl === 'string') {
                        obj.marketingConsentUrl = {default: obj.marketingConsentUrl}
                    }

                    if(obj.placeholder && obj.placeholder[tab] ){
                        obj.placeholder[activeCountryTab] = obj.placeholder[tab];
                        delete obj.placeholder[tab];
                    }

                    if(obj.title && obj.title[tab] ){
                        obj.title[activeCountryTab] = obj.title[tab];
                        delete obj.title[tab];
                    }

                    if(obj.requiredErrorText && obj.requiredErrorText[tab] ){
                        obj.requiredErrorText[activeCountryTab] = obj.requiredErrorText[tab];
                        delete obj.requiredErrorText[tab];
                    }

                    if (isConsentField && obj.marketingConsentUrl && obj.marketingConsentUrl[tab] ){
                        obj.marketingConsentUrl[activeCountryTab] = obj.marketingConsentUrl[tab];
                        delete obj.marketingConsentUrl[tab];
                    }
                }
            })
        
        
        survey.rawForm = JSON.stringify({
            ...parsed,
            pages: [{
                ...page1,
                elements: formQuestions
            }]
        });

        this.setState({ survey, unsavedChanges: true, allowHardWarning: true, formQuestions }, () => this.changeQuestionTitleVisibility(survey.labelPosition));
    }

    updateRawTitleDescComplete = (name, value) => {
        const { survey } = this.state;
        if(name === "header"){
            survey.title = value;
        }
        else{
            if(name === "title"){
                survey.surveyTitle = value;
            }
            if(name === "description"){
                survey.description = value;
            }
            if(name === "completeText"){
                survey.completeText = value;
            }
            if(name === "completedHtml"){
                survey.completedHtml = value;
            }
            
            const parsed = JSON.parse(survey.rawForm);
            survey.rawForm = JSON.stringify({
                ...parsed,
                [name]: value
            });
        }
        this.setState({ survey, unsavedChanges: true, allowHardWarning: true });
    }

    updateRawTitleDescCompleteByLang = (prevTab) => {
        const { survey, activeCountryTab } = this.state;

        if(prevTab){
            if(!survey.surveyTitle || typeof survey.surveyTitle === 'string'){
                survey.surveyTitle = {}
            }
            if(!survey.description || typeof survey.description === 'string'){
                survey.description = {}
            }
            if(!survey.completeText || typeof survey.completeText === 'string'){
                survey.completeText = {}
            }
            if(!survey.completedHtml || typeof survey.completedHtml === 'string'){
                survey.completedHtml = {}
            }

            if(survey.surveyTitle && survey.surveyTitle[prevTab] ){
                survey.surveyTitle[activeCountryTab] = survey.surveyTitle[prevTab];
                delete survey.surveyTitle[prevTab];
                
                const parsed = JSON.parse(survey.rawForm);
                survey.rawForm = JSON.stringify({
                    ...parsed,
                    'title': survey.surveyTitle
                });
            }
            if(survey.description && survey.description[prevTab] ){
                survey.description[activeCountryTab] = survey.description[prevTab];
                delete survey.description[prevTab];

                const parsed = JSON.parse(survey.rawForm);
                survey.rawForm = JSON.stringify({
                    ...parsed,
                    'description': survey.description
                });
            }
            if(survey.completeText && survey.completeText[prevTab] ){
                survey.completeText[activeCountryTab] = survey.completeText[prevTab];
                delete survey.completeText[prevTab];
                
                const parsed = JSON.parse(survey.rawForm);
                survey.rawForm = JSON.stringify({
                    ...parsed,
                    'completeText': survey.completeText
                });
            }
            if(survey.completedHtml && survey.completedHtml[prevTab] ){
                survey.completedHtml[activeCountryTab] = survey.completedHtml[prevTab];
                delete survey.completedHtml[prevTab];

                const parsed = JSON.parse(survey.rawForm);
                survey.rawForm = JSON.stringify({
                    ...parsed,
                    'completedHtml': survey.completedHtml
                });
            }
        }
        this.setState({ survey, unsavedChanges: true, allowHardWarning: true });
    }

    handleBeforeUnload(e){
        const { unsavedChanges } = this.state;
        if(unsavedChanges) {
            e.returnValue = 'Unsaved changes';
            return "Unsaved changes";
        };
    }

    handleClickOutside = (event) => {
        const { allowHardWarning, unsavedChanges } = this.state;
        const { intl } = this.props;

        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && unsavedChanges && allowHardWarning) {
            const top = event.target.getBoundingClientRect().top;
            if(
                event.target.className &&
                top < 80 &&
                (!event.target.className.includes('color') &&
                !event.target.className.includes('esdev') &&
                !event.target.className.includes('strip') &&
                !event.target.className.includes('modal')                    
                )
            ){
                if(!window.confirm(intl.formatMessage({id: 'stripo.confirmUnsaved'}))){
                    event.preventDefault();
                }
                else{
                    this.setState({ allowHardWarning: false });
                };
            }
        }
    }
    
    setWrapperRef(node) {
      this.wrapperRef = node;
    }

    setWrapperFormRef(node) {
        this.wrapperFormRef = node;
      }

    onKeyPressed(e) {
        if(e && e.code === 'KeyS' && e.ctrlKey){
            this.saveForm(e);
        }
    }

    toggleActiveCountryTab = (tab) => {
        if (tab && this.state.activeCountryTab !== tab) {
            this.setState({
                activeCountryTab: tab,
                activeTab: tab === 'default' ? this.state.activeTab : '1'
            });
        }
    }

    addCountryTab = () => {
        const { formLanguages } = this.state;
        const currentTab = formLanguages.length.toString();
        formLanguages.push({language: null, removableLang: true, tab: currentTab});
        this.setState({ formLanguages, activeTab: '1', activeCountryTab: currentTab });
    }

    changeSelectLanguages = (combo) => {
        const { formLanguages, activeCountryTab, comboLanguagesOptions, survey } = this.state;
        const value = combo ? combo.value : null;
        const prevTabId = activeCountryTab;

        if (!survey.description || typeof survey.description === 'string') {
            survey.description = {}
        }

        if (!survey.description[activeCountryTab]) {
            survey.description[activeCountryTab] = ' ';
        }

        const index = formLanguages.findIndex(({tab}) => tab === activeCountryTab);
        if(index !== -1){
            formLanguages[index].language = value;
            formLanguages[index].removableLang = true;
            formLanguages[index].tab = value;
        }

        comboLanguagesOptions.map(l => l.disabled = formLanguages.find(curr => curr.language === l.value) ? true : false);

        this.setState({ formLanguages, comboLanguagesOptions, unsavedChanges: true, activeCountryTab: value }, () => {
            this.updateRawEl(prevTabId);
            this.updateRawTitleDescCompleteByLang(prevTabId);
        });
    }

    toggleDeleteModal = () => {
        this.setState(prev => ({ deleteModal: !prev.deleteModal }));
    }

    toggleDeleteFormModal = () => {
        this.setState(prev => ({ deleteFormModal: !prev.deleteFormModal }));
    }

    removeLanguageTab = () => {
        const { formLanguages, formQuestions, survey, activeCountryTab, comboLanguagesOptions } = this.state;

        const index = formLanguages.findIndex(({tab}) => tab === activeCountryTab);
        if(index !== -1){
            formLanguages.splice(index, 1);
        }

        const parsed = JSON.parse(survey.rawForm);
        const page1 = parsed.pages[0];

        if(activeCountryTab){
            
            formQuestions.map((obj) => {

                if(!obj.placeholder || typeof obj.placeholder === 'string'){
                    obj.placeholder = {}
                }

                if(!obj.title || typeof obj.title === 'string'){
                    obj.title = {}
                }

                if(!obj.requiredErrorText || typeof obj.requiredErrorText === 'string'){
                    obj.requiredErrorText = {}
                }

                if(obj.placeholder && obj.placeholder[activeCountryTab] ){
                    delete obj.placeholder[activeCountryTab];
                }

                if(obj.title && obj.title[activeCountryTab] ){
                    delete obj.title[activeCountryTab];
                }

                if(obj.requiredErrorText && obj.requiredErrorText[activeCountryTab] ){
                    delete obj.requiredErrorText[activeCountryTab];
                }
            })

            survey.rawForm = JSON.stringify({
                ...parsed,
                pages: [{
                    ...page1,
                    elements: formQuestions
                }]
            });

            if(!survey.surveyTitle || typeof survey.surveyTitle === 'string'){
                survey.surveyTitle = {}
            }
            if(!survey.description || typeof survey.description === 'string'){
                survey.description = {}
            }
            if(!survey.completeText || typeof survey.completeText === 'string'){
                survey.completeText = {}
            }
            if(!survey.completedHtml || typeof survey.completedHtml === 'string'){
                survey.completedHtml = {}
            }

            if(survey.surveyTitle && survey.surveyTitle[activeCountryTab] ){
                delete survey.surveyTitle[activeCountryTab];
                
                const parsedTemp = JSON.parse(survey.rawForm);
                delete parsedTemp.title[activeCountryTab];
                survey.rawForm = JSON.stringify({
                    ...parsedTemp,
                    'title': survey.surveyTitle
                });
            }
            if(survey.description && survey.description[activeCountryTab] ){
                delete survey.description[activeCountryTab];

                const parsedTemp = JSON.parse(survey.rawForm);
                survey.rawForm = JSON.stringify({
                    ...parsedTemp,
                    'description': survey.description
                });
            }
            if(survey.completeText && survey.completeText[activeCountryTab] ){
                delete survey.completeText[activeCountryTab];
                
                const parsedTemp = JSON.parse(survey.rawForm);
                delete parsedTemp.completeText[activeCountryTab];
                survey.rawForm = JSON.stringify({
                    ...parsedTemp,
                    'completeText': survey.completeText
                });
            }
            if(survey.completedHtml && survey.completedHtml[activeCountryTab] ){
                delete survey.completedHtml[activeCountryTab];

                const parsedTemp = JSON.parse(survey.rawForm);
                survey.rawForm = JSON.stringify({
                    ...parsedTemp,
                    'completedHtml': survey.completedHtml
                });
            }

            comboLanguagesOptions.map(l => l.disabled = formLanguages.find(curr => curr.language === l.value) ? true : false);
        }
        
        this.setState({ formLanguages, formQuestions, survey, activeCountryTab: 'default', comboLanguagesOptions, deleteModal: false, unsavedChanges: true });
    }

    changeStylesSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null,
        }, () => name === 'hideQuestionCard' ? this.changeQuestionCardVisibility(combo.value) : name === 'labelPosition' ? this.changeQuestionTitleVisibility(combo.value) : this.handleChangeSelectStyles(combo.value, name));
    };

    handleLayoutDisplay = (name, combo) => {
        const { survey, layoutDisplay } = this.state;
        const value = combo.value;

        survey.css = survey.css.replace(`.sd-title {
                text-align: ${layoutDisplay};
            }`, `.sd-title {
                text-align: ${value};
            }`);

        survey.css = survey.css.replace(`.sd-element__content{
                padding-top: 5px;
                text-align: ${layoutDisplay};
            }`, `.sd-element__content{
                padding-top: 5px;
                text-align: ${value};
            }`);

        survey.css = survey.css.replace(`.sd-checkbox {
                text-align: ${layoutDisplay} !important;
            }`, `.sd-checkbox {
                text-align: ${value} !important;
            }`);
        survey.css = survey.css.replace(`.sd-input, .sd-text{
                text-align: ${layoutDisplay};
            }`, `.sd-input, .sd-text{
                text-align: ${value};
            }`);

        survey.css = survey.css.replace(`.sd-selectbase {
                text-align: ${layoutDisplay} !important;
            }`, `.sd-selectbase {
                text-align: ${value} !important;
            }`);

        this.setState({ survey, [name]: value, unsavedChanges: true, allowHardWarning: true });
    }

    handleButtonSubmitPosition = (name, combo) => {
        const { survey, buttonSubmitPosition } = this.state;
        const value = combo.value;

        survey.css = survey.css.replace(`#formComponent .sd-body.sd-body--static .sd-body__navigation.sd-action-bar{
                justify-content: ${buttonSubmitPosition};
            }`, `#formComponent .sd-body.sd-body--static .sd-body__navigation.sd-action-bar{
                justify-content: ${value};
            }`);

        survey.css = survey.css.replace(`#formComponent .sv-components-column > * {
                position: relative;
                z-index: 2;
                opacity: 1;
                justify-content: ${buttonSubmitPosition};
            }`, `#formComponent .sv-components-column > * {
                position: relative;
                z-index: 2;
                opacity: 1;
                justify-content: ${value};
            }`);

        this.setState({ survey, [name]: value, unsavedChanges: true, allowHardWarning: true });
    }

    handleChangeSelectStyles = (value, name) => {
        var { survey } = this.state;
        const parsed = JSON.parse(survey.rawForm);
        
        const page1 = parsed.pages[0];
        
        if(typeof value === 'string' ){
            if(name === 'labelPosition'){
                survey.labelPosition = value;
                page1.elements.map((obj) => {
                    obj.titleLocation = value === 'hidden' ? 'top' : value;
                });
            }else if(name === 'questionErrorLocation'){
                page1.elements.map((obj) => {
                    obj.questionErrorLocation = value;
                });
            }
        }
        else if (name === 'hideQuestionTitle') {
            if (value === true) {
                survey.css = survey.css.replace(`.sd-question__header{
                padding: unset;
            }`, `.sd-question__header{
                padding: unset;
                display: none;
            }`);
            }
            else {
                survey.css = survey.css.replace(`.sd-question__header{
                padding: unset;
                display: none;
            }`, `.sd-question__header{
                padding: unset;
            }`);
            }
        }
        else {
            survey.hideQuestionNumber = value;
            page1.elements.map((obj) => {
                obj.hideNumber = value;
            });
            
        }
        
        if(name !== 'questionErrorLocation'){
            survey.rawForm = JSON.stringify({
                ...parsed,
                pages: [{
                    ...parsed.pages[0],
                    elements: page1.elements
                }]
            });
        }else{
            survey.rawForm = JSON.stringify({
                ...parsed,
                questionErrorLocation: value
            });
        }

        this.setState({ survey, unsavedChanges: true, allowHardWarning: true });
    };

    changeQuestionCardVisibility = (value) => {
        var { survey } = this.state;

        if(value && !survey.css.includes(`#formComponent .sd-row__question--small{
                box-shadow: unset;
                background: unset;
                background-color: unset;
            }`)){

                survey.css += `#formComponent .sd-row__question--small{
                box-shadow: unset;
                background: unset;
                background-color: unset;
            }\n#formComponent .sd-element--with-frame{
                padding: unset;
                box-shadow: unset;
                background-color: transparent;
            }\n#formComponent .sd-error{
                width: 100%;
                margin: unset;
            }\n#formComponent .sd-text__content{
                margin-bottom: unset;
            }`;

        }else if(!value && survey.css.includes(`#formComponent .sd-row__question--small{
                box-shadow: unset;
                background: unset;
                background-color: unset;
            }`)) {

            survey.css = survey.css.replace(`#formComponent .sd-row__question--small{
                box-shadow: unset;
                background: unset;
                background-color: unset;
            }`, "");

            survey.css = survey.css.replace(`#formComponent .sd-element--with-frame{
                padding: unset;
                box-shadow: unset;
                background-color: transparent;
            }`, "");

            survey.css = survey.css.replace(`#formComponent .sd-error{
                width: 100%;
                margin: unset;
            }`, "");

            survey.css = survey.css.replace(`#formComponent .sd-text__content{
                margin-bottom: unset;
            }`, "");
        }

        this.setState({ survey, unsavedChanges: true, allowHardWarning: true });
    }

    changeQuestionTitleVisibility = (value) => {
        var { survey } = this.state;

        if (value === 'hidden' && !survey.css.includes(`.sd-title.sd-element__title.sd-question__title{
                display:none
            }`)) {

            survey.css = survey.css.replace(`.descriptionURL{
                visibility:hidden
            }`, `.sd-title.sd-element__title.sd-question__title{
                display:none
            }\n.descriptionURL{
                visibility:visible
            }`);

        } else if (value !== 'hidden' && survey.css.includes(`.sd-title.sd-element__title.sd-question__title{
                display:none
            }`)) {

            survey.css = survey.css.replace(`.sd-title.sd-element__title.sd-question__title{
                display:none
            }\n.descriptionURL{
                visibility:visible
            }`, `.descriptionURL{
                visibility:hidden
            }`);
        }

        this.handleChangeSelectStyles(value, 'labelPosition');
    }

    copyCode = (id) => {
        const { activeCountryTab } = this.state;
        const formUrl = process.env.REACT_APP_SURVEY_URL;
        const code = `<iframe title="PNForm" style="width:100%; height:100%" src='${formUrl}/${id}${activeCountryTab !== 'default' ? `?lang=${activeCountryTab}&Consent=false` : '?Consent=false'}' frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen> </iframe>`;

        navigator.clipboard.writeText(code);
        handleNotification('', <FormattedMessage id="ProfileNowMenu.CopyCodeToClipboard" />);
    }

    copyUrl = (id) => {
        const { activeCountryTab } = this.state;
        const formUrl = process.env.REACT_APP_SURVEY_URL;
        const link = `${formUrl}/${id}${activeCountryTab !== 'default' ? `?lang=${activeCountryTab}&Consent=false` : '?Consent=false'}`;

        navigator.clipboard.writeText(link);
        handleNotification('', <FormattedMessage id="ProfileNowMenu.LinkCopied" />);
    }

    isValidUrl = (url) => {
        if(!url || url === '') return false;
        // Regular expression for URL validation
        const urlRegex = /^(?:(?:https?|ftp):\/\/)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
        
        // Test the given URL against the regex
        return urlRegex?.test(url);
    }

    checkInvalidUrl = (question, key) => {
        const { marketingUrlNotValid } = this.state;
        if(question){
            if(!question['marketingConsentUrl']){
                if(marketingUrlNotValid !== false){
                    this.setState({ marketingUrlNotValid: false }, () => this.handleQuestionChange(question, key));
                    return;
                }
            }

            if(typeof question['marketingConsentUrl'] === 'string'){
                if(question['marketingConsentUrl'] === ''){
                    if(marketingUrlNotValid !== false){
                        this.setState({ marketingUrlNotValid: false }, () => this.handleQuestionChange(question, key));
                        return;
                    }
                }else if(!this.isValidUrl(question['marketingConsentUrl'])){
                    if(marketingUrlNotValid !== true){
                        this.setState({ marketingUrlNotValid: true }, () => this.handleQuestionChange(question, key));
                        return;
                    }
                }
            }
            else{
                if(Object.keys(question['marketingConsentUrl'])){
                    const hasInvalidUrl = Object.keys(question['marketingConsentUrl']).some((key) => !this.isValidUrl(question['marketingConsentUrl'][key]) && question['marketingConsentUrl'][key] !== '');
                    if(marketingUrlNotValid !== hasInvalidUrl){
                        this.setState({ marketingUrlNotValid: hasInvalidUrl }, () => this.handleQuestionChange(question, key));
                        return;
                    }
                }
            }
        }
        this.handleQuestionChange(question, key)
    }

    checkRequiredFormFields = () => {
        const { survey, formNotCompleted } = this.state;
        
        if(!survey.name || !survey.hotelId){
            if(formNotCompleted !== true){
                this.setState({ formNotCompleted: true });
            }
        }else{
            if(formNotCompleted !== false){
                this.setState({ formNotCompleted: false });
            }
        }
    }

    handleBackgroundImageChange = (evt) => {
        const { survey } = this.state;
        const { value } = evt.target;
    
        const backgroundImageCss = `background-image: url(${value});`;
        const transparentBackgroundCss = `\n#formComponent .sd-body.sd-body--static > .sv-components-column { background-color: transparent !important; }`;
        
        if(!survey.css){
            survey.css = "";
        }

        if (!value) {
            survey.css = survey.css
                .replace(/background-image: url\([^)]+\);/, 'background-image: none;')
                .replace(transparentBackgroundCss, '');
            delete survey.backgroundImage;
        } else {
            survey.css = survey.css.includes('background-image: none;') ? 
                    survey.css.replace('background-image: none;', backgroundImageCss)
                : 
                    survey.css.match(/background-image: url\([^)]+\);/) ? 
                        survey.css.replace(/background-image: url\([^)]+\);/, backgroundImageCss)
                    : 
                        survey.css
            
            if (!survey.css.includes(transparentBackgroundCss)) {
                survey.css += transparentBackgroundCss;
            }
    
            survey.backgroundImage = value;
        }
    
        this.setState({ survey, unsavedChanges: true, allowHardWarning: true });
    }

    handleBackgroundOpacityChange = (evt) => {
        const { survey } = this.state;
        let value = parseFloat(evt.target.value);
    
        value = isNaN(value) || value < 0 ? 0 : value > 100 ? 100 : value;
        const opacity = (value / 100).toFixed(2);
        
        if(!survey.css){
            survey.css = "";
        }

        const beforeBlockRegex = /#formComponent\s+\.sv-components-column--expandable::before\s*{[^}]*}/; //#formComponent .sv-components-column--expandable::before { ... }
        const opacityRegex = /opacity:\s*[^;]+;/;
        const newCss = `opacity: ${opacity};`;
    
        if (beforeBlockRegex.test(survey.css)) {
            survey.css = survey.css.replace(beforeBlockRegex, (block) => {
                if (opacityRegex.test(block)) {
                    return block.replace(opacityRegex, newCss);
                } else {
                    return block.replace(/}$/, ` ${newCss}\n}`);
                }
            });
        } else {
            survey.css += `\n#formComponent .sv-components-column--expandable::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: none;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                ${newCss}
                z-index: 1;
            }`;
        }
    
        survey.backgroundOpacity = parseFloat(opacity);
        this.setState({ survey, unsavedChanges: true, allowHardWarning: true });
    }

    handleLogoPositionChange = (combo) => {
        if (!combo) return;
    
        const { survey } = this.state;
        if (!survey || !survey.css) return;
    
        const value = combo.value;
    
        const flexDirections = {
            ["-webkit-left"]: 'unset',
            ["-webkit-center"]: 'column',
            ["-webkit-right"]: 'row-reverse'
        };
    
        const justifyContent = {
            ["-webkit-left"]: 'unset',
            ["-webkit-center"]: 'center',
            ["-webkit-right"]: 'space-between'
        };
    
        const headerColBlockRegex = /\.surveyHeader\s*>\s*\.col:first-of-type\s*{[^}]*}/; //.surveyHeader > .col:first-of-type { ... }
        survey.css = survey.css.replace(headerColBlockRegex, (block) => {
            block = block.replace(/flex-direction:\s*(row-reverse|row|column|unset);/, `flex-direction: ${flexDirections[value]};`);
            return block.replace(/justify-content:\s*(space-between|center|unset);/, `justify-content: ${justifyContent[value]};`);
        });
    
        const imgMarginRegex = /\.surveyHeader\s*\.col\s*img\s*{[^}]*}/; //.surveyHeader .col img { ... }
        survey.css = survey.css.replace(imgMarginRegex, (block) => {
            return block.replace(/margin-right:\s*(1.5rem|0px)\s*!important;/, `margin-right: ${value === '-webkit-left' ? '1.5rem !important' : '0px !important'};`);
        });
    
        const hotelNameMarginRegex = /\.surveyHeader\s*\.col\s*\.hotelName\s*{[^}]*}/; //.surveyHeader .col .hotelName { ... }
        survey.css = survey.css.replace(hotelNameMarginRegex, (block) => {
            return block.replace(/margin-top:\s*(0px|1rem);/, `margin-top: ${value === '-webkit-center' ? '1rem' : '0px'};`);
        });
    
        survey.logoPosition = value;
        this.setState({ survey, unsavedChanges: true, allowHardWarning: true });
    }

    updateTags = (newTag) => {
        const { survey, tags } = this.state;
        const tagIdx = tags.findIndex(tg => tg.tagGroupId === newTag.tagGroupId);
        if (tagIdx >= 0) {
            tags[tagIdx].options.push({ label: newTag.code, value: newTag.id });
            survey.tagId = newTag.id;
            this.setState({ tags, survey });
        }
    }

    resetCache = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ error: errorMessage, block: false }));
                }
            }
            else {
                handleNotification(data, <FormattedMessage id="Actions.CacheResetSuccessfully" />, <FormattedMessage id="generic.success" />);
                this.setState({ block: false });
            }
        }, `/api/gms/Profile/v1/resetUserCache?cacheKey=PNForms`);
    }

    getGoogleAnalyticsAccounts = () => {
        this.setState({ blockGAAccounts: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockGAAccounts: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, blockGAAccounts: false });
                        return;
                    }
    
                    if(data.response?.length > 0){
                        const options = data.response.map(({ name, id }) => ({ value: id, label: name }));
                        this.setState({ gaAccountsOptions: options, blockGAAccounts: false });
                        return;
                    }

                    this.setState({ blockGAAccounts: false });
                }
            }, `/api/gms/GoogleAnalytics/v1/googleanalytics`);
        });
    }

    hideTitle = () => {
        const { survey } = this.state;

        if (!survey.css.includes(`h3.sd-title { display: none; }`))
        {
            survey.css += `h3.sd-title { display: none; }`;

            this.setState({ survey });
        }
    }

    displayTitle = () => {
        const { survey } = this.state;

        if (survey.css.includes(`h3.sd-title { display: none; }`))
        {
            survey.css = survey.css.replace('h3.sd-title { display: none; }', '');
            
            this.setState({ survey });
        }
    }

    render() {
        const { block, error, activeTab, survey, unsavedChanges, formQuestions, activeCountryTab, formLanguages, 
            deleteModal, hideQuestionCard, hideQuestionNumber, questionErrorLocation, labelPosition, formNotComplete, 
            marketingUrlNotValid, deleteFormModal, tags, tagGroups, layoutDisplay, hideQuestionTitle, buttonSubmitPosition, 
            navigateToUrl, gaAccountsOptions, blockGAAccounts, autoConsentTypes } = this.state;
        const { intl } = this.props;

        const tabWithoutLang = formLanguages.find(({ language }) => !language);

        return (
            <StyledCard block={block} error={error} >
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="/ProfileNowConfig">
                            <FormattedMessage id="ProfileNowMenu.Title"/>
                        </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                        {survey.name ?? <FormattedMessage id="FormBuilder.TabTitle"/>}
                    </BreadcrumbItem>
                </Breadcrumb>
                <div ref={this.setWrapperRef}>
                    <Row>
                        <Col className='col-4'>
                            <form ref={this.setWrapperFormRef} onSubmit={this.saveForm}>
                                <Row>
                                    <Col>
                                        <Nav tabs className="border-0 mb-3">
                                            <NavItem>
                                                <NavLink disabled={activeCountryTab !== 'default'} className={`${activeTab === '0' ? 'tab-border-host text-host border-0' : 'border-0'} ${activeCountryTab !== 'default' ? 'disabledTab' : ''}`} onClick={() => this.toggleTab('0')} style={{ cursor: "pointer" }}>
                                                    <b><FormattedMessage id="FormBuilder.Settings"/></b>
                                                </NavLink>
                                            </NavItem>
                                            {survey.type !== 'LeadForm' && survey.type !== 'DealSatisfactionForm' ?
                                                <NavItem>
                                                    <NavLink className={activeTab === '1' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => this.toggleTab('1')} style={{ cursor: "pointer" }}>
                                                        <b><FormattedMessage id="FormBuilder.Content" /></b>
                                                    </NavLink>
                                                </NavItem>
                                            : ''}
                                            <NavItem>
                                                <NavLink disabled={activeCountryTab !== 'default'} className={`${activeTab === '2' ? 'tab-border-host text-host border-0' : 'border-0'} ${activeCountryTab !== 'default' ? 'disabledTab' : ''}`} onClick={() => this.toggleTab('2')} style={{ cursor: "pointer" }}>
                                                    <b><FormattedMessage id="FormBuilder.Styles"/></b>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </Col>
                                    <Col className="col-2 d-flex align-items-center justify-content-end">
                                        <Button className="btn btn-sm btn-host mr-2" id="resetCachecButton" onClick={this.resetCache}>
                                            <i className="fas fa-sync"></i>
                                        </Button>
                                        <UncontrolledTooltip placement="bottom" target="resetCachecButton" >
                                            <FormattedMessage id="FormBuilder.ResetCache" />
                                        </UncontrolledTooltip >
                                        <Button className="btn btn-sm btn-host mr-2" id="copyCodeButton" onClick={() => this.copyCode(survey.id)}>
                                            <i className="fas fa-copy" ></i>
                                        </Button>
                                        <UncontrolledTooltip placement="bottom" target="copyCodeButton" >
                                            <FormattedMessage id="FormBuilder.CopyCode" />
                                        </UncontrolledTooltip >
                                        <Button className="btn btn-sm btn-host mr-2" id="copyUrlButton" onClick={() => this.copyUrl(survey.id)}>
                                            <i className="fas fa-clipboard" ></i>
                                        </Button>
                                        <UncontrolledTooltip placement="bottom" target="copyUrlButton" >
                                            <FormattedMessage id="FormBuilder.CopyUrl" />
                                        </UncontrolledTooltip >
                                        <Button className="btn-sm btn-host mr-2" onClick={() => this.toggleDeleteFormModal()}>
                                            <i className="fas fa-trash"></i>
                                        </Button>
                                        <Button className="btn btn-sm btn-host" type='submit' disabled={tabWithoutLang || formNotComplete || marketingUrlNotValid}>
                                            <i className="fas fa-save" ></i>
                                            {unsavedChanges ?
                                                <span className='stripoUnsavedChanges'/>
                                            :''}
                                        </Button>
                                    </Col>
                                </Row>
                                <Card>
                                    <CardBody>
                                        {activeTab === '0' ?
                                            <FormSettings
                                                survey={survey}
                                                tags={tags}
                                                tagGroups={tagGroups}
                                                gaAccountsOptions={gaAccountsOptions}
                                                blockGAAccounts={blockGAAccounts}
                                                handleInputChange={this.handleInputChange}
                                                handleChangeCombo={this.handleChangeCombo}
                                                updateTags={this.updateTags}
                                            />
                                        : activeTab === '1' ?
                                            <FormContent
                                                    survey={survey}
                                                    formQuestions={formQuestions}
                                                    activeCountryTab={activeCountryTab}
                                                    formLanguages={formLanguages}
                                                    handleQuestionChange={this.handleQuestionChange}
                                                    updateRawTitleDescComplete={this.updateRawTitleDescComplete}
                                                    changeSelectLanguages={this.changeSelectLanguages}
                                                    comboLanguagesOptions={this.state.comboLanguagesOptions}
                                                    isValidUrl={this.isValidUrl}
                                                    checkInvalidUrl={this.checkInvalidUrl}
                                                    hideTitle={this.hideTitle}
                                                    displayTitle={this.displayTitle}
                                                    handleChangeAutoConsentType={this.handleChangeAutoConsentType}
                                                    autoConsentTypes={autoConsentTypes}
                                            />
                                        : activeTab === '2' ?
                                            <FormStyles
                                                survey={survey}
                                                hideQuestionCard={hideQuestionCard}
                                                hideQuestionNumber={hideQuestionNumber}
                                                labelPosition={labelPosition}
                                                questionErrorLocation={questionErrorLocation}
                                                layoutDisplay={layoutDisplay}
                                                hideQuestionTitle={hideQuestionTitle}
                                                buttonSubmitPosition={buttonSubmitPosition}
                                                handleInputChange={this.handleInputChange}
                                                handleInputChangeOnSurveyAndRawForm={this.handleInputChangeOnSurveyAndRawForm}
                                                handleStylesChange={this.handleStylesChange}
                                                changeStylesSelect={this.changeStylesSelect}
                                                handleBackgroundOpacityChange={this.handleBackgroundOpacityChange}
                                                handleBackgroundImageChange={this.handleBackgroundImageChange}
                                                handleLogoPositionChange={this.handleLogoPositionChange}
                                                handleLayoutDisplay={this.handleLayoutDisplay}
                                                handleButtonSubmitPosition={this.handleButtonSubmitPosition}
                                                handleSubmitTextColor={this.handleSubmitTextColor}
                                                navigateToUrl={navigateToUrl}
                                            />
                                        : ''}
                                    </CardBody>
                                </Card>
                            </form>
                        </Col>
                        <Col className='col-8'>
                            <Nav tabs>
                                {formLanguages.map((obj, key) =>
                                    obj.language === 'default' ?
                                        <NavItem key={key}>
                                            <NavLink className={activeCountryTab === 'default' ? 'tab-border-host text-host border-0 cursor-pointer' : 'border-0 cursor-pointer'} onClick={() => this.toggleActiveCountryTab('default')}>
                                                <span className='mb-2' /> Default
                                            </NavLink>
                                        </NavItem>
                                        :
                                        <NavItem key={key} className="cursor-pointer">
                                            <NavLink className={activeCountryTab === obj.tab ? 'tab-border-host text-host border-0' : 'border-0'} onClick={() => this.toggleActiveCountryTab(obj.tab)}>
                                                <span className={`flag-icon flag-icon-${obj.language === 'en-gb' ? `gb` : obj.language} mb-2 px-2`} />
                                                {obj.removableLang ?
                                                    <span className='formBuilderRemoveTab'><i className="far fa-times-circle" style={{color: "#c10000", borderRadius:' 100px'}} onClick={this.toggleDeleteModal}></i></span>
                                                :''}
                                            </NavLink>
                                        </NavItem>
                                )}
                                { formLanguages.length <= 7 &&
                                    <NavItem>
                                        <NavLink className="d-flex h-100">
                                            <div>
                                                <Button onClick={this.addCountryTab} className=" btn-host btn-sm " style={{ 'fontSize': '0.35rem' }} disabled={tabWithoutLang} >
                                                    <i className="fas fa-plus"/>
                                                </Button>
                                            </div>
                                        </NavLink>
                                    </NavItem>
                                }
                            </Nav>
                            <SurveyPreview
                                formData={survey?.rawForm}
                                css={survey.css}
                                hotelLogo={survey.imageUrl}
                                hotelName={survey.title}
                                activeCountryTab={activeCountryTab}
                            />
                        </Col>
                    </Row>
                </div>
                {deleteModal ?
                    <ConfirmActionModal
                        block={block}
                        error={error}
                        text={intl.formatMessage({ id: "stripo.deleteConfirm" })}
                        toggleModal={this.toggleDeleteModal}
                        modal={deleteModal}
                        actionFunction={this.removeLanguageTab}
                    />
                    : ''}
                {deleteFormModal ?
                    <ConfirmActionModal
                        block={block}
                        error={error}
                        text={intl.formatMessage({ id: "FormBuilder.DeleteForm" })}
                        toggleModal={this.toggleDeleteFormModal}
                        modal={deleteFormModal}
                        actionFunction={this.deleteForm}
                    />
                :''}
            </StyledCard>
        )
    }
}

export default injectIntl(FormBuilder)