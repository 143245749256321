import React, { Component } from 'react'
import { Modal, Form, Button, Col, Row, Card, Input, Label } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { postAPI } from '../Base/API';
import { CoolTooltip, CoolBadge } from '../Base/CommonUIComponents';
import { handleNotification } from '../Base/Notification';
import { SingleDatePicker } from 'react-dates';

import BlockUi from 'react-block-ui';
import CustomSelect from '../Base/CustomSelect';
import moment from 'moment';
import ConfirmActionModal from '../Base/ConfirmActionModal';

class SalesProcessCityEventsEventModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            event: null,
            startDateFocused: null,
            endDateFocused: null,
            categoryOptions: [
                { value: 'Congress', label: this.props.intl.formatMessage({ id: 'SalesProcess.SalesProcessCityEventsEventCategoryCongress' }) },
                { value: 'Religious', label: this.props.intl.formatMessage({ id: 'SalesProcess.SalesProcessCityEventsEventCategoryReligious' }) },
                { value: 'Festival', label: this.props.intl.formatMessage({ id: 'SalesProcess.SalesProcessCityEventsEventCategoryFestival' }) },
                { value: 'Tradeshow', label: this.props.intl.formatMessage({ id: 'SalesProcess.SalesProcessCityEventsEventCategoryTradeshow' }) },
                { value: 'Business&professional', label: this.props.intl.formatMessage({ id: 'SalesProcess.SalesProcessCityEventsEventCategoryBusiness&professional' }) },
                { value: 'Science&technology', label: this.props.intl.formatMessage({ id: 'SalesProcess.SalesProcessCityEventsEventCategoryScience&technology' }) },
                { value: 'Music', label: this.props.intl.formatMessage({ id: 'SalesProcess.SalesProcessCityEventsEventCategoryMusic' }) },
                { value: 'Other', label: this.props.intl.formatMessage({ id: 'SalesProcess.SalesProcessCityEventsEventCategoryOther' }) }
            ],
            typeOptions: [
                { value: 'Local', label: this.props.intl.formatMessage({ id: 'SalesProcess.SalesProcessCityEventsEventTypeLocal' }) },
                { value: 'National', label: this.props.intl.formatMessage({ id: 'SalesProcess.SalesProcessCityEventsEventTypeNational' }) },
                { value: 'International', label: this.props.intl.formatMessage({ id: 'SalesProcess.SalesProcessCityEventsEventTypeInternational' }) }
            ],
            eventTypeOptions: [
                { value: 'Event', label: this.props.intl.formatMessage({ id: 'SalesProcess.SalesProcessCityEventsEventTypeEvent' }) },
                { value: 'Holiday', label: this.props.intl.formatMessage({ id: 'SalesProcess.SalesProcessCityEventsEventTypeHoliday' }) },
                { value: 'Note', label: this.props.intl.formatMessage({ id: 'SalesProcess.SalesProcessCityEventsEventTypeNote' }) }
            ],
            deleteEventModal: false,
        }
    }

    componentDidMount() {
        const { selectedEvent, selectedHotelToAddEvent, hotelListOptions } = this.props;

        const cityOptions = [];
        const seenCities = new Set();
        hotelListOptions.forEach(hotel => {
            if (hotel.city && !seenCities.has(hotel.city)) {
                seenCities.add(hotel.city);
                cityOptions.push({ city: hotel.city, isSelected: false });
            }
        });

        if(selectedEvent) {
            this.setState({ event: { ...selectedEvent }, cityOptions });
        }else{
            if(selectedHotelToAddEvent){
                this.setState({ event: { hotelList: [selectedHotelToAddEvent.id] }, cityOptions });
            }
            else {
                this.setState({ cityOptions });
            }
        }
    }

    saveEvent = (e, recurring) => {
        if(e) e.preventDefault();
        const { event } = this.state;
        let body = null;

        if(!event) {
            return
        }else {
            body = { ...event }
        }

        if(body){
            this.setState({ block: true }, () => {
                postAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];
        
                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data && data.errors && data.errors.length > 0) {
                            handleNotification(data);
                            this.setState({ block: false });
                            return;
                        }
        
                        if (data.response && data.response.length > 0) {
                            handleNotification(data, <FormattedMessage id="SalesProcessLocationEvent.SavedSuccessfully" />, <FormattedMessage id="generic.success" />);

                            this.setState({ block: false }, () => { this.props.toggleModal(null, true); });
                            return;
                        }
        
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    else this.setState({ error: errorMessage, block: false });
                }, `/api/gms/SalesProcess/v1/location/events${recurring ? '?isRecurrent=true' : ''}`, body);
            });
        }
    }

    toggleDeleteEventModal = () => {
        this.setState({ deleteEventModal: !this.state.deleteEventModal });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
    
        this.setState(prevState => ({
            event: {
                ...prevState.event,
                [name]: value
            }
        }));
    };

    handleSelect = (combo, name) => {
        this.setState(prevState => ({
            event: {
                ...prevState.event,
                [name]: combo ? combo.value : null
            }
        }));
    };

    handleHotels = (combo) => {
        this.setState(prevState => ({
            event: {
                ...prevState.event,
                hotelList: combo ? combo.map(cmb => cmb.value) : []
            }
        }));
    };

    handleLoc = (city) => {
        const { cityOptions, event, isAllSelected } = this.state;
        const { hotelListOptions } = this.props;

        let updatedHotelList = [];

        if (city === 'SelectAll') {
            if (!isAllSelected) {
                updatedHotelList = hotelListOptions.map(x => x.value);
            }

            this.setState(prevState => ({
                cityOptions: prevState.cityOptions.map(option => ({
                    ...option,
                    isSelected: !prevState.isAllSelected
                })),
                event: {
                    ...prevState.event,
                    hotelList: updatedHotelList
                },
                isAllSelected: !prevState.isAllSelected
            }));
        } else {
            const data = cityOptions.find(e => e.city === city);

            updatedHotelList = event?.hotelList ? [...event.hotelList] : [];

            if (data) {
                if (data.isSelected) {
                    const hotelsToRemove = hotelListOptions?.filter(hotel => hotel.city === city)?.map(x => x.value) ?? [];
                    updatedHotelList = updatedHotelList.filter(hotel => !hotelsToRemove.includes(hotel));
                }
                else {
                    const hotelsToAdd = hotelListOptions?.filter(hotel => hotel.city == city)?.map(x => x.value) ?? [];
                    updatedHotelList = updatedHotelList.concat(hotelsToAdd);
                }

            }
            else {
                updatedHotelList = [];
            }
            data.isSelected = !data.isSelected;

            this.setState(() => ({
                cityOptions: [...cityOptions],
                event: {
                    ...event,
                    hotelList: updatedHotelList
                },
            }));
        }
    }

    render() {
        const { event, startDateFocused, endDateFocused, categoryOptions, typeOptions, block, deleteEventModal, eventTypeOptions, cityOptions, isAllSelected } = this.state;
        const { selectedEvent, toggleModal, hotelListOptions, saveCityEventsPermission } = this.props;

        return (
            <Modal isOpen={true} toggle={toggleModal}>
                <Button onClick={toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"/>
                </Button>
                <BlockUi tag="div" blocking={block}>
                    <Card className='border-0 p-4 shadow'>
                        <Form onSubmit={(e) => this.saveEvent(e)}>
                            <Row className='align-items-center mb-4'>
                                <Col className='col-10'>
                                    <h5 className='m-0'>
                                        <FormattedMessage id={`SalesProcess.SalesProcessCityEvents${selectedEvent ? 'Edit' : 'New'}Event`} />
                                    </h5>
                                </Col>
                                {saveCityEventsPermission ? 
                                    <Col className='col-2 text-right'>
                                        {selectedEvent ? 
                                            <>
                                                <Button id="deleteEvent" type='button' className="bg-danger border-danger btn-sm btn-host mr-2" onClick={() => this.toggleDeleteEventModal()}>
                                                    <i className="fas fa-trash-alt" />
                                                </Button>
                                                <CoolTooltip placement='top' target="deleteEvent">
                                                    <FormattedMessage id="SalesProcess.SalesProcessCityEventsDeleteEvent" />
                                                </CoolTooltip>
                                            </>
                                        :""}
                                        <Button id="saveEventRecurrent" type='button' className="btn-sm btn-host mr-2" onClick={(e) => this.saveEvent(e, true)}>
                                            <i className="fas fa-calendar-plus" />
                                        </Button>
                                        <CoolTooltip placement='top' target="saveEventRecurrent">
                                            <FormattedMessage id="SalesProcess.SalesProcessCityEventsSaveEventRecurrent" />
                                        </CoolTooltip>
                                        <Button id="saveEvent" type='submit' className="btn-sm btn-host mr-2">
                                            <i className="fas fa-save" />
                                        </Button>
                                        <CoolTooltip placement='top' target="saveEvent">
                                            <FormattedMessage id="SalesProcess.SalesProcessCityEventsSaveEvent" />
                                        </CoolTooltip>
                                    </Col>
                                :''}
                            </Row>
                            <Row className='align-items-end'>
                                <Col className='col-6'>
                                    <Label for="location">
                                        <FormattedMessage id="SalesProcess.SalesProcessCityEventsEventName" /> <span className='text-danger'>*</span>
                                    </Label>
                                    <Input 
                                        type="text" 
                                        name="name" 
                                        value={event ? event.name : ''} 
                                        onChange={this.handleInputChange}
                                        required
                                    />
                                </Col>
                                <Col className="col-3">
                                    <Label for="CityEventStartDate">
                                        <FormattedMessage id="SalesProcess.SalesProcessCityEventsStartDate" /> <span className='text-danger'>*</span>
                                    </Label>
                                    <SingleDatePicker
                                        name="CityEventStartDate"
                                        id="CityEventStartDate"
                                        isOutsideRange={day => null}
                                        date={event?.startDate ? moment(event.startDate) : null}
                                        focused={startDateFocused}
                                        onFocusChange={({ focused }) => this.setState({ startDateFocused: focused })}
                                        small={true}
                                        numberOfMonths={1}
                                        showDefaultInputIcon={true}
                                        onDateChange={date => this.setState({ event: { ...event, startDate: date } })}
                                        required
                                    />
                                </Col>
                                <Col className="col-3">
                                    <Label for="CityEventEndDate">
                                        <FormattedMessage id="SalesProcess.SalesProcessCityEventsEndDate" /> <span className='text-danger'>*</span>
                                    </Label>
                                    <SingleDatePicker
                                        name="CityEventEndDate"
                                        id="CityEventEndDate"
                                        isOutsideRange={day => event?.startDate ? day.isBefore(moment(event?.startDate)) : day.isBefore(moment())}
                                        date={event?.endDate ? moment(event.endDate) : null}
                                        focused={endDateFocused}
                                        onFocusChange={({ focused }) => this.setState({ endDateFocused: focused })}
                                        small={true}
                                        numberOfMonths={1}
                                        showDefaultInputIcon={true}
                                        onDateChange={date => this.setState({ event: { ...event, endDate: date } })}
                                        required
                                        disabled={!event?.startDate}
                                    />
                                </Col>
                                <Col className='col-6 mt-3'>
                                    <div className=' d-flex align-items-end justify-content-between'>
                                        <Label for="eventHotelList" style={{ minWidth: '65px' }}>
                                            <FormattedMessage id="SalesProcess.SalesProcessCityEventsHotels" /> <span className='text-danger'>*</span>
                                        </Label>
                                        <div className="d-flex" style={{ flexShrink: 0 }} >
                                            <CoolBadge
                                                text={<FormattedMessage id="SalesProcess.SelectAll" />}
                                                onClick={this.handleLoc}
                                                isSelected={isAllSelected}
                                                data={'SelectAll'}
                                                hasInput={false}
                                                small={true}
                                            />
                                        </div>
                                        <div className='veryCoolScrollBar'
                                            style={{
                                                overflowX: 'auto',
                                                whiteSpace: 'nowrap',
                                                transform: 'rotateX(180deg)'
                                            }}>
                                            <div className="d-flex" style={{ flexShrink: 0, transform: 'rotateX(180deg)' }}>
                                                {cityOptions?.map((city, key) =>
                                                    <CoolBadge
                                                        key={key}
                                                        text={city.city}
                                                        onClick={this.handleLoc}
                                                        isSelected={city.isSelected}
                                                        data={city.city}
                                                        hasInput={false}
                                                        small={true}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <CustomSelect
                                        name="eventHotelList"
                                        value={event?.hotelList?.length ? hotelListOptions.filter(h => event.hotelList.find(eh => h.value === eh)) : ''}
                                        options={hotelListOptions}
                                        onChange={(combo) => this.handleHotels(combo)}
                                        isMulti={true}
                                        required
                                        isSearchable
                                        placeholder={""}
                                    />
                                </Col>
                                <Col className='col-6 mt-3'>
                                    <Label for="eventEventType">
                                        <FormattedMessage id="SalesProcess.SalesProcessCityEventsEventType" /> <span className='text-danger'>*</span>
                                    </Label>
                                    <CustomSelect
                                        name="eventType"
                                        value={event ? eventTypeOptions.find(c => c.value === event.eventType) : ''}
                                        options={eventTypeOptions}
                                        onChange={(e) => this.handleSelect(e, 'eventType')}
                                        required
                                        isSearchable
                                        placeholder={""}
                                    />
                                </Col>
                                <Col className='col-6 mt-3'>
                                    <Label for="eventCategory">
                                        <FormattedMessage id="SalesProcess.SalesProcessCityEventsEventCategory" />
                                    </Label>
                                    <CustomSelect
                                        name="eventCategory"
                                        value={event ? categoryOptions.find(c => c.value === event.category) : ''}
                                        options={categoryOptions}
                                        onChange={(e) => this.handleSelect(e, 'category')}
                                        isSearchable
                                        placeholder={""}
                                    />
                                </Col>
                                <Col className='col-6 mt-3'>
                                    <Label for="type">
                                        <FormattedMessage id="SalesProcess.SalesProcessCityEventsEventInfluence" />
                                    </Label>
                                    <CustomSelect
                                        name="type"
                                        value={event ? typeOptions.find(t => t.value === event.type)  : ''}
                                        options={typeOptions}
                                        onChange={(e) => this.handleSelect(e, 'type')}
                                        isSearchable
                                        placeholder={""}
                                    />
                                </Col>
                                <Col className='col-6 mt-3'>
                                    <Label for="location">
                                        <FormattedMessage id="SalesProcess.SalesProcessCityEventsEventLocation" />
                                    </Label>
                                    <Input 
                                        type="text" 
                                        name="location" 
                                        value={event ? event.location : ''} 
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                                <Col className='col-6 mt-3'>
                                    <Label for="pax">
                                        <FormattedMessage id="SalesProcess.SalesProcessCityEventsEventPax" />
                                    </Label>
                                    <Input 
                                        type="number" 
                                        name="pax" 
                                        value={event ? event.pax : ''} 
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                                <Col className='col-12 mt-3'>
                                    <Label for="description">
                                        <FormattedMessage id="SalesProcess.SalesProcessCityEventsEventDescription" />
                                    </Label>
                                    <Input 
                                        type="textarea" 
                                        name="description" 
                                        value={event ? event.description : ''} 
                                        onChange={this.handleInputChange}
                                        style={{ minHeight: '150px' }}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Card>

                    {deleteEventModal ?
                        <ConfirmActionModal
                            modal={deleteEventModal}
                            toggleModal={() => this.toggleDeleteEventModal()}
                            actionFunction={() => this.props.deleteEvent(true)}
                            text={<FormattedMessage id="SalesProcess.SalesProcessCityEventsDeleteEventText" values={{ event: event?.name }} />}
                        />
                    : ''}
                </BlockUi>
            </Modal>
        )
    }
}

export default injectIntl(SalesProcessCityEventsEventModal);
