import React, { useState } from "react";
import { FormattedMessage, } from "react-intl";
import { StyledModal } from "../../Base/CommonUIComponents";
import { Button, Col, Row, Input } from "reactstrap";
import SearchReservation from "../../Admin/Reservations/SearchReservation";
import SearchProfile from "../../ProfileMenu/SearchProfile";
import { ErrorAlert } from "../../Base/ErrorAlert";
import BlockUi from 'react-block-ui';
import CreateProfile from "../../ProfileMenu/CreateProfile";
import CustomSelect from "../../Base/CustomSelect";



export const AssociateProfile = ({ block, error, associateProfileOrReservation, selectProfile, profile, toggleModal, modal, enableCreate, toggleCreateModal, associateNewProfile, createModal, disableMasterFilter, createMasterAPI, isPortal, showRoleOptions, profileRoleOptions }) => {
    const [currProfile, setCurrProfile] = useState(null);

    const updateProfile = (newProfile) => {
        const updatedProfile = { ...currProfile, ...newProfile };

        setCurrProfile(updatedProfile);
        selectProfile(updatedProfile);
    }

    return(
        <StyledModal toggleModal={toggleModal} modal={modal} size="xl" style={{ minWidth: 0, maxWidth: '85%', width: '85%' }}>
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                {!showRoleOptions || (showRoleOptions && !createModal) ?
                    <>
                        <Row className="mb-3 mt-2">
                            <Col>
                                <h5><FormattedMessage id="VoucherDetails.ChangeAssociatedProfile" /></h5>
                            </Col>
                            <Col className="text-right">
                                {enableCreate ?
                                    <Button className="btn-sm btn-host mr-2" onClick={toggleCreateModal} type="button">
                                        {createModal ?
                                            <i className="fas fa-list-ul"/>
                                        :
                                            <i className="fas fa-plus"/>
                                        }
                                    </Button>
                                :''}
                                <Button type="button" className="btn-sm btn-host" onClick={(e) => associateProfileOrReservation(e, true)} disabled={!showRoleOptions ? (!profile) : (!profile || !profile?.roleId)}>
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-4 mt-2">
                            <Col className="d-flex align-items-center">
                                <div style={{ width: 'fit-content' }} className="mr-3">
                                    <b><FormattedMessage id="AssociatedProfiles.SelectedProfile" />: </b>
                                    {profile ? profile.name ? <span>{profile.name}</span> : <span>{profile.firstName} {profile.lastName} </span> : <FormattedMessage id="AssociatedProfiles.NoProfileSelected" />}
                                </div>
                                {showRoleOptions && !createModal ?
                                    <>
                                        <div style={{ width: '15%' }} className="d-flex align-items-center mx-3">
                                            <b className="mr-2"><FormattedMessage id="SalesDeal.Role" />:</b>
                                            <CustomSelect
                                                className="w-100"
                                                options={profileRoleOptions || []}
                                                value={profileRoleOptions?.find(p => p.value === profile?.roleId) || null}
                                                onChange={(e) => updateProfile({ roleId: e?.value, role: e?.label })}
                                                isDisabled={!profile}
                                            />
                                        </div>
                                        <div style={{ width: '15%' }} className="d-flex align-items-center pl-3">
                                            <b className="mr-2"><FormattedMessage id="SalesDeal.Notes" />:</b>
                                            <Input
                                                type="text"
                                                value={profile?.notes || ''}
                                                onChange={(e) => updateProfile({ notes: e?.target?.value })}
                                                disabled={!profile}
                                            />
                                        </div>
                                    </>
                                :''}
                            </Col>
                        </Row>
                    </>
                :''}

                {enableCreate && createModal ?
                    <CreateProfile
                        toggleCreateModal={toggleCreateModal}
                        modal={createModal}
                        associateNewProfile={associateNewProfile}
                        createMasterAPI={createMasterAPI}
                        isPortal={isPortal}
                        showRoleOptions={showRoleOptions}
                        profileRoleOptions={profileRoleOptions}
                    />
                :
                    <SearchProfile
                        fromAnotherPage={true}
                        addProfile={updateProfile}
                        disableMasterFilter={disableMasterFilter}
                        isMaster={disableMasterFilter}
                        pageSize={5}
                        openProfileColumn={true}
                    />
                }
            </BlockUi>
        </StyledModal>
    )
};


export const AssociateReservation = ({ block, error, associateProfileOrReservation, hotelId, toggleModal, modal }) => {
    const [reservation, setReservation] = useState({});

    const selectReservation = (reservation, roomStay) => {
        setReservation({
            reservationHotelId: reservation.hotelId,
            reservationNumber: reservation.resIdValue,
            reservationDate: reservation.resIdDate,
            pmsReservationId: reservation.pmsId,
            pmsReservationDetailId: roomStay.pmsId,
            roomCode: roomStay.channelRoomTypeCode
        })
    }



    return <StyledModal toggleModal={toggleModal} modal={modal} size="xl" style={{ minWidth: 0, maxWidth: '85%', width: '85%' }}>
        <BlockUi tag="div" blocking={block}>
            <ErrorAlert error={error} />

            <Row className="mb-3 mt-2">
                <Col>
                    <h5><FormattedMessage id="VoucherDetails.ChangeAssociatedReservation" /></h5>
                </Col>
                <Col className="text-right">
                    <Button type="button" className="btn-sm btn-host" onClick={(e) => associateProfileOrReservation(e, false, reservation)} disabled={!reservation || !reservation.pmsReservationDetailId}>
                        <i className="fas fa-save" />
                    </Button>
                </Col>
            </Row>

            <Row className="mb-4 mt-2">
                <Col>
                    <b><FormattedMessage id="VoucherDetails.SelectedRoom" />: </b> {reservation && reservation.pmsReservationDetailId ? <span>{reservation.roomCode} </span> : <FormattedMessage id="VoucherDetails.NoRoomSelected" />}
                </Col>
            </Row>

            <div className="verticalScroll" style={{ height: '75vh', overflowX: 'hidden' }}>
                <SearchReservation
                    hideFilters={false}
                    hideRoomActions={true}
                    selectedHotelId={hotelId}
                    selectRoom={selectReservation}
                    selectedPmsRoomId={reservation.pmsReservationDetailId || null}
                />
            </div>
        </BlockUi>
    </StyledModal>
};