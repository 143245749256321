import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalBody, Input } from 'reactstrap';
import { handleNotification } from "../Base/Notification";
import { BlankCard } from '../Base/CommonUIComponents';
import CustomSelect from "../Base/CustomSelect";
import { SingleDatePicker } from "react-dates";
import { putAPI } from "../Base/API";
import moment from 'moment';

class DocumentDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            docData: this.props.document ? this.props.document : {},
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            docData: {
                ...this.state.docData,
                [name]: value
            }
        })
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            docData: {
                ...this.state.docData,
                [name]: combo ? combo.value : null,
                issueDate: combo && combo.value === 'FiscalNumber' ? null : this.state.docData.issueDate,
                validUntil: combo && combo.value === 'FiscalNumber' ? null : this.state.docData.validUntil
            }
        })
    };

    saveDoc = (e) => {
        e.preventDefault();

        var doc = { ...this.state.docData };

        if (typeof this.props.saveDocument === 'function') {
            this.props.saveDocument(doc)
        }
        else {
            this.setState({ block: true });

            putAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="DocumentDetails.DocumentSaved" />, <FormattedMessage id="generic.success" />);
                        this.props.updateTable(data.response[0], 'documents');
                        this.props.toggleModal();
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/Profile/v1/profile/document/${this.props.profileId}`, JSON.stringify({ request: doc }));
        }
    }

    render() {
        const { block, error, docData } = this.state;

        return (
            <Modal isOpen={this.props.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.saveDoc}>
                            <Row className="mb-3">
                                <Col>
                                    <h5>{this.props.document ? <FormattedMessage id="DocumentDetails.EditDocument" /> : <FormattedMessage id="DocumentDetails.AddDocument" />}</h5>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" type="submit"> <i className="fas fa-save" /></Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="ProfileDetails.Type" /></Label>
                                        <Col sm={8}>
                                            <CustomSelect
                                                options={this.props.availableDocuments}
                                                required
                                                isClearable
                                                isSearchable
                                                placeholder={""}
                                                onChange={(e) => this.handleChangeSelect(e, "type")}
                                                value={this.props.availableDocuments.find(doc => doc.value === docData.type)}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="ProfileDetails.Number" /></Label>
                                        <Col sm={8}>
                                            <Input
                                                type="text"
                                                name="number"
                                                onChange={(e) => this.handleChange(e)}
                                                value={docData.number ? docData.number : ''}
                                                required
                                                maxLength="50"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {!docData.type || docData.type !== 'FiscalNumber' ?
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label sm={4}> <FormattedMessage id="ProfileDetails.IssueDate" /></Label>
                                            <Col sm={8} className="text-right">
                                                <SingleDatePicker
                                                    id="id_date"
                                                    isOutsideRange={day => day >= moment()}
                                                    date={docData.issueDate ? moment(docData.issueDate) : null}
                                                    focused={this.state.focused2}
                                                    onFocusChange={({ focused }) => this.setState({ focused2: focused })}
                                                    small={true}
                                                    numberOfMonths={1}
                                                    onDateChange={date => this.setState({ docData: { ...this.state.docData, issueDate: date } })}
                                                    showDefaultInputIcon={true}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup row>
                                            <Label sm={4}> <FormattedMessage id="ProfileDetails.ValidUntil" /></Label>
                                            <Col sm={8} className="text-right">
                                                <SingleDatePicker
                                                    id="validUntilOfDoc"
                                                    isOutsideRange={day => day <= moment()}
                                                    date={docData.validUntil ? moment(docData.validUntil) : null}
                                                    focused={this.state.focused}
                                                    onFocusChange={({ focused }) => this.setState({ focused })}
                                                    small={true}
                                                    numberOfMonths={1}
                                                    onDateChange={date => this.setState({ docData: { ...this.state.docData, validUntil: date } })}
                                                    showDefaultInputIcon={true}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            : ''}
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(DocumentDetails)