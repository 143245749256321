import React, { Component } from 'react'
import BlockUi from 'react-block-ui'
import { ErrorAlert } from '../../Base/ErrorAlert'
import { Card, CardBody, Col, Row, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from '../../Base/Notification';
import { postAPI } from '../../Base/API';
import CustomSelect from '../../Base/CustomSelect';
import moment from 'moment-timezone';
import { GeoChart } from '../../Base/CustomCharts';
import ReactBubbleChart from "react-bubble-chart";
import * as countryList from 'react-select-country-list';

class CampaignTargetDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            campaignTarget: this.props.campaignTarget,
            runOptions: [],
            selectedRun: null,
            metricTypes: [
                'Nationalities',
                'AgeGroups',
                'Tags',
                'LoyaltyLevels',
                'WithLoyalty'
            ],
            countriesData: [],
            countriesDataCodes: [],
        };
    }

    componentDidMount(){
        this.formatData();
    }

    formatData = () => {
        const { campaignTarget, metricTypes, selectedRun } = this.state;

        let countriesData = [], countriesDataCodes=[], lineCountries = null, tagsData = [], hasLoyaltyData = [], loyaltyLevelsData = [], agesData = [
            { name: this.props.intl.formatMessage({ id: "Home.GenerationAlpha" }), start: 2013, end: parseInt(moment().format("YYYY")), total: 0 },
            { name: this.props.intl.formatMessage({ id: "Home.GenerationZ" }), start: 1997, end: 2012, total: 0 },
            { name: this.props.intl.formatMessage({ id: "Home.Millennials" }), start: 1981, end: 1996, total: 0 },
            { name: this.props.intl.formatMessage({ id: "Home.GenerationX" }), start: 1965, end: 1980, total: 0 },
            { name: this.props.intl.formatMessage({ id: "Home.BabyBoomers" }), start: 1946, end: 1964, total: 0 },
            { name: 'NA', total: 0 }
        ];

        if(!campaignTarget || !campaignTarget.targetDetails || campaignTarget.targetDetails.length === 0) return;
        const runOptions = campaignTarget?.targetDetails?.map(t => ({
            label: <span>
                <span className='mr-1'><FormattedMessage id="CampaignTargets.Run"/></span>
                (<b>{t.totalCount}</b>)
                <span className='mx-2'>-</span>
                <span>{moment(t.run).format('DD-MM-YYYY')}</span>
            </span>,
            value: t.id
        }))??[];

        const run = selectedRun ? campaignTarget.targetDetails.find(({id}) => selectedRun === id) : campaignTarget.targetDetails[0];

        const metrics = run?.metrics

        if(!metrics) return;

        const countries = metrics.filter(({type}) => type === metricTypes[0]);
        const ages = metrics.filter(({type}) => type === metricTypes[1]);
        const tags = metrics.filter(({type}) => type === metricTypes[2]);
        const loyaltyLevels = metrics.filter(({type}) => type === metricTypes[3]);
        const hasLoyalty = metrics.find(({ type }) => type === metricTypes[4]);

        if(countries && countries.length > 0){
            const labels = [];
            countriesData = [["Country", "Total"]];
            countriesDataCodes = [["Country", "Total"]];

            const groupedCountries = countries.reduce((acc, curr) => {
                if (!curr.key || curr.key === '') {
                    curr.key = null;
                }

                const country = acc.find(cntr => cntr.key?.toLocaleUpperCase() === curr.key?.toLocaleUpperCase())
                if (country) {
                    country.count += curr.count;
                }
                else {
                    acc.push(curr);
                }

                return acc;
            }, []);

            groupedCountries.forEach(c => {
                let countryLabel = c.key;
                if(c.key){
                    countryLabel = countryList().getLabel(c.key.toUpperCase());
                }
                labels.push(c.key);
                countriesData.push(
                    [
                        countryLabel,
                        c.count
                    ]
                );
                countriesDataCodes.push([c.key, c.count]);
            });

            lineCountries = {
                labels: labels,
                datasets: []
            }
        }

        const without = run.totalCount - hasLoyalty?.count;

        if(loyaltyLevels && loyaltyLevels.length > 0){
            loyaltyLevelsData = loyaltyLevels.sort((a, b) => a.count - b.count);
        }

        hasLoyaltyData.push({ count: hasLoyalty?.count, key: 'With Loyalty' });
        hasLoyaltyData.push({ count: without, key: 'Without Loyalty' });
        hasLoyaltyData.sort((a, b) => a.count - b.count);

        if(tags && tags.length > 0){
            tagsData = tags.map((t, k) => ({
                _id: `${t.key} - ${t.count}`,
                colorValue: k,
                value: t.count,
                selected: tags.length === 1 ? true : false
            }));
        }

        if(ages && ages.length > 0){
            const year = parseInt(moment().format('YYYY'));
            let totalCountAges = 0;
            ages.forEach(({key, count}) => {
                const generationYear = year - parseInt(key);
                let current = agesData.find(({start, end}) => start <= generationYear && end >= generationYear);
                if(!current){
                    current = agesData[agesData.length - 1];
                }
                current.total += count;
                totalCountAges += count;
            });

            if(totalCountAges < run.totalCount){
                agesData[agesData.length - 1].total = run.totalCount - totalCountAges;
            }
        }
        
        this.setState({ runOptions, countriesData, countriesDataCodes, selectedRun: run.id, lineCountries, hasLoyaltyData, loyaltyLevelsData, tagsData, agesData });
    }

    createRun = () => {
        this.setState({ block: true }, () => {
            const { campaignTarget } = this.props;
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        this.setState({ block: false }, () => handleNotification(data));
                    }
                    if(data.response && data.response[0]){
                        this.setState({ block: false }, () => {
                            handleNotification(data, <FormattedMessage id="CampaignTargets.Saved" />, <FormattedMessage id="generic.success" />);
                        });
                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/Marketing/v1/CampaignTargets/Schedule/${campaignTarget?.id}`); 
        });
    }

    saveCampaignTargets = (e) => {
        const { campaignTarget } = this.state;
        e.preventDefault();
        this.setState({ block: true }, () => {
            const payload = {...campaignTarget};
            payload.params = JSON.stringify(payload.params);

            if(!payload.id){
                payload.active = true;
            }

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        this.setState({ block: false }, () => handleNotification(data));
                    }
                    if(data.response && data.response[0]){
                        this.setState({ block: false }, () => {
                            if(!payload.id)
                                window.history.replaceState(null, '', '/Targets/' + data.response[0].id);

                            handleNotification(data, <FormattedMessage id="CampaignTargets.Saved" />, <FormattedMessage id="generic.success" />);
                        });
                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/Marketing/v1/CampaignTargets`, payload);
        });
    }

    handleChange = (e) => {
        const { campaignTarget } = this.state;
        campaignTarget[e.target.name] = e.target.value;
        this.setState({ campaignTarget });
    }

    handleSelect = (name, combo) => {
        const { campaignTarget } = this.state;
        campaignTarget[name] = combo?.value;
        this.setState({ campaignTarget });
    }

    handleChangeRun = (combo) => {
        this.setState({ selectedRun: combo?.value }, () => this.formatData());
    }

    render() {
        const { block, error, runOptions, selectedRun, countriesData, hasLoyaltyData, tagsData, loyaltyLevelsData, agesData, campaignTarget, countriesDataCodes } = this.state;

        var colorLegend = [
            {
                color: "#CF53FA",
                textColor: '#ffffff'
            },
            {
                color: "#FFBE2D",
                textColor: '#ffffff'
            },
            {
                color: "#0867FA",
                textColor: '#ffffff'
            },
            {
                color: "#4CC9F0",
                textColor: '#ffffff'
            }
        ];

        const levelColors = [ '#CF53FA', '#FFBE2D', '#0867FA', '#4CC9F0' ];
        const withColors = [ '#f5f5f5', '#4cc9f0' ];

        const totalUniverse = campaignTarget?.targetDetails?.find(({ id }) => id === selectedRun)?.totalCount ?? 0;

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                <Card className='border-0 shadow'>
                    <CardBody className='pt-2 pb-3'>
                        <Row className='mt-3'>
                            <Col>
                                <div className='hoverShow'>
                                    <h5 className='m-0'>
                                        <i className="fab fa-searchengin mr-1" />
                                        <FormattedMessage id="CampaignTargets.Details"/>
                                    </h5>
                                    {totalUniverse ?
                                        <div className='text-muted mt-2'>
                                            <FormattedMessage id="CampaignTargets.TotalUniverse"/>
                                            <b className='ml-1'>{totalUniverse}</b>
                                        </div>
                                    :''}
                                </div>
                            </Col>
                            <Col className='col-4'>
                                {runOptions && runOptions.length > 0 ?
                                    <div className='d-flex flex-column w-100'>
                                        <div >
                                            <div className='title-sm'>
                                                <FormattedMessage id="CampaignTargets.Run"/>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-row align-items-center'>
                                            <div className='w-100'>
                                                <CustomSelect
                                                    icon={"fas fa-code-branch"}
                                                    options={runOptions}
                                                    isClearable={false}
                                                    value={selectedRun ? runOptions.find(({value}) => value === selectedRun) : null}
                                                    isSearchable={false}
                                                    onChange={this.handleChangeRun}
                                                />
                                            </div>
                                            <div className='ml-3'>
                                                {campaignTarget?.id && runOptions && runOptions.length > 0 ?
                                                    <Button onClick={this.createRun} className='btn-sm btn-host'>
                                                        <i className="fas fa-sync-alt"/>
                                                    </Button>
                                                :''}
                                            </div>
                                        </div>
                                    </div>
                                :''}
                            </Col>
                        </Row>
                            
                        {runOptions && runOptions.length > 0 ?
                            <Row className='mt-2'>
                                <Col>
                                    {tagsData ?
                                        <>
                                            <div className='title-sm'>
                                                <FormattedMessage id="CampaignTargets.Tags"/>
                                            </div>
                                            <div className='mt-1 h-100 d-flex' style={{ maxHeight: '330px' }}>
                                                <ReactBubbleChart
                                                    data={tagsData}
                                                    className
                                                    colorLegend={colorLegend}
                                                    selectedColor="#CF53FA"
                                                    selectedTextColor="#ffffff"
                                                />
                                            </div>
                                        </>
                                    :''}
                                </Col>
                                <Col style={{minWidth: '30%'}}>
                                    {agesData ?
                                        <>
                                            <div className='title-sm'>
                                                <FormattedMessage id="Home.DistributionByGeneration"/>
                                            </div>
                                            <div className='mt-1' style={{ fontSize: '0.8em' }}>
                                                {agesData.map((gen, key) => {
                                                    const percentage = gen.total !== 0 ? `${((gen.total * 100) / (campaignTarget.targetDetails.find(({id}) => id === selectedRun)?.totalCount??0)).toFixed(2)}%` : '0';

                                                    return <Row className="mb-2" key={key}>
                                                        <Col className="pr-1 col-4">
                                                            <b> {gen.name} </b> <br /> {gen.start} - {gen.end}
                                                        </Col>
                                                        <Col className="w-100 h-100 pl-0 d-flex align-items-center">
                                                            {gen.total > 0 ?
                                                                <>
                                                                    <div style={{ width: percentage, borderRadius: '5px', height: '25px' }} className="bg-host text-right mr-2">
                                                                        <span className="ml-5" style={{ color: 'transparent' }}>{gen.total} </span>
                                                                    </div>
                                                                    {gen.total}
                                                                </>
                                                            : ''}
                                                        </Col>
                                                    </Row>
                                                })}
                                            </div>
                                        </>
                                    :''}
                                </Col>
                                <Col className=' mt-2'>
                                    {loyaltyLevelsData || hasLoyaltyData ?
                                        <>
                                            <div className='title-sm'>
                                                <FormattedMessage id="CampaignTargets.Loyalty"/>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between mt-1'>
                                                {loyaltyLevelsData ?
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div style={{ height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column-reverse' }}>
                                                            {loyaltyLevelsData.map((l, key) => {
                                                                const total = loyaltyLevelsData.reduce((a, b) => a + b.count, 0)??0;
                                                                const min = 2; // Min height to assure labels are readable
                                                                const allBeforeHeight = (loyaltyLevelsData.filter((d, k) => k < key).reduce((a, b) => a + (b.count < min ? min : b.count), 0)??0);
                                                                const height = l.count !== 0 ? parseFloat((((l.count + allBeforeHeight) * 100) / total).toFixed(2)) : 0;

                                                                return (
                                                                    <div
                                                                        key={key}
                                                                        style={{
                                                                            background: levelColors.at(key),
                                                                            height: height < min ? `${min}%` : `${height}%`,
                                                                            width: '100px',
                                                                            borderRadius: key === (loyaltyLevelsData.length - 1) ? '2px 2px 0 0' : !key ? '0 0 2px 2px' : ''
                                                                        }}
                                                                        className='text-center d-flex align-items-center justify-content-center'
                                                                    />
                                                                )}
                                                            )}
                                                        </div>
                                                        <div className='ml-3' style={{ height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column-reverse' }}>
                                                            {loyaltyLevelsData.map((l, key) => {
                                                                const total = loyaltyLevelsData.reduce((a, b) => a + b.count, 0)??0;
                                                                const percentage = l.count !== 0 ? parseFloat(((l.count * 100) / total).toFixed(2)) : 0;
                                                                
                                                                const min = 10; // Min height to assure labels are readable
                                                                const allBeforeHeight = (loyaltyLevelsData.filter((d, k) => k < key).reduce((a, b) => a + (b.count < min ? min : b.count), 0)??0);
                                                                const height = l.count !== 0 ? parseFloat((((l.count + allBeforeHeight) * 100) / total).toFixed(2)) : 0;

                                                                return (
                                                                    <div className='mt-1 w-100' style={{ height: height < min ? `${min}%` : `${height}%` }}>
                                                                        <div style={{ fontSize: '0.8em' }}>
                                                                            <div className='d-flex align-items-center'>
                                                                                <div style={{ background: levelColors.at(key), width: '6px', height: '6px' }} className='mr-1'/>
                                                                                <b>{l.key}</b>
                                                                            </div>
                                                                            <div>
                                                                                <span className='mr-1'>{percentage}%</span>
                                                                                (<b>{l.count}</b>)
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            )}
                                                        </div>
                                                    </div>
                                                :''}
                                                {hasLoyaltyData ?
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div style={{ height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column-reverse' }}>
                                                            {hasLoyaltyData.map((l, key) => {
                                                                const total = hasLoyaltyData.reduce((a, b) => a + b.count, 0)??0;
                                                                const min = 2; // Min height to assure labels are readable
                                                                const allBeforeHeight = (hasLoyaltyData.filter((d, k) => k < key).reduce((a, b) => a + (b.count < min ? min : b.count), 0)??0);
                                                                const height = l.count !== 0 ? parseFloat((((l.count + allBeforeHeight) * 100) / total).toFixed(2)) : 0;
                                                                
                                                                return (
                                                                    <div
                                                                        key={key}
                                                                        style={{
                                                                            background: withColors.at(key),//'#4cc9f0',
                                                                            height: height < min ? `${min}%` : `${height}%`,
                                                                            width: '100px',
                                                                            borderRadius: key === (hasLoyaltyData.length - 1) ? '2px 2px 0 0' : !key ? '0 0 2px 2px' : ''
                                                                        }}
                                                                        className='text-center d-flex align-items-center justify-content-center'
                                                                    />
                                                                )}
                                                            )}
                                                        </div>
                                                        <div className='ml-3' style={{ height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column-reverse' }}>
                                                            {hasLoyaltyData.map((l, key) => {
                                                                const total = hasLoyaltyData.reduce((a, b) => a + b.count, 0)??0;
                                                                const percentage = l.count !== 0 ? parseFloat(((l.count * 100) / total).toFixed(2)) : 0;
                                                                const min = 10; // Min height to assure labels are readable
                                                                const allBeforeHeight = (hasLoyaltyData.filter((d, k) => k < key).reduce((a, b) => a + (b.count < min ? min : b.count), 0)??0);
                                                                const height = l.count !== 0 ? parseFloat((((l.count + allBeforeHeight) * 100) / total).toFixed(2)) : 0;
                                                                
                                                                return (
                                                                    <div className='mt-1 w-100' style={{ height: height < min ? `${min}%` : `${height}%` }}>
                                                                        <div style={{ fontSize: '0.8em' }}>
                                                                            <div className='d-flex align-items-center'>
                                                                                <div style={{ background: withColors.at(key), width: '6px', height: '6px' }} className='mr-1'/>
                                                                                <b>{l.key}</b>
                                                                            </div>
                                                                            <div>
                                                                                <span className='mr-1'>{percentage}%</span>
                                                                                (<b>{l.count}</b>)
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            )}
                                                        </div>
                                                    </div>
                                                :''}
                                            </div>
                                        </>
                                    :''}
                                </Col>
                                <Col className='col-12 mt-2'>
                                    {countriesData && countriesData.length > 0 ?
                                        <>
                                            <div className='title-sm'>
                                                <FormattedMessage id="CampaignTargets.Nationalities"/>
                                            </div>
                                            <div className='mt-1'>
                                                <GeoChart
                                                    big={true}
                                                    data={countriesData}
                                                    label={countriesDataCodes}
                                                />
                                            </div>
                                        </>
                                    :''}
                                </Col>
                            </Row>
                        :
                            <Row className='mt-2 text-muted text-center'>
                                <Col>
                                    <b><FormattedMessage id="CampaignTargets.NoData"/></b>
                                </Col>
                            </Row>
                        }
                    </CardBody>
                </Card>
            </BlockUi>
        )
    }
}

export default injectIntl(CampaignTargetDetails);