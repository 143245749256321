import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Label, Col, Row, Input, Collapse } from 'reactstrap';
import { CommonHelper } from '../../Base/CommonUIComponents';
import CustomSelect from '../../Base/CustomSelect';

class WalletCardElementsConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            barcodeTypeOptions: [
                { value: 'CODE_128', label: 'Code 128' },
                { value: 'QR_Code', label: 'QR Code' },
            ],
            barcodeValueOptions: [
                { value: 'firstName', label: this.props.intl.formatMessage({ id: 'WalletCardConfig.FirstName' }) },
                { value: 'lastName', label: this.props.intl.formatMessage({ id: 'WalletCardConfig.LastName' }) },
                { value: 'loyaltyCardNumber', label: this.props.intl.formatMessage({ id: 'WalletCardConfig.LoyaltyCardNumber' }) },
                { value: 'loyaltyPoints', label: this.props.intl.formatMessage({ id: 'WalletCardConfig.LoyaltyCardPoints' }) },
                { value: 'profileId', label: this.props.intl.formatMessage({ id: 'WalletCardConfig.ProfileId' }) },
            ],
            openElements: ['0', '1']
        }
    }

    componentDidMount() {
    }

    handleCardChange = (name, value) => {
        const { walletCardConfig } = this.props;
        
        if(name === 'barCode' && !value) {
            const updatedConfig = {
                ...walletCardConfig,
                barCode: false,
                barCodeType: "",
                barCodeEncodedContent: "",
                barCodeText: ""
            };
            this.props.changeWalletCardConfig(updatedConfig);
            return;
        }

        const updatedConfig = {
            ...walletCardConfig,
            [name]: value
        };

        this.props.changeWalletCardConfig(updatedConfig);
    };
    
    handleFieldsChange = (fieldCode, key, value) => {
        const { walletCardConfig } = this.props;
    
        let updatedFieldLocation = walletCardConfig?.fieldLocation || [];
        const fieldIndex = updatedFieldLocation.findIndex(field => field.fieldCode === fieldCode);
    
        if (fieldIndex !== -1) {
            updatedFieldLocation[fieldIndex] = { 
                ...updatedFieldLocation[fieldIndex], 
                [key]: value 
            };
        } else {
            updatedFieldLocation = [
                ...updatedFieldLocation, 
                {
                    fieldCode, 
                    [key]: value, 
                    fieldLabel: fieldCode,
                    fieldValuePresentationType: "",
                    fieldLocation: "" 
                }
            ];
        }
        
        const updatedConfig = { 
            ...walletCardConfig, 
            fieldLocation: updatedFieldLocation 
        };
    
        this.props.changeWalletCardConfig(updatedConfig);
    };
    
    handleOpenElements = (index) => {
        const { openElements } = this.state;
        if(openElements.includes(index)) {
            const updatedElements = openElements.filter(e => e !== index);
            this.setState({ openElements: updatedElements });
        } else {
            this.setState({ openElements: [...openElements, index] });
        }
    };

    

    render() {
        const { openElements, barcodeValueOptions } = this.state;
        const { activeTab, activeConfigTab, walletCardConfig, fieldsInGoogleCard, fields, configField, programTypeOptions } = this.props;
        const disableBarcodeConfig = !walletCardConfig?.barCode;
        
        return (
            <Row>
                {!configField ?
                    activeTab === '0' ?
                        activeConfigTab === '0' ?
                            <Col>
                                <div className='py-3'>
                                    <Col className='d-flex align-items-center justify-content-between col-12'>
                                        <b><FormattedMessage id="WalletCardConfig.InThisPass" /></b>
                                        <i className={`fas fa-chevron-up cursor-pointer rotate-icon ${openElements && openElements.includes('0') ? `rotate-up` : `rotate-down`}`} onClick={() => this.handleOpenElements('0')} />
                                    </Col>
                                    <Col className='pt-2'>
                                        <Collapse isOpen={openElements && openElements.includes('0')}>
                                            <Col className='col-12 py-2 d-flex align-items-center'>
                                                <FormattedMessage id='WalletCardConfig.IssuerName' />
                                                <i style={{ fontSize: '0.8em' }} onClick={() => this.props.toggleFieldConfig('IssuerName')} className="far fa-edit ml-2 cursor-pointer editFieldBtn"></i>
                                            </Col>
                                            <Col className='col-12 py-2 d-flex align-items-center'>
                                                <FormattedMessage id='WalletCardConfig.ProgramName' />
                                                <i style={{ fontSize: '0.8em' }} onClick={() => this.props.toggleFieldConfig('ProgramName')} className="far fa-edit ml-2 cursor-pointer editFieldBtn"></i>
                                            </Col>
                                            {fieldsInGoogleCard ?
                                                fieldsInGoogleCard.map((element, index) => {
                                                    return (
                                                        <Col className='d-flex align-items-center col-12 py-2' key={index}>
                                                            <FormattedMessage id={`WalletCardConfig.${element.value}`} />
                                                            <i style={{ fontSize: '0.8em' }} className="fas fa-trash-alt ml-2 cursor-pointer deleteFieldBtn" onClick={() => this.props.removeElementFromCard(element)} />
                                                        </Col>
                                                    )
                                                })
                                            :''}
                                        </Collapse>
                                    </Col>
                                </div>
                                <hr />
                                <div className='py-3'>
                                    <Col className='d-flex align-items-center justify-content-between mb-2 col-12'>
                                        <b><FormattedMessage id="WalletCardConfig.AddFields" /></b>
                                        <i className={`fas fa-chevron-up cursor-pointer rotate-icon ${openElements && openElements.includes('1') ? `rotate-up` : `rotate-down`} cursor-pointer`} onClick={() => this.handleOpenElements('1')} />
                                    </Col>
                                    {fields ?
                                        <Col>
                                            <Collapse isOpen={openElements && openElements.includes('1')}>
                                                {fields.map((element) => {
                                                    return (
                                                        fieldsInGoogleCard && fieldsInGoogleCard.find(f => f.id === element.id) ? '' :
                                                        <Col 
                                                            className='col-12 py-2 d-flex align-items-center' 
                                                            key={element.id}
                                                            draggable="true"
                                                            onDragStart={e => this.props.onDragStart(e, element)}
                                                        >
                                                            <i className="fas fa-grip-horizontal mr-3" style={{ cursor: 'grab' }}></i><FormattedMessage id={`WalletCardConfig.${element.value}`} />
                                                        </Col>
                                                    )
                                                })}
                                            </Collapse>
                                        </Col>
                                    :''}
                                </div>
                            </Col>
                        : activeConfigTab === '1' ?
                            <Col>  
                                <div className='py-3'>
                                    <Col className='col-12'>
                                        <Label><FormattedMessage id="WalletCardConfig.BackGroundColor" /> <span style={{ color: 'red' }}>*</span></Label>
                                    </Col>
                                    <Col className="d-flex align-items-center">
                                        <Col className="col-10 px-0">
                                            <Input
                                                type="text"
                                                className='noShadow'
                                                value={walletCardConfig?.backgroundColor ?? "#FFFFFF"}
                                                disabled
                                            />
                                        </Col>
                                        <Col className="col-2 pr-0">
                                            <Input
                                                className="p-0 noShadow"
                                                type="color"
                                                value={walletCardConfig?.backgroundColor ?? "#FFFFFF"}
                                                onChange={({ target }) => this.handleCardChange('backgroundColor', target?.value)}
                                                style={{ borderRadius: '10px' }}
                                            />
                                        </Col>
                                    </Col>
                                </div>

                                <hr />

                                <div className='py-3'>
                                    <Col className='col-12 d-flex align-items-center'>
                                        <Label className='mr-1'><FormattedMessage id="WalletCardConfig.Logo" /> (URL)</Label>
                                        <div className='mb-2'>
                                            <CommonHelper help={<FormattedMessage id="WalletCardConfig.GoogleLogoHelp" />} id={'googleWalletLogoHelp'} placement='right' />
                                        </div>
                                    </Col>
                                    <Col className='col-12'>
                                        <Input 
                                            type="text"
                                            value={walletCardConfig?.logoImage ?? ""}
                                            onChange={(e) => this.handleCardChange('logoImage', e?.target?.value)}
                                        />
                                    </Col>
                                </div>

                                <hr />

                                <div className='py-3'>
                                    <Col className='col-12 d-flex align-items-center'>
                                        <Label className='mr-1'><FormattedMessage id="WalletCardConfig.HeroImage" /> (URL)</Label>
                                        <div className='mb-2'>
                                            <CommonHelper help={<FormattedMessage id="WalletCardConfig.GoogleHeroImageHelp" />} id={'GoogleHeroImageHelp'} placement='right' />
                                        </div>
                                    </Col>
                                    <Col className='col-12'>
                                        <Input 
                                            type="text" 
                                            value={walletCardConfig?.heroImage ?? ""}
                                            onChange={(e) => this.handleCardChange('heroImage', e?.target?.value)}
                                        />
                                    </Col>
                                </div>
                            </Col>
                        : activeConfigTab === '2' ?
                            <Col>
                                <Col className='col-12 py-3'>
                                    <Label className='mb-0'><FormattedMessage id="WalletCardConfig.IncludeBarcode" /></Label>
                                    <Input
                                        type='checkbox'
                                        name="includeBarcode"
                                        id="includeBarcode"
                                        className='ml-2'
                                        checked={walletCardConfig?.barCode}
                                        onChange={() => this.handleCardChange('barCode', !walletCardConfig?.barCode)}
                                    />
                                </Col>

                                <hr />

                                <Col className='Col-12 py-3'>
                                    <Label><FormattedMessage id="WalletCardConfig.BarcodeType" /></Label>
                                    <CustomSelect
                                        name="barcodeType"
                                        id="barcodeType"
                                        options={this.state.barcodeTypeOptions}
                                        value={this.state.barcodeTypeOptions.find(type => type.value === walletCardConfig?.barCodeType) || ''}
                                        onChange={(combo) => this.handleCardChange('barCodeType', combo?.value)}
                                        isDisabled={disableBarcodeConfig}
                                    />
                                </Col>

                                <hr />

                                <Col className='Col-12 py-3'>
                                    <Label><FormattedMessage id="WalletCardConfig.BarcodeContent" /></Label>
                                    <CustomSelect
                                        name="barcodeContent"
                                        id="barcodeContent"
                                        options={barcodeValueOptions}
                                        value={barcodeValueOptions.find(bc => bc.value === walletCardConfig?.barCodeEncodedContent) || ''}
                                        onChange={(combo) => this.handleCardChange('barCodeEncodedContent', combo?.value)}
                                        isDisabled={disableBarcodeConfig}
                                    />
                                </Col>

                                <hr />

                                <Col className='Col-12 py-3'>
                                    <div className='d-flex align-items-center'>
                                        <Label><FormattedMessage id="WalletCardConfig.BarcodeBelowText" /></Label>
                                        <div className='mb-2'>
                                            <CommonHelper help={<FormattedMessage id="WalletCardConfig.BarcodeBelowTextHelp" />} id={'barCodeBelowTextHelp'} placement='right' />
                                        </div>
                                    </div>
                                    <CustomSelect
                                        name="barcodeBelowText"
                                        id="barcodeBelowText"
                                        options={barcodeValueOptions}
                                        value={barcodeValueOptions.find(bc => bc.value === walletCardConfig?.barCodeText) || ''}
                                        onChange={(combo) => this.handleCardChange('barCodeText', combo?.value)}
                                        isDisabled={disableBarcodeConfig}
                                        isClearable
                                    />
                                </Col>
                            </Col>
                        : activeConfigTab === '3' ?
                            <Col className='Col-12 py-3'>
                                <Label><FormattedMessage id="WalletCardConfig.CardType" /></Label>
                                <CustomSelect
                                    name="programTypeCode"
                                    id="programTypeCode"
                                    options={programTypeOptions}
                                    value={programTypeOptions.find(bc => bc.value === walletCardConfig?.programTypeCode) || ''}
                                    onChange={(combo) => this.handleCardChange('programTypeCode', combo?.value)}
                                    isSearchable
                                />
                            </Col>
                        :''
                    :
                        <Col>
                            Apple Wallet Config
                        </Col>
                :
                    <Col className='p-3'>
                        <Col className="col-12 d-flex align-items-center justify-content-between">
                            <b className='m-0'>
                                <FormattedMessage id="WalletCardConfig.FieldConfig" />
                            </b>
                            <i className="fas fa-times cursor-pointer" onClick={() => this.props.toggleFieldConfig(null)} />
                        </Col>
                        <Col className='pt-2'>
                            <div className='py-2'>
                                <Label className='mb-1'>
                                    <FormattedMessage id={`WalletCardConfig.${configField}`} />
                                </Label>
                                <Input 
                                    type="text"
                                    value={walletCardConfig?.fieldLocation?.find(field => field?.fieldCode === configField)?.fieldValue || ''}
                                    onChange={(e) => this.handleFieldsChange(configField, 'fieldValue', e?.target?.value)}
                                />
                            </div>
                        </Col>
                    </Col>
                }
            </Row>
            
        )
    }
}

export default injectIntl(WalletCardElementsConfig);
