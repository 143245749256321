import React, { Component } from 'react';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import { Button, ButtonGroup, Col, Input, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CustomFormatDate, InitiativeStatusBadge, SalesProcessHotelCombo, SalesProcessUsersCombo, SelectCustomType, checkSalesProcessPermission, getCurrentUser, getInitiativeType } from './SalesProcessFunctions';
import moment from 'moment-timezone';
import { DateRangePicker } from 'react-dates';
import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { FormatAmountNumber, CommonHelper } from '../Base/CommonUIComponents';
import SalesInitiativeModal from './SalesInitiativeModal/SalesInitiativeModal';
import CustomSelect from '../Base/CustomSelect';

class SalesInitiativeList extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            initiativeList: [],
            initiativeName: '',
            staffIds: [],
            startDate: null,
            endDate: null,
            hotel: null,
            pageSize: 10,
            pageIndex: 0,
            modal: false,
            currentUser: getCurrentUser(),
            typeFilter: null,
            locationFilter: null,
        };
    }
    
    componentDidMount(){
        this.getQueryStringValues();
    }

    getQueryStringValues = () => {
        const search = new URLSearchParams(window.location.search);

        const hotel = search.get('hotels');
        const initiativeName = search.get('initiativeName');
        const staffIds = search.get('staffIds');
        const markets = search.get('markets');
        const startDate = search.get('startDate');
        const endDate = search.get('endDate');

        const pageIndex = search.get('page') ? search.get('page') - 1 : this.state.pageIndex;
        const pageSize = search.get('pageSize') || this.state.pageSize;
        

        this.setState({
            hotel: hotel ? hotel.split(';') : [],
            markets: markets ? markets.split(';').map(el => ({ id: el })) : [],
            initiativeName: initiativeName,
            staffIds: staffIds && staffIds.split(';') ? staffIds.split(';').map(id => parseInt(id, 10)) : [],
            startDate: startDate ? moment(startDate) : null,
            endDate: endDate ? moment(endDate) : null,
            queryString: window.location.search ? window.location.search.replace('?', '') : '',
            pageIndex,
            pageSize
        }, () => this.getInitiatives());
    }

    getInitiatives = () => {
        const { pageSize, pageIndex, initiativeName, staffIds, startDate, endDate, hotel, markets, locationFilter, typeFilter } = this.state;
        
        let qs = `?pageSize=${pageSize}&pageIndex=${pageIndex}`;
        
        if(initiativeName){
            qs += `&initiativeName=${initiativeName}`;
        }
        if(staffIds?.length){
            staffIds.forEach(element => {
                qs += `&staffIds=${element}`;
            });
        }
        if(startDate){
            qs += `&startDate=${moment(startDate).format('YYYY-MM-DD')}`;
        }
        if(endDate){
            qs += `&endDate=${moment(endDate).format('YYYY-MM-DD')}`;
        }
        if(hotel?.length){
            hotel.forEach(element => {
                qs += `&hotels=${element}`;
            });
        }
        if (markets?.length){
            markets.forEach(el => { qs += `&markets=${el.id}` });
        } 
        if(typeFilter){
            qs += `&type=${typeFilter}`;
        }

        if(locationFilter){
            qs += `&location=${locationFilter}`;
        }

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage, initiativeList: [] });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ totalInitiatives: data.total, initiativeList: data.data || [], block: false });
                return;
            }
            else this.setState({ block: false });
        }, `/api/gms/SalesProcess/v1/initiatives${qs}`);
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value })
    }

    handleDateChanges = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate });
    }

    handleHotel = (name, combo) => {
        this.setState({ hotel: combo ? combo?.map(cmb => cmb.value) : [] });
    }

    handleStaff = (name, combo) => {
        this.setState({ staffIds: combo ? combo?.map(cmb => cmb.value) : [] });
    }

    handleMarkets = (name, combo) => {
        this.setState({ [name]: combo ? combo.map(el => ({ id: el.value })) : null });
    }

    handleType = (name, combo) => {
        this.setState({ [name]: combo ? combo.value : null });
    }

    searchInitiatives = () => {
        this.setState({ block: true, pageIndex: 0 }, () => {
            let queryString = this.buildQueryString();

            this.newSearch(queryString);
        });
    }

    newSearch = (queryString) => {
        this.getInitiatives();

        if (!this.props.fromAssociateModal) {
            this.props.history.push({ pathname: `/SalesProcess`, search: `?${queryString}` });
            window.location.hash = "#SalesInitiativeList";
        }
    }

    buildQueryString = () => {
        const { hotel, initiativeName, staffIds, startDate, endDate, markets, pageSize, pageIndex } = this.state;

        const params = [`page=${pageIndex + 1}`, `pageSize=${pageSize}`];

        if (hotel && hotel.length > 0) params.push(`hotels=${hotel.join(';')}`);
        if (initiativeName) params.push(`initiativeName=${initiativeName}`);
        if (staffIds && staffIds.length > 0) params.push(`staffIds=${staffIds.join(';')}`);
        if (markets && markets.length > 0) params.push(`markets=${markets.map(x => x.id).join(';')}`);
        if (startDate && endDate) {
            params.push(`startDate=${moment(startDate).format('YYYY-MM-DD')}`);
            params.push(`endDate=${moment(endDate).format('YYYY-MM-DD')}`);
        }

        return params.length > 0 ? `${params.join('&')}` : '';
    }

    handlePageIndex = (p) => {
        const { pageIndex } = this.state;
        const pageIndexUpdated = parseInt(pageIndex + p);

        var queryString = new URLSearchParams(window.location.search);
        queryString.set("page", pageIndexUpdated + 1);

        this.setState({ pageIndex: pageIndexUpdated, block: true }, () => this.newSearch(queryString));
    }

    changePageSize = (evt) => {
        const { value } = evt.target;
        const { pageSize } = this.state;

        var queryString = new URLSearchParams(window.location.search);
        queryString.set("pageSize", value);

        if (pageSize !== value) {
            this.setState({ block: true, pageSize: value }, () => this.newSearch(queryString));
        }
    }

    toggleModal = () => {
        this.setState({ modal: !this.state.modal });
    }

    render() {
        const { error, block, initiativeList, initiativeName, staffIds, startDate, endDate, hotel, markets, pageIndex, pageSize, modal, currentUser, typeFilter, locationFilter, totalInitiatives } = this.state;
        const { intl, icon, selectInitiativeDetail, fromAssociateModal, hotelList } = this.props;

        return (
            <div>
                {modal ?
                    <SalesInitiativeModal
                        modal={modal}
                        toggleModal={this.toggleModal}
                        searchInitiatives={this.searchInitiatives}
                        hotelList={hotelList}
                    />
                :''}
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <Row>
                        <Col>
                            <div className='mb-4'>
                                {fromAssociateModal ?
                                    <h6 className='m-0'>
                                        <i className={`${icon} mr-2`} />
                                        <FormattedMessage id="SalesProcess.SalesInitiativeList" />
                                    </h6>
                                    :
                                    <h4 className='m-0'>
                                        <i className={`${icon} mr-2`} />
                                        <FormattedMessage id="SalesProcess.SalesInitiativeList" />
                                    </h4>
                                }
                            </div>
                        </Col>
                        <Col >
                            <CommonHelper help={this.props.renderActivityHelperMsg(<FormattedMessage id="SalesInitiatives.Help" />)} id={'InitiativesHelp'} />
                        </Col>

                        </Row>
                    <Row className="px-1">
                        <Col>
                            <div className='title-sm'>
                                <i className={`icon-icon-hotel mr-2`} />
                                <FormattedMessage id="SalesProcess.Hotel" />
                            </div>
                            <div>
                                <SalesProcessHotelCombo
                                    isMulti={true}
                                    isClearable={true}
                                    name='hotelIds'
                                    placeholder={intl.formatMessage({ id: "SalesProcess.Hotel" })}
                                    value={hotel}
                                    onChangeFunc={this.handleHotel}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className='title-sm'>
                                <i className="fas fa-rocket mr-2" />
                                <FormattedMessage id="SalesProcess.InitiativeName" />
                            </div>
                            <div>
                                <Input
                                    type="text"
                                    name="initiativeName"
                                    placeholder={intl.formatMessage({ id: "SalesProcess.InitiativeName" })}
                                    onChange={(e) => this.handleChange(e)}
                                    value={initiativeName || ''}
                                    maxLength="500"
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className='title-sm'>
                                <i className={`fas fa-users mr-2`} />
                                <FormattedMessage id="SalesProcess.Staff" />
                            </div>
                            <div>
                                <SalesProcessUsersCombo
                                    isMulti={true}
                                    isClearable={true}
                                    name='staffIds'
                                    placeholder={intl.formatMessage({ id: "SalesProcess.Staff" })}
                                    value={staffIds}
                                    onChangeFunc={this.handleStaff}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className='title-sm'>
                                <i className="fas fa-indent mr-2" />
                                <FormattedMessage id="SalesProcess.Markets" />
                            </div>
                            <div>
                                <SelectCustomType
                                    name="markets"
                                    onChangeFunc={e => this.handleMarkets('markets', e)}
                                    value={markets || []}
                                    placeholder={<FormattedMessage id="SalesProcess.Markets" /> }
                                    isClearable isSearchable
                                    isMulti
                                    type={'InitiativeMarketType'}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className='title-sm'>
                                <i className="fas fa-map-marker-alt mr-2" />
                                <FormattedMessage id="SalesProcess.Location" />
                            </div>
                            <div>
                                <Input
                                    type="text"
                                    name="locationFilter"
                                    placeholder={intl.formatMessage({ id: "SalesProcess.Location" })}
                                    onChange={(e) => this.handleChange(e)}
                                    value={locationFilter || ''}
                                    maxLength="500"
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className='title-sm'>
                                <i className="fas fa-list mr-2" />
                                <FormattedMessage id="SalesProcess.Type" />
                            </div>
                            <div>
                                <CustomSelect
                                    name="typeFilter"
                                    placeholder={intl.formatMessage({ id: "SalesProcess.Type" })}
                                    isClearable={true}
                                    isSearchable={true}
                                    options={getInitiativeType(intl)}
                                    value={getInitiativeType(intl)?.find(({ value }) => typeFilter === value) || ''}
                                    onChange={(combo) => this.handleType('typeFilter', combo)}
                                />
                            </div>
                        </Col>
                        <Col className="col-2">
                            <div className='title-sm'>
                                <i className="far fa-calendar-alt mr-2" />
                                <FormattedMessage id="SalesProcess.Dates" />
                            </div>
                            <div>
                                <DateRangePicker
                                    startDate={startDate}
                                    startDateId="startDateId"
                                    endDate={endDate}
                                    endDateId="endDateId"
                                    isOutsideRange={_ => false}
                                    onDatesChange={this.handleDateChanges}
                                    focusedInput={this.state.focusedInput}
                                    showClearDates={true}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    small={true}
                                    showDefaultInputIcon={true}
                                    renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                                />
                            </div>
                        </Col>
                        <Col className="col-1 d-flex align-items-center justify-content-end">
                            {!fromAssociateModal ?
                                <Button className="btn-sm btn-host" onClick={this.toggleModal}>
                                    <i className="fas fa-plus" />
                                </Button>
                                : ''}
                            <Button className="btn-sm btn-host ml-2" onClick={this.searchInitiatives}>
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>

                    {initiativeList ?
                        <div className="w-100 mt-4" style={{ fontSize: '0.9em' }}>
                            <div className='d-flex px-3 align-items-center justify-content-between shadow py-3 mb-3 bg-white text-muted' style={{ borderRadius: '4px', gap: '2%' }}>
                                <div style={{ width: '16%' }}>
                                    <FormattedMessage id="SalesProcess.Name"/>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <FormattedMessage id="SalesProcess.Location"/>
                                </div>
                                <div style={{ width: '7%' }}>
                                    <FormattedMessage id="SalesProcess.Type"/>
                                </div>
                                <div style={{ width: '7%' }}>
                                    <FormattedMessage id="SalesProcess.ActionType"/>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <FormattedMessage id="SalesProcess.Cost"/>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <FormattedMessage id="SalesProcess.HotelIds"/>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <FormattedMessage id="SalesProcess.Markets"/>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <FormattedMessage id="SalesProcess.SalesInitiativeUsers"/>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <FormattedMessage id="SalesProcess.Dates"/>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <FormattedMessage id="SalesProcess.Status"/>
                                </div>
                            </div>
                            <div className='mt-2'>
                                {initiativeList.map((init, key) => {
                                    const hasAccess = checkSalesProcessPermission('SalesInitiativeDetail', currentUser?.role, init.hotelIds, currentUser?.hotelList);

                                    return (
                                        <div onMouseDown={(e) => {
                                            if (e?.button !== 2 && hasAccess) {
                                                const isScrollClick = e?.button === 1;
                                            
                                                if (fromAssociateModal) {
                                                    if (!isScrollClick) {
                                                        selectInitiativeDetail(init);
                                                    }
                                                } else {
                                                    selectInitiativeDetail(init.id, "SalesInitiativeList", isScrollClick);
                                                }
                                            }
                                          }} className={'px-3 d-flex align-items-center justify-content-between shadow py-3 mb-2 bg-white ' + (hasAccess && ' cursor-pointer ')} key={key} style={{ borderRadius: '4px', gap: '2%' }}>
                                            <div className='text-truncate' style={{ width: '16%' }}>
                                                {init.name ?? '-'}
                                            </div>
                                            <div className='text-truncate' style={{ width: '10%' }}>
                                                {init.location ?? '-'}
                                            </div>
                                            <div className='text-truncate' style={{ width: '7%' }}>
                                                {init.type ?? '-'}
                                            </div>
                                            <div className='text-truncate' style={{ width: '7%' }}>
                                                {init.actionType ?? '-'}
                                            </div>
                                            <div className='text-truncate' style={{ width: '10%' }}>
                                                {init.cost ?
                                                    <FormatAmountNumber value={init.cost} />
                                                    : '-'}
                                            </div>
                                            <div className='text-truncate' style={{ width: '10%' }}>
                                                {init.hotelIds ?
                                                    <div style={{ maxHeight: '22px' }} onMouseDown={(e) => init.hotelIds.length > 1 ? e.stopPropagation() : undefined} className='overflow-auto veryCoolScrollBar'>
                                                        {init.hotelIds.map((id, key) => {
                                                            const hotel = global.hotelList?.find(x => x.value === id);

                                                            return (
                                                                <div key={key} className={key ? 'mt-1 text-truncate' : 'text-truncate'}
                                                                    onMouseDown={(e) => {
                                                                        if (e?.button !== 2 && hasAccess) {
                                                                            const isScrollClick = e?.button === 1;

                                                                            if (fromAssociateModal) {
                                                                                if (!isScrollClick) {
                                                                                    selectInitiativeDetail(init);
                                                                                }
                                                                            } else {
                                                                                selectInitiativeDetail(init.id, "SalesInitiativeList", isScrollClick);
                                                                            }
                                                                        }
                                                                    }}>
                                                                    {hotel?.label || id}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    : '-'}
                                            </div>
                                            <div className='text-truncate' style={{ width: '10%' }}>
                                                {init.markets ?
                                                    <div style={{ maxHeight: '22px' }} onMouseDown={(e) => init.markets.length > 1 ? e.stopPropagation() : undefined} className='overflow-auto veryCoolScrollBar'>
                                                        {init.markets.map((market, key) =>
                                                            <div key={key} className={key ? 'mt-1 text-truncate' : 'text-truncate'}
                                                                onMouseDown={(e) => {
                                                                    if (e?.button !== 2 && hasAccess) {
                                                                        const isScrollClick = e?.button === 1;

                                                                        if (fromAssociateModal) {
                                                                            if (!isScrollClick) {
                                                                                selectInitiativeDetail(init);
                                                                            }
                                                                        } else {
                                                                            selectInitiativeDetail(init.id, "SalesInitiativeList", isScrollClick);
                                                                        }
                                                                    }
                                                                }}>
                                                                {market.name}
                                                            </div>
                                                        )}
                                                    </div>
                                                    : '-'}
                                            </div>
                                            <div className='text-truncate' style={{ width: '10%' }}>
                                                {init.salesInitiativeUsers ?
                                                    <div style={{ maxHeight: '22px' }} onMouseDown={(e) => init.salesInitiativeUsers.length > 1 ? e.stopPropagation() : undefined} className='overflow-auto veryCoolScrollBar'>
                                                        {init.salesInitiativeUsers.map((user, k) =>
                                                            <div key={k}>
                                                                <div className={k ? 'mt-1 text-truncate' : 'text-truncate'}
                                                                    onMouseDown={(e) => {
                                                                        if (e?.button !== 2 && hasAccess) {
                                                                            const isScrollClick = e?.button === 1;

                                                                            if (fromAssociateModal) {
                                                                                if (!isScrollClick) {
                                                                                    selectInitiativeDetail(init);
                                                                                }
                                                                            } else {
                                                                                selectInitiativeDetail(init.id, "SalesInitiativeList", isScrollClick);
                                                                            }
                                                                        }
                                                                    }}>
                                                                    {user.name}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    : '-'}
                                            </div>
                                            <div className='text-truncate' style={{ width: '10%' }} title={`${init.startDate ? CustomFormatDate(init.startDate, null, null, intl) : ''}${init.endDate ? ` - ${CustomFormatDate(init.endDate, null, null, intl)}` : ''}`}>
                                                {init.startDate && init.endDate ?
                                                    <>
                                                        {init.startDate ?
                                                            init.endDate ?
                                                                `${CustomFormatDate(init.startDate, null, null, intl)} - `
                                                                :
                                                                CustomFormatDate(init.startDate, null, null, intl)
                                                            : ''}
                                                        {init.endDate ?
                                                            CustomFormatDate(init.endDate, null, null, intl)
                                                            : ''}
                                                    </>
                                                    : '-'}
                                            </div>
                                            <div className='text-truncate' style={{ width: '10%' }}>
                                                <InitiativeStatusBadge
                                                    status={init.status}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                                <Row className="px-3 mt-3 mb-3">
                                    <Col className="pl-0">
                                        <ButtonGroup onClick={this.changePageSize}>
                                            <Button className={(pageSize == 10 ? "bg-host text-white" : "text-secondary") + " page-link"} value={10}> 10 </Button>
                                            <Button className={(pageSize == 25 ? "bg-host text-white" : "text-secondary") + " page-link"} value={25}> 25 </Button>
                                            <Button className={(pageSize == 30 ? "bg-host text-white" : "text-secondary") + " page-link"} value={30}> 30 </Button>
                                            <Button className={(pageSize == 50 ? "bg-host text-white" : "text-secondary") + " page-link"} value={50}> 50 </Button>
                                        </ButtonGroup>
                                        <span className="text-muted ml-2" style={{ fontSize: '0.8em' }}>
                                            <FormattedMessage id="generic.totalValuesMessage" values={{
                                                0: parseInt(pageIndex * pageSize + 1),
                                                1: totalInitiatives < parseInt((pageIndex + 1) * pageSize) ? totalInitiatives : parseInt((pageIndex + 1) * pageSize),
                                                2: totalInitiatives
                                            }} />
                                        </span>
                                    </Col>
                                    
                                    <Col className="text-right pr-0">
                                        <span>
                                            <Button className="btn btn-host btn-sm mr-2" onClick={_ => this.handlePageIndex(-1)} disabled={pageIndex === 0}>
                                                <i className="fas fa-angle-left" />
                                            </Button>
                                            <span className="mr-2">
                                                <FormattedMessage id="SearchProfile.Page" values={{ page: parseInt(pageIndex + 1) }} />
                                            </span>
                                            <Button className="btn btn-host btn-sm" onClick={_ => this.handlePageIndex(1)} disabled={totalInitiatives <= pageSize * (pageIndex +1)}>
                                                <i className="fas fa-angle-right" />
                                            </Button>
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    :''}
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(SalesInitiativeList);
