import React, { Component } from 'react';
import { Button, Col, CustomInput, Form, Input, Modal, ModalBody, Row } from 'reactstrap';
import { BlankCard } from '../../Base/CommonUIComponents';
import { FormattedMessage } from 'react-intl';
import CustomSelect from '../../Base/CustomSelect';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment-timezone';
import ConfirmActionModal from '../../Base/ConfirmActionModal';

class SpacePopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            spaceData: {},
            timeOptions: this.getArray(24).flatMap((d, index) => {
                const hour = index.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                })
                return (
                    [{
                        label: `${hour}:00`,
                        value: `${hour}:00`,
                    },
                    {
                        label: `${hour}:15`,
                        value: `${hour}:15`,
                    },
                    {
                        label: `${hour}:30`,
                        value: `${hour}:30`,
                    },
                    {
                        label: `${hour}:45`,
                        value: `${hour}:45`,
                    }]
                );
            }),
            confirmModal: false
        };
    }

    getArray(size) {
        const array = [];
        for (let i = 0; i < size; i++) {
            array.push(i);
        }
        return array;
    }

    componentDidMount() {
        const { spaceReservationId, spaceList, eventData, spacesOptions } = this.props;
        
        let spaceData = {};
        if(!spaceReservationId){
            spaceData = {
                pax: eventData.adults,
                children: eventData.children1,
                children2: eventData.children2,
                startDate: moment(eventData.fromDate),
                startHour: moment(eventData.fromDate).format('HH:mm'),
                endDate: moment(eventData.fromDate).add(1, 'hour'),
                endHour: moment(eventData.fromDate).add(1, 'hour').format('HH:mm')
            };
        }
        else{
            spaceData = spaceList?.find((s) => s.spaceReservationId === spaceReservationId);
            
            if(spaceData){
                const currentSpace = spacesOptions.find(({description}) => description === spaceData.spaceName);
                
                if(!currentSpace)
                    return;

                spaceData.id = spaceData.id ?? spaceData.spaceReservationId;
                spaceData.children = spaceData.children ?? spaceData.children1;
                spaceData.pax = spaceData.pax ?? spaceData.adults;
                spaceData.spaceCode = currentSpace.code;
                spaceData.statusCode = spaceData.reservationStatusCode;
                spaceData.startDate = spaceData.fromDate ? moment(spaceData.fromDate) : null;
                spaceData.endDate = spaceData.toDate ? moment(spaceData.toDate) : null;
                spaceData.startHour = spaceData.startDate?.format('HH:mm') ?? null;
                spaceData.endHour = spaceData.endDate?.format('HH:mm') ?? null;
            }
        }
            
        this.setState({ spaceData });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ block: true }, () => this.props.saveSpace(this.state.spaceData, (success) => {
            this.setState({ block: false }, () => {
                if(success){
                    this.props.toggleDetailPopup();
                }
            })
        }));
    }

    handleComboChange = (combo, name) => {
        this.setState({
            spaceData: {
                ...this.state.spaceData,
                [name]: combo?.value
            }
        });
    }

    handleDateChange = (date, name) => {
        this.setState({
            spaceData: {
                ...this.state.spaceData,
                [name]: date
            }
        });
    }

    handleChange = (e) => {
        const { value, name } = e.target;
        this.setState({
            spaceData: {
                ...this.state.spaceData,
                [name]: value
            }
        });
    }

    toggleConfirmModal = () => {
        this.setState({ confirmModal: !this.state.confirmModal });
    }

    removeSpace = () => {
        this.setState({ block: true }, () => {
            this.props.removeSpace(this.state.spaceData, (success) => {
                this.setState({ block: false, confirmModal: !success }, () => {
                    if(success){
                        this.props.toggleDetailPopup();     
                    }
                });
            });
        });
    }

    isOutsideRange = (day) => {
        const { eventData } = this.props;

        if(!eventData?.fromDate || !eventData?.toDate)
            return false;

        if(day.isBefore(eventData.fromDate) || day.isAfter(eventData.toDate))
            return true;

        return false;
    }

    handleCopyDaily = () => {
        const { spaceData } = this.state;
        this.setState({ spaceData: { ...spaceData, copyDaily: !spaceData?.copyDaily } });
    }

    render() {
        const { toggleDetailPopup, eventTypeOptions, customStatusOptions, spacesOptions, eventData } = this.props;
        const { spaceData, block, error, timeOptions, confirmModal } = this.state;

        return (
            <Modal isOpen={true} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={toggleDetailPopup} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.handleSubmit}>
                            <Row>
                                <Col className='col-6'>
                                    <div>
                                        <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                            <FormattedMessage id="SalesProcess.Space"/>
                                        </div>
                                        <CustomSelect
                                            required={true}
                                            options={spacesOptions}
                                            value={spacesOptions.find(({value}) => value === spaceData.spaceCode)}
                                            onChange={e => this.handleComboChange(e, 'spaceCode')}
                                            placeholder={""}
                                        />
                                    </div>
                                </Col>
                                <Col className='col-6'>
                                    {!spaceData.id ?
                                        <div className='d-flex align-items-center justify-content-end'>
                                            <CustomInput
                                                type="checkbox"
                                                id="popOverCopyDaily"
                                                className="text-ellipsis"
                                                checked={spaceData.copyDaily}
                                                onChange={this.handleCopyDaily}
                                                disabled={spaceData.startDate?.format('DD') !== spaceData.endDate?.clone().add(-1, 'minute').format('DD')}
                                            />
                                            <FormattedMessage id="SalesProcess.CopySpaceDaily"/>
                                        </div>
                                    : spaceData.spaceReservationParentId ?
                                        <div>
                                            <FormattedMessage id="SalesProcess.SubEvent"/>
                                        </div>
                                    :''}
                                </Col>

                                <Col className="col-6 mt-3">
                                    <div className='title-sm required' style={{ fontSize: '0.9em' }}>
                                        <FormattedMessage id="SalesProcess.ServiceType"/>
                                    </div>
                                    <CustomSelect
                                        required={true}
                                        options={eventTypeOptions}
                                        value={eventTypeOptions.find(({value, description}) => (!spaceData.serviceTypeCode ? description === spaceData.spaceTypeDescription : value === spaceData.serviceTypeCode))}
                                        onChange={e => this.handleComboChange(e, 'serviceTypeCode')}
                                        placeholder={""}
                                    />
                                </Col>
                                <Col className="col-6 mt-3">
                                    <div className='title-sm required' style={{ fontSize: '0.9em' }}>
                                        <FormattedMessage id="SalesProcess.Status"/>
                                    </div>
                                    <CustomSelect
                                        required={true}
                                        options={customStatusOptions}
                                        value={customStatusOptions.find(({value}) => value === spaceData.statusCode)}
                                        onChange={e => this.handleComboChange(e, 'statusCode')}
                                        placeholder={""}
                                    />
                                </Col>

                                <Col className="col-6 mt-3">
                                    <div className='title-sm required' style={{ fontSize: '0.9em' }}>
                                        <FormattedMessage id="SalesProcess.StartDate"/>
                                    </div>
                                    <SingleDatePicker
                                        id="StartDate"
                                        isOutsideRange={this.isOutsideRange}
                                        date={spaceData.startDate}
                                        focused={this.state.focused}
                                        onFocusChange={({ focused }) => this.setState({ focused })}
                                        small={true}
                                        required={true}
                                        numberOfMonths={1}
                                        showDefaultInputIcon={true}
                                        onDateChange={e => this.handleDateChange(e, 'startDate')}
                                        showClearDate={true}
                                        initialVisibleMonth={() => moment(eventData.fromDate)}
                                    />
                                </Col>
                                <Col className="col-6 mt-3">
                                    <div className='title-sm required' style={{ fontSize: '0.9em', visibility: 'hidden' }}>
                                        <FormattedMessage id="SalesProcess.StartDate"/>
                                    </div>
                                    <CustomSelect
                                        required={true}
                                        options={timeOptions}
                                        value={timeOptions.find(({value}) => value === spaceData.startHour)}
                                        onChange={e => this.handleComboChange(e, 'startHour')}
                                        placeholder={""}
                                    />
                                </Col>

                                <Col className="col-6 mt-3">
                                    <div className='title-sm required' style={{ fontSize: '0.9em' }}>
                                        <FormattedMessage id="SalesProcess.EndDate"/>
                                    </div>
                                    <SingleDatePicker
                                        id="EndDate"
                                        isOutsideRange={this.isOutsideRange}
                                        date={spaceData.endDate}
                                        focused={this.state.focused2}
                                        required={true}
                                        onFocusChange={({ focused }) => this.setState({ focused2: focused })}
                                        small={true}
                                        numberOfMonths={1}
                                        showDefaultInputIcon={true}
                                        onDateChange={e => this.handleDateChange(e, 'endDate')}
                                        showClearDate={true}
                                        initialVisibleMonth={() => moment(eventData.toDate)}
                                    />
                                </Col>
                                <Col className="col-6 mt-3">
                                    <div className='title-sm required' style={{ fontSize: '0.9em', visibility: 'hidden' }}>
                                        <FormattedMessage id="SalesProcess.EndDate"/>
                                    </div>
                                    <CustomSelect
                                        required={true}
                                        options={timeOptions}
                                        value={timeOptions.find(({value}) => value === spaceData.endHour)}
                                        onChange={e => this.handleComboChange(e, 'endHour')}
                                        placeholder={""}
                                    />
                                </Col>

                                <Col className="col-4 mt-3">
                                    <div className='title-sm required' style={{ fontSize: '0.9em' }}>
                                        <FormattedMessage id="SalesProcess.Adults"/>
                                    </div>
                                    <Input
                                        type='number'
                                        required={true}
                                        name="pax"
                                        value={spaceData.pax}
                                        min={0}
                                        onChange={this.handleChange}
                                        style={{ backgroundColor: (spaceData.maxCapacity < spaceData.pax || eventData.adults < spaceData.pax) ? 'rgb(241, 172, 178)' : '' }}
                                    />
                                </Col>
                                <Col className="col-4 mt-3">
                                    <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                        <FormattedMessage id="SalesProcess.Children"/>
                                    </div>
                                    <Input
                                        type='number'
                                        name="children"
                                        min={0}
                                        style={{ backgroundColor: (spaceData.maxCapacity < spaceData.children || eventData.children < spaceData.children) ? 'rgb(241, 172, 178)' : '' }}
                                        value={spaceData.children}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                <Col className="col-4 mt-3">
                                    <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                        <FormattedMessage id="SalesProcess.Children2"/>
                                    </div>
                                    <Input
                                        type='number'
                                        name="children2"
                                        min={0}
                                        style={{ backgroundColor: (spaceData.maxCapacity < spaceData.children2 || eventData.children2 < spaceData.children2) ? 'rgb(241, 172, 178)' : ''}}
                                        value={spaceData.children2}
                                        onChange={this.handleChange}
                                    />
                                </Col>

                                <Col className="col-12 mt-3">
                                    <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                        <FormattedMessage id="SalesProcess.DailyPrice"/>
                                    </div>
                                    <Input
                                        type='number'
                                        name="dailyPrice"
                                        value={spaceData.dailyPrice}
                                        onChange={this.handleChange}
                                    />
                                </Col>

                                <Col className="col-12 mt-3">
                                    <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                        <FormattedMessage id="SalesProcess.Notes"/>
                                    </div>
                                    <Input
                                        style={{ minHeight: '100px' }}
                                        type='textarea'
                                        name="notes"
                                        onChange={this.handleChange}
                                        value={spaceData.notes}
                                    />
                                </Col>
                            </Row>
                            <div className='mt-3 d-flex align-items-center justify-content-between'>
                                {!spaceData.id ?
                                    <Button className="w-100 py-2 btn btn-host btn-sm btn btn-secondary" type='submit'>
                                        <div style={{ marginTop: '-1px' }}>
                                            <FormattedMessage id="SalesProcess.CreateSpace"/>
                                            <i className='fas fa-plus ml-2'/>
                                        </div>
                                    </Button>
                                :
                                    <>
                                        <Button className="w-100 py-2 btn btn-host btn-sm btn btn-secondary" type='submit'>
                                            <div style={{ marginTop: '-1px' }}>
                                                <FormattedMessage id="SalesProcess.SaveSpace"/>
                                                <i className='fas fa-save ml-2'/>
                                            </div>
                                        </Button>
                                        <Button onClick={this.toggleConfirmModal} className="ml-3 py-2 btn btn-host btn-sm btn btn-secondary bg-danger border-danger" type='button' style={{ width: '7%' }}>
                                            <div style={{ marginTop: '-1px' }}>
                                                <i className='fas fa-trash'/>
                                            </div>
                                        </Button>
                                    </>
                                }
                            </div>
                        </Form>
                        {confirmModal ?
                            <ConfirmActionModal
                                modal={confirmModal}
                                toggleModal={_ => this.toggleConfirmModal()}
                                actionFunction={this.removeSpace}
                                text={<FormattedMessage id="SendGrid.ConfirmRemoveSpace"/>}
                                block={block}
                                error={null}
                            />
                        :''}
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}

export default SpacePopup;
