import React, { Component } from 'react';
import { Row, Col, Card } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { Doughnut } from 'react-chartjs-2';
import { Chart } from 'chart.js';

class ProfileSummaryCardData extends Component {

    constructor(props) {
        super(props);
        this.state = this.getSummaryCardData(this.props.criteria, this.props.unfilled)
    }

    getSummaryCardData = (criteria, unfilled) => {
        const summaryCardData = {
            errors: criteria.results?.length > 0 ? criteria.results[0].filled - criteria.results[0].valid : 0,
            filled: criteria.results && criteria.results[0]?.filled,
            unfilled: unfilled,
            valid: criteria.results && criteria.results[0]?.valid
        }

        return summaryCardData;
    }

    shouldComponentUpdate(nextProps) {
        const { errors, filled, unfilled, valid } = this.getSummaryCardData(nextProps.criteria, nextProps.unfilled);

        if (errors === this.state.errors && filled === this.state.filled && unfilled === this.state.unfilled && valid === this.state.valid) {
            return false;
        }

        this.setState({ errors, filled, unfilled, valid });

        return true;
    }

    render() {
        const { criteria, getChartDatasets, idx, metrics, totalMasters } = this.props;
        const { errors, filled, unfilled, valid } = this.state;

        const metric = metrics.find(m => m.value === criteria.criteria);
        const pieData = getChartDatasets(totalMasters, criteria.results, errors);

        const optionsPie = {
            maintainAspectRatio: false,
            cutout: '45%',
            plugins: {
                legend: {
                    display: false,
                    position: "left",
                    labels: {
                        generateLabels: function (chart) {
                            // Get the default label list
                            const original = Chart.overrides.pie.plugins.legend.labels.generateLabels;
                            const labelsOriginal = original.call(this, chart);

                            // Build an array of colors used in the datasets of the chart
                            let datasetColors = chart.data.datasets.map(function (e) {
                                return e.backgroundColor;
                            });
                            datasetColors = datasetColors.flat();

                            // Modify the color and hide state of each label
                            labelsOriginal.forEach(label => {
                                // There are twice as many labels as there are datasets. This converts the label index into the corresponding dataset index
                                label.datasetIndex = (label.index - label.index % 2) / 2;

                                // The hidden state must match the dataset's hidden state
                                label.hidden = !chart.isDatasetVisible(label.datasetIndex);

                                // Change the color to match the dataset
                                label.fillStyle = datasetColors[label.index];
                            });

                            return labelsOriginal;
                        }
                    },
                    onClick: function (mouseEvent, legendItem, legend) {
                        // toggle the visibility of the dataset from what it currently is
                        legend.chart.getDatasetMeta(
                            legendItem.datasetIndex
                        ).hidden = legend.chart.isDatasetVisible(legendItem.datasetIndex);
                        legend.chart.update();
                    }
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            const labelIndex = (context.datasetIndex * 2) + context.dataIndex;
                            return `${context.chart.data.labels[labelIndex]}: ${context.formattedValue}%`;
                        }
                    }
                },
                datalabels: {
                    color: 'white',
                    formatter: (ctx, data) => {
                        return ctx ? ctx : '';
                    },
                    formatter: (val, context) => {
                        return `${val}%`
                    },
                },
            }
        };

        return (
            <Col sm={3} key={idx} className="mb-4">
                <Card className="border-0 shadow py-4 h-100">
                    <Row className="align-items-center justify-content-center text-muted mb-3">
                        <i className={`${metric.icon} mr-1`} />
                        <h5 className="m-0"><b> {metric.label} </b></h5>
                    </Row>

                    <div className="text-center mt-4 mb-5">
                        {pieData.datasets && pieData.datasets.length > 0 && pieData.datasets[0].data.length > 0 ?
                            <Doughnut data={pieData} height={200} options={optionsPie} />
                            : ''}
                    </div>

                    <Row>
                        <Col className="text-center pr-2" style={{ color: '#22CCE2' }}>
                            <b style={{ fontSize: '19px' }} title={this.props.intl.formatMessage({ id: "ProfileSummary.Filled" })}>
                                {/*{criteria.results && criteria.results[0]?.filled || '-'}*/}
                                {filled || '-'}
                            </b>
                            <br />
                            {/*<FormattedMessage id="ProfileSummary.Filled" />*/}
                        </Col>
                        <Col className="text-center px-2" style={{ color: '#959595' }}>
                            <b style={{ fontSize: '19px' }} title={this.props.intl.formatMessage({ id: "ProfileSummary.Unfilled" })}>
                                {unfilled || '-'}
                            </b>
                            <br />
                            {/*<FormattedMessage id="ProfileSummary.Unfilled" />*/}
                        </Col>
                        <Col className="text-center color-green px-2">
                            <b style={{ fontSize: '19px' }} title={this.props.intl.formatMessage({ id: "ProfileSummary.Valid" })}>
                                {/*{criteria.results && criteria.results[0]?.valid || '-'}*/}
                                {valid || '-'}
                            </b>
                            <br />
                            {/*<FormattedMessage id="ProfileSummary.Valid"/>*/}
                        </Col>
                        <Col className="text-center  color-light-red pl-2">
                            <b style={{ fontSize: '19px' }} title={this.props.intl.formatMessage({ id: "ProfileSummary.Errors" })}>
                                {errors || '-'}
                            </b>
                            <br />
                            {/*<FormattedMessage id="ProfileSummary.Errors" />*/}
                        </Col>
                    </Row>
                </Card>
            </Col>
        );
    }
}

export default injectIntl(ProfileSummaryCardData);