import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Card, Col, Row, Form, Progress } from 'reactstrap';
import { Crt, StyledCard, CommonHelper } from "../../Base/CommonUIComponents";
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { postAPI } from '../../Base/API';
import CustomFunnel from '../../Base/CustomFunnel';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import CustomSelect from '../../Base/CustomSelect';
import { handleNotification } from '../../Base/Notification';
import Chart from 'react-google-charts';
import * as countryList from 'react-select-country-list';
import { Line, Doughnut } from 'react-chartjs-2';
import DashboardCard from '../../Base/DashboardCard';
import { getColorPallete, getDatesRangeArray } from '../../Base/ReferenceDataFunctions';


class PNFormAnalytics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            totalFormsSubmitted: 0,
            conversionFunnel: null,
            startDate: moment().subtract(1, 'month'),
            endDate: moment(),
            usersCount: 0
        };
    }

    componentDidMount() {
        this.getFormAnalytics();
    }

    getFormAnalytics = (e) => {
        if (e) e.preventDefault();

        this.setState({ block: true });

        const param = this.state.formId ? `&formId=${this.state.formId}` : '';

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.response?.length > 0){
                    const gaResults = JSON.parse(data.response[0].payload);
                    
                    this.chartData(gaResults);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/GoogleAnalytics/v1/GetFormAnalytics?startDate=${moment(this.state.startDate).format("YYYY-MM-DD")}&endDate=${moment(this.state.endDate).format("YYYY-MM-DD")}` + param);
    }

    chartData = (json) => {
        var dates = getDatesRangeArray("YYYY-MM-DD", 'days', this.state.startDate, this.state.endDate);

        //Dimensions
        const pageReferrerIndex = json.dimensionHeaders.findIndex(el => el.name === 'pageReferrer');
        const eventNameIndex = json.dimensionHeaders.findIndex(el => el.name === 'eventName');
        const countryIndex = json.dimensionHeaders.findIndex(el => el.name === 'countryId');
        const languageIndex = json.dimensionHeaders.findIndex(el => el.name === 'language');
        const dateIndex = json.dimensionHeaders.findIndex(el => el.name === 'date');
        const deviceIndex = json.dimensionHeaders.findIndex(el => el.name === 'deviceCategory');
        const campaignIndex = json.dimensionHeaders.findIndex(el => el.name === 'sessionCampaignName');

        //Metrics
        const eventCountIndex = json.metricHeaders.findIndex(el => el.name === 'eventCount');
        const totalUsersIndex = json.metricHeaders.findIndex(el => el.name === 'totalUsers');

        //
        let totalPageViews = 0, totalFormStarted = 0, totalFormsSubmitted = 0, totalFormBounced = 0, totalFormAbandoned = 0, usersCount = 0;
        let pageReferrerList = [], usersByLanguage = [],
            dailyData = new Array(dates.length).fill(0),
            userByCountry = [[{ v: "Country Code", f: "Country Label" }, this.props.intl.formatMessage({ id: "PNFormAnalytics.Users" })]],
            dailyUsers = {},
            usersByDevice = { datasets: [{ label: "Users by Device", data: [], backgroundColor: getColorPallete() }], labels: [] },
            usersByCampaign = { datasets: [{ label: "Users by Campaign", data: [], backgroundColor: getColorPallete() }], labels: [] };

        json.rows && json.rows.forEach(row => {
            const country = row.dimensionValues[countryIndex]?.value;
            const language = row.dimensionValues[languageIndex]?.value;
            const date = row.dimensionValues[dateIndex]?.value;
            const pageReferrer = row.dimensionValues[pageReferrerIndex]?.value;
            const device = row.dimensionValues[deviceIndex]?.value;
            const campaign = row.dimensionValues[campaignIndex]?.value;
            const totalUsers = parseInt(row.metricValues[totalUsersIndex]?.value);

            //Events
            const eventName = row.dimensionValues[eventNameIndex]?.value;
            const eventCount = parseInt(row.metricValues[eventCountIndex]?.value);

            if (eventName === 'page_view') {
                totalPageViews += eventCount;

                usersCount += totalUsers;
    
                //Page Referrer
                pageReferrerList = this.fillSimpleStructure(pageReferrerList, 'url', pageReferrer, 'count', 1);
    
                //GeoMap
                userByCountry = this.fillGeoMap(userByCountry, country, totalUsers);
    
                //Daily Data
                const formatedDate = moment(date).format("YYYY-MM-DD");
                const currentDateIndex = dates.findIndex(el => el === formatedDate);
                if (currentDateIndex !== -1) dailyData[currentDateIndex] += totalUsers;
    
                //Users by Language
                usersByLanguage = this.fillSimpleStructure(usersByLanguage, 'language', language, 'totalUsers', totalUsers);
    
                //Pie Charts
                usersByDevice = this.fillPieChart(usersByDevice, device, totalUsers);
                usersByCampaign = this.fillPieChart(usersByCampaign, campaign, totalUsers);
            }
            else if (eventName === 'form_started') {
                totalFormStarted += eventCount;
            }
            else if (eventName === 'form_submitted') {
                totalFormsSubmitted += eventCount;
            }
            else if (eventName === 'form_bounce') {
                totalFormBounced += eventCount;
            }
            else if (eventName === 'form_abandoned') {
                totalFormAbandoned += eventCount;
            }
        })

        //Conversions
        let conversionFunnel = [
            {
                id: `${this.props.intl.formatMessage({ id: "PNFormAnalytics.PageView" })} \n ${totalPageViews}`,
                label: this.props.intl.formatMessage({ id: "PNFormAnalytics.PageView" }),
                value: totalPageViews
            },
            {
                id: `${this.props.intl.formatMessage({ id: "PNFormAnalytics.FormsStarted" })} \n ${totalFormStarted}`,
                label: this.props.intl.formatMessage({ id: "PNFormAnalytics.FormsStarted" }),
                value: totalFormStarted
            },
            {
                id: `${this.props.intl.formatMessage({ id: "PNFormAnalytics.FormsSubmitted" })} \n ${totalFormsSubmitted}`,
                label: this.props.intl.formatMessage({ id: "PNFormAnalytics.FormsSubmitted" }),
                value: totalFormsSubmitted
            },
        ];

        //User by day
        if (dailyData.length > 0) {
            dailyUsers = {
                labels: dates,
                datasets: [
                    { value: 'totalUsers', label: this.props.intl.formatMessage({ id: "PNFormAnalytics.TotalUsers" }), data: dailyData, backgroundColor: '#0867fa', borderColor: '#0867fa', fill: false },
                ]
            }
        }


        //Translate devices
        if (usersByDevice?.labels?.length > 0) {
            usersByDevice.labels = usersByDevice.labels.map(device => device.charAt(0).toUpperCase() + device.substring(1));
        }


        this.setState({
            totalFormStarted,
            totalFormsSubmitted,
            totalFormBounced,
            totalFormAbandoned,
            usersCount,
            conversionFunnel,
            totalPageViews,
            pageReferrerList,
            usersByLanguage,
            dailyUsers,
            userByCountry,
            usersByDevice,
            usersByDevice,
            usersByCampaign
        })
    }

    fillSimpleStructure = (array, keyName, keyValue, valueName, value) => {
        const index = array.findIndex(el => el[keyName] === keyValue);

        if (index !== -1) {
            array[index][valueName] += value;
        }
        else {
            array.push({ [keyName]: keyValue, [valueName]: value });
        }

        return array;
    }

    fillPieChart = (obj, label, value) => {
        const index = obj.labels.findIndex(el => el === label);

        if (index !== -1) {
            obj.datasets[0].data[index] += value;
        }
        else {
            obj.labels.push(label);
            obj.datasets[0].data.push(value);
        }

        return obj;
    }

    fillGeoMap = (dataPerCountry, country, value) => {
        const countryLabel = countryList().getLabel(country.toUpperCase());
        const index = dataPerCountry.findIndex(el => el[0].v === country);

        if (index !== -1) {
            dataPerCountry[index][1] += value;
        }
        else {
            dataPerCountry.push([{ v: country, f: countryLabel }, value]);
        }

        return dataPerCountry;
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }


    render() {
        const { block, error, totalFormStarted, totalFormsSubmitted, totalFormBounced, totalPageViews, totalFormAbandoned, conversionFunnel, pageReferrerList, startDate, endDate, userByCountry, usersByDevice, usersByLanguage, usersByCampaign, dailyUsers } = this.state;
        const { formsWithAnalytics } = this.props;

        const optionsLine = {
            type: 'line',
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            scales: {
                x: { display: true },
                y: {
                    beginAtZero: true, min: 0,
                    grid: {
                        display: false
                    },
                }
            },
            plugins: {
                datalabels: { display: false },
                legend: { display: false } 
            }
        }

        return (
            <StyledCard block={block} error={error} icon="far fa-chart-bar" title="ProfileNowMenu.Stats">
                <Form onSubmit={this.getFormAnalytics}>
                    <Row className="my-3 align-items-center">
                        <Col sm={3}>
                            <CustomSelect
                                options={formsWithAnalytics}
                                isClearable
                                isSearchable
                                placeholder={<FormattedMessage id="ReferenceDataFunctions.Form" />}
                                onChange={(e) => this.handleSelect("formId", e)}
                                value={formsWithAnalytics?.find(c => c.value === this.state.formId) || ''}
                            />
                        </Col>
                        <Col sm={3} className="fullWidth">
                            <DateRangePicker
                                startDate={startDate ? moment(startDate) : null}
                                startDateId="startDate"
                                isOutsideRange={day => day > moment()}
                                endDate={endDate ? moment(endDate) : null}
                                endDateId="endDate"
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate ? moment(startDate) : null, endDate: endDate ? moment(endDate) : null })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                showClearDates={true}
                                required={true}
                            />
                        </Col>
                        <Col className="text-right">
                            <CommonHelper help={<FormattedMessage id="PNForms.StatsHelp" />} id={'PNFormsStats'} />
                            <Button className="btn btn-host btn-sm" type="submit">
                                <i className="icon-icon-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <Row>
                    <Col sm={3}>
                        <Crt value={totalPageViews ?? 0} text={<FormattedMessage id="PNFormAnalytics.TotalPageView" />} />
                    </Col>
                    <Col sm={3}>
                        <Crt
                            leftValue={totalFormsSubmitted ?? 0}
                            value={<FormattedNumber value={(((totalFormsSubmitted * 100) / totalPageViews) / 100) || 0} maximumFractionDigits={2} style="percent" />}
                            text={<FormattedMessage id="PNFormAnalytics.TotalSubmitted" />}
                            valueColor="color-dark-green"
                        />
                    </Col>
                    <Col sm={3}>
                        <Crt
                            leftValue={totalFormBounced ?? 0}
                            value={<FormattedNumber  value={(((totalFormBounced * 100) / totalPageViews) / 100) || 0} maximumFractionDigits={2} style="percent" />}
                            text={<FormattedMessage id="PNFormAnalytics.TotalFormBounced" />}
                            valueColor="color-orange"
                        />
                    </Col>
                    <Col sm={3}>
                        <Crt
                            leftValue={totalFormAbandoned ?? 0}
                            value={<FormattedNumber value={(((totalFormAbandoned * 100) / totalPageViews) / 100) || 0} maximumFractionDigits={2} style="percent" />}
                            text={<FormattedMessage id="PNFormAnalytics.TotalFormAbandoned" />}
                            valueColor="color-red"
                        />
                    </Col>
                </Row>

                <Row className="my-3">
                    <Col sm={6}>
                        <Card body className="border-0 shadow h-100">
                            <h5 className="text-muted"><FormattedMessage id="PNFormAnalytics.UsersByCountry" /></h5>

                            {userByCountry ?
                                <Row>
                                    <Col className="col-10" style={{ maxHeight: '380px', overflow: 'hidden' }}>
                                        <Chart
                                            className="ChartGeoChart"
                                            width='auto'
                                            height='300px'
                                            chartType="GeoChart"
                                            data={userByCountry}
                                            options={{
                                                colorAxis: { colors: ['#d6edfe', '#0867fa'] }
                                            }}
                                        />
                                    </Col>

                                    <Col className="col-2 pl-0 verticalScroll" style={{ maxHeight: '330px', overflow: 'auto' }}>
                                        {userByCountry?.map((arr, key) => {
                                            return key > 0 && <Row className="mb-2 text-right" key={key} style={{ cursor: 'pointer' }}>
                                                <Col className="px-0 text-right">
                                                    {arr[0] && arr[0].v ?
                                                        <span style={{ borderRadius: '3px', fontSize: '18px' }} className={' mr-1 fa-md flag-icon flag-icon-' + arr[0].v.toLowerCase()} />
                                                        : <b className='mr-2' > NA </b>}
                                                </Col>
                                                <Col className="px-2 col-7 text-left">
                                                    <span > {arr[1]} </span>
                                                </Col>
                                            </Row>
                                        })}
                                    </Col>
                                </Row>
                                :
                                <div className='d-flex align-items-center justify-content-center' style={{ height: '100%', minHeight: '150px' }}>
                                    <FormattedMessage id="generic.table.nodata" />
                                </div>
                            }
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <Card body className="border-0 shadow h-100">
                            <h5 className="text-muted"><FormattedMessage id="PNFormAnalytics.Conversions" /></h5>

                            {conversionFunnel && conversionFunnel.length > 0 ?
                                <CustomFunnel
                                    data={conversionFunnel}
                                    enableFormatting={false}
                                />
                                :
                                <div className='d-flex align-items-center justify-content-center' style={{ height: '100%', minHeight: '150px' }}>
                                    <FormattedMessage id="generic.table.nodata" />
                                </div>
                            }
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col sm={6}>
                        <Card body className="border-0 shadow h-100">
                            <h5 className="text-muted"><FormattedMessage id="PNFormAnalytics.UsersByLanguage" /></h5>

                            <Row className="border-bottom py-2 mb-2 text-muted">
                                <Col sm={9}><FormattedMessage id="PNFormAnalytics.Language" /></Col>
                                <Col sm={3} className="text-center"># <FormattedMessage id="PNFormAnalytics.Users" /></Col>
                            </Row>

                            <div className="verticalScroll" style={{ height: '200px', overflowX: 'hidden' }}>
                                {usersByLanguage?.length > 0 ?
                                    usersByLanguage.map((item, key) =>
                                        <Row key={key} className="py-2 overbglight">
                                            <Col className="col-4">
                                                { item.language || 'N/A' }
                                            </Col>
                                            <Col className="text-center pt-2">
                                                <Progress value={Math.round((item.totalUsers / this.state.usersCount) * 100)} style={{ height: '5px' }} />
                                                <span className="title-sm text-center"> {Math.round((item.totalUsers / this.state.usersCount) * 100)} % </span>
                                            </Col>
                                            <Col className="col-3 text-center">
                                                {item.totalUsers}
                                            </Col>
                                        </Row>
                                    )
                                    :
                                    <div className='d-flex align-items-center justify-content-center' style={{ height: '100%', minHeight: '150px' }}>
                                        <FormattedMessage id="generic.table.nodata" />
                                    </div>
                                }
                            </div>
                        </Card>
                    </Col>

                    <Col className="col-6">
                        <DashboardCard header="PNFormAnalytics.UsersByDevice">
                            {usersByDevice?.labels?.length > 0 ?
                                <div className="p-3 mt-3" style={{ height: '200px' }}>
                                    <Doughnut data={usersByDevice} height='100' options={{ responsive: true, maintainAspectRatio: false, cutout: '60%', plugins: { datalabels: { display: false }, legend: { position: "left" } } }} />
                                </div>
                                :
                                <div className='d-flex align-items-center justify-content-center' style={{ height: '100%', minHeight: '150px' }}>
                                    <FormattedMessage id="generic.table.nodata" />
                                </div>
                            }
                        </DashboardCard>
                    </Col>
                </Row>

                <Row className="my-3">
                    <Col className="col-6">
                        <DashboardCard header="PNFormAnalytics.TrafficSource" >
                            {usersByDevice?.labels?.length > 0 ?
                                <div className="p-3 mt-2" style={{ height: '200px' }}>
                                    <Doughnut data={usersByCampaign} height='100' options={{ responsive: true, maintainAspectRatio: false, cutout: '60%', plugins: { datalabels: { display: false }, legend: { position: "right" } } }} />
                                </div>
                                :
                                <div className='d-flex align-items-center justify-content-center' style={{ height: '100%', minHeight: '150px' }}>
                                    <FormattedMessage id="generic.table.nodata" />
                                </div>
                            }
                        </DashboardCard>
                    </Col>

                    <Col sm={6}>
                        <Card body className="border-0 shadow h-100">
                            <h5 className="text-muted"><FormattedMessage id="PNFormAnalytics.PageReferrer" /></h5>

                            <Row className="border-bottom py-2 mb-2 text-muted">
                                <Col sm={10}>Url</Col>
                                <Col sm={2} className="text-center"># <FormattedMessage id="PNFormAnalytics.Views" /></Col>
                            </Row>

                            <div className="verticalScroll" style={{ height: '200px', overflowX: 'hidden' }}>
                                {pageReferrerList?.length > 0 ?
                                    pageReferrerList.map((referrer, key) =>
                                        <Row key={key} className="py-2 overbglight">
                                            <Col sm={10}>{referrer.url || 'N/A'}</Col>
                                            <Col sm={2} className="text-center pr-2">{referrer.count}</Col>
                                        </Row>
                                    )
                                    :
                                    <div className='d-flex align-items-center justify-content-center mt-3' style={{ height: '100%' }}>
                                        <FormattedMessage id="generic.table.nodata" />
                                    </div>
                                }
                            </div>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Card body className="border-0 shadow h-100">
                            <h5 className="text-muted"><FormattedMessage id="PNFormAnalytics.DailyUsers" /></h5>

                            {dailyUsers?.labels?.length > 0 &&
                                <div style={{ height: '300px' }}>
                                    <Line id="lineChart" data={dailyUsers} height="100" options={optionsLine} />
                                </div>
                            }
                        </Card>
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}
export default injectIntl(PNFormAnalytics)
