import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, Col, Nav, NavItem, NavLink, Row, UncontrolledPopover, PopoverBody } from 'reactstrap';
import { ErrorAlert } from '../../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import SalesDealProposalRooms from '../SalesDealProposalRooms';
import SalesDealProposalEvents from '../SalesDealProposalEvents';
import SalesDealProposalStats from '../SalesDealProposalStats';
import SalesDealProposalCalendar from '../SalesDealProposalCalendar';
import { deleteAPI, getAPIBlob, postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import SalesDealProposalResumeBar from '../SalesDealProposalResumeBar';
import SalesDealProposalResume from '../SalesDealProposalResume';
import moment from 'moment';
import { FormatAmountNumber } from '../../Base/CommonUIComponents';
import CustomToolTip from '../../Base/CustomToolTip';
import SalesDealDetailProposalBlock from './SalesDealDetailProposalBlock';
import SalesDealDetailProposalEvents from './SalesDealDetailProposalEvents';
import SalesProcessDownloadProFormaModal from './SalesProcessDownloadProFormaModal';
import ProposalHistoryModal from '../ProposalHistoryModal';
import { CustomFormatDate } from '../SalesProcessFunctions';
import ConfirmActionModal from '../../Base/ConfirmActionModal';


class SalesDealDetailProposal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            errorModal: null,
            proposals: this.props.dealProposals || [],
            filters: [],
            activeTab: 'Stats',
            importGroupResModal: false,
            proposalHistoryModal: false,
            downloadProFormaModal: false,
            activeSupraTab: 'Calendar',
            selectedProposal: null,
            selectedProposalId: null,
            deleteProposalModal: false,
        };
    }

    componentDidMount() {
        if (this.props.dealProposals === null) {
            this.props.getDealProposals(this.editProposalPermissions);
        }
    }

    componentWillReceiveProps(nextProps) {
        const isDiff = () => {
            if(!this.props.dealProposals || !nextProps.dealProposals){
                return false;
            }
            
            const gResProps = this.props.dealProposals.filter(({groupResExternalId}) => groupResExternalId).reduce((a,b) => {
                const current = a.find(({id}) => id === b.groupResExternalId);
                if(current){
                    current.count++;
                    current.idList.push(b.id);
                    return a;
                }

                return [...a, { id: b.groupResExternalId, count: 1, idList: [b.id] }];
            } ,[]);

            const gResNext = nextProps.dealProposals.filter(({groupResExternalId}) => groupResExternalId).reduce((a,b) => {
                const current = a.find(({id}) => id === b.groupResExternalId);
                if(current){
                    current.count++;
                    current.idList.push(b.id);
                    return a;
                }

                return [...a, { id: b.groupResExternalId, count: 1, idList: [b.id] }];
            } ,[]);

            return (
                gResProps.length !== gResNext.length ||
                gResProps.some(({id, count, idList}) => !gResNext.find((n) => n.id === id) || gResNext.find((n) => n.id === id).count !== count || idList.some(n => !gResNext.find(a => a.idList.includes(n))))
            );
        };

        if (nextProps.dealProposals?.length !== this.props.dealProposals?.length || isDiff() || JSON.stringify(nextProps.dealProposals) !== JSON.stringify(this.props.dealProposals)) {
            this.calculateProposalsValues(nextProps.dealProposals);
        }
    }

    editProposalPermissions = () => {
    }

    calculateProposalsValues = (proposals) => {
        const filters = [...this.state.filters];
        const proposalDetailsCards = [...this.props.proposalDetailsCards];
        const hotels = [];
        var nrNights = 0, nrRooms = 0, nrEvents = 0, nrSpaces = 0, nrEquipement = 0, totalValue = 0;

        proposals.filter(({isOption}) => !isOption).forEach(proposal => {
            if (filters.length > 0) {
                if (proposal.isVisible) {
                    var minCheckIn = null;
                    var maxCheckOut = null;

                    if (!minCheckIn || moment(proposal.proposalJSON.data[0].Checkin).isBefore(minCheckIn)) minCheckIn = moment(proposal.proposalJSON.data[0].Checkin);
                    if (!maxCheckOut || moment(proposal.proposalJSON.data[0].Checkout).isAfter(maxCheckOut)) maxCheckOut = moment(proposal.proposalJSON.data[0].Checkout);
                    
                    if (!hotels.some(el => el === proposal.hotelId)) {
                        hotels.push(proposal.hotelId);
                    }

                    
                    proposal.proposalJSON.BlockData && proposal.proposalJSON.BlockData.details && proposal.proposalJSON.BlockData.details.forEach(blockDetail => {
                        if (blockDetail.isVisible) {
                            totalValue += (blockDetail.CurrentRooms * blockDetail.PriceAfterTaxPerRoom_Total) || 0;
                        }
                    })

                    proposal.proposalJSON.data[0].DetailsRooms && proposal.proposalJSON.data[0].DetailsRooms.forEach(room => {
                        if (room.isVisible) {
                            if (!minCheckIn || moment(room.Checkin).isBefore(minCheckIn)) minCheckIn = moment(room.Checkin);
                            if (!maxCheckOut || moment(room.Checkout).isAfter(maxCheckOut)) maxCheckOut = moment(room.Checkout);
                        }
                    })

                    proposal.proposalJSON.data[0].DetailsEvents && proposal.proposalJSON.data[0].DetailsEvents.forEach(event => {
                        if (event.isVisible) {
                            nrEvents++;
                            nrSpaces += event.Spaces.filter(el => el.isVisible)?.length || 0;
                            totalValue += event.Spaces.reduce((accSp, curSp) => {
                                if (curSp.isVisible && curSp.PriceDetails?.length > 0) {
                                    const sumPriceDetails = curSp.PriceDetails.reduce((accPd, pd) => accPd + pd.AmountAfterTax, 0);

                                    accSp = accSp + sumPriceDetails
                                }

                                return accSp;
                            }, 0) ?? 0;
                        }
                    })

                    if(proposal.type === 'Block') {
                        nrNights += proposal.numberOfNights || 0;
                    }else{
                        if (minCheckIn && maxCheckOut) {
                            const differenceInDays = maxCheckOut.diff(minCheckIn, 'days');
                            nrNights += differenceInDays;
                        }
                    }
                    
                    nrEquipement += proposal.numberOfEquipment || 0;
                }
            }
            else {
                nrNights += proposal.numberOfNights || 0;
                nrRooms += proposal.numberOfRooms || 0;
                nrEvents += proposal.proposalJSON?.data?.[0]?.DetailsEvents?.length || 0;
                nrSpaces += proposal.numberOfSpaces || 0;
                nrEquipement += proposal.numberOfEquipment || 0;
                totalValue += proposal.totalValue || 0;

                //Hotels
                if (!hotels.some(el => el === proposal.hotelId)) {
                    hotels.push(proposal.hotelId);
                }
            }
        });

        proposalDetailsCards[0].value = hotels.length;
        proposalDetailsCards[1].value = nrNights || 0;
        proposalDetailsCards[2].value = nrRooms || 0; //Room Nights
        proposalDetailsCards[3].value = nrEvents || 0;
        proposalDetailsCards[4].value = ((this.props.deal.adults??0) + (this.props.deal.children??0)) || 0;
        proposalDetailsCards[5].value = nrSpaces || 0;
        proposalDetailsCards[6].value = nrEquipement || 0;
        proposalDetailsCards[7].value = totalValue || 0;


        this.setState({ proposalDetailsCards, proposals }, () => { 
            this.props.setProposalDetailsCards(proposalDetailsCards);
            this.editProposalPermissions();
        });
    }

    setFilters = (objectId, objectLabel, objectType, parentId) => {
        const filters = [...this.state.filters];

        const idx = filters.findIndex((f) => f.objectId === objectId && f.objectType === objectType && f.parentId === parentId);
        
        if(idx >= 0){
            filters.splice(idx, 1);
        }
        else{
            filters.push({
                objectId,
                objectLabel,
                objectType,
                parentId
            });
        }

        this.updateProposals(filters);
    }

    updateProposals = (filters) => {
        const proposals = [...this.state.proposals];
        const hasFilters = filters.length > 0;
        const reservationFilters = filters.filter(({ objectType }) => objectType === 'Reservation') || null;
        const groupReservationFilters = filters.filter(({ objectType }) => objectType === 'GroupReservation') || null;
        const hotelFilters = filters.filter(({ objectType }) => objectType === 'Hotel');
        const roomsFilters = filters.filter(({ objectType }) => objectType === 'Rooms');
        const eventsFilters = filters.filter(({ objectType }) => objectType === 'Events');
        const spacesFilters = filters.filter(({ objectType }) => objectType === 'Spaces');

        proposals.forEach(proposal => {
            const hasRoomsInFilter = roomsFilters.some(({ parentId }) => parentId === proposal.externalId);
            const hasEventsInFilter = eventsFilters.some(({ parentId }) => parentId === proposal.externalId);
            const hasSpacesInFilter = spacesFilters.some(({ parentId }) => parentId === proposal.externalId);

            const showHotel = hotelFilters.some(rf => rf.objectId === proposal.hotelId);
            const showReservation = reservationFilters.some(rf => rf.objectId === proposal.externalId) || groupReservationFilters.some(gr => gr.objectId === proposal.groupResExternalId);
            
            proposal.isVisible = hasFilters ? showHotel || showReservation || hasRoomsInFilter || hasEventsInFilter || hasSpacesInFilter : true;
            
            proposal.proposalJSON.BlockData && proposal.proposalJSON.BlockData.details && proposal.proposalJSON.BlockData.details.forEach(blockDetails => {
                blockDetails.isVisible = hasFilters ? hasRoomsInFilter || hasEventsInFilter ? roomsFilters.some(({ objectId }) => !objectId || objectId === blockDetails.CategoryId) : (showHotel || showReservation) : true;
            })

            proposal.proposalJSON.data[0].DetailsRooms && proposal.proposalJSON.data[0].DetailsRooms.forEach(room => {
                room.isVisible = hasFilters ? hasRoomsInFilter || hasEventsInFilter ? roomsFilters.some(({ objectId }) => !objectId || objectId === room.CategoryId) : (showHotel || showReservation) : true;
            })
            
            proposal.proposalJSON.data[0].DetailsEvents && proposal.proposalJSON.data[0].DetailsEvents.forEach(event => {
                event.isVisible = hasFilters ?
                    hasEventsInFilter || hasRoomsInFilter ?
                        eventsFilters.some(({ objectId }) => !objectId || objectId === event.EventName) : (showHotel || showReservation)
                    : true;

                    event.Spaces.forEach(space => {
                    space.isVisible = hasFilters ? hasSpacesInFilter ? spacesFilters.some(({ objectId }) => !objectId || objectId === space.SpaceReservationId) : (event.isVisible || showHotel || showReservation) : true;
                    })

                if (hasSpacesInFilter && !event.isVisible) {
                        event.isVisible = event.Spaces.some(el => el.isVisible);
                    }
            })
        })

        this.setState({ proposals, filters }, _ => this.calculateProposalsValues(proposals));
    }

    toggleTab = (tab) => {
        this.setState({ activeTab: tab });
    }

    removeGroupReservation = (proposalId, isToRemove) => {
        if(!isToRemove){
            this.setState(prevState => ({
                deleteProposalModal: !prevState.deleteProposalModal,
                selectedProposalId: proposalId
            }));
        }else{
            if (!proposalId)
                return;
    
            this.setState({ block: true }, () =>
                deleteAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];
    
                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data && data.errors && data.errors.length > 0) {
                            handleNotification(data);
                            this.setState({ block: false });
                            return;
                        }
    
                        handleNotification(data, <FormattedMessage id="SalesDeal.RemovedGroupReservation" />, <FormattedMessage id="generic.success" />);
                        
                        this.setState({ block: false, deleteProposalModal: false }, this.props.getDealProposals);
                        return;
                    }
                    else
                        this.setState({ error: errorMessage, block: false });
                }, `/api/gms/SalesProcess/v1/Proposal/${proposalId}`)
            );
        }
    }

    downloadProForma = (id) => {
        const { deal } = this.props;
        this.setState({ block: true }, () =>
            getAPIBlob(result => {
                const { data, error, headers } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ errorModal: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    const blob = new Blob([data], { type: headers["content-type"] });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    var f = headers["content-disposition"] ? headers["content-disposition"].match("filename=([^;]*);") : [deal.name + '.pdf'];
                    a.download = f[1];
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);

                    this.setState({ block: false });
                    return;
                }
                else
                    this.setState({ errorModal: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/communication/generateproforma?configId=${id}&salesDealId=${deal.id}`)
        );
    }

    hasFilter = (id, type, parent) => {
        return [...this.state.filters].some(({ objectId, objectType, parentId }) => objectId === id && objectType === type && parentId === parent)
    }

    toggleDownloadProFormaModal = () => {
        this.setState({ downloadProFormaModal: !this.state.downloadProFormaModal });
    }

    handleChangeSelect = (combo) => {
        this.setState({
            configId: combo.value
        })
    }

    toggleActiveSupraTab = (activeSupraTab, selectedProposal) => {
        const updateProposals = activeSupraTab === "Calendar";

        if (activeSupraTab !== this.state.activeSupraTab) {
            let inheritProposalsData = null;

            if (!selectedProposal && (activeSupraTab === "Blocks" || activeSupraTab === "Events")) {
                inheritProposalsData = this.getInheritData(activeSupraTab);
            }

            this.setState({ activeSupraTab, selectedProposal, inheritProposalsData }, () => {
                if (updateProposals) {
                    this.props.getDealProposals();
                }
            });
        }
    }

    getInheritData = (activeSupraTab) => {
        let inheritProposalsData = null;
        const { deal } = this.props;

        if (this.state.proposals?.length > 0) {
            const orderedProposals = [...this.state.proposals].sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix());

            const primaryType = activeSupraTab === "Blocks" ? "Block" : "Event";
            const secondaryType = primaryType === "Block" ? "Event" : "Block";

            const firstProposal = orderedProposals.find(el => el.type === primaryType) || orderedProposals.find(el => el.type === secondaryType);

            if (firstProposal) {
                const data = firstProposal.proposalJSON?.BlockData?.headerData?.[0] || firstProposal.proposalJSON?.data?.[0] || {};

                inheritProposalsData = {
                    hotelId: firstProposal.hotelId,
                    description: deal?.name,
                    fromDate: data.CheckIn || data.FromDate,
                    toDate: data.CheckOut || data.ToDate,
                    segmentCode: data.DetailsEvents?.[0]?.SegmentCode || data.SegmentCode,
                    subSegmentCode: data.DetailsEvents?.[0]?.SubSegmentCode || data.SubSegmentCode,
                    distChannelCode: data.DetailsEvents?.[0]?.DistChannelCode || data.DistChannelCode
                }

                if (activeSupraTab === "Blocks") {
                    inheritProposalsData.entityMasterId = data.EntityMasterId || data.DetailsEvents?.[0]?.CompanyEntityMasterId;
                    inheritProposalsData.defaultReservationCustomStatusCode = data.DefaultReservationCustomStatusCode || data.DetailsEvents?.[0]?.ReservationStatusCode;
                }
                else {
                    inheritProposalsData.startHour = inheritProposalsData.fromDate ? moment(inheritProposalsData.fromDate).format("HH:mm") : null;
                    inheritProposalsData.endHour = inheritProposalsData.toDate ? moment(inheritProposalsData.toDate).format("HH:mm") : null;
                    inheritProposalsData.statusCode = data.DetailsEvents?.[0]?.ReservationStatusCode || data.DefaultReservationCustomStatusCode;
                    inheritProposalsData.companyMasterId = data.DetailsEvents?.[0]?.CompanyEntityMasterId || data.EntityMasterId;
                }
            }
        }
        else {
            inheritProposalsData = {
                description: deal.name,
                fromDate: deal.businessStartDate,
                toDate: deal.businessEndDate,
                entityMasterId: deal.mainContactId,
                companyMasterId: deal.mainContactId
            }
        }

        return inheritProposalsData;
    }

    toggleIsOption = (proposalId, isOptional) => {
        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    handleNotification(data, <FormattedMessage id="SalesProcess.ProposalSaved" />, <FormattedMessage id="generic.success" />);
                    this.setState({ block: false }, () => this.props.getDealProposals(e => this.calculateProposalsValues(e)));
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/proposal/${proposalId}/isoptional?isOptional=${isOptional}`);
        });
    }

    createSaveValueAction = (e) => {
        if (e) e.preventDefault();
        const { proposals } = this.state;
        const { deal, intl } = this.props;

        const totalValue = intl.formatNumber(
            proposals.reduce((prev, curr) => prev + (curr.totalValue ?? 0), 0),
            {
                maximumFractionDigits: 2,
                style: "currency",
                currency: global.hotelCurrency
            }
        );

        const body = { 
            salesDealId: deal.id,
            type: 'SavedValue',
            title: 'Deal total value saved.',
            summary: `Total value: ${totalValue} Date: ${CustomFormatDate(moment(), null, 'HH:mm', intl)}.`,
            status: 'Finished',
        };

        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    this.setState({ block: false }, () => handleNotification(data, <FormattedMessage id="SalesDeal.TotalValueSavedSuccessfully" />, <FormattedMessage id="generic.success" />));
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/deals/action`, body);
        });
    }

    getDealEntities = () => {
        const { deal } = this.props;
        const entities = [];
    
        if (!deal) return entities;

        if (Array.isArray(deal.salesDealProfile) && deal.salesDealProfile.length) {
            entities.push(...deal.salesDealProfile
                .filter(profile => profile?.profileId && profile?.name)
                .map(profile => ({
                    value: profile.profileId,
                    label: profile.name,
                    isMaster: profile.isMaster
                }))
            );
        }
    
        if (deal.mainContact?.id && deal.mainContact?.name) {
            entities.push({
                value: deal.mainContact.id,
                label: deal.mainContact.name,
                isMaster: deal.mainContact.isMaster
            });
        }
    
        if (deal.customer?.id && deal.customer?.name) {
            entities.push({
                value: deal.customer.id,
                label: deal.customer.name,
                isMaster: deal.customer.isMaster
            });
        }
    
        const uniqueEntities = Array.from(new Set(entities.map(e => e.value)))
            .map(value => entities.find(e => e.value === value));
    
        return uniqueEntities;
    }

    createGroupRes = (proposal) => {
        this.setState({ block: true }, () => {
            const { proposals } = this.state;
            const { deal } = this.props;
            
            const payload = {
                dealId: deal.id,
                groupResId: proposal.groupResExternalId ?? proposals
                    ?.find(({groupResExternalId, hotelId, id}) =>
                        hotelId === proposal.hotelId &&
                        id !== proposal.id &&
                        groupResExternalId
                    )?.groupResExternalId
            };
            
            const groupEvents = proposals
                ?.filter(({groupResExternalId, type, id}) =>
                    id !== proposal.id &&
                    type === "Event" &&
                    payload.groupResId &&
                    payload.groupResId === groupResExternalId
                )
                .map(({externalId}) => externalId);

            const groupBlock = proposals
                ?.find(({groupResExternalId, type, id}) =>
                    id !== proposal.id &&
                    type === "Block" &&
                    payload.groupResId &&
                    payload.groupResId === groupResExternalId
                )
                ?.externalId;

            if(proposal.groupResExternalId && proposal.type === "Block"){
                payload.blockId = null;
                payload.eventIds = groupEvents;
            }
            else if(proposal.type === "Block"){
                payload.blockId = proposal.externalId;
                payload.eventIds = groupEvents;
            }
            else if(proposal.groupResExternalId && proposal.type === "Event"){
                payload.eventIds = groupEvents;
                payload.blockId = groupBlock;
            }
            else if(proposal.type === "Event"){
                payload.eventIds = [...groupEvents, proposal.externalId];
                payload.blockId = groupBlock;
            }


            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    const msg = payload.groupResId ? <FormattedMessage id="SalesDeal.GroupResUpdated"/> : <FormattedMessage id="SalesDeal.GroupResCreated"/>;
                    handleNotification(data, msg, <FormattedMessage id="generic.success" />);
                    this.setState({ block: false });
                    
                    this.props.updatedProposals(data);
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/pmsgroupreservation?hotelId=${proposal.hotelId}`, payload);
        });
    }

    render() {
        const { deal, toggleImportGroupResModal, importGroupRes, proposalDetailsCards, canAddProposals, hotelCurrency } = this.props;
        const { block, error, proposals, filters, activeTab, downloadProFormaModal, configs, configId, activeSupraTab, selectedProposal, inheritProposalsData, deleteProposalModal, selectedProposalId } = this.state;
        
        return (
            <>
                {deal ?
                    <Card className='border-0 h-100'>
                        <ErrorAlert error={error} />
                        <BlockUi tag="div" blocking={block} className='h-100'>
                            {proposals && proposals.length ?
                                <div>
                                    {activeSupraTab === "Calendar" ?
                                        <Row className='h-100'>
                                            <Col className='pt-2 col-9'>
                                                <div className='h-100'>
                                                    <div>
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <div>
                                                                <h5 className='m-0'><FormattedMessage id="SalesDeal.ProposalDetails" /></h5>
                                                            </div>
                                                            <div className='d-flex align-items-center justify-content-end'>
                                                                {canAddProposals ?
                                                                    <>
                                                                        <Button className='btn btn-host btn-sm' onClick={_ => this.toggleActiveSupraTab("Events")}>
                                                                            <FormattedMessage id="SalesProcess.CreateEvent" />
                                                                            <i className="fas fa-theater-masks ml-2" />
                                                                        </Button>
                                                                        <Button className='ml-2 btn btn-host btn-sm' onClick={_ => this.toggleActiveSupraTab("Blocks")}>
                                                                            <FormattedMessage id="SalesProcess.CreateBlock" />
                                                                            <i className="fas fa-cube ml-2" />
                                                                        </Button>
                                                                        <Button className="ml-2 btn btn-host btn-sm" onClick={toggleImportGroupResModal}>
                                                                            <FormattedMessage id="SalesDeal.AddNewProposal" />
                                                                            <i className="ml-2 fas fa-receipt" />
                                                                        </Button>
                                                                        <Button id="downloadBtn" className="ml-2 btn btn-host btn-sm" onClick={_ => this.toggleActiveSupraTab("DownloadProposal")}>
                                                                            <i className="fas fa-file-download" />
                                                                        </Button>
                                                                        <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="bottom" target={`downloadBtn`}>
                                                                            <PopoverBody>
                                                                                <FormattedMessage id={"SalesProcess.DownloadProposal"} />
                                                                            </PopoverBody>
                                                                        </CustomToolTip>
                                                                        <Button id="proposalHistoryBtn" className="ml-2 btn btn-host btn-sm" onClick={_ => this.toggleActiveSupraTab("ProposalHistory")}>
                                                                            <i className="fas fa-history"/>
                                                                        </Button>
                                                                        <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="bottom" target={`proposalHistoryBtn`}>
                                                                            <PopoverBody>
                                                                                <FormattedMessage id={"SalesProcess.HistoryButton"} />
                                                                            </PopoverBody>
                                                                        </CustomToolTip>
                                                                        <Button className="ml-2 btn btn-host btn-sm" id="saveValueBtn" onClick={(e) => this.createSaveValueAction(e)}>
                                                                            <i className="fas fa-camera" />
                                                                        </Button>
                                                                        <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="bottom" target={`saveValueBtn`}>
                                                                            <PopoverBody>
                                                                                <FormattedMessage id={"SalesDeal.SaveValueBtn"} />
                                                                            </PopoverBody>
                                                                        </CustomToolTip>
                                                                    </>
                                                                :''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='mt-4'>
                                                        <div>
                                                            <Row>
                                                                {proposalDetailsCards.map((card, key) =>
                                                                    <Col className='col-3 mb-3' key={key}>
                                                                        <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                                <div className='text-muted'>
                                                                                    <i className={`${card.icon} mr-2`} />
                                                                                    {card.label}
                                                                                </div>
                                                                                <div style={{ fontSize: '1.4em' }}>
                                                                                    {card.isAmount ?
                                                                                        <FormatAmountNumber value={card.value} currency={hotelCurrency} />
                                                                                    : card.value}
                                                                                </div>
                                                                            </div>
                                                                        </Card>
                                                                    </Col>
                                                                )}
                                                            </Row>
                                                            <div className='mt-4 d-flex align-items-center justify-content-between' style={{ borderBottom: "1px solid #dee2e6" }}>
                                                                <Nav tabs className="border-0">
                                                                    <NavItem>
                                                                        <NavLink className={activeTab === 'Stats' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('Stats')} style={{ cursor: "pointer" }}>
                                                                            <i className="fas fa-chart-pie mr-2"/>
                                                                            <FormattedMessage id="SalesDeal.Stats"/>
                                                                        </NavLink>
                                                                    </NavItem>
                                                                    <NavItem>
                                                                        <NavLink className={activeTab === 'Rooms' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('Rooms')} style={{ cursor: "pointer" }}>
                                                                            <i className="fas fa-bed mr-2"/>
                                                                            <FormattedMessage id="SalesDeal.Rooms"/>
                                                                        </NavLink>
                                                                    </NavItem>
                                                                    <NavItem>
                                                                        <NavLink className={activeTab === 'Events' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('Events')} style={{ cursor: "pointer" }}>
                                                                            <i className="fas fa-theater-masks mr-2"/>
                                                                            <FormattedMessage id="SalesDeal.Events"/>
                                                                        </NavLink>
                                                                    </NavItem>
                                                                    <NavItem>
                                                                        <NavLink className={activeTab === 'Calendar' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => this.toggleTab('Calendar')} style={{ cursor: "pointer" }}>
                                                                            <i className="far fa-calendar-alt mr-2"/>
                                                                            <FormattedMessage id="SalesDeal.Calendar"/>
                                                                        </NavLink>
                                                                    </NavItem>
                                                                </Nav>
                                                                <>
                                                                    <Button className="ml-2 btn btn-host btn-sm" id={`AppliedFilters`}>
                                                                        <i className="fas fa-sliders-h"/>
                                                                    </Button>
                                                                    <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="bottom" target={`AppliedFilters`}>
                                                                        <PopoverBody>
                                                                            <FormattedMessage id={"SalesProcess.FiltersButton"} />
                                                                        </PopoverBody>
                                                                    </CustomToolTip>
                                                                    <UncontrolledPopover target={`AppliedFilters`} placement={"left-start"} placementPrefix='coolPopover bs-popover'>
                                                                        <div className='py-2 px-3' style={{ minWidth: '200px', maxWidth: '250px' }}>
                                                                            <div className='text-muted d-flex align-items-center mb-2' style={{ fontSize: '1.1em' }}>
                                                                                <i className="fas fa-sliders-h mr-2 mt-1"/>
                                                                                <FormattedMessage id="SalesDeal.Filters"/>
                                                                            </div>
                                                                            {filters.length > 0 ?
                                                                                (() => {
                                                                                    const treatedFilters = filters
                                                                                        .map(({parentId}) => parentId)
                                                                                        .filter((value, index, array) => array.indexOf(value) === index)
                                                                                        .map(parentId => {
                                                                                            const fs = filters.filter((f) => f.parentId === parentId);
                                                                                            const keys = fs.map(({objectType}) => objectType).filter((value, index, array) => array.indexOf(value) === index);
                                                                                            const returnValue = {
                                                                                                parentId,
                                                                                                appliedFilters: []
                                                                                            };

                                                                                        keys.forEach((k) => {
                                                                                            returnValue.appliedFilters.push({
                                                                                                type: k,
                                                                                                list: fs.filter((f) => f.objectType === k)
                                                                                            });
                                                                                        });

                                                                                        return returnValue
                                                                                    }
                                                                                );

                                                                                    return (
                                                                                        <div>
                                                                                            {treatedFilters.sort(a => !a.parentId ? -1 : 1).map((parent, key) =>
                                                                                                <div key={key} className='mt-2 pt-2' style={{ borderTop: key ? '1px solid lightgrey' : '' }}>
                                                                                                    {parent.parentId ?
                                                                                                        <div>
                                                                                                            <b>{parent.parentId}</b>
                                                                                                        </div>
                                                                                                        :''}
                                                                                                    {parent.appliedFilters.map((t, key) =>
                                                                                                        <div className={!parent.parentId ? key ? 'mt-2 pt-2' : '' : 'mt-2 px-2'} key={key} style={{ borderTop: !parent.parentId && key ? '1px solid lightgrey' : '' }}>
                                                                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                                                                <div>
                                                                                                                    {parent.parentId ?
                                                                                                                        <div className='text-muted'>
                                                                                                                            <FormattedMessage id={`SalesDeal.${t.type}`}/>
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        <b>
                                                                                                                            <FormattedMessage id={`SalesDeal.${t.type}`}/>
                                                                                                                        </b>
                                                                                                                    }
                                                                                                                </div>
                                                                                                                {t.list.some(a => !a.objectLabel) ?
                                                                                                                    <div style={{ color: '#A80C19' }} className='cursor-pointer' onClick={_ => this.setFilters(null, null, t.type, parent.parentId)}>
                                                                                                                        <i className="fas fa-times"></i>
                                                                                                                    </div>
                                                                                                                    :''}
                                                                                                            </div>
                                                                                                            {t.list.filter(a => a.objectLabel).map((filter, key) =>
                                                                                                                <div key={key} className='mt-2 d-flex align-items-start justify-content-between px-2'>
                                                                                                                    <div>
                                                                                                                        {filter.objectLabel}
                                                                                                                    </div>
                                                                                                                    <div style={{ color: '#A80C19' }} className='cursor-pointer' onClick={_ => this.setFilters(filter.objectId, filter.objectLabel, t.type, parent.parentId)}>
                                                                                                                        <i className="fas fa-times"></i>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    );
                                                                                })()
                                                                                :
                                                                                <div className='text-muted mt-2 text-center'>
                                                                                    <FormattedMessage id="SalesDeal.NoFilters"/>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </UncontrolledPopover>
                                                                </>
                                                            </div>
                                                        </div>
                                                        <div className='mt-4'>
                                                            {activeTab === 'Stats' ?
                                                                <SalesDealProposalStats
                                                                    filtersLength={filters?.length}
                                                                    proposals={proposals}
                                                                    hotelCurrency={hotelCurrency}
                                                                />
                                                            : activeTab === 'Rooms' ?
                                                                <SalesDealProposalRooms
                                                                    filtersLength={filters?.length}
                                                                    proposals={proposals}
                                                                    hotelCurrency={hotelCurrency}
                                                                />
                                                            : activeTab === 'Events' ?
                                                                <SalesDealProposalEvents
                                                                    filters={filters}
                                                                    proposals={proposals}
                                                                    filtersLength={filters?.length}
                                                                    dealName={deal.name}
                                                                    hotelCurrency={hotelCurrency}
                                                                />
                                                            : activeTab === 'Calendar' ?
                                                                <SalesDealProposalCalendar
                                                                    proposals={proposals}
                                                                    filtersLength={filters?.length}
                                                                    hotelCurrency={hotelCurrency}
                                                                />
                                                            :''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className='pt-2 col-3 px-0 d-flex justify-content-between flex-column h-100' style={{ textAlign: 'center', top: '0', position: 'sticky' }}>
                                                <div className='h-100'>
                                                    {proposals && proposals.length > 0 ?
                                                    <div className='d-flex justify-content-between h-100 flex-column h-100'>
                                                            <SalesDealProposalResume
                                                                salesDealProposals={proposals}
                                                                setFilters={this.setFilters}
                                                                hasFilter={this.hasFilter}
                                                                importGroupRes={importGroupRes}
                                                                removeGroupReservation={this.removeGroupReservation}
                                                                toggleActiveSupraTab={this.toggleActiveSupraTab}
                                                                toggleIsOption={this.toggleIsOption}
                                                                hotelCurrency={hotelCurrency}
                                                                dealId={deal.id}
                                                                createGroupRes={this.createGroupRes}
                                                            />

                                                            <SalesDealProposalResumeBar
                                                                classString="mx-3"
                                                                salesDealProposals={proposals}
                                                                hotelCurrency={hotelCurrency}
                                                            />
                                                        </div>
                                                    :
                                                        <div className='mt-4 text-center'>
                                                            <div className='text-muted'>
                                                                <FormattedMessage id="SalesDeal.NoProposalsYet" />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    : activeSupraTab === "Blocks" ?
                                        <SalesDealDetailProposalBlock
                                            dealHotelList={deal.hotelIds}
                                            toggleActiveSupraTab={this.toggleActiveSupraTab}
                                            selectedProposal={selectedProposal}
                                            selectedHotelId={selectedProposal?.hotelId}
                                            dealId={deal.id}
                                            getDealEntities={this.getDealEntities}
                                            dealStartDate={deal.businessStartDate}
                                            dealEndDate={deal.businessEndDate}
                                            inheritProposalsData={inheritProposalsData}
                                        />
                                    : activeSupraTab === "Events" ?
                                        <SalesDealDetailProposalEvents
                                            dealHotelList={deal.hotelIds}
                                            toggleActiveSupraTab={this.toggleActiveSupraTab}
                                            selectedProposal={selectedProposal}
                                            selectedHotelId={selectedProposal?.hotelId}
                                            getDealEntities={this.getDealEntities}
                                            dealId={deal.id}
                                            dealStartDate={deal.businessStartDate}
                                            dealEndDate={deal.businessEndDate}
                                            inheritProposalsData={inheritProposalsData}
                                        />
                                    : activeSupraTab === "DownloadProposal" ?
                                        <SalesProcessDownloadProFormaModal
                                            downloadProFormaModal={downloadProFormaModal}
                                            configs={configs}
                                            configId={configId}
                                            deal={deal}
                                            toggleActiveSupraTab={this.toggleActiveSupraTab}
                                            downloadProForma={this.downloadProForma}
                                            proposals={proposals}
                                        />
                                    : activeSupraTab === "ProposalHistory" ?
                                        <ProposalHistoryModal
                                            salesDealId={deal.id}
                                            deal={deal}
                                            toggleActiveSupraTab={this.toggleActiveSupraTab}
                                        />
                                    :''}
                                </div>
                            :
                                <>
                                    {activeSupraTab === "Blocks" ?
                                        <SalesDealDetailProposalBlock
                                            dealHotelList={deal.hotelIds}
                                            toggleActiveSupraTab={this.toggleActiveSupraTab}
                                            selectedProposal={selectedProposal}
                                            selectedHotelId={selectedProposal?.hotelId}
                                            dealId={deal.id}
                                            getDealEntities={this.getDealEntities}
                                            dealStartDate={deal.businessStartDate}
                                            dealEndDate={deal.businessEndDate}
                                            inheritProposalsData={inheritProposalsData}
                                        />
                                    : activeSupraTab === "Events" ?
                                        <SalesDealDetailProposalEvents
                                            dealHotelList={deal.hotelIds}
                                            toggleActiveSupraTab={this.toggleActiveSupraTab}
                                            selectedProposal={selectedProposal}
                                            selectedHotelId={selectedProposal?.hotelId}
                                            dealId={deal.id}
                                            getDealEntities={this.getDealEntities}
                                            inheritProposalsData={inheritProposalsData}
                                        />
                                    :
                                        <div className='mt-4 text-center'>
                                            <div className='text-muted'>
                                                <FormattedMessage id="SalesDeal.NoProposalsYet" />
                                            </div>
                                            {canAddProposals ?
                                                <div className='mt-2'>
                                                    <Button className='mr-2 btn btn-host btn-sm' onClick={_ => this.toggleActiveSupraTab("Events")}>
                                                        <FormattedMessage id="SalesProcess.CreateEvent" />
                                                        <i className="fas fa-theater-masks ml-2" />
                                                    </Button>
                                                    <Button className='mr-2 btn btn-host btn-sm' onClick={_ => this.toggleActiveSupraTab("Blocks")}>
                                                        <FormattedMessage id="SalesProcess.CreateBlock" />
                                                        <i className="fas fa-cube ml-2" />
                                                    </Button>
                                                    <Button onClick={toggleImportGroupResModal} className="btn btn-host btn-sm">
                                                        <FormattedMessage id="SalesDeal.AddNewProposal" />
                                                        <i className="ml-2 fas fa-receipt"></i>
                                                    </Button>
                                                </div>
                                            : ''}
                                        </div>
                                    }
                                </>
                            }

                            {deleteProposalModal ?
                                <ConfirmActionModal
                                    modal={deleteProposalModal}
                                    toggleModal={() => this.removeGroupReservation(null, null)}
                                    actionFunction={() => this.removeGroupReservation(selectedProposalId, true)}
                                    text={<FormattedMessage id="SalesProcess.ConfirmDeleteProposalText" />}
                                    block={block}
                                />
                            :''}
                        </BlockUi>
                    </Card>
                : ''}
            </>
        );
    }
}

export default injectIntl(SalesDealDetailProposal);

