import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Collapse, Input, Row } from 'reactstrap';
import CustomSelect from '../../Base/CustomSelect';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import moment from 'moment-timezone';
import { ErrorAlert } from '../../Base/ErrorAlert';
import { getAPI, postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import SalesDealDetailProposalEventsSpaces from './SalesDealDetailProposalEventsSpaces';
import { SalesProcessHotelCombo } from '../SalesProcessFunctions';
import { pmsFixedStatus } from '../../Base/ReferenceDataFunctions';
import { CommonHelper } from '../../Base/CommonUIComponents';

class SalesDealDetailProposalEvents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            blockAvail: true,
            error: null,
            eventData: {},
            selectedHotelId: this.props.selectedHotelId ?? ((this.props.dealHotelList && this.props.dealHotelList.length === 1) ? this.props.dealHotelList[0] : null),
            hotelOptions: this.props.dealHotelList?.map((id) => global.hotelList.find(({value}) => value === id)),
            eventTypeOptions: [],
            customStatusOptions: [],
            entityOptions: [],
            segmentOptions: [],
            segmentData: [],
            subSegmentOptions: [],
            subSegmentData: [],
            distChannelOptions: [],
            distChannelData: [],
            distributionChannelsBlock: true,
            subSegmentsBlock: true,
            segmentsBlock: true,
            customStatusBlock: true,
            eventTypeBlock: true,
            collapse: true,
            customStatusOptionsUnfiltered: [],
            eventTypeOptionsUnfiltered: [],
            segmentOptionsUnfiltered: [],
            subSegmentOptionsUnfiltered: [],
            distChannelOptionsUnfiltered: [],
            spacesAvail: null,
            spacesOptions: [],
            timeOptions: this.getArray(24).flatMap((d, index) => {
                const hour = index.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                })
                return (
                    [{
                        label: `${hour}:00`,
                        value: `${hour}:00`,
                    }, {
                        label: `${hour}:30`,
                        value: `${hour}:30`,
                    }]
                );
            }),
            submittedStartDate: null,
            submittedEndDate: null,
            spacesOptionsUnfiltered: [],
            unsavedChanges: true,
            serviceTypeOptions: [],
            serviceTypeOptionsUnfiltered: [],
        };
    }

    getArray(size) {
        const array = [];
        for (let i = 0; i < size; i++) {
            array.push(i);
        }
        return array;
    }
    
    componentDidMount(){
        window.addEventListener('beforeunload', this.handleBeforeUnload);

        const { selectedProposal, inheritProposalsData } = this.props;
        const entityOptions = this.props.getDealEntities()?.filter(el => el.isMaster);

        this.getRefData();
        const block = selectedProposal && selectedProposal.externalId;
        this.setState({ entityOptions, block: block, collapse: !block }, () => {
            if (selectedProposal && selectedProposal.externalId)
                this.getEventInfo(selectedProposal.externalId);

            else if (inheritProposalsData) {
                this.inheritData(inheritProposalsData);
            }
        });
    }
    
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    handleBeforeUnload = (e) => {
        const { unsavedChanges } = this.state;

        if (unsavedChanges) {
            e.returnValue = 'Unsaved changes';
            return "Unsaved changes";
        };
    }

    getEventInfo = (externalId) => {
        const { selectedHotelId } = this.state;
        const { selectedProposal } = this.props;

        this.setState({ block: true }, () => {

            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    
                    const eventData = (data.response && data.response[0]) ?? {};
                    eventData.pax = eventData.adults;
                    eventData.children = eventData.children1;
                    eventData.id = selectedProposal?.externalId??externalId;
                    eventData.description = eventData.eventName;
                    eventData.statusCode = eventData.reservationStatusCode;
                    eventData.startHour = eventData.fromDate ? moment(eventData.fromDate).format('HH:mm') : null;
                    eventData.endHour = eventData.fromDate ? moment(eventData.toDate).format('HH:mm') : null;
                    eventData.companyMasterId = eventData.companyEntityMasterId;

                    this.setState({ block: false, eventData, submittedStartDate: moment(eventData.fromDate), submittedEndDate: moment(eventData.toDate), unsavedChanges: false }, () => this.getSpacesAvailability());
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/SalesProcess/v1/eventInfo?hotelId=${selectedHotelId}&eventId=${externalId}`);
        });
    }
    
    inheritData = (inheritData) => {
        const eventData = { ...inheritData };
        
        this.setState({ eventData, selectedHotelId: inheritData.hotelId });
    }

    getRefData = () => {
        this.getReferenceDataSegments();
        this.getReferenceDataSubSegments();
        this.getReferenceDataDistributionChannels();
        this.getReferenceDataCustomStatus();
        this.getReferenceDataEventType();
        this.getReferenceDataSpaces();
    }

    getReferenceDataSegments = () => {
        getAPI(result => {
            const { data, error } = result;

            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, segmentsBlock: false });

                return;
            }
            if (data) {
                const { filtered, brute } = this.refDataTreatment(data.response);
                this.setState({
                    segmentData: data.response,
                    segmentOptions: filtered??[],
                    segmentOptionsUnfiltered: brute??[],
                    segmentsBlock: false
                });
            }
            else {
                this.setState({ error: errorMessage, segmentsBlock: false });
            }
        }, `/api/gms/Hotel/v1/ReferenceData?type=Segments`)
    }
    
    getReferenceDataSubSegments = () => {
        getAPI(result => {
            const { data, error } = result;

            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, subSegmentsBlock: false });

                return;
            }
            if (data) {
                const { filtered, brute } = this.refDataTreatment(data.response);
                this.setState({
                    subSegmentData: data.response,
                    subSegmentOptions: filtered??[],
                    subSegmentOptionsUnfiltered: brute??[],
                    subSegmentsBlock: false
                });
            }
            else {
                this.setState({ error: errorMessage, subSegmentsBlock: false });
            }
        }, `/api/gms/Hotel/v1/ReferenceData?type=SubSegments`)
    }
    
    getReferenceDataDistributionChannels = () => {
        getAPI(result => {
            const { data, error } = result;

            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, distributionChannelsBlock: false });

                return;
            }
            if (data) {
                const { filtered, brute } = this.refDataTreatment(data.response);
                this.setState({
                    distChannelData: data.response,
                    distChannelOptions: filtered??[],
                    distChannelOptionsUnfiltered: brute??[],
                    distributionChannelsBlock: false
                });
            }
            else {
                this.setState({ error: errorMessage, distributionChannelsBlock: false });
            }
        }, `/api/gms/Hotel/v1/ReferenceData?type=DistributionChannels`)
    }

    getReferenceDataEventType = () => {
        getAPI(result => {
            const { data, error } = result;

            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, eventTypeBlock: false });

                return;
            }
            if (data) {
                const { filtered, brute } = this.refDataTreatment(data.response);
                
                const eventTypeOptions = (filtered??[]).filter(({referenceDataDetails}) => referenceDataDetails?.isEvent !== false);
                const eventTypeOptionsUnfiltered = (brute??[]).filter(({referenceDataDetails}) => referenceDataDetails?.isEvent !== false);;
                const serviceTypeOptions = (filtered??[]).filter(({referenceDataDetails}) => referenceDataDetails?.isService !== false);
                const serviceTypeOptionsUnfiltered = (brute??[]).filter(({referenceDataDetails}) => referenceDataDetails?.isService !== false);;

                this.setState({
                    eventTypeOptions,
                    eventTypeOptionsUnfiltered,
                    serviceTypeOptions,
                    serviceTypeOptionsUnfiltered,
                    eventTypeBlock: false
                });
            }
            else {
                this.setState({ error: errorMessage, eventTypeBlock: false });
            }
        }, `/api/gms/Hotel/v1/ReferenceData?type=EventTypes`)
    }

    getReferenceDataCustomStatus = () => {
        getAPI(result => {
            const { data, error } = result;

            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, customStatusBlock: false });

                return;
            }
            if (data) {
                const { filtered, brute } = this.refDataTreatment(data.response, true);
                this.setState({
                    customStatusOptions: filtered??[],
                    customStatusOptionsUnfiltered: brute??[],
                    customStatusBlock: false
                });
            }
            else {
                this.setState({ error: errorMessage, customStatusBlock: false });
            }
        }, `/api/gms/Hotel/v1/ReferenceData?type=ReservationStatus`)
    }

    getReferenceDataSpaces = () => {
        const { selectedHotelId } = this.state;
        if(!selectedHotelId) return;

        getAPI(result => {
            const { data, error } = result;

            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage });

                return;
            }
            if (data?.data) {
                const unFiltered = data.data
                    .sort((a, b) => a.sortOrder - b.sortOrder)
                    .filter(a => a.isVisible)
                    .map(({ code, shortName, hotelId, price, maxCapacity, isSpaceCommunicant }) => (
                        {
                            hotelId: hotelId,
                            maxCapacity: maxCapacity,
                            value: code,
                            code: code,
                            price: price,
                            description: shortName,
                            label: shortName,
                            isSpaceCommunicant,
                        }
                    ));

                this.setState({ spacesOptions: unFiltered.filter(a => !a.isSpaceCommunicant), spacesOptionsUnfiltered: unFiltered });
            }
            else {
                this.setState({ error: errorMessage });
            }
        }, `/api/gms/SalesProcess/v1/eventspaces?hotelId=${selectedHotelId}`)
    }
    
    refDataTreatment = (data, isStatus) => {
        const { selectedHotelId } = this.state;
        const returnValue = {
            filtered: data
                ?.filter(({hotelId, active}) => active && (!hotelId || selectedHotelId === hotelId))
                .map(({description, code, hotelId, referenceDataDetails}) => ({
                    description,
                    code,
                    hotelId,
                    referenceDataDetails: Array.isArray(referenceDataDetails) ? referenceDataDetails.map(({detailJSON}) => detailJSON && JSON.parse(detailJSON))?.[0] : referenceDataDetails,
                    value: code,
                    label: (
                        <span>
                            {description}
                            <b className="ml-1">({code})</b>
                        </span>
                    )
                })).concat(isStatus ? pmsFixedStatus(this.props.intl) : []),
            brute: data?.filter(a => a.active).map(({description, code, hotelId, referenceDataDetails}) => ({
                value: code,
                hotelId,
                referenceDataDetails: Array.isArray(referenceDataDetails) ? referenceDataDetails.map(({detailJSON}) => detailJSON && JSON.parse(detailJSON))?.[0] : referenceDataDetails,
                label: (
                    <span>
                        {description}
                        <b className="ml-1">({code})</b>
                    </span>
                )
            })),
        }
        return returnValue;
    }

    reloadRefData = (selectedHotelId) => {
        const { segmentOptionsUnfiltered, subSegmentOptionsUnfiltered, distChannelOptionsUnfiltered, customStatusOptionsUnfiltered, eventTypeOptionsUnfiltered, serviceTypeOptionsUnfiltered, } = this.state;

        const eventTypeOptions = eventTypeOptionsUnfiltered?.filter(({hotelId}) => !hotelId || selectedHotelId === hotelId)??[];
        const serviceTypeOptions = serviceTypeOptionsUnfiltered?.filter(({hotelId}) => !hotelId || selectedHotelId === hotelId)??[];
        const segmentOptions = segmentOptionsUnfiltered?.filter(({hotelId}) => !hotelId || selectedHotelId === hotelId)??[];
        const subSegmentOptions = subSegmentOptionsUnfiltered?.filter(({hotelId}) => !hotelId || selectedHotelId === hotelId)??[];
        const distChannelOptions = distChannelOptionsUnfiltered?.filter(({hotelId}) => !hotelId || selectedHotelId === hotelId)??[];
        const customStatusOptions = customStatusOptionsUnfiltered?.filter(({hotelId}) => !hotelId || selectedHotelId === hotelId)??[];

        this.setState({ segmentOptions, subSegmentOptions, distChannelOptions, customStatusOptions, eventTypeOptions, serviceTypeOptions }, () => this.getReferenceDataSpaces());
    }

    saveBlock = (e) => {
        e.preventDefault();
        this.setState({ block: true }, () => {
            const { eventData, selectedHotelId } = this.state;
            const { dealId } = this.props;
            const payload = {...eventData};

            payload.fromDate = moment(payload.fromDate).set('hour', payload.startHour.slice(0, 2)).set('minute', payload.startHour.slice(3)).format('YYYY-MM-DD HH:mm');
            payload.toDate = moment(payload.toDate).set('hour', payload.endHour.slice(0, 2)).set('minute', payload.endHour.slice(3)).format('YYYY-MM-DD HH:mm');

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    
                    const eventData = (data.data && data.data[0]?.event) ?? {};
                    eventData.pax = eventData.adults;
                    eventData.children = eventData.children1;
                    eventData.description = eventData.eventName;
                    eventData.statusCode = eventData.reservationStatusCode;
                    eventData.id = payload.id ?? (data.data && data.data[0] && data.data[0].event?.id);
                    eventData.companyMasterId = eventData.companyEntityMasterId;

                    handleNotification(data, <FormattedMessage id="SalesProcess.EventSaved" />, <FormattedMessage id="generic.success" />);
                    
                    this.setState({
                        block: false, eventData, blockAvail: true, unsavedChanges: false,
                        submittedStartDate: moment(eventData.fromDate), submittedEndDate: moment(eventData.toDate)
                    }, () => this.getSpacesAvailability());
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/SalesProcess/v1/pmsevent?hotelId=${selectedHotelId}&dealId=${dealId}`, payload);
        });
    }

    handleChange = (e) => {
        const { value, name } = e.target;

        this.setState({
            unsavedChanges: true,
            eventData: {
                ...this.state.eventData,
                [name]: value
            }
        });
    }

    handleComboChange = (combo, name) => {
        this.setState({
            unsavedChanges: true,
            eventData: {
                ...this.state.eventData,
                [name]: combo?.value
            }
        });
    }

    handleRefDataCombo = (combo, name) => {
        const { 
            segmentData, segmentOptionsUnfiltered, 
            subSegmentData, subSegmentOptionsUnfiltered, 
            distChannelData, distChannelOptionsUnfiltered, 
            selectedHotelId, eventData 
        } = this.state;
    
        const filterOptionsByHotel = (options) => options?.filter(({ hotelId }) => !hotelId || selectedHotelId === hotelId);

        const removeDuplicates = (options) => {
            if (!options){ 
                return [];
            }
            const exists = {};
            
            return options.filter(option => {
                const key = JSON.stringify(option);
                if (exists[key]){
                    return false;
                } 
                exists[key] = true;
                return true;
            });
        };
    
        const selectedSegment = name === 'segmentCode' ? combo?.value : eventData?.segmentCode;
        const selectedSubSegment = name === 'subSegmentCode' ? combo?.value : eventData?.subSegmentCode;
        const selectedDistChannel = name === 'distChannelCode' ? combo?.value : eventData?.distChannelCode;

        let segmentOptions = filterOptionsByHotel(segmentOptionsUnfiltered);
        let subSegmentOptions = filterOptionsByHotel(subSegmentOptionsUnfiltered);
        let distChannelOptions = filterOptionsByHotel(distChannelOptionsUnfiltered);
    
        if (selectedSegment) {
            const subSegmentsBySegment = segmentData?.find((s) => s.code === selectedSegment)?.subSegments || [];
            const distChannelsBySegment = segmentData?.find((s) => s.code === selectedSegment)?.distChannels || [];
    
            subSegmentOptions = subSegmentOptions?.filter(({ value }) => subSegmentsBySegment.includes(value));
            distChannelOptions = distChannelOptions?.filter(({ value }) => distChannelsBySegment.includes(value));
        }
    
        if (selectedSubSegment) {
            const segmentsBySubSegment = subSegmentData?.find((s) => s.code === selectedSubSegment)?.segments || [];
            const distChannelsBySubSegment = subSegmentData?.find((s) => s.code === selectedSubSegment)?.distChannels || [];
    
            segmentOptions = segmentOptions?.filter(({ value }) => segmentsBySubSegment.includes(value));
            distChannelOptions = distChannelOptions?.filter(({ value }) => distChannelsBySubSegment.includes(value));
        }
    
        if (selectedDistChannel) {
            const segmentsByDistChannel = distChannelData?.find((s) => s.code === selectedDistChannel)?.segments || [];
            const subSegmentsByDistChannel = distChannelData?.find((s) => s.code === selectedDistChannel)?.subSegments || [];
    
            segmentOptions = segmentOptions?.filter(({ value }) => segmentsByDistChannel.includes(value));
            subSegmentOptions = subSegmentOptions?.filter(({ value }) => subSegmentsByDistChannel.includes(value));
        }

        segmentOptions = removeDuplicates(segmentOptions);
        subSegmentOptions = removeDuplicates(subSegmentOptions);
        distChannelOptions = removeDuplicates(distChannelOptions);
    
        this.setState({
            unsavedChanges: true,
            segmentOptions,
            subSegmentOptions,
            distChannelOptions,
            eventData: {
                ...eventData,
                [name]: combo?.value,
            },
        });
    };

    handleHotelChange = (name, combo) => {
        this.setState({ unsavedChanges: true, selectedHotelId: combo?.value }, () => this.reloadRefData(combo?.value));
    }

    handleDatesChange = ({startDate, endDate}) => {
        this.setState({
            unsavedChanges: true,
            eventData: {
                ...this.state.eventData,
                fromDate: startDate?.format('YYYY-MM-DD'),
                toDate: endDate?.format('YYYY-MM-DD'),
            }
        });
    }

    handleDateChange = (date, name) => {
        this.setState({
            unsavedChanges: true,
            eventData: {
                ...this.state.eventData,
                [name]: date?.format('YYYY-MM-DD')
            }
        });
    }

    toggleCollapse = () => {
        this.setState({ collapse: !this.state.collapse });
    }
    
    getSpacesAvailability = () => {
        const { eventData, selectedHotelId } = this.state;

        if(!eventData?.fromDate || !eventData?.toDate) {
            this.setState({ blockAvail: false });
            return;
        }

        const startDate = moment(eventData?.fromDate).format('YYYY-MM-DD');
        const endDate = moment(eventData?.toDate).format('YYYY-MM-DD');

        let qs = `?hotelId=${selectedHotelId}&fromDate=${startDate}&toDate=${endDate}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockAvail: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({
                    spacesAvail: data.response ? data.response[0] ? data.response[0].reservationList ?? [] : [] : [],
                    blockAvail: false
                });

                return;
            }
            else this.setState({ error: errorMessage, blockAvail: false });
        }, `/api/gms/SalesProcess/v1/spacesavailability${qs}`);
    }

    saveSpace = (payload, cb) => {
        this.setState({ block: true }, () => {
            if(payload.copyDaily) {
                const { eventData } = this.state;

                const numberOfDays = moment(eventData.toDate).diff(moment(eventData.fromDate), 'day') + 1;
        
                for (let d = 1; d <= numberOfDays; d++) {
                    const date = moment(eventData.fromDate).clone().add((d - 1), 'day');

                    const newPayload = {...payload};

                    newPayload.startDate = date;
                    newPayload.endDate = date;
                    newPayload.id = 0;

                    this.callSaveSpaceAPI(newPayload, () => {
                        if(d === numberOfDays){
                            if(cb){
                                cb();
                            }
                        }
                    }, d === numberOfDays);
                }
            }
            else{
                this.callSaveSpaceAPI(payload, cb, true);
            }
        });
    }

    removeSpace = (payload, cb) => {
        if(!payload){
            if(cb){
                cb();
            }
            return;
        }

        payload.statusCode = "DELETED";

        this.setState({ block: true }, () => {
            this.callSaveSpaceAPI(payload, cb, true);
        });
    }

    callSaveSpaceAPI = (payload, cb, isLast) => {
        const { selectedHotelId, eventData } = this.state;
        const { dealId } = this.props;
        
        if(payload.startHour && payload.endDate){
            payload.fromDate = moment(payload.startDate).set('hour', payload.startHour.slice(0, 2)).set('minute', payload.startHour.slice(3)).format('YYYY-MM-DD HH:mm');
            payload.toDate = moment(payload.endDate).set('hour', payload.endHour.slice(0, 2)).set('minute', payload.endHour.slice(3)).format('YYYY-MM-DD HH:mm');
        }
        
        payload.eventId = eventData.id;

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false }, () => {
                    if(cb)
                        cb();
                });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false }, () => {
                        if(cb)
                            cb();
                    });
                    return;
                }
                
                handleNotification(data, <FormattedMessage id="SalesProcess.SpaceSaved" />, <FormattedMessage id="generic.success" />);
                
                if(isLast){
                    this.getEventInfo(payload.eventId);
                }

                if(cb)
                    cb(true);
            }
            else {
                this.setState({ error: errorMessage, block: false }, () => {
                    if(cb)
                        cb();
                });
            }
        }, `/api/gms/SalesProcess/v1/pmsspace?hotelId=${selectedHotelId}&dealId=${dealId}`, payload);
    }

    isOutsideRange = (day) => {
        const { dealStartDate, dealEndDate } = this.props;
        
        if(!dealStartDate || !dealEndDate)
            return false;

        if(day.isBefore(dealStartDate) || day.isAfter(dealEndDate, "day"))
            return true;

        return false;
    }

    addSpaceToEvent = (target, cb) => {
        const { spacesOptions, eventData } = this.state;

        if(target === "create-orphan-space"){
            this.setState({ eventData: {
                ...eventData,
                spaces: [
                    ...eventData.spaces,
                    {
                        spaceCode: spacesOptions[0],
                        spaceName: spacesOptions[0].description,
                        startDate: moment(eventData.fromDate),
                        startHour: moment(eventData.fromDate).format('HH:mm'),
                        endDate: moment(eventData.fromDate).add(1, 'hour'),
                        endHour: moment(eventData.fromDate).add(1, 'hour').format('HH:mm'),
                        isNew: true
                    }
                ]
            }}, () => cb());
            
            return;
        }

        const noEvents = 5 + target.slice(5).indexOf('-');

        const noSpace = (noEvents + 1) + target.slice(noEvents + 1).indexOf('-');
        const noIsNew = noSpace + 1 + 6;
        const spaceKey = target.slice(noEvents + 1, noSpace);
        
        const date = target.slice(noIsNew + 2);
        const space = spacesOptions[spaceKey];

        this.setState({
            unsavedChanges: true,
            eventData: {
            ...eventData,
            spaces: [
                ...eventData.spaces,
                {
                    fromDate: date,
                    toDate: date,
                    spaceCode: space.value,
                    spaceName: space.description,
                    isNew: true
                }
            ]
        }}, () => cb());
    }

    removeSpaceToEvent = (cb) => {
        const { eventData } = this.state;

        this.setState({
            unsavedChanges: true,
            eventData: {
            ...eventData,
            spaces: eventData.spaces.filter(({isNew}) => !isNew)
        }}, () => cb());
    }

    renderHelperMsg = () => {
        return (
            <div style={{ width: '200px' }}>
                <div>
                    <div className='d-flex align-items-center justify-content-between mt-3'>
                        <div className='d-flex justify-content-center' style={{ width: '20%' }}>
                            <div style={{
                                width: '18px',
                                height: '35px',
                                borderRadius: '3px',
                                border: `1px solid #d3d3d3`,
                                background: '#d3d3d3'
                            }}/>
                        </div>
                        <div style={{ width: '80%' }} className="pl-3 d-flex align-items-center justify-content-start">
                            <FormattedMessage id="SalesProcess.Occupied"/>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between mt-2'>
                        <div className='d-flex justify-content-center' style={{ width: '20%' }}>
                            <div style={{
                                width: '18px',
                                height: '35px',
                                borderRadius: '3px',
                                border: `1px solid #5f7383`,
                                background: '#5f7383'
                            }}/>
                        </div>
                        <div style={{ width: '80%' }} className="pl-3 d-flex align-items-center justify-content-start">
                            <FormattedMessage id="SalesProcess.OccupiedByThisEvent"/>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between mt-2'>
                        <div className='d-flex justify-content-center' style={{ width: '20%' }}>
                            <div style={{
                                width: '18px',
                                height: '35px',
                                borderRadius: '3px',
                                border: `1px solid #0665ff`,
                                background: '#0665ff'
                            }}/>
                        </div>
                        <div style={{ width: '80%' }} className="pl-3 d-flex align-items-center justify-content-start">
                            <FormattedMessage id="SalesProcess.Focused"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    returnToProposals = (event) => {
        if (this.state.unsavedChanges) {
            if (!window.confirm(this.props.intl.formatMessage({ id: 'stripo.confirmUnsaved' }))) {
                event.preventDefault();

                return;
            }
        }

        this.props.toggleActiveSupraTab("Calendar");
    }

    render() {
        const { block, error, eventData, selectedHotelId, timeOptions, eventTypeOptions, customStatusOptions, entityOptions, segmentOptions, subSegmentOptions, distChannelOptions,
        distributionChannelsBlock, subSegmentsBlock, segmentsBlock, customStatusBlock, collapse, eventTypeBlock, spacesAvail, spacesOptions, blockAvail, submittedStartDate,
        submittedEndDate, spacesOptionsUnfiltered, hotelOptions, serviceTypeOptions, serviceTypeOptionsUnfiltered, } = this.state;
        const { intl, dealStartDate, dealEndDate } = this.props;

        return (
            <div className='h-100'>
                <ErrorAlert error={error}/>
                <BlockUi tag="div" blocking={block}>
                    <form onSubmit={this.saveBlock}>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex aling-items-center'>
                                <div onClick={e => this.returnToProposals(e)} className='p-2 mr-2 cursor-pointer'>
                                    <i className='fas fa-chevron-left'/>
                                </div>
                                <h5 className='d-flex align-items-center justify-content-start m-0'><FormattedMessage id="SalesProcess.Event"/></h5>
                            </div>
                            <div className='d-flex align-items-center'>
                                <Button className="btn btn-host btn-sm" type="submit" disabled={eventData ? false : true }>
                                    <i className="fas fa-save"/>
                                </Button>
                                <CommonHelper help={this.renderHelperMsg()} id={'SalesProcessEventManagment'} />
                            </div>
                        </div>
                        <div>
                            <div>
                                <Row className='mt-2'>
                                    <Col className="col-3">
                                        <div className='title-sm required'>
                                            <FormattedMessage id="SalesProcess.Hotel"/>
                                        </div>
                                        <SalesProcessHotelCombo
                                            required={true}
                                            isDisabled={selectedHotelId && eventData?.id}
                                            value={selectedHotelId}
                                            isSearchable={true}
                                            onChangeFunc={this.handleHotelChange}
                                            placeholder={intl.formatMessage({ id: "SalesProcess.Hotel" })}
                                            dealHotelList={hotelOptions}
                                            filterByDealHotels={true}
                                        />
                                    </Col>
                                    <Col className="col-3">
                                        <div className='title-sm required'>
                                            <FormattedMessage id="SalesProcess.Name"/>
                                        </div>
                                        <Input
                                            type='text'
                                            onChange={this.handleChange}
                                            name="description"
                                            value={eventData?.description ?? ''}
                                            required
                                            placeholder={intl.formatMessage({ id: "SalesProcess.Name" })}
                                        />
                                    </Col>
                                    <Col className="col-3">
                                        <div className='title-sm required'>
                                            <FormattedMessage id="SalesProcess.EventType"/>
                                        </div>
                                        <CustomSelect
                                            options={eventTypeOptions}
                                            value={eventTypeOptions.find(({value, description}) => (!eventData?.eventTypeCode ? description === eventData?.eventType : value === eventData?.eventTypeCode)) ?? ''}
                                            isLoading={eventTypeBlock}
                                            required
                                            onChange={e => this.handleComboChange(e, 'eventTypeCode')}
                                            placeholder={intl.formatMessage({ id: "SalesProcess.EventType" })}
                                            isDisabled={!selectedHotelId}
                                        />
                                    </Col>
                                    <Col className="col-3">
                                        <div className='title-sm required'>
                                            <FormattedMessage id="SalesProcess.Status"/>
                                        </div>
                                        <CustomSelect
                                            options={customStatusOptions}
                                            value={customStatusOptions.find(({value}) => value === eventData?.statusCode) ?? ''}
                                            required
                                            isLoading={customStatusBlock}
                                            placeholder={intl.formatMessage({ id: "SalesProcess.Status" })}
                                            onChange={e => this.handleComboChange(e, 'statusCode')}
                                            isDisabled={!selectedHotelId}
                                        />
                                    </Col>
                                </Row>
                                <Collapse isOpen={collapse}>
                                    <Row>
                                        <Col className="col-6 mt-2">
                                            <Row>
                                                <Col className="col-3">
                                                    <div className='title-sm required'>
                                                        <FormattedMessage id="SalesProcess.StartDate"/>
                                                    </div>
                                                    <SingleDatePicker
                                                        id="StartDate"
                                                        isOutsideRange={this.isOutsideRange}
                                                        date={eventData?.fromDate ? moment(eventData?.fromDate) : null}
                                                        focused={this.state.focused7}
                                                        onFocusChange={({ focused }) => this.setState({ focused7: focused })}
                                                        small={true}
                                                        required={true}
                                                        numberOfMonths={1}
                                                        showDefaultInputIcon={true}
                                                        onDateChange={e => this.handleDateChange(e, 'fromDate')}
                                                        showClearDate={true}
                                                        initialVisibleMonth={() => moment(dealStartDate)}
                                                    />
                                                </Col>
                                                <Col className="col-3">
                                                    <div className='title-sm' style={{ visibility: 'hidden' }}>
                                                        <FormattedMessage id="SalesProcess.StartDate"/>
                                                    </div>
                                                    <CustomSelect
                                                        required={true}
                                                        options={timeOptions}
                                                        value={timeOptions.find(({value}) => value === eventData.startHour)}
                                                        onChange={e => this.handleComboChange(e, 'startHour')}
                                                        placeholder={""}
                                                    />
                                                </Col>

                                                <Col className="col-3">
                                                    <div className='title-sm required'>
                                                        <FormattedMessage id="SalesProcess.EndDate"/>
                                                    </div>
                                                    <SingleDatePicker
                                                        id="EndDate"
                                                        isOutsideRange={this.isOutsideRange}
                                                        date={eventData?.toDate ? moment(eventData?.toDate) : null}
                                                        focused={this.state.focused8}
                                                        required={true}
                                                        onFocusChange={({ focused }) => this.setState({ focused8: focused })}
                                                        small={true}
                                                        numberOfMonths={1}
                                                        showDefaultInputIcon={true}
                                                        onDateChange={e => this.handleDateChange(e, 'toDate')}
                                                        showClearDate={true}
                                                        initialVisibleMonth={() => moment(dealEndDate)}
                                                    />
                                                </Col>
                                                <Col className="col-3">
                                                    <div className='title-sm' style={{ visibility: 'hidden' }}>
                                                        <FormattedMessage id="SalesProcess.EndDate"/>
                                                    </div>
                                                    <CustomSelect
                                                        required={true}
                                                        options={timeOptions}
                                                        value={timeOptions.find(({value}) => value === eventData.endHour)}
                                                        onChange={e => this.handleComboChange(e, 'endHour')}
                                                        placeholder={""}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="col-3 mt-2">
                                            <div className='title-sm required'>
                                                <FormattedMessage id="SalesProcess.Adults"/>
                                            </div>
                                            <Input
                                                type='number'
                                                min='0'
                                                onChange={this.handleChange}
                                                name="pax"
                                                value={eventData?.pax ?? ''}
                                                required
                                                placeholder={intl.formatMessage({ id: "SalesProcess.Adults" })}
                                            />
                                        </Col>
                                        <Col className="col-3 mt-2">
                                            <Row>
                                                <Col className="col-6">
                                                    <div className='title-sm'>
                                                        <FormattedMessage id="SalesProcess.Children"/>
                                                    </div>
                                                    <Input
                                                        type='number'
                                                        min='0'
                                                        onChange={this.handleChange}
                                                        name="children"
                                                        value={eventData?.children ?? ''}
                                                        placeholder={intl.formatMessage({ id: "SalesProcess.Children" })}
                                                    />
                                                </Col>
                                                <Col className="col-6">
                                                    <div className='title-sm'>
                                                        <FormattedMessage id="SalesProcess.Children2"/>
                                                    </div>
                                                    <Input
                                                        type='number'
                                                        min='0'
                                                        onChange={this.handleChange}
                                                        name="children2"
                                                        value={eventData?.children2 ?? ''}
                                                        placeholder={intl.formatMessage({ id: "SalesProcess.Children2" })}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        
                                        <Col className="col-3 mt-2">
                                            <div className='title-sm required'>
                                                <FormattedMessage id="SalesProcess.Company"/>
                                            </div>
                                            <CustomSelect
                                                options={entityOptions}
                                                required={true}
                                                value={entityOptions?.find(({ value }) => value === eventData?.companyMasterId)}
                                                isSearchable={true}
                                                onChange={e => this.handleComboChange(e, 'companyMasterId')}
                                                placeholder={intl.formatMessage({ id: "SalesProcess.Company" })}
                                            />
                                        </Col>
                                        <Col className="col-3 mt-2">
                                            <div className='title-sm required'>
                                                <FormattedMessage id="SalesProcess.DecisionDate"/>
                                            </div>
                                            <SingleDatePicker
                                                id="DecisionDate"
                                                required={true}
                                                isOutsideRange={day => false}
                                                date={eventData?.decisionDate ? moment(eventData.decisionDate) : null}
                                                focused={this.state.focused2}
                                                onFocusChange={({ focused }) => this.setState({ focused2: focused })}
                                                small={true}
                                                numberOfMonths={1}
                                                showDefaultInputIcon={true}
                                                onDateChange={date => this.handleDateChange(date, 'decisionDate')}
                                                showClearDate={true}
                                            />
                                        </Col>
                                        <Col className="col-3 mt-2">
                                            <div className='title-sm required'>
                                                <FormattedMessage id="SalesProcess.FollowupDate"/>
                                            </div>
                                            <SingleDatePicker
                                                id="FollowupDate"
                                                isOutsideRange={day => false}
                                                required={true}
                                                date={eventData?.followupDate ? moment(eventData.followupDate) : null}
                                                focused={this.state.focused3}
                                                onFocusChange={({ focused }) => this.setState({ focused3: focused })}
                                                small={true}
                                                numberOfMonths={1}
                                                showDefaultInputIcon={true}
                                                onDateChange={date => this.handleDateChange(date, 'followupDate')}
                                                showClearDate={true}
                                            />
                                        </Col>
                                        <Col className="col-3 mt-2">
                                            <div className='title-sm'>
                                                <FormattedMessage id="SalesProcess.CutoffDate"/>
                                            </div>
                                            <SingleDatePicker
                                                id="CutoffDate"
                                                isOutsideRange={day => false}
                                                date={eventData?.cutoffDate ? moment(eventData.cutoffDate) : null}
                                                focused={this.state.focused4}
                                                onFocusChange={({ focused }) => this.setState({ focused4: focused })}
                                                small={true}
                                                numberOfMonths={1}
                                                showDefaultInputIcon={true}
                                                onDateChange={date => this.handleDateChange(date, 'cutoffDate')}
                                                showClearDate={true}
                                            />
                                        </Col>

                                        <Col className="col-3 mt-2">
                                            <div className='title-sm required'>
                                                <FormattedMessage id="SalesProcess.Segment"/>
                                            </div>
                                            <CustomSelect
                                                options={segmentOptions}
                                                isClearable={true}
                                                isLoading={segmentsBlock}
                                                value={segmentOptions?.find(({ value }) => value === eventData?.segmentCode)}
                                                isSearchable={true}
                                                required={true}
                                                onChange={e => this.handleRefDataCombo(e, 'segmentCode')}
                                                placeholder={intl.formatMessage({ id: "SalesProcess.SegmentCode" })}
                                                isDisabled={!selectedHotelId}
                                            />
                                        </Col>
                                        <Col className="col-3 mt-2">
                                            <div className='title-sm required'>
                                                <FormattedMessage id="SalesProcess.SubSegment"/>
                                            </div>
                                            <CustomSelect
                                                options={subSegmentOptions}
                                                isClearable={true}
                                                isLoading={subSegmentsBlock}
                                                value={subSegmentOptions?.find(({ value }) => value === eventData?.subSegmentCode)}
                                                isSearchable={true}
                                                required={true}
                                                onChange={e => this.handleRefDataCombo(e, 'subSegmentCode')}
                                                placeholder={intl.formatMessage({ id: "SalesProcess.SubSegmentCode" })}
                                                isDisabled={!selectedHotelId}
                                            />
                                        </Col>
                                        <Col className="col-3 mt-2">
                                            <div className='title-sm required'>
                                                <FormattedMessage id="SalesProcess.DistChannel"/>
                                            </div>
                                            <CustomSelect
                                                options={distChannelOptions}
                                                isClearable={true}
                                                isLoading={distributionChannelsBlock}
                                                value={distChannelOptions?.find(({ value }) => value === eventData?.distChannelCode)}
                                                isSearchable={true}
                                                required={true}
                                                onChange={e => this.handleRefDataCombo(e, 'distChannelCode')}
                                                placeholder={intl.formatMessage({ id: "SalesProcess.DistChannel" })}
                                                isDisabled={!selectedHotelId}
                                            />
                                        </Col>
                                    </Row>
                                </Collapse>
                                {eventData?.id ?
                                    <div
                                        style={{
                                            minHeight: '1px',
                                            background: '#dfdfdf',
                                            marginTop: '1rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'relative'
                                        }}
                                        onClick={this.toggleCollapse}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                background: '#dfdfdf',
                                                position: 'absolute',
                                                top: '1px',
                                                padding: '0rem 1rem',
                                                borderRadius: '0 0 10px 10px',
                                                fontSize: '0.6em',
                                                height: '9px',
                                                cursor: 'pointer',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            {collapse ?
                                                <i className="fas fa-chevron-up"/>
                                            :
                                                <i className="fas fa-chevron-down"/>
                                            }
                                        </div>
                                    </div>
                                :''}
                            </div>
                            {eventData?.id && spacesOptions?.length && spacesAvail ?
                                <BlockUi tag="div" blocking={blockAvail}>
                                    <div className='mt-4' style={{ minHeight: '200px' }}>
                                        <SalesDealDetailProposalEventsSpaces
                                            addSpaceToEvent={this.addSpaceToEvent}
                                            removeSpaceToEvent={this.removeSpaceToEvent}
                                            spacesOptions={spacesOptions}
                                            spacesAvail={spacesAvail}
                                            startDate={submittedStartDate ?? null}
                                            endDate={submittedEndDate ? submittedEndDate.clone().add(-1, 'minute') : null}
                                            eventTypeOptions={serviceTypeOptions}
                                            customStatusOptions={customStatusOptions}
                                            eventData={eventData}
                                            saveSpace={this.saveSpace}
                                            removeSpace={this.removeSpace}
                                            selectedHotelId={selectedHotelId}
                                            spacesOptionsUnfiltered={spacesOptionsUnfiltered}
                                        />
                                    </div>
                                </BlockUi>
                            :''}
                        </div>
                    </form>
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(SalesDealDetailProposalEvents);