import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getAPI } from '../Base/API';
import { StyledCard } from "../Base/CommonUIComponents";
import { handleNotification } from '../Base/Notification';
import { Button, Col, Row, Input } from 'reactstrap';
import { CustomTable } from '../Base/CustomTable';
import CustomSelect from "../Base/CustomSelect";

export class GoContactImport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: true,
            importBatchRunList: [],
            options: [
                { value: 'queue', label: <FormattedMessage id="GoContactImport.queue" /> },
                { value: 'ticket', label: <FormattedMessage id="GoContactImport.ticket" /> },
                { value: 'assisted_transfer', label: <FormattedMessage id="GoContactImport.assisted_transfer" /> },
                { value: 'ivr_campaigns', label: <FormattedMessage id="GoContactImport.ivr_campaigns" /> },
                //{ value: 'getcallbacks', label: <FormattedMessage id="GoContactImport.callbacks" /> }, tem mais 1 nivel
                { value: 'agents', label: <FormattedMessage id="GoContactImport.agents" /> },
                { value: 'webchat', label: <FormattedMessage id="GoContactImport.webchat" /> },
                { value: 'webchat_sessions', label: <FormattedMessage id="GoContactImport.webchat_sessions" /> },
                { value: 'ticket_agent_times', label: <FormattedMessage id="GoContactImport.ticket_agent_times" /> },
                { value: 'scripts', label: <FormattedMessage id="GoContactImport.scripts" /> },
                { value: 'on_hook_attempt', label: <FormattedMessage id="GoContactImport.on_hook_attempt" /> },
                { value: 'quality', label: <FormattedMessage id="GoContactImport.quality" /> },
                { value: 'campaign', label: <FormattedMessage id="GoContactImport.campaign" /> }

            ],
            active: true

        };
    }

    componentDidMount() {
        this.getImportBatchRun()
    }

    getImportBatchRun = () => {


        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response) {
                    this.setState({ importBatchRunList: data.response, block: false });
                }

            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/GoContact/v1/ImportBatchRun?active=${this.state.active}`);
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    render() {
        const { block, error, importBatchRunList, active, options } = this.state;
        const columns = [
            {
                dataField: 'id',
                text: this.props.intl.formatMessage({ id: "GoContactImport.id" }),
                style: { width: '25px' }
            }, {
                dataField: 'hotelGroupApiConnectionId',
                text: this.props.intl.formatMessage({ id: "GoContactImport.connection_id" }),
                style: { width: '25px' }
            },
            {
                dataField: 'goContactTemplateReportId',
                text: this.props.intl.formatMessage({ id: "GoContactImport.template_id" }),
                style: { width: '25px' }
            },
            {
                dataField: 'lastRun',
                text: this.props.intl.formatMessage({ id: "GoContactImport.lastrun" }),
                style: { width: '25px' }
            },
            {
                dataField: 'reportType',
                text: this.props.intl.formatMessage({ id: "GoContactImport.report_type" }),
                style: { width: '25px' }
            },
            {
                dataField: 'dataType',
                text: this.props.intl.formatMessage({ id: "GoContactImport.data_type" }),
                style: { width: '25px' }
            },
            ,
            {
                dataField: 'result',
                text: this.props.intl.formatMessage({ id: "GoContactImport.result" }),
                style: { width: '25px' }
            }

        ]
        return (
            <StyledCard block={block} error={error} icon="fas fa-database" title="Import Batch Run Settings">

                <Row className="mt-3 mb-4 align-items-center">
                    <Col className="col-3 pr-0">
                        <CustomSelect
                            options={options}
                            value={options.find(m => m.value === active)}
                            placeholder={''}
                            onChange={this.handleSelect.bind(this, 'reportType')}
                            required={true}
                        />
                    </Col>
                    <Col>
                    </Col>
                    <Col className="text-right" >
                        <Button className="mr-3 btn btn-sm btn-host" onClick={this.getImportBatchRun} >
                            <i className="fa fa-search" />
                        </Button>
                    </Col>
                </Row>
                <CustomTable
                    data={importBatchRunList}
                    columns={columns}
                    rowStyle={{ cursor: 'pointer' }}
                    hasPagination={true}
                    sizePerPage={10}
                    showTotal={true}
                    search={true}


                />

            </StyledCard>
        );
    }
}
export default injectIntl(GoContactImport)