import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Card, Col, Row, Nav, NavItem, NavLink } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import gocontact from '../../img/gocontact.png';
import GoContactUser from './GoContactUser'
import GoContactContacts from './GoContactContacts'
import GoContactTicket from './GoContactTicket'
import GoContactCallbacks from './GoContactCallbacks'
import GoContactQueue from './GoContactQueue'
import GoContactDatabase from './GoContactDatabase'
import GoContactReport from './GoContactReport'
import GoContactImport from './GoContactImport'


import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../Base/ErrorAlert';

class GoContactMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            activeTab: 'Contacts',
            tabs: [
                { name: 'Tickets', title: 'GoContactMenu.Tickets', icon: 'far fa-envelope' },
                { name: 'Contacts', title: 'GoContactMenu.Contacts', icon: 'far fa-address-book'},
                { name: 'Callbacks', title: 'GoContactMenu.Callbacks', icon: 'fas fa-phone-volume' },
                { name: 'User', title: 'GoContactMenu.User', icon: 'far fa-user' },
                { name: 'Queues', title: 'GoContactMenu.Queues', icon: 'far fa-bookmark' },
                { name: 'Database', title: 'GoContactMenu.Database', icon: 'fas fa-database' },
                { name: 'Reporting', title: 'GoContactMenu.Reporting', icon: 'far fa-chart-bar' },
                { name: 'Import', title: 'GoContactMenu.Import', icon: 'fas fa-cloud-upload-alt' },
            ]
        };
    }

    componentDidMount() {
    }

    

    render() {
        const { block, error, tabs, activeTab } = this.state;

        return (
            <div className="border-0 h-100 py-2">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <Row>
                        <Col className='col-12 d-flex align-items-start pr-0'>
                            <div style={{ width: '15%' }}>
                                <Nav tabs vertical className='border-0'>
                                    <Card className="bg-white p-2 border-0 shadow" style={{ borderRadius: '5px', height: '80vh' }}>
                                        <div className='d-flex align-items-center mb-4 p-2'>
                                            <h5>
                                                <FormattedMessage id="GoContactMenu.Title" />
                                            </h5>
                                        </div>
                                        {tabs && tabs.map((tab, key) => {
                                            const isActive = activeTab === tab.name;

                                            return (
                                                <NavItem className="pb-2" key={key}>
                                                    <NavLink onClick={_ => this.setState({ activeTab: tab.name })} className="pointer border-0" style={{ width: 'fit-content' }}>
                                                        {isActive ?
                                                            <div style={{
                                                                'left': '0',
                                                                'position': 'absolute',
                                                                'borderRadius': '0px 30px 30px 0px',
                                                                'width': '7px',
                                                                'height': '14px',
                                                                'background': '#0667FF 0% 0% no-repeat padding-box',
                                                                'opacity': '1',
                                                                'marginTop': '2px'
                                                            }} />
                                                            : ''}
                                                        <div id={`tab-${key}`} className={(isActive ? 'text-primary ' : '') + 'd-flex align-items-center justify-content-start'}>
                                                            {tab.icon ? <i className={`${tab.icon} mr-2`} /> : <img className='mr-2' alt="" src={gocontact} height="18px" style={{ filter: isActive ? '' : 'grayscale(100%) brightness(0)' }} />}
                                                            <FormattedMessage id={tab.title} />
                                                        </div>
                                                    </NavLink>
                                                </NavItem>
                                            )
                                        }
                                        )}
                                    </Card>
                                </Nav>
                            </div>
                            <div style={{ width: '85%' }}>
                                {activeTab === 'Contacts' && !block ?
                                    <Col>
                                        <GoContactContacts/>
                                    </Col>
                                    : activeTab === 'User' ?
                                        <Col>
                                            <GoContactUser />
                                        </Col>
                                        : activeTab === 'Callbacks' ?
                                            <Col>
                                                <GoContactCallbacks />
                                            </Col>
                                            : activeTab === 'Tickets' ?
                                                <Col>
                                                    <GoContactTicket />
                                                </Col>
                                                : activeTab === 'Queues' ?
                                                    <Col>
                                                        <GoContactQueue />
                                                    </Col>
                                                    : activeTab === 'Database' ?
                                                        <Col>
                                                            <GoContactDatabase />
                                                        </Col>
                                                        : activeTab === 'Reporting' ?
                                                            <Col>
                                                                <GoContactReport />
                                                            </Col>
                                                            : activeTab === 'Import' ?
                                                                <Col>
                                                                    <GoContactImport />
                                                                </Col>
                                                : ''}
                                
                            </div>
                        </Col>
                    </Row>
                </BlockUi>
            </div>
        );
    }
}
export default injectIntl(GoContactMenu)
