import React, { useState } from 'react';
import { Col, Row, Input, Nav, NavItem, NavLink, Label, TabContent, TabPane, Button, Card, Badge, UncontrolledTooltip, Form } from 'reactstrap';
import { CommonHelper } from './CommonUIComponents';
import countryList from "react-select-country-list";
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from "./CustomSelect";
import classnames from 'classnames';
import { getCountrySuggestions } from './ReferenceDataFunctions';
import { Pagination } from './PaginationComponents';
import { PreviewSendGridTemplate } from '../Marketing/SendGrid/PreviewSendGridTemplate';
import PreviewTemplate from '../Marketing/PreviewTemplate';
import TwilioPreviewModal from '../Marketing/TwilioMessage/TwilioPreviewModal';

export const TemplateByCountry = injectIntl(({ campaignTemplates, templates, handleTemplates, handleDefaultTemplate, addTab, removeTab, intl, hasEgoi, hasTwillio, hasPagination, pagination, activeTab, toggle, channel, isJourneyBuilder, searchByApi, getTemplates }) => {
    const [Search, setSearch] = useState();
    const [searchParam, setSearchParam] = useState();

    const campTemp = campaignTemplates.filter(ct => ct.isDefault === false);
    const defaultTemp = campaignTemplates.filter(ct => ct.isDefault === true);
    const countryOptions = countryList().getData();

    const RenderTemplates = (item) => {
        let result = templates;

        if (Search && Search.length > 0) {
            result = templates.filter((temp) => {
                if (temp.templateName.toLowerCase().includes(Search.toLowerCase())) {
                    return true;
                }
                else if (temp.subject.toLowerCase().includes(Search.toLowerCase())) {
                    return true;
                }
                else if (item && Number(item.templateId) === Number(temp.id)) {
                    return true;
                }
            })
        }

        return result;
    }

    const searchTemplateName = (value) => {
        if (searchByApi)
            setSearchParam(value);
        else
            setSearch(value);
    }

    //Returns countries sorted by selection
    const getCountryValues = (coutries) => {
        const values = [];

        if (coutries) {
            coutries.forEach(c => {
                const country = countryOptions.find(opt => opt.value === c);

                if (country) values.push(country);
            })
        }

        return values;
    }

    const handleCountry = (combo, action, item) => {
        let countries = item.countries || [];

        if (action) {
            if (action.action === "select-option") {
                countries.push(action.option.value);
            }
            else if (action.action === "remove-value") {
                countries = countries.filter(c => c !== action.removedValue.value);
            }
            else if (action.action === "clear") {
                countries = []
            }
        }

        handleTemplates(null, countries, item);
    }

    const height = hasEgoi ? '500px' : '400px';

    const [previewTemplate, setpreviewTemplate] = useState();
    const [previewTwillioTemplate, setpreviewTwillioTemplate] = useState();
    const [previewEgoiTemplate, setpreviewEgoiTemplate] = useState();
    const [selectedTemplateId, setselectedTemplateId] = useState();
    const [selectedTemplateName, setselectedTemplateName] = useState();
    const [selectedTemplate, setselectedTemplate] = useState();

    const togglePreviewTwillioModal = (template) => {
        setselectedTemplate(template);
        setpreviewTwillioTemplate(!previewTwillioTemplate);
    }

    const togglePreviewModal = (id, name) => {
        setselectedTemplateId(id);
        setselectedTemplateName(name);
        setpreviewTemplate(!previewTemplate);
    }

    const toggleEgoiPreviewModal = (id, name) => {
        setselectedTemplateId(id);
        setselectedTemplateName(name);
        setpreviewEgoiTemplate(!previewEgoiTemplate)
    }

    return (
        <div>
            {
                previewTwillioTemplate ?
                    <TwilioPreviewModal                        
                        modal={previewTwillioTemplate}
                        toggleModal={togglePreviewTwillioModal}
                        template={selectedTemplate}
                    />
                    :
                    ''
            }
            {
                previewTemplate ?
                    <PreviewSendGridTemplate
                        isOpen={previewTemplate}
                        toggle={togglePreviewModal}
                        templateId={selectedTemplateId}
                        templateName={selectedTemplateName}
                    />
                    :
                    ''
            }
            {
                previewEgoiTemplate ?
                    <PreviewTemplate
                        modal={previewEgoiTemplate}
                        toggleModal={toggleEgoiPreviewModal}
                        templateId={selectedTemplateId}
                        channel={channel}
                    />
                    :
                    ''
            }
            <Nav tabs>
                <NavItem >
                    <NavLink className={classnames({ active: activeTab === 'Default' })} onClick={() => toggle('Default')}>
                        <span className='mb-2' /> Default
                    </NavLink>
                </NavItem>
                {campTemp && campTemp.map((ct, key) => {
                    const firstCountry = ct.countries && ct.countries.length > 0 ? ct.countries[0] : '';

                    return <NavItem key={key} >
                        <NavLink className={classnames({ active: activeTab === key })} onClick={() => toggle(key)}>
                            <span className={`flag-icon flag-icon-${firstCountry && firstCountry.toLowerCase()} mb-2 px-2`} />
                        </NavLink>
                    </NavItem>
                })}
                <NavItem>
                    <NavLink className="d-flex h-100">
                        <div>
                            <Button onClick={addTab} className=" btn-host btn-sm " style={{ 'fontSize': '0.35rem' }}  >
                                <i className="fas fa-plus"/>
                            </Button>
                        </div>
                    </NavLink>
                </NavItem>
                <NavItem className='ml-auto'>
                    <Form onSubmit={(e) => { e.preventDefault(); if (searchByApi) getTemplates(null, searchParam); }}>
                        <div className="d-flex align-items-center">
                            <Input
                                style={{ minHeight: '30px' }}
                                type='text'
                                value={Search}
                                placeholder={intl.formatMessage({ id: 'generic.table.search' })}
                                onChange={(e) => searchTemplateName(e.target.value)}
                            />

                            {searchByApi && getTemplates ?
                                <Button type="submit" className=" btn-host btn-sm ml-2" style={{ 'fontSize': '0.70rem' }}  >
                                    <i className="fas fa-search" />
                                </Button>
                            : ''}
                        </div>
                    </Form>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId='Default' className="pt-3">
                    {templates && Array.isArray(templates) && templates.length > 0 ?
                        <>
                            {templates && RenderTemplates(defaultTemp[0]).map((el, k) =>
                                <div onClick={() => handleDefaultTemplate(el.id, isJourneyBuilder ? el.templateName : undefined)} className="mb-3" style={{
                                    backgroundColor: defaultTemp && defaultTemp.length > 0 &&
                                    defaultTemp[0].templateId.toString() === el.id.toString() ? '#d3eaff' : 'white', width: '24%', display: 'inline-block', cursor: 'pointer',
                                    boxShadow: '0px 3px 15px #6f73881a', borderRadius: '5px', margin: '0 0.5%', height: height, overflow: 'hidden', padding: '1rem 0'
                                }} key={k}>
                                    <div className='mx-2'>
                                        {hasEgoi ?
                                            <RenderEgoiTemplate
                                                template={el}
                                                tooltip={`TooltipDefault${k}`}
                                                togglePreviewModal={toggleEgoiPreviewModal}
                                            />
                                            :
                                            hasTwillio && channel == 'sms' ?
                                                <RenderTwillioTemplate
                                                    template={el}
                                                    togglePreviewModal={togglePreviewTwillioModal}
                                                />
                                                :
                                                <RenderSendGridTemplate
                                                    template={el}
                                                    togglePreviewModal={togglePreviewModal}
                                                />
                                        }
                                    </div>
                                </div>
                            )}
                            
                            {hasPagination ?
                                <Row className="mb-3 mt-2">
                                    <Col className="text-right mr-3">
                                        <Pagination
                                            isPrevDisabled={pagination.isPrevDisabled}
                                            isNextDisabled={pagination.isNextDisabled}
                                            currentPage={pagination.currentPage}
                                            handlePrevButton={pagination.handlePrevButton}
                                            handleNextButton={pagination.handleNextButton}
                                        />
                                    </Col>
                                </Row>
                            : ''}
                        </>
                        : <h6 className="text-center mt-3"><FormattedMessage id="generic.NoEgoiTemplatesAvailable" /></h6>}
                </TabPane>

                {campTemp && campTemp.map((item, key) =>
                    <TabPane tabId={key} key={key} className="pt-3">
                        <div>                            
                            <Row className="mb-2">
                                <Col>
                                    <Label> <FormattedMessage id="Campaigns.Countries" /></Label>
                                </Col>
                                <Col className="col-2 text-right">
                                    <Button onClick={() => removeTab(item)} className=" btn-host btn-sm " >
                                        <i className="fas fa-trash-alt"></i>
                                    </Button>
                                </Col>
                            </Row>

                            <div className="mb-1">
                                <CountrySuggestion
                                    item={item}
                                    handleTemplates={handleTemplates}
                                />
                            </div>
                            <div className="mb-4">
                                <CustomSelect
                                    options={countryOptions}
                                    required
                                    isSearchable
                                    placeholder={<FormattedMessage id="Campaigns.Countries" />}
                                    onChange={(combo, action) => handleCountry(combo, action, item)}
                                    value={getCountryValues(item.countries)}
                                    isMulti
                                />
                            </div>

                            {templates && Array.isArray(templates) && templates.length > 0 ?
                                <>
                                    {templates && RenderTemplates(item).map((el, k) =>
                                        <div onClick={() => handleTemplates(el.id, undefined, item, isJourneyBuilder ? el.templateName : undefined)} className="mb-3" style={{
                                            backgroundColor: item.templateId &&
                                                item.templateId.toString() === el.id.toString() && '#d3eaff', width: '24%', display: 'inline-block',
                                            boxShadow: '0px 3px 15px #6f73881a', borderRadius: '5px', margin: '0 0.5%', height: height, overflow: 'hidden', paddingTop: '1rem'
                                        }} key={k}>
                                            <div className='mx-2'>
                                                {hasEgoi ?
                                                    <RenderEgoiTemplate
                                                        template={el}
                                                        tooltip={`TooltipDefault${k}`}
                                                        togglePreviewModal={toggleEgoiPreviewModal}
                                                    />
                                                    :
                                                    hasTwillio && channel == 'sms' ?
                                                        <RenderTwillioTemplate
                                                            template={el}
                                                            togglePreviewModal={togglePreviewTwillioModal}
                                                        />
                                                        :
                                                        <RenderSendGridTemplate
                                                            template={el}
                                                            togglePreviewModal={togglePreviewModal}
                                                        />
                                                }
                                            </div>
                                        </div>
                                    )}

                                    {hasPagination ?
                                        <Row className="mb-3 mt-2">
                                            <Col className="text-right mr-3">
                                                <Pagination
                                                    isPrevDisabled={pagination.isPrevDisabled}
                                                    isNextDisabled={pagination.isNextDisabled}
                                                    currentPage={pagination.currentPage}
                                                    handlePrevButton={pagination.handlePrevButton}
                                                    handleNextButton={pagination.handleNextButton}
                                                />
                                            </Col>
                                        </Row>
                                        : ''}
                                </>
                            : <h6 className="text-center mt-3"><FormattedMessage id="generic.NoEgoiTemplatesAvailable" /></h6>}
                        </div>
                    </TabPane>
                )}
            </TabContent>
        </div>
    );
});


export const CountrySuggestion = ({ item, handleTemplates }) => {

    const selectCountries = (countries, remove) => {
        let allCountries = []

        if (remove) {
            allCountries = item.countries.filter(country => !countries.some(el => el.value === country));
        }
        else if (item.countries && item.countries.length > 0) {
            allCountries = item.countries;

            countries.forEach(country => {
                if (!item.countries.some(el => el === country.value)) {
                    allCountries.push(country.value);
                }
            })
        }
        else {
            allCountries = countries.map(el => el.value)
        }

        handleTemplates(null, allCountries, item)
    }


    return <Card className = "border-0 shadow px-3 py-2" body style = {{ fontSize: '11px' }}>
        <Row className="align-items-center">
            <Col className="text-muted" sm={1}>
                <FormattedMessage id="Campaigns.Suggestions" />:
            </Col>
            <Col className="text-right">
                <div className="d-flex">
                    {getCountrySuggestions().map((lang, idx) => {
                        const isSelected = item.countries ? lang.countries.every(el => item.countries.some(c => c === el.value)) : false;

                        return <Card className="border-0 py-1 px-3 overbglightBlue pointer mr-4" key={idx}
                            onClick={() => selectCountries(lang.countries, isSelected)}
                            style={isSelected ? { backgroundColor: '#d3eaff' /* bg-host-light */ } : { backgroundColor: '#e6e6e67a'}}
                        >
                            {lang.label}
                        </Card>
                    })}
                </div>
            </Col>
        </Row>
    </Card>
}


export const TemplateValidations = ({ template, target, isPhone }) => {

    const HTMLTemplates = () => {
        var unknowend = [];
        var allMatches = [];
        const text = template.htmlBody ? template.htmlBody : template.text

        if (text) {
            allMatches = [...text.matchAll(new RegExp('{{(.*?)}}', 'gi'))].map(a => a[1]).filter(onlyUnique); 

            if ([...text.matchAll(new RegExp('{REMOVE_LINK}', 'gi'))].length > 0) { // Tag REMOVE_LINK por ter apenas 1 chaveta
                allMatches = allMatches.concat('REMOVE_LINK')
            }

            let mergeTags = [
                'profile.FirstName.value',
                'profile.LastName.value',
                'profile.MiddleName.value',
                'profile.FullName.value',
                'reservation.OnlineCheckinPin.value',
                'reservation.OnlineCheckinLink.value',
                'reservation.KeypassLink.value',
                'reservation.CheckIn.value',
                'reservation.CheckOut.value',
                'reservation.ResIdValue.value',
                'reservation.Adults.value',
                'reservation.Children.value',
                'reservation.Amount.value',
                'reservation.Room.value',
                'reservation.NumberOfNights.value',
                'reservation.Package.value',
                'reservation.DoorPin.value',
                'reservation.HotelName.value',
                'reservation.RoomNumber.value',
                'REMOVE_LINK',
                'reviewprourl.value',
                'formUrl.value',
                'doubleOptIn.value',
                'loyalty.CardNumber.value',
                'loyalty.CardPoints.value',
                'loyalty.CardLevel.value',
                'voucher.VoucherId.value',
                'voucher.ValidFrom.value',
                'voucher.ValidUntil.value',
                'voucher.VoucherValue.value',
                'googleWallet.value',
                'appleWallet.value'
            ];

            if(isPhone)
                mergeTags = [
                    'profile.FirstName.value',
                    'profile.LastName.value',
                    'profile.MiddleName.value',
                    'profile.FullName.value',
                    'reservation.OnlineCheckinPin.value',
                    'reservation.OnlineCheckinLink.value',
                    'reservation.KeypassLink.value',
                    'reservation.CheckIn.value',
                    'reservation.CheckOut.value',
                    'reservation.ResIdValue.value',
                    'reservation.Adults.value',
                    'reservation.Children.value',
                    'reservation.Amount.value',
                    'reservation.Room.value',
                    'reservation.NumberOfNights.value',
                    'reservation.Package.value',
                    'reservation.DoorPin.value',
                    'reservation.HotelName.value',
                    'reservation.RoomNumber.value',
                    'reviewprourl.value',
                    'formUrl.value',
                    'doubleOptIn.value',
                    'loyalty.CardNumber.value',
                    'loyalty.CardPoints.value',
                    'loyalty.CardLevel.value',
                    'voucher.VoucherId.value',
                    'voucher.ValidFrom.value',
                    'voucher.ValidUntil.value',
                    'voucher.VoucherValue.value',
                    'googleWallet.value',
                    'appleWallet.value'
                ]

            unknowend = allMatches.filter((match) => !mergeTags.some(tag => match === tag));

            unknowend.forEach((match) => {
                allMatches.splice(allMatches.indexOf(match), 1)
            })
        }
        return { allMatches, unknowend }
    }

    return (
        <div>
            {HTMLTemplates().unknowend.length > 0 ?
                <span style={{ color: '#EE6A5F' }} id={target}>
                    <b></b> <i className={`fas fa-lg fa-exclamation-circle`}></i>
                </span>
                : HTMLTemplates().allMatches.length > 0 &&
                <span style={{ color: '#76EFAC' }} id={target}>
                    <b></b> <i className={`fas fa-lg fa-check-circle`}></i>
                </span>
            }

            {HTMLTemplates().unknowend.length > 0 || HTMLTemplates().allMatches.length > 0 ?
                <UncontrolledTooltip target={target} placement="right" >
                    <div>
                        {HTMLTemplates().unknowend.length > 0 &&
                            <div>
                            <b><FormattedMessage id='Campaigns.UnknownedCustomFieldsUsed' />:</b>
                            {HTMLTemplates().unknowend.map((miss, key) =>
                                    <div key={key}>{miss}</div>
                                )}
                            </div>
                        }
                        {HTMLTemplates().allMatches.length > 0 &&
                            <div>
                            <b><FormattedMessage id='Campaigns.CustomFieldsUsed' />:</b>
                            {HTMLTemplates().allMatches.map((miss, key) =>
                                    <div key={key}>{miss}</div>
                                )}
                            </div>
                        }
                    </div>
                </UncontrolledTooltip>
            : ''}
        </div>
    )
}

export const TemplateSMSValidationsHelper = () => {
    return (
        <CommonHelper
            help={
                <div>
                    <b><FormattedMessage id="Campaigns.SupportedCustomFields" /></b>
                    <div>
                        <div>
                            <FormattedMessage id="Campaigns.FirstName" />:  {`{{profile.FirstName.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.LastName" />:  {`{{profile.LastName.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.MiddleName" />: {`{{profile.MiddleName.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.FullName" />: {`{{profile.FullName.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.OnlineCheckinPin" />:  {`{{reservation.OnlineCheckinPin.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.OnlineCheckinLink" />:  {`{{reservation.OnlineCheckinLink.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.KeypassLink" />: {`{{reservation.KeypassLink.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.CheckIn" />:  {`{{reservation.CheckIn.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.CheckOut" />: {`{{reservation.CheckOut.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.ResIdValue" />:  {`{{reservation.ResIdValue.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.Adults" />: {`{{reservation.Adults.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.Children" />:  {`{{reservation.Children.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.Amount" />:  {`{{reservation.Amount.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.Room" />:  {`{{reservation.Room.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.NumberOfNights" />:  {`{{reservation.NumberOfNights.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.Package" />:  {`{{reservation.Package.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.DoorPin" />:  {`{{reservation.DoorPin.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.HotelName" />:  {`{{reservation.HotelName.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.RoomNumber" />:  {`{{reservation.RoomNumber.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.CardNumber" />: {`{{loyalty.CardNumber.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.CardPoints" />: {`{{loyalty.CardPoints.value}}`} 
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.CardLevel" />: {`{{loyalty.CardLevel.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.ReviewProEncryptedUrl" />: {`{{reviewprourl.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.FormEncryptedUrl" />: {`{{formUrl.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.DoubleOptIn" />: {`{{doubleOptIn.value}}`}
                        </div>
                        {
                            global.modules?.some(m => m === 'HeyCard') ?
                                <div>
                                    <div>
                                        <FormattedMessage id="Campaigns.VoucherId" />: {`{{voucher.VoucherId.value}}`}
                                    </div>
                                    <div>
                                        <FormattedMessage id="Campaigns.ValidFrom" />: {`{{voucher.ValidFrom.value}}`}
                                    </div>
                                    <div>
                                        <FormattedMessage id="Campaigns.ValidUntil" />: {`{{voucher.ValidUntil.value}}`}
                                    </div>
                                    <div>
                                        <FormattedMessage id="Campaigns.VoucherValue" />: {`{{voucher.VoucherValue.value}}`}
                                    </div>
                                </div>
                                :
                                <div/>
                        }
                        {
                            global.modules?.some(m => m === 'GoogleWallet') ?
                                <div>
                                    <div>
                                        <FormattedMessage id="Campaigns.GoogleWallet" />: {`{{googleWallet.value}}`}
                                    </div>
                                </div>
                                :
                                <div />
                        }
                        {
                            global.modules?.some(m => m === 'AppleWallet') ?
                                <div>
                                    <div>
                                        <FormattedMessage id="Campaigns.AppleWallet" />: {`{{appleWallet.value}}`}
                                    </div>
                                </div>
                                :
                                <div />
                        }
                    </div>
                </div>
            }
            id="TemplateValidationsHelper"
        />
    )
}

export const TemplateValidationsHelper = () => {
    return (
        <CommonHelper
            help={
                <div>
                    <b><FormattedMessage id="Campaigns.SupportedCustomFields" /></b>
                    <div>
                        <div>
                            <FormattedMessage id="Campaigns.FirstName" />:  {`{{profile.FirstName.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.LastName" />:  {`{{profile.LastName.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.MiddleName" />: {`{{profile.MiddleName.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.FullName" />: {`{{profile.FullName.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.OnlineCheckinPin" />:  {`{{reservation.OnlineCheckinPin.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.ReviewProEncryptedUrl" />: {`{{reviewprourl.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.FormEncryptedUrl" />: {`{{formUrl.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.KeypassLink" />: {`{{reservation.KeypassLink.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.CheckIn" />:  {`{{reservation.CheckIn.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.CheckOut" />: {`{{reservation.CheckOut.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.ResIdValue" />:  {`{{reservation.ResIdValue.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.Adults" />: {`{{reservation.Adults.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.Children" />:  {`{{reservation.Children.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.Amount" />:  {`{{reservation.Amount.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.Room" />:  {`{{reservation.Room.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.NumberOfNights" />:  {`{{reservation.NumberOfNights.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.Package" />:  {`{{reservation.Package.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.DoorPin" />:  {`{{reservation.DoorPin.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.HotelName" />:  {`{{reservation.HotelName.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.RoomNumber" />:  {`{{reservation.RoomNumber.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.CardNumber" />: {`{{loyalty.CardNumber.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.CardPoints" />: {`{{loyalty.CardPoints.value}}`} 
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.CardLevel" />: {`{{loyalty.CardLevel.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.OnlineCheckinLink" />:  {`{{reservation.OnlineCheckinLink.value}}`}
                        </div>
                        <div>
                            <FormattedMessage id="Campaigns.DoubleOptIn" />: {`{{doubleOptIn.value}}`}
                        </div>
                        {
                            global.modules?.some(m => m === 'HeyCard') ?
                                <div>
                                    <div>
                                        <FormattedMessage id="Campaigns.VoucherId" />: {`{{voucher.VoucherId}}`}
                                    </div>
                                    <div>
                                        <FormattedMessage id="Campaigns.ValidFrom" />: {`{{voucher.ValidFrom}}`}
                                    </div>
                                    <div>
                                        <FormattedMessage id="Campaigns.ValidUntil" />: {`{{voucher.ValidUntil}}`}
                                    </div>
                                    <div>
                                        <FormattedMessage id="Campaigns.VoucherValue" />: {`{{voucher.VoucherValue}}`}
                                    </div>
                                </div>
                                :
                                <div />
                        }
                        {
                            global.modules?.some(m => m === 'GoogleWallet') ?
                                <div>
                                    <div>
                                        <FormattedMessage id="Campaigns.GoogleWallet" />: {`{{googleWallet.value}}`}
                                    </div>
                                </div>
                                :
                                <div />
                        }
                        {
                            global.modules?.some(m => m === 'AppleWallet') ?
                                <div>
                                    <div>
                                        <FormattedMessage id="Campaigns.AppleWallet" />: {`{{appleWallet.value}}`}
                                    </div>
                                </div>
                                :
                                <div />
                        }
                        <div>
                            <FormattedMessage id="Campaigns.Unsubscribe" />: {`{REMOVE_LINK}`}
                        </div>
                    </div>
                </div>
            }
            id="TemplateValidationsHelper"
        />
    )
}


export const RenderEgoiTemplate = ({ template, tooltip, togglePreviewModal }) => {
    return <>
        <div>                                        
            <Row>
                <Col className="col-8">
                    <h4 className="text-truncate" title={`${template.templateName}`}>{template.templateName}</h4>
                </Col>
                <Col>
                    <Row>
                        <Col className="col-4 mt-1">
                            <TemplateValidations template={template} target={tooltip} />
                        </Col>
                        <Col className="col-6">
                            <Button className="btn btn-host btn-sm" onClick={() => togglePreviewModal(template.id, template.templateName)}>
                                <i className="icon-icon-preview" />
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <div className="mb-2 ">{template.subject}</div>

            <div style={{height:'30px'}}>
                <div className='d-inline-block'>{template.clickTracking ? <Badge className="mb-2 p-2" color="primary">Click Tracking</Badge> : ''}</div>
                <div className='d-inline-block ml-2'>{template.openTracking ? <Badge className="mb-2 p-2" color="primary">Open Tracking</Badge> : ''}</div>
            </div>
        </div>
        <div style={{width: '100%',height:'auto'}}>
            <img src={template.thumbnail || template.thumbNail} style={{ width: 'inherit' }} alt={template.templateName} />
        </div>
    </>
}


//#region SendGrid

export const RenderSendGridTemplate = ({ template, togglePreviewModal }) => {
    return <>
        <div>
            <Row>
                <Col className="col-9">
                    <h4 className="text-truncate" title={`${template.templateName}`}>{template.templateName}</h4>
                </Col>
                <Col>
                    <Button className="btn btn-host btn-sm float-right" onClick={() => togglePreviewModal(template.id, template.name)}>
                        <i className="icon-icon-preview" />
                    </Button>
                </Col>
            </Row>
            <div className="mb-3">{template.subject}</div>
        </div>
        <div style={{ width: '100%', height: 'auto' }}>
            <img src={template.thumbnail_url} style={{ width: 'inherit' }} alt={template.templateName} />
        </div>
    </>
}

export const RenderTwillioTemplate = ({ template, togglePreviewModal }) => {
    return <>
        <div>
            <Row>
                <Col className="col-9">
                    <h4 className="text-truncate" title={`${template.templateName}`}>{template.templateName}</h4>
                </Col>
                <Col>
                    <Button className="btn btn-host btn-sm float-right" onClick={() => togglePreviewModal(template)}>
                        <i className="icon-icon-preview" />
                    </Button>
                </Col>
            </Row>
        </div>
        <div style={{ width: '100%', height: 'auto', backgroundColor: 'white', maxHeight: '350px' }}>
            <div className='p-4 veryCoolScrollBar' style={{ display: 'flex', flexDirection: 'column', maxHeight: '90%', overflow: 'auto' }}>
                <div
                    className='iMessage'
                    style={{
                        borderRadius: '20px', padding: '8px 15px', marginTop: '5px', marginBottom: '5px', backgroundColor: '#eee', position: 'relative',
                    }}
                >
                    <div style={{ maxHeight: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {template.text}
                    </div>
                </div>
            </div>
        </div>
    </>
}

const sendGridMergeTags = [
    { value: 'profile.FirstName', label: <FormattedMessage id="Campaigns.FirstName" /> },
    { value: 'profile.LastName', label: <FormattedMessage id="Campaigns.LastName" /> },
    { value: 'profile.MiddleName', label: <FormattedMessage id="Campaigns.MiddleName" /> },
    { value: 'profile.FullName', label: <FormattedMessage id="Campaigns.FullName" /> },
    { value: 'reservation.OnlineCheckinPin', label: <FormattedMessage id="Campaigns.OnlineCheckinPin" /> },
    { value: 'reservation.OnlineCheckinLink', label: <FormattedMessage id="Campaigns.OnlineCheckinLink" /> },
    { value: 'reservation.doubleOptIn', label: <FormattedMessage id="Campaigns.DoubleOptIn" /> },
    { value: 'reservation.KeypassLink', label: <FormattedMessage id="Campaigns.KeypassLink" /> },
    { value: 'reservation.CheckIn', label: <FormattedMessage id="Campaigns.CheckIn" /> },
    { value: 'reservation.CheckOut', label: <FormattedMessage id="Campaigns.CheckOut" /> },
    { value: 'reservation.ResIdValue', label: <FormattedMessage id="Campaigns.ResIdValue" /> },
    { value: 'reservation.Adults', label: <FormattedMessage id="Campaigns.Adults" /> },
    { value: 'reservation.Children', label: <FormattedMessage id="Campaigns.Children" /> },
    { value: 'reservation.Amount', label: <FormattedMessage id="Campaigns.Amount" /> },
    { value: 'reservation.Room', label: <FormattedMessage id="Campaigns.Room" /> },
    { value: 'reservation.NumberOfNights', label: <FormattedMessage id="Campaigns.NumberOfNights" /> },
    { value: 'reservation.Package', label: <FormattedMessage id="Campaigns.Package" /> },
    { value: 'reservation.DoorPin', label: <FormattedMessage id="Campaigns.DoorPin" /> },
    { value: 'reservation.HotelName', label: <FormattedMessage id="Campaigns.HotelName" /> },
    { value: 'reservation.RoomNumber', label: <FormattedMessage id="Campaigns.RoomNumber" /> },
    { value: 'reviewprourl', label: <FormattedMessage id="Campaigns.ReviewProEncryptedUrl" /> },
    { value: 'formUrl', label: <FormattedMessage id="Campaigns.FormEncryptedUrl" /> },
    { value: 'loyalty.CardNumber', label: <FormattedMessage id="Campaigns.CardNumber" /> },
    { value: 'loyalty.CardPoints', label: <FormattedMessage id="Campaigns.CardPoints" /> },
    { value: 'loyalty.CardLevel', label: <FormattedMessage id="Campaigns.CardLevel" /> },
    { value: 'REMOVE_LINK', label: <FormattedMessage id="Campaigns.Unsubscribe" /> },
    { value: 'doubleOptIn', label: <FormattedMessage id="Campaigns.DoubleOPtIn" /> },
    { value: 'doubleOptIn', label: <FormattedMessage id="Campaigns.DoubleOPtIn" /> },
    { value: 'voucher.VoucherId', label: <FormattedMessage id="Campaigns.VoucherId" /> },
    { value: 'voucher.ValidFrom', label: <FormattedMessage id="Campaigns.ValidFrom" /> },
    { value: 'voucher.ValidUntil', label: <FormattedMessage id="Campaigns.ValidUntil" /> },
    { value: 'voucher.VoucherValue', label: <FormattedMessage id="Campaigns.VoucherValue" /> },
    { value: 'googleWallet', label: <FormattedMessage id="Campaigns.GoogleWallet" /> },
    { value: 'appleWallet', label: <FormattedMessage id="Campaigns.AppleWallet" /> },
];

export const SendGridTemplateValidationsHelper = () => {
    return (
        <CommonHelper
            help={
                <div>
                    <b><FormattedMessage id="Campaigns.SupportedCustomFields" /></b>
                    <div>
                        {sendGridMergeTags.map((tag, key) =>
                            <div key={key}>
                                {tag.label}:  {`{{${tag.value}}}`}
                            </div>
                        )}
                    </div>
                </div>
            }
            id="TemplateValidationsHelper"
        />
    )
}
function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}
export const SendGridTemplateValidations = ({ template, target }) => {
    const HTMLTemplates = () => {
        var unknowend = [];
        var allMatches = [];

        if (template) {
            allMatches = [...template.matchAll(new RegExp('{{(.*?)}}', 'gi'))].map(a => a[1]).filter(onlyUnique);

            if ([...template.matchAll(new RegExp('{REMOVE_LINK}', 'gi'))].length > 0) { // Tag REMOVE_LINK por ter apenas 1 chaveta
                allMatches = allMatches.concat('REMOVE_LINK')
            }

            unknowend = allMatches.filter((match) => !sendGridMergeTags.some(tag => match === tag.value));

            unknowend.forEach((match) => {
                allMatches.splice(allMatches.indexOf(match), 1)
            });
        }
        return { allMatches, unknowend };
    }

    const htmlTemplates = HTMLTemplates();

    return (
        <div>
            {
                htmlTemplates.unknowend.length > 0 ?
                    <span style={{ color: '#EE6A5F' }} id={target}>
                        <b></b> <i className={`fas fa-lg fa-exclamation-circle`}></i>
                    </span>
                    :
                    htmlTemplates.allMatches.length > 0 &&
                        <span style={{ color: '#76EFAC' }} id={target}>
                            <b></b> <i className={`fas fa-lg fa-check-circle`}></i>
                        </span>
            }
            {
                htmlTemplates.unknowend.length > 0 || htmlTemplates.allMatches.length > 0 ?
                    <UncontrolledTooltip target={target} placement="right" >
                        <div>
                            {
                                htmlTemplates.unknowend.length > 0 &&
                                    <div>
                                        <b><FormattedMessage id='Campaigns.UnknownedCustomFieldsUsed' />:</b>
                                        {htmlTemplates.unknowend.map((miss, key) =>
                                            <div key={key}>{miss}</div>
                                        )}
                                    </div>
                            }
                            {
                                htmlTemplates.allMatches.length > 0 &&
                                    <div>
                                        <b><FormattedMessage id='Campaigns.CustomFieldsUsed' />:</b>
                                        {
                                            htmlTemplates.allMatches.map((miss, key) => <div key={key}>{miss}</div>)
                                        }
                                    </div>
                            }
                        </div>
                    </UncontrolledTooltip>
                    :
                    ''
            }
        </div>
    )
}

//#endregion