import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { IntlContext } from '../../Utils/IntlProviderWrapper';
import { FormattedMessage, injectIntl } from "react-intl";
import { setCountryLabelsEN, setCountryLabelsPT } from '../Base/ReferenceDataFunctions';
import { DateRangePicker } from 'react-dates';
import countryList from 'react-select-country-list';

const LanguageSwitch = ({intl}) => {
    const { switchToLangPT, switchToLangEN, locale } = React.useContext(IntlContext);
    const cssclasses = (locale === 'pt' ? 'flag-icon flag-icon-pt pr-2' : 'flag-icon flag-icon-us mr-2');

    if (!countryList().data.some(el => el.value === "XK")) {
        //Add kasovo country
        countryList().data.push({ value: 'XK', label: 'Kasovo' });
        countryList().labelMap.kasovo = "XK";
        countryList().valueMap.xk = "Kasovo";
    }

    if (locale === 'pt') {
        setCountryLabelsPT(countryList);
    }
    else {
        setCountryLabelsEN(countryList);
    }

    const originalDefaultProps = DateRangePicker.defaultProps;

    DateRangePicker.defaultProps = {
        ...originalDefaultProps,
        startDatePlaceholderText: intl.formatMessage({ id: "generic.StartDate" }),
        endDatePlaceholderText: intl.formatMessage({ id: "generic.EndDate" }),
    };

    return (
        <div >
            <UncontrolledDropdown direction={global.isMobile ? "right" : "left"}>
                <DropdownToggle tag="a" className={`dropdown-item ${global.isMobile ? 'pl-0' : ''}`} caret>
                    <i className={cssclasses} /> {locale}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header><FormattedMessage id="LanguageSwitch.ChangeLanguage" /></DropdownItem>
                    <DropdownItem onClick={switchToLangPT} active={locale === 'pt' ? true : false}>
                        <span className="flag-icon flag-icon-pt" /> PT
                    </DropdownItem>
                    <DropdownItem onClick={switchToLangEN} active={locale === 'en' ? true : false}>
                        <span className="flag-icon flag-icon-us" /> EN
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div> 
    );
}

export default injectIntl(LanguageSwitch);