import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl, FormattedNumber } from 'react-intl';
import { Button, Card, Col, Row, Form } from 'reactstrap';
import CustomSelect from '../Base/CustomSelect';
import moment from 'moment-timezone';
import { ExportToPDFButton, FilterButton, FormatAmountNumber } from '../Base/CommonUIComponents';
import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { ErrorAlert } from '../Base/ErrorAlert';
import { downloadExcelFromJson } from '../Base/ReferenceDataFunctions';

class SalesProcessExecutiveReportMonthly extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            block: true,
            monthlyStats: null,
            hotelReport: null,
            selectedCurrency: "EUR",
            segmentOptions: this.props.segmentList,
            subSegmentOptions: this.props.subSegmentList,
            metrics: [
                {
                    name: "numberOfSpaces",
                    type: "number"
                },
                {
                    name: "numberOfLodging",
                    type: "number"
                },
                {
                    name: "adr",
                    type: "value"
                },
                {
                    name: "lodging",
                    type: "value"
                },
                {
                    name: "fb",
                    type: "value"
                },
                {
                    name: "banquets",
                    type: "value"
                },
                {
                    name: "spaceRental",
                    type: "value"
                },
                {
                    name: "misc",
                    type: "value"
                },
                {
                    name: "totalValue",
                    type: "value"
                }
            ]
        };
    }

    componentDidMount() {
        this.getHotelMonthlyMetricsPrevYear();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.segmentList?.length !== this.props.segmentList?.length && prevProps.subSegmentList?.length !== this.props.subSegmentList?.length) {
            this.setState({
                segmentOptions: this.props.segmentList,
                subSegmentOptions: this.props.subSegmentList
            })
        }
    }

    getHotelMonthlyMetricsPrevYear = (e) => {
        if (e) e.preventDefault();

        const { selectedHotel, year } = this.props;
        const { selectedCurrency } = this.state;

        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    if (data.data?.length) {
                        this.setState({ prevYear: data.data[0].months }, () =>
                            this.getHotelMonthlyMetrics())
                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/executivereport/monthly?year=${year - 1}&hotelId=${selectedHotel}&needBudget=false&noCambio=${selectedCurrency !== "EUR"}`);
        });
    }

    getHotelMonthlyMetrics = () => {
        const { selectedHotel, year } = this.props;
        const { selectedCurrency, selectedSubSegment, selectedSegment } = this.state;
        
        let qs = ``;

        if (selectedSegment) qs += `&segment=${selectedSegment}`;
        if (selectedSubSegment) qs += `&subSegment=${selectedSubSegment}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.data?.length) {
                        this.initialCalc(data, selectedHotel);
                    return;
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/executivereport/monthly?year=${year}&hotelId=${selectedHotel}&noCambio=${selectedCurrency !== "EUR"}` + qs);
    }
    
    initialCalc = (data, selectedHotel) => {
        const fields = ['numberOfSpaces', 'numberOfLodging', 'adr', 'lodging', 'fb', 'banquets', 'spaceRental', 'misc', 'totalValue'];
        const { prevYear } = this.state;

        const yearData = data.data[0];

        const monthlyStats = yearData.months.map((m, idx) => {
            const definitiveStage = m.hotels[0]?.stagesTypes?.find(el => el.isDef);
            const definitiveStagePrev = prevYear[idx]?.hotels[0]?.stagesTypes?.find(el => el.isDef);

            const lodging = definitiveStage?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.lodging, 0) ?? 0;
            const numberOfLodging = definitiveStage?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.numberOfLodging, 0) ?? 0;

            const adr = lodging && numberOfLodging ? (Math.round((lodging / numberOfLodging) * 100) / 100) : null;
            //const trevpar = numberOfLodging ? (Math.round(((definitiveStage?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.totalValue, 0) ?? 0) / numberOfLodging) * 100) / 100) : null;

            const lodgingPrev = definitiveStagePrev?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.lodging, 0) ?? 0; 
            const numberOfLodgingPrev = definitiveStagePrev?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.numberOfLodging, 0) ?? 0;

            const adrPrev = lodgingPrev && numberOfLodgingPrev ? (Math.round((lodgingPrev / numberOfLodgingPrev) * 100) / 100) : null;
            //const trevparPrev = numberOfLodgingPrev ? (Math.round(((definitiveStagePrev?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.totalValue, 0) ?? 0) / numberOfLodgingPrev) * 100) / 100) : null;

            const prev = {
                adr: adrPrev,
                //trevpar: trevparPrev,
                lodging: lodgingPrev,
                numberOfLodging: numberOfLodgingPrev,
                fb: definitiveStagePrev?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.fb, 0) ?? 0,
                banquets: definitiveStagePrev?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.banquets, 0) ?? 0,
                misc: definitiveStagePrev?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.misc, 0) ?? 0,
                spaceRental: definitiveStagePrev?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.spaceRental, 0) ?? 0,
                totalValue: definitiveStagePrev?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.totalValue, 0) ?? 0,
                numberOfSpaces: definitiveStagePrev?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.numberOfSpaces, 0) ?? 0,
            }

            const budgetLodging = m.hotels[0]?.budget?.find(el => el.salesGroup === 'ROOM')?.value;
            const budgetNumberOfLodging = m.hotels[0]?.budget?.find(el => el.salesGroup === 'ROOM')?.roomNights;

            const budget = m.hotels[0]?.budget?.length > 0 ? {
                adr: budgetLodging && budgetNumberOfLodging ? (Math.round((budgetLodging / budgetNumberOfLodging) * 100) / 100) : null,
                //trevpar: budgetNumberOfLodging ? (Math.round((m.hotels[0].budget.reduce((acc, cur) => acc + cur.value, 0) / budgetNumberOfLodging) * 100) / 100) : null,
                lodging: budgetLodging,
                numberOfLodging: budgetNumberOfLodging,
                fb: m.hotels[0].budget.find(el => el.salesGroup === 'FB')?.value,
                banquets: m.hotels[0].budget.find(el => el.salesGroup === 'BANQ')?.value,
                misc: m.hotels[0].budget.find(el => el.salesGroup === 'ETC')?.value,
                spaceRental: m.hotels[0].budget.find(el => el.salesGroup === 'SPORT')?.value,
                totalValue: m.hotels[0].budget.reduce((acc, cur) => acc + cur.value, 0),
                numberOfSpaces: m.hotels[0].budget.find(el => el.salesGroup === 'SPORT')?.roomNights,
            }
                : null


            const object = {
                ...m,
                ...m.deals,
                adr,
                //trevpar,
                lodging,
                numberOfLodging,
                fb: definitiveStage?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.fb, 0) ?? 0,
                banquets: definitiveStage?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.banquets, 0) ?? 0,
                misc: definitiveStage?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.misc, 0) ?? 0,
                spaceRental: definitiveStage?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.spaceRental, 0) ?? 0,
                totalValue: definitiveStage?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.totalValue, 0) ?? 0,
                numberOfSpaces: definitiveStage?.sourceTypes.reduce((e, f) => e + f.revenueMetrics.numberOfSpaces, 0) ?? 0,
                budget,
                prev
            }


            object.diff = fields.reduce((acc, cur) => {
                if (object.budget && object.budget[cur] && object.budget[cur] != null) {
                    acc[cur] = object[cur] - object.budget[cur];
                }
                else acc[cur] = 0;

                return acc;
            }, {})


            return object;
        });

        const hotelName = global.hotelList.find(({ value }) => value == selectedHotel)?.label;
        const hotelCurrency = global.hotelList.find(x => x.value == selectedHotel)?.currency;
        const currencyList = [{ value: "EUR", label: "EUR"}];
        if (hotelCurrency !== "EUR") {
            currencyList.push({ value: hotelCurrency, label: hotelCurrency })
        }

        const qData = {
            q1Months: monthlyStats
                .filter(m =>
                    parseInt(moment(m.startDate).format('MM')) <= 3
                ),
            q2Months: monthlyStats
                .filter(m =>
                    (parseInt(moment(m.startDate).format('MM')) > 3) && (parseInt(moment(m.startDate).format('MM')) <= 6)
                ),
            q3Months: monthlyStats
                .filter(m =>
                    (parseInt(moment(m.startDate).format('MM')) > 6) && (parseInt(moment(m.startDate).format('MM')) <= 9)
                ),
            q4Months: monthlyStats
                .filter(m =>
                    parseInt(moment(m.startDate).format('MM')) > 9
                ),
        };

        const quarterData = Object.keys(qData).map((k, idx) => {
            const quarter = qData[k];

            const numberOfLodging = quarter.reduce((a, b) => a + b.numberOfLodging, 0);
            const lodging = quarter.reduce((a, b) => a + b.lodging, 0);
            const adr = lodging && numberOfLodging ? (Math.round((lodging / numberOfLodging) * 100) / 100) : null;
            //const trevpar = numberOfLodging ? (Math.round((quarter.reduce((a, b) => a + b.totalValue, 0) / numberOfLodging) * 100) / 100) : null;
            const fb = quarter.reduce((a, b) => a + b.fb, 0);
            const banquets = quarter.reduce((a, b) => a + b.banquets, 0);
            const spaceRental = quarter.reduce((a, b) => a + b.spaceRental, 0);
            const misc = quarter.reduce((a, b) => a + b.misc, 0);
            const numberOfSpaces = quarter.reduce((a, b) => a + b.numberOfSpaces, 0);
            const totalValue = quarter.reduce((a, b) => a + b.totalValue, 0);


            const prev = quarter.some(el => el.prev) ?
                quarter.reduce((acc, current) => {
                    if (current.prev) {
                        Object.keys(current.prev).forEach((key) => {
                            if (!acc[key]) acc[key] = 0;

                            acc[key] += current.prev[key] ?? 0;
                        });
                    }

                    return acc;
                }, {})
                : null;

            const budget = quarter.some(el => el.budget) ?
                quarter.reduce((acc, current) => {
                    if (current.budget) {
                        Object.keys(current.budget).forEach((key) => {
                            if (!acc[key]) acc[key] = 0;

                            acc[key] += current.budget[key] ?? 0;
                        });
                    }

                    return acc;
                }, {})
            : null;

            if(budget && budget.adr){
                budget.adr = budget.adr / (quarter.reduce((a, b) => a + (b.budget?.adr ? 1 : 0), 0));
            }
            /*if (budget && budget.trevpar) {
                budget.trevpar = budget.trevpar / (quarter.reduce((a, b) => a + (b.budget?.trevpar ? 1 : 0), 0));
            }*/

            const object = {
                idx: idx + 1,
                monthlyData: quarter,
                quarterData: {
                    numberOfLodging,
                    lodging,
                    adr,
                    //trevpar,
                    fb,
                    banquets,
                    spaceRental,
                    misc,
                    numberOfSpaces,
                    totalValue,
                    budget,
                    prev
                }
            }

            object.quarterData.diff = fields.reduce((acc, cur) => {
                if (object.quarterData.budget && object.quarterData.budget[cur] && object.quarterData.budget[cur] !== null) {
                    acc[cur] = object.quarterData[cur] - object.quarterData.budget[cur];
                }
                else acc[cur] = 0;

                return acc;
            }, {});


            return object;
        });


        const hotelReport = this.hotelReportCalc(yearData, fields, monthlyStats);

        this.setState({ block: false, quarterData, hotelName, hotelReport, hotelCurrency, currencyList });
    }

    hotelReportCalc = (yearData, fields, monthlyStats) => {
        const dealFields = ['numberOfDeals', 'numberOfWonDeals', 'numberOfLostDeals', 'proposalValueTotal', 'proposalValueWon', 'proposalValueLost'];
        
        const hotelReport = {
            budget: {},
            prev: {}
        };

        dealFields.forEach(a => {
            hotelReport[a] = yearData.deals[a];
        });

        monthlyStats.forEach(ms => {
            //calc actual and budget 
            fields.forEach(f => {
                if (!hotelReport[f]) hotelReport[f] = 0;
                hotelReport[f] += ms[f] ?? 0;

                if (ms.budget) {
                    if (!hotelReport.budget[f]) hotelReport.budget[f] = 0;
                    hotelReport.budget[f] += ms.budget[f] ?? 0;
                }

                if (ms.prev) {
                    if (!hotelReport.prev[f]) hotelReport.prev[f] = 0;
                    hotelReport.prev[f] += ms.prev[f] ?? 0;
                }
            })
        })


        hotelReport.budget.adr = hotelReport.budget.lodging && hotelReport.budget.numberOfLodging ? (Math.round((hotelReport.budget.lodging / hotelReport.budget.numberOfLodging) * 100) / 100) : null;
        hotelReport.prev.adr = hotelReport.prev.lodging && hotelReport.prev.numberOfLodging ? (Math.round((hotelReport.prev.lodging / hotelReport.prev.numberOfLodging))) : null;
        hotelReport.adr =  hotelReport.numberOfLodging ? (Math.round((hotelReport.lodging / hotelReport.numberOfLodging) * 100) / 100) : null;

        /*hotelReport.budget.trevpar = hotelReport.budget.numberOfLodging && hotelReport.budget.totalValue ? (Math.round((hotelReport.budget.totalValue / hotelReport.budget.numberOfLodging) * 100) / 100) : null;
        hotelReport.prev.trevpar = hotelReport.prev.numberOfLodging && hotelReport.prev.totalValue ? (Math.round((hotelReport.prev.totalValue / hotelReport.prev.numberOfLodging) * 100) / 100) : null;
        hotelReport.trevpar = hotelReport.numberOfLodging && hotelReport.totalValue ? (Math.round((hotelReport.totalValue / hotelReport.numberOfLodging) * 100) / 100) : null;*/

        hotelReport.diff = fields.reduce((acc, cur) => {
            if (hotelReport.budget[cur] && hotelReport.budget[cur] !== null) {
                acc[cur] = hotelReport[cur] - hotelReport.budget[cur];
            }
            else acc[cur] = 0;

            return acc
        }, {});



        return hotelReport;
    }

    hotelReportCalc2 = (monthlyStats, fields) => {
        const dealFields = ['numberOfDeals', 'numberOfWonDeals', 'numberOfLostDeals', 'proposalValueTotal', 'proposalValueWon', 'proposalValueLost'];
        
        const hotelReport = {
            budget: {},
            prev: {}
        };

        monthlyStats.forEach(ms => {
            //calc deal totals
            dealFields.forEach(df => {
                if (!hotelReport[df]) hotelReport[df] = 0;
                hotelReport[df] += ms.deals[df] ?? 0;
            })

            //calc actual and budget 
            fields.forEach(f => {
                if (!hotelReport[f]) hotelReport[f] = 0;
                hotelReport[f] += ms[f] ?? 0;

                if (ms.budget) {
                    if (!hotelReport.budget[f]) hotelReport.budget[f] = 0;
                    hotelReport.budget[f] += ms.budget[f] ?? 0;
                }

                if (ms.prev) {
                    if (!hotelReport.prev[f]) hotelReport.prev[f] = 0;
                    hotelReport.prev[f] += ms.prev[f] ?? 0;
                }
            })
        })


        hotelReport.budget.adr = hotelReport.budget.lodging && hotelReport.budget.numberOfLodging ? (Math.round((hotelReport.budget.lodging / hotelReport.budget.numberOfLodging) * 100) / 100) : null;
        hotelReport.prev.adr = hotelReport.prev.lodging && hotelReport.prev.numberOfLodging ? (Math.round((hotelReport.prev.lodging / hotelReport.prev.numberOfLodging))) : null;
        hotelReport.adr =  hotelReport.numberOfLodging ? (Math.round((hotelReport.lodging / hotelReport.numberOfLodging) * 100) / 100) : null;

        /*hotelReport.budget.trevpar = hotelReport.budget.numberOfLodging && hotelReport.budget.totalValue ? (Math.round((hotelReport.budget.totalValue / hotelReport.budget.numberOfLodging) * 100) / 100) : null;
        hotelReport.prev.trevpar = hotelReport.prev.numberOfLodging && hotelReport.prev.totalValue ? (Math.round((hotelReport.prev.totalValue / hotelReport.prev.numberOfLodging) * 100) / 100) : null;
        hotelReport.trevpar = hotelReport.numberOfLodging && hotelReport.totalValue ? (Math.round((hotelReport.totalValue / hotelReport.numberOfLodging) * 100) / 100) : null;*/

        hotelReport.diff = fields.reduce((acc, cur) => {
            if (hotelReport.budget[cur] && hotelReport.budget[cur] !== null) {
                acc[cur] = hotelReport[cur] - hotelReport.budget[cur];
            }
            else acc[cur] = 0;

            return acc
        }, {});



        return hotelReport;
    }

    handleYearChange = (combo) => {
        this.props.handleChangeYear(combo);
    }

    handleSelectedCurrency = (combo) => {
        this.setState({ selectedCurrency: combo.value }, () => this.getHotelMonthlyMetrics())
    }

    handleSegment = (combo, name) => {
        this.setState({
            [name]: combo ? combo.code : null
        }, () => this.filterRefData())
    }

    filterRefData = () => {
        const { selectedSegment, selectedSubSegment } = this.state;
        const { segmentList, subSegmentList } = this.props;
        let segmentOptions = [...segmentList], subSegmentOptions = [...subSegmentList];
        
        if (selectedSegment || selectedSubSegment) {
            segmentOptions = [...segmentList]?.filter(({ subSegments }) => !selectedSubSegment || subSegments.includes(selectedSubSegment));
            subSegmentOptions = [...subSegmentList]?.filter(({ segments }) => !selectedSegment || segments.includes(selectedSegment));
        }

        this.setState({ segmentOptions, subSegmentOptions })
    }

    downLoadFormatAmountNumber = (value, currency) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value).replaceAll(",", "");
    };

    downloadExcelData = () => {
        const { hotelReport, hotelName, quarterData, selectedCurrency, metrics } = this.state;
        const { year, intl } = this.props;

        const yearlyStats = [
            {
                "DealType": "Deals",
                "Nr": hotelReport?.numberOfDeals ?? 0,
                "Value": this.downLoadFormatAmountNumber(hotelReport?.proposalValueTotal ?? 0, selectedCurrency)
            },
            {
                "DealType": "Negotiation Deals",
                "Nr": (hotelReport?.numberOfDeals ?? 0) - (hotelReport?.numberOfLostDeals ?? 0) - (hotelReport?.numberOfWonDeals ?? 0),
                "Value": this.downLoadFormatAmountNumber((hotelReport?.proposalValueTotal ?? 0) - (hotelReport?.proposalValueLost ?? 0) - (hotelReport?.proposalValueLost ?? 0), selectedCurrency)
            },
            {
                "DealType": "Lost Deals",
                "Nr": hotelReport?.numberOfLostDeals ?? 0,
                "Value": this.downLoadFormatAmountNumber(hotelReport?.proposalValueLost ?? 0, selectedCurrency)
            },
            {
                "DealType": "Won Deals",
                "Nr": hotelReport?.numberOfWonDeals ?? 0,
                "Value": this.downLoadFormatAmountNumber(hotelReport?.proposalValueWon ?? 0, selectedCurrency)
            }
        ];

        const metricsData = metrics.map(metric => ({
            "Metric": intl.formatMessage({ id: `SalesProcess.${metric.name}` }),
            "Prev": hotelReport?.prev[metric.name] ?? '0',
            "Budget": hotelReport?.budget[metric.name] ?? '0',
            "Actual": hotelReport?.[metric.name] ?? '0',
            "Diff": hotelReport?.diff[metric.name] ?? '0'
        }));

        const quarterlyStats = quarterData.reduce((acc, q) => {
            q.monthlyData.forEach((m, idx) => {
                const month = moment(m.startDate).format('MMMM');
                metrics.forEach(metric => {
                    const metricName = intl.formatMessage({ id: `SalesProcess.${metric.name}` });
                    if (!acc[metricName]) acc[metricName] = {};
                    if (!acc[metricName][month]) acc[metricName][month] = {};
                    acc[metricName][month] = {
                        "Prev": m.prev?.[metric.name] ?? '0',
                        "Budget": m.budget?.[metric.name] ?? '0',
                        "Actual": m?.[metric.name] ?? '0',
                        "Diff": m.diff?.[metric.name] ?? '0'
                    };
                });
            });
            return acc;
        }, {});

        const quarterlyStatsData = Object.keys(quarterlyStats).map(metricName => {
            const row = { "Metric": metricName };
            Object.keys(quarterlyStats[metricName]).forEach(month => {
                row[`${month}-Prev`] = quarterlyStats[metricName][month]["Prev"];
                row[`${month}-Budget`] = quarterlyStats[metricName][month]["Budget"];
                row[`${month}-Actual`] = quarterlyStats[metricName][month]["Actual"];
                row[`${month}-Diff`] = quarterlyStats[metricName][month]["Diff"];
            });
            return row;
        });

        const jsonData = {
            "YearlyStats": yearlyStats,
            "Metrics": metricsData,
            "QuarterlyStats": quarterlyStatsData
        };

        downloadExcelFromJson(jsonData, `${hotelName} Report - ${year}`, intl, 'ExecutiveReportHeaders');
    };

    countFilters = (e) => {
        if (e) e.preventDefault();
        const { selectedSubSegment, selectedSegment, selectedCurrency, hotelCurrency } = this.state;
        const { year } = this.props;
        let count = 0;

        if (selectedSubSegment) count++;
        if (selectedSegment) count++;
        if (hotelCurrency !== "EUR" && selectedCurrency) count++;
        if (year) count++;

        return count;
    }

    render() {
        const { error, block, quarterData, hotelName, hotelReport, metrics, hotelCurrency, selectedCurrency, currencyList, segmentOptions, subSegmentOptions, selectedSubSegment, selectedSegment } = this.state;
        const { toggleTab, intl, year, yearOptions, selectedHotel } = this.props;

        return (
            <div className="border-0 h-100 pb-2">
                <Card id='executiveReportMonthlyPdf' className='border-0 shadow p-3 bg-white' style={{ minHeight: '90vh' }}>
                    <ErrorAlert error={error}/>
                    <BlockUi tag="div" blocking={block}>
                        <div>
                            <Form onSubmit={(e) => this.getHotelMonthlyMetricsPrevYear(e)}>
                                <Row className='d-flex align-items-center justify-content-between'>
                                    <Col className='d-flex align-items-center justify-content-start col-7'>
                                        <div onClick={_ => toggleTab("SalesProcessExecutiveReportYearly")} className='p-3 cursor-pointer'>
                                            <i className='fas fa-chevron-left' />
                                        </div>
                                        <div className='ml-2'>
                                            <div>
                                                <h5 className='m-0'>
                                                    <b className='mr-2'>{hotelName}</b>
                                                    <FormattedMessage id="SalesProcess.Report" />
                                                </h5>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className='col-5 d-flex align-items-center justify-content-end'>
                                        {hotelCurrency !== "EUR" ?
                                            <div style={{ minWidth: '150px' }}>
                                                <CustomSelect
                                                    options={currencyList}
                                                    value={currencyList?.find(({ value }) => value === selectedCurrency)}
                                                    onChange={this.handleSelectedCurrency}
                                                    placeholder={intl.formatMessage({ id: "Account.Currency" })}
                                                />
                                            </div>
                                            : ''
                                        }
                                        <div style={{ minWidth: '150px' }} className='ml-4'>
                                            <CustomSelect
                                                options={yearOptions}
                                                value={yearOptions.find(({ value }) => value === year)}
                                                isSearchable={true}
                                                onChange={this.handleYearChange}
                                                placeholder={intl.formatMessage({ id: "SalesProcess.Year" })}
                                            />
                                        </div>
                                        <div className='ml-4 d-flex align-items-center'>
                                            <div className="mr-4">
                                                <FilterButton totalFilters={this.countFilters()} butId={"buttonSubmit"}>
                                                    <div className="fullWidth">
                                                        <div className='mt-3'>
                                                            <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                                                <i className="fas fa-layer-group mr-2" />
                                                                <FormattedMessage id="SalesProcess.Segment" />
                                                            </div>
                                                            <div>
                                                                <CustomSelect
                                                                    options={segmentOptions}
                                                                    isClearable={true}
                                                                    value={segmentOptions?.filter(({ code }) => selectedSegment === code) || ''}
                                                                    isSearchable={true}
                                                                    onChange={e => this.handleSegment(e, 'selectedSegment')}
                                                                    placeholder={intl.formatMessage({ id: "SalesProcess.Segment" })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                                                <i className="fas fa-layer-group mr-2" />
                                                                <FormattedMessage id="SalesProcess.SubSegment" />
                                                            </div>
                                                            <div>
                                                                <CustomSelect
                                                                    options={subSegmentOptions}
                                                                    isClearable={true}
                                                                    value={subSegmentOptions?.filter(({ code }) => selectedSubSegment === code) || ''}
                                                                    isSearchable={true}
                                                                    onChange={e => this.handleSegment(e, 'selectedSubSegment')}
                                                                    placeholder={intl.formatMessage({ id: "SalesProcess.SubSegment" })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </FilterButton>
                                            </div>

                                            <div className="mr-2">
                                                <ExportToPDFButton name="executiveReportMonthlyPdf" fromPage="executiveReportHotel" />
                                            </div>

                                            <Button className="btn btn-host btn-sm" onClick={this.downloadExcelData}>
                                                <i className="fas fa-file-excel" />
                                            </Button>

                                            <Button className="btn btn-host btn-sm ml-2" type="submit" id="buttonSubmit">
                                                <i className='fas fa-search' />
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                            <hr className='mt-2 mb-4'/>
                            <div>
                                <div className='pt-1'>
                                    <h5 className='m-0'>
                                        <i className="mr-2 fas fa-calendar-alt"/>
                                        <FormattedMessage id="SalesProcess.YearlyStats"/>
                                    </h5>
                                </div>
                                <Row className='mt-4'>
                                    <Col className='col-12'>
                                        <Row style={{ fontSize: '0.9em' }}>
                                            <Col className='col-3'>
                                                <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px' }} onClick={_ => toggleTab("SalesDealList", selectedHotel, null, null, true)}>
                                                    <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                        <i className={`fas fa-briefcase mr-2`}/>
                                                        <FormattedMessage id='SalesProcess.TotalDeals'/>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                        <div>
                                                            {hotelReport?.numberOfDeals??'0'}
                                                        </div>
                                                        <div>
                                                            <FormatAmountNumber
                                                                value={hotelReport?.proposalValueTotal ?? '0'}
                                                                currency={selectedCurrency}
                                                                hideDecimals={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col className='col-3'>
                                                <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px', color: '#0665ff' }} onClick={_ => toggleTab("SalesDealList", selectedHotel, null, null, true, null, ["WaitingHotel", "WaitingClient"])}>
                                                    <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                        <i className={`fas fa-people-arrows mr-2`}/>
                                                        <FormattedMessage id='SalesProcess.NegotiationDeals'/>
                                                    </div>
                                                    {(() => {
                                                        const number = (hotelReport?.numberOfDeals??0) - ((hotelReport?.numberOfLostDeals??0) + (hotelReport?.numberOfWonDeals??0));

                                                        const lost = hotelReport?.proposalValueLost??0;
                                                        const won = hotelReport?.proposalValueWon??0;
                                                        const total = hotelReport?.proposalValueTotal??0;
                                                        const wonAndLost = won + lost;
                                                        const value = total - wonAndLost;

                                                        return (
                                                            <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                                <div>
                                                                    {number}
                                                                </div>
                                                                <div>
                                                                    <FormatAmountNumber
                                                                        value={value}
                                                                        currency={selectedCurrency}
                                                                        hideDecimals={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        );
                                                    })()}
                                                </Card>
                                            </Col>
                                            <Col className='col-3'>
                                                <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px', color: '#F38375' }} onClick={_ => toggleTab("SalesDealList", selectedHotel, null, null, true, null, "Cancelled")}>
                                                    <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                        <i className={`far fa-thumbs-down mr-2`}/>
                                                        <FormattedMessage id='SalesProcess.LostDeals'/>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                        <div>
                                                            {hotelReport?.numberOfLostDeals??'0'}
                                                        </div>
                                                        <div>
                                                            <FormatAmountNumber
                                                                value={hotelReport?.proposalValueLost ?? '0'}
                                                                currency={selectedCurrency}
                                                                hideDecimals={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                            <Col className='col-3'>
                                                <Card className='shadow bg-white border-0 p-3 pointer' style={{ borderRadius: '5px', color: '#36ad51' }} onClick={_ => toggleTab("SalesDealList", selectedHotel, null, null, true, null, ["Finished", "Effective"])}>
                                                    <div className='text-center text-muted' style={{ fontSize: '1.2em' }}>
                                                        <i className={`fas fa-trophy mr-2`}/>
                                                        <FormattedMessage id='SalesProcess.WonDeals'/>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between mt-2' style={{ fontSize: '1.4em' }}>
                                                        <div>
                                                            {hotelReport?.numberOfWonDeals??'0'}
                                                        </div>
                                                        <div>
                                                            <FormatAmountNumber
                                                                value={hotelReport?.proposalValueWon ?? '0'}
                                                                currency={selectedCurrency}
                                                                hideDecimals={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {hotelReport ?
                                        <Col className='col-12 mt-3' style={{ fontSize: '0.9em' }}>
                                            <Card className='shadow bg-white border-0 p-3 h-100' style={{ borderRadius: '5px' }}>
                                                <div className='overflow-auto scrollableDiv'>
                                                    <div className='d-flex align-items-center justify-content-between px-3 py-1' style={{ borderRadius: '4px' }}>
                                                        <div style={{ minWidth: '150px' }}>
                                                            <b>
                                                                <FormattedMessage id="SalesProcess.Metrics"/>
                                                            </b>
                                                        </div>
                                                        <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                            <div className='text-truncate'>
                                                                <b><FormattedMessage id="SalesProcess.PreviousYear"/></b>
                                                            </div>
                                                        </div>
                                                        <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                            <div className='text-truncate'>
                                                                <b><FormattedMessage id="SalesProcess.Budget"/></b>
                                                            </div>
                                                        </div>
                                                        <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                            <div className='text-truncate'>
                                                                <b><FormattedMessage id="SalesProcess.Actual"/></b>
                                                            </div>
                                                        </div>
                                                        <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                            <div className='text-truncate'>
                                                                <b><FormattedMessage id="SalesProcess.Diff"/></b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='mt-2'>
                                                        {metrics.map((metric, k) =>
                                                            <div key={k} style={{ borderTop: k ? '1px solid lightgrey' : '', background: (k + 1) === metrics.length ? '#e9e9e9' : k === 2 ? 'rgb(248, 248, 248)' :  '' }} className='py-2 px-3 d-flex align-items-center justify-content-between'>
                                                                <div className='text-truncate d-flex align-items-center' style={{ minWidth: '150px' }}>
                                                                    <div className='text-truncate text-center'>
                                                                        <FormattedMessage id={`SalesProcess.${metric.name}`}/>
                                                                    </div>
                                                                </div>
                                                                <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                                    <div className='text-truncate'>
                                                                        <RenderCellValue object={hotelReport.prev} metric={metric} showNulls={true} selectedCurrency={selectedCurrency} hideDecimals={true} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                                    <div className='text-truncate'>
                                                                        <RenderCellValue object={hotelReport.budget} metric={metric} showNulls={true} hideDecimals={true} selectedCurrency={selectedCurrency} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                                    <div className='text-truncate'>
                                                                        {metric.name === "totalValue" ?
                                                                            <span className='cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesDealList", selectedHotel, null, null, true)}>
                                                                                <RenderCellValue object={hotelReport} metric={metric} selectedCurrency={selectedCurrency} hideDecimals={true} />
                                                                            </span>
                                                                            :
                                                                            <RenderCellValue object={hotelReport} metric={metric} selectedCurrency={selectedCurrency} hideDecimals={true} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                                    <div className='text-truncate'>
                                                                        <RenderCellValue object={hotelReport.diff} metric={metric} showNulls={true} hideDecimals={true} showCompareValueIcon={true} selectedCurrency={selectedCurrency} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                    :''}
                                </Row>
                            </div>
                            <div className='mt-4'>
                                <div className='pt-1'>
                                    <h5 className='m-0'>
                                        <i className="mr-2 fas fa-calendar-week"/>
                                        <FormattedMessage id="SalesProcess.QuarterlyStats"/>
                                    </h5>
                                </div>
                                <Row className='mt-2'>
                                    {quarterData ?
                                        quarterData.map((q, k) =>
                                            <Col key={k} className='col-12 mt-3' style={{ fontSize: '0.9em' }}>
                                                <Card className='shadow bg-white border-0 p-3 h-100' style={{ borderRadius: '5px' }}>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div className='text-muted'>
                                                            <FormattedMessage id="SalesProcess.Quarter"/>
                                                            <span className='ml-1'>{q.idx}</span>
                                                        </div>
                                                    </div>
                                                    <div className='overflow-auto mt-2 scrollableDiv'>
                                                        <div className='d-flex align-items-center justify-content-between px-3 py-1' style={{ borderRadius: '4px' }}>
                                                            <div style={{ minWidth: '150px' }}>
                                                                <b>
                                                                    <FormattedMessage id="SalesProcess.Metrics"/>
                                                                </b>
                                                            </div>
                                                            {q.monthlyData.map((m, k) =>
                                                                <div style={{ borderLeft: '1px solid #a5a5a5' }} key={k} className='w-100 text-center'>
                                                                    <div className='w-100'>
                                                                        <b>{moment(m.startDate).format('MMMM')}</b>
                                                                    </div>
                                                                    <div className='mt-1 w-100 d-flex align-items-center justify-content-between text-muted' style={{ gap: '5%' }}>
                                                                        <div className='w-100 text-center'>
                                                                            <div className='text-truncate'>
                                                                                <FormattedMessage id="SalesProcess.PreviousYear"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className='w-100 text-center'>
                                                                            <div className='text-truncate'>
                                                                                <FormattedMessage id="SalesProcess.Budget"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className='w-100 text-center'>
                                                                            <div className='text-truncate'>
                                                                                <FormattedMessage id="SalesProcess.Actual"/>
                                                                            </div>
                                                                        </div>
                                                                        <div className='w-100 text-center'>
                                                                            <div className='text-truncate'>
                                                                                <FormattedMessage id="SalesProcess.Diff"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div style={{ borderLeft: '1px solid #a5a5a5' }} className='w-100 text-center'>
                                                                <div className='w-100'>
                                                                    <b><FormattedMessage id="SalesProcess.Total"/></b>
                                                                </div>
                                                                <div className='mt-1 w-100 d-flex align-items-center justify-content-between text-muted' style={{ gap: '5%' }}>
                                                                    <div className='w-100'>
                                                                        <div className='text-truncate'>
                                                                            <FormattedMessage id="SalesProcess.PreviousYear"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className='w-100'>
                                                                        <div className='text-truncate'>
                                                                            <FormattedMessage id="SalesProcess.Budget"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className='w-100'>
                                                                        <div className='text-truncate'>
                                                                            <FormattedMessage id="SalesProcess.Actual"/>
                                                                        </div>
                                                                    </div>
                                                                    <div className='w-100'>
                                                                        <div className='text-truncate'>
                                                                            <FormattedMessage id="SalesProcess.Diff"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mt-2'>
                                                            {metrics.map((metric, k) =>
                                                                <div key={k} style={{ borderTop: k ? '1px solid lightgrey' : '', background: (k + 1) === metrics.length ? '#e9e9e9' : k === 2 ? 'rgb(248, 248, 248)' : '' }} className='py-2 px-3 d-flex align-items-center justify-content-between'>
                                                                    <div className='text-truncate d-flex align-items-center' style={{ minWidth: '150px' }}>
                                                                        <div className='text-truncate text-center'>
                                                                            <FormattedMessage id={`SalesProcess.${metric.name}`}/>
                                                                        </div>
                                                                    </div>
                                                                    {q.monthlyData.map((m, k) =>
                                                                        <div key={k} className='w-100 d-flex align-items-center justify-content-between' style={{ gap: '5%', borderLeft: '1px solid #a5a5a5', fontSize: "0.9em" }}>
                                                                            <div className='w-100 text-center text-truncate'>
                                                                                <RenderCellValue object={m.prev} metric={metric} showNulls={true} selectedCurrency={selectedCurrency} hideDecimals={true} />
                                                                            </div>
                                                                            <div className='w-100 text-center text-truncate'>
                                                                                <RenderCellValue object={m.budget} metric={metric} showNulls={true} hideDecimals={true} selectedCurrency={selectedCurrency} />
                                                                            </div>
                                                                            <div className='w-100 text-center text-truncate'>
                                                                                <span>
                                                                                    {metric.name === "numberOfLodging" || metric.name === "numberOfSpaces" ?
                                                                                        <span className='cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesProcessResources", selectedHotel, null, moment(m.startDate).format('MM'), true, metric.name === "numberOfSpaces")}>
                                                                                            <RenderCellValue object={m} metric={metric} selectedCurrency={selectedCurrency} hideDecimals={true} />
                                                                                        </span>
                                                                                    : metric.name === "totalValue" ?
                                                                                        <span className='cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesDealList", selectedHotel, null, moment(m.startDate).format('MM'), true)}>
                                                                                                <RenderCellValue object={m} metric={metric} selectedCurrency={selectedCurrency} hideDecimals={true} />
                                                                                        </span>
                                                                                        :
                                                                                            <RenderCellValue object={m} metric={metric} selectedCurrency={selectedCurrency} hideDecimals={true} />
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className='w-100 text-center text-truncate'>
                                                                                <RenderCellValue showCompareValueIcon={true} object={m.diff} metric={metric} showNulls={true} hideDecimals={true} selectedCurrency={selectedCurrency} />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    <div key={k} className='w-100 d-flex align-items-center justify-content-between' style={{ gap: '5%', borderLeft: '1px solid #a5a5a5', fontSize: "0.9em" }}>
                                                                        <div className='w-100 text-center text-truncate'>
                                                                            <RenderCellValue object={q.quarterData.prev} metric={metric} showNulls={true} hideDecimals={true} selectedCurrency={selectedCurrency} />
                                                                        </div>
                                                                        <div className='w-100 text-center text-truncate'>
                                                                            <RenderCellValue object={q.quarterData.budget} metric={metric} showNulls={true} hideDecimals={true} selectedCurrency={selectedCurrency} />
                                                                        </div>
                                                                        <div className='w-100 text-center text-truncate'>
                                                                            <span>
                                                                                {metric.name === "totalValue" ?
                                                                                    <span className='cursor-pointer hrefStyle' onClick={_ => toggleTab("SalesDealList", selectedHotel, null, moment(q.monthlyData[0].startDate).format('MM'), true, null, null, true)}>
                                                                                        <RenderCellValue object={q.quarterData} metric={metric} selectedCurrency={selectedCurrency} hideDecimals={true} />
                                                                                    </span>
                                                                                    : 
                                                                                    <RenderCellValue object={q.quarterData} metric={metric} selectedCurrency={selectedCurrency} hideDecimals={true} />
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        <div className='w-100 text-center text-truncate'>
                                                                            <RenderCellValue showCompareValueIcon={true} object={q.quarterData.diff} metric={metric} showNulls={true} hideDecimals={true} selectedCurrency={selectedCurrency} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                        )
                                    :''}
                                </Row>
                            </div>
                        </div>
                    </BlockUi>
                </Card>
            </div>
        );
    }
}

export default injectIntl(SalesProcessExecutiveReportMonthly);


const RenderCellValue = ({ metric, object, showNulls, showCompareValueIcon, hideDecimals, selectedCurrency }) => {
    if (object) {
        const value = object[metric.name];

        if (showNulls && !value) {
            return '-'
        }

        if (metric.type === "value")
            return <span title={value ?? 0} className={!showCompareValueIcon ? '' : value > 0 ? 'text-success' : 'text-danger'}><FormatAmountNumber value={value ?? 0} hideDecimals={hideDecimals} currency={selectedCurrency} /></span>
        else if (metric.type === "percentage")
            return <span title={value ?? 0} className={!showCompareValueIcon ? '' : value > 0 ? 'text-success' : 'text-danger'}>{value ?? 0}%</span>
        else
            return <span title={value ?? 0} className={!showCompareValueIcon ? '' : value > 0 ? 'text-success' : 'text-danger'}>
                <FormattedNumber value={value ?? 0} />
            </span>
    }
    else {
        return '-';
    }
}