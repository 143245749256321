import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Row, BreadcrumbItem, Breadcrumb } from 'reactstrap';
import { handleNotification } from "../../Base/Notification";
import { BlankCard } from '../../Base/CommonUIComponents';
import { postAPI, getAPI } from "../../Base/API";
import AlertStep1 from './AlertStep1';
import AlertStep2 from './AlertStep2';
import { Link } from "react-router-dom";

class MarketingAlertDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            hasEgoi: false,
            hasSendGrid: false,
            hasTwillio: false,
            alert: this.props.location.alert ? this.props.location.alert : null,
            steps: [
                { step: 1, active: true },
                { step: 2, active: false }
            ],
            collectMetricsForGoogleAnalytics: false,
            invalidCampaignName: false
        };
        this.form = React.createRef();
    }

    componentDidMount() {
        window.setTimeout(
            () => {
                this.setState({
                    hasEgoi: global.modules && global.modules.some(m => m === 'Egoi'),
                    hasSendGrid: global.modules && global.modules.some(m => m === 'SendGrid'),
                    hasTwillio: global.modules && global.modules.some(m => m === 'TwilioMessaging')
                });
            },
            global.modules ? 0 : 2500
        );

        if (!this.state.alert && this.props.match.params.id) {
            this.getAlert();
        }
    }

    getAlert = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.response) {
                    const collectMetricsForGoogleAnalytics = data.response[0].campaignName !== null && data.response[0].campaignName !== undefined;
                    if (!data.response[0].loyaltyCardTypeConfigCode) {
                        data.response[0].loyaltyCardTypeConfigCode = null;
                    }

                    this.setState({ error: errorMessage, block: false, alert: data.response[0], collectMetricsForGoogleAnalytics });
                }
                else {
                    this.setState({ error: errorMessage, block: false, alert: {} });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Marketing/v1/marketingalert/${this.props.match.params.id}`);
    }

    saveAlert = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var alert = { ...this.state.alert };

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="MarketingAlertDetails.AlertSaved" />, <FormattedMessage id="generic.success" />);
                    this.props.history.push({ pathname: `/MarketingAlertDetails/${data.response[0].id}` });
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/marketingalert`, JSON.stringify({ request: alert }));
    }

    nextStep = (val) => {
        if (!this.form.current.reportValidity()) {
            return;
        }
        var stateCopy = Object.assign({}, this.state);
        stateCopy.steps = stateCopy.steps.slice();
        for (var i = 0; i < stateCopy.steps.length; i++) {
            if (stateCopy.steps[i].step === val) {
                stateCopy.steps[i] = Object.assign({}, stateCopy.steps[i]);
                stateCopy.steps[i].active = true;
            }
            else {
                stateCopy.steps[i] = Object.assign({}, stateCopy.steps[i]);
                stateCopy.steps[i].active = false;
            }
        }
        this.setState(stateCopy);
    }

    movePos = (val) => {
        this.nextStep(this.state.steps.find(el => el.active === true).step + val);
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            alert: {
                ...prevState.alert,
                [name]: value
            }
        }))
    }

    handleCardType = (combo, evt) => {
        if (evt) {
            this.setState(prevState => ({
                alert: {
                    ...prevState.alert,
                    loyaltyCardTypeConfigCode: combo && combo.value,
                    loyaltyCardLevelConfigCode: null
                }
            }))
        }
    }

    handleSwitch = (name, combo) => {
        this.setState(prevState => ({
            [name]: combo && combo.value,
            invalidCampaignName: false,
            alert: {
                ...prevState.alert,
                campaignName: null
            }
        }));
    }

    handleAlertType = (alertTypeValue) => {
        const { alert } = this.state;

        if (alert.alertType !== alertTypeValue) {
            this.setState(prevState => ({
                alert: {
                    ...prevState.alert,
                    alertType: alertTypeValue,
                    listId: null,
                    loyaltyCardTypeConfigCode: null,
                    loyaltyCardLevelConfigCode: null,
                    voucherAmount: null,
                    voucherEndDate: null,
                    voucherIssueCode: null,
                    voucherTypeId: null
                }
            }));
        }
    }

    handleSelect = (name, combo) => {
        this.setState(prevState => ({
            alert: {
                ...prevState.alert,
                [name]: combo ? combo.value : null
            }
        }));
    }

    handleArrays = (name, value, isToDelete) => {
        let array = this.state.alert[name] ? this.state.alert[name] : [];

        if (isToDelete) {
            array = array.filter(a => a !== value);
        }
        else {
            array.push(value);
        }

        this.setState(prevState => ({
            alert: {
                ...prevState.alert,
                [name]: array
            }
        }))
    }

    handleChangeAction = (e) => {
        const { name, value } = e.target;
        let action = this.state.alert && this.state.alert.marketingAlertActions ? this.state.alert.marketingAlertActions[0] : {};
        action[name] = value;

        this.setState(prevState => ({
            alert: {
                ...prevState.alert,
                marketingAlertActions: [ action ]
            }
        }))
    }

    upsertAction = (action) => {
        this.setState({ block: true });
        let actions = this.state.alert && this.state.alert.marketingAlertActions ? this.state.alert.marketingAlertActions : [];
        const index = actions && actions.findIndex(ac => action.id && ac.id === action.id);

        if (index === -1) {
            actions.push(action);
        }
        else {
            actions[index] = action;
        }

        this.setState({ alert: { ...this.state.alert, marketingAlertActions: actions }, block: false });
    }

    removeAction = (actionId) => {
        this.setState(prevState => ({
            alert: {
                ...prevState.alert,
                marketingAlertActions: prevState.alert.marketingAlertActions.filter(a => a.id !== actionId)
            }
        }));
    }

    isDisabled = () => {
        const { alert, invalidCampaignName } = this.state;
        let isDisabled = true;

        if (this.state.steps[0].active === true && this.state.alert !== null && this.state.alert.name && this.state.alert.active !== undefined && alert.alertType && !invalidCampaignName) {
            if (alert.alertType === 'Review' && alert.fieldValue && alert.fieldType && alert.fieldCompare !== undefined) {
                isDisabled = false;
            }
            if (alert.alertType === 'Survey' && alert.formId) {
                isDisabled = false;
            }
            if (alert.alertType === 'LoyaltyCardIssue'){
                isDisabled = false;
            }
            if (alert.alertType === 'LoyaltyCardLevelUp') {
                isDisabled = false;
            }
            if (alert.alertType === 'FormSubmit') {
                isDisabled = false;
            }
        }

        if (this.state.steps[1].active === true && this.state.alert !== null && this.state.alert.marketingAlertActions && this.state.alert.marketingAlertActions.length > 0) {
            isDisabled = false;
        }

        return isDisabled
    }

    handleVoucherChange = (voucher) => {
        if (voucher) {
            this.setState(prevState => ({
                alert: {
                    ...prevState.alert,
                    voucherTypeId: voucher.value,
                    voucherEndDate: voucher.voucherEndDate,
                    voucherAmount: voucher.voucherAmount,
                    voucherIssueCode: null
                }
            }))
        }
        else {
            this.setState(prevState => ({
                alert: {
                    ...prevState.alert,
                    voucherTypeId: null,
                    voucherEndDate: null,
                    voucherAmount: null,
                    voucherIssueCode: null
                }
            }))
        }
        
    }

    validateCampaignName = (evt) => {
        evt.preventDefault();
        const { alert } = this.state;
        const regex = /^[A-Za-z\d]{1,}$/i;

        if (alert.campaignName && (!regex.test(alert.campaignName))) {
            this.setState({ invalidCampaignName: true });
        }
        else {
            this.setState({ invalidCampaignName: false });
        }
    }

    render() {
        const { block, error, alert, steps, collectMetricsForGoogleAnalytics, invalidCampaignName } = this.state;

        return (
            <BlankCard block={block} error={error}>
                <div>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/MarketingAlerts"> <FormattedMessage id="NavMenu.Alerts"/> </Link></BreadcrumbItem>
                        <BreadcrumbItem active><FormattedMessage id="MarketingAlert.AlertDetails"/></BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <Row className="mb-2">
                    <Col className="col-1 text-left d-flex align-items-center">
                        {steps[0].active === false ?
                            <Button className="btn- btn-host btn-small mr-4" style={{ 'flex': '1' }} onClick={this.movePos.bind(this, -1)}>
                                <i className="fas fa-angle-left" />
                            </Button>
                            : ''}
                    </Col>

                    <Col className="py-1 mb-4">
                        <ul className="steps">
                            <li className={'step ' + (steps[0].active === true ? ' step-active' : '')} style={{ cursor: 'pointer' }}>
                                <div className="step-content">
                                    <span className="step-text small">
                                        <FormattedMessage id="CampaignDetails.Step1" />
                                    </span>
                                    <span className="step-circle" />
                                </div>
                            </li>
                            <li className={'step ' + (steps[1].active === true ? ' step-active' : '')} style={{ cursor: 'pointer' }}>
                                <div className="step-content">
                                    <span className="step-text small">
                                        <FormattedMessage id="CampaignDetails.Step2" />
                                    </span>
                                    <span className="step-circle" />
                                </div>
                            </li>
                        </ul>
                    </Col>

                    <Col className="col-1 text-right d-flex align-items-center">
                        {steps[1].active === true ?
                            <Button className="btn- btn-host btn-small ml-4" style={{ 'flex': '1' }} onClick={(e) => this.saveAlert(e)} disabled={this.isDisabled()}>
                                <i className="fas fa-save" />
                            </Button>
                            :
                            <Button className="btn- btn-host btn-small ml-4" style={{ 'flex': '1' }} onClick={this.movePos.bind(this, 1)} disabled={this.isDisabled()}>
                                <i className="fas fa-angle-right" />
                            </Button>
                        }
                    </Col>
                </Row>
                <form ref={this.form}>
                    {steps[0].active === true ?
                        <AlertStep1
                            alert={alert}
                            handleChange={this.handleChange}
                            handleSelect={this.handleSelect}
                            handleVoucherChange={this.handleVoucherChange}
                            hasSendGrid={this.state.hasSendGrid}
                            collectMetricsForGoogleAnalytics={collectMetricsForGoogleAnalytics}
                            invalidCampaignName={invalidCampaignName}
                            handleSwitch={this.handleSwitch}
                            validateCampaignName={this.validateCampaignName}                       
                            handleCardType={this.handleCardType}                       
                            handleAlertType={this.handleAlertType}                       
                        />
                    : ''}

                    {steps[1].active === true ?
                        <AlertStep2
                            alert={alert}
                            handleSelect={this.handleSelect}
                            handleChangeAction={this.handleChangeAction}
                            upsertAction={this.upsertAction}
                            removeAction={this.removeAction}
                            hasEgoi={this.state.hasEgoi}
                            hasSendGrid={this.state.hasSendGrid}
                            hasTwillio={this.state.hasTwillio}
                        />
                        : ''}
                </form>
            </BlankCard>
        );
    }
}
export default injectIntl(MarketingAlertDetails)