import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import CoolDataGrid from '../../Base/CoolDataGrid/CoolDataGrid';
import moment from 'moment-timezone';
import { CoolTooltip, ExportToPDFButton, FormatAmountNumber } from '../../Base/CommonUIComponents';
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { ErrorAlert } from '../../Base/ErrorAlert';
import CustomBlockUI from '../../Base/CustomBlockUI';
import { Button, Col, Progress, Row, UncontrolledPopover } from 'reactstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import SalesProcessGridSpaceCell from './SalesProcessGridSpaceCell';
import { CustomFormatDate } from '../SalesProcessFunctions';
import ExcelJS from 'exceljs';

class SalesProcessDealsRooms extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            rows: [],
            startDate: null,
            endDate: null,
            spacesAvail: null,
            roomsAvail: null,
            error: null,
            blockSpaces: {},
            blockRooms: {},
            hotelIds: [],
            hideStageType: false,
            hideOption: false,
            hideNoInv: false,
            dealIdWithFocusedCells: null,
            statusAffectingInv: []
        };
    }

    componentWillReceiveProps(nextProps) {
        const { hotelIds, hotelList, startDate, endDate, hideStageType, hideOption, hideNoInv } = nextProps;
        
        const diffHotelLength = hotelList?.length !== this.props.hotelList?.length;
        const diffDealLength = () => hotelList?.reduce((a, b) => a + b.stageTypes?.reduce((a, b) => a + b.salesDeals?.length, 0), 0) !== this.props.hotelList?.reduce((a, b) => a + b.stageTypes?.reduce((a, b) => a + b.salesDeals?.length, 0), 0);
        const diffDates = (startDate && !startDate.isSame(this.state.startDate)) || (endDate && !endDate.isSame(this.state.endDate));
        const diffHotels = (hotelIds && (hotelIds?.length !== this.props.hotelIds?.length || hotelIds?.some((hotelId) => !this.props.hotelIds?.includes(hotelId)) || this.props.hotelIds?.some((hotelId) => !hotelIds?.includes(hotelId))));
        const diffViewParams = hideStageType !== this.state.hideStageType || hideOption !== this.state.hideOption || hideNoInv !== this.state.hideNoInv;

        if(diffViewParams || diffHotels || diffDates || diffHotelLength || diffDealLength()) {
            this.setState({
                hotelIds,
                hideStageType,
                hideOption,
                hideNoInv,
                hotelList,
                startDate: startDate?.clone(),
                endDate: endDate?.clone(),
            }, () => {
                if (diffHotels || (diffDates && startDate && endDate)){
                    this.getHotelsEvents()
                    this.initialLoad(hotelList, startDate, endDate, hotelIds);
                }
                else {
                    this.getHotelsEvents()
                    this.initialCalc(hotelList, startDate, endDate);
                }
            });
        }
    }

    componentDidMount() {
        const { startDate, endDate, hotelList, hotelIds } = this.props;
        this.getTypesList();
        if(hotelIds && (!startDate?.isSame(this.state.startDate) || !endDate?.isSame(this.state.endDate)) && startDate && endDate){
            this.setState({ hotelList, startDate, endDate }, () => this.initialLoad(hotelList, startDate, endDate, hotelIds));            
        }
    }

    getTypesList = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.data?.length) {
                    this.setState({
                        block: false,
                        statusAffectingInv: data.data.find(x => x.fixedValue === "Won")?.mappingCodes??[]
                    });

                    return;
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/customtypes?type=PipelineStageType`);
    }

    goToDealDetail = (e, id) => {
        e.stopPropagation();
        this.props.selectDealDetail(id, 'SalesProcessGrid');
    }

    toggleFocusedDeals = (dealId, focus) => {
        const { dealIdWithFocusedCells } = this.state;
        
        const idToUse = focus ? dealId : dealIdWithFocusedCells;
        const cells = document.getElementsByClassName(`space-cell-data-${idToUse}`);
        const remove = document.getElementsByClassName("resources-grid-focused-cell");

        if(remove && remove.length > 0){
            while(remove.length > 0){
                remove[0].classList.remove("resources-grid-focused-cell");
            };
        }

        if(cells && cells.length > 0){
            if(focus){
                cells.forEach(c => c.classList.add("resources-grid-focused-cell"));
            }

            cells[0].scrollIntoView(false);

            this.setState({ dealIdWithFocusedCells: focus ? dealId : null });
        }
    }

    sortByStatus(arr) {
        const statusOrder = {
            "Won": 1,
            "WaitingHotel": 2,
            "WaitingClient": 3,
        };
    
        return arr?.sort((a, b) => {
            const statusA = statusOrder[a?.status] || 4; // Default to 4 if status is not found
            const statusB = statusOrder[b?.status] || 4; // Default to 4 if status is not found
            
            return (statusA??0) - (statusB??0);
        });
    }

    getHotelsEvents = (e) => {
        if (e) e.preventDefault();
        const { startDate, endDate } = this.state;
        const { hotelIds } = this.props;

        if (!startDate || !endDate) {
            return;
        }

        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    if (data.response?.length) {
                        const dailySummary = {};

                        data.response.forEach(event => {
                            const startDate = new Date(event.startDate);
                            const endDate = new Date(event.endDate);

                            let currentDate = new Date(startDate);
                            while (currentDate <= endDate) {
                                const dayStr = currentDate.toISOString().split('T')[0];

                                if (!dailySummary[dayStr]) {
                                    dailySummary[dayStr] = { type: "", day: dayStr, pax: 0 };
                                }
                                dailySummary[dayStr].pax += event.pax;

                                dailySummary[dayStr].type = dailySummary[dayStr].type == "Event" ? "Event" : event.eventType;

                                currentDate.setDate(currentDate.getDate() + 1);
                            }
                        });

                        var sortedSummary = Object.values(dailySummary).sort((a, b) => new Date(a.day) - new Date(b.day));

                        this.setState({ hotelsCityEvents: sortedSummary, hotelsCityEventsDetails: data.response, block: false });
                        return;
                    }

                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/location/events?${hotelIds && hotelIds.map(h => `hotelIds=${h}`).join('&')}${startDate ? `&startDate=${moment(startDate)?.format('YYYY-MM-DD')}` : ''}${endDate ? `&endDate=${moment(endDate)?.format('YYYY-MM-DD')}` : ''}`)
        });
    }

    downloadExcel = () => {
        const { startDate, endDate, hotelList, spacesAvail, roomsAvail, statusAffectingInv } = this.state;
        const { intl, spaceList } = this.props;
        
        if(!roomsAvail || !spacesAvail || !hotelList?.length)
            return;

        const alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');
        const dealNameLabel = intl.formatMessage({ id: "SalesProcess.Name" });
        const responsibleLabel = intl.formatMessage({ id: "SalesProcess.Responsible" });
        const dealStatusLabel = intl.formatMessage({ id: "SalesProcess.DealStatus" });
        const statusLabel = intl.formatMessage({ id: "SalesProcess.Status" });
        const totalPaxLabel = intl.formatMessage({ id: "SalesProcess.TotalPax" });
        const numberOfRoomsLabel = intl.formatMessage({ id: "SalesProcess.NumberOfRooms" });
        const checkInLabel = intl.formatMessage({ id: "SalesProcess.CheckIn" });
        const checkOutLabel = intl.formatMessage({ id: "SalesProcess.CheckOut" });
        const numberOfNightsLabel = intl.formatMessage({ id: "SalesProcess.NumberOfNights" });
        const avgRateLabel = intl.formatMessage({ id: "SalesProcess.TotalAvgRate" });
        const rateLabel = intl.formatMessage({ id: "SalesProcess.AvgRate" });
        const revenueRoomsLabel = intl.formatMessage({ id: "SalesProcess.RevenueRooms" });
        const fBLabel = intl.formatMessage({ id: "SalesProcess.F&B" });
        const banquetsLabel = intl.formatMessage({ id: "SalesProcess.Banquets" });
        const spaceRentalLabel = intl.formatMessage({ id: "SalesProcess.SpaceRental" });
        const miscLabel = intl.formatMessage({ id: "SalesProcess.Misc" });
        const totalRevenueLabel = intl.formatMessage({ id: "SalesProcess.TotalRevenue" });
        
        const cols = [
            dealNameLabel,
            responsibleLabel,
            dealStatusLabel,
            statusLabel,
            checkInLabel,
            checkOutLabel,
            totalPaxLabel,
            avgRateLabel,
            numberOfNightsLabel,
            numberOfRoomsLabel,
            revenueRoomsLabel,
            fBLabel,
            banquetsLabel,
            spaceRentalLabel,
            miscLabel,
            totalRevenueLabel
        ];

        const numberOfDays = moment(endDate).diff(moment(startDate), 'day') + 1;
                
        const getCurrentLetter = (counter) => {
            const value = (counter / alphabet.length);
            
            if(value > 1){
                const startIdx = Math.floor(counter / alphabet.length);
                const lastIdx = counter - (alphabet.length * startIdx);
                
                return `${alphabet[startIdx - 1]}${alphabet[lastIdx]}`;
            }

            return alphabet[counter];
        }

        const getValue = (isHotel, deal, key, hotelRoomsAvail, d, currency) => {
            if(!isHotel){
                switch(d){
                    case dealNameLabel:
                        return `HYPERLINK("${window.location.href.slice(0, window.location.href.indexOf('/', 8))}/SalesProcess?dealId=${deal.dealId}#SalesDealDetail", "${deal.dealName.replace('"', '""')}")`;;
                    case responsibleLabel:
                        return deal.salesDealUsers[0]?.name??'-';
                    case dealStatusLabel:
                        return (deal.status === "WaitingClient" || deal.status === "WaitingHotel") ? 
                            intl.formatMessage({ id: "SalesProcess.InNegotiation" })
                        : deal.status === "Finished" ? 
                            intl.formatMessage({ id: "SalesProcess.Finished" })
                        : deal.status === "Effective" ? 
                            intl.formatMessage({ id: "SalesProcess.Effective" })
                        :
                            intl.formatMessage({ id: "SalesProcess.Lost" });
                    case statusLabel:
                        return deal.proposals?.find(({pmsStatus, type}) => type === "Block" && statusAffectingInv.includes(pmsStatus))?.pmsStatus
                            ??
                                deal.proposals?.find(({type}) => type === "Block")?.pmsStatus
                            ??
                                deal.proposals?.find(({pmsStatus, type}) => type === "Event" && statusAffectingInv.includes(pmsStatus))?.pmsStatus
                            ??
                                deal.proposals?.find(({type}) => type === "Event")?.pmsStatus;
                    case totalPaxLabel:
                        return deal.pax;
                    case numberOfRoomsLabel:
                        return deal.numberOfRooms ?? 0;
                    case checkInLabel:
                        return CustomFormatDate(deal.checkIn, null, null, intl);
                    case checkOutLabel:
                        return CustomFormatDate(deal.checkOut, null, null, intl);
                    case numberOfNightsLabel:
                        return moment(deal.checkOut).diff(moment(deal.checkIn), 'd');
                    case avgRateLabel:
                        return !deal.totalRevenue || !deal.numberOfRooms || isNaN((deal.totalRevenue / deal.numberOfRooms)) ?
                            0
                        :
                            intl.formatNumber(((deal.totalRevenue / deal.numberOfRooms)?.toFixed(2) ?? 0)); 
                    case rateLabel:
                        return !deal.revenueRooms || !deal.numberOfRooms || isNaN((deal.revenueRooms / deal.numberOfRooms)) ?
                            0
                        :
                            intl.formatNumber(((deal.revenueRooms / deal.revenueRooms)?.toFixed(2) ?? 0)); 
                    case revenueRoomsLabel:
                        return intl.formatNumber((deal.revenueRooms?.toFixed(2)??0));
                    case fBLabel:
                        return intl.formatNumber((deal.revenueFB?.toFixed(2)??0));
                    case banquetsLabel:
                        return intl.formatNumber((deal.revenueBanquets?.toFixed(2)??0));
                    case spaceRentalLabel:
                        return intl.formatNumber((deal.revenueSpaces?.toFixed(2)??0));
                    case miscLabel:
                        return intl.formatNumber((deal.revenueEquipment?.toFixed(2)??0));
                    case totalRevenueLabel:
                        return intl.formatNumber((deal.totalRevenue?.toFixed(2)??0));
                    default:{
                        const value = (deal.proposals.reduce((a, b) => a + (JSON.parse(b.proposalJSON)?.BlockData?.details?.reduce((a, b) => a + (moment(b.Date).format('YYYY-MM-DD') === d ? b.CurrentRooms : 0), 0)??0), 0)??0);
                        return value === 0 ? '' : value;
                    }
                }
            }
            else{
                if(d === dealNameLabel)
                    return intl.formatMessage({ id: "SalesProcess.Hotel" });
                else if(key > 15)
                    return hotelRoomsAvail?.find(({hotelDate}) => moment(hotelDate).isSame(d))?.freeRooms??0;
                return "-";
            }
        }
  
        for (let d = 1; d <= numberOfDays; d++) {
            const date = startDate.clone().add((d - 1), 'day');
            
            cols.push(...[moment(date).format('YYYY-MM-DD'), `${moment(date).format('YYYY-MM-DD')}a`, `${moment(date).format('YYYY-MM-DD')}b`, `${moment(date).format('YYYY-MM-DD')}c`]);
        }
        
        const workbook = new ExcelJS.Workbook();
        
        hotelList.forEach((hotel) => {
            const hotelRoomsAvail = roomsAvail && roomsAvail[hotel.hotelId];
            const allDeals = hotel.stageTypes.flatMap(s => s.salesDeals);
            const currency = global.hotelList.find(x => x.value == hotel.hotelId)?.currency;

            const rows = [
                Object.assign({}, ...cols.map((d, key) => ({
                    [d]: getValue(true, null, key, hotelRoomsAvail, d, currency)
                }))),
                ...allDeals.map(deal => 
                    Object.assign({}, ...cols.map((d, key) => ({
                        [d]: getValue(false, deal, key, null, d, currency)
                    })
                ))
            )];
            
            const worksheet = workbook.addWorksheet(global.hotelList.find(x => x.value == hotel.hotelId)?.label);
            
            worksheet.columns = cols.map((key, k) => ({
                header: key,
                key: key,
                width: !k ? 50 : k < 16 ? 20 : 5
            }));

            for(let i = 1; i <= (cols.length - 16); i++){
                const letterCounter = (i === 1 ? 1 : ((i - 1) * 4) + 1) + 15;
                
                const currentLetter = getCurrentLetter(letterCounter);
                const lastLetter = getCurrentLetter(letterCounter + 3);
                
                worksheet.mergeCells(`${currentLetter}1:${lastLetter}1`);
            }
    
            worksheet.getRow(1).font = { bold: true, name: 'Roboto' };
            worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getRow(1).height = 30;

            worksheet.getCell(1, 1).alignment = { vertical: 'middle', horizontal: 'left' };
    
            rows.forEach((rowData, index) => {
                const row = worksheet.addRow(rowData);

                if(index){
                    row.getCell(1).value = { formula: row.getCell(1).value };
                }

                const totalDays = (cols.length - 16) / 4;

                for(let i = 1; i <= totalDays; i++){
                    const letterCounter = (i === 1 ? 1 : ((i - 1) * 4) + 1) + 15;
                    
                    const currentLetter = getCurrentLetter(letterCounter);
                    const lastLetter = getCurrentLetter(letterCounter + 3);

                    worksheet.mergeCells(`${currentLetter}${index + 2}:${lastLetter}${index + 2}`);
                }

                row.eachCell({ includeEmpty: true }, (cell, key) => {
                    cell.font = { name: 'Roboto', color: { argb: 'FF4A4A4A' } };

                    cell.border = {
                        top: { style: 'thin', color: { argb: 'FFCCCCCC' } },
                        left: { style: 'thin', color: { argb: 'FFCCCCCC' } },
                        bottom: { style: 'thin', color: { argb: 'FFCCCCCC' } },
                        right: { style: 'thin', color: { argb: 'FFCCCCCC' } }
                    };

                    if(key === 1){
                        cell.alignment = { vertical: 'middle', horizontal: 'left' };
                    }
                    else{
                        cell.alignment = { vertical: 'middle', horizontal: 'center' };
                    }

                    if(!index) {
                        if(key > 16){
                            const date = moment(worksheet.getCell(1, key).value);
                            const dailyInv = hotelRoomsAvail?.find(({hotelDate}) => moment(hotelDate).isSame(date));
                            const roomsInv = dailyInv?.inventoryRooms??0;
                            const freeRooms = dailyInv?.freeRooms??0;
                            const total = roomsInv - freeRooms;
                            const percentage = Math.round(((total / roomsInv) * 100) * 100) / 100;
            
                            const color = (0 <= percentage && percentage < 33) ? 'FF5CB85C'
                            : (33 <= percentage && percentage < 66) ? 'FFF0AD4E'
                            : 'FFD9534F';
                            
                            if(dailyInv){
                                cell.fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: color }
                                };
                            }
                        }
                    }
                    else{
                        if (index % 2 === 0) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFF3F3F3' }
                            };
                        }
                    }
                });

                row.height = 30;
            });

            const spaceTitleRow = worksheet.addRow({ [dealNameLabel]: intl.formatMessage({ id: "SalesProcess.Spaces" }) });
            spaceTitleRow.font = { bold: true, name: 'Roboto' };
            spaceTitleRow.alignment = { vertical: 'middle', horizontal: 'left' };
            spaceTitleRow.height = 30;
            
            worksheet.mergeCells(spaceTitleRow.number, 1, spaceTitleRow.number, cols.length);

            const hotelSpaceAvail = spacesAvail && spacesAvail[hotel.hotelId];

            const spacesRows = spaceList
                .filter(({hotelId}) => hotelId === hotel.hotelId)
                .map(({description}) => {
                    const events = hotelSpaceAvail?.filter(({spaceName}) => spaceName === description);

                    const dailyOccBySlot = [];

                    if(events){
                        events.forEach(({eventId, spaceFromDate, spaceToDate, reservationStatusCode}) => {
                            const numberOfDays = moment(spaceToDate).diff(moment(spaceFromDate), 'day') + 1;
                            
                            for (let d = 1; d <= numberOfDays; d++) {
                                const date = moment(spaceFromDate).clone().add((d - 1), 'day').format('YYYY-MM-DD');

                                let current = dailyOccBySlot.find(({day}) => day === date);

                                if(!current){
                                    dailyOccBySlot.push({
                                        day: date,
                                        slots: [
                                            {
                                                value: 0,
                                                events: []
                                            },
                                            {
                                                value: 0,
                                                events: []
                                            },
                                            {
                                                value: 0,
                                                events: []
                                            },
                                            {
                                                value: 0,
                                                events: []
                                            }
                                        ]
                                    });

                                    current = dailyOccBySlot.find(({day}) => day === date);
                                }

                                current.slots = current.slots.map((slot, key) => {
                                    const newValue = (key) => {
                                        const from = moment(spaceFromDate);
                                        const to = moment(spaceToDate);
                                        const isFirstDay = from.clone().startOf('d').isSame(date);
                                        const isLastDay = to.clone().startOf('d').isSame(date);
                                        const startHour = parseInt(from.format('HH'));
                                        let endHour = parseInt(to.clone().format('HH'));
                                        
                                        if(endHour > 0) {
                                            endHour = parseInt(to.clone().add('minute', -1).format('HH'));
                                        }

                                        const valueToCompareStart = 6 * (key + 1);
                                        const valueToCompareEnd = 6 * (key);

                                        if(isFirstDay && isLastDay){
                                            return startHour < valueToCompareStart && endHour >= valueToCompareEnd;
                                        }

                                        if(isFirstDay) {
                                            return startHour < valueToCompareStart;
                                        }

                                        if(isLastDay) {
                                            return endHour > valueToCompareEnd;
                                        }

                                        return !isFirstDay && !isLastDay
                                    }

                                    const proposalForEvent = allDeals
                                        ?.filter(d => d.proposals)
                                        ?.flatMap(d => d.proposals)
                                        ?.filter((proposal) =>
                                            moment(proposal.startDate).startOf('d').isSameOrBefore(date) &&
                                            moment(proposal.endDate).startOf('d').isSameOrAfter(date)
                                        )
                                        .find(({externalId}) => parseInt(externalId) === eventId);

                                    const result = {
                                        value: slot.value + (newValue(key) ? 1 : 0),
                                        events: slot.events
                                    }

                                    if(proposalForEvent){
                                        result.events.push({
                                            eventId,
                                            spaceFromDate,
                                            spaceToDate,
                                            isDeal: true,
                                            name: proposalForEvent.dealName,
                                            pmsStatus: proposalForEvent.pmsStatus,
                                            dealId: proposalForEvent.dealId,
                                            start: moment(spaceFromDate).format("HH:mm"),
                                            end: moment(spaceToDate).format("HH:mm"),
                                            isDEF: statusAffectingInv.includes(proposalForEvent.pmsStatus)
                                        });
                                    }
                                    else{
                                        result.events.push({
                                            eventId,
                                            spaceFromDate,
                                            spaceToDate,
                                            pmsStatus: reservationStatusCode,
                                            start: moment(spaceFromDate).format("HH:mm"),
                                            end: moment(spaceToDate).format("HH:mm"),
                                            isDEF: statusAffectingInv.includes(reservationStatusCode)
                                        });
                                    }

                                    return result;
                                });
                            }
                        });
                    }

                    return ({
                        [dealNameLabel]: description,
                        ...Object.assign({},
                            ...cols.map((key, k) => {
                                if(key === dealNameLabel)
                                    return
                                if(k < 16)
                                    return {[key]: '-'};

                                const dailyOcc = dailyOccBySlot.filter(({day}) => day === (key.length > 10 ? key.substring(0, 10) : key));
                                
                                const slotOcc = dailyOcc.reduce((a, b) => {
                                    const arr = [...a];

                                    arr[0].value += (b.slots[0]?.value??0);
                                    arr[1].value += (b.slots[1]?.value??0);
                                    arr[2].value += (b.slots[2]?.value??0);
                                    arr[3].value += (b.slots[3]?.value??0);

                                    if(b.slots[0]?.events && b.slots[0]?.events.length > 0){
                                        arr[0].events = [...arr[0].events, ...b.slots[0]?.events];
                                    }
                                    if(b.slots[1]?.events && b.slots[1]?.events.length > 0){
                                        arr[1].events = [...arr[1].events, ...b.slots[1]?.events];
                                    }
                                    if(b.slots[2]?.events && b.slots[2]?.events.length > 0){
                                        arr[2].events = [...arr[2].events, ...b.slots[2]?.events];
                                    }
                                    if(b.slots[3]?.events && b.slots[3]?.events.length > 0){
                                        arr[3].events = [...arr[3].events, ...b.slots[3]?.events];
                                    }

                                    return arr;
                                }, [{value: 0, events: []},{value: 0, events: []},{value: 0, events: []},{value: 0, events: []}]);

                                const letter = key.substring(10, 11);

                                const idx = !letter ? 0 : letter === 'a' ? 1 : letter === 'b' ? 2 : 3;

                                return ({
                                    [key]: slotOcc[idx]
                                })
                            })
                        )
                    });
                });

            spacesRows.forEach((rowData, index) => {
                const rowDataOnlyValue = Object.values(rowData).map((x) => typeof x === "object" ? x?.value === 0 ? '' : x?.value : x);

                const row = worksheet.addRow(rowDataOnlyValue);

                row.eachCell({ includeEmpty: true }, (cell, key) => {

                    cell.border = {
                        top: { style: 'thin', color: { argb: 'FFCCCCCC' } },
                        left: { style: 'thin', color: { argb: 'FFCCCCCC' } },
                        bottom: { style: 'thin', color: { argb: 'FFCCCCCC' } },
                        right: { style: 'thin', color: { argb: 'FFCCCCCC' } }
                    };

                    if(key === 1){
                        cell.alignment = { vertical: 'middle', horizontal: 'left' };
                    }
                    else{
                        cell.alignment = { vertical: 'middle', horizontal: 'center' };
                    }
                    
                    let fgColor = 'FFFFFFFF';

                    if(key <= 16){
                        if(index % 2 === 0)
                            fgColor = 'FFF3F3F3';
                    }
                    else{
                        if(cell.value > 0){
                            if(cell.value > 1){
                                const dateKey = Object.keys(rowData)[key - 1];

                                if(rowData[dateKey]?.events.filter(({isDEF}) => isDEF).length > 1){
                                    fgColor = 'FF3B3434';
                                }
                                else{
                                    fgColor = 'FFD9534F';
                                }
                            }
                            else{
                                fgColor = 'FF5CB85C';
                            }
                        }
                    }

                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: fgColor }
                    };

                    cell.font = { name: 'Roboto', color: { argb: fgColor === 'FF3B3434' ? 'FFFFFFFF' : 'FF4A4A4A' } };

                });

                row.height = 30;
            });

            const collapse = 16;
            for(let i = 1; i <= cols.length; i++){
                const col = worksheet.getColumn(i);
                col.outlineLevel = i > 1 && i <= collapse ? 1 : 0;
                col.hidden = i > 1 && i <= collapse;
            }
            
            worksheet.views = [
                { state: 'frozen', xSplit: 1, ySplit: 1 }
            ];


            const exportedRow = worksheet.addRow({ [dealNameLabel]: `Exported on ${moment().format("DD-MM-YYYY HH:mm")}` });
            exportedRow.font = { name: 'Roboto' };
            exportedRow.alignment = { vertical: 'middle', horizontal: 'left' };
            exportedRow.height = 30;
            
            worksheet.mergeCells(exportedRow.number, 1, exportedRow.number, cols.length);
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${`ProfileNow Resources Grid`}_${moment().format("DD-MM-YYYY")}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    initialCalc = (data, startDate, endDate) => {
        const { blockRooms, spacesAvail, roomsAvail, blockSpaces, hotelIds, hideStageType, hideOption, hideNoInv, statusAffectingInv, dealIdWithFocusedCells, hotelsCityEvents, hotelsCityEventsDetails } = this.state;
        const { spaceList, intl, colorsByStatus } = this.props;
        
        const columns = [
            {
                key: 'name',
                isFixed: true,
                name: intl.formatMessage({ id: "SalesProcess.Name" }),
                filterable: true,
                width: 280
            }
        ];
  
        const numberOfDays = moment(endDate).diff(moment(startDate), 'day') + 1;
  
        for (let d = 1; d <= numberOfDays; d++) {
            const date = startDate.clone().add((d - 1), 'day');
            
            const isWeekend = (date.day() === 0) || (date.day()  === 6);

            columns.push(
                {
                    key: moment(date).format('YYYY-MM-DD'),
                    name: `${moment(date).format('ddd')} ${moment(date).format('DD-MM')}`,
                    width: 100,
                    isWeekend,
                    filterable: true,
                    isToday: moment(date).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD'),
                    isHolidayOrNote: hotelsCityEvents?.some(x => (x.type == "Holiday" || x.type == "Note")
                        && moment(date).format('YYYY-MM-DD') == moment(x.day).format('YYYY-MM-DD')),
                    isSmallEvent: hotelsCityEvents?.some(x => x.type == "Event" && x.pax < 2000
                        && moment(date).format('YYYY-MM-DD') == moment(x.day).format('YYYY-MM-DD')),
                    isEvent: hotelsCityEvents?.some(x => x.type == "Event" && x.pax >= 2000 && x.pax < 15000
                        && moment(date).format('YYYY-MM-DD') == moment(x.day).format('YYYY-MM-DD')),
                    isBigEvent: hotelsCityEvents?.some(x => x.type == "Event" && x.pax >= 15000
                        && moment(date).format('YYYY-MM-DD') == moment(x.day).format('YYYY-MM-DD')),
                    eventDetails: hotelsCityEventsDetails?.filter(x => moment(date).format('YYYY-MM-DD') >= moment(x.startDate).format('YYYY-MM-DD') && moment(date).format('YYYY-MM-DD') <= moment(x.endDate).format('YYYY-MM-DD'))
                }
            );
        }
    
        const days = columns.slice(1);

        const calcRooms = (stage, hotelId, hideStageType, hideOption, hideNoInv, statusAffectingInv) => {
            return this.sortByStatus(stage.salesDeals)?.map((deal, dealKey) => {
                const roomsPerDay = {};
                const daysWithOptions = {};
                const daysNoAffecting = {};
                let dealHasEvents = deal.status !== "Cancelled" && deal.proposals.some(a => {
                    if(a.type !== "Event" || a.proposalJSON === null)
                        return false;

                    const json = JSON.parse(a.proposalJSON);
                    const events = json.data?.[0]?.DetailsEvents;
                    
                    if(events?.length === 0)
                        return false;

                    return (
                        events.some(e => e.Spaces.some(({FromDate, ToDate}) => moment(FromDate).isSameOrBefore(endDate) && moment(ToDate).isSameOrAfter(startDate)))
                    );
                });
                
                deal.proposals.filter(({isOption}) => !hideOption || !isOption).forEach(p => {
                    const json = JSON.parse(p.proposalJSON);
    
                    if(json?.BlockData?.details && (!hideNoInv || (json?.BlockData?.headerData && statusAffectingInv.includes(p.pmsStatus)))){
                        const notAffecting = !statusAffectingInv.includes(p.pmsStatus);
                        json.BlockData.details.forEach(d => {
                            const date = moment(d.Date).format('YYYY-MM-DD');
    
                            if(!roomsPerDay[date])
                                roomsPerDay[date] = 0;
                            
                            roomsPerDay[date] += d.CurrentRooms;

                            if(p.isOption)
                                daysWithOptions[date] = true;

                            if(notAffecting)
                                daysNoAffecting[date] = true;
                        });
                    }
                });

                const originalRoomsPerDay = JSON.parse(JSON.stringify(roomsPerDay));

                Object.keys(roomsPerDay).forEach((key) => {
                    const current = roomsPerDay[key];
                    const dayHasOption = daysWithOptions[key];
                    const dayNoInv = daysNoAffecting[key];
                    
                    roomsPerDay[key] = (
                        <div
                            className='w-100 h-100 d-flex align-items-center justify-content-center'
                            style={{
                                background: dayHasOption && dayNoInv ?
                                    'repeating-linear-gradient(45deg, #614a0457, #614a0457 5px, #bdd4f3 5px, #bdd4f3 12px)'
                                : dayHasOption ?
                                    'repeating-linear-gradient(45deg, #614a0457, #614a0457 5px, #ffffff 5px, #ffffff 12px)'
                                : dayNoInv ?
                                    'repeating-linear-gradient(45deg, #bdd4f3, #bdd4f3 5px, #ffffff 5px, #ffffff 12px)'
                                :''
                                }}
                            >
                            {current}
                        </div>
                    );
                });

                const preWrapContent = dealHasEvents ? (
                    <div className='w-100 h-100 d-flex align-items-center justify-content-center cursor-pointer' onClick={_ => this.toggleFocusedDeals(deal.dealId, true)}>
                        <i className="far fa-eye"/>
                    </div>
                ):'';

                const currency = global.hotelList.find(x => x.value == hotelId)?.currency;

                const mainBlockProposal =
                    deal.proposals.find(({pmsStatus, type}) => type === "Block" && statusAffectingInv.includes(pmsStatus))
                ??
                    deal.proposals.find(({type}) => type === "Block");
                    
                const mainEventProposal =
                    deal.proposals.find(({pmsStatus, type}) => type === "Event" && statusAffectingInv.includes(pmsStatus))
                ??
                    deal.proposals.find(({type}) => type === "Event");

                return (
                    {
                        data: {
                            name: (
                                <>
                                    <div style={{ borderTop: hideStageType && !dealKey && stage.stageColor ? `3px solid ${stage.stageColor}` :'' }} className="h-100 cursor-pointer d-flex align-items-center justify-content-between w-100 px-2" id={`deal-detail-cell-${deal.dealId}-${hotelId}`}>
                                        <div className="text-truncate">
                                            <div className="text-truncate">
                                                <a className='hover-underline text-truncate' href={`/SalesProcess?dealId=${deal.dealId}#SalesDealDetail`} target="_blank">
                                                    {deal.dealName}
                                                </a>
                                            </div>
                                            {deal.salesDealUsers && deal.salesDealUsers[0] ?
                                                <div style={{ fontSize: '0.8em', marginTop: '-2px' }}>{deal.salesDealUsers[0].name}</div>
                                            :''}
                                        </div>
                                        <div className="ml-2 d-flex align-items-center justify-content-between">
                                            <div className='mr-3 text-muted' style={{ fontSize: '0.8em' }}>
                                                {mainBlockProposal?.pmsStatus ?? mainEventProposal?.pmsStatus}
                                            </div>
                                            <div>
                                                {(deal.status === "WaitingClient" || deal.status === "WaitingHotel") ? 
                                                    <i style={{ color: '#0665ff' }} className="fas fa-people-arrows"/>
                                                : deal.status === "Finished" ?
                                                    <i style={{ color: '#36ad51' }} className="fas fa-trophy"/>
                                                : deal.status === "Effective" ?
                                                    <i style={{ color: "#6EDE8A" }} className="far fa-check-circle mr-1"/>
                                                :
                                                    <i style={{ color: '#f38375' }} className="far fa-thumbs-down"/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <UncontrolledPopover target={`deal-detail-cell-${deal.dealId}-${hotelId}`} placement={"right"} placementPrefix='salesProcessGridCellPopover coolPopover bs-popover'>
                                        <div style={{ width: '20rem' }} className='p-2'>
                                            <div>
                                                <div className='text-truncate' style={{ fontSize: '1.1em' }}>
                                                    <div className='text-truncate'>
                                                        {deal.dealName}
                                                    </div>
                                                </div>
                                                <div className='text-truncate' style={{ fontSize: '0.85em' }}>
                                                    <Link to={{ pathname: "/ProfileDetails/" + deal.customer?.id }} target="_blank" rel="noopener noreferrer">
                                                        <div className='text-truncate'>
                                                            {deal.customer?.name}
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <Row className='mt-1'>
                                                <Col className="col-6">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.Resposible"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {deal.salesDealUsers[0] ?
                                                            deal.salesDealUsers[0].name??'-'
                                                        :'-'}
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.MainBlock"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {mainBlockProposal?.pmsStatus ?? mainEventProposal?.pmsStatus}
                                                    </div>
                                                </Col>
                                                <Col className="col-6">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.TotalPax"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {deal.pax}
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.NumberOfRooms"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {deal.numberOfRooms}
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.CheckIn"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {CustomFormatDate(deal.checkIn, null, null, intl)}
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.CheckOut"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {CustomFormatDate(deal.checkOut, null, null, intl)}
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.NumberOfNights"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {moment(deal.checkOut).diff(moment(deal.checkIn), 'd')}
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.TotalAvgRate"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {!deal.totalRevenue || !deal.numberOfRooms || isNaN((deal.totalRevenue / deal.numberOfRooms)) ?
                                                            <FormatAmountNumber
                                                                value={0}
                                                                currency={currency}
                                                                hideDecimals={true}
                                                            />
                                                        :
                                                            <FormatAmountNumber
                                                                value={(deal.totalRevenue / deal.numberOfRooms)}
                                                                currency={currency}
                                                                hideDecimals={true}
                                                            />
                                                        }
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.AvgRate"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {!deal.revenueRooms || !deal.numberOfRooms || isNaN((deal.revenueRooms / deal.numberOfRooms)) ?
                                                            <FormatAmountNumber
                                                                value={0}
                                                                currency={currency}
                                                                hideDecimals={true}
                                                            />
                                                        :
                                                            <FormatAmountNumber
                                                                value={(deal.revenueRooms / deal.numberOfRooms)}
                                                                currency={currency}
                                                                hideDecimals={true}
                                                            />
                                                        }
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.RevenueRooms"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        <FormatAmountNumber
                                                            value={deal.revenueRooms}
                                                            currency={currency}
                                                            hideDecimals={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.F&B"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        <FormatAmountNumber
                                                            value={deal.revenueFB}
                                                            currency={currency}
                                                            hideDecimals={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.Banquets"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        <FormatAmountNumber
                                                            value={deal.revenueBanquets}
                                                            currency={currency}
                                                            hideDecimals={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.SpaceRental"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        <FormatAmountNumber
                                                            value={deal.revenueSpaces}
                                                            currency={currency}
                                                            hideDecimals={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.Misc"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        <FormatAmountNumber
                                                            value={deal.revenueEquipment}
                                                            currency={currency}
                                                            hideDecimals={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-6 mt-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.TotalRevenue"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        <FormatAmountNumber
                                                            value={deal.totalRevenue}
                                                            currency={currency}
                                                            hideDecimals={true}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </UncontrolledPopover>
                                </>
                            ),
                            ...roomsPerDay
                        },
                        dailyData: originalRoomsPerDay, // just for calcs 
                        cellsNoPadding: true,
                        preWrapContent
                    }
                );
            })??[];
        }
        
        const rows = hotelIds?.map((hotelId) => {
            const h = data.find((h) => h.hotelId === hotelId);

            let stages;

            if(!hideStageType){
                stages = h?.stageTypes?.map((stage) => {
                    const children = calcRooms(stage, hotelId, false, hideOption, hideNoInv, statusAffectingInv);
        
                    const dailyInv = Object.assign({}, ...days.map(({key}) => ({ [key]: ((children?.reduce((a,b) => a + (b.dailyData[key]??0), 0)??0) > 0 ? (children?.reduce((a,b) => a + (b.dailyData[key]??0), 0)??0) : '-') })));
    
                    return ({
                        data: {
                            name: (
                                <div className='w-100 d-flex align-items-center justify-content-between'>
                                    <div className="text-muted">{stage.stageName}</div>
                                    {stage.stageColor ?
                                        <div style={{ background: stage.stageColor, width: '10%', height: '10px' }}/>
                                    :''}
                                </div>
                            ),
                            ...dailyInv
                        },
                        isSmall: true,
                        isOpened: true,
                        children
                    })
                });
            }
            else{
                stages = h?.stageTypes.map((stage) => calcRooms(stage, hotelId, true, hideOption, hideNoInv, statusAffectingInv))?.flat();
            }

            const dailyInv = Object.assign({}, ...days.map(({key}) => {
                const dailyInv = roomsAvail && roomsAvail[hotelId]?.find(({hotelDate}) => moment(hotelDate).isSame(key));
                const roomsInv = dailyInv?.inventoryRooms??0;
                const freeRooms = dailyInv?.freeRooms??0;
                const total = roomsInv - freeRooms;
                const percentage = Math.round(((total / roomsInv) * 100) * 100) / 100;

                const color = (0 <= percentage && percentage < 33) ?
                    { color: 'success', hex: '#5cb85c' }
                : (33 <= percentage && percentage < 66) ?
                    { color: 'warning', hex: '#f0ad4e' }
                : { color: 'danger', hex: '#d9534f' };


                return ({
                    [key]: (
                        <CustomBlockUI blocking={blockRooms[hotelId]}>
                            <div className='w-100' style={{ visibility: !dailyInv ? 'hidden' : '' }} id={`availabilityPercentage-${key}-${hotelId}`}>
                                <div className='px-3'>
                                    <Progress
                                        color={color.color}
                                        value={percentage + 1}
                                        style={{
                                            maxHeight: '5px',
                                        }}
                                    />
                                </div>
                                <div className='cursor-default text-center mt-2'>
                                    {freeRooms}
                                </div>
                            </div>
                            <CoolTooltip trigger="legacy" target={`availabilityPercentage-${key}-${hotelId}`} placement="right">
                                {roomsInv > 0 ?
                                    <>
                                        <i className={`far fa-square mr-2`} style={{ background: color.hex }} /> {percentage}%
                                    </>
                                :''}
                                <div className="d-flex align-items-center"> <i className="fas fa-hashtag fa-xs mr-2 pr-1 ml-1" /> {total}/{roomsInv} </div>
                            </CoolTooltip>
                        </CustomBlockUI>
                    )
                })
            }));

            const hotelSpaceAvail = spacesAvail && spacesAvail[hotelId];

            const newDeals = h?.stageTypes?.flatMap(({salesDeals}) => salesDeals);

            const spaceRows = spaceList
                ?.filter((s) => s.hotelId === hotelId)
                .map(({description, id}) => {
                    const events = hotelSpaceAvail?.filter(({spaceName}) => spaceName === description);

                    const dailyOccBySlot = [];

                    if(events){
                        events.forEach(({eventId, spaceFromDate, spaceToDate, reservationStatusCode}) => {
                            const numberOfDays = moment(spaceToDate).diff(moment(spaceFromDate), 'day') + 1;
                            
                            for (let d = 1; d <= numberOfDays; d++) {
                                const date = moment(spaceFromDate).clone().add((d - 1), 'day').format('YYYY-MM-DD');

                                let current = dailyOccBySlot.find(({day}) => day === date);

                                if(!current){
                                    dailyOccBySlot.push({
                                        day: date,
                                        slots: [
                                            {
                                                value: 0,
                                                events: []
                                            },
                                            {
                                                value: 0,
                                                events: []
                                            },
                                            {
                                                value: 0,
                                                events: []
                                            },
                                            {
                                                value: 0,
                                                events: []
                                            }
                                        ]
                                    });

                                    current = dailyOccBySlot.find(({day}) => day === date);
                                }

                                current.slots = current.slots.map((slot, key) => {
                                    const newValue = (key) => {
                                        const from = moment(spaceFromDate);
                                        const to = moment(spaceToDate);
                                        const isFirstDay = from.clone().startOf('d').isSame(date);
                                        const isLastDay = to.clone().startOf('d').isSame(date);
                                        const startHour = parseInt(from.format('HH'));
                                        let endHour = parseInt(to.clone().format('HH'));
                                        
                                        if(endHour > 0) {
                                            endHour = parseInt(to.clone().add('minute', -1).format('HH'));
                                        }

                                        const valueToCompareStart = 6 * (key + 1);
                                        const valueToCompareEnd = 6 * (key);

                                        if(isFirstDay && isLastDay){
                                            return startHour < valueToCompareStart && endHour >= valueToCompareEnd;
                                        }

                                        if(isFirstDay) {
                                            return startHour < valueToCompareStart;
                                        }

                                        if(isLastDay) {
                                            return endHour > valueToCompareEnd;
                                        }

                                        return !isFirstDay && !isLastDay
                                    }

                                    const proposalForEvent = newDeals
                                        ?.filter(d => d.proposals)
                                        ?.flatMap(d => d.proposals)
                                        ?.filter((proposal) =>
                                            moment(proposal.startDate).startOf('d').isSameOrBefore(date) &&
                                            moment(proposal.endDate).startOf('d').isSameOrAfter(date)
                                        )
                                        .find(({externalId}) => parseInt(externalId) === eventId);

                                    const calcValue = (newValue(key) ? 1 : 0);

                                    const result = {
                                        value: slot.value + calcValue,
                                        events: slot.events
                                    }

                                    if(calcValue > 0) {
                                        if(proposalForEvent){
                                            result.events.push({
                                                eventId,
                                                spaceFromDate,
                                                spaceToDate,
                                                isDeal: true,
                                                name: proposalForEvent.dealName,
                                                pmsStatus: proposalForEvent.pmsStatus,
                                                dealId: proposalForEvent.dealId,
                                                start: moment(spaceFromDate).format("HH:mm"),
                                                end: moment(spaceToDate).format("HH:mm"),
                                                isDEF: statusAffectingInv.includes(proposalForEvent.pmsStatus)
                                            });
                                        }
                                        else {
                                            result.events.push({
                                                eventId,
                                                spaceFromDate,
                                                spaceToDate,
                                                pmsStatus: reservationStatusCode,
                                                start: moment(spaceFromDate).format("HH:mm"),
                                                end: moment(spaceToDate).format("HH:mm"),
                                                isDEF: statusAffectingInv.includes(reservationStatusCode)
                                            });
                                        }
                                    }

                                    return result;
                                });
                            }
                        });
                    }

                    return ({
                        cellsNoPadding: false,
                        data: {
                            name: <div className=''>{description}</div>,
                            ...Object.assign({},
                                ...days.map(({key}) => {
                                    const dailyOcc = dailyOccBySlot.filter(({day}) => day === key);
                                    
                                    const dealList = dailyOcc && dailyOcc
                                        .flatMap(({slots}) => slots)
                                        .flatMap(({events}) => events)
                                        .filter((a, b, c) => c.findIndex(({dealId}) => dealId === a.dealId) === b);

                                    const customClasses = dealList?.map(({dealId}) => `space-cell-data-${dealId}`)?.toString()?.replace(',',' ');
                                    
                                    const slotOcc = dailyOcc.reduce((a, b) => {
                                        const arr = [...a];

                                        arr[0].value += (b.slots[0]?.value??0);
                                        arr[1].value += (b.slots[1]?.value??0);
                                        arr[2].value += (b.slots[2]?.value??0);
                                        arr[3].value += (b.slots[3]?.value??0);

                                        if(b.slots[0]?.events && b.slots[0]?.events.length > 0){
                                            arr[0].events = [...arr[0].events, ...b.slots[0]?.events];
                                        }
                                        if(b.slots[1]?.events && b.slots[1]?.events.length > 0){
                                            arr[1].events = [...arr[1].events, ...b.slots[1]?.events];
                                        }
                                        if(b.slots[2]?.events && b.slots[2]?.events.length > 0){
                                            arr[2].events = [...arr[2].events, ...b.slots[2]?.events];
                                        }
                                        if(b.slots[3]?.events && b.slots[3]?.events.length > 0){
                                            arr[3].events = [...arr[3].events, ...b.slots[3]?.events];
                                        }

                                        return arr;
                                    }, [{value: 0, events: []},{value: 0, events: []},{value: 0, events: []},{value: 0, events: []}]);

                                    return ({
                                        [key]: (
                                            <SalesProcessGridSpaceCell
                                                slotOcc={slotOcc}
                                                blockSpaces={blockSpaces}
                                                hotelId={hotelId}
                                                customClasses={customClasses}
                                                dealList={dealList}
                                                d={key}
                                                r={{data: {id}}}
                                                description={description}
                                                toggleFocusedDeals={this.toggleFocusedDeals}
                                                colorsByStatus={colorsByStatus}
                                            />
                                        )
                                    })
                                })
                            )
                        }
                    });
                });

            return (
                {
                    data: {
                        name: global.hotelList.find(({value}) => value === hotelId)?.label??hotelId,
                        ...dailyInv
                    },
                    isOpened: true,
                    emptyNull: true,
                    children: [
                        {
                            separator: true,
                            isOpened: true,
                            title: intl.formatMessage({ id: "SalesProcess.Deals" }),
                            children: stages,
                        },
                        {
                            separator: true,
                            isOpened: true,
                            title: intl.formatMessage({ id: "SalesProcess.Spaces" }),
                            children: spaceRows
                        }
                    ].filter(({children}) => children && children.length > 0)
                }
            );
        });

        this.setState({ columns, rows });
    }

    initialLoad = (hotelList, startDate, endDate, hotelIds) => {
        const { blockRooms, blockSpaces, spacesAvail, roomsAvail } = this.state;
        
        const getRoomsData = (hotelId, key, array) => {
            this.getRoomsAvailability(hotelId, (hasError) => {
                if(hasError){
                    this.setState({
                        blockRooms: {
                            ...blockRooms,
                            [hotelId]: false
                        }
                    });
                }
                if((key + 1) === array.length){
                    this.setState({
                        blockRooms: {
                            ...blockRooms,
                            [hotelId]: false
                        }
                    }, () => this.initialCalc(hotelList, startDate, endDate));
                }
                else{
                    const nextKey = key + 1;
                    getRoomsData(array[nextKey], nextKey, array);
                }
            });
        }
        
        const getSpacesData = (hotelId, key, array) => {
            this.getSpacesAvailability(hotelId, (hasError) => {
                if(hasError){
                    this.setState({
                        blockSpaces: {
                            ...blockSpaces,
                            [hotelId]: false
                        }
                    });
                }
                if((key + 1) === array.length){
                    this.setState({
                        blockSpaces: {
                            ...blockSpaces,
                            [hotelId]: false
                        }
                    }, () => this.initialCalc(hotelList, startDate, endDate));
                }
                else{
                    const nextKey = key + 1;
                    getSpacesData(array[nextKey], nextKey, array);
                }
            });
        }

        if(hotelIds?.length){
            const hotelKey = hotelIds && hotelIds[0];
    
            const blockSpaces = {
                ...this.state.blockSpaces
            };
            const blockRooms = {
                ...this.state.blockRooms
            };
    
            if(hotelKey){
                blockRooms[hotelKey] = !(roomsAvail && roomsAvail[hotelKey] && roomsAvail[hotelKey].find(({hotelDate}) => moment(hotelDate).isSame(startDate) && roomsAvail[hotelKey].findLast(({hotelDate}) => moment(hotelDate).isSame(endDate))));
                blockSpaces[hotelKey] = !(spacesAvail && spacesAvail[hotelKey] && spacesAvail[hotelKey].find(({hotelDate}) => moment(hotelDate).isSame(startDate) && spacesAvail[hotelKey].findLast(({hotelDate}) => moment(hotelDate).isSame(endDate))));

                this.setState({ blockRooms, blockSpaces, hotelIds }, () => {
                    this.initialCalc(hotelList, startDate, endDate);
                    getRoomsData(hotelIds[0], 0, hotelIds);
                    getSpacesData(hotelIds[0], 0, hotelIds);
                });
            }
            else{
                this.initialCalc(hotelList, startDate, endDate);
            }
        }
    }

    getRoomsAvailability = (hotelId, cb) => {
        const { startDate, endDate, roomsAvail } = this.state;
        
        let qs = `?hotelId=${hotelId}&fromDate=${startDate.format('YYYY-MM-DD')}&toDate=${endDate.clone().add(1, 'day').format('YYYY-MM-DD')}`;
        let hasError = false;
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                hasError = true
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage }, _ => cb(hasError));
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    hasError = true;
                }

                this.setState({
                    roomsAvail: {
                        ...roomsAvail,
                        [hotelId]: data.response??[]
                    }
                }, _ => cb(hasError));

                return;
            }
            else this.setState({ error: errorMessage }, _ => cb(true));
        }, `/api/gms/SalesProcess/v1/roomsforecast${qs}`);
    }

    getSpacesAvailability = (hotelId, cb) => {
        const { startDate, endDate, spacesAvail } = this.state;

        let qs = `?hotelId=${hotelId}&fromDate=${startDate.format('YYYY-MM-DD')}&toDate=${endDate.clone().add(1, 'day').format('YYYY-MM-DD')}`;
        let hasError = false;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage }, _ => cb(hasError));
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({
                    spacesAvail: {
                        ...spacesAvail,
                        [hotelId]: data.response ? data.response[0] ? data.response[0].reservationList ?? [] : [] : []
                    }
                }, _ => cb(hasError));

                return;
            }
            else this.setState({ error: errorMessage }, () => cb(true));
        }, `/api/gms/SalesProcess/v1/spacesavailability${qs}`);
    }

    render() {
        const { columns, rows, error } = this.state;

        return (
            columns?.length > 1 ?
                <div>
                    <div className='d-flex align-items-center justify-content-end my-2'>
                        <div className="mr-2">
                            <ExportToPDFButton name="MainCoolDataGrid-GridRooms" fromPage="ResourcesGrid" />
                        </div>
                        <div>
                            <Button className="btn btn-host btn-sm" onClick={this.downloadExcel}>
                                <i className="fas fa-file-download" />
                            </Button>
                        </div>
                    </div>
                    <ErrorAlert error={error} />
                    <CoolDataGrid
                        rows={rows}
                        cols={columns}
                        tableName="GridRooms"
                    />
                </div>
            :''
        );
    }
}

export default injectIntl(SalesProcessDealsRooms);