import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../../Base/ErrorAlert';
import { Button, Card, Col, Row } from 'reactstrap';
import { DefaultPage, FormatAmountNumber, SelectHotel } from '../../Base/CommonUIComponents';
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { DateRangePicker } from 'react-dates';
import CustomSelect from '../../Base/CustomSelect';
import { salesInitiativeStatus } from '../../Base/ReferenceDataFunctions';
import { Pagination, ResultsPerPage, TotalResults } from '../../Base/PaginationComponents';
import { checkSalesProcessPermission, CustomFormatDate, InitiativeStatusBadge, SelectCustomType } from '../../SalesProcess/SalesProcessFunctions';

class ProfileSalesProcessInitiatives extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: true,
            error: null,
            startDate: null,
            endDate: null,
            hotel: null,
            status: null,
            profileInitiatives: [],
            pageSize: 10,
            pageIndex: 0,
            totalItems: 0,
        }
    }

    componentDidMount() {
        this.getProfileInitiatives();
    }

    getProfileInitiatives = () => {
        const { profileId } = this.props;
        const { markets, startDate, endDate, status, hotel, pageSize, pageIndex } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                this.setState({ block: false, profileInitiatives: data.data ?? [], totalItems: data.total })
                return;
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/initiative/profile/${profileId}?${startDate ? `startDate=${moment(startDate).format('YYYY-MM-DD')}` : ''}${endDate ? `&endDate=${moment(endDate).format('YYYY-MM-DD') }` : ''}${markets ? markets.map(mk => `markets=${mk.id}`).join('&') + '&' : ''}${hotel ? `&hotelId=${hotel}` : ''}${status ? `&status=${status}` : ''}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
    }

    createNewInitiative = () => {
        this.props.history.push({ pathname: `/SalesProcess`, search: `?newInitiative=true#SalesInitiativeList` });
    }

    goToInitiativeDetail = (id) => {
        this.props.history.push({ pathname: `/SalesProcess`, search: `?initiativeId=${id}#SalesInitiativeDetail` });
    }

    handleHotel = (e, combo) => {
        this.setState({ hotel: combo?.value });
    }

    handleCombo = (combo, name) => {
        this.setState({ [name]: combo?.value });
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, this.getProfileInitiatives);
    }

    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.pageSize !== value) {
            this.setState({ pageSize: value, pageIndex: 0 }, this.getProfileInitiatives);
        }
    }

    handleMarkets = (name, combo) => {
        this.setState({ [name]: combo ? combo.map(el => ({ id: el.value })) : null });
    }

    handleDateChanges = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate });
    }

    render() {
        const { block, error, profileInitiatives, startDate, endDate, status, hotel, pageSize, pageIndex, totalItems, markets } = this.state;
        const { intl, currentSalesProcessUser } = this.props;

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                <Card className="ml-1 my-0 mr-0 border-0 bg-light h-100">
                    <div className="m-2 px-2">
                        <Row className="mb-3">
                            <Col>
                                <h5>
                                    <i className="fas fa-rocket mr-1" />
                                    <FormattedMessage id="ProfileDetails.SalesProcessInitiatives" />
                                </h5>
                            </Col>
                            {global.isMobile ?
                                <Col className='text-right'>
                                    <Button className="btn-sm btn-host" onClick={this.createNewInitiative}>
                                        <i className="fas fa-plus" />
                                    </Button>
                                    <Button className="btn-sm btn-host ml-2" onClick={this.getProfileInitiatives}>
                                        <i className="fas fa-search" />
                                    </Button>
                                </Col>
                                : ''}
                        </Row>
                        <Row className="mb-3">
                            <Col className={global.isMobile ? 'col-6 pr-2 mb-2' : ''}>
                                <div className='title-sm'>
                                    <i className={`fas fa-code-branch mr-2`} />
                                    <FormattedMessage id="SalesProcess.Hotel" />
                                </div>
                                <div>
                                    <SelectHotel
                                        isSearchable
                                        onChangeFunc={this.handleHotel}
                                        placeholder={intl.formatMessage({ id: "SalesProcess.Hotel" })}
                                        value={hotel || ''}
                                        params='?onlyFromHotelGroup=true'
                                    />
                                </div>
                            </Col>
                            <Col className={global.isMobile ? 'col-6 pl-0 mb-2' : ''}>
                                <div className='title-sm'>
                                    <i className={`fas fa-code-branch mr-2`} />
                                    <FormattedMessage id="SalesProcess.Status" />
                                </div>
                                <div>
                                    <CustomSelect
                                        options={salesInitiativeStatus()}
                                        required={true}
                                        isClearable={true}
                                        isMulti={false}
                                        value={salesInitiativeStatus().filter(({ value }) => value === status) || ''}
                                        isSearchable={true}
                                        onChange={e => this.handleCombo(e, 'status')}
                                        placeholder={intl.formatMessage({ id: "SalesProcess.Status" })}
                                    />
                                </div>
                            </Col>
                            <Col className={global.isMobile ? 'col-6 pl-0 mb-2' : ''}>
                                <div className='title-sm'>
                                    <i className="fas fa-indent mr-2" />
                                    <FormattedMessage id="SalesProcess.Markets" />
                                </div>
                                <div>
                                    <SelectCustomType
                                        name="markets"
                                        onChangeFunc={e => this.handleMarkets('markets', e)}
                                        value={markets || []}
                                        placeholder={<FormattedMessage id="SalesProcess.Markets" />}
                                        isClearable
                                        isSearchable
                                        isMulti
                                        type={'InitiativeMarketType'}
                                    />
                                </div>
                            </Col>
                            <Col className={global.isMobile ? 'col-6 pr-2 mt-2' : ''}>
                                <div className='title-sm'>
                                    <i className="far fa-calendar-alt mr-2" />
                                    <FormattedMessage id="SalesProcess.Dates" />
                                </div>
                                <div>
                                    <DateRangePicker
                                        startDate={startDate}
                                        startDateId="startDateId"
                                        endDate={endDate}
                                        endDateId="endDateId"
                                        isOutsideRange={_ => false}
                                        onDatesChange={this.handleDateChanges}
                                        focusedInput={this.state.focusedInput}
                                        showClearDates={true}
                                        onFocusChange={focusedInput => this.setState({ focusedInput })}
                                        small={true}
                                        showDefaultInputIcon={true}
                                        renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                                    />
                                </div>
                            </Col>
                            {!global.isMobile ?
                                <Col className='text-right'>
                                    <Button className="btn-sm btn-host" onClick={this.createNewInitiative}>
                                        <i className="fas fa-plus" />
                                    </Button>
                                    <Button className="btn-sm btn-host ml-2" onClick={this.getProfileInitiatives}>
                                        <i className="fas fa-search" />
                                    </Button>
                                </Col>
                                : ''}
                        </Row>
                        <div className={`${!global.isMobile ? 'verticalScroll' : ''} p-lg-2`} style={{ height: !global.isMobile ? '81vh' : '' }}>
                            {profileInitiatives.length > 0 ?
                                <>
                                    <Row>
                                        {profileInitiatives.map((initiative, key) => {
                                            const hotelCurrency = global.hotelList.find(x => x.value == initiative.hotelIds?.[0])?.currency ?? global.hotelCurrency;
                                            const canClick = checkSalesProcessPermission('ProfileDetails:initiatives:details', currentSalesProcessUser.role);

                                            return (
                                                <Col key={key} className="col-12 col-lg-6 mb-3">
                                                    <Card className={`shadow border-0 h-100 pb-2 ${canClick ? 'pointer' : ''}`}
                                                        body onClick={_ => canClick ? this.goToInitiativeDetail(initiative.id) : undefined}>
                                                        <Row>
                                                            <Col className='col-6'>
                                                                <div style={{ fontSize: '1.1em' }}>{initiative.name}</div>
                                                            </Col>
                                                            <Col className='col-6 text-right'>
                                                                <InitiativeStatusBadge
                                                                    status={initiative.status}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <hr className='my-3' />
                                                        <Row>
                                                            <Col className='col-6 col-lg-4'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesProcess.Dates" />
                                                                </div>
                                                                <div>
                                                                    {initiative.startDate && initiative.endDate ?
                                                                        <>
                                                                            {initiative.startDate ?
                                                                                initiative.endDate ?
                                                                                    `${moment(initiative.startDate).format('YYYY-MM-DD')} - `
                                                                                    :
                                                                                    moment(initiative.startDate).format('YYYY-MM-DD')
                                                                                : ''}
                                                                            {initiative.endDate ?
                                                                                moment(initiative.endDate).format('YYYY-MM-DD')
                                                                                : ''}
                                                                        </>
                                                                        : '-'}
                                                                </div>
                                                            </Col>
                                                            <Col className='col-6 col-lg-4'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesProcess.Location" />
                                                                </div>
                                                                <div>
                                                                    {initiative.location ?? '-'}
                                                                </div>
                                                            </Col>
                                                            <Col className='col-6 col-lg-2'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesProcess.Type" />
                                                                </div>
                                                                <div>
                                                                    {initiative.type ?? '-'}
                                                                </div>
                                                            </Col>
                                                            <Col className='col-6 col-lg-2'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesProcess.ActionType" />
                                                                </div>
                                                                <div>
                                                                    {initiative.actionType ?? '-'}
                                                                </div>
                                                            </Col>
                                                            <Col className='col-12 col-lg-4 mt-2 mt-lg-2'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="ProfileSalesProcess.HotelIds" />
                                                                </div>
                                                                <div>
                                                                    {initiative.hotelIds ?
                                                                        <div style={{ maxHeight: '25px' }} className='overflow-auto veryCoolScrollBar'>
                                                                            {initiative.hotelIds?.map((hotelId, key) => {
                                                                                const hotel = global.hotelList?.find(({ value }) => value === hotelId);
                                                                                return (
                                                                                    <div key={key}>
                                                                                        <span className={key ? 'ml-1' : ''}>
                                                                                            {hotel?.label || hotelId}
                                                                                        </span>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        : '-'}
                                                                </div>
                                                            </Col>
                                                            <Col className='col-6 col-lg-4 mt-2 mt-lg-2'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesProcess.Markets" />
                                                                </div>
                                                                <div>
                                                                    {initiative.markets && initiative.markets.length > 0?
                                                                        <div style={{ maxHeight: '25px' }} className='overflow-auto veryCoolScrollBar'>
                                                                            {initiative.markets?.map((market, key) => {
                                                                                return (
                                                                                    <div key={key}>
                                                                                        <span className={key ? 'ml-1' : ''}>
                                                                                            {market.name}
                                                                                        </span>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        : '-'}
                                                                </div>
                                                            </Col>
                                                            <Col className='col-6 col-lg-4 mt-2 mt-lg-2'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesProcess.Staff" />
                                                                </div>
                                                                <div>
                                                                    {initiative.salesInitiativeUsers ?
                                                                        <div style={{ maxHeight: '25px' }} className='overflow-auto veryCoolScrollBar'>
                                                                            {initiative.salesInitiativeUsers?.map((user, key) => {
                                                                                const userName = global.salesProcessUsers?.find(x => x.value === user.userId);
                                                                                return (
                                                                                    <div key={key}>
                                                                                        <span className={key ? 'ml-1' : ''}>
                                                                                            {userName?.label || '-'}
                                                                                        </span>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        : '-'}
                                                                </div>
                                                            </Col>

                                                            <Col className='col-12 col-lg-4 mt-2 mt-lg-2'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesProcess.CreationDate" />
                                                                </div>
                                                                <div>
                                                                    {initiative.createdAt ?
                                                                        CustomFormatDate(initiative.createdAt, null, null, intl)
                                                                        : '-'}
                                                                </div>
                                                            </Col>
                                                            <Col className='col-6 col-lg-4 mt-2 mt-lg-2'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesProcess.RegistrationDate" />
                                                                </div>
                                                                <div>
                                                                    {initiative.registrationDate ?
                                                                        CustomFormatDate(initiative.registrationDate, null, null, intl)
                                                                        : '-'}
                                                                </div>
                                                            </Col>
                                                            <Col className='col-6 col-lg-4 mt-2 mt-lg-2'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesProcess.PaymentDueDate" />
                                                                </div>
                                                                <div>
                                                                    {initiative.paymentDueDate ?
                                                                        CustomFormatDate(initiative.paymentDueDate, null, null, intl)
                                                                        : '-'}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                            )
                                        }
                                        )}
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ResultsPerPage
                                                changeSizePerPage={this.changePageSize}
                                                pageSize={pageSize}
                                            />
                                            {!global.isMobile ?
                                                <span className="ml-2">
                                                    <TotalResults
                                                        end={pageIndex * pageSize + profileInitiatives.length}
                                                        start={pageIndex * pageSize + 1}
                                                        total={totalItems}
                                                    />
                                                </span>
                                                : ''}
                                        </Col>
                                        <Col className="text-right">
                                            <Pagination
                                                isPrevDisabled={pageIndex === 0}
                                                isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                                currentPage={pageIndex + 1}
                                                handlePrevButton={() => this.handlePagination(-1)}
                                                handleNextButton={() => this.handlePagination(1)}
                                            />
                                        </Col>
                                    </Row>
                                </>
                                :
                                <DefaultPage text="ProfileSalesProcess.NoInitiatives" icon="fas fa-briefcase" />
                            }
                        </div>
                    </div>
                </Card>
            </BlockUi>
        );
    }
}

export default injectIntl(ProfileSalesProcessInitiatives);
