import React, { Component } from 'react'
import { getAPI, postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../../Base/ErrorAlert';
import { getConsentTypeList, getCountrySuggestions, getProfileType, getReviewFields } from '../../Base/ReferenceDataFunctions';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, CardBody, Col, CustomInput, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalBody, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { CoolBadge, SelectHotel, SelectVipCodes } from '../../Base/CommonUIComponents';
import CustomSelect from '../../Base/CustomSelect';
import countryList from 'react-select-country-list';
import { ReviewRange } from '../Common/MarketingFunctions';
import MapModal from '../../Base/MapModal';
import { DateRangePicker } from 'react-dates';
import moment from 'moment-timezone';

class CampaignTargetParams extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: true,
            error: null,
            deleteModal: false,
            campaignTarget: this.props.campaignTarget,
            modalMap: false,
            distanceTypeOptions: [],
            loyaltyCardsOptions: [],
            activeTab: '0',
            tagList: [],
            reviewIsOpen: false,
            langOptions: getCountrySuggestions()
        };
    }

    componentDidMount() {
        this.getLevels();
    }

    getLevels = () => {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage });

                return;
            }
            if (data) {
                const loyaltyCardsOptions = data.response
                    .sort((a, b) => a.minRoomNightsToLevel + a.minPointsToLevel - b.minRoomNightsToLevel - b.minPointsToLevel)
                    .map(opt => {
                        const loyaltyCardsOption = {
                            value: opt.code,
                            label: opt.description,
                        };
                        return loyaltyCardsOption;
                    });

                this.setState({ loyaltyCardsOptions: loyaltyCardsOptions }, () => this.getTags());
                return;
            }

            this.setState({ block: false });
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardLevelConfig`);
    }

    getTags = () => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    this.setState({ tagList: data.response, block: false });
                    return;
                }

                this.setState({ block: false });
            }
        }, `/api/gms/Profile/v1/tag/groupDetails?tagType=all`)
    }

    saveChanges = (e) => {
        const { campaignTarget } = this.state;
        this.props.saveCampaignTargets(e, campaignTarget);
    }

    toggleModalMap = () => {
        this.setState({ modalMap: !this.state.modalMap });
    }

    mapOnClick = (latitude, longitude) => {
        const { campaignTarget } = this.state;
        const params = { ...campaignTarget.params, latitude, longitude };
        this.setState({ campaignTarget: { ...campaignTarget, params } });
    }

    getCoordinates = () => {
        navigator.geolocation.getCurrentPosition(position => {
            this.mapOnClick(position.coords.latitude, position.coords.longitude);
        }, showError);

        function showError(error) {
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    handleNotification(error.code, <FormattedMessage id="MapModal.PermissionDenied" />, '', 'info');
                    break;
                case error.POSITION_UNAVAILABLE:
                    handleNotification(error.code, <FormattedMessage id="MapModal.PositionUnvailable" />, '', 'info');
                    break;
                case error.TIMEOUT:
                    handleNotification(error.code, <FormattedMessage id="MapModal.Timeout" />, '', 'info');
                    break;
                case error.UNKNOWN_ERROR:
                    handleNotification(error.code, <FormattedMessage id="MapModal.UnknownError" />, '', 'info');
                    break;
            }
        }
    }

    isDisabledSelectHotelIds = () => {
        const { campaignTarget } = this.state;
        
        return !campaignTarget.params ||
        (campaignTarget.params.stayFrom == null && campaignTarget.params.stayTo == null && campaignTarget.params.bookingFromDate == null && campaignTarget.params.bookingToDate == null &&
        campaignTarget.params.noStayFrom == null && campaignTarget.params.noStayTo == null && campaignTarget.params.noBookingFromDate == null && campaignTarget.params.noBookingToDate == null);
    }

    getSelectedNationalities = () => {
        const { campaignTarget } = this.state;
        const options = countryList().getData();
        const nationalities = []

        if(!campaignTarget.params?.nationalities){
            return nationalities;
        }

        campaignTarget.params.nationalities.forEach(c => {
            const nat = options.find(o => o.value === c);

            if (nat) {
                nationalities.push(nat);
            }
        });

        return nationalities;
    }

    toggle = (i) => {
        this.setState({
            activeTab: i
        });
    }

    changeTagMin = (e, tagFilter, type) => {
        const { campaignTarget } = this.state;
        const tags = campaignTarget.params && campaignTarget.params[type] ? campaignTarget.params[type] : [];
        const tag = tags.find(({id}) => id === tagFilter.id);

        tag.min = e.target.value;

        const params = { ...campaignTarget.params, [type]: tags };
        this.setState({ campaignTarget: { ...campaignTarget, params } });
    }

    handleTag = (tag, type) => {
        const { campaignTarget } = this.state;
        const tags = campaignTarget.params && campaignTarget.params[type] ? campaignTarget.params[type] : [];
        const idx = tags.findIndex(({id}) => id === tag.id);

        if(idx > -1){
            tags.splice(idx, 1);
        }
        else{
            tags.push({ id: tag.id, min: 1 });
        }

        const params = { ...campaignTarget.params, [type]: tags };
        this.setState({ campaignTarget: { ...campaignTarget, params } });
    }

    handleTagType = (evt) => {
        const { campaignTarget } = this.state;
        const params = { ...campaignTarget.params, ['tagParametersFilterType']: evt.target.name };
        this.setState({ campaignTarget: { ...campaignTarget, params } });
        console.log(params)

    }

    handleNationalities = (combo) => {
        const { campaignTarget, langOptions } = this.state;
        const params = { ...campaignTarget.params, nationalities: combo ? combo.map(cmb => cmb.value) : [] };
        
        langOptions.forEach(r => {
            r.isSelected = r.countries.find(({value}) => !params.nationalities.includes(value)) ? false : true;
        });

        this.setState({ campaignTarget: { ...campaignTarget, params }, langOptions });
    }

    renderMonthElement = ({ month, onYearSelect }) => {
        let i, years = [];
        for(i = moment().year(); i >= moment().year() - 122; i--) {
            years.push(<option onClick={(e) => onYearSelect(month, e.target.id)} id={i} key={`year-${i}`}>{i}</option>);
        };

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Row>
                    <Col>
                        {moment(month).locale(this.props.intl.locale).format('MMMM')}
                    </Col>
                    <Col>
                        <select onChange={(e) => onYearSelect(month, e.target.value)} value={month.year()}>
                            {years}
                        </select>
                    </Col>
                </Row>
            </div>
        );
    }

    handleSwitchChange = (e) => {
        const { campaignTarget } = this.state;
        const params = { ...campaignTarget.params, [e.target.name]: e.target?.checked };
        this.setState({ campaignTarget: { ...campaignTarget, params } });
    }

    handleChangeCombo = (e, name) => {
        const { campaignTarget } = this.state;
        const params = { ...campaignTarget.params, [name]: e?.value };
        this.setState({ campaignTarget: { ...campaignTarget, params } });
    }

    handleChangeMultiCombo = (name, combo) => {
        const { campaignTarget } = this.state;
        const params = { ...campaignTarget.params, [name]: combo ? combo?.map(cmb => cmb.value) : [] };
        this.setState({ campaignTarget: { ...campaignTarget, params } });
    }

    handleLoyaltyCard = (value) => {
        const { campaignTarget } = this.state;

        const hasLoyaltyCard = campaignTarget.params.hasLoyaltyCard === value ? null : value;

        const params = { ...campaignTarget.params, hasLoyaltyCard, loyaltyCards: hasLoyaltyCard ? campaignTarget.params.loyaltyCards : [] };
        
        this.setState({ campaignTarget: { ...campaignTarget, params } });
    }

    handleVipCodes = (value) => {
        const { campaignTarget } = this.state;

        const hasVipCodes = campaignTarget.params.hasVipCodes === value ? null : value;

        const params = { ...campaignTarget.params, hasVipCodes, vipCodes: hasVipCodes ? campaignTarget.params.vipCodes : [] };
        
        this.setState({ campaignTarget: { ...campaignTarget, params } });
    }

    handleChange = (e, isRange) => {
        const { campaignTarget } = this.state;
        const params = { ...campaignTarget.params, [e.target.name]: isRange ? e.target.value === "0" ? null : e.target.value : e.target.value };
        this.setState({ campaignTarget: { ...campaignTarget, params } });
    }
    
    handleDates = (startDate, endDate, startFieldName, endFieldName) => {
        const { campaignTarget } = this.state;

        const params = {
            ...campaignTarget.params,
            [startFieldName]: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
            [endFieldName]: endDate ? moment(endDate).format("YYYY-MM-DD") : null
        };

        if(startFieldName === "stayFrom" || startFieldName === "noStayFrom" || startFieldName === "bookingFromDate" || startFieldName === "noBookingFromDate"){
            if(!params[startFieldName] && !params[endFieldName]){
                params.hotelIds = null;
            }
        }

        this.setState({ campaignTarget: { ...campaignTarget, params } });
    }

    handleLang = (lang) => {
        const { campaignTarget, langOptions } = this.state;

        const data = langOptions.find(e => e.language === lang);

        if(data.isSelected){
            campaignTarget.params = {
                ...campaignTarget.params,
                nationalities: campaignTarget.params?.nationalities?.filter((c) => !data.countries.find(({value}) => value === c))
            };
        }
        else{
            const nationalities = campaignTarget.params?.nationalities ?? [];
            data.countries.forEach(({value}) => {
                if(!nationalities.includes(value))
                    nationalities.push(value);
            });

            campaignTarget.params = {
                ...campaignTarget.params,
                nationalities
            };
        }

        data.isSelected = !data.isSelected;

        this.setState({ campaignTarget, langOptions });
    }

    handleBiggerThan = () => {
        const { campaignTarget } = this.state;
        const params = {
            ...campaignTarget.params,
            distanceComparisonType: (
                !campaignTarget.params?.distanceComparisonType ||
                campaignTarget.params?.distanceComparisonType === 'GreaterThanOrEqual'
            ) ?
            'LessThanOrEqual' : 'GreaterThanOrEqual'
        };
        this.setState({ campaignTarget: { ...campaignTarget, params } });
    }

    render() {
        const { block, error, campaignTarget, modalMap, loyaltyCardsOptions, activeTab, tagList, langOptions } = this.state;
        const { intl } = this.props;

        const params = campaignTarget?.params??{};
        const profileTypeOptions = getProfileType(intl);
        const otaEmailsOptions = [
            { value: true, label: <FormattedMessage id="CustomCampaign.ProfilesWithOtaEmails" /> },
            { value: false, label: <FormattedMessage id="CustomCampaign.ProfilesWithoutOtaEmails" /> }
        ];

        const tagParametersFilterAndTypes = [undefined, null, 'and'];

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                <div>
                    <form onSubmit={this.saveChanges} id="CampaignTargets">
                        <Card className='border-0 shadow'>
                            <CardBody className='py-2'>
                                <div>
                                    <Row className='my-3'>
                                        <Col>
                                            <h5>
                                                <i className="fas fa-hat-wizard mr-1" />
                                                <FormattedMessage id="CampaignTargets.Targets"/>
                                            </h5>
                                        </Col>
                                        <Col className="text-right col-2">
                                            <Button className="btn btn-sm btn-host" type='submit'>
                                                <i className="fas fa-save"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='mt-2'>
                                    <Row className='pb-2'>
                                        <Col className='col-3 align-items-end d-flex'>
                                            <div className='w-100'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="CampaignTargets.StayDate"/>
                                                </div>
                                                <div className='coolDatePicker'>
                                                    <DateRangePicker
                                                        startDate={params.stayFrom ? moment(params.stayFrom) : null}
                                                        startDateId="startBirth_date_id"
                                                        isOutsideRange={day => false}
                                                        endDate={params.stayTo ? moment(params.stayTo) : null}
                                                        endDateId="endBirth_date_id"
                                                        onDatesChange={({ startDate, endDate }) => this.handleDates(startDate, endDate, 'stayFrom', 'stayTo')}
                                                        focusedInput={this.state.focusedInput1}
                                                        onFocusChange={focusedInput => this.setState({ focusedInput1: focusedInput })}
                                                        numberOfMonths={2}
                                                        showDefaultInputIcon={true}
                                                        renderMonthElement={props => this.renderMonthElement(props)}
                                                        minimumNights={0}
                                                        showClearDates={true}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className='col-3 align-items-end d-flex'>
                                            <div className='w-100'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="CampaignTargets.NoStayDate"/>
                                                </div>
                                                <div className='coolDatePicker'>
                                                    <DateRangePicker
                                                        startDate={params.noStayFrom ? moment(params.noStayFrom) : null}
                                                        startDateId="noStayFrom"
                                                        isOutsideRange={day => false}
                                                        endDate={params.noStayTo ? moment(params.noStayTo) : null}
                                                        endDateId="noStayTo"
                                                        onDatesChange={({ startDate, endDate }) => this.handleDates(startDate, endDate, 'noStayFrom', 'noStayTo')}
                                                        focusedInput={this.state.focusedInput2}
                                                        onFocusChange={focusedInput => this.setState({ focusedInput2: focusedInput })}
                                                        numberOfMonths={2}
                                                        showDefaultInputIcon={true}
                                                        renderMonthElement={props => this.renderMonthElement(props)}
                                                        minimumNights={0}
                                                        showClearDates={true}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className='col-6 d-flex align-items-end'>
                                            <div className='w-100'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="CampaignTargets.Hotels"/>
                                                </div>
                                                <SelectHotel
                                                    name="hotelIds"
                                                    icon={"icon-icon-hotel"}
                                                    isMulti={true}
                                                    onChangeFunc={this.handleChangeMultiCombo}
                                                    placeholder="Hotels"
                                                    isSearchable={false}
                                                    isClearable
                                                    value={params.hotelIds}
                                                    addDefaultValue={false}
                                                    isDisabled={this.isDisabledSelectHotelIds()}
                                                />
                                            </div>
                                        </Col>
                                        <Col className='col-3 mt-3 align-items-end d-flex'>
                                            <div className='w-100'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="CampaignTargets.ReservationDate"/>
                                                </div>
                                                <div className='coolDatePicker'>
                                                    <DateRangePicker
                                                        startDate={params.bookingFromDate ? moment(params.bookingFromDate) : null}
                                                        startDateId="bookingFromDate"
                                                        isOutsideRange={day => false}
                                                        endDate={params.bookingToDate ? moment(params.bookingToDate) : null}
                                                        endDateId="bookingToDate"
                                                        onDatesChange={({ startDate, endDate }) => this.handleDates(startDate, endDate, 'bookingFromDate', 'bookingToDate')}
                                                        focusedInput={this.state.focusedInput3}
                                                        onFocusChange={focusedInput => this.setState({ focusedInput3: focusedInput })}
                                                        numberOfMonths={2}
                                                        showDefaultInputIcon={true}
                                                        renderMonthElement={props => this.renderMonthElement(props)}
                                                        minimumNights={0}
                                                        showClearDates={true}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className='col-3 mt-3 align-items-end d-flex'>
                                            <div className='w-100'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="CampaignTargets.NoReservationDate"/>
                                                </div>
                                                <div className='coolDatePicker'>
                                                    <DateRangePicker
                                                        startDate={params.noBookingFromDate ? moment(params.noBookingFromDate) : null}
                                                        startDateId="noBookingFromDate"
                                                        isOutsideRange={day => false}
                                                        endDate={params.noBookingToDate ? moment(params.noBookingToDate) : null}
                                                        endDateId="noBookingToDate"
                                                        onDatesChange={({ startDate, endDate }) => this.handleDates(startDate, endDate, 'noBookingFromDate', 'noBookingToDate')}
                                                        focusedInput={this.state.focusedInput4}
                                                        onFocusChange={focusedInput => this.setState({ focusedInput4: focusedInput })}
                                                        numberOfMonths={2}
                                                        showDefaultInputIcon={true}
                                                        renderMonthElement={props => this.renderMonthElement(props)}
                                                        minimumNights={0}
                                                        showClearDates={true}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='mt-2'>
                                    <Row className='pb-2'>
                                        <Col className='col-3 align-items-end d-flex'>
                                            <div className='w-100'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="CampaignTargets.Consent"/>
                                                </div>
                                                <CustomSelect
                                                    className="coolSelect"
                                                    icon={"icon-icon-consents"}
                                                    value={params.consentType ? getConsentTypeList().find((o) => o.value === params.consentType) : null}
                                                    onChange={e => this.handleChangeCombo(e, 'consentType')}
                                                    options={getConsentTypeList()}
                                                    placeholder={intl.formatMessage({ id: 'CampaignTargets.Consent' })}
                                                    isClearable={true}
                                                    isSearchable={false}
                                                />
                                            </div>
                                        </Col>
                                        <Col className='col-3 d-flex align-items-end'>
                                            <div className='w-100'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="CampaignTargets.ProfileType"/>
                                                </div>
                                                <CustomSelect
                                                    name="profileType"
                                                    value={params.profileType ? profileTypeOptions.filter(opt => params.profileType.some(crd => opt.value === crd)) : null}
                                                    onChange={e => this.handleChangeMultiCombo('profileType', e)}
                                                    options={profileTypeOptions}
                                                    isMulti={true}
                                                    placeholder={intl.formatMessage({ id: 'CampaignTargets.ProfileType' })}
                                                    isClearable={true}
                                                    isSearchable={false}
                                                />
                                            </div>
                                        </Col>
                                        <Col className='col-6 d-flex align-items-end'>
                                            <div className='w-100'>
                                                <div className='title-sm d-flex align-items-end justify-content-between'>
                                                    <FormattedMessage id="CampaignTargets.Nationality"/>
                                                    <div className='text-right d-flex'>
                                                        {langOptions.map((lang, key) =>
                                                            <CoolBadge
                                                                key={key}
                                                                text={intl.formatMessage({ id: `CampaignTargets.${lang.language}` })}
                                                                onClick={this.handleLang}
                                                                isSelected={lang.isSelected}
                                                                onChange={this.changeTagMin}
                                                                data={lang.language}
                                                                hasInput={false}
                                                                small={true}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='mt-1'>
                                                    <CustomSelect
                                                        icon={"fas fa-globe"}
                                                        options={countryList().getData()}
                                                        isClearable
                                                        isMulti={true}
                                                        value={this.getSelectedNationalities()}
                                                        isSearchable
                                                        placeholder={'Nationality'}
                                                        onChange={this.handleNationalities.bind(this)}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className='col-3 mt-3 d-flex align-items-end'>
                                            <div className='w-100'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="CampaignTargets.BirthDate"/>
                                                </div>
                                                <div className='coolDatePicker'>
                                                    <DateRangePicker
                                                        startDate={params.startBirthDate ? moment(params.startBirthDate) : null}
                                                        startDateId="startBirth_date_id"
                                                        isOutsideRange={day => false}
                                                        endDate={params.endBirthDate ? moment(params.endBirthDate) : null}
                                                        endDateId="endBirth_date_id"
                                                        onDatesChange={({ startDate, endDate }) => this.handleDates(startDate, endDate, 'startBirthDate', 'endBirthDate')}
                                                        focusedInput={this.state.focusedInput}
                                                        onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                        numberOfMonths={2}
                                                        showDefaultInputIcon={true}
                                                        renderMonthElement={props => this.renderMonthElement(props)}
                                                        minimumNights={0}
                                                        showClearDates={true}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className='col-3 mt-3 d-flex align-items-end'>
                                            <div className='w-100'>
                                                <div className='title-sm'>
                                                    <FormattedMessage id="CampaignTargets.OtaEmails"/>
                                                </div>
                                                <CustomSelect
                                                    icon={"far fa-envelope"}
                                                    className="coolSelect"
                                                    value={params.hasOtaEmail !== null ? otaEmailsOptions.find((o) => o.value === params.hasOtaEmail) : null}
                                                    onChange={e => this.handleChangeCombo(e, 'hasOtaEmail')}
                                                    options={otaEmailsOptions}
                                                    placeholder="OTA Emails"
                                                    isClearable={true}
                                                    isSearchable={false}
                                                />
                                            </div>
                                        </Col>
                                        {global.populateCoordinates ?
                                            <Col className='col-6 mt-3 d-flex align-items-end'>
                                                <div className='w-100'>
                                                    <div className='title-sm d-flex align-items-end justify-content-between'>
                                                        <FormattedMessage id="CampaignTargets.Location"/>
                                                        <div className='text-right'>
                                                            <span className="text-muted mr-2 cursor-pointer coolEdit" onClick={this.getCoordinates}>
                                                                <i className="fas fa-map-marker-alt"/>
                                                            </span>
                                                            <span className="text-muted cursor-pointer coolEdit" onClick={this.toggleModalMap}>
                                                                <i className="fas fa-map"/>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <Row className='mt-1'>
                                                        <Col className="col-4 d-flex align-items-end">
                                                            <InputGroup className='shadow coolInputGroup'>
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText style={{ background: 'white', padding: '0.375rem 1rem' }}>
                                                                        <i className="fas fa-map-marker-alt"/>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input
                                                                    type="number"
                                                                    name="latitude"
                                                                    step="any"
                                                                    placeholder='Latitude'
                                                                    className='coolTextInput'
                                                                    value={params.latitude || ''}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </InputGroup>
                                                        </Col>
                                                        <Col className="col-4 d-flex align-items-end">
                                                            <InputGroup className='shadow coolInputGroup'>
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText style={{ background: 'white', padding: '0.375rem 1rem' }}>
                                                                        <i className="fas fa-map-marker-alt"/>
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input
                                                                    type="number"
                                                                    icon="fas fa-map-marker-alt"
                                                                    name="longitude"
                                                                    className='coolTextInput'
                                                                    placeholder='Longitude'
                                                                    step="any"
                                                                    value={params.longitude || ''}
                                                                    onChange={this.handleChange}
                                                                />
                                                            </InputGroup>
                                                        </Col>
                                                        <Col className='d-flex align-items-end'>
                                                            <div>
                                                                <CoolBadge
                                                                    text={params.distanceComparisonType ? params.distanceComparisonType === 'GreaterThanOrEqual' ? ' >= ' : ' <= ' : ' >= '}
                                                                    onClick={this.handleBiggerThan}
                                                                    hasInput={false}
                                                                    squared={true}
                                                                />
                                                            </div>
                                                            <div className='w-100 d-flex align-items-end flex-column justify-content-end'>
                                                                <div className='px-2 text-muted' style={{ fontSize: '0.8em' }}>
                                                                    {params.distance ? params.distance : '-'} km
                                                                </div>
                                                                <CustomInput
                                                                    type="range"
                                                                    className="primaryRange"
                                                                    id="distance"
                                                                    min="0"
                                                                    max="1000"
                                                                    name="distance"
                                                                    step="10"
                                                                    value={params.distance || '0'}
                                                                    onChange={e => this.handleChange(e, true)}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        :''}
                                        <Col className='col-3 mt-3 d-flex align-items-end'>
                                            <div className='w-100'>
                                                <div className='title-sm d-flex align-items-center'>
                                                    <FormattedMessage id="CampaignTargets.LoyaltyCard"/>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                                    <div className='shadow py-3 w-50 pointer mr-2 d-flex align-items-center justify-content-center text-muted' style={{ fontSize: '0.9em', background: params.hasLoyaltyCard ? "#d3eaff" : 'white' }} onClick={() => this.handleLoyaltyCard(true)}>
                                                        <FormattedMessage id="CampaignDetails.ProfilesWithCard"/>
                                                    </div>
                                                    <div className='shadow py-3 w-50 pointer ml-2 d-flex align-items-center justify-content-center text-muted' style={{ fontSize: '0.9em', background: params.hasLoyaltyCard === false ? "#d3eaff" : 'white' }} onClick={() => this.handleLoyaltyCard(false)}>
                                                        <FormattedMessage id="CampaignDetails.ProfilesWithoutCard"/>
                                                    </div>
                                                </div>
                                                <CustomSelect
                                                    name="hasLoyaltyCard"
                                                    icon={"icon-icon-member-card"}
                                                    placeholder="Loyalty Card Level"
                                                    isClearable={true}
                                                    isSearchable={false}
                                                    isMulti={true}
                                                    isDisabled={!params.hasLoyaltyCard}
                                                    options={loyaltyCardsOptions}
                                                    value={params.loyaltyCards ? loyaltyCardsOptions.filter(opt => params.loyaltyCards.some(crd => opt.value === crd)) : null}
                                                    onChange={(combo) => this.handleChangeMultiCombo('loyaltyCards', combo)}
                                                />
                                            </div>
                                        </Col>
                                        <Col className='col-3 mt-3 d-flex align-items-end'>
                                            <div className='w-100'>
                                                <div className='title-sm d-flex align-items-center'>
                                                    <FormattedMessage id="CampaignTargets.VIPCodes"/>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                                    <div className='shadow py-3 w-50 pointer mr-2 d-flex align-items-center justify-content-center text-muted' style={{ fontSize: '0.9em', background: params.hasVipCodes ? "#d3eaff" : 'white' }} onClick={() => this.handleVipCodes(true)}>
                                                        <FormattedMessage id="CampaignDetails.ProfilesWithVipCodes"/>
                                                    </div>
                                                    <div className='shadow py-3 w-50 pointer ml-2 d-flex align-items-center justify-content-center text-muted' style={{ fontSize: '0.9em', background: params.hasVipCodes === false ? "#d3eaff" : 'white' }} onClick={() => this.handleVipCodes(false)}>
                                                        <FormattedMessage id="CampaignDetails.ProfilesWithoutVipCodes"/>
                                                    </div>
                                                </div>
                                                <SelectVipCodes
                                                    name="vipCodes"
                                                    isMulti={true}
                                                    onChangeFunc={this.handleChangeMultiCombo}
                                                    isClearable={true}
                                                    placeholder="VIP Codes"
                                                    isSearchable={false}
                                                    icon={"fas fa-crown"}
                                                    value={params.vipCodes}
                                                    isDisabled={!params.hasVipCodes}
                                                />
                                            </div>
                                        </Col>
                                        <Col className='col-12'>
                                            <div>
                                                <div className='title-sm d-flex align-items-end justify-content-between'>
                                                    <div>
                                                        <FormattedMessage id="CampaignTargets.Tags"/>
                                                    </div>
                                                    <div className='text-right'>
                                                        <Nav tabs className="border-0 mt-3">
                                                            <NavItem>
                                                                <NavLink className={activeTab === '0' ? 'cool-tab-border-host text-host border-0 ' : 'cursor-pointer border-0'} onClick={() => this.toggle('0')}>
                                                                    <FormattedMessage id="CampaignDetails.Included" />
                                                                    {params.tags && params.tags.length > 0 ?
                                                                        <span style={{ 
                                                                            position: 'absolute',
                                                                            top: '18px',
                                                                            marginLeft: '2px',
                                                                            fontWeight: '800',
                                                                            fontSize: '0.8em'
                                                                        }}>
                                                                            {params.tags.length}
                                                                        </span>
                                                                    :''}
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink className={activeTab === '1' ? 'cool-tab-border-host text-host border-0 ' : 'cursor-pointer border-0'} onClick={() => this.toggle('1')}>
                                                                    <FormattedMessage id="CampaignDetails.Excluded" />
                                                                    {params.excludedTags && params.excludedTags.length > 0 ?
                                                                        <span style={{ 
                                                                            position: 'absolute',
                                                                            top: '18px',
                                                                            marginLeft: '2px',
                                                                            fontWeight: '800',
                                                                            fontSize: '0.8em'
                                                                        }}>
                                                                            {params.excludedTags.length}
                                                                        </span>
                                                                    :''}
                                                                </NavLink>
                                                            </NavItem>
                                                        </Nav>
                                                    </div>
                                                </div>
                                                <TabContent activeTab={activeTab}>
                                                    <TabPane tabId="0" className='border-0 px-0 pt-0'>
                                                        <div className='px-2'> 
                                                            <Row className="mt-2 mb-2" style={{ fontSize: '0.8rem'}}>
                                                                <Col className="col-2">
                                                                    <CustomInput
                                                                        type="switch"
                                                                        id="and"
                                                                        name="and"
                                                                        onChange={(e) => this.handleTagType(e)}
                                                                        checked={tagParametersFilterAndTypes.some(tp => tp === campaignTarget.params.tagParametersFilterType)}
                                                                        label={`Profile has all Tags`}
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    <CustomInput
                                                                        type="switch"
                                                                        id="or"
                                                                        name="or"
                                                                        onChange={(e) => this.handleTagType(e)}
                                                                        checked={campaignTarget.params.tagParametersFilterType === 'or'}
                                                                        label={'Profile has at Least one Tag'}
                                                                    />
                                                                </Col>
                                                            </Row>

                                                            {tagList && tagList.map((el, k) =>
                                                                <Row className="pb-2" key={k}>
                                                                    <Col className="mb-1 col-2 d-flex align-items-center" style={{ fontSize: '0.8rem', flex: '0 0 12%', maxWidth: '12%' }}>
                                                                        {el.code}:
                                                                    </Col>
                                                                    <Col className="d-flex flex-wrap">
                                                                        {el.tag && el.tag.map((tag, k1) => {
                                                                            const tagFilter = params.tags && params.tags.find(t => t.id === tag.id);
                                                                            const isSelected = tagFilter !== undefined ? true : false;

                                                                            return (
                                                                                <CoolBadge
                                                                                    key={k1}
                                                                                    text={tag.code}
                                                                                    onClick={this.handleTag}
                                                                                    type="tags"
                                                                                    isSelected={isSelected}
                                                                                    onChange={this.changeTagMin}
                                                                                    data={tag}
                                                                                    value={tagFilter?.min}
                                                                                    hasInput={true}
                                                                                />
                                                                            );
                                                                        }
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                        </div>
                                                    </TabPane>
                                                    <TabPane tabId="1" className='border-0 px-0 pt-0'>
                                                        <div className='px-2'>
                                                            {tagList && tagList.map((el, k) =>
                                                                <Row className="pb-2" key={k}>
                                                                    <Col className="mb-1 col-2 d-flex align-items-center" style={{ fontSize: '0.8rem', flex: '0 0 12%', maxWidth: '12%' }}>
                                                                        {el.code}:
                                                                    </Col>
                                                                    <Col className="d-flex flex-wrap">
                                                                        {el.tag && el.tag.map((tag, k1) => {
                                                                            const tagFilter = params.excludedTags && params.excludedTags.find(t => t.id === tag.id);
                                                                            const isSelected = tagFilter !== undefined ? true : false;

                                                                            return (
                                                                                <CoolBadge
                                                                                    key={k1}
                                                                                    text={tag.code}
                                                                                    onClick={this.handleTag}
                                                                                    type="excludedTags"
                                                                                    isSelected={isSelected}
                                                                                    hasInput={false}
                                                                                    onChange={this.changeTagMin}
                                                                                    data={tag}
                                                                                    value={tagFilter?.min}
                                                                                />
                                                                            );
                                                                        }
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            )}
                                                        </div>
                                                    </TabPane>
                                                </TabContent>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='mt-2'>
                                    <Row className='pb-2'>
                                        {getReviewFields().map((rev, key) => {
                                            const biggerThanValue = `${rev.reviewValue}BiggerOrEqualThan`;

                                            return <Col sm={4} key={key}>
                                                <ReviewRange
                                                    label={rev.label}
                                                    reviewName={rev.reviewValue}
                                                    reviewValue={params[rev.reviewValue]}
                                                    biggerThanValue={params[biggerThanValue]}
                                                    handleBiggerThan={() => this.handleChange({ target: { value: !params[biggerThanValue], name: biggerThanValue } })}
                                                    handleRangeInput={this.handleChange}
                                                    pretty={true}
                                                />
                                            </Col>
                                        } )}
                                    </Row>
                                </div>
                            </CardBody>
                        </Card>
                    </form>
                </div>
                
                <Modal isOpen={modalMap} fade={false} className="modal-lg">
                    <Button onClick={this.toggleModalMap} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody className="modal-lg p-3">
                        <h5 className="mb-3">
                            <FormattedMessage id="CustomCampaign.SelectAPoint"/>
                        </h5>
                        <MapModal
                            latitude={params.latitude}
                            longitude={params.longitude}
                            distance={params.distance}
                            onClick={this.mapOnClick}
                        />
                    </ModalBody>
                </Modal>
            </BlockUi>
        )
    }
}

export default injectIntl(CampaignTargetParams)