import React from 'react';
import CoolDataGridRow from './CoolDataGridRow';
import moment from 'moment-timezone';
import { UncontrolledPopover, Row, Col, Badge } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

const CoolDataGridColumn = ({ onScroll, tableName, col, rows, isFixed, hasIndent, firstCol, newHotelRowStyle, colKey }) => {

    return (
        <div onScroll={e => isFixed && onScroll(e)} id={`${isFixed ? "FixedCoolDataGrid" : ""}`} style={{
            position: col.isFixed ? 'sticky' : '',
            left: col.isFixed ? '0' : !isFixed ? `${((col.width) * colKey)}px` : '',
            minWidth: col.width ?? '',
            maxWidth: col.width ?? '',
            maxHeight: isFixed ? '74vh' : '',
            overflow: isFixed ? 'auto' : '',
            position: !isFixed ? 'absolute' : ''
        }} className={isFixed ? 'noScrollBar' : ''}>
            <div id={`table-${tableName}-col-${col.key}`} style={{ ...col.style, position: 'sticky', top: '0' }} className={`${col.isHolidayOrNote || col.isSmallEvent || col.isEvent || col.isBigEvent ? 'pointer' : ''}
            ${isFixed ? 'text-left' : ''} CoolDataGrid-cols ${!col.style?.width ? "w-100 CoolDataGrid-cols" : ""} ${col.isWeekend ? 'CoolDataGrid-cols-weekend' : ''}
             `}>
                <span className={`${col.isHolidayOrNote ? 'CoolDataGrid-cols-holiday' : ''}${col.isSmallEvent ? 'CoolDataGrid-cols-smallEvent' : ''}
                ${col.isEvent ? 'CoolDataGrid-cols-event' : ''}${col.isBigEvent ? 'CoolDataGrid-cols-bigEvent' : ''}`}>

                    <span className={`${col.isToday ? 'CoolDataGrid-cols-today' : ''}`}>
                        {col.name}
                    </span>
                </span>
                {col.eventDetails && col.eventDetails.length > 0 ?
                    <div>
                        <UncontrolledPopover target={`table-${tableName}-col-${col.key}`} placement={'top'} placementPrefix='coolPopover bs-popover' onScroll={(e) => e.stopPropagation()}>
                            <div className='py-2 pl-3 veryCoolScrollBar' style={{ minWidth: '250px', maxWidth: '350px', maxHeight: '150px', overflow: 'auto', overflowX: 'hidden' }} id="SalesActionsPopOver">
                                {col.eventDetails.map((x, index) => (
                                    <div style={{ width: '20rem' }} className='p-1' key={index}>
                                        <Row>
                                            <Col className='col-8 text-truncate' style={{ fontSize: '1.1em' }}>
                                                <div className='text-truncate'>
                                                    {x.name} <span className='text-muted ml-1' style={{ fontSize: '0.8em' }}>{index + 1} <FormattedMessage id="generic.Of" /> {col.eventDetails.length}</span>
                                                </div>
                                            </Col>
                                            <Col className='col-4 text-center'>
                                                <Badge className={`text-truncate cityEvent-${x.eventType} p-2`}>
                                                    {x.eventType}
                                                </Badge>
                                            </Col>
                                        </Row>
                                        <Row className='mt-1'>
                                            {x.eventType !== "Holiday" && x.eventType !== "Note" ?
                                                <Col className="col-12 mb-2">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.TotalPax" />
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {x.pax}
                                                    </div>
                                                </Col>
                                                : ''}
                                            <Col className="col-6">
                                                <div className='title-sm text-truncate mb-0'>
                                                    <FormattedMessage id="SalesProcess.StartDate" />
                                                </div>
                                                <div className='text-truncate'>
                                                    {moment(x.startDate).format('YYYY-MM-DD')}
                                                </div>
                                            </Col>
                                            <Col className="col-6">
                                                <div className='title-sm text-truncate mb-0'>
                                                    <FormattedMessage id="SalesProcess.EndDate" />
                                                </div>
                                                <div className='text-truncate'>
                                                    {moment(x.endDate).format('YYYY-MM-DD')}
                                                </div>
                                            </Col>
                                            {x.eventType === "Note" ?
                                                <Col className="col-12">
                                                    <div className='title-sm text-truncate mb-0'>
                                                        <FormattedMessage id="SalesProcess.Note" />
                                                    </div>
                                                    <div>
                                                        {x.description}
                                                    </div>
                                                </Col>
                                                : ''}
                                        </Row>
                                        {col.eventDetails[index + 1] != null ? <hr style={{ margin: '8px' }} /> : ''}
                                    </div>
                                ))}
                            </div>
                        </UncontrolledPopover>
                    </div>
                    : ''}
            </div>
            {rows ?
                <div className={`d-flex flex-column align-items-start CoolDataGrid-rows-container ${col.isWeekend ? 'CoolDataGrid-rows-container-weekend' : ''}${col.isToday ? 'CoolDataGrid-rows-container-today' : ''}`}>
                    {rows.map((row, rowKey) =>
                        <CoolDataGridRow
                            row={row}
                            isFixed={isFixed}
                            key={rowKey}
                            firstCol={firstCol}
                            rowKey={rowKey}
                            col={col}
                            hasIndent={hasIndent}
                            tableName={tableName}
                            newHotelRowStyle={newHotelRowStyle}
                        />
                    )}
                </div>
            :''}
        </div>
    );
}

export default CoolDataGridColumn;
