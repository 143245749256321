import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FormatAmountNumber } from '../Base/CommonUIComponents';
import { CustomTable } from '../Base/CustomTable';
import { CustomFormatDate } from './SalesProcessFunctions';

class SalesDealProposalRooms extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            listRooms: [],
            totalRoomsValue: 0,
        };
    }

    componentDidMount() {
        this.roomsTableList();
    }

    componentDidUpdate(prevProps) {
        if (this.props.filtersLength !== prevProps.filtersLength) {
            this.roomsTableList(this.props.proposals);
        }
    }

    roomsTableList = () => {
        const { proposals, intl } = this.props;

        var listRooms = [];
        var totalRoomsValue = 0;

        proposals.forEach((p, index) => {
            if (p.isVisible) {
                p.proposalJSON.BlockData && p.proposalJSON.BlockData.details.forEach((b, idx) => {
                    const headerData = p.proposalJSON.BlockData.headerData?.length > 0 ? p.proposalJSON.BlockData.headerData.find(el => el.Id === b.HeaderId) : null;
                    
                    if (b.isVisible) {
                        totalRoomsValue += (b.CurrentRooms * b.PriceAfterTaxPerRoom_Total) || 0;
                        listRooms.push({
                            Id: `blockDetail-${index}-${idx}`,
                            Date: b.Date ? CustomFormatDate(b.Date, null, null, intl) : null,
                            ResNumber: p.externalId,
                            Hotel: p.hotelId,
                            CategoryCode: b.CategoryCode,
                            PricelistCode: headerData?.PriceListCode,
                            PackageCode: headerData?.PackageCode,
                            Rooms: b.CurrentRooms,
                            RoomPrice: b.PriceAfterTaxPerRoom_Total,
                            TotalPrice: (b.CurrentRooms * b.PriceAfterTaxPerRoom_Total) || 0
                        });
                    }
                })
            }
        })

        this.setState({ listRooms, filteredListRooms: listRooms, totalRoomsValue })
    }

    render() {
        const { filteredListRooms, totalRoomsValue } = this.state;
        const { hotelCurrency } = this.props;
        const columns = [
            {
                dataField: 'ResNumber',
                text: this.props.intl.formatMessage({ id: "SalesDeal.ResNumber" }),
                sort: true
            },
            {
                dataField: 'Date',
                text: this.props.intl.formatMessage({ id: "SalesDeal.Date" }),
                sort: true
            },
            {
                dataField: 'CategoryCode',
                text: this.props.intl.formatMessage({ id: "SalesDeal.Category" }),
                sort: true
            },
            {
                dataField: 'PricelistCode',
                text: this.props.intl.formatMessage({ id: "SalesDeal.Rate" }),
                sort: true
            },
            {
                dataField: 'PackageCode',
                text: this.props.intl.formatMessage({ id: "SalesDeal.Package" }),
                sort: true
            },
            {
                dataField: 'Rooms',
                text: this.props.intl.formatMessage({ id: "SalesDeal.Rooms" }),
                sort: true
            },
            {
                dataField: 'RoomPrice',
                text: this.props.intl.formatMessage({ id: "SalesDeal.Price" }),
                sort: true,
                formatter: (cell, row) => cell ? < FormatAmountNumber currency={hotelCurrency} value={cell} /> : ''
            },
            {
                dataField: 'TotalPrice',
                text: this.props.intl.formatMessage({ id: "SalesDeal.TotalPrice" }),
                sort: true,
                formatter: (cell, row) => cell ? < FormatAmountNumber currency={hotelCurrency} value={cell} /> : ''
            }
        ];

        return (
            <div>
                <div className='d-flex align-items-center justify-content-between text-muted pl-2 mb-3'>
                    <div style={{ fontSize: '1.1em' }}>
                        <i className="fas fa-bed mr-2"/>
                        <FormattedMessage id="SalesDeal.Rooms"/>
                    </div>
                    <div style={{ fontSize: '1.4em' }}>
                        <FormatAmountNumber currency={hotelCurrency} value={totalRoomsValue}/>
                    </div>
                </div>
                {filteredListRooms && filteredListRooms.length > 0 ?
                    <div>
                        <CustomTable
                            data={filteredListRooms}
                            columns={columns}
                            search={true}
                            exportCSV={true}
                            hasPagination={true}
                            showTotal={true}
                            hideSizePerPage={false}
                        />
                    </div>
                : ''}
            </div>
        );
    }
}

export default injectIntl(SalesDealProposalRooms);
