import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TabContent, TabPane, Col, Row } from 'reactstrap';
import { StyledCard } from "../../Base/CommonUIComponents";
import { ComplexSideBar } from '../../Base/SideBar';
import Whitelist from './Settings/Whitelist';
import Blocks from './Settings/Blocks';
import Bounces from './Settings/Bounces';
import Spam from './Settings/Spam';
import Invalid from './Settings/Invalid';
import UnsubscribeGroups from './Settings/UnsubscribeGroups';
import Suppression from './Settings/Suppression';
import Dashboard from './Dashboard';
import Geographical from './Stats/Geographical';
import DeviceStats from './Stats/DeviceStats';
import BrowserStats from './Stats/BrowserStats';
import Tracking from './Settings/Tracking';
import { SendGridTransactionalTemplateList } from './SendGridTransactionalTemplateList';
import Senders from './Settings/Senders';
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import SendGridAccount from './SendGridAccount';
import Alerts from './Settings/Alerts/Alerts';
import SendGridWebhooks from './Settings/Webhooks/SendGridWebhooks';
import MailSettings from './Settings/MailSettings/MailSettings';
import Activity from './Activity';
import Engagement from './Engagement';

export class MailConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            activeMenu: 'Dashboard',
            activeTab: null,
            menus: [
                { tag: 'Dashboard', icon: 'fas fa-tachometer-alt mr-2', name: 'Dashboard', isSelectable: true },
                { tag: 'Account', icon: 'far fa-user-circle mr-2', name: <FormattedMessage id="SendGrid.Account" />, isSelectable: true },
                { tag: 'Templates', icon: 'fas fa-envelope-open-text mr-2', name: 'Templates', isSelectable: true },
                { tag: 'Activity', icon: 'fas fa-history mr-2', name: <FormattedMessage id="SendGrid.Activity" />, isSelectable: true },
                { tag: 'Engagement', icon: 'far fa-thumbs-up mr-2', name: <FormattedMessage id="SendGrid.Engagement" />, isSelectable: true },
                { tag: 'Stats', icon: 'icon-icon-Satistics-menu mr-2', name: <FormattedMessage id="SendGrid.Statistics" />, isSelectable: false,
                    submenus: [
                        { tag: "GeographicalStats", icon: "icon-icon-Satistics-menu", name: <FormattedMessage id="SendGrid.Geographical" /> },
                        { tag: "DevicesStats", icon: "icon-icon-Satistics-menu", name: <FormattedMessage id="SendGrid.EmailClientsAndDevices" /> },
                        { tag: "BrowserStats", icon: "icon-icon-Satistics-menu", name: <FormattedMessage id="SendGrid.Browser" /> },
                    ]
                },
                {
                    tag: 'Supression', icon: 'fas fa-exclamation-circle mr-2', name: <FormattedMessage id="SendGrid.Supression" />, isSelectable: false,
                    submenus: [
                        { tag: "Bounces", icon: "fas fa-exclamation-circle", name: <FormattedMessage id="SendGrid.Bounces" /> },
                        { tag: "SpamReports", icon: "fas fa-exclamation-circle", name: <FormattedMessage id="SendGrid.SpamReports" /> },
                        { tag: "Blocks", icon: "fas fa-exclamation-circle", name: <FormattedMessage id="SendGrid.Blocks" /> },
                        { tag: "Invalid", icon: "fas fa-exclamation-circle", name: <FormattedMessage id="SendGrid.Invalid" /> },
                        { tag: "UnsubscribeGroups", icon: "fas fa-exclamation-circle", name: <FormattedMessage id="SendGridSupression.UnsubscribeGroups" /> },
                        { tag: "GlobalSuppressions", icon: "fas fa-exclamation-circle", name: <FormattedMessage id="SendGridSupression.Suppressions" /> },
                    ]
                },
                { tag: 'Settings', icon: 'fas fa-cog mr-2', name: <FormattedMessage id="SendGrid.Settings" />, isSelectable: false,
                    submenus: [
                        { tag: "Alerts", icon: "fas fa-cog mr-2", name: <FormattedMessage id="SendGrid.Alerts" /> },
                        { tag: "Whitelist", icon: "fas fa-cog mr-2", name: <FormattedMessage id="SendGrid.WhiteList" /> },
                        { tag: "Senders", icon: "fas fa-cog mr-2", name: <FormattedMessage id="SendGrid.Senders" /> },
                        { tag: 'Webhooks', icon: 'fas fa-cog mr-2', name: 'Webhooks' },
                        { tag: "MailSettings", icon: "fas fa-cog", name: 'Mail' },
                        { tag: "Tracking", icon: "fas fa-cog", name: <FormattedMessage id="SendGrid.Tracking" /> },

                    ]
                },
            ],
            userData: null,
            creditBalance: null
        };
    }

    componentDidMount(){
        this.getUserName();
    }

    getUserName = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }

                const userData = {
                    userName: data.response?.username,
                    userId: data.response?.user_id
                };

                this.setState({ userData }, () => this.getUserRep());
            }
        }, `/api/gms/SendGrid/v1/username`);
    }

    getUserRep = () => {
        const { userData } = this.state;
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }

                this.setState({ userData: { ...userData, reputation: data.response[0]?.reputation ? parseInt(data.response[0]?.reputation) : null } }, () => this.getUsersCredit());
            }
        }, `/api/gms/SendGrid/v1/subuser/reputation?username=${userData.userName}`);
    }

    getUsersCredit = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }

                const creditBalance = {
                    remain: data.response?.remain,
                    total: data.response?.total,
                    overage: data.response?.overage,
                    used: data.response?.used,
                    lastReset: data.response?.last_reset,
                    nextReset: data.response?.next_reset,
                    resetFrequency: data.response?.reset_frequency,
                };

                this.setState({ creditBalance, block: false });
            }
        }, `/api/gms/SendGrid/v1/creditbalance`);
    }
    
    toggleMenu = (menu, isSelectable) => {
        if (this.state.activeMenu !== menu) {
            const activeTab = isSelectable ? null : this.state.activeTab;

            this.setState({
                activeMenu: menu,
                activeTab
            });
        }
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    render() {
        const { block, error, menus, activeMenu, activeTab, userData, creditBalance } = this.state;

        const currentMenu = menus.find(p => p.tag === activeMenu);
        const currentPage = activeTab ? menus.flatMap(m => m.submenus).find(p => p?.tag === activeTab) : null;


        return (
            <StyledCard /* block={block} */ error={error} >
                <Row>
                    <Col sm={3}>
                        <ComplexSideBar
                            menuTitle={<FormattedMessage id="NavMenu.MailConfig" />}
                            activeMenu={activeMenu}
                            activeTab={activeTab}
                            menus={menus}
                            toggleMenu={this.toggleMenu}
                            toggleTab={this.toggleTab}
                            userData={userData}
                            creditBalance={creditBalance}
                            hideSubmenuIcon={true}
                            block={block}
                        />
                    </Col>
                    <Col className="col-9 pl-0">
                        <TabContent activeTab={currentMenu.submenus ? activeTab : activeMenu} className='border-0 verticalScroll' style={{ maxHeight: '89vh', overflowX: 'hidden', height: '100%' }}>
                            <TabPane tabId='Dashboard' className='border-0 py-0'>
                                <Dashboard
                                    page={currentMenu}
                                />
                            </TabPane>

                            {/* Supression */}
                            <TabPane tabId='Bounces' className='border-0 py-0'>
                                {this.state.activeTab === 'Bounces' ?
                                    <Bounces
                                        page={{ ...currentPage, icon: currentMenu.icon }}
                                    />
                                    : ''}
                            </TabPane>
                            <TabPane tabId='SpamReports' className='border-0 py-0'>
                                {this.state.activeTab === 'SpamReports' ?
                                    <Spam
                                        page={{ ...currentPage, icon: currentMenu.icon }}
                                    />
                                    : ''}
                            </TabPane>
                            <TabPane tabId='Blocks' className='border-0 py-0'>
                                {this.state.activeTab === 'Blocks' ?
                                    <Blocks
                                        page={{ ...currentPage, icon: currentMenu.icon }}
                                    />
                                    : ''}
                            </TabPane>
                            <TabPane tabId='Invalid' className='border-0 py-0'>
                                {this.state.activeTab === 'Invalid' ?
                                    <Invalid
                                        page={{ ...currentPage, icon: currentMenu.icon }}
                                    />
                                    : ''}
                            </TabPane>
                            <TabPane tabId='UnsubscribeGroups' className='border-0 py-0'>
                                {this.state.activeTab === 'UnsubscribeGroups' ?
                                    <UnsubscribeGroups
                                        page={{ ...currentPage, icon: currentMenu.icon }}
                                    />
                                    : ''}
                            </TabPane>

                            <TabPane tabId='GlobalSuppressions' className='border-0 py-0'>
                                {this.state.activeTab === 'GlobalSuppressions' ?
                                    <Suppression
                                        page={{ ...currentPage, icon: currentMenu.icon }}
                                    />
                                    : ''}
                            </TabPane>

                            {/* Settings */}
                            <TabPane tabId='Alerts' className='border-0 py-0'>
                                {this.state.activeTab === 'Alerts' ?
                                    <Alerts
                                        page={currentPage}
                                    />
                                    : ''}
                            </TabPane>
                            <TabPane tabId='Whitelist' className='border-0 py-0'>
                                {this.state.activeTab === 'Whitelist' ?
                                <Whitelist
                                    page={currentPage}
                                />
                                : ''}
                            </TabPane>
                            <TabPane tabId='Senders' className='border-0 py-0'>
                                {this.state.activeTab === 'Senders' ?
                                    <Senders
                                        page={currentPage}
                                    />
                                    : ''}
                            </TabPane>
                            <TabPane tabId='Webhooks' className='border-0 py-0'>
                                {this.state.activeTab === 'Webhooks' ?
                                    <SendGridWebhooks
                                        page={currentPage}
                                    />
                                : ''}
                            </TabPane>
                            <TabPane tabId='Tracking' className='border-0 py-0'>
                                {this.state.activeTab === 'Tracking' ?
                                <Tracking
                                    page={{ ...currentPage, icon: currentMenu.icon }}
                                />
                                : ''}
                            </TabPane>
                            <TabPane tabId='MailSettings' className='border-0 py-0'>
                                {this.state.activeTab === 'MailSettings' ?
                                    <MailSettings
                                        page={currentPage}
                                    />
                                    : ''}
                            </TabPane>


                            {/* Stats */}
                            <TabPane tabId='GeographicalStats' className='border-0 py-0'>
                                {this.state.activeTab === 'GeographicalStats' ?
                                    <Geographical
                                        page={currentPage}
                                    />
                                    : ''}
                            </TabPane>
                            <TabPane tabId='DevicesStats' className='border-0 py-0'>
                                {this.state.activeTab === 'DevicesStats' ?
                                    <DeviceStats
                                        page={currentPage}
                                    />
                                    : ''}
                            </TabPane>
                            <TabPane tabId='BrowserStats' className='border-0 py-0'>
                                {this.state.activeTab === 'BrowserStats' ?
                                    <BrowserStats
                                        page={currentPage}
                                    />
                                    : ''}
                            </TabPane>

                            {/* Templates */}
                            <TabPane tabId='Templates' className='border-0 py-0'>
                                {this.state.activeMenu === 'Templates' ?
                                    <SendGridTransactionalTemplateList
                                        page={currentMenu}
                                        intl={this.props.intl}
                                    />
                                : ''}
                            </TabPane>

                            {/* Account */}
                            <TabPane tabId='Account' className='border-0 py-0'>
                                {this.state.activeMenu === 'Account' ?
                                    <SendGridAccount
                                        page={currentMenu}
                                        userData={userData}
                                        creditBalance={creditBalance}
                                    />
                                : ''}
                            </TabPane>

                            <TabPane tabId='Activity' className='border-0 py-0'>
                                {this.state.activeMenu === 'Activity' ?
                                    <Activity
                                        page={currentMenu}
                                    />
                                : ''}
                            </TabPane>

                            <TabPane tabId='Engagement' className='border-0 py-0'>
                                {this.state.activeMenu === 'Engagement' ?
                                    <Engagement
                                        page={currentMenu}
                                        userName={userData?.userName}
                                    />
                                : ''}
                            </TabPane>

                        </TabContent>
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}

export default injectIntl(MailConfig)