import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Form, FormGroup, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import { getAPI, putAPI } from "../Base/API";
import { ActiveInactiveStatusBadge, EmptyCard, StyledCard, ActiveInactiveStatusCombo } from "../Base/CommonUIComponents";
import { handleNotification } from "../Base/Notification";
import { getComparationList, getRunPeriodList, getWeekDays } from "../Base/ReferenceDataFunctions";
import ScheduleDetails from './ScheduleDetails';
import AddRule from './AddRule';
import moment from 'moment';

import Authorization from '../Base/Authorization';

export class DataQualityTemplateDetail extends Component {

    constructor(props) {
        super(props);
        this.getHotels = this.getTemplateDetails.bind(this);
        this.state = {
            block: true,
            templateDetail: {},
            addRulesModal: false,
            scheduleModal: false
        };
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.getTemplateDetails();
        }        
    }

    getTemplateDetails() {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ templateDetail: data.response ? data.response[0] : {} });
            }
            this.setState({ error: errorMessage, block: false });
        }, '/api/gms/DataQuality/v1/DataQualityTemplateRules/' + this.props.match.params.id);
    }
    
    handleInput({ target: { value, name } }) {
        this.setState({
            templateDetail: {
                ...this.state.templateDetail,
                [name]: value
            }
        });
    }

    saveTemplate = (e) => {
        e.preventDefault();
        this.setState({ block: true });
        const body = {
            request: {
                id: this.state.templateDetail.id,
                name: this.state.templateDetail.name,
                active: this.state.templateDetail.active,
                dataQualityTopicId: this.state.templateDetail.dataQualityTopicId,
                description: this.state.templateDetail.description,
                order: this.state.templateDetail.order
            }
        }

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="DataQualityTemplateDetail.TemplateSaved" />, <FormattedMessage id="generic.success" />);

                    const copy = Object.assign(this.state.templateDetail, data.response[0]);
                    this.setState({ templateDetail: copy })
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/DataQuality/v1/DataQualityTemplate`, JSON.stringify(body));
    }

    removeRule = (e, ruleId) => {
        e.preventDefault();
        this.setState({ block: true });
        var body = [];

        this.state.templateDetail.rules.forEach(el => {
            if (el.id !== ruleId) {
                body.push(el.id)
            }
        })

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="DataQualityTemplateDetail.RuleRemovedSuccessfully" />, <FormattedMessage id="generic.success" />);
                    this.updateTable(data.response[0].rules, 'rules');
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/DataQuality/v1/DataQualityTemplateRules/${this.props.match.params.id}`, body);
    }

    updateTable = (data, prop) => {
        if (prop) {
            this.setState({
                templateDetail: {
                    ...this.state.templateDetail,
                    [prop]: data
                }
            })
        }
        else {
            this.setState({ templateDetail: data })
        }
    }

    toggleModal = (modal) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));
    }

    runNow = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0 && data.response[0].running) {
                    handleNotification(data, <FormattedMessage id="DataQualityTemplateDetail.RulesExecutedSuccessfully" />, <FormattedMessage id="generic.success" />);
                    this.getTemplateDetails();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gmsscheduletask/ScheduleTasks/RunTemplate?templateId=${this.state.templateDetail.id}`);
    }

    handleChangeCombo = (name, combo) => {
        this.setState({
            templateDetail: {
                ...this.state.templateDetail,
                [name]: combo ? combo.value : null
            }
        });
    };

    render() {
        const { block, error, templateDetail } = this.state;

        const weekDays = getWeekDays(this.props.intl);

        const runPeriod = templateDetail.schedule && templateDetail.schedule.period && getRunPeriodList().find(o => o.value === templateDetail.schedule.period) ? getRunPeriodList().find(o => o.value === templateDetail.schedule.period).label : '';
        
        var weekDaysRunning = [];
        if (templateDetail.schedule && templateDetail.schedule.period === 'Weekly') {
            templateDetail.schedule.weekDays.map((day, i) => {
                if (day) {
                    weekDaysRunning.push(weekDays[i])
                }
            })
        }

        return (
            <>
                <StyledCard error={error} block={block}>
                    <Form onSubmit={this.saveTemplate}>   
                        <>
                            <Row>
                                <Col>
                                    <h5>
                                        <i className="fas fa-file-alt mr-2"></i>
                                        <FormattedMessage id="DataQualityTemplateDetail.Title"/>
                                    </h5>
                                </Col>
                                <Authorization
                                    perform="dataQualityTemplateDetail:save"
                                    yes={() => (
                                        <Col className="text-right col-1">
                                            <Button className="btn btn-sm btn-host mr-2" type="submit">
                                                <i className="fas fa-save"></i>
                                            </Button>
                                        </Col>
                                    )}
                                    no={() => <div></div>}
                                />
                            </Row>
                     
                            <Row className='mt-3'>
                                <Col>
                                    <Label>
                                        <FormattedMessage id="DataQualityTemplateDetail.TemplateName"/>
                                    </Label>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={templateDetail ? templateDetail.name : ''}
                                        onChange={e => this.handleInput(e)}
                                        maxLength="50"
                                        required
                                    />
                                </Col>
                                <Col>
                                    <Label>
                                        <FormattedMessage id="generic.status"/>
                                    </Label>
                                    <ActiveInactiveStatusCombo
                                        name='active'
                                        onChangeFunc={this.handleChangeCombo.bind(this)}
                                        placeholder={''}
                                        value={templateDetail ? templateDetail.active : ''}
                                    />
                                </Col>
                                <Col>
                                    <Label>
                                        <FormattedMessage id="DataQualityTemplateDetail.Order"/>
                                    </Label>
                                    <Input
                                        type="number"
                                        min="0"
                                        max="99"
                                        name="order"
                                        value={templateDetail ? templateDetail.order : ''}
                                        onChange={e => this.handleInput(e)}
                                        required
                                    />
                                </Col>
                                <Col className='col-12 mt-2'>
                                    <Label>
                                        <FormattedMessage id="DataQualityTemplateDetail.Description"/>
                                    </Label>
                                    <Input
                                        type="textarea"
                                        name="description"
                                        required
                                        value={templateDetail ? templateDetail.description : ''}
                                        onChange={e => this.handleInput(e)}
                                        maxLength="200"
                                    />
                                </Col>
                            </Row>
                        </>    
                    </Form>                                   

                    {this.state.addRulesModal ?
                        <AddRule
                            modal={this.state.addRulesModal}
                            toggleModal={() => this.toggleModal('addRulesModal')}
                            templateId={templateDetail.id}
                            rules={templateDetail.rules}
                            updateTable={this.updateTable}
                        />
                    : ''}

                <div className="mb-2" />
                    <EmptyCard>
                        <Row>
                            <Col>
                                <h5> <FormattedMessage id="DataQualityTemplateDetail.Rules" /> </h5>
                            </Col>
                            <Authorization
                                perform="dataQualityTemplateDetail:rules:add"
                                yes={() => (
                                    <Col className="text-right mb-2">
                                        <Button className="btn btn-sm btn-host mr-2" onClick={() => this.toggleModal('addRulesModal')}>
                                            <i className="fas fa-plus"></i>
                                        </Button>
                                    </Col>
                                )}
                                no={() => <div></div>}
                            />
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <b><FormattedMessage id="DataQualityTemplateDetail.Name" /></b>
                            </Col>
                            <Col>
                                <b><FormattedMessage id="DataQualityTemplateDetail.Field" /></b>
                            </Col>
                            <Col>
                                <b><FormattedMessage id="DataQualityTemplateDetail.Entity" /></b>
                            </Col>
                            <Col>
                                <b><FormattedMessage id="DataQualityTemplateDetail.ComparisonType" /></b>
                            </Col>
                            <Col>
                                <b><FormattedMessage id="DataQualityTemplateDetail.Status" /></b>
                            </Col>
                            <Authorization
                                perform="dataQualityTemplateDetail:rules:delete"
                                yes={() => (
                                    <Col className="col-1 text-right mr-3" />
                                )}
                                no={() => <div></div>}
                            />
                        </Row>

                        {templateDetail && templateDetail.rules && templateDetail.rules.map((rule, k) =>
                            <Row className="overbglight mb-1" key={k}>
                                <Col style={{ 'cursor': 'pointer' }} onClick={() => this.props.history.push({ pathname: "/RuleDetail/" + rule.id })}>
                                    {rule.name}
                                </Col>
                                <Col>
                                    {rule.field}
                                </Col>
                                <Col>
                                    {rule.entity}
                                </Col>
                                <Col>
                                    {rule.comparisonType && getComparationList().find(o => o.value === rule.comparisonType) != undefined ? getComparationList().find(o => o.value === rule.comparisonType).label : ''}
                                </Col>
                                <Col>
                                    <ActiveInactiveStatusBadge status={rule.active} />
                                </Col>
                                <Authorization
                                    perform="dataQualityTemplateDetail:rules:delete"
                                    yes={() => (
                                        <Col className="col-1 text-right mr-2" >
                                            <Button className="btn btn-sm btn-host" onClick={(e) => this.removeRule(e, rule.id)}>
                                                <i className="fas fa-trash"></i>
                                            </Button>
                                        </Col>
                                    )}
                                    no={() => <div></div>}
                                />
                            </Row>
                        )}
                    </EmptyCard>

                    <div className="mb-2" />

                    <EmptyCard >
                        <Row className="mt-2">
                            <Col>
                                <h5> <FormattedMessage id="DataQualityTemplateDetail.Schedule" /> </h5>
                            </Col>

                            <Authorization
                                perform="dataQualityTemplateDetail:schedule"
                                yes={() => (
                                    <Col className="text-right">
                                        <Button className="btn btn-sm btn-host mr-2" onClick={() => this.toggleModal('scheduleModal')} id="scheduleTemplate">
                                            <i className="fas fa-calendar-alt"></i>
                                        </Button>
                                        <UncontrolledTooltip placement="top" target="scheduleTemplate">
                                            Schedule Template
                                        </UncontrolledTooltip>
                                    </Col>
                                )}
                                no={() => <div></div>}
                            />

                        </Row>

                        {templateDetail.schedule === null || templateDetail.schedule === undefined ?
                            <Row>
                                <Col>
                                    <Label><FormattedMessage id="DataQualityTemplateDetail.ThereAreNoSchedule" /></Label>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col>
                                    <b>
                                        {templateDetail.schedule.period === 'Never' ?
                                            <span> {runPeriod} {this.props.intl.formatMessage({ id: "DataQualityTemplateDetail.Runs" }).toLowerCase()}  </span>
                                            : <span> <FormattedMessage id="DataQualityTemplateDetail.Runs" /> {runPeriod}</span>}
                                    
                                        {templateDetail.schedule.period === 'Weekly' ?
                                            <span> <FormattedMessage id="DataQualityTemplateDetail.On" /> {weekDaysRunning.join(', ')} </span>
                                        : templateDetail.schedule.period === 'Monthly' ?
                                                <span> <FormattedMessage id="DataQualityTemplateDetail.OnDay" /> {templateDetail.schedule.day} </span>
                                        : ''}
                                    </b>
                                </Col>
                            </Row>
                        }

                        {this.state.scheduleModal ?
                            <ScheduleDetails
                                modal={this.state.scheduleModal}
                                toggleModal={() => this.toggleModal('scheduleModal')}
                                templateId={templateDetail.id}
                                schedule={templateDetail.schedule}
                                updateTable={this.updateTable}
                            />
                        : ''}
                    </EmptyCard>

                    <div className="mb-2" />

                    <EmptyCard >
                        <Row className="mt-2">
                            <Col>
                                <h5> <FormattedMessage id="DataQualityTemplateDetail.Run" /> </h5>
                            </Col>
                            <Col className="text-right">
                                <Authorization
                                    perform="dataQualityTemplateDetail:rules:run"
                                    yes={() => (
                                        <>
                                            <Button className="btn btn-sm btn-host mr-2" onClick={this.runNow} id="runNow" disabled={templateDetail.active === false}>
                                                <i className="fas fa-play"></i>
                                            </Button>
                                            <UncontrolledTooltip placement="top" target="runNow">
                                                <FormattedMessage id="DataQualityTemplateDetail.RunRulesNow" />
                                            </UncontrolledTooltip>
                                        </>
                                    )}
                                    no={() => <div></div>}
                                />

                                <Button className="btn btn-sm btn-host mr-2" onClick={() => this.props.history.push({ pathname: `/TaskResultDashboard`, search: `?page=1&pageSize=10&topic=${templateDetail.dataQualityTopicId}&template=${templateDetail.id}` })} id="runDetails">
                                    <i className="far fa-calendar-check"></i>
                                </Button>
                                <UncontrolledTooltip placement="top" target="runDetails">
                                    <FormattedMessage id="DataQualityTemplateDetail.ViewRunDetails" />
                                </UncontrolledTooltip>

                            </Col>
                        </Row>
                        {templateDetail.run === null || templateDetail.run === undefined ?
                            <Row>
                                <Col>
                                    <Label><FormattedMessage id="DataQualityTemplateDetail.ThereAreNoRuns" /></Label>
                                </Col>
                            </Row>
                            :
                            <>
                                <Row>
                                    <Col>
                                        <div><b><FormattedMessage id="DataQualityTemplateDetail.Date" />: </b>{moment(templateDetail.run.runDate).format("YYYY-MM-DD HH:mm")}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div><b><FormattedMessage id="DataQualityTemplateDetail.TotalRecords" />: </b>{templateDetail.run.numRecordsAnalyzed}</div>
                                    </Col>
                                    <Col>
                                        <div><b><FormattedMessage id="DataQualityTemplateDetail.RecordsWithIssues" />: </b>{templateDetail.run.numRecordsWithIssues}</div>
                                    </Col>
                                    <Col>
                                        <div><b>% <FormattedMessage id="DataQualityTemplateDetail.RecordsWithIssues" />: </b>{templateDetail.run.numRecordsWithIssues && templateDetail.run.numRecordsAnalyzed ? ((templateDetail.run.numRecordsWithIssues * 100) / templateDetail.run.numRecordsAnalyzed).toFixed(2) : 0}%</div>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col onClick={() => this.props.history.push({ pathname: `/TaskResultDetail/${templateDetail.run.runId}`, search: "?page=1&pageSize=10" })} style={{ cursor: 'pointer' }}>
                                        <span style={{ textDecoration: 'underline' }}> <FormattedMessage id="generic.SeeDetails" /> </span>
                                    </Col>
                                </Row>
                            </>
                        }
                    </EmptyCard>

                </StyledCard>
            </>
        );
    }
}
export default injectIntl(DataQualityTemplateDetail)