import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, ButtonGroup, Col, Row, FormGroup, FormFeedback, Card, Input } from 'reactstrap';
import moment from 'moment';
import BlockUi from 'react-block-ui';
import { ActiveInactiveStatusCombo, BinaryStatusCombo } from '../../Base/CommonUIComponents';
import { SingleDatePicker } from 'react-dates';
import { SenderChannelOptions } from '../Common/CommunicationFunctions';
import { VoucherConfiguration } from '../Alerts/VoucherConfiguration';
import { hasVoucherAccess } from '../CommunicationJourney/CommonFunctions';

class CampaignStep1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            senders: null,
        };
    }

    selectSender = (sender) => {       
        const senderId = { target: { name: 'senderId', value: sender.sender_id } };
        const senderName = { target: { name: 'sender', value: sender.name ? sender.name : sender.email ? sender.email : sender.cellphone } };
        
        this.props.handleChange(senderId);
        this.props.handleChange(senderName);
    }

    handleButton = (field, data) => {
        const e = { target: { name: field, value: data } };

        this.props.handleChange(e);
    }

    handleSenderChannel = (field, data) => {
        const senderChannel = { target: { name: field, value: data } };
        this.props.handleChange(senderChannel);
        const senderId = { target: { name: 'senderId', value: null } };
        this.props.handleChange(senderId);
        const sender = { target: { name: 'sender', value: null } };
        this.props.handleChange(sender);
    }

    render() {
        const { marketingType, campaign, handleChange, handleStatusSelect, handleDateChange, handleMarketingType, filters, handleVoucherChange, handleVoucherEndDate, handleChangeOnInputFilter, alreadySentAndExists, collectMetricsForGoogleAnalytics, handleSwitch, hasSendGrid, invalidCampaignName, validateCampaignName, handleWeekDays } = this.props;

        const validWeekDays = [
            {
                value: "Sun",
                label: <FormattedMessage id="generic.Sun" />
            },
            {
                value: "Mon",
                label: <FormattedMessage id="generic.Mon" />
            },
            {
                value: "Tue",
                label: <FormattedMessage id="generic.Tue" />
            },
            {
                value: "Wed",
                label: <FormattedMessage id="generic.Wed" />
            },
            {
                value: "Thu",
                label: <FormattedMessage id="generic.Thu" />
            },
            {
                value: "Fri",
                label: <FormattedMessage id="generic.Fri" />
            },
            {
                value: "Sat",
                label: <FormattedMessage id="generic.Sat" />
            }
        ];

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <Row >
                    <Col>
                        <h5><FormattedMessage id="CampaignDetails.Name" /></h5>
                    </Col>
                </Row>
                <FormGroup row>
                    <Col>
                        <Input
                            type="text"
                            name="name"
                            onChange={(e) => handleChange(e)}
                            value={campaign.name ? campaign.name : ''}
                            maxLength='100'
                        />
                    </Col>
                </FormGroup>
                <Row>
                    <Col className="col-3">
                        <h5>
                            <FormattedMessage id="CampaignDetails.Status" />
                        </h5>
                    </Col>
                    <Col className="col-3">
                        <h5>
                            <FormattedMessage id="CampaignDetails.StartDate" />
                        </h5>
                    </Col>
                    <Col className="col-3">
                        <h5>
                            <FormattedMessage id="CampaignDetails.EndDate" />
                        </h5>
                    </Col>
                    <Col className="col-3">
                        <h5>
                            <FormattedMessage id="CampaignDetails.Weekdays" />
                        </h5>
                    </Col>
                </Row>
                <FormGroup row>
                    <Col className="col-3">
                        <ActiveInactiveStatusCombo name='active' value={campaign.active} onChangeFunc={handleStatusSelect} placeholder={<FormattedMessage id="generic.status" />} required={true} />
                    </Col>
                    <Col className="col-3">
                        <SingleDatePicker
                            id="startDateCampaignStep1"
                            isOutsideRange={day => day < moment()}
                            date={campaign.startDate ? moment(campaign.startDate) : null}
                            focused={this.state.startDateFocused}
                            onFocusChange={({ focused }) => this.setState({ startDateFocused: focused })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            onDateChange={date => handleDateChange('startDate', date)}
                            required
                        />
                    </Col>
                    <Col className="col-3">
                        <SingleDatePicker
                            id="endDateCampaignStep1"
                            isOutsideRange={day => day < moment()}
                            date={campaign.endDate ? moment(campaign.endDate) : null}
                            focused={this.state.endDateFocused}
                            onFocusChange={({ focused }) => this.setState({ endDateFocused: focused })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            onDateChange={date => handleDateChange('endDate', date)}
                        />
                    </Col>
                    <Col className="col-3">
                        <ButtonGroup name="weekdays">
                            {
                                validWeekDays.map((weekDay, key) =>
                                    <Button
                                        className={campaign.weekdays && campaign.weekdays.some(el => el === weekDay.value) ? 'btn btn-host-button-group' : 'btn'}
                                        onClick={() => handleWeekDays(weekDay.value)}
                                        key={key}>
                                        {weekDay.label}
                                    </Button>
                                )
                            }
                        </ButtonGroup>
                    </Col>
                </FormGroup>
                {
                    hasSendGrid ?
                        <>
                            <Row>
                                <Col className="col-3">
                                    <h5>
                                        <FormattedMessage id="CampaignDetails.CollectMetricsForGoogleAnalytics" />
                                    </h5>
                                </Col>
                                {
                                    collectMetricsForGoogleAnalytics ?
                                        <Col>
                                            <h5>
                                                <FormattedMessage id="CampaignDetails.CampaignName" />
                                            </h5>
                                        </Col>
                                        :
                                        ''
                                }
                            </Row>
                            <FormGroup row>
                                <Col className="col-3">
                                    <BinaryStatusCombo
                                        name='collectMetricsForGoogleAnalytics'
                                        value={collectMetricsForGoogleAnalytics}
                                        onChangeFunc={handleSwitch}
                                        placeholder={<FormattedMessage id="generic.status" />}
                                    />
                                </Col>
                                {
                                    collectMetricsForGoogleAnalytics ?
                                        <Col>
                                            <FormattedMessage id="CampaignDetails.CampaignName">
                                                {
                                                    placeholder =>
                                                        <Input
                                                            type="text" name="campaignName" value={campaign.campaignName ? campaign.campaignName : ''}
                                                            invalid={invalidCampaignName}
                                                            disabled={alreadySentAndExists}
                                                            onChange={(e) => handleChange(e)}
                                                            onBlur={(e) => validateCampaignName(e)}
                                                            maxLength="50"
                                                            placeholder={placeholder}
                                                        />
                                                }
                                            </FormattedMessage>
                                            <FormFeedback>
                                                <FormattedMessage id="CampaignDetails.InvalidCampaignName" />
                                            </FormFeedback>
                                        </Col>
                                        :
                                        ''
                                }
                                
                            </FormGroup>
                        </>
                        :
                        ''
                }
                <Row>
                    <Col>
                        <h5><FormattedMessage id="CampaignDetails.CampaignType" /></h5>
                    </Col>                   
                </Row>
                <FormGroup row>
                    <Col>
                        <Row>
                            {marketingType && marketingType.map((mt, key) =>
                                <Col className="col-2" key={key}>
                                    <Card className="border-0 shadow pointer" body
                                        style={campaign && campaign.marketingType && campaign.marketingType === mt.value ? { backgroundColor: '#d3eaff' } : {}}                                        
                                        onClick={() => handleMarketingType(mt.value)}
                                    >
                                        {mt.label}
                                    </Card>

                                </Col>
                            )}
                        </Row>
                    </Col>
                </FormGroup>

                <SenderChannelOptions
                    hasEgoi={this.props.hasEgoi}
                    hasSendGrid={this.props.hasSendGrid}
                    hasTwillio={this.props.hasTwillio}
                    selectedChannel={campaign?.senderChannel}
                    senderId={campaign?.senderId}
                    handleSenderChannel={(channel) => this.handleSenderChannel('senderChannel', channel)}
                    selectSender={this.selectSender}
                    displayCountriesToSend={false}
                />

                {
                    hasVoucherAccess() ?
                        <VoucherConfiguration
                            alert={filters}
                            handleChange={handleChangeOnInputFilter}
                            handleVoucherChange={handleVoucherChange}
                            handleVoucherEndDate={handleVoucherEndDate}
                        />
                        :
                        <div />
                }
            </BlockUi>
        );
    }
}
export default injectIntl(CampaignStep1)