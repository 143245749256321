import React, { Component } from 'react';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import { Badge, Button, ButtonGroup, Col, Row, PopoverBody, Input } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../Base/CustomSelect';
import { DateRangePicker } from 'react-dates';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { FilterButton, CommonHelper, CoolBadge } from '../Base/CommonUIComponents';
import SalesDealModal from './SalesDealModal/SalesDealModal';
import SalesActionsPopOver from './SalesActionsPopOver';
import CustomToolTip from '../Base/CustomToolTip';
import { handleNotification } from '../Base/Notification';
import { getAPI } from '../Base/API';
import { CustomFormatDate, SalesProcessHotelCombo, SalesProcessUsersCombo, SelectCustomType } from './SalesProcessFunctions';

class SalesLeads extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            modal: false,
            staffIds: [],
            negStartDate: null,
            negEndDate: null,
            salesSourceId: null,
            hotel: this.props.selectedHotel,
            pageSize: 25,
            pageIndex: 0,
            leadList: []
        };
    }

    componentDidMount(){
        const { fromAssociateModal } = this.props;

        if(fromAssociateModal){
            this.getLeadList();
        }else{
            this.getQueryStringData();
        }
    }

    buildQueryString = () => {
        const { staffIds, negStartDate, negEndDate, busStartDate, busEndDate, createdStartDate, createdEndDate, salesSourceId, dealName, hotel, pageSize, pageIndex, leadTags } = this.state;
        const queryParams = new URLSearchParams();
        queryParams.append('page', pageIndex + 1);
        queryParams.append('pageSize', pageSize);


        if (staffIds?.length > 0) {
            staffIds.forEach(s => queryParams.append('staffIds', s));
        }

        if (leadTags?.length > 0) {
            leadTags.forEach(s => queryParams.append('leadTags', s.id));
        }
    
        if (negStartDate && moment(negStartDate)) {
            queryParams.append('negStartDate', moment(negStartDate).format('YYYY-MM-DD'));
        }
    
        if (negEndDate && moment(negEndDate)) {
            queryParams.append('negEndDate', moment(negEndDate).format('YYYY-MM-DD'));
        }
    
        if (busStartDate && moment(busStartDate)) {
            queryParams.append('busStartDate', moment(busStartDate).format('YYYY-MM-DD'));
        }
    
        if (busEndDate && moment(busEndDate)) {
            queryParams.append('busEndDate', moment(busEndDate).format('YYYY-MM-DD'));
        }
    
        if (createdStartDate && moment(createdStartDate)) {
            queryParams.append('createdStartDate', moment(createdStartDate).format('YYYY-MM-DD'));
        }
    
        if (createdEndDate && moment(createdEndDate)) {
            queryParams.append('createdEndDate', moment(createdEndDate).format('YYYY-MM-DD'));
        }
    
        if (salesSourceId) {
            queryParams.append('sourceId', salesSourceId);
        }
    
        if (dealName) {
            queryParams.append('leadName', dealName);
        }
    
        if (hotel) {
            queryParams.append('hotels', hotel);
        }
    
        const queryString = queryParams.toString();
        const newUrl = `/SalesProcess${queryString ? `?${queryString}` : ''}#SalesLeads`;
    
        window.history.pushState(null, '', newUrl); 
        this.getLeadList();
    };

    getQueryStringData = () => {
        const urlParams = new URLSearchParams(window.location.search);

        const staffIds = urlParams.getAll('staffIds');
        const leadTags = urlParams.getAll('leadTags');
        const negStartDate = urlParams.get('negStartDate');
        const negEndDate = urlParams.get('negEndDate');
        const busStartDate = urlParams.get('busStartDate');
        const busEndDate = urlParams.get('busEndDate');
        const createdStartDate = urlParams.get('createdStartDate');
        const createdEndDate = urlParams.get('createdEndDate');
        const salesSourceId = urlParams.get('sourceId');
        const dealName = urlParams.get('leadName');
        const hotel = urlParams.get('hotels');

        const pageIndex = urlParams.get('page') ? urlParams.get('page') - 1 : this.state.pageIndex;
        const pageSize = urlParams.get('pageSize') || this.state.pageSize;
    
        this.setState({
            staffIds: staffIds ?? [],
            leadTags: leadTags ? leadTags.map(id => ({ id: parseInt(id, 10) })) : [],
            negStartDate: negStartDate ? moment(negStartDate) : null,
            negEndDate: negEndDate ? moment(negEndDate) : null,
            busStartDate: busStartDate ? moment(busStartDate) : null,
            busEndDate: busEndDate ? moment(busEndDate) : null,
            createdStartDate: createdStartDate ? moment(createdStartDate) : null,
            createdEndDate: createdEndDate ? moment(createdEndDate) : null,
            salesSourceId: salesSourceId ?? null,
            dealName: dealName ?? null,
            hotel: hotel ?? null,
            pageIndex,
            pageSize
        }, () => this.getLeadList());
    }

    getLeadList = () => {
        const { staffIds, negStartDate, negEndDate, salesSourceId, pageSize, pageIndex, hotel, createdStartDate, createdEndDate, dealName, busStartDate, busEndDate, leadTags } = this.state;
        let qs = `?pageIndex=${pageIndex}&pageSize=${pageSize}&leadOnly=true&`;

        if(staffIds && staffIds.length > 0)
            staffIds.forEach(s => {
                qs += `staffIds=${s}&`;
            });
        if (leadTags && leadTags.length > 0)
            leadTags.forEach(s => {
                qs += `dealTags=${s.id}&`;
            });
        if(negStartDate)
            qs += `negStartDate=${moment(negStartDate).format('YYYY-MM-DD')}&`;
        if(negEndDate)
            qs += `negEndDate=${moment(negEndDate).format('YYYY-MM-DD')}&`;
        if (busStartDate)
            qs += `busStartDate=${moment(busStartDate).format('YYYY-MM-DD')}&`;
        if (busEndDate)
            qs += `busEndDate=${moment(busEndDate).format('YYYY-MM-DD')}&`;
        if (createdStartDate)
            qs += `createdStartDate=${moment(createdStartDate).format('YYYY-MM-DD')}&`;
        if (createdEndDate)
            qs += `createdEndDate=${moment(createdEndDate).format('YYYY-MM-DD')}&`;
        if(salesSourceId)
            qs += `sourceId=${salesSourceId}&`;
        if (dealName)
            qs += `dealName=${dealName}&`;
        if (hotel)
            qs += `hotels=${hotel}&`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                
                this.setState({ block: false, leadList: data.data, totalLeads: data.total || [] });
                return;
            }
            this.setState({ block: false });
        }, `/api/gms/SalesProcess/v1/deals` + qs);
    }

    toggleModal = () => {
        this.setState(prevState => ({ modal: !prevState.modal }));
    }

    handleCombo = (combo, name) => {
        this.setState({ [name]: combo?.value });
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({ [name]: value })
    }
    
    handleNegDateChanges = ({ startDate, endDate }) => {
        this.setState({ negStartDate: startDate, negEndDate: endDate });
    }

    handleBusDateChanges = ({ startDate, endDate }) => {
        this.setState({ busStartDate: startDate, busEndDate: endDate });
    }

    handleCreatedDateChanges = ({ startDate, endDate }) => {
        this.setState({ createdStartDate: startDate, createdEndDate: endDate });
    }

    searchLeads = (e) => {
        const { fromAssociateModal } = this.props;
        if(e) e.preventDefault();
        
        this.setState({ block: true, pageIndex: 0 }, () =>{
            if(fromAssociateModal){
                this.getLeadList();
            }else{
                this.buildQueryString();
            }
        });
    }

    handleMultiCombo = (combo, name) => {
        this.setState({ [name]: combo ? combo?.map(cmb => cmb.value) : [] });
    }

    handlePageIndex = (p) => {
        const { pageIndex } = this.state;
        this.setState({ pageIndex: parseInt(pageIndex + p), block: true }, () => this.buildQueryString());
    }

    changePageSize = (evt) => {
        const { value } = evt.target;
        const { pageSize } = this.state;

        if (pageSize !== value) {
            this.setState({ block: true, pageSize: value }, () => this.buildQueryString());
        }
    }

    handleHotel = (e, combo) => {
        this.setState({ hotel: combo?.value });
    }

    handleStaff = (e, combo) => {
        this.setState({ staffIds: combo ? combo?.map(cmb => cmb.value) : [] });
    }

    countFilters = (e) => {
        if (e) e.preventDefault();
        const { busStartDate, busEndDate, staffIds, negStartDate, negEndDate, salesSourceId, hotel, createdStartDate, createdEndDate, dealName, leadTags } = this.state;
        let count = 0;

        if (busStartDate || busEndDate) count++;
        if (staffIds && staffIds.length > 0) count++;
        if (leadTags && leadTags.length > 0) count++;
        if (negStartDate || negEndDate) count++;
        if (createdStartDate || createdEndDate) count++;
        if (dealName) count++;
        if (salesSourceId) count++;
        if (hotel && hotel.length > 0) count++;

        return count;
    }

    handleLeadTagChange = (combo) => {
        this.setState({
            leadTags: combo ? combo.map(x => ({ id: x.value, label: x.label })) : []
        });
    };

    render() {
        const { block, error, modal, staffIds, negStartDate, negEndDate, salesSourceId, pageSize, pageIndex,
            hotel, leadList, createdStartDate, createdEndDate, dealName, busStartDate, busEndDate, totalLeads, leadTags } = this.state;
        const { icon, salesUsers, salesSources, intl, selectDealDetail, currentUser, fromAssociateModal, hotelList } = this.props;

        return (
            <div className="border-0 h-100 pb-2">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    {modal ?
                        <SalesDealModal
                            isLead={true}
                            modal={true}
                            salesUsers={salesUsers}
                            toggleModal={this.toggleModal}
                            editableSalesPipeline={true}
                            salesSources={salesSources}
                            getDeals={this.searchLeads}
                            currentUser={currentUser}
                            hotelList={hotelList}
                        />
                    :''}
                    <Row>
                        <Col>
                            <div className='mb-4'>
                                {fromAssociateModal ?
                                    <h6 className='m-0'>
                                        <i className={`${icon} mr-2`} />
                                        <FormattedMessage id="SalesProcess.SalesLeadList" />
                                    </h6>
                                    :
                                    <h4 className='m-0'>
                                        <i className={`${icon} mr-2`} />
                                        <FormattedMessage id="SalesProcess.SalesLeadList" />
                                    </h4>
                                }
                            </div>
                        </Col>
                        <Col >
                            <CommonHelper help={this.props.renderActivityHelperMsg(<FormattedMessage id="SalesLeads.Help" />)} id={'LeadsHelp'} />
                        </Col>
                        </Row>
                    <Row>
                        <Col>
                            <div className='title-sm'>
                                <i className={`icon-icon-hotel mr-2`} />
                                <FormattedMessage id="SalesProcess.Hotel" />
                            </div>
                            <div>
                                <SalesProcessHotelCombo
                                    isMulti={false}
                                    isClearable={true}
                                    name='hotelIds'
                                    placeholder={intl.formatMessage({ id: "SalesProcess.Hotel" })}
                                    value={hotel}
                                    onChangeFunc={this.handleHotel}
                                />
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div className='title-sm'>
                                <i className="fas fa-bullseye mr-2"/>
                                <FormattedMessage id="SalesProcess.Lead" />
                            </div>
                            <div>
                                <Input
                                    type="text"
                                    name="dealName"
                                    placeholder={this.props.intl.formatMessage({ id: "SalesProcess.Lead" })}
                                    onChange={(e) => this.handleChange(e)}
                                    value={dealName || ''}
                                    maxLength="500"
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className='title-sm'>
                                <i className={`fas fa-users mr-2`} />
                                <FormattedMessage id="SalesProcess.Staff" />
                            </div>
                            <div>
                                <SalesProcessUsersCombo
                                    isMulti={true}
                                    isClearable={true}
                                    name='staffIds'
                                    placeholder={intl.formatMessage({ id: "SalesProcess.Staff" })}
                                    value={staffIds}
                                    onChangeFunc={this.handleStaff}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className='title-sm'>
                                <i className="far fa-calendar-alt mr-2" />
                                <FormattedMessage id="SalesProcess.BusinessDates" />
                            </div>
                            <div>
                                <DateRangePicker
                                    startDate={busStartDate}
                                    startDateId="busStartDateId"
                                    endDate={busEndDate}
                                    endDateId="busEndDateId"
                                    isOutsideRange={_ => false}
                                    onDatesChange={this.handleBusDateChanges}
                                    focusedInput={this.state.focusedInput}
                                    showClearDates={true}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    small={true}
                                    showDefaultInputIcon={true}
                                    renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                                />
                            </div>
                        </Col>
                        <Col className="text-right mt-4 pt-1 pl-0" style={{ flex: ' 0 0 12%', maxWidth: '12%' }}>
                            <span className="mr-2 pr-1">
                                <FilterButton totalFilters={this.countFilters()} butId={"buttonSubmit"}>
                                    <div className="fullWidth">
                                        <div>
                                            <div className='title-sm mt-3'>
                                                <i className={`fas fa-code-branch mr-2`} />
                                                <FormattedMessage id="SalesProcess.SalesSources" />
                                            </div>
                                            <div>
                                                <CustomSelect
                                                    options={salesSources}
                                                    isClearable={true}
                                                    isMulti={false}
                                                    value={salesSources?.filter(x => x.value == salesSourceId) || ''}
                                                    isSearchable={true}
                                                    onChange={e => this.handleCombo(e, 'salesSourceId')}
                                                    placeholder={intl.formatMessage({ id: "SalesProcess.SalesSources" })}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className='title-sm mt-3'>
                                                <i className="far fa-calendar-alt mr-2" />
                                                <FormattedMessage id="SalesProcess.NegotiationDates" />
                                            </div>
                                            <div>
                                                <DateRangePicker
                                                    startDate={negStartDate}
                                                    startDateId="negStartDateId"
                                                    endDate={negEndDate}
                                                    endDateId="negEndDateId"
                                                    isOutsideRange={_ => false}
                                                    onDatesChange={this.handleNegDateChanges}
                                                    focusedInput={this.state.focusedInput2}
                                                    showClearDates={true}
                                                    onFocusChange={focusedInput2 => this.setState({ focusedInput2: focusedInput2 })}
                                                    small={true}
                                                    showDefaultInputIcon={true}
                                                    renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className='title-sm mt-3'>
                                                <i className="far fa-calendar-alt mr-2" />
                                                <FormattedMessage id="SalesProcess.CreatedAtDates" />
                                            </div>
                                            <div>
                                                <DateRangePicker
                                                    startDate={createdStartDate}
                                                    startDateId="createdStartDateId"
                                                    endDate={createdEndDate}
                                                    endDateId="createdEndDateId"
                                                    isOutsideRange={_ => false}
                                                    onDatesChange={this.handleCreatedDateChanges}
                                                    focusedInput={this.state.focusedInput3}
                                                    showClearDates={true}
                                                    onFocusChange={focusedInput3 => this.setState({ focusedInput3 })}
                                                    small={true}
                                                    showDefaultInputIcon={true}
                                                    renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className='title-sm mt-3'>
                                                <i className="fas fa-tags mr-2"/>
                                                <FormattedMessage id="SalesProcess.DealTag" />
                                            </div>
                                            <div>
                                                <SelectCustomType
                                                    name="dealTags"
                                                    isSearchable={true}
                                                    placeholder={intl.formatMessage({ id: "SalesProcess.DealTag" })}
                                                    isDisabled={false}
                                                    isClearable={true}
                                                    isMulti={true}
                                                    type={'DealTag'}
                                                    onChangeFunc={(combo) => this.handleLeadTagChange(combo)}
                                                    value={leadTags || []}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </FilterButton>
                            </span>
                            {!fromAssociateModal ?
                                <Button className="btn-sm btn-host ml-2" onClick={this.toggleModal}>
                                    <i className="fas fa-plus" />
                                </Button>
                                : ''}
                            <Button className="btn-sm btn-host ml-2" onClick={this.searchLeads} id="buttonSubmit">
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>
                    {leadList ?
                        <div className="w-100 mt-4 chunkyVeryCoolScrollBar p-2" style={{ overflowX: 'auto', whiteSpace: 'nowrap', transform: 'rotateX(180deg)' }} >
                            <div className='mt-4' style={{ width: '110vw', transform: 'rotateX(180deg)', fontSize: '0.9em' }}>
                                <div className='d-flex align-items-center justify-content-between shadow p-3 mb-3 bg-white text-muted' style={{ borderRadius: '4px', gap: '1%' }} >
                                    <div style={{ width: '15%' }}>
                                        <FormattedMessage id={`SalesProcess.Lead`} />
                                    </div>
                                    <div style={{ width: '9%' }}>
                                        <FormattedMessage id={`SalesProcess.SalesSource`} />
                                    </div>
                                    <div style={{ width: '9%' }}>
                                        <FormattedMessage id={`SalesProcess.Staff`} />
                                    </div>
                                    <div style={{ width: '10%' }}>
                                        <FormattedMessage id={`SalesProcess.RelatedHotels`} />
                                    </div>
                                    <div style={{ width: '12%' }}>
                                        <FormattedMessage id={`SalesProcess.NegotiationDates`} />
                                    </div>
                                    <div style={{ width: '12%' }}>
                                        <FormattedMessage id={`SalesProcess.BusinessDates`} />
                                    </div>
                                    <div style={{ width: '3%' }}>
                                        <FormattedMessage id={`SalesProcess.ActionsStatus`} />
                                    </div>
                                    <div style={{ width: '6%' }}>
                                        <FormattedMessage id={`SalesProcess.LeadStatus`} />
                                    </div>
                                    <div style={{ width: '6%' }}>
                                        <FormattedMessage id={`SalesProcess.DealTag`} />
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    {leadList.map((lead, key) =>
                                        <div onMouseDown={(e) => {
                                            if (e?.button !== 2) {
                                                const isScrollClick = e?.button === 1;

                                                if (fromAssociateModal) {
                                                    if (!isScrollClick) {
                                                        selectDealDetail(lead);
                                                    }
                                                } else {
                                                    selectDealDetail(lead.id, "SalesLeads", isScrollClick);
                                                }
                                            }
                                        }} className='d-flex align-items-center justify-content-between shadow p-3 mb-2 bg-white cursor-pointer' key={key} style={{ borderRadius: '4px', gap: '1%' }}>
                                            <div style={{ width: '15%' }}>
                                                <div className='text-truncate'>
                                                    <b id={`leadName-${key}`}>{lead.name}</b>
                                                    <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="right" target={`leadName-${key}`}>
                                                        <PopoverBody>
                                                            <div>
                                                                <b>{lead.name}</b>
                                                            </div>
                                                            <div dangerouslySetInnerHTML={{ __html: lead.summary }} />
                                                        </PopoverBody>
                                                    </CustomToolTip>
                                                </div>
                                                {lead.customerId ?
                                                    <div style={{ fontSize: '0.8em' }} className='text-muted hover-underline text-truncate'>
                                                        <Link to={{ pathname: "/ProfileDetails/" + lead.customer.id }}>
                                                            {lead.customer.name}
                                                        </Link>
                                                    </div>
                                                    : ''}
                                            </div>
                                            <div style={{ width: '9%' }}>
                                                <div className='text-truncate' >
                                                    <span>
                                                        {lead.salesSourceName}
                                                    </span>
                                                </div>
                                            </div>
                                            <div style={{ width: '9%' }}>
                                                {lead.salesDealUser ?
                                                    <div style={{ maxHeight: '22px' }} onMouseDown={(e) => lead.salesDealUser.length > 1 ? e.stopPropagation() : undefined} className='overflow-auto veryCoolScrollBar'>
                                                        {lead.salesDealUser.map((user, k) => {
                                                            const u = salesUsers?.find(({ value }) => value === user.userId);
                                                            return (
                                                                u ?
                                                                    <div key={k}>
                                                                        <div className={k ? 'mt-1 text-truncate' : 'text-truncate'}
                                                                            onMouseDown={(e) => {
                                                                                if (e?.button !== 2) {
                                                                                    const isScrollClick = e?.button === 1;

                                                                                    if (fromAssociateModal) {
                                                                                        if (!isScrollClick) {
                                                                                            selectDealDetail(lead);
                                                                                        }
                                                                                    } else {
                                                                                        selectDealDetail(lead.id, "SalesLeads", isScrollClick);
                                                                                    }
                                                                                }
                                                                            }}>
                                                                            {`${u.firstName} ${u.lastName}`}
                                                                        </div>
                                                                    </div>
                                                                    : ''
                                                            )
                                                        }
                                                        )}
                                                    </div>
                                                    : '-'}
                                            </div>
                                            <div style={{ width: '10%' }}>
                                                {lead.hotelIds ?
                                                    <div style={{ maxHeight: '22px' }} onMouseDown={(e) => lead.hotelIds.length > 1 ? e.stopPropagation() : undefined} className='overflow-auto veryCoolScrollBar'>
                                                        {lead.hotelIds.map((id, key) => {
                                                            const hotel = global?.hotelList?.find(x => x.value === id);
                                                            return (

                                                                <div key={key} className={key ? 'mt-1 text-truncate' : 'text-truncate'}
                                                                    onMouseDown={(e) => {
                                                                        if (e?.button !== 2) {
                                                                            const isScrollClick = e?.button === 1;

                                                                            if (fromAssociateModal) {
                                                                                if (!isScrollClick) {
                                                                                    selectDealDetail(lead);
                                                                                }
                                                                            } else {
                                                                                selectDealDetail(lead.id, "SalesLeads", isScrollClick);
                                                                            }
                                                                        }
                                                                    }}>
                                                                    {hotel?.label}
                                                                </div>
                                                            )
                                                        }
                                                        )}
                                                    </div>
                                                    : '-'}
                                            </div>
                                            <div style={{ width: '12%' }}>
                                                <div className='text-truncate' title={`${CustomFormatDate(lead.negotiationStartDate, null, null, intl)}${lead.negotiationEndDate ? ` - ${CustomFormatDate(lead.negotiationEndDate, null, null, intl)}` : ''}`}>
                                                    {lead.negotiationStartDate ?
                                                        <span>
                                                            {CustomFormatDate(lead.negotiationStartDate, null, null, intl)}
                                                        </span>
                                                        : '-'}

                                                    {lead.negotiationEndDate ?
                                                        <>
                                                            <span className='mx-1'>-</span>
                                                            <span>
                                                                {CustomFormatDate(lead.negotiationEndDate, null, null, intl)}
                                                            </span>
                                                        </>
                                                        : ''}
                                                </div>
                                            </div>
                                            <div style={{ width: '12%' }}>
                                                <div className='text-truncate' title={`${CustomFormatDate(lead.businessStartDate, null, null, intl)}${lead.businessEndDate ? ` - ${CustomFormatDate(lead.businessEndDate, null, null, intl)}` : ''}`}>
                                                    {lead.businessStartDate ?
                                                        <span>
                                                            {CustomFormatDate(lead.businessStartDate, null, null, intl)}
                                                        </span>
                                                        : '-'}

                                                    {lead.businessEndDate ?
                                                        <>
                                                            <span className='mx-1'>-</span>
                                                            <span>
                                                                {CustomFormatDate(lead.businessEndDate, null, null, intl)}
                                                            </span>
                                                        </>
                                                        : ''}
                                                </div>
                                            </div>
                                            <div style={{ width: '3%' }}>
                                                {lead.status !== "Finished" && lead.status !== "Cancelled" ?
                                                    <SalesActionsPopOver
                                                        id={`SalesAction-${key}`}
                                                        salesUsers={salesUsers}
                                                        placement={'left'}
                                                        deal={lead}
                                                        getDeals={this.searchLeads}
                                                        small={true}
                                                    />
                                                    : ''}
                                            </div>
                                            <div style={{ width: '6%' }}>
                                                <Badge id={`dealStatus-${key}`} style={{ fontSize: '0.7em' }} color={(lead.status === "WaitingHotel" || lead.status === "WaitingClient") ? "primary" : lead.status === "Finished" ? "success" : "danger"} className="py-1 px-2">
                                                    {(lead.status === "WaitingHotel" || lead.status === "WaitingClient") ?
                                                        <FormattedMessage id={`SalesProcess.InNegotiation`} />
                                                        : lead.status === "Finished" ?
                                                            <FormattedMessage id={`SalesProcess.Finished`} />
                                                            :
                                                            <FormattedMessage id={`SalesProcess.Cancelled`} />
                                                    }
                                                </Badge>
                                            </div>
                                            <div style={{ width: '6%' }}>
                                                {lead.dealTags ?
                                                    <div style={{ maxHeight: '35px' }} onMouseDown={(e) => lead.dealTags.length > 1 ? e.stopPropagation() : undefined} className='overflow-auto veryCoolScrollBar'>
                                                        {lead.dealTags.map((tag, key) => {
                                                            return (
                                                                <div key={key} className={key ? 'mt-1 text-truncate' : 'text-truncate'}
                                                                    onMouseDown={(e) => {
                                                                        if (e?.button !== 2) {
                                                                            const isScrollClick = e?.button === 1;

                                                                            if (fromAssociateModal) {
                                                                                if (!isScrollClick) {
                                                                                    selectDealDetail(lead, null);
                                                                                }
                                                                            } else {
                                                                                selectDealDetail(lead.id, "SalesLeads", isScrollClick);
                                                                            }
                                                                        }
                                                                    }}>
                                                                    <CoolBadge
                                                                        key={key}
                                                                        text={tag.name}
                                                                        type="tags"
                                                                        isSelected={false}
                                                                        data={tag}
                                                                        hasInput={false}
                                                                        onClick={(e)=> e.stopPropagation() }
                                                                    />
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    : '-'}
                                            </div>
                                        </div>
                                    )}
                                    <Row className="mt-3">
                                        <Col>
                                            <ButtonGroup onClick={this.changePageSize}>
                                                <Button className={(pageSize == 10 ? "bg-host text-white" : "text-secondary") + " page-link"} value={10}> 10 </Button>
                                                <Button className={(pageSize == 25 ? "bg-host text-white" : "text-secondary") + " page-link"} value={25}> 25 </Button>
                                                <Button className={(pageSize == 30 ? "bg-host text-white" : "text-secondary") + " page-link"} value={30}> 30 </Button>
                                                <Button className={(pageSize == 50 ? "bg-host text-white" : "text-secondary") + " page-link"} value={50}> 50 </Button>
                                            </ButtonGroup>
                                            <span className="text-muted ml-2" style={{ fontSize: '0.8em' }}>
                                                <FormattedMessage id="generic.totalValuesMessage" values={{
                                                    0: parseInt(pageIndex * pageSize + 1),
                                                    1: totalLeads < parseInt((pageIndex + 1) * pageSize) ? totalLeads : parseInt((pageIndex + 1) * pageSize),
                                                    2: totalLeads
                                                }} />
                                            </span>
                                        </Col>

                                        <Col className="text-right">
                                            <span>
                                                <Button className="btn btn-host btn-sm mr-2" onClick={_ => this.handlePageIndex(-1)} disabled={pageIndex === 0}>
                                                    <i className="fas fa-angle-left" />
                                                </Button>
                                                <span className="mr-2">
                                                    <FormattedMessage id="SearchProfile.Page" values={{ page: parseInt(pageIndex + 1) }} />
                                                </span>
                                                <Button className="btn btn-host btn-sm" onClick={_ => this.handlePageIndex(1)} disabled={totalLeads <= pageSize * (pageIndex + 1)}>
                                                    <i className="fas fa-angle-right" />
                                                </Button>
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    :''}
                </BlockUi>
            </div>
        )
    }
}

export default injectIntl(SalesLeads);