

import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getAPI } from '../Base/API';
import { StyledCard } from "../Base/CommonUIComponents";
import { handleNotification } from '../Base/Notification';
import { Button, Col, Row } from 'reactstrap';
import CustomSelect from "../Base/CustomSelect";

export class GoContactReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: true,
            reportList: null,
            topic: ['owners', 'templates'],
            options: [
                { value: 'getqueue', label: <FormattedMessage id="GoContactReport.getqueue" /> },
                { value: 'getticket', label: <FormattedMessage id="GoContactReport.getticket" /> },
                { value: 'getassisted_transfer', label: <FormattedMessage id="GoContactReport.getassisted_transfer" /> },
                { value: 'getivr_campaigns', label: <FormattedMessage id="GoContactReport.getivr_campaigns" /> },
                //{ value: 'getcallbacks', label: <FormattedMessage id="GoContactReport.getcallbacks" /> }, tem mais 1 nivel
                { value: 'getagents', label: <FormattedMessage id="GoContactReport.getagents" /> },
                { value: 'getwebchat', label: <FormattedMessage id="GoContactReport.getwebchat" /> },
                { value: 'getwebchat_sessions', label: <FormattedMessage id="GoContactReport.getwebchat_sessions" /> },
                { value: 'getticket_agent_times', label: <FormattedMessage id="GoContactReport.getticket_agent_times" /> },
                { value: 'getscripts', label: <FormattedMessage id="GoContactReport.getscripts" /> },
                { value: 'geton_hook_attempt', label: <FormattedMessage id="GoContactReport.geton_hook_attempt" /> },
                { value: 'getquality', label: <FormattedMessage id="GoContactReport.getquality" /> },
                { value: 'getcampaign', label: <FormattedMessage id="GoContactReport.getcampaign" /> }

            ],
            action: 'getqueue'
        };
    }

    componentDidMount() {
        this.getTemplates()
    }

    getTemplates = () => {

        this.state.block = true;
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.data) {
                    this.setState({ reportList: data.data[0], block: false });
                }

            }
            else this.setState({ error: errorMessage, block: false });
        //}, `/api/gms/GoContact/v1/DownloadReport?file=14-01-2025_Report-67863c648a3140.89403962`);
        //}, `/api/gms/GoContact/v1/BuildReport?templateId=493&ownerType=ticket&startDate=2025-01-01&endDate=2025-01-09&dataType=1`);
        //}, `/api/gms/GoContact/v1/ImportTickets?templateId=493&ownerType=ticket&startDate=2024-01-01&endDate=2024-12-31&updateTickets=false`);
        }, `/api/gms/GoContact/v1/ReportTemplates?action=${this.state.action}`);
    }
    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    render() {
        const { block, error, reportList, topic, options, action } = this.state;

        return (
            <StyledCard block={block} error={error} icon="far fa-bookmark" title="Queue">

                <Row className="mt-3 mb-4 align-items-center">
                    <Col className="col-4 pr-0">
                        <CustomSelect
                            options={options}
                            value={options.find(m => m.value === action)}
                            placeholder={''}
                            onChange={this.handleSelect.bind(this, 'action')}
                            required={true}
                        />
                    </Col>
                    <Col>
                    </Col>
                    <Col className="text-right" >
                        <Button className="mr-3 btn btn-sm btn-host" onClick={this.getTemplates} >
                            <i className="fa fa-search" />
                        </Button>
                    </Col>
                </Row>
                {topic.map((e, id) =>
                    <div>
                        <h4 className="my-2">{e}</h4>
                        {reportList && reportList[e].map((el, x) =>
                            <Row>
                                <Col className="col-4">{el.id}</Col>
                            <Col>{el.name}</Col>
                    </Row>
                        )}
                    </div>
                    //<div>
                    //    <h4 className="my-2">{e}</h4>
                    //    {reportList && reportList[e] && reportList[e].map((el, x) =>
                    //        <Row>
                    //            <Col className="col-4">{el.id}</Col>
                    //            <Col>{el.name}</Col>
                    //        </Row>
                    //    )}
                    //</div>
                )}
            </StyledCard>
        );
    }
}
export default injectIntl(GoContactReport)