import React, { Component } from 'react';
import { FormatAmountNumber, ActiveDeletedStatusBadge, CoolTooltip } from '../Base/CommonUIComponents';
import { Button, ButtonGroup, Col, Row, Collapse } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from '../Base/Notification';
import { getAPI } from '../Base/API';
import { ErrorAlert } from '../Base/ErrorAlert';
import { CommonHelper } from '../Base/CommonUIComponents';
import { CustomFormatDate } from './SalesProcessFunctions';
import BlockUi from 'react-block-ui';
import moment from 'moment-timezone';
import SalesDealDetailsProposalDetailsModal from './SalesDealDetail/SalesDealDetailsProposalDetailsModal';

class ProposalHistoryModal extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            pageSize: 10,
            pageIndex: 0,
            proposalHistoryList: [],
            collapseList: [],
            selectedProposalVersion: null,
            proposalDetailsModal: false,
            proposalCurrency: null
        };
    }

    componentDidMount() {
        window.setTimeout(() => {
            const { deal } = this.props;
            const dealHotel = deal?.hotelIds?.[0];
    
            const hotelCurrency = dealHotel ? global.hotelList?.find(x => x.value === dealHotel)?.currency : null;
    
            if (hotelCurrency) {
                this.setState({ proposalCurrency: hotelCurrency }, this.getproposalHistoryList);
            } else {
                this.getproposalHistoryList();
            }
        }, global.hotelList ? 0 : 2500);
    }

    getproposalHistoryList = () => {
        const { salesDealId } = this.props;
        const { pageSize, pageIndex } = this.state;

        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
    
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors?.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
    
                    if (data.data?.length > 0) {
                        const proposalHistoryList = data.data.map(x => ({
                            ...x,
                            lastVersion: {
                                ...x.lastVersion,
                                proposalJSON: x.lastVersion?.proposalJSON ? JSON.parse(x.lastVersion.proposalJSON) : null
                            },
                            allVersions: Array.isArray(x.allVersions) ? 
                                x.allVersions.map(y => ({
                                    ...y,
                                    proposalJSON: y.proposalJSON ? JSON.parse(y.proposalJSON) : null
                                }))
                            : 
                                []
                        }));
                    
                        this.setState({ proposalHistoryList, block: false });
                        return;
                    }
    
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                else{
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
            }, `/api/gms/SalesProcess/v1/ProposalHistory/${salesDealId}?pageSize=${pageSize}&pageIndex=${pageIndex}`);
        });
    }

    changePageSize = (evt) => {
        const { value } = evt.target;
        const { pageSize } = this.state;

        if (pageSize !== value) {
            this.setState({ block: true, pageSize: value }, () => this.getproposalHistoryList());
        }
    }

    handlePageIndex = (p) => {
        const { pageIndex } = this.state;
        this.setState({ pageIndex: parseInt(pageIndex + p), block: true }, () => this.getproposalHistoryList());
    }

    toggleCollapse = (key) => {
        this.setState(prevState => ({
            collapseList: {
                ...prevState.collapseList,
                [key]: !prevState.collapseList[key] 
            }
        }));
    }

    toggleProposalDetailsModal = (selectedProposalVersion) => {
        this.setState(prevstate => ({ proposalDetailsModal: !prevstate.proposalDetailsModal, selectedProposalVersion }));
    }

    render() {
        const { block, error, pageSize, pageIndex, proposalHistoryList, collapseList, proposalDetailsModal, selectedProposalVersion, proposalCurrency } = this.state;
        const { toggleActiveSupraTab, deal, intl } = this.props;
        
        return (
            <div className='h-100'>
                <ErrorAlert error={error}/>
                <BlockUi tag="div" blocking={block}>
                    <Row>
                        <Col className='d-flex aling-items-center'>
                            <div className='p-2 mr-2 cursor-pointer'>
                                <i className="fas fa-chevron-left" onClick={_ => toggleActiveSupraTab("Calendar")} />
                            </div>
                            <h5 className='d-flex align-items-center justify-content-start m-0'>
                                <FormattedMessage id="SalesDeal.ProposalHistory" />
                            </h5>
                        </Col>
                        <Col>
                            <CommonHelper help={<FormattedMessage id="SalesProposalHistory.Help" />} id={'SalesProcessProposalHistory'} />
                        </Col>
                    </Row>
                    {proposalHistoryList?.length > 0 ?
                        <Row className='mt-4'>
                            <Col className='col-12 veryCoolScrollBar py-3' style={{ maxHeight: '65vh', overflow: 'auto' }}>
                                {proposalHistoryList.map((res, key) => {
                                    const proposalType = isNaN(res.lastVersion.type) ? '-' : res.lastVersion.type === 0 ? <FormattedMessage id="SalesProcess.Block" /> : res.lastVersion.type === 1 ? <FormattedMessage id="SalesProcess.Event" /> : <FormattedMessage id="SalesProcess.GroupReservation" />;
                                    return(
                                        <Col className='shadow mb-2 bg-white col-12' style={{ borderRadius: '4px', gap: '1%' }} key={key}>
                                            {res.lastVersion ?
                                                <Row className="p-2 tableElementHover">
                                                    <Col className='col-12 d-flex align-items-center'>
                                                        <div style={{ width: '2%', fontSize: '0.8rem' }}>
                                                            <i id={`blockOrEventProposal-${key}`} className={`fas fa-${res.lastVersion.type === 0 ? 'cube' : 'theater-masks'} mr-2`}></i>
                                                            <CoolTooltip placement="top" target={`blockOrEventProposal-${key}`}>
                                                                {proposalType}
                                                            </CoolTooltip>
                                                        </div>
                                                        <div className='d-flex align-items-center' style={{ width: '96%' }}>
                                                            <div style={{ width: '4%' }}> {/* Version */}
                                                                <div className='title-sm text-truncate'>
                                                                    <FormattedMessage id={`SalesDeal.Version`} />
                                                                </div>
                                                                <div title={res.lastVersion.version} className='text-truncate mt-2'>
                                                                    {res.lastVersion.version}
                                                                </div>
                                                            </div>
                                                            <div style={{ width: '4%' }}> {/* Status */}
                                                                <div className='title-sm text-truncate'>
                                                                    <FormattedMessage id={`SalesDeal.Status`} />
                                                                </div>
                                                                <div className='text-truncate mt-2'>
                                                                    <ActiveDeletedStatusBadge status={!res.lastVersion.deleted} />
                                                                </div>
                                                            </div>
                                                            <div className='pr-2' style={{ width: '6%' }}> {/* Block / Res Name */}
                                                                <div className='title-sm text-truncate'>
                                                                    <FormattedMessage id={`SalesDeal.Name`} />
                                                                </div>
                                                                <div title={res.lastVersion.Type} className='text-truncate mt-2'>
                                                                    {(res.lastVersion.type === 0 ? res.lastVersion.proposalJSON?.BlockData?.headerData?.[0]?.Code : res.lastVersion.proposalJSON?.data?.[0]?.Name) || '-'}
                                                                </div>
                                                            </div>
                                                            <div className='pr-2' style={{ width: '6%' }}> {/* ResNumber */}
                                                                <div className='title-sm text-truncate'>
                                                                    <FormattedMessage id={`SalesDeal.ResNumber`} />
                                                                </div>
                                                                <div title={res.lastVersion.externalId} className='text-truncate mt-2'>
                                                                    {res.lastVersion.externalId}
                                                                </div>
                                                            </div>
                                                            <div className='pr-2' style={{ width: '8%' }}> {/* UpdatedAt */}
                                                                <div className='title-sm text-truncate'>
                                                                    <FormattedMessage id={`SalesDeal.UpdatedAt`} />
                                                                </div>
                                                                <div title={CustomFormatDate(res.lastVersion.updatedAt, null, 'hh:mm', intl)} className='text-truncate mt-2'>
                                                                    {CustomFormatDate(res.lastVersion.updatedAt, null, 'hh:mm', intl)}
                                                                </div>
                                                            </div>
                                                            <div className='pr-2' style={{ width: '8%' }}> {/* StartDate */}
                                                                <div className='title-sm text-truncate'>
                                                                    <FormattedMessage id={`SalesDeal.CheckIn`} />
                                                                </div>
                                                                <div title={CustomFormatDate(res.lastVersion.startDate, null, 'hh:mm', intl)} className='text-truncate mt-2'>
                                                                    {CustomFormatDate(res.lastVersion.startDate, null, 'hh:mm', intl)}
                                                                </div>
                                                            </div>
                                                            <div className='pr-2' style={{ width: '8%' }}> {/* EndDate */}
                                                                <div className='title-sm text-truncate'>
                                                                    <FormattedMessage id={`SalesDeal.CheckOut`} />
                                                                </div>
                                                                <div title={CustomFormatDate(res.lastVersion.endDate, null, 'hh:mm', intl)} className='text-truncate mt-2'>
                                                                    {CustomFormatDate(res.lastVersion.endDate, null, 'hh:mm', intl)}
                                                                </div>
                                                            </div>
                                                            <div style={{ width: '8%' }}> {/* RoomsValue */}
                                                                <div className='title-sm text-truncate'>
                                                                    <FormattedMessage id={`SalesDeal.RoomsValue`} />
                                                                </div>
                                                                <div title={res.lastVersion.numberOfRooms} className='text-truncate mt-2'>
                                                                    <FormatAmountNumber currency={proposalCurrency} value={res.lastVersion.roomsValue ?? 0} />
                                                                </div>
                                                            </div>
                                                            <div style={{ width: '8%' }}> {/* EventsValue */}
                                                                <div className='title-sm text-truncate'>
                                                                    <FormattedMessage id={`SalesDeal.EventsValue`} />
                                                                </div>
                                                                <div title={res.lastVersion.numberOfEvents} className='text-truncate mt-2'>
                                                                    <FormatAmountNumber currency={proposalCurrency} value={res.lastVersion.eventsValue ?? 0} />
                                                                </div>
                                                            </div>
                                                            <div style={{ width: '8%' }}> {/* SpacesValue */}
                                                                <div className='title-sm text-truncate'>
                                                                    <FormattedMessage id={`SalesDeal.SpacesValue`} />
                                                                </div>
                                                                <div title={res.lastVersion.numberOfEquipment} className='text-truncate mt-2'>
                                                                    <FormatAmountNumber currency={proposalCurrency} value={res.lastVersion.spacesValue ?? 0} />
                                                                </div>
                                                            </div>
                                                            <div className='pr-2' style={{ width: '9%' }}> {/* EquipmentValue */}
                                                                <div className='title-sm text-truncate'>
                                                                    <FormattedMessage id={`SalesDeal.EquipmentValue`} />
                                                                </div>
                                                                <div title={res.lastVersion.numberOfEquipment} className='text-truncate mt-2'>
                                                                    <FormatAmountNumber currency={proposalCurrency} value={res.lastVersion.equipmentValue ?? 0} />
                                                                </div>
                                                            </div>
                                                            <div style={{ width: '7%' }}> {/* FbValue */}
                                                                <div className='title-sm text-truncate'>
                                                                    <FormattedMessage id={`SalesDeal.F&BValue`} />
                                                                </div>
                                                                <div className='text-truncate mt-2'>
                                                                    <FormatAmountNumber currency={proposalCurrency} value={res.lastVersion.fbValue ?? 0} />
                                                                </div>
                                                            </div>
                                                            <div style={{ width: '7%' }}> {/* BanquetsValue */}
                                                                <div className='title-sm text-truncate'>
                                                                    <FormattedMessage id={`SalesDeal.BanquetsValue`} />
                                                                </div>
                                                                <div className='text-truncate mt-2'>
                                                                    <FormatAmountNumber currency={proposalCurrency} value={res.lastVersion.banquetsValue ?? 0} />
                                                                </div>
                                                            </div>
                                                            <div style={{ width: '8%' }}> {/* TotalValue */}
                                                                <div className='title-sm text-truncate'>
                                                                    <FormattedMessage id={`SalesDeal.TotalValue`} />
                                                                </div>
                                                                <div title={res.lastVersion.totalValue} className='text-truncate mt-2'>
                                                                    <FormatAmountNumber currency={proposalCurrency} value={res.lastVersion.totalValue ?? 0} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex align-items-center justify-content-end' style={{ fontSize: '0.8rem', width: '2%' }}>
                                                            <i id={`toggleDetailLastVersion-${key}-${res.lastVersion.id}`} className="far fa-eye cursor-pointer" onClick={() => this.toggleProposalDetailsModal(res.lastVersion)} style={{ marginRight: '10px' }} />
                                                            <i className={`fas fa-chevron-up cursor-pointer rotate-icon ${collapseList[key] ? `rotate-up` : `rotate-down`}`} onClick={() => this.toggleCollapse(key)} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                : ''
                                            }
                                            <Collapse isOpen={collapseList[key] ?? false}>
                                                {res.allVersions && res.allVersions.length > 0 && res.allVersions.map((x, idx) =>
                                                    <Row key={idx} className='p-2 tableElementHover' style={{ borderTop: '1px solid lightgrey', }}>
                                                        <Col className='col-12 d-flex align-items-center'>
                                                            <div style={{ width: '2%' }} />
                                                            <div className='d-flex align-items-center' style={{ width: '96%' }}>
                                                                <div style={{ width: '4%' }}> {/* Version */}
                                                                    <div className='text-truncate'>
                                                                        {x.version}
                                                                    </div>
                                                                </div>
                                                                <div style={{ width: '4%' }}> {/* Status */}
                                                                </div>
                                                                <div className='pr-2' style={{ width: '6%' }}> {/* Block / Res Name */}
                                                                </div>
                                                                <div className='pr-2' style={{ width: '6%' }}> {/* ResNumber */}
                                                                </div>
                                                                <div className='pr-2' style={{ width: '8%' }}> {/* UpdatedAt */}
                                                                    <div className='text-truncate'>
                                                                        {CustomFormatDate(x.updatedAt, null, 'hh:mm', intl)}
                                                                    </div>
                                                                </div>
                                                                <div className='pr-2' style={{ width: '8%' }}> {/* startDate */}
                                                                    <div className='text-truncate'>
                                                                        {CustomFormatDate(x.startDate, null, 'hh:mm', intl)}
                                                                    </div>
                                                                </div>
                                                                <div className='pr-2' style={{ width: '8%' }}> {/* EndDate */}
                                                                    <div className='text-truncate'>
                                                                        {CustomFormatDate(x.endDate, null, 'hh:mm', intl)}
                                                                    </div>
                                                                </div>
                                                                <div style={{ width: '8%' }}> {/* RoomsValue */}
                                                                    <div className='text-truncate'>
                                                                        <FormatAmountNumber currency={proposalCurrency} value={x.roomsValue ?? 0} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ width: '8%' }}> {/* EventsValue */}
                                                                    <div className='text-truncate'>
                                                                        <FormatAmountNumber currency={proposalCurrency} value={x.eventsValue ?? 0} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ width: '8%' }}> {/* SpacesValue */}
                                                                    <div className='text-truncate'>
                                                                        <FormatAmountNumber currency={proposalCurrency} value={x.spacesValue ?? 0} />
                                                                    </div>
                                                                </div>
                                                                <div className='pr-2' style={{ width: '9%' }}> {/* EquipmentValue */}
                                                                    <div className='text-truncate'>
                                                                        <FormatAmountNumber currency={proposalCurrency} value={x.equipmentValue ?? 0} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ width: '7%' }}> {/* FbValue */}
                                                                    <div className='text-truncate mt-2'>
                                                                        <FormatAmountNumber currency={proposalCurrency} value={x.fbValue ?? 0} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ width: '7%' }}> {/* BanquetsValue */}
                                                                    <div className='text-truncate mt-2'>
                                                                        <FormatAmountNumber currency={proposalCurrency} value={x.banquetsValue ?? 0} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ width: '8%' }}> {/* TotalValue */}
                                                                    <div className='text-truncate'>
                                                                        <FormatAmountNumber currency={proposalCurrency} value={x.totalValue ?? 0} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='text-right' style={{ fontSize: '0.8rem', width: '2%' }}>
                                                                <i id={`toggleDetailOtherVersions-${key}-${idx}`} className='far fa-eye cursor-pointer' onClick={() => this.toggleProposalDetailsModal(x)} />
                                                                <CoolTooltip placement="top" target={`toggleDetailOtherVersions-${key}-${idx}`}>
                                                                    <FormattedMessage id="SalesDeal.MoreDetails" />
                                                                </CoolTooltip>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Collapse>
                                        </Col>
                                    )
                                })}
                            </Col>
                            <Col className="col-12 d-flex align-items-center mt-4 px-0">
                                <Col className='col-6'>
                                    <ButtonGroup onClick={this.changePageSize}>
                                        <Button className={(pageSize == 10 ? "bg-host text-white" : "text-secondary") + " page-link"} value={10}> 10 </Button>
                                        <Button className={(pageSize == 25 ? "bg-host text-white" : "text-secondary") + " page-link"} value={25}> 25 </Button>
                                        <Button className={(pageSize == 30 ? "bg-host text-white" : "text-secondary") + " page-link"} value={30}> 30 </Button>
                                        <Button className={(pageSize == 50 ? "bg-host text-white" : "text-secondary") + " page-link"} value={50}> 50 </Button>
                                    </ButtonGroup>
                                </Col>

                                <Col className="text-right col-6">
                                    <span>
                                        <Button className="btn btn-host btn-sm mr-2" onClick={_ => this.handlePageIndex(-1)} disabled={pageIndex === 0}>
                                            <i className="fas fa-angle-left" />
                                        </Button>
                                        <span className="mr-2">
                                            <FormattedMessage id="SearchProfile.Page" values={{ page: parseInt(pageIndex + 1) }} />
                                        </span>
                                        <Button className="btn btn-host btn-sm" onClick={_ => this.handlePageIndex(1)} disabled={proposalHistoryList && proposalHistoryList.length < pageSize}>
                                            <i className="fas fa-angle-right" />
                                        </Button>
                                    </span>
                                </Col>
                            </Col>
                        </Row>
                    : !block ?
                        <div className='d-flex align-items-center justify-content-center mt-3'>
                            <FormattedMessage id="SalesDeal.NoProposalHistory"/>
                        </div>
                    :''}
                </BlockUi>

                {proposalDetailsModal ?
                    <SalesDealDetailsProposalDetailsModal
                        selectedProposalVersion={selectedProposalVersion}
                        proposalCurrency={proposalCurrency}
                        deal={deal}
                        modal={proposalDetailsModal}
                        toggleModal={this.toggleProposalDetailsModal}
                    />
                :''}
            </div>
        );
    }
}

export default injectIntl(ProposalHistoryModal);
