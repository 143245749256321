import React, { Component } from 'react';
import { Button, Popover } from 'reactstrap';
import SpaceManagment from './SpaceManagment';

class SpacePopover extends Component {

    constructor(props) {
        super(props);
        this.state = {
            spacesDetails: [{}],
            selectedSpaceTimeSlotLength: 0,
            space: null,
            date: null,
        };
    }

    componentDidMount() {
        const { eventData, target, isOrphan, spacesOptions } = this.props;
        this.initialCalc(eventData, target, isOrphan, spacesOptions);
    }

    initialCalc = (eventData, target, isOrphan, spacesOptions) => {
        if(isOrphan){
            return;
        }
        
        if(target !== "create-orphan-space"){
            const noEvents = 5 + target.slice(5).indexOf('-');
    
            const events = target.slice(5, noEvents).split(',').map(a => parseInt(a)).filter(a => a);
    
            const noSpace = (noEvents + 1) + target.slice(noEvents + 1).indexOf('-');
            const noIsNew = noSpace + 1 + 6;
            const spaceKey = target.slice(noEvents + 1, noSpace);
    
            const isNewSpace = target.slice(noIsNew, noIsNew + 1) === 't' ? true : false;
            
            const date = target.slice(noIsNew + 2);
            const space = spacesOptions.filter(a => !a.isSpaceCommunicant)[spaceKey];
    
            let spacesDetails = eventData.spaces.filter(({spaceReservationId, isNew}) =>
                ((isNew && isNewSpace) || (!isNew && !isNewSpace)) && (!events?.length || events.includes(spaceReservationId))
            ).map((a) => ({
                ...a,
                children: a.children ?? a.children1,
                pax: a.pax ?? a.adults,
                dailyPrice: a.dailyPrice ?? space?.price,
                spaceCode: space?.code,
                maxCapacity: space?.maxCapacity,
                id: a.id ?? a.spaceReservationId,
                statusCode: a.statusCode ?? a.reservationStatusCode
            }));
            
            if(!spacesDetails.length){
                spacesDetails = [{
                    spaceName: space?.description,
                    spaceCode: space?.code,
                    spaceId: space?.id
                }];
            }

            this.setState({ spacesDetails, space, date, target: target.slice(0, 4).concat(target.slice(noEvents, noSpace)).concat(target.slice(noIsNew + 1)) });
        }
        else {
            const space = spacesOptions[0];

            let spacesDetails = eventData.spaces.filter(({isNew}) => isNew )
            .map((a) => ({
                ...a,
                children: a.children ?? a.children1,
                pax: a.pax ?? a.adults,
                dailyPrice: a.dailyPrice ?? space?.price,
                spaceCode: space?.code,
                maxCapacity: space?.maxCapacity,
                id: a.id ?? a.spaceReservationId,
                statusCode: a.statusCode ?? a.reservationStatusCode
            }));
            
            this.setState({ spacesDetails, space, target });
        }

    }

    render() {
        const { toggleDetailPopOver, eventTypeOptions, customStatusOptions, eventData, saveSpace, spacesOptions, removeSpace } = this.props;
        const { spacesDetails, date, target } = this.state;

        return (
            target ?
                <Popover boundariesElement="window" isOpen={true} target={target} placementPrefix='coolPopover bs-popover' onScroll={e => e.stopPropagation()}>
                    <Button onClick={toggleDetailPopOver} className="closeModal">
                        <i className="fas fa-times fa-sm"/>
                    </Button>
                    <div className={`d-flex align-items-center justify-content-between pt-3 pb-2 ${spacesDetails.length > 1 ? 'overflow-auto' : ''} veryCoolScrollBar`} style={{ maxWidth: spacesDetails.length > 1 ? '700px' : '' }}>
                        {spacesDetails.map((space, key) =>
                            <div style={{ borderLeft: key ? '1px solid lightgrey' : '' }} key={key}>
                                <SpaceManagment
                                    spaceData={space}
                                    spaceName={space.spaceName}
                                    spaceCode={space.spaceCode}
                                    colKey={date}
                                    toggleDetailPopOver={toggleDetailPopOver}
                                    eventTypeOptions={eventTypeOptions}
                                    customStatusOptions={customStatusOptions}
                                    eventData={eventData}
                                    saveSpace={saveSpace}
                                    spacesOptions={spacesOptions}
                                    removeSpace={removeSpace}
                                />
                            </div>
                        )}
                    </div>
                </Popover>
            :''
        );
    }
}

export default SpacePopover;
