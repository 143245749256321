import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Card, Col, Row, CardBody, Modal, ModalBody, Input, Form, FormGroup, Label, UncontrolledTooltip, Badge } from 'reactstrap';
import { getAPI, putAPI, deleteAPI } from "../../Base/API";
import { StyledCard, ActiveInactiveStatusBadge, DefaultPage } from "../../Base/CommonUIComponents";
import { getRunPeriodList, getWeekDays } from '../../Base/ReferenceDataFunctions';
import { handleNotification } from '../../Base/Notification';
import { BlankCard } from '../../Base/CommonUIComponents';
// import { QRCodeCanvas } from 'qrcode.react';

// import Barcode from 'react-barcode';
import CustomSelect from "../../Base/CustomSelect";
import WalletCardBuilder from './WalletCardBuilder';

class WalletCardConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: false,
            configuredCards: [],
            filteredConfiguredCards: [],
            cardTypeSelected: '',
            showWalletCardBuilder: false,
            selectedWalletCardId: null,
            programTypeOptions: []
        }
    };

    componentDidMount() {
        this.getConfiguredCards();
    };

    getConfiguredCards = (updateData) => {
        const { cardTypeSelected } = this.state;

        if(!cardTypeSelected || updateData){
            this.setState({ block: true }, () => {
                getAPI(result => {
                    const { data, error } = result;
                    if (error) {
                        var errorMessage = [];
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data.errors?.length > 0) {
                            handleNotification(data);
                            this.setState({ error: errorMessage, block: false });
                            return;
                        }
    
                        if (data.data?.length > 0) {
                            const configuredCards = this.parseData(data.data);
                            
                            this.setState({ configuredCards, filteredConfiguredCards: configuredCards }, () => this.getProgramTypeOptions());
                            return;
                        }
                        else {
                            this.setState({ error: errorMessage, block: false });
                            return;
                        }
                    }
                    this.setState({ block: false });
                }, `/api/gms/Wallet/v1`);
            });
        }else{
            const { configuredCards, cardTypeSelected } = this.state;
            const filteredConfiguredCards = configuredCards?.filter(card => card?.programTypeCode === cardTypeSelected);

            this.setState({ filteredConfiguredCards });
        }
    };

    parseData = (data) => {
        return data.map(card => {
            const cardData = card?.commonData?.[0] || {};
            const fields = cardData?.fieldLocation || [];
            const backgroundColor = cardData?.backgroundColor || '#FFFFFF';
            const textColor = cardData?.textColor || this.getContrastColor(backgroundColor, 'text');
            const labelColor = cardData?.labelColor || this.getContrastColor(backgroundColor, 'text');
    
            const getField = (code) => fields.find(fl => fl.fieldCode === code)?.fieldValue;
            const getRowFields = (row) => 
                fields
                    .filter(({ fieldLocation }) => fieldLocation?.startsWith(`${row},`))
                    .map(({ fieldValue }) => fieldValue)
                    .filter(Boolean);
    
            return {
                ...cardData,
                id: card?.id || null,
                programTypeCode: card?.programTypeCode || null,
                issuerName: getField('IssuerName'),
                programName: getField('ProgramName'),
                account: getField('Account'),
                firstRow: getRowFields(0),
                secondRow: getRowFields(1),
                thirdRow: getRowFields(2),
                backgroundColor,
                textColor,
                labelColor,
            };
        });
    };

    getProgramTypeOptions = () => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors?.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if(data.response?.length > 0){
                    const programTypeOptions = data.response.map(pt => {
                        return { value: pt.code, label: pt.description }
                    });

                    this.setState({ programTypeOptions, block: false });
                    return;
                }
            }else{
                this.setState({ block: false });
                return;
            }
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardTypeConfig`);
    };

    handleChangeSelect = (name, combo) => {
        this.setState({ [name]: combo ? combo.value : null });
    };

    getContrastColor = (hexcolor, type) => {
        var r = parseInt(hexcolor.substr(1, 2), 16);
        var g = parseInt(hexcolor.substr(3, 2), 16);
        var b = parseInt(hexcolor.substr(5, 2), 16);
        var brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;

        if(type === 'text'){
            return brightness > 128 ? '#000000' : '#FFFFFF';
        }else{
            return brightness > 128 ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.32)';
        }
    };

    toggleWalletCardBuilder = (selectedWalletCardId, updateData) => {
        this.setState({ showWalletCardBuilder: !this.state.showWalletCardBuilder, selectedWalletCardId }, () => {
            if(updateData){
                this.getConfiguredCards(updateData);
            }
        });
    };

    render() {
        const { block, error, filteredConfiguredCards, cardTypeSelected, showWalletCardBuilder, selectedWalletCardId, programTypeOptions } = this.state;
        
        return (
            <>
                {!showWalletCardBuilder ?
                    <StyledCard block={block} error={error}>
                        <Row className="mb-3 align-items-center">
                            <Col>
                                <h5>
                                    <i className="fas fa-wallet mr-2"></i><FormattedMessage id="NavMenu.WalletCardConfig" />
                                </h5>
                            </Col>
                        </Row>
                        <Row className='d-flex align-items-center justify-content-between pb-3'>
                            <Col className='col-8'>
                                <Col className='col-4 px-0'>
                                    <Label>
                                        <FormattedMessage id="WalletCardConfig.SelectCardType" />
                                    </Label>
                                    <CustomSelect
                                        options={programTypeOptions}
                                        value={programTypeOptions.find(el => el.value === cardTypeSelected) || ''}
                                        placeholder={''}
                                        onChange={(combo) => this.handleChangeSelect('cardTypeSelected', combo)}
                                        isClearable
                                    />
                                </Col>
                            </Col>
                            <Col className='col-4 d-flex align-items-center justify-content-end'>
                                <Button className="btn btn-host btn-sm mr-2" onClick={() => this.getConfiguredCards()}>
                                    <i className="fas fa-search"></i>
                                </Button>
                                <Button className="btn btn-host btn-sm mr-2" onClick={() => this.toggleWalletCardBuilder(null, false)}>
                                    <i className="fas fa-plus"></i>
                                </Button>
                            </Col>
                        </Row>
                        <Row className='mt-4' style={{ height: !filteredConfiguredCards?.length > 0 && !block ? '80vh' : '' }}>
                            {filteredConfiguredCards?.length > 0 ? 
                                filteredConfiguredCards.map((card, index) => {
                                    return (
                                        <Col key={index} className='col-2 d-flex align-items-center justify-content-center flex-column pointer walletHoverCol p-3' onClick={() => this.toggleWalletCardBuilder(card?.id, false)}>
                                            <div className='d-flex align-items-center justify-content-start mb-2' style={{ width: '73%' }}>
                                                <h5 className='text-truncate font-weight-bold m-0'>{card?.programName || ''}</h5>
                                                <Badge color={card?.published ? "primary" : "secondary"} className='ml-2'>
                                                    <FormattedMessage id={`WalletCardConfig.${card?.published ? 'Published' : 'NotPublished'}`} />
                                                </Badge>
                                            </div>

                                            <Card 
                                                className='previewWalletCard previewWalletCardHover pointer' 
                                                style={{ 
                                                    background: card?.backgroundColor || '#FFFFFF',
                                                    width: '210px',
                                                    minHeight: '270px',
                                                    transition: 'box-shadow 0.2s ease-in-out'
                                                }}
                                            >
                                                <div className={`w-100 d-flex align-items-start justify-content-${card?.logoImage ? 'between' : 'end'}`} style={{ padding: '12px' }}>
                                                    <Col className='col-10 d-flex align-items-center p-0'>
                                                        <div>
                                                            <img src={card?.logoImage} alt="logo" style={{ width: '20px', height: '20px', borderRadius: '100%' }} /> 
                                                        </div>
                                                        <div className='ml-2 text-truncate'>
                                                            <h6 className='m-0 text-truncate' style={{ color: card?.textColor || '#000000', fontSize: '0.7em' }}>{card?.issuerName || '-'}</h6>
                                                        </div>
                                                    </Col>
                                                    <Col className='col-2 pt-2 px-0 d-flex align-items-center justify-content-end'>
                                                        <i className={`fab fa-${card?.type === 'Google' ? 'google' : 'apple'}`} style={{ color: card?.textColor || '#000000', fontSize: '0.7em' }}></i>
                                                    </Col>
                                                </div>
                                                <div>
                                                    <hr className='m-0'style={{ borderTop: `1px solid ${this.getContrastColor(card?.backgroundColor, 'border')}` }} />
                                                </div>
                                                <div className={`w-100`} style={{ padding: '12px', paddingBottom: '0px' }}>
                                                    <Col className='col-12 d-flex align-items-center justify-content-start pb-3 px-0'>
                                                        <div className=''>
                                                            <h6 className='m-0 text-truncate' style={{ color: card?.textColor || '#000000', fontSize: '0.7em' }}>{card?.programName || '-'}</h6>
                                                        </div>
                                                    </Col>
                                                    {[...Array(3)].map((row, rowIndex) => {
                                                        const currentRow = rowIndex === 0 ? card?.firstRow : rowIndex === 1 ? card?.secondRow : card?.thirdRow;

                                                        return(
                                                            <Col className={`col-12 d-flex align-items-center justify-content-${currentRow?.length > 1 ? 'between' : 'center'} py-2 px-0`} key={rowIndex}>
                                                                {currentRow?.length > 0 ? 
                                                                    <div className=''>
                                                                        <h6 className='m-0 text-truncate' style={{ fontSize: '0.5em', color: card?.textColor || '#000000' }}>{currentRow[0] || ''}</h6>
                                                                    </div>
                                                                :''}
                                                                {currentRow?.length > 1 ?
                                                                    <div className=''>
                                                                        <h6 className='m-0 text-truncate' style={{ fontSize: '0.5em', color: card?.textColor || '#000000' }}>{currentRow[1] || ''}</h6>
                                                                    </div>
                                                                :''}
                                                                {currentRow?.length > 2 ?
                                                                    <div className=''>
                                                                        <h6 className='m-0 text-truncate' style={{ fontSize: '0.5em', color: card?.textColor || '#000000' }}>{currentRow[2] || ''}</h6>
                                                                    </div>
                                                                :''}
                                                            </Col>
                                                        )
                                                    })}
                                                    <Col className='col-12 pt-3 justify-content-center'>
                                                        {card?.barCodeType ?
                                                            <>
                                                                <Col className='d-flex align-items-end justify-content-center col-12'>
                                                                    {card?.barCodeType === 'QR_Code' ?
                                                                        <div className='walletQrcode d-flex align-items-center justify-content-center' style={{ backgroundColor: 'white', padding: '7px', borderRadius: '10px' }}>
                                                                            {/* <QRCodeCanvas 
                                                                                value={'qrcode'} 
                                                                                size={30} 
                                                                                id="qrcode"
                                                                            /> */}
                                                                        </div>
                                                                    :
                                                                        <span id="barCode" className='walletBarCode' style={{ backgroundColor: 'white', padding: '5px', borderRadius: '10px' }}>
                                                                            {/* <Barcode 
                                                                                value={'barCode'}
                                                                                format={card?.barCodeType === 'CODE_128' ? 'CODE128' : (card?.barCodeType || 'CODE128')}
                                                                                background="#FFFFFF" 
                                                                                width={1}
                                                                                height={40}
                                                                                displayValue={false}
                                                                            /> */}
                                                                        </span>
                                                                    }
                                                                </Col>
                                                                {card?.barCodeText ?
                                                                    <Col className='text-center py-1'>
                                                                        <span style={{ color: card?.textColor, fontSize: '0.5em' }}>
                                                                            {card?.barCodeText}
                                                                        </span>
                                                                    </Col>
                                                                :''}
                                                            </>
                                                        :''}
                                                    </Col>
                                                </div>
                                                <div>
                                                    <Col className='col-12 d-flex align-items-center justify-content-center p-0'>
                                                        {card?.heroImage ?
                                                            <Col className='col-12 p-0 googleWalletHeroConfig'>
                                                                <img src={card?.heroImage} alt='heroImage' />
                                                            </Col>
                                                        :''}
                                                    </Col>
                                                </div>
                                            </Card>
                                        </Col>
                                    )
                                })
                            : !block ?
                                <Col className='col-12 d-flex align-items-center justify-content-center'>
                                    <DefaultPage text="WalletCardConfig.NoResultsForSearchCriteria" icon="icon-icon-search" />
                                </Col>
                            :''}
                        </Row>
                    </StyledCard>
                :
                    <WalletCardBuilder 
                        selectedWalletCardId={selectedWalletCardId}
                        programTypeOptions={programTypeOptions}
                        goBack={() => this.toggleWalletCardBuilder(null, true)}
                    />
                 }           
            </>
            
        )
    }
}

export default injectIntl(WalletCardConfig);