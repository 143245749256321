import React, { Component } from 'react';
import { Crt, StyledCard } from '../../Base/CommonUIComponents';
import { Button, Row, Col, Card, CardBody, } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getAPI } from "../../Base/API";
import moment from 'moment';
import { getProfileDataQualityMetrics, getProfileType } from '../../Base/ReferenceDataFunctions';
import { handleNotification } from '../../Base/Notification';
import CustomSelect from '../../Base/CustomSelect';
import ProfileSummaryCardData from './ProfileSummaryCardData';

class ProfileSummary extends Component {
    static displayName = ProfileSummary.name;

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            profileDataQuality: {},
            metrics: getProfileDataQualityMetrics(),
            legend: [{ label: this.props.intl.formatMessage({ id: 'ProfileSummary.Filled' }), color: '#56CFE1' },
                { label: this.props.intl.formatMessage({ id: 'ProfileSummary.Unfilled' }), color: '#EDEDED' },
                { label: this.props.intl.formatMessage({ id: 'ProfileSummary.Valid' }), color: '#6EDE8A' },
                { label: this.props.intl.formatMessage({ id: 'ProfileSummary.Errors' }), color: '#F38375' }],
            calculateResultsDisabled: false,
            type: null
            //calculateResultsDisabled: true
        };
    };

    componentDidMount() {
        this.getConfig();
    }

    getConfig = () => {
        const { type } = this.state;

        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }
                else {
                    this.setState({ profileDataQuality: data.response ? data.response[0] : {}, block: false, modal: false });
                }
            }
            else this.setState({ block: false });
        }, `/api/gms/DataQuality/v1/qualityProfile${type ? `?type=${type}` : ``}`);
    }

    calculateResults = (e) => {
        const { type } = this.state;
        e.preventDefault();
        this.setState({ block: true });
        
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }
                else {
                    if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="ProfileSummary.Calculate" />, <FormattedMessage id="generic.success" />);
                        this.setState({ profileDataQuality: data.response[0], block: false, modal: false });
                    }
                    else {
                        handleNotification({}, <FormattedMessage id="ProfileSummary.Timeout" />, <FormattedMessage id="generic.Timeout" />, 'info');
                        this.setState({ block: false, modal: false, calculateResultsDisabled: true });
                    }
                }
            }
            else this.setState({ block: false });
        }, `/api/gms/DataQuality/v1/qualityProfile/calculate${type ? `?type=${type}` : ``}`);
    }

    getChartDatasets = (totalMasters, results, errors) => {
        const { legend } = this.state;

        const filled = results?.length > 0 && results[0]?.filled;
        const valid = results?.length > 0 && results[0]?.valid;

        const percFilled = filled && totalMasters ? parseFloat(filled * 100 / totalMasters).toFixed() : 0;
        const percNotFilled = filled && totalMasters ? parseFloat((totalMasters - filled) * 100 / totalMasters).toFixed() : 0;

        const percValid = valid && filled ? parseFloat(valid * 100 / filled).toFixed() : 0;
        const percErrors = errors && filled ? parseFloat(errors * 100 / filled).toFixed() : 0;


        const chartData = {
            "labels": legend.map(el=> el.label),
            "datasets": [
                { "data": [percFilled, percNotFilled], "backgroundColor": [legend[0].color, legend[1].color] },
                { "data": [percValid, percErrors], "backgroundColor": [legend[2].color, legend[3].color] }
            ]
        }

        return chartData
    }

    setCombo = (name, combo) => {
        this.setState({ [name]: combo && combo.value });
    }

    render() {
        const { block, calculateResultsDisabled, error, profileDataQuality, metrics, legend, type } = this.state;

        return (
            <StyledCard block={block} error={error} title={<FormattedMessage id="NavMenu.ProfileSummary" />} icon="fas fa-user-cog">
                <Row className="mt-2 mb-4">
                    <Col className="col-3">
                        <CustomSelect
                            placeholder={<FormattedMessage id="SearchProfile.EntityType" />}
                            options={getProfileType(this.props.intl)}
                            value={getProfileType(this.props.intl).find(t => t.value === type)}
                            onChange={this.setCombo.bind(this, 'type')}
                            isClearable
                            isSearchable
                        />
                    </Col>
                    <Col className="text-right">
                        <Button className="btn-sm btn-host mr-2" onClick={this.getConfig}>
                            <i className="fas fa-search" />
                        </Button>
                        <Button className="btn-sm btn-host" onClick={this.calculateResults} disabled={calculateResultsDisabled}>
                            <i className="fas fa-calculator" />
                        </Button>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col sm={3}>
                        <Crt
                            text={'Total Masters'}
                            value={profileDataQuality?.totalMaster || 0}
                        />
                    </Col>
                    <Col>
                        <Card className="shadow border-0 h-100 my-0 py-0">
                            <CardBody>
                                <Row className="mb-3">
                                    <Col><h5 className="text-muted"><FormattedMessage id="ProfileSummary.Legend" /></h5></Col>
                                </Row>
                                <Row style={{ float: 'right', minWidth: '70%' }}>
                                    {legend.map((item, key) =>
                                        <Col key={key}>
                                            <div className="mb-1 pl-2" style={{ fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif", fontSize: '12px', fontStyle: 'normal', fontColor: '#666', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                <div className="align-items-center" style={{ display: 'inline-flex' }} >
                                                    <div style={{ width: '35px', height: '9.5px', backgroundColor: item.color }} className="mr-2" />
                                                    {item.label}
                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={3}>
                        <Crt
                            text={<FormattedMessage id="BatchLogs.LastRun" />}
                            value={profileDataQuality && profileDataQuality.lastRun ? moment(profileDataQuality.lastRun).format("YYYY-MM-DD HH:mm") : ''}
                        />
                    </Col>
                </Row>

                <Row>
                    {profileDataQuality && profileDataQuality.criteria && profileDataQuality.criteria.map((criteria, idx) =>
                        <ProfileSummaryCardData
                            criteria={criteria}
                            idx={idx}
                            metrics={metrics}
                            totalMasters={profileDataQuality.totalMaster}
                            getChartDatasets={this.getChartDatasets}
                        />
                    )}
                </Row>
            </StyledCard>
        );
    }
}

export default injectIntl(ProfileSummary); 