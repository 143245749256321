import React, { Component } from 'react';
import { Button, Row, Col, Form, FormGroup, Input, Label, Card, CardBody, Badge, UncontrolledCollapse } from 'reactstrap';
import CustomSelect from '../Base/CustomSelect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAddressTypeList, getContactTypeList, getDocumentAllList, getDocumentList, getGenderOptions, getISSOptions, getProfileType } from '../Base/ReferenceDataFunctions';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment-timezone';
import countryList from 'react-select-country-list';
import { handleNotification } from '../Base/Notification';
import { putAPI, postAPI } from '../Base/API';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../Base/ErrorAlert';
import Authorization, { CheckAuthorization } from '../Base/Authorization';
import AddressDetails from './AddressDetails';
import ContactDetails from './ContactDetails';
import { ActiveInactiveStatusBadge, DefaultPage } from '../Base/CommonUIComponents';
import DocumentDetails from './DocumentDetails';
import DuplicatedProfilesModal from './DuplicatedProfilesModal';

class CreateProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            profile: {
                profileAddresses: [],
                profileContacts: [],
                profileDocuments: []
            },
            addressTypes: getAddressTypeList(this.props.intl),
            contactTypes: getContactTypeList(this.props.intl),
            profileAddressModal: false,
            profileContactModal: false,
            profileDocumentModal: false,
            isFirstTime: true,
            duplicatedProfilesModal: false,
            duplicatedProfiles: null
        };
    }

    saveProfile = (e) => {
        e.preventDefault();

        if (this.props.createMasterAPI) {
            this.createMasterProfile()
        }
        else {
            this.createProfile()
        }
    }

    createMasterProfile = () => {
        this.setState({ block: true }, () => {
            const { associateNewProfile, showRoleOptions } = this.props;
            const { profile } = this.state;

            const request = {
                ...profile
            };

            request.contacts = request.profileContacts;
            request.documents = request.profileDocuments;
            request.addresses = request.profileAddresses;

            if (this.props.isPortal) profile.isPortal = true;

            postAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="CreateProfile.ProfileCreated" />, <FormattedMessage id="generic.success" />);

                        const newProfile = data.response[0];
                        associateNewProfile(newProfile.id, newProfile.firstName, newProfile.lastName, (showRoleOptions ? ({ roleId: profile?.roleId, role: profile?.role, notes: profile?.notes }) : (null)));
                        return;
                    }
                    this.setState({ block: false });
                }
            }, `/api/gms/Profile/v1/profile/master?createMaster=true`, JSON.stringify({ request }))
        });
    }

    createProfile = () => {
        this.setState({ block: true }, () => {
            const { associateNewProfile, showRoleOptions } = this.props;
            const { profile, isFirstTime } = this.state;
            const duplicateVerification = isFirstTime && (profile.profileContacts.length > 0 || profile.profileDocuments.length > 0);

            putAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false, isFirstTime: false });
                    return;
                }
                if (data) {
                    if (data.warnings && data.warnings.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false, isFirstTime: false, duplicatedProfilesModal: true, duplicatedProfiles: data.response });

                        return;
                    }
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="CreateProfile.ProfileCreated" />, <FormattedMessage id="generic.success" />);
                        
                        const newProfile = data.response[0];

                        if (typeof associateNewProfile === 'function') {
                            associateNewProfile(newProfile.id, newProfile.firstName, newProfile.lastName, (showRoleOptions ? ({ roleId: profile?.roleId, role: profile?.role, notes: profile?.notes }) : (null)));
                        }
                        else {
                            this.props.history.push({ pathname: `/ProfileDetails/${data.response[0].id}` });
                        }

                        return;
                    }
                    this.setState({ block: false, isFirstTime: false });
                }
            }, `/api/gms/Profile/v1/profile?duplicateVerification=${duplicateVerification}`, JSON.stringify({ request: profile }))
        });
    }
    
    handleSelect = (name, combo) => {
        this.setState(prevState => ({
            profile: {
                ...prevState.profile,
                [name]: combo ? combo.value : null,
                ...(name === 'role' && { roleId: combo?.value, role: combo?.label }),
            },
        }));
    };

    handleChange = (evt) => {
        const { name, value } = evt.target;
        this.setState(prevState => ({
            profile: {
                ...prevState.profile,
                [name]: value
            }
        }));
    }
    
    toggleModal = (modalName, propName, selectedElement) => {
        this.setState(prevState => ({
            [modalName]: !prevState[modalName],
            [propName]: selectedElement
        }))
    }

    updateList = (listName, newData) => {
        const { profile } = this.state;

        if (newData.index > -1) {
            profile[listName][newData.index] = newData;
        }
        else
            profile[listName].push(newData);

        this.setState({ profile, profileAddressModal: false, profileContactModal: false, profileDocumentModal: false });
    }

    removeListElement = (listName, index) => {
        const { profile } = this.state;

        profile[listName].splice(index, 1);

        this.setState({ profile });
    }

    render() {
        const { block, error, profile, profileAddressModal, profileContactModal, profileDocumentModal, addressTypes, isFirstTime, duplicatedProfilesModal, duplicatedProfiles } = this.state;
        const { showRoleOptions, profileRoleOptions, history } = this.props;

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                <Form className='py-2' onSubmit={this.saveProfile}>
                    <div className={`mb-3 ${!showRoleOptions ? 'mt-2' : ''} d-flex align-items-center justify-content-between`}>
                        <div>
                            <h5>
                                <i className="fas fa-user mr-2"/>
                                <FormattedMessage id="CreateProfile.CreateProfile"/>
                            </h5>
                        </div>
                        <div className='d-flex align-items-center'>
                            {
                                showRoleOptions ?
                                    <Button className="btn-sm btn-host" onClick={() => this.props.toggleCreateModal()} type="button">
                                        <i className="fas fa-list-ul" />
                                    </Button>
                                    :
                                    ''
                            }
                            <Authorization
                                perform="profilePersonalData:save"
                                yes={() => 
                                    <Button className="btn-sm btn-host ml-2" type="submit" disabled={profile.type !== 'Person' && !profile.firstName && !profile.lastName}>
                                        <i className="fas fa-save" />
                                    </Button>
                                }
                                no={() => <div></div>}
                            />
                        </div>
                    </div>

                    <Row>
                        <Col sm={7} className="pl-4">
                            <FormGroup row>
                                <Label sm={2} >
                                    <FormattedMessage id="ProfileDetails.EntityType" />
                                </Label>
                                <Col className="col-4">
                                    <CustomSelect
                                        placeholder=""
                                        options={getProfileType(this.props.intl)}
                                        value={getProfileType(this.props.intl).find(t => t.value === profile.type) || ''}
                                        onChange={this.handleSelect.bind(this, 'type')}
                                        isClearable={false}
                                        isSearchable={false}
                                        required
                                    />
                                </Col>
                                {showRoleOptions ?
                                    <>
                                        <Label sm={2} >
                                            <FormattedMessage id="SalesDeal.Role" />
                                        </Label>
                                        <Col className="col-4">
                                            <CustomSelect
                                                placeholder=""
                                                options={profileRoleOptions || []}
                                                value={profileRoleOptions?.find(r => r.value === profile?.roleId) || ''}
                                                onChange={this.handleSelect.bind(this, 'role')}
                                                isClearable={false}
                                                required
                                            />
                                        </Col>
                                    </>
                                :''}
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2} id="FirstName">
                                    <FormattedMessage id="ProfileDetails.Name" />
                                </Label>
                                <Col sm={4}>
                                    <Input
                                        type="text"
                                        maxLength="100"
                                        name="firstName"
                                        required={profile.type === 'Person'}
                                        value={profile.firstName ? profile.firstName : ''}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                <Label sm={2} id="LastName">
                                    <FormattedMessage id="ProfileDetails.Surname" />
                                </Label>
                                <Col sm={4}>
                                    <Input
                                        type="text"
                                        maxLength="100"
                                        name="lastName"
                                        required
                                        value={profile.lastName ? profile.lastName : ''}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2} id="FullName" className="pr-0">
                                    <FormattedMessage id="ProfileDetails.FullName" />
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        type="text"
                                        name="fullName"
                                        value={profile.fullName ? profile.fullName : ''}
                                        maxLength="200"
                                        onChange={this.handleChange}
                                        readOnly
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2}>
                                    <FormattedMessage id="ProfileDetails.OtherNames" />
                                </Label>
                                <Col sm={4}>
                                    <Input
                                        type="text"
                                        maxLength="100"
                                        name="middleName"
                                        value={profile.middleName || ''}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                                <Label sm={2} id="BirthDate">
                                    <FormattedMessage id="ProfileDetails.DateOfBirth" />
                                </Label>
                                <Col sm={4}>
                                    <SingleDatePicker
                                        id="dateOfBirth"
                                        isOutsideRange={day => day > moment()}
                                        date={profile.birthDate ? moment(profile.birthDate) : null}
                                        focused={this.state.focused}
                                        onFocusChange={({ focused }) => this.setState({ focused })}
                                        small={true}
                                        numberOfMonths={1}
                                        showDefaultInputIcon={true}
                                        onDateChange={date => this.setState({ profile: { ...this.state.profile, birthDate: date?.format('YYYY-MM-DD') } })}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label sm={2} id="Gender">
                                    <FormattedMessage id="ProfileDetails.Gender" />
                                </Label>
                                <Col sm={4}>
                                    <CustomSelect
                                        placeholder=""
                                        options={getGenderOptions(this.props.intl)}
                                        value={getGenderOptions(this.props.intl).find(g => g.value === profile.gender) || ''}
                                        onChange={this.handleSelect.bind(this, 'gender')}
                                        isClearable
                                        isSearchable
                                    />
                                </Col>
                                <Label sm={2} id="Language">
                                    <FormattedMessage id="ProfileDetails.Language" />
                                </Label>
                                <Col sm={4}>
                                    <Input
                                        type="text"
                                        className="shadow"
                                        maxLength="5"
                                        name="language"
                                        value={profile.language ? profile.language : ''}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2} id="Nationality">
                                    <FormattedMessage id="ProfileDetails.Nationality" />
                                </Label>
                                <Col sm={4}>
                                    <CustomSelect
                                        options={countryList().getData()}
                                        placeholder=""
                                        value={profile.nationality ? countryList().getData().find(country => country.value === profile.nationality) : ''}
                                        onChange={this.handleSelect.bind(this, 'nationality')}
                                        isClearable
                                        isSearchable
                                    />
                                </Col>
                                <Label sm={2} className="pr-0">
                                    <FormattedMessage id="ProfileDetails.ISS" />
                                </Label>
                                <Col sm={4}>
                                    <CustomSelect
                                        options={getISSOptions(this.props.intl)}
                                        placeholder=""
                                        value={getISSOptions(this.props.intl).find(el => el.value == profile.iss) || ''}
                                        onChange={this.handleSelect.bind(this, 'iss')}
                                        isClearable
                                        isSearchable
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row className='mt-4'>
                                {showRoleOptions ?
                                    <>
                                        <Col sm={12}>
                                            <h5 className="m-0">
                                                <i className="fas fa-highlighter mr-2" />
                                                <FormattedMessage id="SalesDeal.InitiativeProfileNotes" />
                                            </h5>
                                        </Col>
                                        <Col sm={12} className='mt-2'>
                                            <Input
                                                type="textarea"
                                                name="notes"
                                                className="py-2"
                                                style={{ height: '150px' }}
                                                value={profile.notes || ''}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </>
                                :''}
                                <Col sm={12} className={showRoleOptions ? 'mt-4' : ''}>
                                    <h5 className="m-0">
                                        <i className="fas fa-highlighter mr-2" />
                                        <FormattedMessage id="ProfileDetails.GeneralNotes" />
                                    </h5>
                                </Col>
                                <Col sm={12} className='mt-2'>
                                    <Input
                                        type="textarea"
                                        name="guestInfo"
                                        className="py-2"
                                        style={{ height: '150px' }}
                                        value={profile.guestInfo || ''}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>

                        <Col sm={5} className="px-2">
                            <div className="verticalScroll px-2" style={{ height: '75vh', overflowX: 'hidden' }}>
                                <div className='mt-3'>
                                    <div className="d-flex justify-content-between align-items-center border-bottom pb-1 mb-1">
                                        <span>
                                            <i className="icon-icon-addresses mr-2" />
                                            <FormattedMessage id="ProfileDetails.ProfileAddress" />
                                        </span>
                                        <div className="d-flex align-items-center">
                                            <Button className="btn-sm btn-host" onClick={() => this.toggleModal('profileAddressModal')} >
                                                <i className="fas fa-plus" />
                                            </Button>
                                            <i className="fas fa-chevron-down mx-2 pl-2 pointer" id="profileAddress" />
                                        </div>
                                    </div>

                                    <UncontrolledCollapse toggler="#profileAddress" defaultOpen={true}>
                                        {profile.profileAddresses?.length > 0 ?
                                            profile.profileAddresses.map((address, key) => {
                                                const type = address.type ? addressTypes.find(type => type.value === address.type) : null;

                                                return <Card className="border-0 mb-2 shadow" key={key}>
                                                    <CardBody className="pt-2">
                                                        <Row>
                                                            <Col className="pl-2">
                                                                <div style={{ cursor: 'pointer' }} onClick={() => this.toggleModal('profileAddressModal', 'selectedAddress', { ...address, index: key })}>
                                                                    <Row>
                                                                        <Col className="col-2">
                                                                            <div>{address.country ? <i className={('mr-2 flag-icon flag-icon-') + (address.country.toLowerCase())}></i> : address.country} {address.locality}</div>
                                                                            <div>{type ? <Badge color="primary" >{type.label}</Badge> : ''}</div>
                                                                        </Col>
                                                                        <Col className="col-10">
                                                                            <Row>
                                                                                {address.addressLine1 &&
                                                                                    <Col sm={6} id={`AddressLine1-${address.id}`}>
                                                                                        <div className="title-sm"><FormattedMessage id="ProfileDetails.AddressLine" /> 1 </div>  {address.addressLine1 || '-'}
                                                                                    </Col>
                                                                                }
                                                                                {address.addressLine2 &&
                                                                                    <Col sm={6} id={`AddressLine2-${address.id}`}>
                                                                                        <div className="title-sm"><FormattedMessage id="ProfileDetails.AddressLine" /> 2 </div> {address.addressLine2 || '-'}
                                                                                    </Col>
                                                                                }
                                                                                {address.addressLine3 &&
                                                                                    <Col sm={6} id={`Address.Line3-${address.id}`}><div className="title-sm"><FormattedMessage id="ProfileDetails.AddressLine" /> 3 </div>  {address.addressLine3 || '-'}</Col>
                                                                                }
                                                                                {address.addressLine4 &&
                                                                                    <Col sm={6} id={`AddressLine4-${address.id}`}><div className="title-sm"><FormattedMessage id="ProfileDetails.AddressLine" /> 4 </div>  {address.addressLine4 || '-'}</Col>
                                                                                }
                                                                            </Row>
                                                                            <Row className="mt-1">
                                                                                <Col id={`PostalCode-${address.id}`}><div className="title-sm"><FormattedMessage id="ProfileDetails.PostalCode" /> </div> {address.postalCode}</Col>
                                                                                <Col id={`Region-${address.id}`}><div className="title-sm"><FormattedMessage id="ProfileDetails.Region" /> </div> {address.region}</Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Col>
                                                            <Col className="text-right col-1 pr-2">
                                                                <span onClick={() => this.removeListElement('profileAddresses', key)} style={{ cursor: 'pointer' }}>
                                                                    <i className="fas fa-trash-alt" />
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            })

                                            : <DefaultPage text="ProfileDetails.NoAddressesAvailable" height="15vh" />}
                                    </UncontrolledCollapse>
                                </div>

                                <div className='mt-4'>
                                    <div className="d-flex justify-content-between align-items-center border-bottom pb-1 mb-1">
                                        <span>
                                            <i className="icon-icon-contacts mr-2" />
                                            <FormattedMessage id="ProfileDetails.ProfileContact" />
                                        </span>
                                        <div className="d-flex align-items-center">
                                            <Button className="btn-sm btn-host" onClick={() => this.toggleModal('profileContactModal')} >
                                                <i className="fas fa-plus" />
                                            </Button>
                                            <i className="fas fa-chevron-down mx-2 pl-2 pointer" id="profileContact" />
                                        </div>
                                    </div>

                                    <UncontrolledCollapse toggler="#profileContact" defaultOpen={true}>
                                        {profile.profileContacts?.length > 0 ?
                                            profile.profileContacts.map((contact, key) => {
                                                const contactType = getContactTypeList(this.props.intl).find(c => c.value === contact.type);

                                                return <Card className="border-0 mb-2 shadow" key={key}>
                                                    <CardBody className="p-3 p-lg-2">
                                                        <Row className="mb-0 align-items-center">
                                                            <Col className={`pr-0 pr-lg-3 ${global.isMobile ? 'col-10' : ('')}`}>
                                                                <div style={{ cursor: 'pointer' }} onClick={() => this.toggleModal('profileContactModal', 'selectedContact', { ...contact, index: key })}>
                                                                    <Row className='align-items-center'>
                                                                        <Col className="col-5 col-lg-2 text-truncate" id={`Type-${contact.id}`}>
                                                                            {<i className={contactType?.icon + " mr-2"} />}
                                                                            {contactType?.label}
                                                                        </Col>
                                                                        <Col className="col-3 text-center text-lg-left col-lg-2 p-lg-0">
                                                                            <ActiveInactiveStatusBadge status={contact.active} />
                                                                            {contact.main ? <Badge color="primary" className="mx-2 p-1">Main</Badge> : ''}
                                                                        </Col>
                                                                        <Col id={`Contact-${contact.id}`} className={global.isMobile ? 'col-4 text-truncate' : ''}> {contact.contact} </Col>
                                                                    </Row>
                                                                </div>
                                                            </Col>
                                                            <Col className={`col-2 col-lg-1 text-right`}>
                                                                <span onClick={() => this.removeListElement('profileContacts', key)} style={{ cursor: 'pointer' }}>
                                                                    <i className="fas fa-trash-alt" />
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            })
                                            :
                                            <DefaultPage text="ProfileDetails.NoContactsAvailable" height="15vh" />
                                        }
                                    </UncontrolledCollapse>
                                </div>

                                <div className='mt-4'>
                                    <div className="d-flex justify-content-between align-items-center border-bottom pb-1 mb-1">
                                        <span>
                                            <i className="icon-icon-contacts mr-2" />
                                            <FormattedMessage id="ProfileDetails.ProfileDocument" />
                                        </span>
                                        <div className="d-flex align-items-center">
                                            <Button className="btn-sm btn-host" onClick={() => this.toggleModal('profileDocumentModal')} >
                                                <i className="fas fa-plus" />
                                            </Button>
                                            <i className="fas fa-chevron-down mx-2 pl-2 pointer" id="profileDocument" />
                                        </div>
                                    </div>

                                    <UncontrolledCollapse toggler="#profileDocument" defaultOpen={true}>
                                        {profile.profileDocuments?.length > 0 ?
                                            profile.profileDocuments.map((doc, key) =>
                                                <Card className="border-0 mb-2 shadow" key={key}>
                                                    <CardBody className="p-2">
                                                        <Row>
                                                            <Col>
                                                                <div style={{ cursor: 'pointer' }} onClick={() => this.toggleModal('profileDocumentModal', 'selectedDocument', { ...doc, index: key })}>
                                                                    <Row>
                                                                        <Col className="col-3" id={`Type-${doc.id}`}>
                                                                            <i className="fas fa-file-invoice mr-2"></i>
                                                                            {getDocumentAllList(this.props.intl).find(d => d.value === doc.type)?.label || doc.type}
                                                                        </Col>
                                                                        <Col id={`Number-${doc.id}`}>
                                                                            <div className="title-sm"><FormattedMessage id="ProfileDetails.Number" /></div>
                                                                            {doc.number}
                                                                        </Col>

                                                                        {doc.type !== 'FiscalNumber' ?
                                                                            <>
                                                                                <Col id={`IssueDate-${doc.id}`}>
                                                                                    {doc.issueDate && <><div className="title-sm"><FormattedMessage id="ProfileDetails.IssueDate" /> </div> {moment(doc.issueDate).format("YYYY-MM-DD")} </>}
                                                                                </Col>
                                                                                <Col id={`ValidUntil-${doc.id}`}>
                                                                                    {doc.validUntil &&
                                                                                        <>
                                                                                            <div className="mr-2 title-sm"><FormattedMessage id="ProfileDetails.ValidUntil" />
                                                                                                <span style={{ width: '16px' }}> {doc.isExpired || true ? <i className="icon-icon-warnings-enabled text-warning fa-sm" /> : ''}</span>
                                                                                            </div>
                                                                                            {moment(doc.validUntil).format("YYYY-MM-DD")}
                                                                                        </>
                                                                                    }
                                                                                </Col>
                                                                            </>
                                                                            : ''}
                                                                    </Row>
                                                                </div>
                                                            </Col>
                                                            <Col className="text-right col-1">
                                                                <span onClick={() => this.removeListElement('profileDocuments', key)} style={{ cursor: 'pointer' }}>
                                                                    <i className="fas fa-trash-alt" />
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            )
                                            :
                                            <DefaultPage text="ProfileDetails.NoDocumentsAvailable" height="15vh" />
                                        }
                                    </UncontrolledCollapse>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>

                {
                    duplicatedProfilesModal ?
                        <DuplicatedProfilesModal
                            modal={duplicatedProfilesModal}
                            toggleModal={_ => this.toggleModal('duplicatedProfilesModal')}
                            duplicatedProfiles={duplicatedProfiles}
                            history={history}
                            createProfile={this.createProfile}
                        />
                        :
                        ''
                }
                {profileAddressModal ?
                    <AddressDetails
                        modal={profileAddressModal}
                        toggleModal={_ => this.toggleModal('profileAddressModal', 'selectedAddress')}
                        address={this.state.selectedAddress}
                        availableAddressType={addressTypes}
                        saveAddress={(address) => this.updateList('profileAddresses', address)}
                    />
                    : ''}

                {profileContactModal ?
                    <ContactDetails
                        modal={profileContactModal}
                        toggleModal={_ => this.toggleModal('profileContactModal', 'selectedContact')}
                        contact={this.state.selectedContact}
                        allContacts={profile.profileContacts}
                        availableTypes={this.state.contactTypes}
                        nationality={profile.nationality}
                        addresses={this.state.profile.profileAddressModal}
                        saveContact={(contact) => this.updateList('profileContacts', contact)}
                    />
                : ''}
                    
                {profileDocumentModal ?
                    <DocumentDetails
                        modal={profileDocumentModal}
                        toggleModal={_ => this.toggleModal('profileDocumentModal', 'selectedDocument')}
                        document={this.state.selectedDocument}
                        availableDocuments={getDocumentList(this.props.intl)}
                        saveDocument={(document) => this.updateList('profileDocuments', document)}
                    />
                    : ''}                
            </BlockUi>
        );
    }
}

export default injectIntl(CreateProfile);