import React from 'react';
import { Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, UncontrolledDropdown } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { CheckAuthorization } from '../../Base/Authorization';



export const StripoFolders = ({ folders, saveNewFolder, selectFolder, selectedFolder, onDrop, onDragOver, overFolder, handleNewFolderName,
    toggleEditFolder, toggleDeleteModal, editFolder, newFolderName, saveFolderEdit, hasTemplates, isEmail }) => {
        
    return (
        isEmail || CheckAuthorization("admin:view", null) ?
            <div>
                <Card className={"shadow pointer stripoFolder "} body onClick={saveNewFolder} style={{ border: '2px solid transparent' }}>
                    <span> <i className="fas fa-folder-plus mr-2 fa-lg" /> <FormattedMessage id="stripo.newFolder" /></span>
                </Card>
                <Card className={"shadow pointer " + `stripoFolder ${!selectedFolder ? 'bg-host-light' : ''}`} body onClick={() => selectFolder(null)}
                style={{ border: (overFolder === 0 && selectedFolder !== null) ? '2px dashed grey' : '2px solid transparent' }} onDragOver={(e) => onDragOver(e, 0)} onDrop={(e) => onDrop(e, null)}>
                    <span><i className="fas fa-home mr-2 fa-lg"/>Home</span>
                </Card>
                {folders?.filter(({hotelGroupId}) => (hotelGroupId !== "00000000-0000-0000-0000-000000000000" && isEmail) || (hotelGroupId === "00000000-0000-0000-0000-000000000000" && !isEmail))
                .map((folder, k) =>
                    <Card onClick={() => selectFolder(folder.id)} body key={k} className={"shadow pointer " + `stripoFolder ${selectedFolder === folder.id ? 'bg-host-light' : ''}`}
                    style={{ border: (overFolder === folder.id && selectFolder !== folder.id) ? '2px dashed grey' : '2px solid transparent' }} onDragOver={(e) => onDragOver(e, folder.id)}
                    onDrop={(e) => onDrop(e, folder.id)}>
                        {editFolder === folder.id ? 
                            <Row className='p-0 m-0'>
                                <Col className='p-0 m-0'>
                                    <Input
                                        type="text"
                                        style={{ boxShadow: 'none' }}
                                        autoFocus
                                        onChange={handleNewFolderName}
                                        defaultValue={newFolderName && newFolderName?.length > 0 ? newFolderName : folder.name}
                                    />
                                </Col>
                                <Col className="col-2 p-0 m-0 d-flex align-items-center justify-content-end">
                                    <Button className="btn btn-host btn-sm float-right mx-1" onClick={saveFolderEdit}  >
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>   
                            </Row>
                        :
                            <span className='d-flex align-items-center'>
                                {selectedFolder === folder.id || overFolder === folder.id ?
                                    <i className="far fa-folder-open mr-2 fa-lg"/>
                                :
                                        <i className="far fa-folder mr-2 fa-lg" />
                                }
                                <div>{folder.name}</div>
                                {selectedFolder === folder.id ?
                                    <UncontrolledDropdown className='ml-2'>
                                        <DropdownToggle style={{ background: 'none', border: 'none', color: 'unset', padding: 'unset', width: '10px' }}>
                                            <i className="fas fa-ellipsis-v"/>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => toggleEditFolder(folder.id)}>
                                                <i className="mr-2 far fa-edit"/>
                                                <FormattedMessage id="stripo.edit"/>
                                            </DropdownItem>
                                            <DropdownItem disabled={hasTemplates} onClick={() => toggleDeleteModal(folder.id)}>
                                                <div style={{ color: hasTemplates ? 'grey' : '#A80C19' }}>
                                                    <i className="mr-2 far fa-trash-alt"/>
                                                    <FormattedMessage id="stripo.delete"/>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                :''}
                            </span>
                        }
                    </Card>
                )}
            </div>
        :''
    )
};
