import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { CoolTooltip, DefaultPage, KebabMenu, StyledCard } from "../../Base/CommonUIComponents";
import { Button, Card, Col, Row, Modal, ModalBody, CustomInput } from 'reactstrap';
import { isAValidJson } from '../../Marketing/CommunicationJourney/CommonFunctions';
import ConfirmActionModal from '../../Base/ConfirmActionModal';
import { handleNotification } from '../../Base/Notification';
import profileNow from '../../../img/profileNow.png';
import { deleteAPI, getAPI, patchAPI, postAPI } from '../../Base/API';
import { FormattedMessage } from 'react-intl';
import SurveyJS from '../../Base/SurveyJS';
import { Pagination, TotalResults } from '../../Base/PaginationComponents';


class PNForms extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            pnForms: this.props.pnForms || [],
            previewModal: false,
            formDetails: {},
            deleteModal: false,
            formToDelete: null,
            selectedFolder: 0,
            folders: [
                { id: null, name: 'Home', forms: [] }
            ],
            pageSize: 21,
            pageIndex: 0
        };
    }

    componentDidMount() {
        this.getFolders();
    }

    getFolders = () => {
        this.setState({ block: true });
        
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false }, _ => this.organizeFolders(this.state.folders));
                return;
            }
            if (data) {
                let { folders } = this.state;

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.response?.length > 0) {
                    folders = folders.concat(data.response.map(folder => ({ id: folder.id, name: folder.name, forms: [] })));
                }

                this.organizeFolders(folders);
            }
            else this.organizeFolders(this.state.folders);
        }, `/api/gms/Survey/v1/survey/group?pageSize=50&pageIndex=0`);
    }

    organizeFolders = (folders) => {
        if (this.props.pnForms?.length > 0) {
            this.props.pnForms.forEach(form => {
                if (!form.surveyGroupId) {
                    folders[0].forms.push(form);
                }
                else {
                    const folderIndex = folders.findIndex(f => f.id === form.surveyGroupId);

                    if (folderIndex !== -1) {
                        folders[folderIndex].forms.push(form);
                    }
                }
            })

            this.setState({ block: false, folders });
        }

        this.setState({ block: false });
    }

    getSelectedForm = (id) => {
        this.setState({ block: true })
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ selectedForm: data.response ? data.response[0] : null, previewModal: true });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Survey/v1/survey/${id}`);
    }

    toggleModal = () => {
        this.setState(prev => ({ previewModal: !prev.previewModal }));
    }

    createForm = () => {
        this.props.history.push({ pathname: `/FormBuilder` });
    }

    toggleDeleteModal = (formId) => {
        this.setState(prev => ({ deleteModal: !prev.deleteModal, formToDelete: formId }));
    }

    deleteForm = () => {
        const { formToDelete } = this.state;

        this.setState({ block: true }, () =>
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    this.setState({ block: false, formToDelete: null }, () =>
                        handleNotification(data, <FormattedMessage id="ProfileNowMenu.FormDeleted" />, <FormattedMessage id="generic.success" />)
                    );
                }
                this.setState({ error: errorMessage, deleteModal: false, formToDelete: null }, () => {
                    const { folders } = this.state;

                    var groupToRemove = folders.findIndex(folder => folder.forms.some(el => el.id === formToDelete));

                    folders[groupToRemove].forms = folders[groupToRemove].forms.filter(f => f.id !== formToDelete);

                    this.setState({ folders, block: false }, _ => this.props.updatePnForms({ id: formToDelete }, true));
                });
            }, `/api/gms/Survey/v1/survey/${formToDelete}/delete`)
        );
    }

    goToMapping = (id, name) => {
        this.props.history.push({ pathname: `/MapPNForm?form=${id}`, state: { surveyName: name } });
        this.props.history.go();
    }

    goToAnswers = (id, name, isProfileCreation) => {
        this.props.history.push({ pathname: `/PNFormAnswers/${id}`, state: { surveyName: name, isProfileCreation: isProfileCreation } });
    }

    editForm = (id) => {
        this.props.history.push({ pathname: `/FormBuilder/${id}` });
    }

    //#region folders
    newFolder = () => {
        const { folders } = this.state;

        folders.push({ name: this.props.intl.formatMessage({ id: 'stripo.newFolder' }), forms: [] });

        this.setState({ folders, focusedFolder: (folders.length - 1), selectedFolder: (folders.length - 1), folderNameChanged: true });
    }

    editFolder = (index) => {
        this.setState({ focusedFolder: index });
    }

    handleFolderName = (e, index) => {
        const { value } = e.target;
        const { folders } = this.state;

        folders[index].name = value;

        this.setState({ folders, folderNameChanged: true })
    }

    selectFolder = (index) => {
        this.setState({ selectedFolder: index });
    }

    saveFolder = (index) => {
        if (this.state.folderNameChanged) {
            this.setState({ block: true }, () => {
                const { folders } = this.state;
                const updatedFolder = {
                    id: folders[index].id,
                    name: folders[index].name,
                }

                postAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];

                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data.errors && data.errors.length > 0) {
                            handleNotification(data);
                            this.setState({ error: errorMessage, block: false });
                        }
                        else if (data.response?.length > 0) {
                            folders[index].id = data.response[0].id;
                            folders[index].name = data.response[0].name;

                            this.setState({ folders, focusedFolder: null, error: errorMessage, block: false, folderNameChanged: false })
                        }
                    }
                    else this.setState({ error: errorMessage, block: false });
                }, `/api/gms/Survey/v1/survey/group`, updatedFolder)
            });
        }
        else {
            this.setState({ focusedFolder: null, folderNameChanged: false });
        }
    }

    deleteFolder = (folderId) => {
        this.setState({ block: true }, () =>
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                };
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                    }
                    else if (data.response?.length > 0) {
                        let { folders } = this.state;
                        handleNotification(data, <FormattedMessage id="stripo.successfulyDeleted" />, <FormattedMessage id="generic.success" />);

                        folders = folders.filter(el => el.id !== folderId);

                        this.setState({ folders, selectedFolder: 0, error: errorMessage, deleteModal: false, block: false })
                    }
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/Survey/v1/survey/group/${folderId}`)
        );
    }
    
    onDragStart = (e, form) => {
        e.dataTransfer.setData("form", JSON.stringify(form))
        this.setState({ dragging: true });
    }

    onDragEnd = (e) => {
        e.preventDefault();
        this.setState({ dragging: false, hoverFolder: null });
    }

    onDragOver = (e, folderIndex) => {
        e.preventDefault();
        this.setState({ hoverFolder: folderIndex });
    }

    changeFolder = (e, groupId) => {
        e.preventDefault();
        const { selectedFolder, folders } = this.state;

        const form = JSON.parse(e.dataTransfer.getData('form'));

        if (form && folders[selectedFolder].id !== groupId) {
            const param = groupId ? `?groupId=${groupId}` : '';

            this.setState({ dragging: false, overFolder: null, block: true }, () =>
                patchAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];

                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false, editTemplate: false, });
                        return;
                    }
                    if (data) {
                        if (data.errors && data.errors.length > 0) {
                            handleNotification(data);
                        };
                        if (data.response?.length > 0) {
                            var groupToRemove = folders.findIndex(f => f.id === form.surveyGroupId);
                            var groupToAdd = folders.findIndex(f => f.id === groupId);

                            folders[groupToRemove].forms = folders[groupToRemove].forms.filter(f => f.id !== form.id);

                            form.surveyGroupId = groupId;
                            folders[groupToAdd].forms.push(form);

                            this.props.updatePnForms(form, false)
                        }
                        this.setState({ folders, block: false });
                    };
                }, `/api/gms/Survey/v1/survey/${form.id}/group` + param)
            );
        }
        else this.setState({ dragging: false });
    }

    handlePagination = (value) => {
        this.setState({
            pageIndex: this.state.pageIndex + value
        });
    }

    //#endregion

    render() {
        const { block, error, selectedForm, previewModal, deleteModal, folders, focusedFolder, selectedFolder, hoverFolder, pageIndex, pageSize } = this.state;

        const forms = folders[selectedFolder].forms?.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);

        return (
            <StyledCard block={block} error={error} >
                <Row>
                    <Col className="d-flex align-items-center">
                        <h5>
                            <img className='mr-2' alt="" src={profileNow} height="18px" />
                            <FormattedMessage id="ProfileNowMenu.Title" />
                        </h5>
                    </Col>
                    <Col>
                        <Button onClick={this.createForm} className="btn btn-sm btn-host float-right">
                            <i className="fas fa-plus"></i>
                        </Button>
                    </Col>
                </Row>

                <Folders
                    folders={folders}
                    focusedFolder={focusedFolder}
                    selectedFolder={selectedFolder}
                    hoverFolder={hoverFolder}
                    newFolder={this.newFolder}
                    selectFolder={this.selectFolder}
                    onDragOver={this.onDragOver}
                    changeFolder={this.changeFolder}
                    handleFolderName={this.handleFolderName}
                    saveFolder={this.saveFolder}
                    deleteFolder={this.deleteFolder}
                    editFolder={this.editFolder}
                />

                {forms?.length > 0 ?
                    <>
                        <Row>
                            {forms.map((form, idx) =>
                                <Col className={`h-100 col-4 mb-3 ${(idx % 3) === 1 ? 'px-0' : ''}`} key={idx}>
                                    <Card className="border-0 shadow d-flex flex-column justify-content-center" style={{ height: '55px', padding: '14px' }} draggable onDragStart={(event) => this.onDragStart(event, form)} onDragEnd={this.onDragEnd}>
                                        <Row>
                                            <Col className='col-8'>
                                                <div>
                                                    <span className="mb-0 mr-2 text-truncate" style={{ fontSize: '1.15em', fontWeight: 'bold' }}>{form.name}</span>
                                                </div>
                                            </Col>
                                            <Col className='d-flex align-items-center justify-content-end col-4'>
                                                <div className='mr-2' style={{ fontSize: '0.9em' }}>
                                                    {form.type === 'LeadForm' ?
                                                        <>
                                                            <i className="fas fa-bullseye mr-2 text-muted" id={`LeadForm${idx}`} />
                                                            <CoolTooltip target={`LeadForm${idx}`} placement="bottom">
                                                                <FormattedMessage id="ProfileNowMenu.LeadForm" />
                                                            </CoolTooltip>
                                                        </>
                                                        : ''}
                                                    {form.type === 'DealSatisfactionForm' ?
                                                        <>
                                                            <i className="fas fa-briefcase mr-2 text-muted" id={`DealSatisfactionForm${idx}`} />
                                                            <CoolTooltip target={`DealSatisfactionForm${idx}`} placement="bottom">
                                                                <FormattedMessage id="ProfileNowMenu.DealSatisfaction" />
                                                            </CoolTooltip>
                                                        </>
                                                        : ''}
                                                    {form.isMapped ?
                                                        <>
                                                            <i className="fas fa-random mr-2 text-muted" id={`isMapped${idx}`} />
                                                            <CoolTooltip target={`isMapped${idx}`} placement="bottom">
                                                                <FormattedMessage id="ProfileNowMenu.Mapped" />
                                                            </CoolTooltip>
                                                        </>
                                                        : ''}
                                                    {form.anonymous ?
                                                        <>
                                                            <i className="fas fa-user-secret mr-2 text-muted" id={`anonymous${idx}`} />
                                                            <CoolTooltip target={`anonymous${idx}`} placement="bottom">
                                                                <FormattedMessage id="ProfileNowMenu.Anonymous" />
                                                            </CoolTooltip>
                                                        </>
                                                        : ''}
                                                    {form.createProfile ?
                                                        <>
                                                            <i className="fas fa-user-plus text-muted" id={`createProfile${idx}`} />
                                                            <CoolTooltip target={`createProfile${idx}`} placement="bottom">
                                                                <FormattedMessage id="ProfileNowMenu.CreatesUsers" />
                                                            </CoolTooltip>
                                                        </>
                                                        : ''}
                                                </div>
                                                <div>
                                                    <KebabMenu
                                                        deleteFunction={_ => this.toggleDeleteModal(form.id)}
                                                        editFunction={(form.anonymous && form.createProfile) || form.type === 'LeadForm' || form.type === 'DealSatisfactionForm' ? _ => this.editForm(form.id) : null}
                                                        previewFunction={_ => this.getSelectedForm(form.id)}
                                                        extraFields={
                                                            form.type !== 'LeadForm' && form.type !== 'DealSatisfactionForm' && !(form.anonymous && form.createProfile) ?
                                                                [{
                                                                    function: _ => this.goToAnswers(form.id, form.name, form.createProfile),
                                                                    icon: "far fa-comment-dots mr-2",
                                                                    text: "ProfileNowMenu.Replies"
                                                                },
                                                                {
                                                                    function: _ => this.goToMapping(form.id, form.name),
                                                                    icon: "fas fa-random mr-2",
                                                                    text: "ProfileNowMenu.Mapping"
                                                                }]
                                                                :
                                                                [{
                                                                    function: _ => this.goToAnswers(form.id, form.name, form.createProfile),
                                                                    icon: "far fa-comment-dots mr-2",
                                                                    text: "ProfileNowMenu.Replies"
                                                                }]
                                                        }
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            )}
                        </Row>

                        {folders[selectedFolder].forms?.length > pageSize && !block ?
                            <Row className="my-3">
                                <Col>
                                    <TotalResults
                                        end={pageIndex * pageSize + pageSize}
                                        start={pageIndex * pageSize + 1}
                                        total={folders[selectedFolder].forms?.length}
                                    />
                                </Col>

                                <Col className="text-right">
                                    <Pagination
                                        isPrevDisabled={pageIndex === 0}
                                        isNextDisabled={folders[selectedFolder].forms?.length <= ((pageIndex + 1) * pageSize)}
                                        currentPage={pageIndex + 1}
                                        handlePrevButton={() => this.handlePagination(-1)}
                                        handleNextButton={() => this.handlePagination(1)}
                                    />
                                </Col>
                            </Row>
                            : ''}
                    </>

                    : <DefaultPage icon="fas fa-file-signature" text="ConfigPNForms.NoFormsInFolder"/> }


                <Modal isOpen={previewModal} toggle={this.toggleModal} fade={false} size={"lg"} style={{ minWidth: '80vw' }}>
                    <ModalBody>
                        {selectedForm?.rawForm && isAValidJson(selectedForm.rawForm) ?
                            <SurveyJS
                                formId={selectedForm.id}
                            />
                            : <div className="text-center"> <FormattedMessage id="ConfigPNForms.SurveyUnavailable" /> </div>}
                    </ModalBody>
                </Modal>

                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={_ => this.toggleDeleteModal()}
                        actionFunction={() => this.deleteForm()}
                        text={<FormattedMessage id="ConfigPNForms.DeleteFormQuestion" />}
                        block={block}
                    />
                    : ''}
            </StyledCard>
        );
    }
}
export default injectIntl(PNForms)


const Folders = ({ folders, focusedFolder, selectedFolder, hoverFolder, newFolder, selectFolder, onDragOver, changeFolder, handleFolderName, saveFolder, deleteFolder, editFolder }) => {
    return (
        <div className="d-flex mb-4 mt-3 pt-2">
            <Card className={"shadow pointer stripoFolder mb-3"} body onClick={_ => { if (!focusedFolder) newFolder() }}>
                <span> <i className="fas fa-folder-plus mr-2 fa-lg" /> <FormattedMessage id="stripo.newFolder" /></span>
            </Card>

            {folders.map((folder, index) => {
                const isFocused = focusedFolder === index;
                const isSelected = selectedFolder === index;
                const isHover = hoverFolder === index;

                return (
                    <Card className={"shadow pointer stripoFolder mb-3 " + (isFocused || isSelected ? ' bg-host-light ' : '')} body onClick={_ => selectFolder(index)} key={index}
                        style={{ border: (isHover && !isSelected) ? '2px dashed grey' : '' }}
                        //onBlur={_ => { if (isFocused) saveFolder(index) }}
                        onDragOver={(e) => onDragOver(e, index)} onDrop={(e) => { if (!isSelected) changeFolder(e, folder.id) }}
                    >
                        <div className="d-flex align-items-center">
                            {index === 0 ?
                                <i className="fas fa-home mr-2 fa-lg" />
                                : isSelected || isHover ?
                                    <i className="far fa-folder-open mr-2 fa-lg" />
                                    :
                                    <i className="far fa-folder mr-2 fa-lg" />
                            }

                            {isFocused ?
                                <>
                                    <CustomInput
                                        type="text"
                                        id={`nameInput${index}`}
                                        className="cleanInput shadow"
                                        style={{ width: '110px', backgroundColor: 'white', paddingLeft: '10px' }}
                                        value={folder.name}
                                        onChange={(e) => handleFolderName(e, index)}
                                        maxLength={50}
                                        autoFocus
                                    />

                                    <Button className="btn btn-host btn-sm float-right ml-2" onClick={_ => saveFolder(index)}  >
                                        <i className="fas fa-save" />
                                    </Button>
                                </>
                                :
                                <span> {folder.name} </span>
                            }

                            {index && isSelected && !isFocused ?
                                <div className="ml-3">
                                    <KebabMenu
                                        deleteFunction={_ => deleteFolder(folder.id)}
                                        editFunction={_ => editFolder(index)}
                                    />
                                </div>
                                : ''}
                        </div>
                    </Card>
                )
            })}
        </div>
    )
}