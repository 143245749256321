import React, { Component } from 'react';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import moment from 'moment-timezone';
import { getAPI } from '../Base/API';
import SalesProcessExecutiveReportMonthly from './SalesProcessExecutiveReportMonthly';
import SalesProcessExecutiveReportYearly from './SalesProcessExecutiveReportYearly';
import SalesDealList from './SalesDealList';
import SalesProcessResources from './SalesProcessResources';
import SalesProcessGrid from './SalesProcessGrid/SalesProcessGrid';
import { withRouter } from 'react-router-dom';

class SalesProcessExecutiveReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            block: true,
            year: moment().format('YYYY'),
            hotelOptions: [],
            yearOptions: [
                { label:'2023', value:'2023' },
                { label:'2024', value:'2024' },
                { label:'2025', value:'2025' },
                { label:'2026', value:'2026' },
                { label:'2027', value:'2027' },
                { label:'2028', value:'2028' },
                { label:'2029', value:'2029' }
            ],
            colors: ["#0867fa", "#59B2C2", "#48d7ea", "#6495ED","#afcace","#FA339A","#F16D84","#FFBE2D","#FF8A00","#62a140","#66E27F","#8EE3C8","#D7EAFF","#405FC1"],
            yearlyReport: null,
            selectedHotel: null,
            activeTab: "SalesProcessExecutiveReportYearly",
            selectedStageTypeId: null,
            selectedMonth: null,
            isFromMonthly: false,
            isSpaces: false,
            startDate: moment().startOf('year'),
            endDate: moment().endOf('year'),
            segmentList: [],
            subSegmentList: []
        };
    }

    componentDidMount(){
        this.getHotelList();
        this.getReferenceData();
        
        const urlParams = new URLSearchParams(window.location.search);
        const tab = urlParams.get('tab') || 'SalesProcessExecutiveReportYearly';
        const selectedHotel = urlParams.get('hotel');

        this.setState({ activeTab: tab, selectedHotel });
    }
    
    getHotelList = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                const hotelOptions = [];

                data.response.map((item) => {
                    const obj = { value: item.hotelId, label: item.name2 };
                    const groupIndex = hotelOptions.findIndex(c => c.groupId === item.hotelGroupId);
        
                    if (groupIndex !== -1) {
                        hotelOptions[groupIndex].options.push(obj)
                    }
                    else {
                        hotelOptions.push({ label: item.hotelGroupName, groupId: item.hotelGroupId, options: [obj] });
                    }
                });

                this.setState({ hotelOptions, block: false });

                return;
            }
            this.setState({ block: false });
        }, '/api/gms/Hotel/v1/hotel/list?onlyFromHotelGroup=true');
    }
    
    getReferenceData = (e) => {
        if (e) e.preventDefault();
        this.setState({ block: true });
        
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.response?.length > 0) {
                    let segmentList = [], subSegmentList = [];

                    data.response.forEach(el => {
                        const obj = {
                            ...el,
                            value: el.id,
                            label: (<span> {el.description} <b className="ml-1">({el.code})</b> </span>),
                        }

                        if (el.type === "Segments" && (el.code == "GRPC" || el.code == "GRPL") && !segmentList.some(x => x.code == el.code)) {
                            segmentList.push(obj);
                        } else if (el.type === "SubSegments" && el.segments.some(segment => segment === "GRPC" || segment === "GRPL") && !subSegmentList.some(x => x.code == el.code)) {
                            subSegmentList.push(obj);
                        }
                    })

                    this.setState({ segmentList, subSegmentList })
                }
            }
        }, `/api/gms/Hotel/v1/ReferenceData?type=Segments&type=SubSegments`);
    }

    updateYearlyReport = (yearlyReport) => {
        this.setState({ yearlyReport }, this.handleChangeYearBySearch);
    }

    toggleTab = (tab, selectedHotel, selectedStageTypeId, selectedMonth, isFromMonthly, isSpaces, salesStatus, isQuarter, selectedSource, cancelReasonId) => {
        const { startDate, endDate } = this.state;
        const hotelList = selectedHotel ? Array.isArray(selectedHotel) ? selectedHotel : [selectedHotel] : null;
        const statusList = salesStatus ? Array.isArray(salesStatus) ? salesStatus : [salesStatus] : null;
        
        if (tab !== this.state.activeTab) {
            const urlParams = new URLSearchParams(window.location.search);

            if (tab === 'SalesProcessExecutiveReportYearly') {
                urlParams.delete('tab');
                urlParams.delete('hotel');
                urlParams.delete('salesStatus');
                urlParams.delete('stageTypeId');
                urlParams.delete('busStartDate');
                urlParams.delete('busEndDate');
                urlParams.delete('salesSourceId');
                urlParams.delete('cancelReason');
            }
            else {
                urlParams.set('tab', tab);
                
                if (hotelList?.length > 0){
                    urlParams.set('hotel', hotelList[0]);
                    urlParams.set('hotelIds', JSON.stringify(hotelList));
                }
                else{
                    urlParams.delete('hotel');
                }

                if (statusList?.length > 0){
                    urlParams.set('salesStatus', statusList.toString().replaceAll(',', ';'));
                }
                else{
                    urlParams.delete('salesStatus');
                }

                if (selectedStageTypeId){
                    urlParams.set('stageTypeId', selectedStageTypeId);
                }
                else{
                    urlParams.delete('stageTypeId');
                }

                if (selectedSource){
                    urlParams.set('salesSourceId', selectedSource);
                }
                else{
                    urlParams.delete('salesSourceId');
                }

                if (startDate) {
                    urlParams.set('busStartDate', startDate.format('YYYY-MM-DD'));
                }
                else{
                    urlParams.delete('busStartDate');
                }

                if (endDate) {
                    urlParams.set('busEndDate', endDate.format('YYYY-MM-DD'));
                }
                else {
                    urlParams.delete('busEndDate');
                }

                if (cancelReasonId) 
                    urlParams.set('cancelReason', cancelReasonId);
                else 
                    urlParams.delete('cancelReason');          
            }

            this.props.history.replace({ search: urlParams.toString() + `${window.location.hash}` });

            this.setState({ activeTab: tab, selectedHotel: hotelList, selectedStageTypeId, selectedMonth, isFromMonthly, isSpaces, salesStatus: statusList, isQuarter, selectedSource });
        }
    }

    handleChangeYear = (combo) => {
        this.setState({ year: combo?.value, startDate: this.state.startDate.year(combo?.value), endDate: this.state.endDate.year(combo?.value) });
    }

    handleDates = (startDate, endDate) => {
        this.setState({
            startDate, endDate
        })
    }

    handleChangeYearBySearch = () => {
        this.setState({
            year: this.state.startDate?.format("YYYY")
        })
    }

    render() {
        const { error, block, activeTab, year, hotelOptions, yearOptions, colors, yearlyReport, selectedHotel, selectedStageTypeId, selectedMonth, isFromMonthly, isSpaces,
            salesStatus, isQuarter, startDate, endDate, selectedSource } = this.state;
        const { icon, selectDealDetail, salesUsers, resourcesIcon, dealListIcon, salesPipelines, salesSources, dealList, getDealList, salesSourcesTypes, hotelList, renderActivityHelperMsg, totalDeals } = this.props;

        return (
            <div className="border-0 h-100 pb-2">
                <ErrorAlert error={error}/>
                <BlockUi tag="div" blocking={block}>
                    {activeTab === "SalesProcessExecutiveReportYearly" ?
                        <SalesProcessExecutiveReportYearly
                            year={year}
                            hotelOptions={hotelOptions}
                            handleDates={this.handleDates}
                            updateYearlyReport={this.updateYearlyReport}
                            yearOptions={yearOptions}
                            colors={colors}
                            yearlyReport={yearlyReport}
                            icon={icon}
                            selectedHotel={selectedHotel}
                            toggleTab={this.toggleTab}
                            startDate={startDate}
                            endDate={endDate}
                            segmentList={this.state.segmentList}
                            subSegmentList={this.state.subSegmentList}
                        />
                        : activeTab === "SalesProcessExecutiveReportMonthly" ?
                        <SalesProcessExecutiveReportMonthly
                            handleChangeYear={this.handleChangeYear}
                            year={year}
                            hotelOptions={hotelOptions}
                            yearOptions={yearOptions}
                            colors={colors}
                            yearlyReport={yearlyReport}
                            selectedHotel={selectedHotel}
                            toggleTab={this.toggleTab}
                            segmentList={selectedHotel ? this.state.segmentList.filter(({ hotelId }) => selectedHotel.includes(hotelId)): []}
                            subSegmentList={selectedHotel ? this.state.subSegmentList.filter(({ hotelId }) => selectedHotel.includes(hotelId)) : []}
                        />
                    : activeTab === "SalesDealList" ?
                        <SalesDealList
                            selectDealDetail={selectDealDetail}
                            icon={dealListIcon}
                            salesUsers={salesUsers}
                            salesPipelines={salesPipelines}
                            salesSources={salesSources}
                            dealList={dealList}
                            getDealList={getDealList}
                            salesSourcesTypes={salesSourcesTypes}
                            isChild={true}
                            selectedHotel={selectedHotel}
                            selectedStageTypeId={selectedStageTypeId}
                            year={year}
                            toggleTab={this.toggleTab}
                            selectedSource={selectedSource}
                            salesStatus={salesStatus}
                            selectedMonth={selectedMonth}
                            isFromMonthly={isFromMonthly}
                            isQuarter={isQuarter}
                            startDate={startDate}
                            endDate={endDate}
                            fromExecutiveReport={true}
                            hotelList={hotelList}
                            renderActivityHelperMsg={renderActivityHelperMsg}
                            totalDeals={totalDeals}
                        />
                    : activeTab === "SalesProcessResources" ?
                        <SalesProcessGrid
                            history={this.props.history}
                            toggleTab={this.toggleTab}
                            salesUsers={salesUsers}
                            selectDealDetail={selectDealDetail}
                            isSpaces={isSpaces}
                            icon={resourcesIcon}
                            isChild={true}
                            selectedHotel={selectedHotel}
                            selectedStageTypeId={selectedStageTypeId}
                            year={year}
                            selectedMonth={selectedMonth}
                            isFromMonthly={isFromMonthly}
                        />
                    :''}
                </BlockUi>
            </div>
        );
    }
}

export default withRouter(SalesProcessExecutiveReport);