import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Button, Card, Modal, ModalBody } from 'reactstrap';
import { ActiveInactiveStatusBadge, BlankCard } from "../../Base/CommonUIComponents";
import { handleNotification } from "../../Base/Notification";
import { Pagination } from "../../Base/PaginationComponents";
import CustomSelect from "../../Base/CustomSelect";
import { getAPI } from '../../Base/API';
import { CampaignStats } from '../Common/MarketingFunctions';
import moment from 'moment';
import { getSenderChannelOptions } from '../../Base/ReferenceDataFunctions';

class SelectCampaignModal extends Component {

    constructor(props) {
        super(props);
        this.state = {  
            block: false,
            totalItems: 0,
            pageIndex: 0,
            pageSize: 9,
            typeOptions: this.props.typeOptions, 
            linkedToType: null,
            showEvents: false,
            selectedCampaign: this.props.selectedCampaign || null,
            hasEgoi: false
        };
    }

    componentDidMount() {
        window.setTimeout(
            () => {
                this.setState({
                    hasEgoi: global.modules && global.modules.some(m => m === 'Egoi')
                });
            },
            global.modules ? 0 : 2500
        );
        this.getCampaigns();
    }

    getCampaigns = () => {
        this.setState({ block: true });

        var params = '';
        if (this.props.campaign && this.props.campaign.id) params += `&excludeCampaign=${this.props.campaign.id}`;
        if (this.state.linkedToType) {
            params += `&type=${this.state.linkedToType}`
        } else this.props.typeOptions.map(el => { params += `&type=${el.value}` });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({
                    campaigns: data.response || [],
                    totalItems: data.totalItems ? data.totalItems : 0
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/marketingsend/list?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}` + params);
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getCampaigns());
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null,
            showEvents: combo && combo.value === 'Custom' ? true : false
        });
    };

    selectCampaign = (campaign) => {
        this.setState({ selectedCampaign: campaign })
    }

    doSearch = () => {
        this.setState({
            pageIndex: 0
        }, () => this.getCampaigns())
    }

    render() {
        const { modal, campaign } = this.props;
        const { campaigns, pageIndex, pageSize, totalItems, typeOptions, linkedToType, selectedCampaign, hasEgoi } = this.state;


        return (
            <Modal isOpen={modal} fade={false} className="modal-xl" >
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={this.state.block} error={this.state.error}>
                        <Row>
                            <Col>
                                <h5> <FormattedMessage id="CustomStep3Campaigns.SelectCampaign" /></h5>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn btn-sm btn-host mb-3" onClick={() => { this.props.selectCampaign(selectedCampaign); this.props.toggleModal() }} disabled={!selectedCampaign} >
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={2}>
                                <CustomSelect
                                    isSearchable
                                    placeholder={<FormattedMessage id="CampaignDetails.CampaignType" />}
                                    options={typeOptions}
                                    onChange={(combo) => this.handleSelect("linkedToType", combo)}
                                    value={linkedToType ? typeOptions.find((op) => op.value === linkedToType) : ''}
                                />
                            </Col>
                            <Col />
                            <Col className="text-right col-2">
                                <Button className="btn btn-sm btn-host mt-2" onClick={this.getCampaigns}>
                                    <i className="icon-icon-search"></i>
                                </Button>
                            </Col>
                        </Row>

                        {campaigns ?
                            <>
                                <Row className="my-3">
                                    {campaigns.map((camp, key) =>
                                        <Col key={key} className="col-4 mb-3">
                                            <Card className="border-0 shadow p-3 h-100 pointer" onClick={() => this.selectCampaign(camp)}
                                                style={{ backgroundColor: (selectedCampaign && selectedCampaign.id === camp.id ? '#d3eaff' : 'white') }}>
                                                <Row className="mb-2">
                                                    <Col>
                                                        <h5><span className="bg-light-grey circle-sm mr-2">
                                                            {campaign.senderChannel === "Email" ? <i className="icon-icon-mail px-1" /> : <i className="icon-icon-sms px-1" />}
                                                        </span><b>{camp.name}</b> </h5>
                                                    </Col>
                                                    <Col className="text-right col-2">
                                                        <ActiveInactiveStatusBadge status={camp.active} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col> <b>Notify at </b> {camp.scheduleDay ? moment(camp.scheduleDay).format("YYYY-MM-DD HH:mm") : ''} </Col>
                                                    <Col className="text-right col-4"> <FormattedMessage id={`Campaigns.${camp.marketingType}`}/> </Col>
                                                </Row>
                                                <div> <b>Sender</b> {camp.sender} </div>

                                                <hr />

                                                <div className="d-flex align-items-center justify-content-center" style={{ height: '72px' }}>
                                                    {camp.marketingSendKpis && moment(moment(camp.scheduleDay).format("YYYY-MM-DD HH:mm")).isBefore(moment().format("YYYY-MM-DD HH:mm")) ?
                                                        <CampaignStats
                                                            marketingSendKpis={camp.marketingSendKpis}
                                                            isSmsEgoi={hasEgoi && camp.senderChannel === getSenderChannelOptions()[1].value}
                                                        />
                                                        :
                                                        <div className="text-center"> <FormattedMessage id="Campaigns.NoStatsAvailable" /> </div>}
                                                </div>
                                            </Card>
                                        </Col>
                                    )}
                                </Row>

                                {totalItems !== 1 &&
                                    <div className="text-right mr-3 w-100">
                                        <Pagination
                                            isPrevDisabled={pageIndex === 0}
                                            isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                            currentPage={pageIndex + 1}
                                            handlePrevButton={() => this.handlePagination(-1)}
                                            handleNextButton={() => this.handlePagination(1)}
                                        />
                                    </div>
                                }
                            </>
                            : ''}
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(SelectCampaignModal)