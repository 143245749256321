import React, { Component } from 'react';
import { ErrorAlert } from '../../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import SalesProcessDealsRooms from './SalesProcessDealsRooms';
import { Button, Card, Col, CustomInput, Row } from 'reactstrap';
import moment from 'moment-timezone';
import { handleNotification } from '../../Base/Notification';
import { getAPI } from '../../Base/API';
import { SelectCustomType } from '../SalesProcessFunctions';
import { CommonHelper, FilterButton, SelectHotel } from '../../Base/CommonUIComponents';
import CustomSelect from '../../Base/CustomSelect';
import CustomTwoMonthDateRangePicker from '../../Base/CustomTwoMonthDateRangePicker';

class SalesProcessGrid extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            block: true,
            isDataFromProps: this.props.year ? true : false,
            startDate: moment().set('year', (this.props.year ?? moment().format('YYYY'))).set('month', (this.props.selectedMonth ? (parseInt(this.props.selectedMonth) - 1) : (parseInt(moment().format('MM')) - 1))).startOf('month'),
            endDate: (moment().set('year', (this.props.year ?? moment().format('YYYY'))).set('month', (this.props.selectedMonth ? (parseInt(this.props.selectedMonth) - 1) : (parseInt(moment().format('MM')) - 1)))),
            hotelList: [],
            statusList: ["Effective", "Finished", "WaitingHotel", "WaitingClient"],
            hotelIds: this.props.selectedHotel ?? [],
            stageTypeList: [],
            staffIds: [],
            submittedEndDate: null,
            submittedStartDate: null,
            submittedHotelIds: [],
            hideStageType: false,
            hideOption: false,
            hideNoInv: false,
            unsubmittedHideStageType: false,
            unsubmittedHideOption: false,
            unsubmittedHideNoInv: false,
            spaceList: [],
            segmentList: [],
            subSegmentList: [],
            segmentOptions: [],
            segmentData: [],
            subSegmentOptions: [],
            segmentOptionsFilteredBySubSegments: [],
            subSegmentData: [],
            subSegmentOptionsUnfiltered: [],
            subSegmentOptionsFilteredBySegments: [],
            segmentOptionsUnfiltered: [],
            hideCancelled: true,
            focused: null,
            colorsByStatus: [ // Fixed Status
                { status: "STANDARD", color: "#00B764", order: 3 },
                { status: "CI", color: "#00FFFF", order: 6 },
                { status: "CO", color: "#FF0000", order: 7 },
                { status: "OPTION", color: "#004A7F", order: 9 },
                { status: "PENDING", color: "#FFE97F", order: 10 },
                { status: "WAITLIST", color: "#004A7F", order: 13 },
                { status: "OOI", color: "#FFFFFF", order: 18 },
                { status: "OOO", color: "#FFFFFF", order: 19 },
                { status: "CLX", color: "#FF0000", order: 20 },
                { status: "NOSHOW", color: "#7F3300", order: 21 },
                { status: "REFUSED", color: "#808080", order: 22 },
                { status: "REC", color: "#FF0000", order: 25 }
            ],
            dealTags: [],
            statusData: [],
            statusOptions: [],
            statusOptionsUnfiltered: [],
            pmsStatusList: []
        };
    }

    componentDidMount() {
        const currentUser = global.salesProcessUsers?.find(el => el.isCurrentUser);
        const userHotelIds = global.hotelList.filter(hotel => currentUser?.hotelIds?.some(hotelId => hotelId === hotel.value));
        if (userHotelIds && userHotelIds.length === 1) {
            const hotelIds = [userHotelIds[0].value];
            this.setState({ hotelIds })
        }

        this.getSpaces();
    }

    getUrlParams = () => {
        let search = new URLSearchParams(window.location.search);

        const startDate = (search.get('startDate') && moment(search.get('startDate'))) ?? null;
        const endDate = (search.get('endDate') && moment(search.get('endDate'))) ?? null;
        const statusList = (search.get('statusList') && JSON.parse(search.get('statusList'))) ?? [];
        const hotelIds = (search.get('hotelIds') && JSON.parse(search.get('hotelIds'))) ?? [];
        const stageTypeList = (search.get('stageTypeList') && JSON.parse(search.get('stageTypeList'))) ?? [];
        const staffIds = (search.get('staffIds') && JSON.parse(search.get('staffIds'))) ?? [];
        const segmentList = (search.get('segmentList') && JSON.parse(search.get('segmentList'))) ?? [];
        const subSegmentList = (search.get('subSegmentList') && JSON.parse(search.get('subSegmentList'))) ?? [];
        const hideStageType = search.get('hideStageType') === "true";
        const hideOption = search.get('hideOption') === "true";
        const hideNoInv = search.get('hideNoInv') === "true";
        const hideCancelled = search.get('hideCancelled') === "true";

        if(startDate && endDate){
            this.setState({
                hideStageType,
                hideOption,
                hideNoInv,
                hideCancelled,
                startDate,
                endDate,
                statusList,
                hotelIds,
                stageTypeList,
                staffIds,
                segmentList,
                subSegmentList,
                unsubmittedHideStageType: hideStageType,
                unsubmittedHideOption: hideOption,
                unsubmittedHideNoInv: hideNoInv
            }, () => this.getResources()
        );
        }
        else if(this.state.isDataFromProps){
            let endDateTemp = this.state.endDate;
            if (this.props.isFromMonthly) {
                endDateTemp = this.state.endDate.endOf('month');
            }

            this.setState({ endDate: endDateTemp }, () => this.getResources()
                );
        }else{
            this.setState({ endDate: this.state.endDate.startOf('month').add(15, 'days') });
        }
    }

    getResources = () => {
        const { statusList, hotelIds, stageTypeList, staffIds, hideCancelled, segmentList, subSegmentList, startDate, endDate,
        unsubmittedHideStageType, unsubmittedHideOption, unsubmittedHideNoInv, dealTags, pmsStatusList } = this.state;

        let qs = `startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}&hideCancelled=${hideCancelled}`;

        if(statusList?.length){
            statusList.forEach(s => {
                qs += `&statusList=${s}`;
            });
        }

        if(hotelIds?.length){
            hotelIds.forEach(h => {
                qs += `&hotelIds=${h}`;
            });
        }

        if(stageTypeList?.length){
            stageTypeList.forEach(s => {
                qs += `&stageTypeList=${s}`;
            });
        }

        if(staffIds && staffIds.length > 0){
            staffIds.forEach(s => {
                qs += `&staffIds=${s}`;
            });
        }

        if(subSegmentList && subSegmentList.length > 0){
            subSegmentList.forEach(s => {
                qs += `&subSegmentList=${s}`;
            });
        }

        if(segmentList && segmentList.length > 0){
            segmentList.forEach(s => {
                qs += `&segmentList=${s}`;
            });
        }
        
        if(pmsStatusList && pmsStatusList.length > 0){
            pmsStatusList.forEach(s => {
                qs += `&pmsStatusList=${s}`;
            });
        }
        
        if(dealTags && dealTags.length > 0){
            dealTags.forEach(s => {
                qs += `&dealTags=${s.id}`;
            });
        }

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({
                    hotelList: data.data??[],
                    block: false,
                    submittedEndDate: endDate,
                    submittedStartDate: startDate,
                    submittedHotelIds: [...hotelIds],
                    hideStageType: unsubmittedHideStageType,
                    hideOption: unsubmittedHideOption,
                    hideNoInv: unsubmittedHideNoInv
                }, () => this.setQS());

                return;
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/resourcegrid?${qs}`);
    }

    setQS = () => {
        if(window.location.hash === "#SalesProcessGrid"){
            const { startDate, endDate, statusList, hotelIds, stageTypeList, staffIds, hideStageType, hideOption, hideNoInv, hideCancelled } = this.state;
            
            const search = new URLSearchParams(window.location.search);

            search.set('startDate', startDate.format('YYYY-MM-DD'));
            search.set('endDate', endDate.format('YYYY-MM-DD'));
            search.set('hotelIds', JSON.stringify(hotelIds));
            
            if(statusList?.length)
                search.set('statusList', JSON.stringify(statusList));

            if(stageTypeList?.length)
                search.set('stageTypeList', JSON.stringify(stageTypeList));
            
            if(staffIds?.length)
                search.set('staffIds', JSON.stringify(staffIds));

            if(hideStageType)
                search.set('hideStageType', hideStageType);

            if(hideOption)
                search.set('hideOption', hideOption);

            if(hideNoInv)
                search.set('hideNoInv', hideNoInv);

            if(hideCancelled)
                search.set('hideCancelled', hideCancelled);
            

            const string = search.toString();

            this.props.history.push({ pathname: `/SalesProcess`, search: `?${string}` });
            window.location.hash = "#SalesProcessGrid";
        }
    }

    getSpaces = () => {
        getAPI(result => {
            const { data, error } = result;

            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data?.data) {
                this.setState({
                    block:false,
                    spaceList: data.data.sort((a, b) => a.sortOrder - b.sortOrder).map(({ code, shortName, hotelId, price, maxCapacity, id }) => ({
                        hotelId: hotelId,
                        id: id,
                        maxCapacity: maxCapacity,
                        value: code,
                        code: code,
                        price: price,
                        description: shortName,
                        label: (
                            <span>
                                {shortName}
                                <b className="ml-1">({code})</b>
                            </span>
                        )
                    }))
                }, () => {
                    this.getUrlParams();
                    this.getRefData();
                });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/SalesProcess/v1/eventspaces?isVisible=true&isSpaceCommunicant=false`)
    }

    getRefData = () => {
        const getSegment = () => {
            getAPI(result => {
                const { data, error } = result;
    
                const errorMessage = [];
    
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });
    
                    return;
                }
                if (data) {
                    const { filtered, brute } = this.refDataTreatment(data.response);
                    this.setState({
                        segmentData: data.response,
                        segmentOptions: filtered,
                        segmentOptionsUnfiltered: brute
                    }, () => getSubSegment());
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/Hotel/v1/ReferenceData?type=Segments`);
        };

        const getSubSegment = () => {
            getAPI(result => {
                const { data, error } = result;
    
                const errorMessage = [];
    
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });
    
                    return;
                }
                if (data) {
                    const { filtered, brute } = this.refDataTreatment(data.response);
                    this.setState({
                        subSegmentData: data.response,
                        subSegmentOptions: filtered,
                        subSegmentOptionsUnfiltered: brute,
                    }, () => getStatus());
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/Hotel/v1/ReferenceData?type=SubSegments`);
        };

        const getStatus = () => {
            getAPI(result => {
                const { data, error } = result;
    
                const errorMessage = [];
    
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });
    
                    return;
                }
                if (data) {
                    const { filtered, brute } = this.refDataTreatment(data.response);

                    this.setState({
                        colorsByStatus: [...this.state.colorsByStatus,
                            ...filtered.map(status => {
                                if(status.referenceDataDetails) {
                                    return({
                                        status: status.value,
                                        color: status.referenceDataDetails.color,
                                        order: status.referenceDataDetails.order && parseInt(status.referenceDataDetails.order)
                                    });
                                }
                            }).filter(a => a)
                        ],
                        statusData: data.response,
                        statusOptions: filtered,
                        statusOptionsUnfiltered: brute,
                        block: false
                    });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/Hotel/v1/ReferenceData?type=ReservationStatus`);
        };

        getSegment();
    }

    refDataTreatment = (data, hotelIdsNew) => {
        const hotelIds = hotelIdsNew ?? this.state.hotelIds;
    
        const filteredData = data
            ?.filter((d) => !d.hotelId || !hotelIds || !hotelIds.length || hotelIds.includes(d.hotelId))
            .filter((item, index, self) =>
                index === self.findIndex((t) => t.code === item.code)
            )
            .map(({ description, code, hotelId, referenceDataDetails }) => ({
                value: code,
                description,
                code,
                hotelId,
                referenceDataDetails: Array.isArray(referenceDataDetails) ? referenceDataDetails?.map(({detailJSON}) => detailJSON && JSON.parse(detailJSON))?.[0] : referenceDataDetails,
                label: (
                    <span>
                        {description}
                        <b className="ml-1">({code})</b>
                    </span>
                )
            }));
    
        const bruteData = data
            ?.filter((item, index, self) =>
                index === self.findIndex((t) => t.code === item.code)
            )
            .map(({ description, code, hotelId, referenceDataDetails }) => ({
                value: code,
                hotelId,
                description,
                code,
                referenceDataDetails: Array.isArray(referenceDataDetails) ? referenceDataDetails?.map(({detailJSON}) => detailJSON && JSON.parse(detailJSON))?.[0] : referenceDataDetails,
                label: (
                    <span>
                        {description}
                        <b className="ml-1">({code})</b>
                    </span>
                )
            }));
    
        const returnValue = {
            filtered: filteredData,
            brute: bruteData,
        };
    
        return returnValue;
    };

    handleDates = (startDate, endDate, focusedInput) => {
        this.setState({ startDate, endDate: (focusedInput === "startDate" && startDate) ? null : endDate });
    }
    
    handleStatus = (status) => {
        let { statusList } = this.state;
        if(status === "Negotiation" && (statusList.includes("WaitingHotel") || statusList.includes("WaitingClient"))){
            statusList = statusList.filter(s => s !== "WaitingHotel" && s !== "WaitingClient");
        }
        else if(statusList.includes(status)){
            statusList = statusList.filter(s => s !== status);
        }
        else{
            if(status === "Negotiation"){
                statusList.push("WaitingHotel");
                statusList.push("WaitingClient");
            }
            else{
                statusList.push(status);
            }
        }
        this.setState({ statusList });
    }
    
    handleStage = (combo) => {
        this.setState({ stageTypeList: combo ? combo?.map(cmb => cmb.value) : [] });
    }

    handleHotels = (n, combo) => {
        let { subSegmentOptions, segmentOptions, subSegmentOptionsUnfiltered, segmentOptionsUnfiltered, statusOptionsUnfiltered, statusOptions } = this.state;
        const hotelIds = combo ? combo?.map(cmb => cmb.value) : [];

        if(segmentOptionsUnfiltered?.length){
            segmentOptions = this.refDataTreatment(segmentOptionsUnfiltered, hotelIds).filtered;
        }
        if(subSegmentOptionsUnfiltered?.length){
            subSegmentOptions = this.refDataTreatment(subSegmentOptionsUnfiltered, hotelIds).filtered;
        }
        if(statusOptionsUnfiltered?.length){
            statusOptions = this.refDataTreatment(statusOptionsUnfiltered, hotelIds).filtered;
        }

        this.setState({ subSegmentOptions, segmentOptions, hotelIds, statusOptions });
    }

    handleMultiCombo = (combo, name) => {
        const { 
            segmentList, 
            subSegmentList, 
            segmentData,
            subSegmentData, 
            segmentOptions, 
            subSegmentOptions,
        } = this.state;

        if(name === 'segmentList'){
            if (combo) {
                const subSegments = combo.map(cmb => segmentData.find(s => s.code === cmb.value)?.subSegments)?.flat() || [];

                const uniqueSubSegmentOptions = Array.from(
                    new Set(subSegmentOptions?.filter(s => subSegments.includes(s.value)))
                );
        
                this.setState({ [name]: combo.map(cmb => cmb.value) || [], subSegmentOptionsFilteredBySegments: uniqueSubSegmentOptions });
                return;
            }else if(subSegmentList?.length > 0){
                this.setState(prevstate =>  ({
                    subSegmentOptionsFilteredBySegments: [],
                    segmentOptionsFilteredBySubSegments: prevstate.segmentOptionsFilteredBySubSegments,
                    [name]: combo ? combo?.map(cmb => cmb.value) : [],
                }));
                return;
            }
        }

        if(name === 'subSegmentList'){
            if (combo) {
                const segments = combo.map(cmb => subSegmentData.find(s => s.code === cmb.value)?.segments)?.flat() || [];

                const uniqueSegmentOptions = Array.from(
                    new Set(segmentOptions?.filter(s => segments.includes(s.value)))
                );
        
                this.setState({ [name]: combo.map(cmb => cmb.value) || [], segmentOptionsFilteredBySubSegments: uniqueSegmentOptions });
                return;
            }else if(segmentList?.length > 0){
                this.setState(prevstate => ({
                    segmentOptionsFilteredBySubSegments: [],
                    subSegmentOptionsFilteredBySegments: prevstate.subSegmentOptionsFilteredBySegments,
                    [name]: combo ? combo?.map(cmb => cmb.value) : [],
                }));
                return;
            }
        }

        this.setState({ [name]: combo ? combo?.map(cmb => cmb.value) : [], segmentOptionsFilteredBySubSegments: [], subSegmentOptionsFilteredBySegments: [] });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ block: true }, () => this.getResources());
    }

    toggleBool = (name) => {
        this.setState({ [name]: !this.state[name] });
    }

    renderHelperMsg = () => {
        const { colorsByStatus } = this.state;

        return (
            <div style={{ width: '350px', maxHeight: '800px' }} className='veryCoolScrollBar overflow-auto'>
                <div>
                    <div className='d-flex align-items-center justify-content-between mt-2'>
                        <div style={{ width: '20%' }} className="d-flex align-items-center justify-content-center">
                            <div clas style={{
                                width: '50px',
                                borderTop: '2px dotted #000a9c'
                            }} />
                        </div>
                        <div style={{ width: '80%' }} className='pl-3'>
                            <FormattedMessage id="SalesProcess.IsHolidayOrNote" />
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between mt-2'>
                        <div style={{ width: '20%' }} className="d-flex align-items-center justify-content-center">
                            <div style={{
                                width: '50px',
                                borderTop: '2px dotted #369c00'
                            }} />
                        </div>
                        <div style={{ width: '80%' }} className='pl-3'>
                            <FormattedMessage id="SalesProcess.IsSmallEvent" />
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between mt-2'>
                        <div style={{ width: '20%' }} className="d-flex align-items-center justify-content-center">
                            <div style={{
                                width: '50px',
                                borderTop: '2px dotted #e8d41c'
                            }} />
                        </div>
                        <div style={{ width: '80%' }} className='pl-3'>
                            <FormattedMessage id="SalesProcess.IsEvent" />
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between mt-2'>
                        <div style={{ width: '20%' }} className="d-flex align-items-center justify-content-center">
                            <div style={{
                                width: '50px',
                                borderTop: '2px dotted #ff3e15'
                            }} />
                        </div>
                        <div style={{ width: '80%' }} className='pl-3'>
                            <FormattedMessage id="SalesProcess.IsBigEvent" />
                        </div>
                    </div>
                </div>
                <hr />
                <div>
                    <div className='d-flex align-items-center justify-content-between mt-2'>
                        <div style={{ width: '20%' }} className="d-flex align-items-center justify-content-center">
                            <div className='shadow' style={{
                                width: '50px',
                                height: '20px',
                                background: 'repeating-linear-gradient(45deg, #bdd4f3, #bdd4f3 5px, #ffffff 5px, #ffffff 12px)'
                            }}/>
                        </div>
                        <div style={{ width: '80%' }} className='pl-3'>
                            <FormattedMessage id="SalesProcess.NotAffectingAvail"/>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between mt-2'>
                        <div style={{ width: '20%' }} className="d-flex align-items-center justify-content-center">
                            <div className='shadow' style={{
                                width: '50px',
                                height: '20px',
                                background: 'repeating-linear-gradient(45deg, #614a0457, #614a0457 5px, #ffffff 5px, #ffffff 12px'
                            }}/>
                        </div>
                        <div style={{ width: '80%' }} className='pl-3'>
                            <FormattedMessage id="SalesProcess.OptionalProposals"/>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between mt-2'>
                        <div style={{ width: '20%' }} className="d-flex align-items-center justify-content-center">
                            <div className='shadow' style={{
                                width: '50px',
                                height: '20px',
                                borderRadius: '3px',
                                background: 'repeating-linear-gradient(45deg, #614a0457, #614a0457 5px, #bdd4f3 5px, #bdd4f3 12px)'
                            }}/>
                        </div>
                        <div style={{ width: '80%' }} className='pl-3'>
                            <FormattedMessage id="SalesProcess.OptionalProposalsAndNotAffectingAvail"/>
                        </div>
                    </div>
                </div>
                <hr/>
                <div>
                    <div className='d-flex align-items-center justify-content-between mt-3'>
                        <div className='d-flex justify-content-center' style={{ width: '20%' }}>
                            <div className='shadow' style={{
                                width: '18px',
                                height: '35px',
                                borderRadius: '3px',
                                border: `1px solid #5cb85c`,
                                background: '#5cb85c'
                            }}/>
                        </div>
                        <div style={{ width: '80%' }} className="pl-3 d-flex align-items-center justify-content-start">
                            <FormattedMessage id="SalesProcess.FullColorExplain"/>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between mt-2'>
                        <div className='d-flex justify-content-center' style={{ width: '20%' }}>
                            <div className='shadow' style={{
                                width: '18px',
                                height: '35px',
                                borderRadius: '3px',
                                border: `1px solid #5cb85c`,
                                background: 'repeating-linear-gradient(45deg, #5cb85c, #5cb85c 4px, #ffffff 5px, #ffffff 9px)'
                            }}/>
                        </div>
                        <div style={{ width: '80%' }} className="pl-3 d-flex align-items-center justify-content-start">
                            <FormattedMessage id="SalesProcess.StripedColorExplain"/>
                        </div>
                    </div>
                    {colorsByStatus.map((s, key) =>
                        <div key={key} className='d-flex align-items-center justify-content-between mt-2'>
                            <div className='d-flex justify-content-center' style={{ width: '20%' }}>
                                <div className='shadow' style={{
                                    width: '18px',
                                    height: '35px',
                                    borderRadius: '3px',
                                    border: `1px solid ${s.color}`,
                                    background: s.color
                                }}/>
                            </div>
                            <div style={{ width: '80%' }} className="pl-3 d-flex align-items-center justify-content-start">
                                <FormattedMessage id="SalesProcess.OccupiedbyStatus" values={{ status: s.status }} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    loadMonths = (monthKey) => {
        const unit = monthKey > 0 ? 15 : -15;
        const startDate = this.state.startDate.clone().add(unit, 'days');
        const endDate = this.state.endDate.clone().add(unit, 'days');
        this.setState({ startDate, endDate });
    }

    countFilters = (e) => {
        if (e) e.preventDefault();
        const { stageTypeList, staffIds, hideCancelled, unsubmittedHideNoInv, unsubmittedHideOption, unsubmittedHideStageType, subSegmentList, segmentList } = this.state;
        let count = 0;

        if (stageTypeList?.length > 0) count++;
        if (staffIds?.length > 0) count++;
        if (hideCancelled) count++;
        if (unsubmittedHideNoInv) count++;
        if (unsubmittedHideOption) count++;
        if (unsubmittedHideStageType) count++;
        if (subSegmentList?.length > 0) count++;
        if (segmentList?.length) count++;

        return count;
    }

    handleTagChange = (combo) => {
        this.setState({
            dealTags: combo ? combo.map(x => ({ id: x.value, label: x.label })) : []
        });
    };

    render() {
        const { error, block, submittedEndDate, submittedStartDate, hotelList, statusList, hotelIds, stageTypeList, staffIds, hideCancelled, startDate, endDate, submittedHotelIds, hideStageType, colorsByStatus,
        spaceList, hideOption, hideNoInv, segmentList, subSegmentList, segmentOptions, subSegmentOptions, unsubmittedHideStageType, unsubmittedHideOption, unsubmittedHideNoInv, segmentOptionsFilteredBySubSegments,
        subSegmentOptionsFilteredBySegments, dealTags, statusOptions, pmsStatusList } = this.state;
        const { icon, intl, salesUsers, isChild, toggleTab, selectedHotel, isFromMonthly, selectDealDetail, currentUser } = this.props;

        return (
            <div className="border-0 h-100 pb-2" id="SalesProcessGrid">
                <ErrorAlert error={error} />
                <form onSubmit={this.handleSubmit}>
                    <div className='mb-4 d-flex align-items-center justify-content-between'>
                        <div style={{ whiteSpace: 'nowrap' }} className='d-flex align-items-center'>
                            {isChild ?
                                <div onClick={_ => toggleTab(isFromMonthly ? "SalesProcessExecutiveReportMonthly" : "SalesProcessExecutiveReportYearly", selectedHotel)} className='p-3 cursor-pointer mr-2'>
                                    <i className='fas fa-chevron-left'/>
                                </div>
                            :''}
                            <h4 className='m-0'>
                                <i className={`${icon} mr-2`} />
                                <FormattedMessage id="SalesProcess.Grid" />
                            </h4>
                        </div>
                        <div className='d-flex align-items-center justify-content-end'>
                            <Button className="btn-sm btn-host mr-2" type="submit" id="buttonSubmit" disabled={!(startDate && endDate && hotelIds?.length)}>
                                <i className="fas fa-search"></i>
                            </Button>
                            <CommonHelper help={this.renderHelperMsg()} id={'GridHelp'} />
                        </div>
                    </div>
                    <Card className="border-0 p-3 shadow">
                        <BlockUi tag="div" blocking={block}>
                            <Row className="">
                                <Col className="col-4">
                                    <SelectHotel
                                        maxOptions={3}
                                        isSearchable
                                        name='hotels'
                                        isMulti={true}
                                        onChangeFunc={this.handleHotels}
                                        placeholder={'Hotels'}
                                        value={hotelIds || ''}
                                        params='?onlyFromHotelGroup=true'
                                        required={true}
                                        hotelIdsToFilter={currentUser?.hotelIds}
                                    />
                                </Col>
                                <Col className='col-4 d-flex align-items-center justify-content-center'>
                                    <div className='cursor-pointer' onClick={_ => this.loadMonths(-1)}>
                                        <b><i className="fas fa-chevron-left"/></b>
                                    </div>
                                    <CustomTwoMonthDateRangePicker
                                        startDate={startDate}
                                        endDate={endDate}
                                        handleDates={this.handleDates}
                                        required={true}
                                        small={true}
                                        isOutsideRange={(day, focusedInput) => startDate && focusedInput === "endDate" && day.isAfter(moment(startDate).add(31, "day"))}
                                    />
                                    <div className='cursor-pointer' onClick={_ => this.loadMonths(1)}>
                                        <b><i className="fas fa-chevron-right"/></b>
                                    </div>
                                </Col>
                                <Col className="col-4 d-flex align-items-center justify-content-end">
                                    <div className="d-flex align-items-center justify-content-end" style={{ fontSize: '0.7em', whiteSpace: 'nowrap' }}>
                                        <div onClick={_ => this.handleStatus("Effective")} className={`deal-action-badge-Effective ${statusList.includes("Effective") ? 'selected-deal-action-badge' : ''} ml-2 p-2 text-truncate`}>
                                            <i className="far fa-check-circle mr-1"/>
                                            <FormattedMessage id={`SalesProcess.Effective`}/>
                                        </div>
                                        <div onClick={_ => this.handleStatus("Finished")} className={`deal-action-badge-Finished ${statusList.includes("Finished") ? 'selected-deal-action-badge' : ''} ml-2 p-2 text-truncate`}>
                                            <i className="fas fa-trophy mr-1"/>
                                            <FormattedMessage id={`SalesProcess.Won`}/>
                                        </div>
                                        <div onClick={_ => this.handleStatus("Negotiation")} className={`deal-action-badge-OnGoing ${statusList.includes("WaitingHotel") ? 'selected-deal-action-badge' : ''} ml-2 p-2 text-truncate`}>
                                            <i className="fas fa-people-arrows mr-1"/>
                                            <FormattedMessage id={`SalesProcess.Negotiation`}/>
                                        </div>
                                        <div onClick={_ => this.handleStatus("Cancelled")} className={`deal-action-badge-Cancelled ${statusList.includes("Cancelled") ? 'selected-deal-action-badge' : ''} ml-2 p-2 text-truncate`}>
                                            <i className="far fa-thumbs-down mr-1"/>
                                            <FormattedMessage id={`SalesProcess.Lost`}/>
                                        </div>
                                    </div>
                                    <div className='ml-4'>
                                        <FilterButton totalFilters={this.countFilters()} butId={"buttonSubmit"}>
                                            <div className="fullWidth">
                                                <div>
                                                    <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                                        <i className='fas fa-route mr-2' />
                                                        <FormattedMessage id="SalesProcess.StageType" />
                                                    </div>
                                                    <div>
                                                        <SelectCustomType
                                                            isMulti={true}
                                                            onChangeFunc={this.handleStage}
                                                            value={stageTypeList ? stageTypeList.map(x => ({id : x})) : []}
                                                            placeholder={intl.formatMessage({ id: "SalesProcess.StageType" })}
                                                            isDisabled={false}
                                                            isClearable={true}
                                                            type={'PipelineStageType'}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-3'>
                                                    <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                                        <i className={`fas fa-users mr-2`} />
                                                        <FormattedMessage id="SalesProcess.Staff" />
                                                    </div>
                                                    <div>
                                                        <CustomSelect
                                                            options={salesUsers}
                                                            isClearable={true}
                                                            isMulti={true}
                                                            value={salesUsers?.filter(({ value }) => staffIds.includes(value))}
                                                            isSearchable={true}
                                                            onChange={e => this.handleMultiCombo(e, 'staffIds')}
                                                            placeholder={intl.formatMessage({ id: "SalesProcess.Staff" })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-3'>
                                                    <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                                        <i className={`fas fa-tags mr-2`} />
                                                        <FormattedMessage id="SalesProcess.DealTag"/>
                                                    </div>
                                                    <div>
                                                        <SelectCustomType
                                                            name="dealTags"
                                                            isSearchable={true}
                                                            placeholder={intl.formatMessage({ id: "SalesProcess.DealTag" })}
                                                            isDisabled={false}
                                                            isClearable={true}
                                                            isMulti={true}
                                                            type={'DealTag'}
                                                            onChangeFunc={(combo) => this.handleTagChange(combo)}
                                                            value={dealTags || []}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-3'>
                                                    <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                                        <i className={`fas fa-power-off mr-2`} />
                                                        <FormattedMessage id="SalesProcess.PMSStatus"/>
                                                    </div>
                                                    <div>
                                                        <CustomSelect
                                                            options={statusOptions}
                                                            isClearable={true}
                                                            isMulti={true}
                                                            value={statusOptions?.filter(({ value }) => pmsStatusList.includes(value))}
                                                            isSearchable={true}
                                                            onChange={e => this.handleMultiCombo(e, 'pmsStatusList')}
                                                            placeholder={intl.formatMessage({ id: "SalesProcess.PMSStatus" })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-3'>
                                                    <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                                        <i className="fas fa-layer-group mr-2" />
                                                        <FormattedMessage id="SalesProcess.Segment" />
                                                    </div>
                                                    <div>
                                                        <CustomSelect
                                                            options={segmentOptionsFilteredBySubSegments?.length ? segmentOptionsFilteredBySubSegments : segmentOptions}
                                                            isClearable={true}
                                                            isMulti={true}
                                                            value={segmentOptions?.filter(({ value }) => segmentList.includes(value))}
                                                            isSearchable={true}
                                                            onChange={e => this.handleMultiCombo(e, 'segmentList')}
                                                            placeholder={intl.formatMessage({ id: "SalesProcess.Segment" })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-3'>
                                                    <div className='title-sm' style={{ fontSize: '0.9em' }}>
                                                        <i className="fas fa-layer-group mr-2" />
                                                        <FormattedMessage id="SalesProcess.SubSegment" />
                                                    </div>
                                                    <div>
                                                        <CustomSelect
                                                            options={subSegmentOptionsFilteredBySegments?.length ? subSegmentOptionsFilteredBySegments : subSegmentOptions}
                                                            isClearable={true}
                                                            isMulti={true}
                                                            value={subSegmentOptions?.filter(({ value }) => subSegmentList.includes(value))}
                                                            isSearchable={true}
                                                            onChange={e => this.handleMultiCombo(e, 'subSegmentList')}
                                                            placeholder={intl.formatMessage({ id: "SalesProcess.SubSegment" })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-3'>
                                                    <CustomInput
                                                        type="checkbox"
                                                        id={"hideStageType"}
                                                        className="text-ellipsis mr-4"
                                                        checked={unsubmittedHideStageType}
                                                        onChange={_ => this.toggleBool("unsubmittedHideStageType")}
                                                    >
                                                        <FormattedMessage id="SalesProcess.HideStageType" />
                                                    </CustomInput>
                                                </div>
                                                <div className='mt-3'>
                                                    <CustomInput
                                                        type="checkbox"
                                                        id={"hideOption"}
                                                        className="text-ellipsis mr-4"
                                                        checked={unsubmittedHideOption}
                                                        onChange={_ => this.toggleBool("unsubmittedHideOption")}
                                                    >
                                                        <FormattedMessage id="SalesProcess.HideOption" />
                                                    </CustomInput>
                                                </div>
                                                <div className='mt-3'>
                                                    <CustomInput
                                                        type="checkbox"
                                                        id={"hideNoInv"}
                                                        className="text-ellipsis mr-4"
                                                        checked={unsubmittedHideNoInv}
                                                        onChange={_ => this.toggleBool("unsubmittedHideNoInv")}
                                                    >
                                                        <FormattedMessage id="SalesProcess.HideNoInv" />
                                                    </CustomInput>
                                                </div>
                                                <div className='mt-3'>
                                                    <CustomInput
                                                        type="checkbox"
                                                        id={"hideCancelled"}
                                                        className="text-ellipsis mr-4"
                                                        checked={hideCancelled}
                                                        onChange={_ => this.toggleBool("hideCancelled")}
                                                    >
                                                        <FormattedMessage id="SalesProcess.HideCancelled" />
                                                    </CustomInput>
                                                </div>
                                            </div>
                                        </FilterButton>
                                    </div>
                                </Col>
                            </Row>
                            <div>
                                <SalesProcessDealsRooms
                                    hotelIds={submittedHotelIds}
                                    hotelList={hotelList}
                                    startDate={submittedStartDate}
                                    endDate={submittedEndDate}
                                    history={this.props.history}
                                    hideStageType={hideStageType}
                                    hideOption={hideOption}
                                    hideNoInv={hideNoInv}
                                    spaceList={spaceList}
                                    selectDealDetail={selectDealDetail}
                                    colorsByStatus={colorsByStatus}
                                />
                            </div>
                        </BlockUi>
                    </Card>
                </form>
            </div>
        );
    }
}

export default injectIntl(SalesProcessGrid);