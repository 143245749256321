import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getAPI } from '../Base/API';
import { StyledCard } from "../Base/CommonUIComponents";
import { handleNotification } from '../Base/Notification';
import { Button, Col, Row, Input } from 'reactstrap';
import { CustomTable } from '../Base/CustomTable';
import CustomSelect from "../Base/CustomSelect";

export class GoContactDatabase extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: true,
            databaseList: [],
            options: [
                { value: true, label: <FormattedMessage id="GoContactDatabase.Active" /> },
                { value: false, label: <FormattedMessage id="GoContactDatabase.NotActive" /> }
            ],
            active: true

        };
    }

    componentDidMount() {
        this.getDatabase()
    }

    getDatabase= () => {


        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.data) {
                    this.setState({ databaseList: data.data, block: false });
                }

            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/GoContact/v1/Databases?active=${this.state.active}`);
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    render() {
        const { block, error, databaseList,active, options } = this.state;
        const columns = [
            {
                dataField: 'id',
                text: this.props.intl.formatMessage({ id: "GoContactDatabase.id" }),
                style: { width: '25px' }
            },{
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "GoContactDatabase.name" }),
                style: { width: '25px' }
            },
            {
                dataField: 'owner_type',
                text: this.props.intl.formatMessage({ id: "GoContactDatabase.owner_type" }),
                style: { width: '25px' }
            },
            {
                dataField: 'owner_id',
                text: this.props.intl.formatMessage({ id: "GoContactDatabase.owner_id" }),
                style: { width: '25px' }
            },
            {
                dataField: 'created_at',
                text: this.props.intl.formatMessage({ id: "GoContactDatabase.created_at" }),
                style: { width: '25px' }
            },
            {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "GoContactDatabase.active" }),
                style: { width: '25px' }
            },
            {
                dataField: 'type',
                text: this.props.intl.formatMessage({ id: "GoContactDatabase.type" }),
                style: { width: '25px' }
            }




        ]
        return (
            <StyledCard block={block} error={error} icon="fas fa-database" title="Database">

                <Row className="mt-3 mb-4 align-items-center">
                    <Col className="col-3 pr-0">
                        <CustomSelect
                            options={options}
                            value={options.find(m => m.value === active)}
                            placeholder={''}
                            onChange={this.handleSelect.bind(this, 'active')}
                            required={true}
                        />
                    </Col>
                    <Col>
                    </Col>
                    <Col className="text-right" >
                        <Button className="mr-3 btn btn-sm btn-host" onClick={this.getDatabase} >
                            <i className="fa fa-search" />
                        </Button>
                    </Col>
                </Row>
                <CustomTable
                    data={databaseList}
                    columns={columns}
                    rowStyle={{ cursor: 'pointer' }}
                    hasPagination={true}
                    sizePerPage={10}
                    showTotal={true}
                    search={true}


                />

            </StyledCard>
        );
    }
}
export default injectIntl(GoContactDatabase)