import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button, Modal, ModalBody, Form, Input, CustomInput } from 'reactstrap';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import Authorization, { CheckAuthorization } from '../Base/Authorization';
import { ProfileHistory } from '../Base/HistoryComponents';
import CardDetails from './CardDetails';
import moment from 'moment';
import { handleNotification } from '../Base/Notification';
import { getAPI, postAPI } from '../Base/API';
import { ActiveInactiveStatusBadge, BlankCard, DefaultPage, KebabMenu, SelectHotel } from '../Base/CommonUIComponents';
import CustomSelect from '../Base/CustomSelect';
import { DateRangePicker } from 'react-dates';
import { CustomTable } from '../Base/CustomTable';
import GenericTextArea from '../Base/GenericTextArea';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import LoyaltyLedgerDetail from './LoyaltyLedgerDetail';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';

export class ProfileMembershipCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            cardModal: false,
            loyaltyLevels: [],
            loyaltyTypes: [],
            selectedSource: null,
            deleteManualTransactions: false,
            sourceOptions: [
                { label: this.props.intl.formatMessage({ id: "ProfileDetails.All" }), value: null },
                { label: this.props.intl.formatMessage({ id: "ConfigCardPoints.AdditionalRevenue" }), value: 'AdditionalRevenue' },
                { label: this.props.intl.formatMessage({ id: "ConfigCardPoints.ReservationRevenue" }), value: 'ReservationRevenue' },
                { label: this.props.intl.formatMessage({ id: "ProfileDetails.Reservation" }), value: 'Reservation' },
                { label: this.props.intl.formatMessage({ id: "ProfileDetails.Manual" }), value: 'Manual' },
                // { label: this.props.intl.formatMessage({ id: "ProfileDetails.Transfer" }), value: 'Transfer' },
                { label: this.props.intl.formatMessage({ id: "ProfileDetails.Rebate" }), value: 'Rebate' },
                { label: this.props.intl.formatMessage({ id: "ProfileDetails.Nights" }), value: 'Nights' },
            ],
            addTransModal: false,
            newPoints: {},
            ledgerData: null,
            pageIndex: 0,
            pageSize: 10,
            totalItems: 0,
            detailModal: false,
            selectedRow: null,
            hasOneCardByType: false,
            selectedMembership: null,
            columns: [
                {
                    dataField: 'id',
                    text: this.props.intl.formatMessage({ id: "ProfileDetails.id" }),
                    hidden: true
                },
                {
                    dataField: 'idx',
                    text: '#'
                },
                {
                    dataField: 'hotelName',
                    text: this.props.intl.formatMessage({ id: "ProfileDetails.hotel" }),
                    formatter: (cell, row) => cell ? cell : '-'
                },
                {
                    dataField: 'entryDate',
                    text: this.props.intl.formatMessage({ id: "ProfileDetails.date" }),
                    formatter: (cell, row) => cell ? moment(cell).format('YYYY-MM-DD HH:mm') : ''
                },
                {
                    dataField: 'source',
                    text: this.props.intl.formatMessage({ id: "ProfileDetails.source" }),
                    formatter: (cell, row) => cell ? this.state.sourceOptions.find(({value}) => value === cell)?.label : ''
                },
                {
                    dataField: 'hasRule',
                    text: this.props.intl.formatMessage({ id: "ProfileDetails.hasRule" }),
                    formatter: (cell, row) => cell ? <i className="fas fa-check-circle text-success"/> : <span/>
                },
                {
                    dataField: 'tierPoints',
                    text: this.props.intl.formatMessage({ id: "ProfileDetails.tierPoints" }),
                },
                // {
                //     dataField: 'accrualPoints',
                //     text: intl.formatMessage({ id: "ProfileDetails.accrualPoints" }),
                //     hidden: !isAdmin
                // },
                {
                    dataField: 'sumPointsTier',
                    text: this.props.intl.formatMessage({ id: "ProfileDetails.sumPointsTier" })
                },
                // {
                //     dataField: 'sumPointsAcc',
                //     text: intl.formatMessage({ id: "ProfileDetails.sumPointsAcc" }),
                //     hidden: !isAdmin
                // }
            ]
        }
    }

    componentDidMount() {
        const { columns } = this.state;
        window.setTimeout(
            () => {
                if(global.modules?.includes('OneCardByType')){
                    columns.splice(3, 0, {
                        dataField: 'cardTypeDescription',
                        text: this.props.intl.formatMessage({ id: "ProfileDetails.cardTypeDescription" }),
                        formatter: (cell, row) => cell ? cell : '-'
                    });
                }
                
                this.setState({
                    columns,
                    hasOneCardByType: global.modules?.includes('OneCardByType')
                });
            },
            global.modules ? 0 : 2500
        );

        this.getCodeTypes();
    }

    componentWillReceiveProps(newProps){
        if((!this.props.membershipCards && newProps.membershipCards && newProps.membershipCards.length === 1) || (!this.state.selectedMembership && newProps.membershipCards)){
            this.setState({
                selectedMembership: newProps.membershipCards[0]?.id
            });
        }
    }

    getLevels = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            const hasLoyalty = global.modules && global.modules.some(m => m === 'LoyaltyProgram');
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false, hasLoyalty: hasLoyalty });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                let loyaltyLevels = []

                if (data.response && data.response.length > 0) {
                    if (CheckAuthorization("profileMembershipCard:loyaltyLevelList:all")) {
                        loyaltyLevels = data.response.map(ll => ({ value: ll.id, label: `${ll.code} (${ll.description})`, code: ll.code, description: ll.description, type: ll.loyaltyCardTypeConfigCode }));
                    }
                    else {
                        //Retorna apenas os niveis com o minPointsToLevel menor
                        loyaltyLevels = data.response.reduce((acc, cur) => {
                            if (cur.minPointsToLevel !== null) {
                                const obj = { value: cur.id, label: `${cur.code} (${cur.description})`, code: cur.code, description: cur.description, minPointsToLevel: cur.minPointsToLevel };

                                if (acc.length === 0 || acc[0]?.minPointsToLevel > cur.minPointsToLevel) {
                                    acc = [obj];
                                }
                                else if (acc[0]?.minPointsToLevel === cur.minPointsToLevel) {
                                    acc.push(obj)
                                }
                            }

                            return acc;
                        }, [])
                    }
                }

                this.setState({ error: errorMessage, loyaltyLevels, block: false });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardLevelConfig`);
    }

    getCodeTypes = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const loyaltyTypes = data.response ?
                    data.response.map(type => ({ value: type.id, label: `${type.code} (${type.description})`, code: type.code, description: type.description }))
                    :
                    [];
                this.setState({ error: errorMessage, loyaltyTypes }, this.getLevels);
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardTypeConfig`);
    }

    toggleModal = (obj) => {
        this.setState(prevState => ({
            cardModal: !prevState.cardModal,
            selectedCard: obj
        }))
    }
    
    handleSelect = (combo, name) => {
        this.setState({ [name]: combo.value });
    }

    toggleAddTransModal = () => {
        let { addTransModal, selectedMembership } = this.state, newPoints = {};
        const { membershipCards } = this.props;

        const card = membershipCards?.find(({id}) => id === selectedMembership);

        if(!addTransModal){
            newPoints = {
                accrualPoints: '0',
                tierPoints: '0',
                note: '',
                profileId: this.props.profileId,
                source: 'Manual',
                loyaltyCardTypeConfigCode: card.membershipTypeCode,
                profileMembershipCardId: card.id
            };
        }

        this.setState({ addTransModal: !addTransModal, newPoints });
    }
    
    recountTrans = () => {
        this.setState({ block: true }, () => {
            const { deleteManualTransactions, pageSize, selectedMembership } = this.state;
            const { profileId, membershipCards } = this.props;

            const typeId = membershipCards?.find(({id}) => id === selectedMembership)?.membershipTypeCode;

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
    
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });
    
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    
                    let ledgerData = data.response;
                    const totalItems = data.totalItems;
                    
                    if(ledgerData){
                        let idx = totalItems + 1;

                        ledgerData = ledgerData.map((row) => {
                            row.hotelName = row.hotelId ? global.hotelList?.find(({value}) => value === row.hotelId)?.label : '';
                            row.hasRule = row.ruleDetailId ? true : false;
                            row.idx = --idx;
                            return row;
                        });
                    }
                    
                    this.setState({ block: false, ledgerData, totalItems, confirmModal: false }, this.props.getProfile);
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/LoyaltyCard/v1/ReCountLedger?${typeId ? `membershipTypeCode=${typeId}&` : ''}profileId=${profileId}&pageSize=${pageSize}&deleteManualTransactions=${deleteManualTransactions}`);
        });
    }

    submitPointsTransaction = (e) => {
        if(e) e.preventDefault();
        
        let { newPoints, ledgerData } = this.state;

        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
    
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
    
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false, addTransModal: false, detailModal: false });
                    }
                    else {
                        const newTrans = data.response[0];

                        if(!ledgerData)
                            ledgerData = [];

                        newTrans.idx = ledgerData.length + 1;

                        ledgerData.unshift(newTrans);

                        this.setState({ block: false, ledgerData, addTransModal: false, detailModal: false }, () => {
                            handleNotification(data, <FormattedMessage id="ConfigCardPoints.ConfigSaved" />, <FormattedMessage id="generic.success"/>);
                            this.props.getProfile();
                        });
                        return;
                    }
                }
    
                this.setState({ block: false });
            }, `/api/gms/LoyaltyCard/v1/CreateTransaction`, { request: newPoints });
        });
    }

    handleNewPointsChange = (e) => {
        const { value, name } = e.target;
        const { newPoints }  = this.state;
        newPoints[name] = value;

        this.setState({ newPoints });
    }

    getLedgerData = () => {
        const { profileId, membershipCards } = this.props;
        const { pageSize, pageIndex, selectedSource, startDate, endDate, selectedHotelId, selectedMembership } = this.state;

        const typeId = membershipCards?.find(({id}) => id === selectedMembership)?.membershipTypeCode;

        let params = "";
        if (selectedSource) params += `&source=${selectedSource}`;
        if (startDate) params += `&startDate=${moment(startDate).format('YYYY-MM-DD')}`;
        if (endDate) params += `&endDate=${moment(endDate).format('YYYY-MM-DD')}`;
        if (selectedHotelId) params += `&hotelId=${selectedHotelId}`;

        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
    
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });
    
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else{
                        let ledgerData = data.response;
                        const totalItems = data.totalItems;
                        
                        if(ledgerData){
                            let idx = totalItems + 1;

                            ledgerData = ledgerData.map((row) => {
                                row.hotelName = row.hotelId ? global.hotelList?.find(({value}) => value === row.hotelId)?.label : '';
                                row.hasRule = row.ruleDetailId ? true : false;
                                row.idx = --idx;
                                return row;
                            });
                        }
                        
                        this.setState({ block: false, ledgerData, totalItems });
                        return;
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/LoyaltyCard/v1/GetLoyaltyPointsLedger/${profileId}?${typeId ? `membershipTypeCode=${typeId}&` : ''}pageSize=${pageSize}&pageIndex=${pageIndex}` + params);
        });
    }

    toggleDetailModal = (rowIndex) => {
        const { detailModal } = this.state;
        if(detailModal){
            this.setState({ detailModal: false, selectedRow: null });
        }
        else{
            this.getTransDetail(rowIndex);
        }
    }

    getTransDetail = (rowIndex) => {
        const { ledgerData } = this.state;
        const { profileType } = this.props;
        
        const selectedRow = ledgerData[rowIndex].id;

        if(!selectedRow) return;

        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else{
                        const transDetail = data.response[0];

                        const current = ledgerData.find(({id}) => transDetail.id === id);

                        current.revenue = transDetail.revenue;
                        current.reservation = transDetail.reservation;
                        current.rule = transDetail.rule;
                        current.rebated = transDetail.rebated;
                        current.accrualNightPoints = transDetail.accrualNightPoints;
                        current.accrualRevenuePoints = transDetail.accrualRevenuePoints;
                        current.accrualResPoints = transDetail.accrualResPoints;
                        current.tierNightPoints = transDetail.tierNightPoints;
                        current.tierResPoints = transDetail.tierResPoints;
                        current.tierRevenuePoints = transDetail.tierRevenuePoints;

                        this.setState({ block: false, ledgerData, selectedRow, detailModal: true });
                        return;
                    }
                }
                
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/LoyaltyCard/v1/GetLoyaltyTransDetail/${selectedRow}?profileType=${profileType}`);
        });
    }
    
    handleTableChange = (type, { page, sizePerPage }) => {
        this.setState({
            pageIndex: page - 1,
            pageSize: sizePerPage,
        }, () => this.getLedgerData());
    }

    revertTransaction = () => {
        const { ledgerData, selectedRow, selectedMembership } = this.state;
        const { membershipCards } = this.props;

        const selectedRowData = ledgerData?.find(({id}) => id === selectedRow); 
        const card = membershipCards?.find(({id}) => id === selectedMembership);

        const newPoints = {
            accrualPoints: parseInt(-selectedRowData.accrualPoints),
            tierPoints: parseInt(-selectedRowData.tierPoints),
            profileId: this.props.profileId,
            source: 'Rebate',
            transferId: selectedRow,
            loyaltyCardTypeConfigCode: card.membershipTypeCode,
            profileMembershipCardId: card.id
        };

        selectedRowData.isCanceled = true;

        this.setState({ newPoints, ledgerData }, () => this.submitPointsTransaction());
    }

    toggleConfirmModal = () => {
        this.setState({ confirmModal: !this.state.confirmModal });
    }
    
    handleInput = (e) => {
        const { checked, name } = e?.target;
        this.setState({ [name]: checked });
    }

    selectMembership = (id) => {
        if(this.state.selectedMembership === id)
            return;

        this.setState({ selectedMembership: id }, () => this.getLedgerData())
    }
    
    render() {
        const { membershipCards, isMaster, intl, profileId, updateTable, removeFromTable, canEditProfile } = this.props;
        const { startDate, endDate, selectedSource, sourceOptions, addTransModal, selectedHotelId, newPoints, ledgerData, pageIndex, pageSize, totalItems, block, columns,
        detailModal, cardModal, selectedCard, loyaltyLevels, selectedRow, confirmModal, error, deleteManualTransactions, hasOneCardByType, loyaltyTypes, selectedMembership } = this.state;

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: 'white',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.toggleDetailModal(rowIndex);
            }
        };
        
        const selectedRowData = ledgerData?.find(({ id }) => id === selectedRow);
        
        return (
            <div className="m-2 p-2">
                <BlockUi tag="div" blocking={block}>
                    <ErrorAlert error={error} />
                    <Row>
                        <Col>
                            <h5><i className="icon-icon-member-card mr-1"></i> <FormattedMessage id="ProfileDetails.MembershipCard" /></h5>
                        </Col>

                        <Col className="text-right">
                            <ProfileHistory
                                component={"membershipcard"}
                                profileId={this.props.profileId}
                            />

                            {canEditProfile || (isMaster && (!membershipCards || (global.modules?.some(m => m === 'LoyaltyProgram') ? membershipCards.length !== loyaltyTypes.length : membershipCards.length === 0))) ?
                                <Authorization
                                    perform="profileMembershipCard:add"
                                    yes={() => (
                                        <Button className="btn-sm btn-host ml-2" onClick={() => this.toggleModal(null)}>
                                            <i className="fas fa-plus" />
                                        </Button>
                                    )}
                                    no={() => <div></div>}
                                />
                                : ''}
                        </Col>
                    </Row>
                    <Row className="my-2">
                        {membershipCards && membershipCards.map((sm, key) => {
                            const type = this.state.loyaltyTypes.find(tp => tp.code === sm.membershipTypeCode);
                            const level = this.state.loyaltyLevels.find(lv => lv.code === sm.cardTypeCode);

                            return <Col className="col-4 mb-2" key={key}>
                                <Card className="border-0 mb-1 shadow h-100">
                                    <CardBody className={`pb-2 ${isMaster && membershipCards.length > 1 ? 'pointer' : ''} d-flex flex-column justify-content-between`} style={{ background: selectedMembership === sm.id && membershipCards.length > 1 ? '#d3eaff' : '' }} onClick={_ => isMaster && this.selectMembership(sm.id)}>
                                        <div>
                                            <Row>
                                                <Col>
                                                    {hasOneCardByType && sm.membershipTypeCode ?
                                                        <Row>
                                                            <Col>
                                                                <b><FormattedMessage id="ProfileDetails.MembershipTypeCode" />:</b> {`${type?.code || sm.membershipTypeCode}${type?.description ? ` - ${type?.description}` : ``}`}
                                                            </Col>
                                                        </Row>
                                                    :
                                                        <span/>
                                                    }
                                                    <Row>
                                                        <Col>
                                                            <b><FormattedMessage id="ProfileDetails.cardType" />:</b> {level?.code || sm.cardTypeCode} - {level?.description || sm.cardTypeDescription}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="col-2 pl-0 text-right">
                                                    <ActiveInactiveStatusBadge status={sm.active === null ? true : sm.active} />
                                                </Col>
                                                {canEditProfile && CheckAuthorization("profileMembershipCard:edit", null) ?
                                                    <Col className='col-1'>
                                                        <KebabMenu
                                                            editFunction={() => this.toggleModal(sm)}
                                                        />
                                                    </Col>
                                                :''}
                                            </Row>
                                        </div>

                                        <div>
                                            <Row className="my-2">
                                                <Col className="text-right"><b> <FormattedMessage id="CardDetails.CardNumber" />: </b> {sm.cardNumber}</Col>
                                            </Row>
                                            <Row className="my-2" style={{ display: sm.points === null ? 'none' : '' }}>
                                                <Col className="text-right"><b> <FormattedMessage id="CardDetails.Points" />: </b> {sm.points}</Col>
                                            </Row>
                                            <Row className="my-2">
                                                <Col className="text-right">
                                                    {sm.isExpired ? <i className="icon-icon-warnings-enabled text-warning fa-sm" /> : ''}
                                                    <b className='mr-1'>
                                                        <FormattedMessage id="CardDetails.Validity" />:
                                                    </b>
                                                    {sm.validFrom ? 
                                                        <span className='mr-1'>
                                                            {moment(sm.validFrom).format("YYYY-MM-DD")}
                                                        </span>
                                                    : ''}
                                                    {sm.validFrom && sm.validTo ?
                                                        <span className='mr-1'>/</span>
                                                    :''}
                                                    {sm.validTo ? 
                                                        <span>
                                                            {moment(sm.validTo).format("YYYY-MM-DD")}
                                                        </span>
                                                    : ''}
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        })}
                    </Row>
                    
                    <Authorization
                        perform="profileMembershipCard:loyaltyProgram:view"
                        yes={() => (
                            <>
                                {isMaster && membershipCards?.length > 0 && selectedMembership && global.modules && global.modules.some(m => m === 'LoyaltyProgram') ?
                                    <>
                                        <div>
                                            <Row className='mt-2'>
                                                <Col className='col-4'>
                                                    <b><FormattedMessage id="ProfileDetails.PointsTransactions"/></b>
                                                </Col>
                                                <Col className='text-right'>
                                                    {canEditProfile &&
                                                        <Button className="btn btn-sm btn-host mr-2" onClick={this.toggleConfirmModal}>
                                                            <i className="fas fa-sync" />
                                                        </Button>
                                                    }
                                                    {canEditProfile &&
                                                        <Button className="btn btn-sm btn-host mr-2" onClick={this.toggleAddTransModal}>
                                                            <i className="fas fa-plus"></i>
                                                        </Button>
                                                    }
                                                    <Button className="btn btn-sm btn-host" onClick={this.getLedgerData}>
                                                        <i className="fas fa-search"></i>
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row className='mt-2'>
                                                <Col className='col-4'>
                                                    <div className="title-sm mb-1">
                                                        <FormattedMessage id="ProfileDetails.hotel"/>
                                                    </div>
                                                    <SelectHotel
                                                        name={'selectedHotelId'}
                                                        icon={"icon-icon-hotel"}
                                                        onChangeFunc={(name, combo) => this.setState({ selectedHotelId: combo ? combo.value : null })}
                                                        placeholder={'Hotel'}
                                                        isSearchable={false}
                                                        isClearable={true}
                                                        value={selectedHotelId || ''}
                                                        params='?onlyFromHotelGroup=true'
                                                    />
                                                </Col>
                                                <Col className='col-4'>
                                                    <div className="title-sm mb-1">
                                                        <FormattedMessage id="ProfileDetails.source"/>
                                                    </div>
                                                    <CustomSelect
                                                        icon={"fas fa-project-diagram"}
                                                        value={sourceOptions.find(({value}) => value === selectedSource)}
                                                        options={sourceOptions}
                                                        isSearchable={false}
                                                        placeholder={""}
                                                        onChange={(combo) => this.handleSelect(combo, 'selectedSource')}
                                                    />
                                                </Col>
                                                <Col className='col-4'>
                                                    <div className="title-sm mb-1">
                                                        <FormattedMessage id="ProfileDetails.DateRange"/>
                                                    </div>
                                                    <DateRangePicker
                                                        startDate={startDate}
                                                        startDateId="startDate"
                                                        isOutsideRange={day => moment().isSameOrBefore(day)}
                                                        endDate={endDate}
                                                        endDateId="endDate"
                                                        onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                                        focusedInput={this.state.focusedInput}
                                                        onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                        small={true}
                                                        numberOfMonths={1}
                                                        showDefaultInputIcon={true}
                                                        renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>

                                        {ledgerData ?
                                            <Row className='mt-2'>
                                                <Col>
                                                    <CustomTable
                                                        keyField="Id"
                                                        data={ledgerData}
                                                        columns={columns}
                                                        showTotal={true}
                                                        remote={true}
                                                        page={pageIndex + 1}
                                                        sizePerPage={pageSize}
                                                        onTableChange={this.handleTableChange}
                                                        totalSize={totalItems}
                                                        hideSizePerPage={false}
                                                        selectRow={selectRow}
                                                    />
                                                </Col>
                                            </Row>
                                        :''}
                                    </>
                                :''}
                            </>
                        )}
                        no={() => <div/>}
                    />

                    {cardModal ?
                        <CardDetails
                            selectMembership={this.selectMembership}
                            modal={cardModal}
                            toggleModal={() => this.toggleModal(null)}
                            card={selectedCard}
                            profileId={profileId}
                            updateTable={updateTable}
                            removeFromTable={removeFromTable}
                            loyaltyLevels={loyaltyLevels}
                            loyaltyTypes={loyaltyTypes?.map((t) => ({ ...t, disabled: membershipCards?.find(({membershipTypeCode}) => membershipTypeCode === t.code) }))}
                            hasOneCardByType={hasOneCardByType}
                        />
                    : ''}

                    {addTransModal ?
                        <Modal isOpen={addTransModal} fade={false} size={"xl"} toggle={this.toggleAddTransModal} style={{ minWidth: 0 }}>
                            <Button type="button" onClick={this.toggleAddTransModal} className="closeModal">
                                <i className="fas fa-times fa-sm"></i>
                            </Button>
                            <ModalBody>
                                <BlankCard>
                                    <Form onSubmit={this.submitPointsTransaction}>
                                        <Row>
                                            <Col>
                                                <h5>
                                                    <FormattedMessage id="ProfileDetails.AddPointsTransaction" />
                                                </h5>
                                            </Col>
                                            <Col>
                                                <Button className="btn btn-host btn-sm float-right" type='submit'>
                                                    <i className="fas fa-save" />
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className='mt-2'>
                                            <Col className='col-6'>
                                                <div className="title-sm mb-1">
                                                    <FormattedMessage id="ProfileDetails.TierPoints"/>
                                                </div>
                                                <div>
                                                    <Input
                                                        value={newPoints.tierPoints}
                                                        onChange={this.handleNewPointsChange}
                                                        type="number"
                                                        name="tierPoints"
                                                        required={true}
                                                        placeholder={""}
                                                    />
                                                </div>
                                            </Col>
                                            {/* {isAdmin ?
                                                <Col className='col-6'>
                                                    <div className="title-sm mb-1">
                                                        <FormattedMessage id="ProfileDetails.AccrualPoints"/>
                                                    </div>
                                                    <div>
                                                        <Input
                                                            value={newPoints.accrualPoints}
                                                            onChange={this.handleNewPointsChange}
                                                            type="number"
                                                            name="accrualPoints"
                                                            required={true}
                                                            placeholder={""}
                                                        />
                                                    </div>
                                                </Col>
                                            :''} */}
                                            <Col className='col-12 mt-2'>
                                                <div className="title-sm mb-1">
                                                    <FormattedMessage id="ProfileDetails.Note"/>
                                                </div>
                                                <div>
                                                    <GenericTextArea
                                                        backgroundColor={"white"}
                                                        value={newPoints.note}
                                                        placeholder={""}
                                                        onChange={this.handleNewPointsChange}
                                                        name="note"
                                                        className="py-1"
                                                        required={true}
                                                        maxLength={2000}
                                                        readOnly={false}
                                                        style={{
                                                            width: '100%',
                                                            background: 'white',
                                                            boxShadow: 'unset',
                                                            outline: '0',
                                                            maxHeight : '600px',
                                                            minHeight: '50px',
                                                            height: '50px',
                                                            padding: '5px 0',
                                                            resize: 'none',
                                                            color: '#07294C',
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </BlankCard>
                            </ModalBody>
                        </Modal>
                    :''}

                    <LoyaltyLedgerDetail
                        detailModal={detailModal}
                        ledgerData={ledgerData}
                        selectedRow={selectedRow}
                        toggleDetailModal={this.toggleDetailModal}
                        revertTransaction={this.revertTransaction}
                        sourceOptions={sourceOptions}
                    />

                    {confirmModal ?
                        <ConfirmActionModal
                            modal={confirmModal}
                            toggleModal={this.toggleConfirmModal}
                            actionFunction={this.recountTrans}
                            text={
                                <div>
                                    <Row>
                                        <Col>
                                            <FormattedMessage id="ProfileDetails.recountTransWarning"/>
                                        </Col>
                                    </Row>
                                    <div className='mt-1 px-4 d-flex align-tems-center justify-content-center'>
                                        <b><FormattedMessage id="ProfileDetails.DeleteManualTransactions"/></b>
                                        <CustomInput
                                            className='ml-2'
                                            type='switch'
                                            name="deleteManualTransactions"
                                            id="deleteManualTransactions"
                                            checked={deleteManualTransactions}
                                            onChange={this.handleInput}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    :''}

                    {membershipCards == null || membershipCards.length == 0 ?
                        <DefaultPage text="ProfileDetails.NoMembershipCardsAvailable" icon="icon-icon-member-card" />
                    : ''}
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(ProfileMembershipCard);
