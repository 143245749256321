import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalBody, Input } from 'reactstrap';
import { handleNotification } from "../Base/Notification";
import { BlankCard } from '../Base/CommonUIComponents';
import CreatableSelect from 'react-select/creatable';
import { putAPI, getAPI  } from "../Base/API";

class InfoDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            infoData: this.props.information ? this.props.information : {},
            sectionOptions: []
        };
    }

    componentDidMount() {
        this.getReferenceData();
    }

    getReferenceData = () => {
        const isLoading = [`isSectionsLoading`];

        this.setState({ [isLoading]: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, [isLoading]: false });
                return;
            }
            if (data) {
                const list = [];

                data.response && data.response.forEach(rd => {
                    const newOption = { value: rd.code, label: rd.code, isDisabled: !rd.active };
                    const hotelIdx = list.findIndex(el => el.hotelId === rd.hotelId);

                    if (hotelIdx !== -1) {
                        list[hotelIdx].options.push(newOption);
                    }
                    else {
                        list.push({ label: rd.hotelName, hotelId: rd.hotelId, options: [newOption] })
                    }
                })

                this.setState({
                    sectionOptions: list,
                    [isLoading]: false
                });
            }
            else this.setState({ [isLoading]: false })
        }, `/api/gms/Hotel/v1/ReferenceData?type=Sections`);
    }

    handleChange = (e, type) => {
        const { name, value } = e.target;

        this.setState({
            infoData: {
                ...this.state.infoData,
                [name]: value
            }
        })
    }

    saveInfo = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var info = { ...this.state.infoData };

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="InfoDetails.InfoSaved" />, <FormattedMessage id="generic.success" />);
                    this.props.updateTable(data.response[0], 'informations');
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/profile/information/${this.props.profileId}`, JSON.stringify({ request: info }));
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            infoData: {
                ...this.state.infoData,
                [name]: combo ? combo.value : null
            }
        })
    };

    handleCreate = (label, name) => {
        this.setState({
            infoData: {
                ...this.state.infoData,
                [name]: label
            }
        });
    };

    render() {
        const { block, error, infoData } = this.state;

        return (
            <Modal isOpen={this.props.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.saveInfo}>
                            <Row className="mb-3">
                                <Col>
                                    <h5> {this.props.information ? <FormattedMessage id="InfoDetails.EditInformation" /> : <FormattedMessage id="InfoDetails.AddInformation" />}</h5>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" type="submit"> <i className="fas fa-save" /></Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={2}> <FormattedMessage id="ProfileDetails.Area" /></Label>
                                        <Col sm={10}>
                                            <CreatableSelect
                                                isClearable
                                                options={this.state.sectionOptions}
                                                onChange={(e) => this.handleChangeSelect(e, 'sectionCode')}
                                                onCreateOption={(e) => this.handleCreate(e, 'sectionCode')}
                                                formatCreateLabel={userInput => <span><FormattedMessage id="generic.Create" /> "{userInput}"</span>}
                                                placeholder={''}
                                                value={{ value: infoData?.sectionCode, label: infoData?.sectionCode }}
                                                isLoading={this.state[`isSectionLoading`]}
                                                isRequired
                                                maxLength="10"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={2}> <FormattedMessage id="ProfileDetails.Notes" /></Label>
                                        <Col sm={10}>
                                            <Input
                                                type="textarea"
                                                name="notes"
                                                onChange={(e) => this.handleChange(e)}
                                                value={infoData.notes ? infoData.notes : ''}
                                                maxLength="1000"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(InfoDetails)