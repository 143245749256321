import React from 'react';
import CoolDataGridCell from './CoolDataGridCell';

const CoolDataGridRow = ({ rowKey, tableName, row, col, hasIndent, firstCol, newHotelRowStyle }) => {

    return (
        <>
            <CoolDataGridCell
                cellsNoPadding={row.cellsNoPadding}
                newHotel={rowKey > 0}
                isSmall={row.isSmall}
                rowData={row.data}
                colKey={col.key}
                colType={col.type}
                separator={row.separator}
                title={row.title}
                firstCol={firstCol}
                rowEmptyNull={row.emptyNull}
                isChild={false}
                rowKey={rowKey}
                tableName={tableName}
                hasIndent={hasIndent}
                newHotelRowStyle={newHotelRowStyle}
                rowHeight={row.rowHeight}
            />
            {row.children?.length && row.isOpened ?
                row.children.map((child, childKey) =>
                    <React.Fragment key={childKey}>
                        <CoolDataGridCell
                            cellsNoPadding={child.cellsNoPadding}
                            key={childKey}
                            isSmall={child.isSmall}
                            rowData={child.data}
                            colKey={col.key}
                            colType={col.type}
                            separator={child.separator}
                            title={child.title}
                            firstCol={firstCol}
                            rowEmptyNull={child.emptyNull}
                            childKey={childKey}
                            hasIndent={hasIndent}
                            rowKey={rowKey}
                            tableName={tableName}
                            newHotelRowStyle={newHotelRowStyle}
                            rowHeight={child.rowHeight}
                        />
                        {child.children?.length && child.isOpened ?
                            child.children.map((child2, child2Key) =>
                                <React.Fragment key={child2Key}>
                                    <CoolDataGridCell
                                        cellsNoPadding={child2.cellsNoPadding}
                                        key={child2Key}
                                        isSmall={child2.isSmall}
                                        rowData={child2.data}
                                        colKey={col.key}
                                        colType={col.type}
                                        separator={child2.separator}
                                        title={child2.title}
                                        firstCol={firstCol}
                                        rowEmptyNull={child2.emptyNull}
                                        childKey={childKey}
                                        secChildKey={child2Key}
                                        hasIndent={hasIndent}
                                        rowKey={rowKey}
                                        tableName={tableName}
                                        newHotelRowStyle={newHotelRowStyle}
                                        rowHeight={child2.rowHeight}
                                    />
                                    {child2.children?.length && child2.isOpened ?
                                        child2.children.map((child3, child3Key) =>
                                            <CoolDataGridCell
                                                cellsNoPadding={child3.cellsNoPadding}
                                                key={child3Key}
                                                isSmall={child3.isSmall}
                                                rowData={child3.data}
                                                colKey={col.key}
                                                colType={col.type}
                                                separator={child3.separator}
                                                title={child3.title}
                                                firstCol={firstCol}
                                                rowEmptyNull={child3.emptyNull}
                                                childKey={childKey}
                                                secChildKey={child2Key}
                                                thirdChildKey={child3Key}
                                                hasIndent={hasIndent}
                                                rowKey={rowKey}
                                                tableName={tableName}
                                                newHotelRowStyle={newHotelRowStyle}
                                                rowHeight={child3.rowHeight}
                                            />
                                        )
                                    :''}
                                </React.Fragment>
                            )
                        :''}
                    </React.Fragment>
                )
            :''}
        </>
    );
}

export default CoolDataGridRow;
