import { Funnel } from '@nivo/funnel';
import React, { Component } from 'react';

class CustomFunnel extends Component {

    constructor(props) {
        super(props);

        this.funnelParent = React.createRef();

        this.state = {
            funnelWidth: null
        };
    }

    componentDidMount() {
        this.setState({
            funnelWidth: this.funnelParent?.current?.offsetWidth ?? 600
        });
    }

    Labels(props) {
        return props.parts.map((part, index) => {
            const lines = part.data.id.split("\n");

            return (
                <text
                    key={index}
                    x={part.x} 
                    y={part.y - 12} 
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{
                        pointerEvents: 'none',
                        fontSize: '12px',
                        color: 'lightgray'
                    }}
                >
                    {lines.map((line, lineIndex) => (
                        <tspan
                            key={lineIndex}
                            x={part.x} 
                            dy={lineIndex === 0 ? 0 : "1.2em"} 
                        >
                            {line}
                        </tspan>
                    ))}
                </text>
            );
        });
    }


    render() {
        const { data, colorScheme = 'blues', height = 300, enableFormatting = true } = this.props;
        const { funnelWidth } = this.state;

        return (
            <div className='w-100 h-100' ref={this.funnelParent}>
                <Funnel
                    height={height}
                    width={funnelWidth}
                    data={data}
                    margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                    direction="horizontal"
                    valueFormat={enableFormatting ? ">-.4s" : ''}
                    colors={{ scheme: colorScheme }}
                    borderWidth={20}
                    labelColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                3
                            ]
                        ]
                    }}
                    beforeSeparatorOffset={20}
                    afterSeparatorOffset={20}
                    layers={['separators', 'parts', this.Labels, 'annotations']}
                />
            </div>
        );
    }
}

export default CustomFunnel;
