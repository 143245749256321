
import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getAPI } from '../Base/API';
import { StyledCard } from "../Base/CommonUIComponents";
import { handleNotification } from '../Base/Notification';
import { Button, Col, Row, Input } from 'reactstrap';
import { CustomTable } from '../Base/CustomTable';

export class GoContactCallbacks extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: true,
            callList: []
        };
    }

    componentDidMount() {
        this.getCallbacks()
    }

    getCallbacks = () => {
       

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.data?.length >0) {
                    this.setState({ callList: data.data[0].result, block: false });
                }

            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/GoContact/v1/Callbacks`);
    }


    render() {
        const { block, error, callList } = this.state;
        const columns = [
            {
                dataField: 'owner_name',
                text: this.props.intl.formatMessage({ id: "GoContactCallbacks.owner_name" }),
                style: { width: '25px' }
            },
            {
                dataField: 'callback_type',
                text: this.props.intl.formatMessage({ id: "GoContactCallbacks.callback_type" }),
                style: { width: '25px' }
            },
             {
                dataField: 'callback_comments',
                 text: this.props.intl.formatMessage({ id: "GoContactCallbacks.callback_comments" }),
                style: { width: '25px' }
            },
            {
                dataField: 'contact_name',
                text: this.props.intl.formatMessage({ id: "GoContactCallbacks.contact_name" }),
                style: { width: '25px' }
            },
            {
                dataField: 'contact_phone',
                text: this.props.intl.formatMessage({ id: "GoContactCallbacks.contact_phone" }),
                style: { width: '25px' }
            }
            
            
            
            
             ]
        return (
            <StyledCard block={block} error={error} icon="fas fa-phone-volume" title="Callback">

                <Row className="mt-3 mb-4 align-items-center">
                    <Col className="col-2 pr-0">

                    </Col>
                    <Col>
                    </Col>
                    <Col className="text-right" >
                        <Button className="mr-3 btn btn-sm btn-host" >
                            <i className="fa fa-search" />
                        </Button>
                    </Col>
                </Row>
                <CustomTable
                    data={callList}
                    columns={columns}
                    rowStyle={{ cursor: 'pointer' }}
                    hasPagination={true}
                    sizePerPage={10}
                    showTotal={true}
                    search={true}


                />
               
            </StyledCard>
        );
    }
}
export default injectIntl(GoContactCallbacks)