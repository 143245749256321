import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getAPI } from '../Base/API';
import { SelectHotel, StyledCard } from "../Base/CommonUIComponents";
import { handleNotification } from '../Base/Notification';
import { Button, Col, Row, Badge } from 'reactstrap';

import { CustomTable } from '../Base/CustomTable';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { getFormSource } from '../Base/ReferenceDataFunctions';
import CustomSelect from '../Base/CustomSelect';
import { Pagination, TotalResults } from '../Base/PaginationComponents';
import { Link } from 'react-router-dom';
import { format } from 'ol/coordinate';

export class GoContactUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            status: null,
            role: null,
            userList :[]
        };
    }

    componentDidMount() {
        this.getContact()
    }

    getContact = () => {
        const { status,role } = this.state;
        const queryParams = new URLSearchParams();
        queryParams.append('status', status);
        queryParams.append('role', role);

        const queryString = queryParams.toString();
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                if (data.data) {
                    this.setState({ userList: data.data, block: false});
                }

            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/GoContact/v1/Users?${queryString}`);
    }


    render() {
        const { block, error, userList} = this.state;
        const columns = [
            {
                dataField: 'user_id',
                text: this.props.intl.formatMessage({ id: "GoContactUser.Id" }),
                style: { width: '25px' }
            },
            {
                dataField: 'username',
                text: this.props.intl.formatMessage({ id: "GoContactUser.Username" }),
                style: { width: '50px' }
            },
            {
                dataField: 'full_name',
                text: this.props.intl.formatMessage({ id: "GoContactUser.Fullname" }),
                style: { width: '120px' }
            },
            {
                dataField: 'role',
                text: this.props.intl.formatMessage({ id: "GoContactUser.role" }),
                style: { width: '40px' }
            },
            {
                dataField: 'group_name',
                text: this.props.intl.formatMessage({ id: "GoContactUser.Group" }),
                style: { width: '50px' }
            },
            {
                dataField: 'status',
                text: this.props.intl.formatMessage({ id: "GoContactUser.Status" }),
                style: { width: '50px' },
                formatter: (cell, row) => {
                    if (cell) {
                        
                        return <Badge className="m-1"> {cell} </Badge>
                    }
                    return '';
                },
            },
            {
                dataField: 'email',
                text: this.props.intl.formatMessage({ id: "GoContactUser.Email" }),
                style: { width: '50px' }
            },
            {
                dataField: 'register_date',
                text: this.props.intl.formatMessage({ id: "GoContactUser.CreateAt" }),
                style: { width: '50px' }
            },
            {
                dataField: 'last_update',
                text: this.props.intl.formatMessage({ id: "GoContactUser.UpdatedAt" }),
                style: { width: '50px' }
            },
            
            
        ];
        return (
            <StyledCard block={block} error={error} icon="far fa-user" title="GoContactUser.User">

                <Row className="mt-3 mb-4 align-items-center">
                    <Col className="col-2 pr-0">
                       
                    </Col>
                    <Col>
                                            </Col>
                    <Col className="text-right" >
                        <Button className="mr-3 btn btn-sm btn-host" >
                            <i className="fa fa-search" />
                        </Button>
                    </Col>
                </Row>
                    <CustomTable
                            data={userList}
                            columns={columns}
                            rowStyle={{ cursor: 'pointer' }}
                            hasPagination={true}
                            sizePerPage={10}
                            showTotal={true}
                            search={true}
                            

                    />
               
            </StyledCard>
        );
    }
}
export default injectIntl(GoContactUser)