import React, { Component } from 'react';
import { Button, Card, Col, Row, Modal, ModalBody } from 'reactstrap';
import { getAPI } from '../../Base/API';
import { StyledCard } from '../../Base/CommonUIComponents';
import { FormattedMessage } from 'react-intl';

export class SendGridPreviewVersion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            htmlBody: null,
            date: null
        };
    }

    componentDidMount() {
        const { templateId, selectedVersionId } = this.props;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                this.setState({ error: errorMessage, block: false, htmlBody: data.response.html_content, date: data.response.updated_at });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/SendGrid/v1/templateversion?templateId=${templateId}&versionId=${selectedVersionId}`);
    }

    render() {
        const { isOpen, toggle, templateName } = this.props;
        const { block, error, htmlBody, date } = this.state;

        return (
            <Modal isOpen={isOpen} fade={false} size={"xl"} style={{ minWidth: 0 }}>
                <Button onClick={toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <StyledCard block={block} error={error}>
                        <Row className="mb-2">
                            <Col>
                                <h5>
                                    <FormattedMessage id="SendGrid.VersionOn" values={{ templateName: templateName, date: date }} />
                                </h5>
                            </Col>
                        </Row>
                        <div dangerouslySetInnerHTML={{ __html: htmlBody }} />
                    </StyledCard>
                </ModalBody>
            </Modal>
        );
    }
}