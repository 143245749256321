import React, { Component } from 'react';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import { Button, ButtonGroup, Col, Input, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../Base/CustomSelect';
import moment from 'moment-timezone';
import { getSalesDealActionStatusOptions } from '../Base/ReferenceDataFunctions';
import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import SalesActionModal from './SalesActionModal';
import { CommonHelper } from '../Base/CommonUIComponents';
import { CustomFormatDate, SalesProcessHotelCombo, SalesProcessUsersCombo } from './SalesProcessFunctions';

class SalesProcessTasks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            block: true,
            taskId: null,
            hotels: null,
            status: ["Scheduled", "OnGoing"],
            createdBy: null,
            assignedTo: null,
            pageSize: 25,
            pageIndex: 0,
            statusOptions: getSalesDealActionStatusOptions(this.props.intl),
            tasksList: [],
            modal: false,
            selectedAction: null,
            queryString: ''
        };
    }

    componentDidMount(){
        const { taskId } = this.props;
        if(taskId){
            this.setState({ taskId }, () => this.getTasks(true));
        }
        else{
            this.getQueryStringData();
        }
    }

    getQueryStringData = () => {
        const { status, statusOptions } = this.state;
        
        const queryParams = new URLSearchParams(window.location.search);
        const hotels = queryParams.getAll('hotelId');
        let statusQs = queryParams.getAll('statusList');
        const createdBy = queryParams.get('createdById');
        const assignedTo = queryParams.get('userIds');
        const taskId = queryParams.get('taskId');
        const firstEntry = queryParams.get('firstEntry');

        if(statusQs?.length > 0){
            if(statusOptions?.length > 0){
                statusQs = statusQs.filter(s => statusOptions.map(({value}) => value).includes(s));
            }else{
                statusQs = [];
            }
        }

        this.setState({
            hotels,
            status: firstEntry === 'false' || statusQs?.length > 0 ? statusQs : status,
            createdBy,
            assignedTo,
            taskId,
        }, () => this.getTasks());
    };

    buildQueryString = () => {
        const { hotels, status, createdBy, assignedTo, taskId } = this.state;
        const queryParams = new URLSearchParams();
    
        if (hotels?.length > 0) {
            hotels.forEach(h => queryParams.append('hotelId', h));
        }
        if (status?.length > 0) {
            status.forEach(s => queryParams.append('statusList', s));
        }
        if (createdBy) {
            queryParams.append('createdById', createdBy);
        }
        if (assignedTo) {
            queryParams.append('userIds', assignedTo);
        }
        if (taskId) {
            queryParams.append('taskId', taskId);
        }
        
        queryParams.append('firstEntry', false);
    
        const queryString = queryParams.toString();
        const newUrl = `/SalesProcess${queryString ? `?${queryString}` : ''}#SalesProcessTasks`;

        this.setState({ queryString }, () => window.history.pushState(null, '', newUrl));
    };

    getTasks = (focus) => {
        const { taskId, hotels, status, createdBy, assignedTo, pageSize, pageIndex } = this.state;
        const { salesPipelines } = this.props;

        let q = `?pageSize=${pageSize}&pageIndex=${pageIndex}`;

        if(taskId){
            q += `&taskId=${taskId}`;
            this.changeUrl();
        }
        else{
            this.changeUrl();
        }

        if(!focus){
            if(hotels && hotels.length > 0)
                hotels.forEach(h => q += `&hotelId=${h}`)
            if(status)
                status.forEach(s => 
                    q += `&statusList=${s}`
                );
            if(createdBy)
                q += `&createdById=${createdBy}`;
            if(assignedTo)
                q += `&userIds=${assignedTo}`;
        }

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                const tasksList = data.data?.map((t) => {
                    const customers = [];

                    if(t.customer){
                        if(!customers?.find(c => c.value === t.customer.id)){
                            customers.push({
                                value: t.customer.id,
                                label: t.customer.name,
                                ...t.customer
                            });
                        }
                    }

                    if(t.mainContact){
                        if(!customers?.find(c => c.value === t.mainContact.id)){
                            customers.push({
                                value: t.mainContact.id,
                                label: t.mainContact.name,
                                ...t.mainContact
                            });
                        }
                    }

                    if(t.salesDealProfiles?.length > 0){
                        t.salesDealProfiles.forEach(p => {
                            if(!customers?.find(c => c.value === p.profileId)){
                                customers.push({
                                    value: p.profileId,
                                    label: p.name,
                                    ...p
                                });
                            }
                        });
                    }
                    return({
                        ...t,
                        stageName: salesPipelines?.flatMap(({stages}) => stages).find(({value}) => value === t.salesPipelineStageId)?.label??'',
                        customers
                    })
                }) ?? [];

                this.setState({
                    block: false,
                    tasksList
                }, () => {
                    if(focus){
                        this.toggleModal(tasksList[0]);
                    }
                    this.buildQueryString();
                });

                return;
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/tasks${q}`);
    }

    changeUrl = (id) => {
        const taskId = id ?? this.state.taskId;

        const url = new URL(window.location);

        if(taskId){
            url.searchParams.set('taskId', taskId);
        }
        else {
            url.searchParams.delete('taskId');
        }

        window.history.pushState({}, '', url);
    }

    handleStatus = (combo) => {
        this.setState({ status: combo ? combo?.map(cmb => cmb.value) : [] });
    }

    handleCombo = (e, name) => {
        this.setState({ [name]: e?.value });
    }

    handleHotel = (e, combo) => {
        this.setState({ hotels: combo?.map(({value}) => value) });
    }

    handleUsers = (e, combo) => {
        this.setState({ [e]: combo?.value });
    }

    handlePageIndex = (p) => {
        const { pageIndex } = this.state;
        this.setState({ pageIndex: parseInt(pageIndex + p), block: true }, () => this.getTasks());
    }

    changePageSize = (evt) => {
        const { value } = evt.target;
        const { pageSize } = this.state;

        if (pageSize !== value) {
            this.setState({ block: true, pageSize: value }, () => this.getTasks());
        }
    }

    searchTasks = () => {
        this.setState({ block: true }, this.getTasks());
    }

    toggleModal = (action) => {
        this.setState({ modal: !this.state.modal, selectedAction: action }, () => this.changeUrl(action?.id));
    }

    handleTaskIdChange = (e) => {
        this.setState({ taskId: e?.target?.value });
    }

    render() {
        const { error, block, hotels, createdBy, assignedTo, taskId, statusOptions, status, tasksList, pageIndex, pageSize, modal, selectedAction, queryString } = this.state;
        const { icon, salesUsers, intl, selectDealDetail, selectInitiativeDetail } = this.props;

        return (
            <div className="border-0 h-100 pb-2">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <div className='mb-4'>
                        <Row>
                            <Col>
                                <h4 className='m-0'>
                                    <i className={`${icon} mr-2`} />
                                    <FormattedMessage id="SalesProcess.Tasks" />
                                </h4>
                            </Col>
                            <Col className="pr-0">
                                <CommonHelper help={''} id={'TasksHelp'} />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <div className='d-flex align-items-start justify-content-between' style={{ gap: '2%' }}>
                            <div style={{ width: '20%' }}>
                                <div className='title-sm'>
                                    <i className="fas fa-briefcase mr-2"/>
                                    <FormattedMessage id="SalesTasks.TaskId"/>
                                </div>
                                <div>
                                    <Input
                                        type="text"
                                        value={taskId}
                                        onChange={this.handleTaskIdChange}
                                        placeholder={intl.formatMessage({ id: "SalesTasks.TaskId" })}
                                    />
                                </div>
                            </div>
                            <div style={{ width: '20%' }}>
                                <div className='title-sm'>
                                    <i className="fas fa-users mr-2"/>
                                    <FormattedMessage id="SalesTasks.AssignedTo"/>
                                </div>
                                <div>
                                    <SalesProcessUsersCombo
                                        isMulti={false}
                                        isClearable={true}
                                        name='assignedTo'
                                        placeholder={intl.formatMessage({ id: "SalesTasks.AssignedTo" })}
                                        value={assignedTo}
                                        onChangeFunc={this.handleUsers}
                                    />
                                </div>
                            </div>
                            <div style={{ width: '20%' }}>
                                <div className='title-sm'>
                                    <i className="fas fa-users mr-2"/>
                                    <FormattedMessage id="SalesTasks.CreatedBy"/>
                                </div>
                                <div>
                                    <SalesProcessUsersCombo
                                        isMulti={false}
                                        isClearable={true}
                                        name='createdBy'
                                        placeholder={intl.formatMessage({ id: "SalesTasks.CreatedBy" })}
                                        value={createdBy}
                                        onChangeFunc={this.handleUsers}
                                    />
                                </div>
                            </div>
                            <div style={{ width: '20%' }}>
                                <div className='title-sm'>
                                    <i className="icon-icon-hotel mr-2"/>
                                    <FormattedMessage id="SalesTasks.Hotel"/>
                                </div>
                                <div>
                                    <SalesProcessHotelCombo
                                        isMulti={true}
                                        isClearable={true}
                                        name='hotelIds'
                                        placeholder={intl.formatMessage({ id: "SalesProcess.Hotel" })}
                                        value={hotels}
                                        onChangeFunc={this.handleHotel}
                                    />
                                </div>
                            </div>
                            <div style={{ width: '20%' }}>
                                <div className='title-sm'>
                                    <i className="fas fa-power-off mr-2"/>
                                    <FormattedMessage id="SalesTasks.Status"/>
                                </div>
                                <div>
                                    <CustomSelect
                                        options={statusOptions}
                                        isClearable={true}
                                        isMulti={true}
                                        value={statusOptions?.filter(({value}) => status.includes(value))}
                                        isSearchable={true}
                                        onChange={this.handleStatus}
                                        placeholder={intl.formatMessage({ id: "SalesTasks.Status" })}
                                    />
                                </div>
                            </div>
                            <div style={{ width: '5%', whiteSpace: 'nowrap' }} className='text-right mt-4'>
                                <Button className="btn-sm btn-host" onClick={this.searchTasks}>
                                    <i className="fas fa-search"/>
                                </Button>
                            </div>
                        </div>
                        <div className='mt-4' style={{ fontSize: '0.9em' }}>
                            <div className='d-flex align-items-center justify-content-between shadow p-3 mb-2 bg-white text-muted' style={{ borderRadius: '4px', gap: '1%' }}>
                                <div style={{ width: '3%' }}>
                                    <div className='text-truncate'>
                                        <FormattedMessage id={`SalesTasks.Id`}/>
                                    </div>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <div className='text-truncate'>
                                        <FormattedMessage id={`SalesTasks.Title`}/>
                                    </div>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <div className='text-truncate'>
                                        <FormattedMessage id={`SalesTasks.Type`}/>
                                    </div>
                                </div>
                                <div style={{ width: '10%' }}>
                                    <div className='text-truncate'>
                                        <FormattedMessage id={`SalesTasks.DealName`}/>
                                    </div>
                                </div>
                                <div style={{ width: '8%' }}>
                                    <div className='text-truncate'>
                                        <FormattedMessage id={`SalesTasks.CreatedBy`}/>
                                    </div>
                                </div>
                                <div style={{ width: '8%' }}>
                                    <div className='text-truncate'>
                                        <FormattedMessage id={`SalesTasks.CreatedAt`}/>
                                    </div>
                                </div>
                                <div style={{ width: '8%' }}>
                                    <div className='text-truncate'>
                                        <FormattedMessage id={`SalesTasks.AssignedTo`}/>
                                    </div>
                                </div>
                                <div style={{ width: '8%' }}>
                                    <div className='text-truncate'>
                                        <FormattedMessage id={`SalesTasks.Status`}/>
                                    </div>
                                </div>
                                <div style={{ width: '8%' }}>
                                    <div className='text-truncate'>
                                        <FormattedMessage id={`SalesTasks.StartDate`}/>
                                    </div>
                                </div>
                                <div style={{ width: '8%' }}>
                                    <div className='text-truncate'>
                                        <FormattedMessage id={`SalesTasks.EndDate`}/>
                                    </div>
                                </div>
                                <div style={{ width: '8%' }}>
                                    <div className='text-truncate'>
                                        <FormattedMessage id={`SalesTasks.DueDate`}/>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-3'>
                                {tasksList.map((task, key) =>
                                    <div key={key} className='d-flex align-items-center justify-content-between cursor-pointer shadow p-3 mb-2 bg-white' style={{ borderRadius: '4px', gap: '1%' }} onClick={_ => this.toggleModal(task)}>
                                        <div style={{ width: '3%' }}>
                                            <div className='text-truncate'>
                                                <b>{task.id ?? '-'}</b>
                                            </div>
                                        </div>
                                        <div style={{ width: '10%' }}>
                                            <div className='text-truncate'>
                                                {task.title ?? '-'}
                                            </div>
                                        </div>
                                        <div style={{ width: '10%' }}>
                                            <div className='text-truncate'>
                                                {task.actionType ?? '-'}
                                            </div>
                                        </div>
                                        <div style={{ width: '10%' }}>
                                            <div className='text-truncate hover-underline hrefStyle' onClick={_ => {
                                                task?.salesInitiativeId !== null && task?.salesInitiativeId !== undefined ? 
                                                    selectInitiativeDetail(task.salesInitiativeId, "SalesProcessTasks") 
                                                :
                                                    selectDealDetail(task.salesDealId, "SalesProcessTasks")}
                                            }>
                                                {task.dealName ?? '-'}
                                            </div>
                                        </div>
                                        <div style={{ width: '8%' }}>
                                            <div className='text-truncate'>
                                                {task.createdBy ?
                                                    salesUsers.find(({userId}) => userId === task.createdBy)?.label ?? task.createdBy
                                                :'-'}
                                            </div>
                                        </div>
                                        <div style={{ width: '8%' }}>
                                            <div className='text-truncate'>
                                                {task.createdAt ?
                                                    CustomFormatDate(task.createdAt, null, 'HH:mm', intl)
                                                :'-'}
                                            </div>
                                        </div>
                                        <div style={{ width: '8%' }}>
                                            {task.salesDealActionUsers?.length ?
                                                <div style={{ maxHeight: '22px' }} className='overflow-auto veryCoolScrollBar'>
                                                    {task.salesDealActionUsers.map((u, key) =>
                                                        <div key={key} className={key ? 'mt-1 text-truncate' : 'text-truncate'}>
                                                            {u.name}
                                                        </div>
                                                    )}
                                                </div>
                                            :'-'}
                                        </div>
                                        <div style={{ width: '8%' }}>
                                            <div className='text-truncate'>
                                                {task.status ?
                                                    <div className='d-flex align-items-center'>
                                                        {task.status === 'Scheduled' ?
                                                            <i style={{ color: 'lightgrey' }} className="mr-2 fas fa-minus-circle"/>
                                                        : task.status === 'Cancelled' ?
                                                            <i className="mr-2 fas fa-times-circle color-light-red"/>
                                                        : task.status === 'OnGoing' ?
                                                            <i className="mr-2 fas fa-arrows-alt-h" style={{ color: "#0665ff" }}/>
                                                        : task.status === 'Finished' ?
                                                            <i className="mr-2 fas fa-check-circle text-success"/>
                                                        :''}
                                                        <div style={{ marginTop: '1px' }} className={`deal-action-status-${task.status}`}>
                                                            {task.status === 'Finished' ? 
                                                                <FormattedMessage id={`SalesActions.Finished`}/>
                                                            :
                                                                <FormattedMessage id={`SalesProcess.${task.status}`}/>
                                                            }
                                                        </div>
                                                    </div>
                                                :'-'}
                                            </div>
                                        </div>
                                        <div style={{ width: '8%' }}>
                                            <div className='text-truncate'>
                                                {task.startDate ?
                                                    CustomFormatDate(task.startDate, null, 'HH:mm', intl)
                                                :'-'}
                                            </div>
                                        </div>
                                        <div style={{ width: '8%' }}>
                                            <div className='text-truncate'>
                                                {task.endDate ?
                                                    CustomFormatDate(task.endDate, null, 'HH:mm', intl)
                                                :'-'}
                                            </div>
                                        </div>
                                        <div style={{ width: '8%' }}>
                                            <div className={`text-truncate ${task.dueDate && moment(task.dueDate) < moment() ? 'text-danger' : '' } `}>
                                                {task.dueDate ?
                                                    moment(task.dueDate) < moment() ?
                                                        <b>{CustomFormatDate(task.dueDate, null, null, intl)}</b>
                                                    :
                                                        CustomFormatDate(task.dueDate, null, null, intl)
                                                :'-'}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <Row className="mt-4">
                                    <Col>
                                        <ButtonGroup onClick={this.changePageSize}>
                                            <Button className={(pageSize == 10 ? "bg-host text-white" : "text-secondary") + " page-link"} value={10}> 10 </Button>
                                            <Button className={(pageSize == 25 ? "bg-host text-white" : "text-secondary") + " page-link"} value={25}> 25 </Button>
                                            <Button className={(pageSize == 30 ? "bg-host text-white" : "text-secondary") + " page-link"} value={30}> 30 </Button>
                                            <Button className={(pageSize == 50 ? "bg-host text-white" : "text-secondary") + " page-link"} value={50}> 50 </Button>
                                        </ButtonGroup>
                                    </Col>

                                    <Col className="text-right">
                                        <span>
                                            <Button className="btn btn-host btn-sm mr-2" onClick={_ => this.handlePageIndex(-1)} disabled={pageIndex === 0}>
                                                <i className="fas fa-angle-left" />
                                            </Button>
                                            <span className="mr-2">
                                                <FormattedMessage id="SearchProfile.Page" values={{ page: parseInt(pageIndex + 1) }} />
                                            </span>
                                            <Button className="btn btn-host btn-sm" onClick={_ => this.handlePageIndex(1)} disabled={tasksList && tasksList.length < pageSize}>
                                                <i className="fas fa-angle-right" />
                                            </Button>
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    {modal ?
                        <SalesActionModal
                            onlyTask={true}
                            toggle={_ => this.toggleModal()}
                            salesUsers={salesUsers}
                            salesDealUsers={salesUsers?.filter(user => selectedAction?.salesDealUsers?.some(dealUser => dealUser.userId === user.value))}
                            customers={selectedAction?.customers ?? []}
                            salesDealHotelIds={selectedAction?.dealHotels}
                            salesDealId={selectedAction?.salesDealId}
                            salesInitiativeId={selectedAction?.salesInitiativeId}
                            selectedAction={selectedAction || null}
                            updateActions={this.searchTasks}
                            dealName={selectedAction?.dealName}
                            stageName={selectedAction?.stageName}
                        />
                    :''}
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(SalesProcessTasks);