import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button, Input } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import Authorization, { CheckAuthorization } from '../Base/Authorization';
import { ProfileDefault } from './ProfileDefault';
import InfoDetails from './InfoDetails';
import { ProfileHistory } from '../Base/HistoryComponents';
import { DefaultPage } from '../Base/CommonUIComponents';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import moment from 'moment';
import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { getProfileType } from '../Base/ReferenceDataFunctions';


class ProfileInformations extends Component {
    ProfileInterests = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            informationModal: false,
            selectedInfo: null,
            modal: false,
            deleteModal: false,
            pricelistRule: null
        }
    }

    componentDidMount() {
        const { hasPriceListRules, isMaster, profileId, profileType } = this.props;

        if (hasPriceListRules && isMaster && getProfileType(this.props.intl).slice(1, 3).some(prfTp => prfTp.value === profileType)) {
            this.setState({ block: true });

            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                    }
                    else {
                        if (data.data && data.data.length > 0) {
                            this.setState({ error: errorMessage, block: false, pricelistRule: data.data[0] })
                        }
                        else {
                            this.setState({ error: errorMessage, block: false });
                        }
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/Profile/v1/profile/${profileId}/pricelist?pageSize=20&pageIndex=0`)
        }
    }


    toggleModal = (obj) => {
        this.setState(prevState => ({
            informationModal: !prevState.informationModal,
            selectedInfo: obj
        }))
    }

    toggleDeleteModal = (selectedInfo) => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
            selectedInfo
        }));
    }

    removeInfo = () => {
        this.props.removeElement(this.props.profileId, this.state.selectedInfo.id, 'information', 'informations');

        this.toggleDeleteModal();
    }

    render() {
        const { informations, profileInfo, profileId, profileDefault, block, groupByHotel, updateTable, removeElement, canEditProfile } = this.props;
        const { deleteModal, pricelistRule } = this.state;

        return (
            <div className="m-2 p-2">

                <Row className="mb-2">
                    <Col>
                        <h5><i className="icon-icon-information"> </i><FormattedMessage id="ProfileDetails.ProfileInformation" /></h5>
                    </Col>
                    <Col className="text-right">
                        <ProfileHistory
                            component={"information"}
                            profileId={this.props.profileId}
                        />

                        <Authorization
                            perform="profileInformations:add"
                            yes={() => canEditProfile && (
                                <Button className="btn-sm btn-host ml-2" onClick={() => this.toggleModal(null)}>
                                    <i className="fas fa-plus" />
                                </Button>
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>

                {informations && Object.keys(informations).map((hotel, key) =>
                    <div key={key} >
                        <Row className="col-6 mb-2">
                            <Col className="pl-2">{hotel} </Col>
                        </Row>

                        {informations[hotel].map((info, i) =>
                            <Row className="mb-0" key={i}>
                                <Col>
                                    <Card className="border-0 mb-1 shadow">
                                        <CardBody className="py-2">
                                            <Row className='align-items-center'>
                                                <Col className='col-10 col-lg-11'>
                                                    <div style={{ cursor: 'pointer' }} onClick={() => { if (canEditProfile && CheckAuthorization("profileInformations:add", null)) this.toggleModal(info) }}>
                                                        <Row className='align-items-center'>
                                                            <Col className="col-6 col-lg-2 d-flex flex-column justify-content-center align-items-start" id={`SectionCode-${info.id}`}>
                                                            {info.sectionCode ? 
                                                                <h6 className="mb-0 mr-2"><i className="fas fa-info-circle mr-2"></i>{info.sectionCode}</h6>
                                                            :''} 
                                                                <div className="text-muted title-sm m-0">{info.updatedAt && moment(info.updatedAt) ? moment(info.updatedAt).format("YYYY-MM-DD HH:mm") : ''}</div>
                                                            </Col>
                                                            <Col id={`Notes-${info.id}`} className='col-6 col-lg-10 text-truncate '> {info.notes} </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <Authorization
                                                    perform="profileInformations:delete"
                                                    yes={() => canEditProfile && (
                                                        <Col className="col-2 col-lg-1 text-right">
                                                            <span onClick={() => this.toggleDeleteModal(info)} style={{ cursor: 'pointer' }}>
                                                                <i className="fas fa-trash-alt" />
                                                            </span>
                                                        </Col>
                                                    )}
                                                    no={() => <div></div>}
                                                />
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </div>
                )}

                {informations == null || informations.length == 0 ?
                    <DefaultPage text="ProfileDetails.NoInformationsAvailable" icon="icon-icon-information" height='30vh' />
                : ''}

                {this.state.informationModal ?
                    <InfoDetails
                        modal={this.state.informationModal}
                        toggleModal={() => this.toggleModal(null)}
                        information={this.state.selectedInfo}
                        profileId={profileId}
                        updateTable={updateTable}
                    />
                : ''}

                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={this.toggleDeleteModal}
                        actionFunction={this.removeInfo}
                        text={<FormattedMessage id="ProfileInformations.ConfirmDeleteText" />}
                    />
                : ''}


                <ProfileDefault
                    block={block}
                    profileDefault={profileDefault}
                    profileId={profileId}
                    updateTable={updateTable}
                    removeElement={removeElement}
                    groupByHotel={groupByHotel}
                    isMaster={this.props.isMaster}
                    hotels={this.props.hotels}
                    canEditProfile={canEditProfile}
                    pricelistRule={pricelistRule}
                />
            </div>
        );
    }
}
export default injectIntl(ProfileInformations)