import moment from 'moment-timezone';
import React, { Component } from 'react';
import { Button, UncontrolledPopover } from 'reactstrap';
import { getSalesDealActionTypes } from '../Base/ReferenceDataFunctions';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CoolTooltip, KebabMenu } from '../Base/CommonUIComponents';
import SalesActionModal from './SalesActionModal';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { handleNotification } from '../Base/Notification';
import { putAPI } from '../Base/API';
import { CustomFormatDate } from './SalesProcessFunctions';

class SalesActionsPopOver extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            hasMissedAction: false,
            hasNextAction: false,
            allActionTypes: getSalesDealActionTypes(this.props.intl),
            actionableActionTypes: getSalesDealActionTypes(this.props.intl, undefined, true),
            selectedAction: null,
            modal: false,
            customers: [],
            confirmActionModal: false,
            status: null
        };
    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props)
            this.initialCalc(nextProps);
    }

    componentDidMount(){
        this.initialCalc(this.props);
    }

    initialCalc = (props) => {
        const { deal } = props;

        if(!deal)
            return;

        const customers = [];

        if(deal.customer){
            customers.push({
                value: deal.customer.id,
                label: deal.customer.name,
                ...deal.customer
            });
        }
        if(deal.mainContact){
            customers.push({
                value: deal.mainContact.id,
                label: deal.mainContact.name,
                ...deal.mainContact
            });
        }
        if(deal.salesDealProfiles){
            deal.salesDealProfiles.forEach(p => {
                customers.push({
                    value: p.profileId,
                    label: p.name,
                    ...p
                });
            });
        }
        
        const tasks = deal.salesDealActions?.filter(({type}) => type === 'Task') || [];

        const hasMissedAction = tasks.length > 0 && 
        tasks.some(({dueDate}) =>  dueDate && moment().isSameOrAfter(dueDate, 'date'));

        const hasOnGoingAction = tasks.length > 0 &&
        tasks.some(({dueDate, status}) => dueDate && moment().isSameOrBefore(dueDate, 'date') && status === "OnGoing");

        const hasNextAction = tasks.length > 0 &&
        tasks.some(({dueDate, status}) => dueDate && moment().isSameOrBefore(dueDate, 'date') && status !== "OnGoing");

        this.setState({
            customers,
            hasMissedAction,
            hasOnGoingAction,
            hasNextAction: deal.hasFutureActions || hasNextAction
        });
    }

    toggleModal = (selectedAction) => {
        this.setState({ selectedAction, modal: !this.state.modal })
    }
    
    toggleConfirmActionModal = (selectedAction, status) => {
        this.setState({
            confirmActionModal: !this.state.confirmActionModal,
            selectedAction,
            status
        })
    }

    startOrFinishAction = () => {
        const { selectedAction, status } = this.state;
        
        this.setState({ block: true }, () => {
            putAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    if (data.data && data.data.length > 0) {
                        handleNotification(data, <FormattedMessage id="SalesDeal.TaskStatusChanged" />, <FormattedMessage id="generic.success" />);
                        this.setState({
                            error: errorMessage,
                            block: false,
                            status: null,
                            selectedAction: null,
                            confirmActionModal: false
                        }, () => this.updateActions());

                        return;
                    }   
                }

                this.setState({ error: errorMessage, block: false, status: null, selectedAction: null, confirmActionModal: false });
            }, `/api/gms/SalesProcess/v1/deals/action/${selectedAction.id}/status/${status}`);
        });
    }

    updateActions = () => {
        const { getDeals, selectedPipeline } = this.props;
        getDeals(selectedPipeline);
    }

    getActionStatus = (action) => {
        let status = moment().isSameOrAfter(action.startDate, 'date') ? ((action.endDate && moment().isSameOrBefore(action.endDate, 'date')) ? "OnGoing" : "Late") : "Soon";
        const isTask = action.type === "Task";

        if(isTask){
            const missedTask = action.dueDate && moment().isSameOrAfter(action.dueDate, 'date');
            const onGoingTask = action.dueDate && moment().isSameOrBefore(action.dueDate, 'date') && action.status === "OnGoing";
            const soonTask = action.dueDate && moment().isSameOrBefore(action.dueDate, 'date') && action.status !== "OnGoing";

            status = missedTask ? "Late" : onGoingTask ? "OnGoing" : soonTask ? "Soon" : null;
        }
        return status;
    }

    render() {
        const { block, hasMissedAction, hasNextAction, selectedAction, allActionTypes, customers, modal, confirmActionModal, status, hasOnGoingAction } = this.state;
        const { intl, salesUsers, deal, small, id = "SalesActionsPopOver", placement = "right" } = this.props;
        
        return (
            <div onMouseDown={(e) => e.stopPropagation()} onClick={e => e.stopPropagation()}>
                <div id={id} className={small ? '' : 'pl-2 pb-2'}>
                    {hasMissedAction ?
                        <div className='actionStatusIcon' style={{ background: "#dc3545" }}>
                            <b id={`MissedAction-${deal.id}`}>
                                {placement === "left" ?
                                    '<'
                                :'>'}
                            </b>
                            <CoolTooltip placement="top" target={`MissedAction-${deal.id}`}>
                                <FormattedMessage id="SalesProcess.MissedAction" />
                            </CoolTooltip>
                        </div>
                    : hasOnGoingAction ?
                        <div className='actionStatusIcon' style={{ background: "#0665ff" }}>
                            <b>
                                {placement === "left" ?
                                    '<'
                                :'>'}
                            </b>
                            <CoolTooltip placement="top" target={`OnGoingAction-${deal.id}`}>
                                <FormattedMessage id="SalesProcess.OnGoingAction" />
                            </CoolTooltip>
                        </div>
                    : hasNextAction ?
                        <div className='actionStatusIcon' style={{ background: "#28a745" }}>
                            <b>
                                {placement === "left" ?
                                    '<'
                                :'>'}
                            </b>
                            <CoolTooltip placement="top" target={`ScheduledAction-${deal.id}`}>
                                <FormattedMessage id="SalesProcess.ScheduledAction" />
                            </CoolTooltip>
                        </div>
                    :
                        <div className='actionStatusIcon' style={{ background: "#ffc107" }}>
                            <b>
                                {placement === "left" ?
                                    '<'
                                :'>'}
                            </b>
                            <CoolTooltip placement="top" target={`NoActions-${deal.id}`}>
                                <FormattedMessage id="SalesProcess.NoActions" />
                            </CoolTooltip>
                        </div>
                    }
                </div>

                <UncontrolledPopover target={id} placement={placement} placementPrefix='coolPopover bs-popover'>
                    <div className='py-2 pl-3' style={{ minWidth: '350px', maxWidth: '450px' }} id="SalesActionsPopOver">
                        {deal?.salesDealActions?.length ?
                            <div className='veryCoolScrollBar overflow-auto' style={{ maxHeight: '250px' }}>
                                <div className='pr-3'>
                                    {deal.salesDealActions.filter(a => a.type === 'Task' || a.pinned) && 
                                    deal.salesDealActions.filter(a => a.type === 'Task' || a.pinned).map((action, key) => {
                                        const type = allActionTypes.find(({value}) => value === action.type);
                                        const responsableUser = salesUsers[0];
                                        const actionStatus = this.getActionStatus(action);
                                        const isTask = action.type === "Task";
                                        const isNote = action.type === "Note";
                                        
                                        return (
                                            <div key={key} className='w-100 py-2' style={{ borderTop: key ? '1px solid #a8a8a8' : '' }}>
                                                <div className='d-flex align-items-start justify-content-between'>
                                                    <div>
                                                        <i className={type?.icon}/>
                                                    </div>
                                                    <div className='px-3' style={{ width: '-webkit-fill-available' }}>
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <div className='text-truncate'>{action.title}</div>
                                                            {action.pinned ?
                                                                <div className='ml-2'>
                                                                    <i id={`PinnedTag-${action.id}`} className="fas fa-thumbtack" style={{ color: '#0665ff', fontSize: '0.9em' }}/>
                                                                    <CoolTooltip placement="top" target={`PinnedTag-${action.id}`}>
                                                                        <FormattedMessage id="SalesProcess.Pinned" />
                                                                    </CoolTooltip>
                                                                </div>
                                                            :
                                                                <div>
                                                                    <div target={`ActionStatus-${deal.id}`}>
                                                                        {actionStatus === "Late" ?
                                                                            <i className="text-danger fas fa-exclamation-circle"/>
                                                                        : actionStatus === "Soon" ?
                                                                            <i style={{ color: 'lightgrey' }} className="fas fa-minus-circle"/>
                                                                        :
                                                                            <i style={{ color: '#0665ff' }} className="fas fa-arrows-alt-h"/>
                                                                        }
                                                                    </div>
                                                                    <CoolTooltip placement="top" target={`ActionStatus-${deal.id}`}>
                                                                        {actionStatus === "Late" ?
                                                                            <FormattedMessage id="SalesProcess.LateAction"/>
                                                                        : actionStatus === "Soon" ?
                                                                            <FormattedMessage id="SalesProcess.ActionSoon"/>
                                                                        :
                                                                            <FormattedMessage id="SalesProcess.OnGoingAction"/>
                                                                        }
                                                                    </CoolTooltip>
                                                                </div>
                                                            }
                                                        </div>
                                                        {isTask ?
                                                            <div className='mt-1 text-muted d-flex align-items-center justify-content-between' style={{ fontSize: '0.9em' }}>
                                                                <div>
                                                                    {CustomFormatDate(action.dueDate, null, 'HH:mm', intl)}
                                                                </div>
                                                                {responsableUser ?
                                                                    <div>
                                                                        {`${responsableUser.firstName} ${responsableUser.lastName}`}
                                                                    </div>
                                                                :''}
                                                            </div>
                                                        :''}
                                                    </div>
                                                    <div>
                                                        <KebabMenu
                                                            extraFields={[
                                                                action.status == "Scheduled" ? {
                                                                    text: "SalesProcess.OnGoing",
                                                                    textClass: "onGoingColorIcon",
                                                                    function: _ => this.toggleConfirmActionModal(action, 'OnGoing'),
                                                                    icon: "fas fa-arrows-alt-h mr-2 onGoingColorIcon",
                                                                } : null,
                                                                action.status == "OnGoing" ? {
                                                                    text: "SalesActions.Finished",
                                                                    textClass: "text-success",
                                                                    function: _ => this.toggleConfirmActionModal(action, 'Finished'),
                                                                    icon: "mr-2 fas fa-check-circle text-success"
                                                                } : null,
                                                            ].filter(Boolean)}
                                                            editFunction={_ => this.toggleModal(action)}
                                                            deleteFunction={_ => this.toggleConfirmActionModal(action, 'Cancelled')}
                                                            deleteText={'SalesProcess.CancelAction'}
                                                        />
                                                    </div>
                                                </div>
                                                {isNote ?
                                                    <>
                                                        <hr className='my-2'/>
                                                        <div className='text-muted' style={{ fontSize: '0.9em' }}>
                                                            <div dangerouslySetInnerHTML={{ __html: action.summary }}/>
                                                        </div>
                                                    </>
                                                :''}
                                            </div>
                                        )}
                                    )}
                                </div>
                            </div>
                        :
                            <div className='d-flex align-items-center justify-content-center py-3'>
                                <div>
                                    {hasNextAction ?
                                        <FormattedMessage id="SalesProcess.NothingToFocus"/>
                                    :
                                        <FormattedMessage id="SalesProcess.NoActionsConfigured"/>
                                    }
                                </div>
                            </div>
                        }
                        <div className='pr-3'>
                            <Button className="btn btn-sm btn-host mt-2 w-100 mb-1" onClick={_ => this.toggleModal()}>
                                <i className="fas fa-plus mr-1"></i>
                                <FormattedMessage id="SalesProcess.CreateAction"/>
                            </Button>
                        </div>
                    </div>
                </UncontrolledPopover>

                {modal ?
                    <SalesActionModal
                        customers={customers}
                        toggle={_ => this.toggleModal()}
                        salesUsers={salesUsers}
                        salesDealUsers={salesUsers?.filter(user => deal?.salesDealUser?.some(dealUser => dealUser.userId === user.value))}
                        salesDealHotelIds={deal?.hotelIds}
                        selectedAction={selectedAction}
                        updateActions={this.updateActions}
                        salesDealId={deal.id}
                        salesPipelineStageId={deal.salesPipelineStage?.id}
                    />
                :''}

                {confirmActionModal ?
                    <ConfirmActionModal
                        modal={confirmActionModal}
                        toggleModal={() => this.toggleConfirmActionModal()}
                        actionFunction={this.startOrFinishAction}
                        text={status === "Finished" ? <FormattedMessage id={`SalesDeal.ConfirmChangeActionStatusToFinished`} />
                            : status === "OnGoing" ? <FormattedMessage id={`SalesDeal.ConfirmChangeActionStatusToOnGoing`} />
                            : <FormattedMessage id="SalesDeal.ConfirmDeleteText" values={{ action: selectedAction.title }} />}
                        block={block}
                    />
                :''}
            </div>
        );
    }
}

export default injectIntl(SalesActionsPopOver);