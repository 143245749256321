import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Badge, Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import { BlankCard } from '../Base/CommonUIComponents';
import { CustomTable } from '../Base/CustomTable';

class DuplicatedProfilesCreateProfileModal extends Component {

    render() {
        const { modal, toggleModal, createProfile, duplicatedProfiles } = this.props;

        const columns = [
            {
                dataField: 'fullName',
                text: this.props.intl.formatMessage({ id: "SearchProfile.FirstName" })
            },
            {
                dataField: 'hotelId',
                text: this.props.intl.formatMessage({ id: "SearchProfile.Hotel" }),
                formatter: cell => global && global.hotelList ? global.hotelList.find(htl => htl.value === cell)?.label : ''
            },
            {
                dataField: 'isMaster',
                text: 'Master',
                formatter: (cell, row) => cell ? <Badge color='primary'> Master </Badge> : row.linked ? <Badge className=' color-white bg-cyan'> Linked </Badge> : ''
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row) => {
                this.props.history.push({ pathname: `/ProfileDetails/${row.id}` })
            }
        };

        return (
            <Modal isOpen={modal} fade={false} size={"xl"}>
                <Button onClick={toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard>
                        <Row className="mb-3">
                            <Col>
                                <h5>
                                    <FormattedMessage id="ProfileDetails.PossibleDuplicatedProfiles" />
                                </h5>
                            </Col>
                            <Col className="text-right col-1">
                                <Button className="btn-sm btn-host" onClick={createProfile}>
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <CustomTable
                                    data={duplicatedProfiles}
                                    columns={columns}
                                    selectRow={selectRow}
                                />
                            </Col>
                        </Row>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(DuplicatedProfilesCreateProfileModal);
