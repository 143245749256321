import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Card, Col, Row } from 'reactstrap';

// import { QRCodeCanvas } from 'qrcode.react';
// import Barcode from 'react-barcode';

class WalletCardPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: false,
            frontCardRows: 0,
            detailsCardRows: 0,
            showDeleteRow: null,
            initialRender: true
        }
    };

    shouldComponentUpdate(nextProps, nextState) {
        const { initialRender } = this.state;
    
        if (nextProps.fieldsInGoogleCard !== this.props.fieldsInGoogleCard && initialRender) {
            const frontCardRows = nextProps.fieldsInGoogleCard?.filter(element => element.card === 'frontCard')?.reduce((maxRow, element) => Math.max(maxRow, element.row), -1) + 1 || 0;
            const detailsCardRows = nextProps.fieldsInGoogleCard?.filter(element => element.card === 'detailsCard')?.reduce((maxRow, element) => Math.max(maxRow, element.row), -1) + 1 || 0;
    
            this.setState({ frontCardRows, detailsCardRows, initialRender: false });
        }
    
        return true;
    }

    getContrastColor = (hexColor, type) => {
        const color = hexColor.replace('#', '');
      
        const r = parseInt(color.substring(0, 2), 16);
        const g = parseInt(color.substring(2, 4), 16);
        const b = parseInt(color.substring(4, 6), 16);
      
        const brightness = (r * 299 + g * 587 + b * 114) / 1000;
        if(type === 'text') {
            return brightness > 128 ? '#000000' : '#FFFFFF';
        }else{
            return brightness > 128 ? '#848484' : '#e6e6e6';
        }
    };

    addRow = (card) => {
        if(card === 'detailsCard') {
            this.setState({ detailsCardRows: this.state.detailsCardRows + 1 });
        }else{
            this.setState({ frontCardRows: this.state.frontCardRows + 1 });
        }
    }

    /* setShowDeleteRow = (showDeleteRow) => {
        this.setState({ showDeleteRow });
    } */

    /* deleteRow = (rowIndex) => {
        
        const { fieldsInGoogleCard } = this.props;
        const newFields = fieldsInGoogleCard.filter(element => element.row !== rowIndex);

        this.setState({ frontCardRows: this.state.frontCardRows - 1 }, () => this.props.setFieldsInGoogleCard(newFields));
    } */

    render() {
        const { frontCardRows, detailsCardRows } = this.state;
        const { walletCardConfig, activeTab, fieldsInGoogleCard } = this.props;

        const textColor = activeTab === '0' ? this.getContrastColor( walletCardConfig?.backgroundColor || '#FFFFFF', 'text') : walletCardConfig?.labelColor;
        const borderColor = activeTab === '0' ? this.getContrastColor( walletCardConfig?.backgroundColor || '#FFFFFF', 'border') : walletCardConfig?.labelColor;
        
        return (
            <div className='d-flex align-items-start justify-content-center p-3'>
                <Col className='d-flex flex-column align-items-center justify-content-center mx-4'>
                    <span className='pb-3'>
                        <FormattedMessage id="WalletCardConfig.CardFront" />
                    </span>
                    <Card 
                        className='previewWalletCard' 
                        style={{ 
                            background: (activeTab === '0' ? walletCardConfig?.backgroundColor : walletCardConfig?.backgroundColor) || '#FFFFFF' 
                        }}
                    >
                        {activeTab === '0' ?
                            <>
                                <Row className='px-3 pt-3'>
                                    <Col className='d-flex align-items-center col-12'>
                                        {walletCardConfig?.logoImage ?
                                            <div className='googleWalletLogo mr-2'>
                                                <img src={walletCardConfig?.logoImage || ''} alt='googleWalletLogo'  />
                                            </div>
                                        :''}
                                        <span onClick={() => this.props.toggleFieldConfig('IssuerName')} className={`cursor-pointer ${!walletCardConfig?.fieldLocation?.find(f => f.fieldCode === 'IssuerName')?.fieldValue ? 'font-italic text-truncate' : ''}`} style={{ color: textColor }}>
                                            {walletCardConfig?.fieldLocation?.find(f => f.fieldCode === 'IssuerName')?.fieldValue || <FormattedMessage className='font-italic' id='WalletCardConfig.IssuerName' />}
                                        </span>
                                    </Col>
                                </Row>
                                <hr /> 
                                <Row className='mx-3 mb-3'>
                                    <Col onClick={() => this.props.toggleFieldConfig('ProgramName')} style={{ color: textColor }} className={`col-12 p-0 cursor-pointer text-truncate ${!walletCardConfig?.fieldLocation?.find(f => f.fieldCode === 'ProgramName')?.fieldValue ? 'font-italic' : ''}`}>
                                        {walletCardConfig?.fieldLocation?.find(f => f.fieldCode === 'ProgramName')?.fieldValue || <FormattedMessage id='WalletCardConfig.ProgramName' />}
                                    </Col>
                                </Row>
                                {frontCardRows > 0 ? (
                                    <Row className='mx-3 mb-3'>
                                        <Col className='p-0 d-flex flex-column align-items-center justify-content-center col-12'>
                                            {[...Array(frontCardRows ?? 0)].map((_, rowIndex) => (
                                                <Row
                                                    key={`row-${rowIndex}`}
                                                    /* onMouseOver={() => this.setShowDeleteRow(rowIndex)}
                                                    onMouseOut={() => this.setShowDeleteRow(null)} */
                                                    className='w-100 my-2 d-flex align-items-center'
                                                >
                                                    {[...Array(3)].map((_, colIndex) => (
                                                        <Col
                                                            key={`${rowIndex}-${colIndex}`}
                                                            onDragOver={e => this.props.onDragOver(e)}
                                                            onDrop={e => this.props.onDrop(e, rowIndex, colIndex, 'frontCard')}
                                                            className={`${colIndex === 1 ? 'mx-3' : ''} drop-zone`}
                                                            style={{ border: `1px solid ${borderColor}`, maxWidth: `${this.state.showDeleteRow === rowIndex ? '28%' : '30%'}` }}
                                                        >
                                                            {fieldsInGoogleCard.map((element, index) => {
                                                                if (element.row === rowIndex && element.column === colIndex && element.card === 'frontCard') {
                                                                    return (
                                                                        <div
                                                                            key={index}
                                                                            className='mt-2 text-truncate'
                                                                            style={{ color: textColor, cursor: 'grab' }}
                                                                            draggable="true"
                                                                            onDragStart={e => this.props.onDragStart(e, element)}
                                                                        >
                                                                            <FormattedMessage id={`WalletCardConfig.${element.value}`} />
                                                                        </div>
                                                                    );
                                                                }
                                                                return '';
                                                            })}
                                                        </Col>
                                                    ))}

                                                    {/* {this.state.showDeleteRow === rowIndex ? (
                                                        <div onClick={() => this.deleteRow(rowIndex)} style={{ color: 'red' }}>
                                                            <i className="fas fa-trash-alt cursor-pointer" />
                                                        </div>
                                                    ) : ''} */}
                                                </Row>
                                            ))}
                                        </Col>
                                    </Row>
                                ) : ''}
                                {frontCardRows < 3 ?
                                    <Row className='cursor-pointer mx-3 mb-3' onClick={() => this.addRow('frontCard')}>
                                        <Col className='d-flex align-items-center justify-content-center col-12 drop-zone' style={{ border: `1px solid ${borderColor}` }}>
                                            <span style={{ color: borderColor }}>
                                                <i className="fas fa-plus"></i>
                                            </span>
                                        </Col>
                                    </Row>
                                :''}
                                {walletCardConfig?.barCodeType ?
                                    <Row className='px-3 pb-3 justify-content-center'>
                                        <Col className='d-flex align-items-end justify-content-center col-12'>
                                            {walletCardConfig?.barCodeType === "QR_Code" ?
                                                <div style={{ backgroundColor: 'white', padding: '10px', borderRadius: '10px' }}>
                                                    {/* <QRCodeCanvas 
                                                        value={'qrcode'} 
                                                        size={80} 
                                                        id="qrcode"
                                                    /> */}
                                                </div>
                                            :
                                                <span id="barCode" style={{ backgroundColor: 'white', padding: '5px', borderRadius: '10px' }}>
                                                    {/* <Barcode 
                                                        value={'barCode'}
                                                        format={walletCardConfig?.barCodeType === 'CODE_128' ? 'CODE128' : (walletCardConfig?.barCodeType || 'CODE128')}
                                                        background="#FFFFFF" 
                                                        width={1}
                                                        height={40}
                                                        displayValue={false}
                                                    /> */}
                                                </span>
                                            }
                                        </Col>
                                        {walletCardConfig?.barCodeText ?
                                            <Col className='text-center pt-1'>
                                                <span style={{ color: textColor }}>
                                                    {walletCardConfig?.barCodeText}
                                                </span>
                                            </Col>
                                        :''}
                                    </Row>
                                :''}

                                {walletCardConfig?.heroImage ?
                                    <Row className='d-flex align-items-center justify-content-center'>
                                        <Col className='googleWalletHero col-12'>
                                            <img src={walletCardConfig?.heroImage} alt='heroImage' />
                                        </Col>
                                    </Row>
                                :''}
                            </>
                        :''}
                    </Card>
                </Col>
                <Col className='d-flex flex-column align-items-center justify-content-center mx-4'>
                    <span className='pb-3'>
                        <FormattedMessage id="WalletCardConfig.CardDetails" />
                    </span>
                    <Card className='previewWalletCard p-3' style={{ minHeight: '400px' }}>
                        <Row>
                            <Col>
                                <FormattedMessage id="WalletCardConfig.CardDetails" />
                            </Col>
                        </Row>
                        {detailsCardRows > 0 ? (
                            <Row className='m-3'>
                                <Col className='p-0 d-flex flex-column align-items-center justify-content-center col-12'>
                                    {[...Array(detailsCardRows ?? 0)].map((_, rowIndex) => (
                                        <Row
                                            key={`detailsRow-${rowIndex}`}
                                            className='w-100 my-2 d-flex align-items-center'
                                        >
                                            <Col
                                                key={`detailsCol-${rowIndex}`}
                                                onDragOver={e => this.props.onDragOver(e)}
                                                onDrop={e => this.props.onDrop(e, rowIndex, null, 'detailsCard')}
                                                className={`drop-zone col-12`}
                                                style={{ border: `1px solid #848484` }}
                                            >
                                                {fieldsInGoogleCard.map((element, index) => {
                                                    if (element.row === rowIndex && element.column === null  && element.card === 'detailsCard') {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className='mt-2 text-truncate'
                                                                style={{ cursor: 'grab' }}
                                                                draggable="true"
                                                                onDragStart={e => this.props.onDragStart(e, element)}
                                                            >
                                                                <FormattedMessage id={`WalletCardConfig.${element.value}`} />
                                                            </div>
                                                        );
                                                    }
                                                    return '';
                                                })}
                                            </Col>

                                            {/* {this.state.showDeleteRow === rowIndex ? (
                                                <div onClick={() => this.deleteRow(rowIndex)} style={{ color: 'red' }}>
                                                    <i className="fas fa-trash-alt cursor-pointer" />
                                                </div>
                                            ) : ''} */}
                                        </Row>
                                    ))}
                                </Col>
                            </Row>
                        ) : ''}
                        <Row className={`cursor-pointer ${detailsCardRows > 0 ? 'mx-3 mb-3' : 'm-3'} `} onClick={() => this.addRow('detailsCard')}>
                            <Col className='d-flex align-items-center justify-content-center col-12 drop-zone' style={{ border: `1px solid #848484` }}>
                                <span style={{ color: '#848484' }}>
                                    <i className="fas fa-plus"></i>
                                </span>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </div>
        )
    }
}

export default injectIntl(WalletCardPreview);
