import React, { Component } from 'react';
import { BlankCard, SelectHotel, StyledModal, CommonHelper } from '../Base/CommonUIComponents';
import { handleNotification } from '../Base/Notification';
import { getAPI, postAPI } from '../Base/API';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, ButtonGroup, Col, Form, Input, Row } from 'reactstrap';
import CustomSelect from '../Base/CustomSelect';
import { DateRangePicker } from 'react-dates';
import moment from 'moment-timezone';
import { getSalesDealImportTypes } from '../Base/ReferenceDataFunctions';
import { CustomFormatDate, SalesProcessHotelCombo } from './SalesProcessFunctions';

class CreateDealByImportGroupRes extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            error: null,
            reservationList: [],
            pageSize: 10,
            pageIndex: 0,
            searchTerm: "",
            startDate: null,
            endDate: null,
            selectedGroupResId: null,
            hotelId: null,
            steps: [
                { step: 1, active: true, name: 'Config' },
                { step: 2, active: false, name: 'Import' },
            ],
            salesPipelineId: this.props.selectedPipeline?.id,
            importTypeId: 'GroupReservation'
        };
    }

    componentDidMount() {
        const currentUser = global.salesProcessUsers?.find(el => el.isCurrentUser);
        const userHotelIds = global.hotelList.filter(hotel => currentUser?.hotelIds?.some(hotelId => hotelId === hotel.value));
        if (userHotelIds && userHotelIds.length === 1) {
            const hotelId = [userHotelIds[0].value];
            this.setState({ hotelId })
        }
    }

    getGroupRes = () => {
        const { pageSize, pageIndex, hotelId, searchTerm, startDate, endDate, importTypeId } = this.state;
        let qs = "";

        if (hotelId)
            qs += `&hotelId=${hotelId}`;

        if (searchTerm)
            qs += `&searchTerm=${searchTerm}`;

        if (startDate) {
            qs += `&checkinRangeFrom=${moment(startDate).format("YYYY-MM-DD")}`;
        }

        if (endDate) {
            qs += `&checkinRangeTo=${moment(endDate).format("YYYY-MM-DD")}`;
        }

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ reservationList: data.response, block: false, activeType: importTypeId });
                return;
            }
            else
                this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/PmsGroupReservations?pageSize=${pageSize}&pageIndex=${pageIndex}${qs}&type=${importTypeId}`);
    }

    searchGroupRes = () => {
        this.setState({ block: true }, () => this.getGroupRes());
    }

    validateDates = (e, startDate, endDate) => {
        e.preventDefault();

        const startDateInput = document.getElementById("importGroupResStartDate");
        const endDateInput = document.getElementById("importGroupResEndDate");

        if ((startDate && !endDate) || (!startDate && endDate)) {
            if (!startDate) {
                startDateInput.setCustomValidity(this.props.intl.formatMessage({ id: "generic.FillInThisField" }));
            } else {
                startDateInput.setCustomValidity("");
            }

            if (!endDate) {
                endDateInput.setCustomValidity(this.props.intl.formatMessage({ id: "generic.FillInThisField" }));
            } else {
                endDateInput.setCustomValidity("");
            }
        } else {
            startDateInput.setCustomValidity("");
            endDateInput.setCustomValidity("");
        }

        if (this.form.current.reportValidity()) {
            this.setState({ pageIndex: 0 }, () => this.searchGroupRes());
        } else {
            return;
        }
    }

    handleComboChange = (combo, name) => {
        this.setState({ [name]: combo?.value });
    }

    handleHotel = (e, combo) => {
        this.setState({ hotelId: combo?.value });
    }

    handleInputChange = (e) => {
        const { value, name } = e.target;

        this.setState({ [name]: value });
    }

    changePageSize = (evt) => {
        const { value } = evt.target;
        const { pageSize } = this.state;

        if (pageSize !== value) {
            this.setState({ block: true, pageSize: value }, () => this.getGroupRes());
        }
    }

    handlePageIndex = (p) => {
        const { pageIndex } = this.state;
        this.setState({ pageIndex: parseInt(pageIndex + p), block: true }, () => this.getGroupRes());
    }

    selectGroupResId = (id) => {
        this.setState({ selectedGroupResId: id === this.state.selectedGroupResId ? null : id });
    }

    importRes = () => {
        const { selectedGroupResId, hotelId, salesPipelineId, salesSourceId, importTypeId } = this.state;
        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    else if (data.response && data.response[0]) {
                        handleNotification(data, <FormattedMessage id="SalesDeal.ReservationImported" />, <FormattedMessage id="generic.success" />);

                        this.setState({ block: false }, () => {
                            this.props.toggleModal();
                            this.props.history.push({ pathname: `/SalesProcess`, search: `?dealId=${data.response[0].id}` });
                            window.location.hash = "#SalesDealDetail";
                        });

                        return;
                    }
                    else {
                        this.setState({ block: false });
                        return;
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/SalesProcess/v1/importdeal?externalId=${selectedGroupResId}&hotelId=${hotelId}&pipelineId=${salesPipelineId}&sourceId=${salesSourceId}${importTypeId ? `&type=${importTypeId}` : ''}`)
        })
    }

    changeStep = (value) => {
        const { steps } = this.state;

        steps.forEach(el => {
            el.active = el.step === value;
        })

        this.setState({ steps });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    render() {
        const { toggleModal, modal, salesPipelines, salesSources, selectedPipeline, intl } = this.props;
        const { block, error, reservationList, pageSize, pageIndex, searchTerm, startDate, endDate, hotelId, selectedGroupResId, salesSourceId, salesPipelineId, steps, activeType, importTypeId } = this.state;

        const currentStep = steps.find(el => el.active);
        return (
            <StyledModal toggleModal={toggleModal} modal={modal} className="bigModal" size="xl">
                <BlankCard block={block} error={error}>
                    <Row>
                        <Col>
                            <h5>
                                <i className="mr-2 fas fa-receipt" />
                                <FormattedMessage id="SalesDeal.CreateDealByGroupReservation" />
                            </h5>
                        </Col>
                        <Col className="text-right">
                            <CommonHelper help={<FormattedMessage id="SalesProcess.ImportDeal" />} id={'ImportDeal'} />
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col className="col-1 text-left d-flex align-items-center">
                            {steps[0].active === false ?
                                <Button className="btn- btn-host btn-small mr-4" style={{ 'flex': '1' }} onClick={_ => this.changeStep(currentStep.step - 1)}>
                                    <i className="fas fa-angle-left" />
                                </Button>
                                : ''}
                        </Col>

                        <Col className="py-1">
                            <ul className="steps">
                                {steps.map((step, key) =>
                                    <li className={'step ' + (step.active ? ' step-active' : '')} style={{ cursor: 'pointer' }} key={key}>
                                        <div className="step-content">
                                            <span className="step-text small">
                                                <FormattedMessage id={`SalesDeal.Step${step.step}`} />
                                            </span>
                                            <span className="step-circle my-1" />
                                            <span className="step-text small">
                                                <FormattedMessage id={`SalesDeal.${step.name}`} />
                                            </span>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </Col>

                        <Col className="col-1 d-flex align-items-center justify-content-end">
                            {steps[1].active ?
                                <>
                                    <Button type="button" className="btn-sm btn-host mr-2" onClick={this.importRes} disabled={selectedGroupResId == null}>
                                        <i className="fas fa-file-upload" />
                                    </Button>
                                    <Button className="btn-sm btn-host" onClick={(e) => this.validateDates(e, startDate, endDate)}>
                                        <i className="fas fa-search" />
                                    </Button>
                                </>
                                :
                                <Button className="btn- btn-host btn-small ml-4" style={{ 'flex': '1' }} onClick={_ => this.changeStep(currentStep.step + 1)} disabled={hotelId == null || salesPipelineId == null || salesSourceId == null}>
                                    <i className="fas fa-angle-right" />
                                </Button>
                            }
                        </Col>
                    </Row>

                    <form ref={this.form} onSubmit={this.handleSubmit}>
                        {steps[0].active ?
                            <>
                                <Row className="mt-2">
                                    <Col className="col-4">
                                        <div className='title-sm required'>
                                            <FormattedMessage id="SalesDeal.Hotel" />
                                        </div>
                                        <SalesProcessHotelCombo
                                            isMulti={false}
                                            isClearable={true}
                                            name='hotelIds'
                                            placeholder={intl.formatMessage({ id: "SalesProcess.Hotel" })}
                                            value={hotelId}
                                            onChangeFunc={this.handleHotel}
                                        />
                                    </Col>
                                    <Col>
                                        <div className="title-sm required"> <FormattedMessage id="SalesDeal.Source" /></div>
                                        <CustomSelect
                                            name="salesSourceId"
                                            placeholder=""
                                            isClearable={false}
                                            isSearchable={false}
                                            required={true}
                                            options={salesSources}
                                            value={salesSources?.find(({ value }) => salesSourceId === value) || ''}
                                            onChange={e => this.handleComboChange(e, 'salesSourceId')}
                                        />
                                    </Col>
                                    <Col className='col-4'>
                                        <div className="title-sm required"> <FormattedMessage id="SalesDeal.Pipelines" /></div>
                                        <CustomSelect
                                            name="salesPipelineId"
                                            placeholder=""
                                            isClearable={false}
                                            isSearchable={false}
                                            required={true}
                                            isDisabled={selectedPipeline}
                                            options={salesPipelines}
                                            value={salesPipelines?.find(({ value }) => value === salesPipelineId || '')}
                                            onChange={e => this.handleComboChange(e, 'salesPipelineId')}
                                        />
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <Row className='mt-3'>
                                    <Col className="col-2">
                                        <div className='title-sm'>
                                            <FormattedMessage id="SalesDeal.Type" />
                                        </div>
                                        <CustomSelect
                                            placeholder=""
                                            options={getSalesDealImportTypes(this.props.intl)}
                                            onChange={e => this.handleComboChange(e, 'importTypeId')}
                                            value={getSalesDealImportTypes(this.props.intl).find(({ value }) => value === importTypeId)}
                                            required={true}
                                            isClearable={false}
                                            isSearchable={false}
                                        />
                                    </Col>
                                    <Col className="col-2">
                                        <div className='title-sm required'>
                                            <FormattedMessage id="SalesDeal.Hotel" />
                                        </div>
                                        <SalesProcessHotelCombo
                                            isMulti={false}
                                            isClearable={true}
                                            name='hotelIds'
                                            placeholder={intl.formatMessage({ id: "SalesProcess.Hotel" })}
                                            value={hotelId}
                                            onChangeFunc={this.handleHotel}
                                            isDisabled={true}
                                        />
                                    </Col>
                                    <Col className="col-5">
                                        <div className='title-sm'>
                                            <FormattedMessage id="SalesDeal.FreeSearch" />
                                        </div>
                                        <Input
                                            type="text"
                                            value={searchTerm}
                                            name="searchTerm"
                                            onChange={this.handleInputChange}
                                        />
                                    </Col>
                                    <Col className='col-3'>
                                        <div className='title-sm'>
                                            <FormattedMessage id="SalesDeal.Dates" />
                                        </div>
                                        <DateRangePicker
                                            startDate={startDate}
                                            startDateId="importGroupResStartDate"
                                            isOutsideRange={day =>
                                                day.isBefore(moment().subtract(5, 'years')) || day.isAfter(moment().add(5, 'years'))
                                            }
                                            endDate={endDate}
                                            endDateId="importGroupResEndDate"
                                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate, endDate: endDate })}
                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                            small={true}
                                            numberOfMonths={1}
                                            showDefaultInputIcon={true}
                                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                        />
                                    </Col>
                                </Row>
                                {reservationList ?
                                    <div className='mt-4'>
                                        {reservationList && reservationList.length > 0 ?
                                            <>
                                                <div style={{ maxHeight: '50vh', overflow: 'auto' }} className='veryCoolScrollBar'>
                                                    {reservationList && reservationList.length > 0 ?
                                                        <>
                                                            <div style={{ maxHeight: '50vh', overflow: 'auto' }} className='veryCoolScrollBar'>
                                                                {activeType === 'GroupReservation' && reservationList.map((res, key) =>
                                                                    <div key={key} onClick={_ => this.selectGroupResId(res.groupReservationNumber)}
                                                                        className='shadow p-3 mb-3 cursor-pointer'
                                                                        style={{
                                                                            borderRadius: '4px',
                                                                            background: res.groupReservationNumber === selectedGroupResId ? '#d3eaff' : 'white'
                                                                        }}
                                                                    >
                                                                        <div className='w-100 d-flex align-items-center justify-content-between'>
                                                                            <div style={{ width: '30%' }}>
                                                                                <div className='title-sm'>
                                                                                    {res.companyEntityName ?
                                                                                        <FormattedMessage id={`SalesDeal.CompanyName`} />
                                                                                        :
                                                                                        <FormattedMessage id={`SalesDeal.AgencyName`} />
                                                                                    }
                                                                                </div>
                                                                                <div title={res.companyEntityName} className='text-truncate'>
                                                                                    {res.companyEntityName ? res.companyEntityName : res.agencyEntityName ? res.agencyEntityName : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: '12%' }}>
                                                                                <div className='title-sm'>
                                                                                    <FormattedMessage id={`SalesDeal.Status`} />
                                                                                </div>
                                                                                <div title={res.reservationStatusDescription} className='text-truncate'>
                                                                                    {res.reservationStatusDescription ? res.reservationStatusDescription : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: '12%' }}>
                                                                                <div className='title-sm'>
                                                                                    <FormattedMessage id={`SalesDeal.CheckIn`} />
                                                                                </div>
                                                                                <div title={CustomFormatDate(res.checkIn, null, null, intl)} className='text-truncate'>
                                                                                    {res.checkIn ? CustomFormatDate(res.checkIn, null, null, intl) : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: '12%' }}>
                                                                                <div className='title-sm'>
                                                                                    <FormattedMessage id={`SalesDeal.CheckOut`} />
                                                                                </div>
                                                                                <div title={CustomFormatDate(res.checkOut, null, null, intl)} className='text-truncate'>
                                                                                    {res.checkOut ? CustomFormatDate(res.checkOut, null, null, intl) : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: '6%' }}>
                                                                                <div className='title-sm'>
                                                                                    <FormattedMessage id={`SalesDeal.NumberRooms`} />
                                                                                </div>
                                                                                <div title={res.rooms} className='text-truncate'>
                                                                                    {res.rooms ? res.rooms : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: '6%' }}>
                                                                                <div className='title-sm'>
                                                                                    <FormattedMessage id={`SalesDeal.NumberSpaces`} />
                                                                                </div>
                                                                                <div title={res.spaces} className='text-truncate'>
                                                                                    {res.spaces ? res.spaces : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: '6%' }}>
                                                                                <div className='title-sm'>
                                                                                    <FormattedMessage id={`SalesDeal.Pax`} />
                                                                                </div>
                                                                                <div title={res.pax} className='text-truncate'>
                                                                                    {res.pax ? res.pax : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: '16%' }} className='text-right'>
                                                                                <div className='title-sm'>
                                                                                    <FormattedMessage id={`SalesDeal.ResNumber`} />
                                                                                </div>
                                                                                <div title={res.groupReservationNumber} className='text-truncate'>
                                                                                    {res.groupReservationNumber ? res.groupReservationNumber : '-'}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='mt-2 w-100 d-flex align-items-center justify-content-between'>
                                                                            <div style={{ width: '30%' }}>
                                                                                <div className='title-sm'>
                                                                                    <FormattedMessage id={`SalesDeal.Block`} />
                                                                                </div>
                                                                                <div title={res.allotmentBlockDescription} className='text-truncate'>
                                                                                    {res.allotmentBlockDescription ? res.allotmentBlockDescription : '-'}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {activeType === 'Block' && reservationList.map((res, key) =>
                                                                    <div key={key} onClick={_ => this.selectGroupResId(res.id)}
                                                                        className='shadow p-3 mb-3 cursor-pointer'
                                                                        style={{
                                                                            borderRadius: '4px',
                                                                            background: res.id === selectedGroupResId ? '#d3eaff' : 'white'
                                                                        }}
                                                                    >
                                                                        <div className='w-100 d-flex align-items-center justify-content-between'>
                                                                            <div style={{ width: '34%' }}>
                                                                                <div className='title-sm'>
                                                                                    <FormattedMessage id={`SalesDeal.Entity`} />
                                                                                </div>
                                                                                <div title={res.entityName} className='text-truncate'>
                                                                                    {res.entityName ? res.entityName : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: '15%' }}>
                                                                                <div className='title-sm'>
                                                                                    <FormattedMessage id={`SalesDeal.Status`} />
                                                                                </div>
                                                                                <div title={res.defaultReservationCustomStatusCode} className='text-truncate'>
                                                                                    {res.defaultReservationCustomStatusCode ? res.defaultReservationCustomStatusCode : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: '16%' }}>
                                                                                <div className='title-sm'>
                                                                                    <FormattedMessage id={`SalesDeal.CheckIn`} />
                                                                                </div>
                                                                                <div title={CustomFormatDate(res.fromDate, null, null, intl)} className='text-truncate'>
                                                                                    {res.fromDate ? CustomFormatDate(res.fromDate, null, null, intl) : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: '16%' }}>
                                                                                <div className='title-sm'>
                                                                                    <FormattedMessage id={`SalesDeal.CheckOut`} />
                                                                                </div>
                                                                                <div title={CustomFormatDate(res.toDate, null, null, intl)} className='text-truncate'>
                                                                                    {res.toDate ? CustomFormatDate(res.toDate, null, null, intl) : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: '19%' }} className='text-right'>
                                                                                <div className='title-sm'>
                                                                                    <FormattedMessage id={`SalesDeal.PriceListCode`} />
                                                                                </div>
                                                                                <div title={res.priceListCode} className='text-truncate'>
                                                                                    {res.priceListCode ? res.priceListCode : '-'}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='mt-2 w-100 d-flex align-items-center justify-content-between'>
                                                                            <div style={{ width: '30%' }}>
                                                                                <div className='title-sm'>
                                                                                    <FormattedMessage id={`SalesDeal.Block`} />
                                                                                </div>
                                                                                <div title={res.code} className='text-truncate'>
                                                                                    {res.code ? res.code : '-'}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {activeType === 'Event' && reservationList.map((res, key) =>
                                                                    <div key={key} onClick={_ => this.selectGroupResId(res.individualReservationId)}
                                                                        className='shadow p-3 mb-3 cursor-pointer'
                                                                        style={{
                                                                            borderRadius: '4px',
                                                                            background: res.individualReservationId === selectedGroupResId ? '#d3eaff' : 'white'
                                                                        }}
                                                                    >
                                                                        <div className='w-100 d-flex align-items-center justify-content-between'>
                                                                            <div style={{ width: '34%' }}>
                                                                                <div className='title-sm'>
                                                                                    <FormattedMessage id={`SalesDeal.Entity`} />
                                                                                </div>
                                                                                <div title={res.agencyEntityName} className='text-truncate'>
                                                                                    {res.agencyEntityName ? res.agencyEntityName : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: '15%' }}>
                                                                                <div className='title-sm'>
                                                                                    <FormattedMessage id={`SalesDeal.Status`} />
                                                                                </div>
                                                                                <div title={res.reservationStatusCode} className='text-truncate'>
                                                                                    {res.reservationStatusCode ? res.reservationStatusCode : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: '16%' }}>
                                                                                <div className='title-sm'>
                                                                                    <FormattedMessage id={`SalesDeal.CheckIn`} />
                                                                                </div>
                                                                                <div title={CustomFormatDate(res.checkIn, null, null, intl)} className='text-truncate'>
                                                                                    {res.checkIn ? CustomFormatDate(res.checkIn, null, null, intl) : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: '16%' }}>
                                                                                <div className='title-sm'>
                                                                                    <FormattedMessage id={`SalesDeal.CheckOut`} />
                                                                                </div>
                                                                                <div title={CustomFormatDate(res.checkOut, null, null, intl)} className='text-truncate'>
                                                                                    {res.checkOut ? CustomFormatDate(res.checkOut, null, null, intl) : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ width: '19%' }} className='text-right'>
                                                                                <div className='title-sm'>
                                                                                    <FormattedMessage id={`SalesDeal.PriceListCode`} />
                                                                                </div>
                                                                                <div title={res.individualReservationNumber} className='text-truncate'>
                                                                                    {res.individualReservationNumber ? res.individualReservationNumber : '-'}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='mt-2 w-100 d-flex align-items-center justify-content-between'>
                                                                            <div style={{ width: '30%' }}>
                                                                                <div className='title-sm'>
                                                                                    <FormattedMessage id={`SalesDeal.Event`} />
                                                                                </div>
                                                                                <div title={res.eventName} className='text-truncate'>
                                                                                    {res.eventName ? res.eventName : '-'}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                        : ''}
                                                </div>
                                                <Row className="mt-3">
                                                    <Col>
                                                        <ButtonGroup onClick={this.changePageSize}>
                                                            <Button className={(pageSize == 10 ? "bg-host text-white" : "text-secondary") + " page-link"} value={10}> 10 </Button>
                                                            <Button className={(pageSize == 25 ? "bg-host text-white" : "text-secondary") + " page-link"} value={25}> 25 </Button>
                                                            <Button className={(pageSize == 30 ? "bg-host text-white" : "text-secondary") + " page-link"} value={30}> 30 </Button>
                                                            <Button className={(pageSize == 50 ? "bg-host text-white" : "text-secondary") + " page-link"} value={50}> 50 </Button>
                                                        </ButtonGroup>
                                                    </Col>

                                                    <Col className="text-right">
                                                        <span>
                                                            <Button className="btn btn-host btn-sm mr-2" onClick={_ => this.handlePageIndex(-1)} disabled={pageIndex === 0}>
                                                                <i className="fas fa-angle-left" />
                                                            </Button>
                                                            <span className="mr-2">
                                                                <FormattedMessage id="SearchProfile.Page" values={{ page: parseInt(pageIndex + 1) }} />
                                                            </span>
                                                            <Button className="btn btn-host btn-sm" onClick={_ => this.handlePageIndex(1)} disabled={reservationList && reservationList.length < pageSize}>
                                                                <i className="fas fa-angle-right" />
                                                            </Button>
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </>
                                            : ''}
                                    </div>
                                    :
                                    <div className='py-4'>
                                        <FormattedMessage id="SalesDeal.NoGroupReservations" />
                                    </div>
                                }
                            </>
                        }
                    </form>
                </BlankCard>
            </StyledModal>
        );
    }
}

export default injectIntl(CreateDealByImportGroupRes);