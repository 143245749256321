import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Badge, Button, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getContactTypeList, getSocialMediaList } from '../Base/ReferenceDataFunctions';
import { ActiveInactiveStatusBadge } from '../Base/CommonUIComponents';
import { handleNotification } from '../Base/Notification';
import { postAPI } from '../Base/API';
import SocialMediaDetails from './SocialMediaDetails'; 
import ContactDetails from './ContactDetails';
import { SendEmailModal } from './SendEmailModal';

import Authorization, { CheckAuthorization } from '../Base/Authorization';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../Base/ErrorAlert';
import { ProfileHistory } from '../Base/HistoryComponents';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { DefaultPage } from '../Base/CommonUIComponents';
import SendSendGridEmailModal from './SendSendGridEmailModal';
import { SendTwillioSmsModal } from './SendTwillioSmsModal';

export class ProfileContacts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            availableTypes: [],
            deleteContactModal: false,
            deleteSocialMediaModal: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.profile !== nextProps.profile) {
            this.checkAvailableTypes(nextProps.profile.contacts);
        }
    }

    componentDidMount() {
        this.checkAvailableTypes(this.props.profile.contacts);
    }

    checkAvailableTypes = (contacts) => {
        const toMasterProps = global.toMasterProperties;
        const availableTypes = getContactTypeList(this.props.intl);

        //If toMasterProperties is cofigured, profile can only have 1 main and 1 non-main of each type
        if (toMasterProps && contacts) {
            const toMasterOptions = [{ enum: 'Email2', type: 'Email' }, { enum: 'Phone2', type: 'Phone' }, { enum: 'Mobile2', type: 'Mobile' }];


            if (toMasterProps.filter(el => toMasterOptions.some(t => el === t.type)).length === 3 && 
                contacts.some(c => c.type === 'Email' && c.main) && contacts.some(c => c.type === 'Email' && !c.main) &&
                contacts.some(c => c.type === 'Phone' && c.main) && contacts.some(c => c.type === 'Phone' && !c.main) &&
                contacts.some(c => c.type === 'Mobile' && c.main) && contacts.some(c => c.type === 'Mobile' && !c.main))
            {
                availableTypes = availableTypes.filter(t => t.value !== 'Email' && t.value !== 'Phone' && t.value !== 'Mobile' );
            }
            else {
                toMasterOptions.forEach(option => {
                    if (toMasterProps.some(el => el.value === option.type)) {
                        const hasMainEmail = contacts.some(el => el.type === option.type && el.main);
                        const hasNotMainEmail = contacts.some(el => el.type === option.type && !el.main);

                        const index = availableTypes.findIndex(t => t.value === option.type);

                        if (index > -1) {
                            if (hasMainEmail && hasNotMainEmail) {
                                availableTypes.splice(index, 1);
                            }
                            else {
                                availableTypes[index].mainOptions = { hasMainEmail, hasNotMainEmail };
                            }
                        }
                    }
                })
            }
        }

        this.setState({ availableTypes });
    }

    emailValidation = (evt, email) => {
        evt.preventDefault();
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                if (data.emailValid === "true") {
                    handleNotification(data, <FormattedMessage id="ProfileDetails.EmailValid" />, <FormattedMessage id="generic.success" />);
                }
                else {
                    handleNotification({ errors: [{ message: <FormattedMessage id="ProfileDetails.EmailInvalid" /> }] });
                }

                this.setState({ block: false });
            }
        }, `/api/gms/Marketing/v1/egoitransactional/v2/utilities/emailValidator?mail=${email}`)
    }

    resubscribeEmail = () => {
        const { profile } = this.props;
        const request = {
            emailAddress: profile.contacts && profile.contacts.find(c => c.type === 'Email').contact
        }
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.data && data.data.includes('@')) {
                    handleNotification(data, <FormattedMessage id="ProfileDetails.ResubscribeSuccessMessage" />, <FormattedMessage id="generic.success" />);
                }
                else {
                    const errorObj = {
                        errors: [{ message: data.data }]
                    };
                    handleNotification(errorObj);
                }
            }
            this.setState({ error: errorMessage, block: false });

        }, `/api/gms/Marketing/v1/egoitransactional/v2/email/action/resubscribe`, request);
    }


    toggleDeleteModal = (modal, selectedContact) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            selectedContact
        }));
    }

    removeContact = () => {
        this.props.removeElement(this.props.profile.id, this.state.selectedContact.id, 'contact', 'contacts');

        this.toggleDeleteModal('deleteContactModal');
    }

    removeSocialMedia = () => {
        this.props.removeElement(this.props.profile.id, this.state.selectedContact.id, 'socialMedia', 'socialMedia');

        this.toggleDeleteModal('deleteSocialMediaModal');
    }

    render() {
        const { profile, profileId, contactModal, selectedContact, socialMediaModal, selectedSocialMedia, sendSendGridEmailModal, sendEmailModal, sendSmsModal, hasEgoi, hasSendGrid, toggleModal, removeElement, updateTable, canEditProfile, hasTwillio, sendTwillioSmsModal } = this.props;
        const { deleteSocialMediaModal, deleteContactModal } = this.state;

        return (
            <div className="m-2 p-2">
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    {
                        sendTwillioSmsModal ?
                            <SendTwillioSmsModal
                                isOpen={sendTwillioSmsModal}
                                toggle={() => toggleModal('sendTwillioSmsModal')}
                                senderType={"cellphone"}
                                type={"sms"}
                                contacts={profile.contacts && profile.contacts.filter(c => c.type === 'Mobile')}
                                buttonLabel={<FormattedMessage id="ProfileDetails.SendSms" />}
                                profileId={profileId}
                            />
                            :
                            <div />
                    }
                    {
                        sendSendGridEmailModal ?
                            <SendSendGridEmailModal
                                isOpen={sendSendGridEmailModal}
                                toggle={() => toggleModal('sendSendGridEmailModal')}
                                senderType={"email"}
                                type={"email"}
                                contacts={profile.contacts && profile.contacts.filter(c => c.type === 'Email')}
                                buttonLabel={<FormattedMessage id="ProfileDetails.SendEmail" />}
                                profileId={profileId}
                            />
                            :
                            <div />
                    }
                    {
                        sendEmailModal ?
                            <SendEmailModal
                                isOpen={sendEmailModal}
                                toggle={() => toggleModal('sendEmailModal')}
                                senderType={"email"}
                                type={"email"}
                                contacts={profile.contacts && profile.contacts.filter(c => c.type === 'Email')}
                                buttonLabel={<FormattedMessage id="ProfileDetails.SendEmail" />}
                                profileId={profileId}
                            />
                            :
                            <div />
                    }
                    {
                        sendSmsModal ?
                            <SendEmailModal
                                isOpen={sendSmsModal}
                                toggle={() => toggleModal('sendSmsModal')}
                                senderType={"cellphone"}
                                type={"sms"}
                                contacts={profile.contacts && profile.contacts.filter(c => c.type === 'Mobile')}
                                buttonLabel={<FormattedMessage id="ProfileDetails.SendSms" />}
                                profileId={profileId}
                            />
                            :
                            <div />
                    }
                    <div className="pl-2">
                        <Row className="mb-2">
                            <Col className={global.isMobile ? 'col-12' : ''}>
                                <h5><i className="icon-icon-contacts mr-1"> </i><FormattedMessage id="ProfileDetails.ProfileContact" /></h5>
                            </Col>
                            <Col className="text-right mt-2 mt-lg-0">
                                <ProfileHistory
                                    component={"contact"}
                                    profileId={this.props.profileId}
                                />

                                {hasEgoi && canEditProfile ?
                                    <span>

                                        { profile.isMaster ?
                                            <Authorization
                                                perform="profileContacts:resubscribe"
                                                yes={() => (
                                                    <Button className="btn-sm btn-host mr-1 ml-2" onClick={this.resubscribeEmail} disabled={!profile.contacts || (profile.contacts && profile.contacts.find(c => c.type === 'Email') === undefined)}>
                                                        <FormattedMessage id="ProfileDetails.ReSubscribe" />
                                                    </Button>
                                                )}
                                                no={() => <div></div>}
                                            />
                                        : ''}   
                                        <Authorization
                                            perform="profileContacts:sendEmail"
                                            yes={() => (
                                                <Button className="btn-sm btn-host ml-2" onClick={() => toggleModal('sendEmailModal')} disabled={!profile.contacts || (profile.contacts && profile.contacts.find(c => c.type === 'Email') === undefined)}>
                                                    <i className="icon-icon-mail" />{" "}<FormattedMessage id="ProfileDetails.SendEmail" />
                                                </Button>
                                            )}
                                            no={() => <div></div>}
                                        />
                                        <Authorization
                                            perform="profileContacts:sendSms"
                                            yes={() => (
                                                <Button className="btn-sm btn-host ml-2" onClick={() => toggleModal('sendSmsModal')} disabled={!profile.contacts || (profile.contacts && profile.contacts.find(c => c.type === 'Mobile') === undefined)}>
                                                    <i className="icon-icon-sms" />{" "}<FormattedMessage id="ProfileDetails.SendSms" />
                                                </Button>
                                            )}
                                            no={() => <div></div>}
                                        />
                                    </span>
                                : ''}
                                {
                                    hasSendGrid && canEditProfile ?
                                        <span>
                                            <Authorization
                                                perform="profileContacts:sendEmail"
                                                yes={() => (
                                                    <Button className="btn-sm btn-host ml-2" onClick={() => toggleModal('sendSendGridEmailModal')} disabled={!profile.contacts || (profile.contacts && profile.contacts.find(c => c.type === 'Email') === undefined)}>
                                                        <i className="icon-icon-mail" />{" "}<FormattedMessage id="ProfileDetails.SendEmail" />
                                                    </Button>
                                                )}
                                                no={() => <div></div>}
                                            />
                                        </span>
                                        :
                                        ''
                                }
                                {
                                    hasTwillio && canEditProfile ?
                                        <span>
                                            <Authorization
                                                perform="profileContacts:sendSms"
                                                yes={() => (
                                                    <Button className="btn-sm btn-host ml-2" onClick={() => toggleModal('sendTwillioSmsModal')} disabled={!profile.contacts || (profile.contacts && profile.contacts.find(c => c.type === 'Mobile') === undefined)}>
                                                        <i className="icon-icon-sms" />{" "}<FormattedMessage id="ProfileDetails.SendSms" />
                                                    </Button>
                                                )}
                                                no={() => <div></div>}
                                            />
                                        </span>
                                        :
                                        ''
                                }

                                <Authorization
                                    perform="profileContacts:add"
                                    yes={() => canEditProfile && (
                                        <Button className="btn-sm btn-host ml-2" onClick={() => toggleModal('contactModal', 'selectedContact', null)}>
                                            <i className="fas fa-plus" />
                                        </Button>
                                    )}
                                    no={() => <div></div>}
                                />
                            </Col>
                        </Row>
                        {
                            profile.contacts && profile.contacts.map((contact, key) => {
                                const contactType = getContactTypeList(this.props.intl).find(c => c.value === contact.type);

                                return <Card className="border-0 mb-2 shadow" key={key}>
                                    <CardBody className="p-3 p-lg-2">
                                        <Row className="mb-0 align-items-center">
                                            <Col className={`pr-0 pr-lg-3 ${global.isMobile ? (hasEgoi && contact.type === 'Email' ? 'col-9' : 'col-10') : ('')}`}>
                                                <div style={{ cursor: 'pointer' }} onClick={() => { if (canEditProfile && CheckAuthorization("profileContacts:add", null)) toggleModal('contactModal', 'selectedContact', contact) }}>
                                                    <Row className='align-items-center'>
                                                        <Col className="col-5 col-lg-2 text-truncate" id={`Type-${contact.id}`}>
                                                            {<i className={contactType?.icon + " mr-2"} />}
                                                            {contactType?.label}
                                                        </Col>
                                                        <Col className="col-3 text-center text-lg-left col-lg-1 p-lg-0">
                                                            <ActiveInactiveStatusBadge status={contact.active} />
                                                            {contact.main ? <Badge color="primary" className="mx-2 p-1">Main</Badge> : ''}
                                                        </Col>
                                                        <Col id={`Contact-${contact.id}`} className={global.isMobile ? 'col-4 text-truncate' : ''}> {contact.contact} </Col>
                                                    </Row>
                                                </div>
                                            </Col>


                                            <Col className={`${hasEgoi && contact.type === 'Email' ? 'col-3' : 'col-2'} col-lg-1 text-right`}>
                                                {hasEgoi && contact.type === 'Email'?
                                                    <>
                                                        <span className="pointer" onClick={(e) => this.emailValidation(e, contact.contact)} id="emailValidation">
                                                            <i className="fas fa-check mr-4" />
                                                        </span>
                                                        <UncontrolledTooltip target="emailValidation" placement="bottom">
                                                            <FormattedMessage id="ProfileDetails.ValidateEmail" />
                                                        </UncontrolledTooltip>
                                                    </>
                                                    : ''}

                                                <Authorization
                                                    perform="profileContacts:delete"
                                                    yes={() => canEditProfile && (
                                                        <span onClick={() => this.toggleDeleteModal('deleteContactModal', contact)} style={{ cursor: 'pointer' }}>
                                                            <i className="fas fa-trash-alt" />
                                                        </span>
                                                    )}
                                                    no={() => <div></div>}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            })
                        }
                        {profile.contacts == null || profile.contacts.length == 0 ?
                            <DefaultPage text="ProfileDetails.NoContactsAvailable" icon="icon-icon-contacts" height="30vh" />
                        : ''}

                        {contactModal ?
                            <ContactDetails
                                modal={contactModal}
                                toggleModal={() => toggleModal('contactModal')}
                                contact={selectedContact}
                                profileId={profileId}
                                updateTable={updateTable}
                                allContacts={profile.contacts}
                                availableTypes={this.state.availableTypes}
                                nationality={this.props.nationality}
                                addresses={this.props.addresses}
                            />
                        : ''}
                    </div>

                    <div className="mt-5 pl-2">
                        <Row className="mb-2">
                            <Col>
                                <h5><i className="fab fa-facebook-square mr-1"></i> <FormattedMessage id="ProfileDetails.ProfileSocialMedia" /></h5>
                            </Col>

                            <Col className="text-right">
                                <ProfileHistory
                                    component={"socialmedia"}
                                    profileId={this.props.profileId}
                                />

                                <Authorization
                                    perform="profileContacts:socialMedia:add"
                                    yes={() => canEditProfile && (
                                        <Button className="btn-sm btn-host ml-2" onClick={() => { if (CheckAuthorization("profileContacts:socialMedia:add", null)) toggleModal('socialMediaModal', 'selectedSocialMedia', null) }}>
                                            <i className="fas fa-plus" />
                                        </Button>
                                    )}
                                    no={() => <div></div>}
                                />
                            </Col>
                        </Row>
                    
                        {profile.socialMedia && profile.socialMedia.map((sm, key) =>
                            <Card className="border-0 mb-2 shadow" key={key}>
                                <CardBody className="p-2">
                                    <Row className="mb-0">
                                        <Col>
                                            <div style={{ cursor: 'pointer' }} onClick={() => { if (canEditProfile && CheckAuthorization("profileContacts:socialMedia:add", null)) toggleModal('socialMediaModal', 'selectedSocialMedia', sm)}}>
                                                <Row>
                                                    <Col className="col-2" id={`Type-${sm.id}`}>
                                                        <h6 className="mb-0 ">{getSocialMediaList().find(i => i.value === sm.type)?.icon} {sm.type} {sm.main ? <Badge color="primary" className="ml-2">Main</Badge> : ''}</h6>
                                                    </Col>
                                                    <Col>
                                                        <Row>
                                                            <Col id={`Url-${sm.id}`}><b>URL: </b> {sm.url} </Col>
                                                        </Row>
                                                        <Row id={`Notes-${sm.id}`}>
                                                            <Col><b><FormattedMessage id="ProfileDetails.Notes" />: </b> {sm.notes} </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>

                                        <Authorization
                                            perform="profileContacts:socialMedia:delete"
                                            yes={() => canEditProfile && (
                                                <Col className="text-right col-1">
                                                    <span onClick={() => this.toggleDeleteModal('deleteSocialMediaModal', sm)} style={{ cursor: 'pointer' }}>
                                                        <i className="fas fa-trash-alt" />
                                                    </span>
                                                </Col>
                                            )}
                                            no={() => <div></div>}
                                        />
                                    </Row>
                                </CardBody>
                            </Card>
                        )}

                        {profile.socialMedia == null || profile.socialMedia.length == 0 ? 
                            <DefaultPage text="ProfileDetails.NoSocialMediaAvailable" icon="fab fa-facebook-square" height="30vh" />
                        : ''}

                        {socialMediaModal ?
                            <SocialMediaDetails
                                modal={socialMediaModal}
                                toggleModal={() => toggleModal('socialMediaModal')}
                                socialMedia={selectedSocialMedia}
                                profileId={profileId}
                                updateTable={updateTable}
                                allSocialMedia={profile.socialMedia}
                            />
                        : ''}


                        {deleteSocialMediaModal || deleteContactModal ?
                            <ConfirmActionModal
                                modal={deleteSocialMediaModal || deleteContactModal}
                                toggleModal={() => this.toggleDeleteModal(deleteContactModal ? 'deleteContactModal' : 'deleteSocialMediaModal')}
                                actionFunction={deleteContactModal ? this.removeContact : this.removeSocialMedia}
                                text={deleteContactModal ? <FormattedMessage id="ProfileContacts.ConfirmDeleteText" /> : <FormattedMessage id="ProfileSocialMedia.ConfirmDeleteText" />}
                            />
                        : ''}
                    </div>
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(ProfileContacts);
