import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Badge, Breadcrumb, BreadcrumbItem, Button, Card, CardBody, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { getAPI, postAPI } from "../../Base/API";
import { ErrorAlert } from "../../Base/ErrorAlert";

import WalletCardElementsConfig from './WalletCardElementsConfig';
import BlockUi from 'react-block-ui';
import WalletCardPreview from './WalletCardPreview';
import { handleNotification } from '../../Base/Notification';
import moment from 'moment-timezone';

class WalletCardBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: false,
            activeTab: '0',
            activeConfigTab: '0',
            walletCardConfig: {},
            fieldsInGoogleCard: [],
            fieldsInAppleCard: [],
            fields: [
                { id: '1', name:'First Name', value: 'FirstName', card: null, row: null, column: null }, 
                { id: '2', name:'Last Name', value: 'LastName', card: null, row: null, column: null }, 
                { id: '3', name:'Email', value: 'EMail', card: null, row: null, column: null }, 
                { id: '4', name:'Phone Number', value: 'Phone', card: null, row: null, column: null }, 
                { id: '5', name:'Loyalty Card Number', value: 'CardNumber', card: null, row: null, column: null }, 
                { id: '6', name:'Points', value: 'Points', card: null, row: null, column: null }, 
                { id: '7', name:'Level', value: 'Level', card: null, row: null, column: null }
            ],
            configField: null
        }
    }

    componentDidMount() {
        const { selectedWalletCardId } = this.props;
        if(selectedWalletCardId){
            this.getWalletCardConfig(selectedWalletCardId);
        }
    }

    getWalletCardConfig = (walletId) => {
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors?.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }

                    if (data.data?.length > 0) {
                        const commonData = data.data[0].commonData?.[0] || {};
                        const walletCardConfig = {
                            ...commonData,
                            walletId: data.data[0].id,
                            programTypeCode: data.data[0].programTypeCode,
                            barCode: commonData.barCodeType ? true : false,
                        };
                    
                        this.setState({ walletCardConfig, block: false, error: null }, () => this.getFieldsInCard());
                        return;
                    }
                    else {
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                }
                this.setState({ block: false });
            }, `/api/gms/Wallet/v1${walletId ? `?id=${walletId}` : ''}`);
        });
    };

    getFieldsInCard = () => {
        const { walletCardConfig, fields, activeTab } = this.state;
        if (walletCardConfig?.fieldLocation?.length > 0) {
            if(activeTab === '0') {
                const fieldsInGoogleCard = walletCardConfig.fieldLocation.filter(f => f.fieldLocation)?.map(fl => {
                    const field = fields.find(_ => _.value === fl.fieldValue);
                    if(field){
                        const row = fl.fieldLocation?.split(',')?.[0] ? parseInt(fl.fieldLocation?.split(',')?.[0]) : null;
                        const column = fl.fieldLocation?.split(',')?.[1] ? parseInt(fl.fieldLocation?.split(',')?.[1]) : null;
                        
                        return { ...field, row, column, card: !isNaN(row) && isNaN(column) ? 'detailsCard' : 'frontCard' };
                    }
                }) || [];

                const updatedFields = fields.map(f => {
                    const field = fieldsInGoogleCard.find(_ => _.value === f.value);
                    if(field){
                        return { ...f, row: field.row, column: field.column, card: field.card };
                    }
                    return f;
                });
    
                this.setState({ fieldsInGoogleCard, fields: updatedFields });
            }else{
                const fieldsInAppleCard = [];
    
                this.setState({ fieldsInAppleCard });
            }
        }
    };

    saveWalletCardConfig = (e) => {
        if(e) e.preventDefault();
        const { walletCardConfig, fieldsInGoogleCard, fieldsInAppleCard } = this.state;
        const { intl } = this.props;

        if(!walletCardConfig){
            handleNotification({warnings: [{message: intl.formatMessage({ id: 'WalletCardConfig.SaveConfigWarning' })}]});
        }
        const programTypeCode = walletCardConfig.programTypeCode;
        delete walletCardConfig.programTypeCode;

        const fieldLocationFixedValues = walletCardConfig?.fieldLocation?.filter(f => !f.fieldLocation);
        const fieldLocationDynamicValues = fieldsInGoogleCard.map(f => {
            return {
                fieldCode: f.value,
                fieldLabel: f.name,
                fieldValue: f.value,
                fieldLocation: f.card === "detailsCard" ? `${f.row}` : `${f.row},${f.column}`,
                fieldValuePresentationType: ""
            }
        });
        
        const fieldLocation = [...fieldLocationFixedValues, ...fieldLocationDynamicValues];
        const updatedWalletCardConfig = { 
            id: walletCardConfig.walletId, 
            commonData: [{ ...walletCardConfig, fieldLocation }],
            programTypeCode
        };

        this.setState({ block: true, error: null }, () => {
            postAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors?.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if(data.data?.length > 0){
                        handleNotification(data, '', <FormattedMessage id="WalletCardConfig.WalletCardConfigSuccessfullySaved" />);
                        this.getWalletCardConfig(walletCardConfig.walletId);
                        return;
                    }

                }else{
                    this.setState({ block: false });
                    return;
                }
            }, `/api/gms/Wallet/v1`, updatedWalletCardConfig);
        });
    };

    publishWalletCardConfig = () => {
        const { walletCardConfig } = this.state;
        const walletId = walletCardConfig?.walletId;
        const type = walletCardConfig?.type

        if(!walletId) return;

        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors?.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if(data.data?.length > 0){
                        handleNotification(data, '', <FormattedMessage id="WalletCardConfig.WalletCardConfigSuccessfullyPublished" />);
                        this.getWalletCardConfig(walletId);
                        return;
                    }

                }else{
                    this.setState({ block: false });
                    return;
                }
            }, `/api/gms/Wallet/v1/publish?walletId=${walletId}&type=${type}`);
        });
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    };

    toggleConfigType = (configType) => {
        if (this.state.activeConfigTab !== configType) {
            this.setState({
                activeConfigTab: configType
            });
        }
    };

    changeWalletCardConfig = (walletCardConfig) => {
        this.setState({ walletCardConfig });
    };

    setFieldsInCard = (fields) => {
        const { activeTab } = this.state;
        
        if (activeTab === '0') {
            this.setState({ fieldsInGoogleCard: fields });
        } else {
            this.setState({ fieldsInAppleCard: fields });
        }
    };

    removeElementFromCard = (element) => {
        if (!element) return;
    
        const { activeTab, fields, fieldsInGoogleCard, fieldsInAppleCard } = this.state;
    
        const updatedFields = fields.map(e => 
            e.id === element.id ? { ...e, row: null, column: null } : e
        );
    
        const updatedGoogleCard = fieldsInGoogleCard.filter(e => e.id !== element.id);
        const updatedAppleCard = fieldsInAppleCard.filter(e => e.id !== element.id);
    
        this.setState({ fields: updatedFields, ...(activeTab === '0'  ? { fieldsInGoogleCard: updatedGoogleCard } : { fieldsInAppleCard: updatedAppleCard }) });
    };

    setFieldsInGoogleCard = (fieldsInGoogleCard) => {
        this.setState({ fieldsInGoogleCard });
    };

    onDragStart = (event, element) => {
        if (event && event.dataTransfer) {
            event.dataTransfer.setData('application/elementId', element.id);
        }
    };

    onDragOver = (e) => {
        e.preventDefault();
    };

    onDrop = (event, row, col, card) => {
        event.preventDefault();
        const { fields } = this.state;
        const elementId = event.dataTransfer.getData('application/elementId');
        
        if (!elementId) return;
    
        const updatedFields = [...fields];
        const element = updatedFields.find(e => e.id === elementId);
        
        if (!element) return;
        
        const elementInPositionIdx = updatedFields.findIndex(e => e.row === row && e.column === col && e.card === card);
        if (elementInPositionIdx !== -1) {
            if ((element.row || element.row === 0) && (element.column || element.column === 0)) {
                updatedFields[elementInPositionIdx] = { ...updatedFields[elementInPositionIdx], row: element.row, column: element.column, card: element.card };
            } else {
                return;
            }
        }
    
        element.row = row;
        element.column = col;
        element.card = card;
    
        updatedFields[fields.findIndex(e => e.id === elementId)] = element;
    
        this.setState({ fields: updatedFields }, () => {
            this.setFieldsInCard(updatedFields.filter(e => e.row !== null && e.card !== null));
        });
    };

    toggleFieldConfig = (fieldName) => {
        this.setState({ configField: fieldName });
    };

    render() {
        const { block, error, activeTab, activeConfigTab, walletCardConfig, fieldsInGoogleCard, fields, configField } = this.state;
        const { programTypeOptions } = this.props;
        
        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link onClick={this.props.goBack} to='#'>
                            <FormattedMessage id="NavMenu.WalletCardConfig" />
                        </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                        {walletCardConfig?.fieldLocation?.find(field => field?.fieldCode === 'ProgramName')?.fieldValue || <FormattedMessage id="WalletCardConfig.BuilderTitle"/>}
                    </BreadcrumbItem>
                </Breadcrumb>
                <Row className='my-3'>
                    <Col className='col-4'>
                        <form ref={this.setWrapperFormRef} onSubmit={(e) => this.saveWalletCardConfig(e)}>
                            <Row>
                                <Col>
                                    <Nav tabs className="border-0 mb-3">
                                        <NavItem>
                                            <NavLink className={`${activeConfigTab === '0' ? 'tab-border-host text-host border-0' : 'border-0'}`} onClick={() => this.toggleConfigType('0')} style={{ cursor: "pointer" }}>
                                                <b><FormattedMessage id="WalletCardConfig.Items"/></b>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={activeConfigTab === '1' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => this.toggleConfigType('1')} style={{ cursor: "pointer" }}>
                                                <b><FormattedMessage id="WalletCardConfig.Theme"/></b>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={activeConfigTab === '2' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => this.toggleConfigType('2')} style={{ cursor: "pointer" }}>
                                                <b><FormattedMessage id="WalletCardConfig.BarCode"/></b>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className={activeConfigTab === '3' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => this.toggleConfigType('3')} style={{ cursor: "pointer" }}>
                                                <b><FormattedMessage id="WalletCardConfig.CardConfig"/></b>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Col>
                                <Col className="col-3 d-flex align-items-center justify-content-end">
                                    <Button className="btn btn-sm btn-host mr-2" type='button' onClick={() => this.publishWalletCardConfig()}>
                                        <FormattedMessage id="WalletCardConfig.Publish"/>
                                    </Button>
                                    <Button className="btn btn-sm btn-host" type='submit'>
                                        <i className="fas fa-save" ></i>
                                    </Button>
                                </Col>
                            </Row>
                            <Card>
                                <CardBody>
                                    <WalletCardElementsConfig
                                        activeTab={activeTab}
                                        activeConfigTab={activeConfigTab}
                                        fieldsInGoogleCard={fieldsInGoogleCard}
                                        fields={fields}
                                        configField={configField}
                                        programTypeOptions={programTypeOptions}
                                        walletCardConfig={{...walletCardConfig}}
                                        changeWalletCardConfig={this.changeWalletCardConfig}
                                        onDragStart={this.onDragStart}
                                        removeElementFromCard={this.removeElementFromCard}
                                        toggleFieldConfig={this.toggleFieldConfig}
                                    />
                                </CardBody>
                            </Card>
                        </form>
                        <Col className='col-12 d-flex flex-column align-items-start px-0 mt-3'>
                            <div className='d-flex align-items-center'>
                                <FormattedMessage id="WalletCardConfig.Status" />
                                <Badge color={walletCardConfig?.published ? "primary" : "secondary"} className='ml-2'>
                                    <FormattedMessage id={`WalletCardConfig.${walletCardConfig?.published ? 'Published' : 'NotPublished'}`} />
                                </Badge>
                            </div>
                            {walletCardConfig?.publishingDate ?
                                <div className='d-flex align-items-center mt-2'>
                                    <FormattedMessage id="WalletCardConfig.PublishingDate" />
                                    <span className='ml-2'>{moment(walletCardConfig?.publishingDate)?.format('YYYY/MM/DD - HH:mm')}</span>
                                </div>
                            : <div />}
                        </Col>
                    </Col>
                    <Col className='col-8'>
                        <Row>
                            <Col className='d-flex align-items-center justify-content-center'>
                                <Nav tabs className="border-0 mb-3">
                                    <NavItem>
                                        <NavLink className={`${activeTab === '0' ? 'tab-border-host text-host border-0' : 'border-0'}`} onClick={() => this.toggleTab('0')} style={{ cursor: "pointer" }}>
                                            <i className="fab fa-google mr-2" /><b><FormattedMessage id="WalletCardConfig.GoogleWallet"/></b>
                                        </NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                        <NavLink className={activeTab === '1' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => this.toggleTab('1')} style={{ cursor: "pointer" }}>
                                            <i className="fab fa-apple mr-2" /><b><FormattedMessage id="WalletCardConfig.AppleWallet"/></b>
                                        </NavLink>
                                    </NavItem> */}
                                </Nav>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='d-flex align-items-center justify-content-center'>
                                <WalletCardPreview 
                                    walletCardConfig={walletCardConfig} 
                                    activeTab={activeTab} 
                                    fieldsInGoogleCard={fieldsInGoogleCard}
                                    onDragStart={this.onDragStart}
                                    onDrop={this.onDrop}
                                    onDragOver={this.onDragOver}
                                    removeElementFromCard={this.removeElementFromCard}
                                    setFieldsInGoogleCard={this.setFieldsInGoogleCard}
                                    toggleFieldConfig={this.toggleFieldConfig}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </BlockUi>
        )
    }
}

export default injectIntl(WalletCardBuilder);