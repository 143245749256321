import React, { Component } from 'react';
import { Button, Col, Row, Modal, ModalBody } from 'reactstrap';
import { StyledCard } from '../../Base/CommonUIComponents';
import { CustomTable } from '../../Base/CustomTable';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SendGridPreviewVersion } from './SendGridPreviewVersion';
import { handleNotification } from '../../Base/Notification';
import { deleteAPI } from '../../Base/API';

class SendGridHistoryModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            previewModal: false,
            selectedVersionId: null
        };
    }

    togglePreviewModal = (versionId) => {
        this.setState(prevState => ({ previewModal: !prevState.previewModal, selectedVersionId: versionId }))
    }

    removeVersion = (versionId, versionIdx) => {
        const { templateId, templateIdx, removeVersionByTemplate } = this.props;

        this.setState({ block: true });
        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="SendGrid.VersionOfTemplateRemoved" />, <FormattedMessage id="generic.success" />);
                    removeVersionByTemplate(templateIdx, versionIdx);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SendGrid/v1/templateversion?templateId=${templateId}&versionId=${versionId}`);
    }

    render() {
        const { isOpen, toggle, templateId, templateName, versions } = this.props;
        const { block, error, previewModal, selectedVersionId } = this.state;

        const columns = [
            {
                dataField: 'updated_at',
                text: this.props.intl.formatMessage({ id: "SendGrid.Date" })
            },
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "SendGrid.Name" })
            },
            {
                dataField: 'subject',
                text: this.props.intl.formatMessage({ id: "SendGrid.Subject" })
            },
            {
                dataField: 'id',
                text: this.props.intl.formatMessage({ id: "SendGrid.Preview" }),
                formatter: cell => <Button className="btn btn-host btn-sm ml-2" onClick={() => this.togglePreviewModal(cell)}>
                    <i className="icon-icon-preview" />
                </Button>
            },
            {
                dataField: 'id',
                text: this.props.intl.formatMessage({ id: "SendGrid.Remove" }),
                formatter: (cell, _, idx) => <Button className="btn btn-host btn-sm ml-2" onClick={() => this.removeVersion(cell, idx)}>
                    <i className="far fa-trash-alt"></i>
                </Button>
            }
        ]

        return (
            <Modal isOpen={isOpen} fade={false} size={"xl"} style={{ minWidth: 0 }}>
                {
                    previewModal ?
                        <SendGridPreviewVersion
                            isOpen={previewModal}
                            toggle={this.togglePreviewModal}
                            templateId={templateId}
                            templateName={templateName}
                            selectedVersionId={selectedVersionId}
                        />
                        :
                        ''
                }
                <Button onClick={toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <StyledCard block={block} error={error}>
                        <Row className="mb-2">
                            <Col>
                                <h5>
                                    <FormattedMessage id="SendGrid.Versions" values={{ templateName: templateName }} />
                                </h5>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <CustomTable
                                    data={versions}
                                    columns={columns}
                                    hasPagination={false}
                                />
                            </Col>
                        </Row>
                    </StyledCard>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(SendGridHistoryModal);