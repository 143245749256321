import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Col, Row, Progress, Badge } from 'reactstrap';
import { handleNotification } from "../../Base/Notification";
import { BlankCard } from "../../Base/CommonUIComponents";
import { getAPI } from "../../Base/API";
import { FormattedMessage } from "react-intl";
import countryList from "react-select-country-list";

class MentionDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false
        };
    }

    componentDidMount() {
        if (this.props.formId) {
            this.getFormQuestions();
        }
    }

    getFormQuestions = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    var list = [];

                    data.response.forEach(el => {
                        if (el.questionTitle) list.push({ value: el.id, label: el.questionTitle });
                    });

                    this.setState({ questions: list });
                }
            }
            this.setState({ error: errorMessage }, () => this.getMap());
        }, `/api/gms/ReviewPro/v1/SurveyQuestions/${this.props.formId}`);
    }


    render() {
        const { block, error } = this.state;
        const { selectedReview } = this.props;

        return (
            <BlankCard block={block} error={error}>
                <Row>
                    <Col>
                        <h5> <i className="fas fa-award " /> <b> <FormattedMessage id="ReviewProStats.Review" /> </b>
                            {selectedReview.sentimentPositive ?
                                <Badge color="success" className='p-1 ml-3'><FormattedMessage id="ReviewProStats.Positive" /></Badge> :
                                <Badge color="danger" className='p-1 ml-3'><FormattedMessage id="ReviewProStats.Negative" /></Badge>
                            }
                        </h5>
                    </Col>
                    <Col className="text-right">
                        <h5> {selectedReview.date} </h5>
                    </Col>
                </Row>
                <hr className="mt-2" />
                <Row>
                    <Col sm={2}> <b> Hotel </b> </Col>
                    <Col> {selectedReview.hotel && selectedReview.hotel.name} </Col>
                    <Col sm={2}> <b> <FormattedMessage id="ReviewProStats.Source" /> </b> </Col>
                    <Col> {selectedReview.source} </Col>
                </Row>
                <Row className="mb-5 mt-3">
                    <Col sm={2}> <b> <FormattedMessage id="ReviewProStats.Rating" /> </b> </Col>
                    <Col>
                        <Progress striped
                            value={selectedReview.reviewRating && selectedReview.reviewRating.value}
                            max={selectedReview.reviewRating && selectedReview.reviewRating.outOf}
                        >
                            {selectedReview.reviewRating && selectedReview.reviewRating.value}
                        </Progress>
                    </Col>
                    <Col sm={2}> <b> <FormattedMessage id="ReviewProStats.Score" /> </b> </Col>
                    <Col> {selectedReview.score * 100} % </Col>
                </Row>


                <h5> <i className="far fa-id-card" /> <b> <FormattedMessage id="ReviewProStats.Reviewer" /> </b> </h5>
                <hr className="mt-2" />
                <Row>
                    <Col sm={2}> <b> <FormattedMessage id="ReviewProStats.Name" /> </b> </Col>
                    <Col> {selectedReview.reviewer}</Col>
                </Row>
                <Row className="mb-5 mt-3">
                    <Col sm={2}> <b> <FormattedMessage id="ReviewProStats.Country" /> </b> </Col>
                    <Col>
                        {selectedReview.country ?
                            <span><i className={('mr-2 flag-icon flag-icon-') + (selectedReview.country.toLowerCase())}></i> {countryList().getLabel(selectedReview.country)}</span>
                        :''}
                    </Col>
                    <Col sm={2}> <b> <FormattedMessage id="ReviewProStats.Language" /> </b> </Col>
                    <Col> {selectedReview.language}</Col>
                </Row>


                <h5> <i className="far fa-edit" /> <b> <FormattedMessage id="ReviewProStats.Content" /> </b> </h5>
                <hr className="mt-2" />
                <Row>
                    <Col sm={2}> <b> <FormattedMessage id="ReviewProStats.Mention" /> </b></Col>
                    <Col> {selectedReview.mention} </Col>
                </Row>
                <Row className="mb-5 mt-3">
                    <Col sm={2}> <b> <FormattedMessage id="ReviewProStats.Phrase" /> </b></Col>
                    <Col> {selectedReview.phrase} </Col>
                </Row>


                <h5> <i className="fas fa-signature" /> <b> <FormattedMessage id="ReviewProStats.Concepts" /> </b> </h5>
                <hr className="mt-2" />
                {selectedReview.concepts && selectedReview.concepts.length > 0 ?
                    <div>
                        <Row className="mb-3">
                            <Col>
                                <b> <FormattedMessage id="ReviewProStats.Name" /> </b>
                            </Col>
                            <Col>
                                <b> <FormattedMessage id="ReviewProStats.Category" /> </b>
                            </Col>
                            <Col>
                                <b> <FormattedMessage id="ReviewProStats.CustomCategory" />  </b>
                            </Col>
                        </Row>

                        {selectedReview.concepts && selectedReview.concepts.map((concept, key) =>
                            <Row className="overbglight mb-2" key={key}>
                                <Col>
                                    {concept.name}
                                </Col>
                                <Col>
                                    {concept.category && concept.category.name}
                                </Col>
                                <Col>
                                    {concept.customCategory && concept.customCategory.name}
                                </Col>
                            </Row>
                        )}
                    </div>
                : ''}
                

            </BlankCard>
        );
    }
}
export default injectIntl(MentionDetails)
