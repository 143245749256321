import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Col, Row, Popover, Button } from "reactstrap";
import { EventCircle, CampaignBox, PreviewTemplates } from './CommonFunctions';
import { ErrorAlert } from "../../Base/ErrorAlert";
import { withRouter } from 'react-router-dom';
import BlockUi from 'react-block-ui';


class RecurringCampaigns extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            campaigns: this.props.recurringCampaigns ? this.props.recurringCampaigns : [],
            campaignTypes: [
                { value: 'ReservationDate', label: this.props.intl.formatMessage({ id: 'Campaigns.ReservationDate' }), icon: "far fa-flag", color: 'cyan', campaigns: [] },
                { label: null, campaigns: [] },
                { value: 'CancelationDate', label: this.props.intl.formatMessage({ id: 'Campaigns.CancelationDate' }), icon: 'far fa-bookmark', color: 'light-red', campaigns: [] },
                { label: null, campaigns: [] },
                { value: 'Arrival', label: this.props.intl.formatMessage({ id: 'Campaigns.Arrival' }), icon: 'fas fa-door-open', color: 'yellow', campaigns: [] },
                { label: null, campaigns: [] },
                { value: 'InHouse', label: this.props.intl.formatMessage({ id: 'Campaigns.InHouse' }), icon: 'fas fa-envelope-open', color: 'violet', campaigns: [] },
                { label: null, campaigns: [] },
                { value: 'Departure', label: this.props.intl.formatMessage({ id: 'Campaigns.Departure' }), icon: 'fas fa-door-closed', color: 'purple', campaigns: [] },
                {  label: null, campaigns: [] },
            ],
            otherEvents: []
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.recurringCampaigns !== this.props.recurringCampaigns) {
            this.setState({
                campaigns: nextProps.recurringCampaigns
            }, () => this.groupCampaigns());
        }
    }

    componentDidMount() {
        this._isMounted = true;

        this.groupCampaigns();
    }

    groupCampaigns = () => {
        let { campaignTypes, otherEvents } = this.state;
        otherEvents = [];
        campaignTypes = campaignTypes.map(ct => { return { ...ct, campaigns: [] } });


        this.state.campaigns && this.state.campaigns.forEach(campaign => {
            if (campaign.marketingType === 'Birthday' || campaign.marketingType === 'CreationDay') {
                otherEvents.push(campaign);
            }
            else {
                const beforeAfter = (campaign.frequencyAdjust > 0) ? 1 : (campaign.frequencyAdjust < 0) ? -1 : 0;

                if (campaign.frequency === 'ReservationDate') {
                    campaign.color = "cyan";
                    campaignTypes[(0 + (beforeAfter))].campaigns.push(campaign);
                    return
                }
                if (campaign.frequency === 'CancelationDate') {
                    campaign.color = "light-red";
                    campaignTypes[(2 + (beforeAfter))].campaigns.push(campaign);
                    return
                }
                if (campaign.frequency === 'Arrival') {
                    campaign.color = "yellow";
                    campaignTypes[(4 + (beforeAfter))].campaigns.push(campaign);
                    return
                }
                if (campaign.frequency === 'InHouse') {
                    campaign.color = "violet";
                    campaignTypes[(6 + (beforeAfter))].campaigns.push(campaign);
                    return
                }
                if (campaign.frequency === 'Departure') {
                    campaign.color = "purple";
                    campaignTypes[(8 + (beforeAfter))].campaigns.push(campaign);
                    return
                }
                else {
                    otherEvents.push(campaign);
                }
            }
        })

        this.setState({ campaignTypes, otherEvents });
    }

    handlePreview = (e, key, templates) => {
        e.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            [key]: { 'isOpen': (prevState[key] ? !prevState[key].isOpen : true), 'templates': templates },
        }));
    }

    render() {
        const { block, error, campaignTypes, otherEvents } = this.state;

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                <Row>
                    <Col sm={10} className="pr-1 py-3 bothScrollableDiv" style={{ height: '87vh' }} >
                        {campaignTypes.map((type, key) =>
                            <Row key={key} className="justify-content-center align-items-center my-2" style={{ height: '10%' }}>
                                {type.label === null ? <div style={{ borderBottom: '1px dashed #C9DEFE', zIndex: '0', position: 'absolute', width: '99%' }} /> : ''}

                                <Col sm={3}>
                                    {type.label ?
                                        <div className="d-flex align-items-center pl-3">
                                            <EventCircle icon={type.icon} bgColor={`bg-${type.color}`} borderColor={`border-${type.color}`}/>
                                            <b className={`ml-3 color-${type.color}`}> {type.label.toUpperCase()} </b>
                                        </div>
                                    : ''}
                                </Col>

                                <Col sm={9} className="d-flex align-items-center w-100">
                                    {type.campaigns && type.campaigns.map((campaign, i) =>
                                        <div key={i}>
                                            <div className="mr-3">
                                                <CampaignBox
                                                    id={`type${campaign.type}label${type.value}id${i}`}
                                                    type={campaign.marketingType}
                                                    channel={campaign.senderChannel}
                                                    bgColor={`bg-${campaign.color}`}
                                                    campaignName={campaign.name}
                                                    active={campaign.active}
                                                    adjust={campaign.frequencyAdjust}
                                                    handlePreview={(e) => this.handlePreview(e, `type${campaign.type}label${type.value}id${i}`, campaign.templates)}
                                                    hasFilters={(campaign.channel && campaign.channel.length > 0) || (campaign.rate && campaign.rate.length > 0) || (campaign.roomCategory && campaign.roomCategory.length > 0)}
                                                />

                                                <Popover placement="right" isOpen={this.state[`type${campaign.type}label${type.value}id${i}`] && this.state[`type${campaign.type}label${type.value}id${i}`].isOpen === true ? true : false} target={`type${campaign.type}label${type.value}id${i}`} className="styled-popover">
                                                    <div className="py-3 px-4">
                                                        <PreviewTemplates
                                                            hasTemplates={this.state[`type${campaign.type}label${type.value}id${i}`] && this.state[`type${campaign.type}label${type.value}id${i}`].templates && this.state[`type${campaign.type}label${type.value}id${i}`].templates.length > 0 ? true : false}
                                                            templates={this.state[`type${campaign.type}label${type.value}id${i}`] && this.state[`type${campaign.type}label${type.value}id${i}`].templates}
                                                            showOptions={true}
                                                            onEditClick={() => this.props.history.push({ pathname: "/CampaignDetails/" + campaign.id })}
                                                            onStatsClick={() => this.props.history.push({ pathname: "/CommunicationStats/" + campaign.id })}
                                                            onClose={(e) => this.handlePreview(e, `type${campaign.type}label${type.value}id${i}`, campaign.templates)}
                                                        />
                                                    </div>
                                                </Popover>
                                            </div>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        )}
                    </Col>


                    <Col sm={2} className="pt-4 d-flex flex-column align-items-center pl-0" style={{ borderLeft: '1px solid #C9DEFE',  zIndex: '10' }} >
                        <EventCircle icon="fas fa-ellipsis-h" bgColor="bg-host" borderColor="border-host" />

                        <div className="text-center mt-3 mb-4">
                            <b className={`color-host`}> {this.props.intl.formatMessage({ id: 'RecurringCampaigns.OtherEvents' }).toUpperCase()}  </b>
                        </div>

                        <div className="verticalScroll" style={{ height: '65vh', overflowY: 'auto' }}>
                            {otherEvents && otherEvents.map((event, i) =>
                                <Row key={i} className=" mb-3" style={{ margin: '0 auto' }}>
                                    <Col>
                                        <CampaignBox
                                            id={`otherCampId${i}`}
                                            channel={event.senderChannel}
                                            bgColor={`bg-host`}
                                            campaignName={event.name}
                                            type={event.marketingType}
                                            handlePreview={(e) => this.handlePreview(e, `otherCampId${i}`, event.templates)}
                                            hasFilters={(event.channel && event.channel.length > 0) || (event.rate && event.rate.length > 0) || (event.roomCategory && event.roomCategory.length > 0)}
                                        />

                                        <Popover placement="left" isOpen={this.state[`otherCampId${i}`] && this.state[`otherCampId${i}`].isOpen === true ? true : false} target={`otherCampId${i}`} className="styled-popover">
                                            <div className="py-3 px-4">
                                                <PreviewTemplates
                                                    hasTemplates={this.state[`otherCampId${i}`] && this.state[`otherCampId${i}`].templates && this.state[`otherCampId${i}`].templates.length > 0 ? true : false}
                                                    templates={this.state[`otherCampId${i}`] && this.state[`otherCampId${i}`].templates}
                                                    showOptions={true}
                                                    onEditClick={() => this.props.history.push({ pathname: "/CampaignDetails/" + event.id })}
                                                    onStatsClick={() => this.props.history.push({ pathname: "/CommunicationStats/" + event.id })}
                                                    onClose={(e) => this.handlePreview(e, `otherCampId${i}`, event.templates)}
                                                />
                                            </div>
                                        </Popover>
                                    </Col>
                                </Row>
                            )}
                        </div>

                        <div className="text-center w-100 pl-2" style={{ position: 'absolute', bottom: '-15px' }}>
                            <Button className="text-center bg-host-gradient px-3 py-2" style={{ borderRadius: '15px' }} onClick={() => this.props.history.push({ pathname: "/CampaignDetails" })}>
                                + <FormattedMessage id="RecurringCampaigns.CreateCampaign" />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </BlockUi>
        );
    }
}
export default injectIntl(withRouter(RecurringCampaigns))